import React, { useState, useRef } from 'react';
import AuthorPicker, {
  AuthorUserType,
  AuthorTeamType,
} from '~/components/AuthorPicker';
import Button from '~/components/Button';
import InputField from '~/components/InputField';
import ModalDatePicker, {
  ModalDatePickerHandler,
} from '~/components/ModalDatePicker';
import ModalTimePicker, {
  ModalTimePickerHandler,
} from '~/components/ModalTimePicker';
import NumberInput from '~/components/NumberInput';
import SectionHeader from '~/components/SectionHeader';
import {
  SettingPanel,
  SettingToggleItem,
  SettingLabelItem,
} from '~/components/SettingPanel';
import { CalendarDateObject } from '~/data/models/calendar';
import { UserGroup } from '~/data/models/group';
import { Team } from '~/data/models/team';
import { ModalScreenLayout } from '~/screens/style';
import { Spacer } from '~/theme/wrappers';
import { formattedDate } from '~/utils/dates';
import { t } from '~/utils/i18n';
import { getImageFromLibrary } from '~/utils/image-picker';
import PollOptionInput, { PollOptionInfo } from './PollOptionInput';
import { ScroolView, Section, SectionButtonRow, Footer } from './style';

export type CreatePollInputProps = {
  selectedTeamId?: string;
  question: string;
  deadline: string;
  answersAllowed: number;
  randomizeOptions: boolean;
  allowRevote: boolean;
  allowComments: boolean;
  anonymousVotes: boolean;
  privateResults: boolean;
  options: PollOptionInfo[];
};

type CreatePollLayoutProps = {
  loggedUserGroup?: UserGroup | null;
  teams: Team[];
  lockedTeam?: Team | null;
  loading: boolean;
  onBack: () => void;
  onCreatePoll: (pollInput: CreatePollInputProps) => void;
};

const pollOptionKey = (index: number): string => `opt${index}`;

const pollOptionInitialKey = pollOptionKey(1);

const pollOptionInitialInfo: PollOptionInfo = {
  text: '',
  imgUrl: '',
};

export default function CreatePoll({
  loggedUserGroup,
  teams,
  lockedTeam,
  loading,
  onBack,
  onCreatePoll,
}: CreatePollLayoutProps): JSX.Element {
  const dateModalizeRef = useRef<ModalDatePickerHandler>(null);
  const timeModalizeRef = useRef<ModalTimePickerHandler>(null);

  const [question, setQuestion] = useState<string>('');

  const [optionsMap, setOptionsMap] = useState<{
    [key: string]: PollOptionInfo;
  }>({
    [pollOptionInitialKey]: pollOptionInitialInfo,
  });

  const [answersAllowed, setAnswersAllowed] = useState<number>(1);

  const [author, setAuthor] = useState<string | undefined>();
  const [randomizeOptions, setRandomizeOptions] = useState<boolean>(false);
  const [allowRevote, setAllowRevote] = useState<boolean>(false);
  const [allowComments, setAllowCommentss] = useState<boolean>(true);
  const [anonymousVotes, setAnonymousVotes] = useState<boolean>(false);
  const [privateResults, setPrivateResults] = useState<boolean>(false);

  const [dueDate, setDueDate] = useState<CalendarDateObject | null>(null);
  const [dueTime, setDueTime] = useState<Date | undefined>(undefined);

  const optionKeys = Object.keys(optionsMap);

  const onAddOption = () => {
    const nextIndex = optionKeys.length + 1;
    const nextPoolOptionKey = pollOptionKey(nextIndex);
    setOptionsMap((optsMap) => ({
      ...optsMap,
      [nextPoolOptionKey]: pollOptionInitialInfo,
    }));
  };

  const onPickOptionImage = async (optionKey: string) => {
    const imgUris = await getImageFromLibrary();

    const uri = imgUris?.[0];
    if (uri) {
      setOptionsMap((optsMap) => ({
        ...optsMap,
        [optionKey]: {
          ...optsMap[optionKey],
          imgUrl: uri,
        },
      }));
    }
  };

  const mappedTeams = teams.map<AuthorTeamType>((item) => {
    return { id: item.id, authorType: 'team', team: item };
  });

  const authorMe: AuthorUserType | null = loggedUserGroup
    ? {
        id: loggedUserGroup.user.id,
        authorType: 'user',
        userGroup: loggedUserGroup,
      }
    : null;

  const authors: (AuthorUserType | AuthorTeamType)[] = authorMe
    ? [authorMe, ...mappedTeams]
    : mappedTeams;

  const optionsFilled = Object.values(optionsMap).filter(
    (option) => option.text != '',
  );
  const isAllOptionsFilled =
    optionsFilled.length === Object.values(optionsMap).length;

  const createPollEnabled =
    question != '' && isAllOptionsFilled && author != '' && dueDate && dueTime;

  const onCreate = () => {
    if (createPollEnabled && dueDate && dueTime) {
      const day = formattedDate(dueDate.dateString, 'yyyy-MM-dd', undefined);
      const time = formattedDate(dueTime, 'HH:mm:ss', undefined);
      const deadline = formattedDate(`${day}T${time}`, `yyyy-MM-dd'T'HH:mm:ss`);
      const options = Object.values(optionsMap);
      onCreatePoll({
        selectedTeamId: lockedTeam
          ? lockedTeam.id
          : author === loggedUserGroup?.user.id
          ? undefined
          : author,
        question,
        deadline,
        answersAllowed,
        randomizeOptions,
        allowRevote,
        allowComments,
        anonymousVotes,
        privateResults,
        options,
      });
    }
  };

  return (
    <ModalScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.createPoll')}
      onBackPress={onBack}
    >
      <ScroolView showsVerticalScrollIndicator={false}>
        <SectionHeader sub title={t('createPoll.questioSection')} />
        <Section>
          <InputField
            testID={'inputQuestion'}
            value={question}
            placeholder={t('createPoll.inputQuestion')}
            onChangeText={(value) => setQuestion(value)}
          />
        </Section>
        <Spacer size={12} />
        <SectionHeader sub title={t('createPoll.optionSection')} />
        <Section>
          {optionKeys.map((optionKey) => (
            <PollOptionInput
              key={optionKey}
              testID={optionKey}
              optionInfo={optionsMap[optionKey]}
              onChangeText={(text) => {
                setOptionsMap((optsMap) => ({
                  ...optsMap,
                  [optionKey]: {
                    ...optsMap[optionKey],
                    text,
                  },
                }));
              }}
              onImagePress={() => onPickOptionImage(optionKey)}
            />
          ))}
          <SectionButtonRow>
            <Button
              testID={'buttonAddOption'}
              text={t('g.addOption')}
              iconName="plus"
              icon="left"
              flex
              type={'secondary-base'}
              size={'sm'}
              onPress={onAddOption}
            />
          </SectionButtonRow>
        </Section>
        <Spacer size={8} />
        <SectionHeader
          sub
          title={t('createPoll.optinsNumberSection')}
          RightComponent={
            <NumberInput
              testID={'answersNumberInput'}
              number={answersAllowed}
              min={1}
              max={optionKeys.length}
              onChangeNumber={(number) => setAnswersAllowed(number)}
            />
          }
        />
        <Spacer size={32} />
        <SectionHeader sub title={t('createPoll.authorSection')} />
        <AuthorPicker
          testID={'authorPicker'}
          authors={
            lockedTeam
              ? [{ id: lockedTeam.id, authorType: 'team', team: lockedTeam }]
              : authors
          }
          selectedAuthorId={lockedTeam?.id || author}
          onSelectedAuthor={(authorId) => !lockedTeam && setAuthor(authorId)}
        />
        <Spacer size={32} />
        <SectionHeader sub title={t('createPoll.settingSection')} />
        <SettingPanel>
          <SettingToggleItem
            testID={'toggleRandomizeOptions'}
            text={t('createPoll.randomizeOptions')}
            checked={randomizeOptions}
            onToggleChange={setRandomizeOptions}
          />
          <SettingToggleItem
            testID={'toggleAllowRevote'}
            text={t('createPoll.allowRevote')}
            checked={allowRevote}
            onToggleChange={setAllowRevote}
          />
          <SettingToggleItem
            testID={'toggleAllowComments'}
            text={t('createPoll.allowComments')}
            checked={allowComments}
            onToggleChange={setAllowCommentss}
          />
          <SettingToggleItem
            testID={'toggleAnonymousVotes'}
            text={t('createPoll.anonymousVotes')}
            checked={anonymousVotes}
            onToggleChange={setAnonymousVotes}
          />
          <SettingToggleItem
            testID={'togglePrivateResults'}
            text={t('createPoll.privateResults')}
            checked={privateResults}
            onToggleChange={setPrivateResults}
          />
        </SettingPanel>
        <Spacer size={20} />
        <SectionHeader sub title={t('createPoll.deadlineSection')} />
        <SettingPanel>
          <SettingLabelItem
            testID={'buttonDueDate'}
            text={t('createPoll.dueDate')}
            labelText={
              dueDate ? formattedDate(dueDate.dateString, 'MMM d yyyy') : ''
            }
            onLabelPress={() => dateModalizeRef.current?.open()}
          />
          <SettingLabelItem
            testID={'buttonDueTime'}
            text={t('createPoll.dueTime')}
            labelText={dueTime ? formattedDate(dueTime, 'hh:mm a') : ''}
            onLabelPress={() => timeModalizeRef.current?.open()}
          />
        </SettingPanel>
        <Spacer size={120} />
        <Footer>
          <Button
            testID={'buttonCreatePoll'}
            state={createPollEnabled ? 'default' : 'disabled'}
            text={t('createPoll.createPoll')}
            size={'xl'}
            loading={loading}
            flex
            onPress={onCreate}
          />
        </Footer>
      </ScroolView>
      <ModalDatePicker
        testID={'datePicker'}
        ref={dateModalizeRef}
        title={t('createPoll.deadlineTime')}
        buttonText={t('createPoll.confirmDate')}
        date={dueDate}
        onConfirmDate={(dateObj) => {
          setDueDate(dateObj);
          dateModalizeRef.current?.close();
        }}
      />
      <ModalTimePicker
        testID={'timePicker'}
        ref={timeModalizeRef}
        title={t('createPoll.deadlineTime')}
        buttonText={t('createPoll.confirmTime')}
        time={dueTime}
        onConfirmTime={(time) => {
          setDueTime(time);
          timeModalizeRef.current?.close();
        }}
      />
    </ModalScreenLayout>
  );
}
