import React, { useState } from 'react';
import { Platform } from 'react-native';
import MarkdownEditor from '~/components/MarkdownEditor';
import TextEditor from '~/components/TextEditor';
import { DEFAULT_MAX_CHARS } from '~/data/constants';
import { ReportInstance } from '~/data/models/yearbook';
import { TabScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import { SettingsButtonIconIcon } from './style';

type ReportDetailLayoutProps = {
  reportInstance?: ReportInstance | null;
  saving?: boolean;
  viewOnly?: boolean;
  autoFocus?: boolean;
  allowImages?: boolean;
  onBack: () => void;
  onSetting: (text?: string) => void;
  onSave: (text: string) => void;
};

export default function ReportDetail({
  reportInstance,
  saving = false,
  viewOnly,
  autoFocus = true,
  allowImages,
  onBack,
  onSetting,
  onSave,
}: ReportDetailLayoutProps): JSX.Element {
  const initialMarkdownText = reportInstance?.text || '';
  const [markdown, setMarkdown] = useState(() => initialMarkdownText);

  return (
    <TabScreenLayout
      backButtonTestID={'buttonBack'}
      title={reportInstance?.title || t('screens.reportDetail')}
      RightComponent={
        !viewOnly ? (
          <SettingsButtonIconIcon
            testID={'buttonSetting'}
            onPress={() => {
              onSetting(markdown);
            }}
          />
        ) : null
      }
      onBackPress={onBack}
    >
      {Platform.OS === 'web' ? (
        <MarkdownEditor
          dom={{ scrollEnabled: true }}
          initialMarkdownText={initialMarkdownText}
          saving={saving}
          saveEnabled={markdown !== initialMarkdownText}
          disabled={viewOnly}
          allowImages={allowImages}
          maxCharCount={reportInstance?.maxChars || DEFAULT_MAX_CHARS}
          currentCharCount={
            markdown.replace(/<img>([\s\S]*?)<\/img>/g, '').length
          }
          onChangeMarkdown={setMarkdown}
          onEndEditing={() => onSave(markdown)}
        />
      ) : (
        <TextEditor
          inputTestID={'textEditor'}
          initialMarkdownText={initialMarkdownText}
          autoFocus={autoFocus}
          saving={saving}
          disabled={viewOnly}
          allowImages={allowImages}
          maxCharCount={reportInstance?.maxChars || DEFAULT_MAX_CHARS}
          onEndEditing={onSave}
          onChangeMarkdown={setMarkdown}
          minHeight={300}
        />
      )}
    </TabScreenLayout>
  );
}
