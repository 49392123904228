import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  CompositeNavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { NO_PAG_LIST_OFFSET } from '~/data/constants';
import useAppLocale from '~/data/hooks/useAppLocale';
import { DefaultAvatar } from '~/data/models/custom';
import { UPDATE_ME } from '~/data/operations/auth';
import { DEFAULT_AVATARS } from '~/data/operations/custom';
import {
  SET_USER_GROUP_AVATAR,
  UPDATE_USER_GROUP,
} from '~/data/operations/group';
import { UserType } from '~/data/types/graphql';
import { getLanguageFromLocale } from '~/data/utils';
import { MainTabParamList, RootStackParamList } from '~/navigation/types';
import { getImageFile } from '~/utils/helpers';
import { requestNotifications } from '~/utils/permissions';
import OnboardingLayout, { OnboardingLayoutProps } from './layout';

export type OnboardingType = OnboardingLayoutProps['onboardingType'];

type OnboardingNavProp = CompositeNavigationProp<
  StackNavigationProp<RootStackParamList, 'Onboarding'>,
  StackNavigationProp<MainTabParamList>
>;

type OnboardingRouteProp = RouteProp<RootStackParamList, 'Onboarding'>;

export default function Onboarding(): JSX.Element {
  const navigation = useNavigation<OnboardingNavProp>();
  const { params } = useRoute<OnboardingRouteProp>();

  const { onboardingType } = params;

  const isCoreFlow =
    onboardingType === 'core' || onboardingType === 'core_help';

  const { authGroupId, authUserGroupId, authUserGroup } = useAuth();

  const { locale } = useAppLocale();

  const authUser = authUserGroup?.user;

  const { data: defaultAvatarsData } = useQuery(DEFAULT_AVATARS, {
    skip: !isCoreFlow,
    variables: {
      first: NO_PAG_LIST_OFFSET,
    },
  });

  const defaultAvatars: DefaultAvatar[] =
    (defaultAvatarsData?.defaultAvatars?.edges.map(
      (edge) => edge?.node,
    ) as DefaultAvatar[]) || [];

  const [updateMe] = useMutation(UPDATE_ME);
  const [updateUserGroup] = useMutation(UPDATE_USER_GROUP);
  const [setUserGroupAvatar, { loading: setUserGroupAvatarLoading }] =
    useMutation(SET_USER_GROUP_AVATAR);

  const onUpdateGender = async (gender: string) => {
    try {
      if (authUser) {
        const { firstName, lastName, email, phone, phoneCountryCode } =
          authUser;
        const communicationLanguage = getLanguageFromLocale();
        const { data } = await updateMe({
          variables: {
            input: {
              firstName,
              lastName,
              email,
              phone,
              phoneCountryCode,
              gender,
              communicationLanguage,
            },
          },
        });
        const messages = data?.updateMe?.errors?.map(
          (error) => error?.messages[0],
        );
        const errorMessage = messages?.[0];
        if (errorMessage) {
          Snackbar.show(errorMessage);
          return;
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onUpdateRole = async (role: UserType) => {
    try {
      if (authGroupId && authUserGroup?.user?.id) {
        await updateUserGroup({
          variables: {
            input: {
              groupId: authGroupId,
              userId: authUserGroup.user.id,
              role: role || authUserGroup.role,
            },
          },
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onUpdateAvatar = async (
    uri: string | null,
    defaultAvatarId: string | null,
  ) => {
    try {
      const imageFile = uri ? await getImageFile(uri) : null;

      await setUserGroupAvatar({
        variables: {
          input: {
            userGroupId: authUserGroupId,
            avatar: imageFile,
            defaultAvatarId,
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return (
    <OnboardingLayout
      locale={locale}
      onboardingType={onboardingType}
      authUserGroup={authUserGroup}
      defaultAvatars={defaultAvatars}
      uploadingImage={setUserGroupAvatarLoading}
      onUpdateGender={onUpdateGender}
      onUpdateRole={onUpdateRole}
      onUpdateAvatar={onUpdateAvatar}
      onRequestNotification={() => requestNotifications(['alert', 'sound'])}
      onFinish={() => {
        if (onboardingType === 'core_help') {
          navigation.goBack();
        } else {
          navigation.navigate('PlanningStack', { screen: 'Planning' });
        }
      }}
    />
  );
}
