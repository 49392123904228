import React from 'react';
import { useTheme } from 'styled-components/native';
import Button from '~/components/Button';
import IconStyled from '~/components/IconStyled';
import { t } from '~/utils/i18n';
import { ASSETS_CREATION_STEP } from '../CreateTargeting/types';
import {
  Container,
  StepContainer,
  StepperPipeLine,
  MiddleStepperIcon,
  LeftStepperIconContainer,
  RightStepperIconContainer,
  ContinueButtonWrapper,
  BackButtonWrapper,
  ButtonWrapper,
  StepperIconText,
  MiddleStepperContainer,
  MiddleStepperIconText,
  StepperIconContainer,
  EmptyButtonPlaceholder,
} from './style';

type CreateAssetStepperProps = {
  assetsCreationStep: ASSETS_CREATION_STEP;
  onCancelAssetsCreate: () => void;
  setAssetsCreationStep: (page: ASSETS_CREATION_STEP) => void;
  onSubmitAsset: () => void;
  isContinueButtonDisabled: boolean;
};

const STEPPER_PROGRESS: Record<ASSETS_CREATION_STEP, number> = {
  ChannelSelection: 0,
  CreateTargeting: 50,
  CreateAssetsDesign: 100,
};

export default function CreateAssetStepper({
  assetsCreationStep,
  onCancelAssetsCreate,
  setAssetsCreationStep,
  onSubmitAsset,
  isContinueButtonDisabled,
}: CreateAssetStepperProps): JSX.Element {
  const theme = useTheme();
  const onContinue = () => {
    switch (assetsCreationStep) {
      case ASSETS_CREATION_STEP.ChannelSelection:
        setAssetsCreationStep(ASSETS_CREATION_STEP.CreateTargeting);
        break;
      case ASSETS_CREATION_STEP.CreateTargeting:
        setAssetsCreationStep(ASSETS_CREATION_STEP.CreateAssetsDesign);
        break;
      case ASSETS_CREATION_STEP.CreateAssetsDesign:
      default:
        onSubmitAsset();
        break;
    }
  };

  const onBack = () => {
    switch (assetsCreationStep) {
      case ASSETS_CREATION_STEP.CreateTargeting:
        setAssetsCreationStep(ASSETS_CREATION_STEP.ChannelSelection);
        break;
      case ASSETS_CREATION_STEP.CreateAssetsDesign:
        setAssetsCreationStep(ASSETS_CREATION_STEP.CreateTargeting);
        break;
      default:
        break;
    }
  };

  const ctaButtonText =
    assetsCreationStep === ASSETS_CREATION_STEP.CreateAssetsDesign
      ? t('createAssets.createDesign.cta')
      : t('createAssets.stepper.continue');

  const showBackButton =
    ASSETS_CREATION_STEP.ChannelSelection !== assetsCreationStep;

  return (
    <Container>
      <Button
        text={t('createAssets.stepper.cancel')}
        type={'secondary-base'}
        onPress={onCancelAssetsCreate}
        size={'md'}
      />
      <StepContainer>
        <LeftStepperIconContainer>
          <StepperIconContainer isActive={true}>
            <IconStyled name={'contents_primary'} size={16} />
          </StepperIconContainer>
          <StepperIconText isActive={true}>
            {t('createAssets.stepper.channel')}
          </StepperIconText>
        </LeftStepperIconContainer>

        <StepperPipeLine
          fillPercentage={STEPPER_PROGRESS[assetsCreationStep]}
          colors={[theme.color.base.c2, theme.color.base.c2]}
        />
        <MiddleStepperContainer>
          <MiddleStepperIcon
            isActive={
              assetsCreationStep === ASSETS_CREATION_STEP.CreateTargeting ||
              assetsCreationStep === ASSETS_CREATION_STEP.CreateAssetsDesign
            }
          >
            <IconStyled
              name={
                assetsCreationStep === ASSETS_CREATION_STEP.CreateTargeting ||
                assetsCreationStep === ASSETS_CREATION_STEP.CreateAssetsDesign
                  ? 'statistic_group_primary'
                  : 'statistic_base'
              }
              size={16}
            />
          </MiddleStepperIcon>
          <MiddleStepperIconText
            isActive={
              assetsCreationStep === ASSETS_CREATION_STEP.CreateTargeting ||
              assetsCreationStep === ASSETS_CREATION_STEP.CreateAssetsDesign
            }
          >
            {t('createAssets.stepper.targeting')}
          </MiddleStepperIconText>
        </MiddleStepperContainer>
        <RightStepperIconContainer>
          <StepperIconContainer
            isActive={
              assetsCreationStep === ASSETS_CREATION_STEP.CreateAssetsDesign
            }
          >
            <IconStyled
              name={
                assetsCreationStep === ASSETS_CREATION_STEP.CreateAssetsDesign
                  ? 'creative_primary'
                  : 'creative_base'
              }
              size={16}
            />
          </StepperIconContainer>
          <StepperIconText
            isActive={
              assetsCreationStep === ASSETS_CREATION_STEP.CreateAssetsDesign
            }
          >
            {t('createAssets.stepper.design')}
          </StepperIconText>
        </RightStepperIconContainer>
      </StepContainer>
      <ButtonWrapper>
        <BackButtonWrapper>
          {showBackButton ? (
            <Button
              text={t('createAssets.stepper.goBack')}
              type={'secondary-base'}
              onPress={onBack}
              size={'md'}
              minWidth={97}
            />
          ) : (
            <EmptyButtonPlaceholder />
          )}
        </BackButtonWrapper>

        <ContinueButtonWrapper>
          <Button
            testID={'createAssetModalContinue'}
            text={ctaButtonText}
            type={'secondary-contrast'}
            onPress={onContinue}
            size={'md'}
            flex={true}
            minWidth={104}
            state={isContinueButtonDisabled ? 'disabled' : 'default'}
          />
        </ContinueButtonWrapper>
      </ButtonWrapper>
    </Container>
  );
}
