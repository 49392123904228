import styled from 'styled-components/native';

export const Text = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 14px;
`;

export const PaginationContainer = styled.View`
  align-items: left;
  padding-top: 8px;
`;

export const PreviewContainer = styled.View`
  overflow: hidden;
  position: absolute;
  right: -83px;
  opacity: 0.5;
`;

export const PreviewWrapperContainer = styled.View``;

export const PreviewWrapper = styled.View`
  flex-direction: row;
`;

export const WebPreviewContainer = styled.View`
  background-color: ${({ theme }) => theme.color.base.c2};
  align-items: center;
  justify-content: center;
  padding: 8px;
`;

export const WebPreviewText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 14px;
`;
