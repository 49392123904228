import React, { useState, useEffect, useRef, RefObject } from 'react';
import { PDFDocument } from 'pdf-lib';
import { Platform, View } from 'react-native';
import {
  GestureHandlerRootView,
  PanGestureHandler,
  PanGestureHandlerGestureEvent,
  State,
} from 'react-native-gesture-handler';
import Pdf from 'react-native-pdf';
import { Pagination } from 'react-native-snap-carousel';
import { useTheme } from 'styled-components';
import { trackError } from '~/utils/sentry';
import {
  PreviewWrapperContainer,
  PaginationContainer,
  PreviewWrapper,
  WebPreviewContainer,
  WebPreviewText,
} from './style';

const PDFViewer = ({
  uri,
  hidePreview = false,
  height = 310,
  width = 220,
  name,
}: {
  uri: string;
  hidePreview?: boolean;
  width?: number;
  height?: number;
  name?: string | null;
}) => {
  const theme = useTheme();
  const [pageCount, setPageCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pdfRef = useRef<Pdf>(null);

  useEffect(() => {
    const fetchPageCount = async () => {
      try {
        const response = await fetch(uri);
        const arrayBuffer = await response.arrayBuffer();
        const pdfDoc = await PDFDocument.load(arrayBuffer);
        setPageCount(pdfDoc.getPageCount());
      } catch (e) {
        trackError(e);
      }
    };

    fetchPageCount();
  }, [uri]);

  const handlePageChange = (page: number) => {
    try {
      setCurrentPage(page);
      if (pdfRef.current && page !== currentPage) {
        pdfRef.current.setPage(page);
      }
    } catch (e) {
      trackError(e);
    }
  };

  const handleGesture = ({ nativeEvent }: PanGestureHandlerGestureEvent) => {
    const { state, translationX } = nativeEvent;
    const isEndOrFailed = state === State.END || state === State.FAILED;
    const isSwipeLeft = translationX < 0 && currentPage < pageCount;
    const isSwipeRight = translationX > 0 && currentPage > 1;

    if (!isEndOrFailed) return;

    let changePage = 0;
    if (isSwipeLeft) {
      changePage = 1;
    } else if (isSwipeRight) {
      changePage = -1;
    }

    handlePageChange(currentPage + changePage);
  };

  if (Platform.OS === 'web') {
    return (
      <WebPreviewContainer
        style={{
          height,
          width,
        }}
      >
        <WebPreviewText>
          {'PDF viewer not available on web ' + (name ?? '')}
        </WebPreviewText>
      </WebPreviewContainer>
    );
  }

  if (hidePreview) {
    return (
      <Pdf
        ref={pdfRef as RefObject<Pdf>}
        source={{ uri, cache: true }}
        trustAllCerts={false}
        singlePage
        onPageChanged={(page) => handlePageChange(page)}
        fitPolicy={0}
        enablePaging
        style={{ flex: 1, width, height }}
      />
    );
  }

  return (
    <GestureHandlerRootView>
      <PanGestureHandler onHandlerStateChange={handleGesture}>
        <PreviewWrapperContainer>
          <PreviewWrapper>
            <Pdf
              ref={pdfRef as RefObject<Pdf>}
              source={{ uri, cache: true }}
              trustAllCerts={false}
              singlePage
              onPageChanged={(page) => handlePageChange(page)}
              fitPolicy={0}
              enablePaging
              style={{ flex: 1, width, height }}
            />
            {currentPage < pageCount && (
              <View
                style={{
                  overflow: 'hidden',
                  position: 'absolute',
                  right: -83,
                  opacity: 0.5,
                }}
              >
                <Pdf
                  style={{ width, height }}
                  source={{ uri }}
                  page={currentPage + 1}
                  singlePage
                  fitPolicy={0}
                  enablePaging={true}
                />
              </View>
            )}
          </PreviewWrapper>
          <PaginationContainer>
            <Pagination
              dotsLength={pageCount}
              activeDotIndex={currentPage - 1}
              containerStyle={{
                backgroundColor: theme?.color.base.c2,
                paddingHorizontal: 5,
                paddingVertical: 5,
                borderRadius: 10,
              }}
              dotContainerStyle={{
                marginHorizontal: 2,
              }}
              dotStyle={{
                height: 6,
                width: 6,
                borderRadius: 5,
                backgroundColor: theme?.color.base.c6,
              }}
              inactiveDotStyle={{
                height: 6,
                width: 6,
                borderRadius: 5,
                backgroundColor: theme?.color.base.c4,
              }}
              inactiveDotOpacity={1.0}
              inactiveDotScale={1.0}
              tappableDots
            />
          </PaginationContainer>
        </PreviewWrapperContainer>
      </PanGestureHandler>
    </GestureHandlerRootView>
  );
};

export default PDFViewer;
