import React, { useRef, useState } from 'react';
import { Platform } from 'react-native';
import InputField, { InputFieldHandle } from '~/components/InputField';
import SchoolTypePicker from '~/components/SchoolTypePicker';
import SwitchSelector from '~/components/SwitchSelector';
import { GraduationYearsType } from '~/data/constants';
import { GroupIcon } from '~/data/models/custom';
import { GraduationType } from '~/data/models/group';
import { InputContent } from '~/screens/Group/CreateGroup/layout/style';
import { OnSetupGroupNextArgs } from '~/screens/Importer/SetupGroup';
import { IMPORTER_TOTAL_PROGRESS } from '~/screens/Importer/constants';
import ImporterLayout from '~/screens/Importer/layout';
import { t } from '~/utils/i18n';
import { getImageFromLibrary } from '~/utils/image-picker';
import OldImageGroupPicker from './OldImageGroupPicker';

type SetupGroupLayoutProps = {
  name: string;
  onNext: (args: OnSetupGroupNextArgs) => void;
  graduationTypes: GraduationType[];
  groupIcons: GroupIcon[];
  years: GraduationYearsType;
  loading: boolean;
  onBack: () => void;
};

export default function SetupGroup({
  name: userName,
  onBack,
  onNext,
  groupIcons,
  years,
  graduationTypes,
  loading,
}: SetupGroupLayoutProps): JSX.Element {
  const nameRef = useRef<InputFieldHandle>(null);

  const [name, setName] = useState<string>('');
  const [avatarUri, setAvatarUri] = useState<string | undefined>();
  const [groupIcon, setGroupIcon] = useState<GroupIcon | undefined>();
  const [graduationType, setGraduationType] = useState<
    GraduationType | undefined
  >(undefined);
  const [yearKey, setYearKey] = useState<string>(years[1]);

  const pickImage = async (event?: Event) => {
    if (Platform.OS !== 'web') {
      const imgUris = await getImageFromLibrary();

      const uri = imgUris?.[0];
      if (uri) {
        setAvatarUri(uri);
        setGroupIcon(undefined);
      }
    } else if (event) {
      const target = event.target as HTMLInputElement;
      const file = target.files?.[0];
      if (file) {
        const uri = URL.createObjectURL(file);
        setAvatarUri(uri);
        setGroupIcon(undefined);
      }
    }
  };

  const isValidInput = (): boolean => {
    return name != '' && graduationType != undefined;
  };

  const handleNext = () => {
    isValidInput() &&
      onNext &&
      onNext({
        groupName: name,
        graduationType: graduationType?.name || '',
        graduationYear: Number(yearKey),
        iconId: groupIcon ? groupIcon.id : null,
        avatar: avatarUri || null,
      });
  };

  const headerProps = {
    title: t('importer.hi', { name: userName }),
    descp: t('importer.setUpGroup'),
    total: IMPORTER_TOTAL_PROGRESS,
    progress: 2,
  };

  const submitButtonProps = {
    isValidInput,
    loading,
    onPress: handleNext,
    text: t('importer.next'),
  };

  return (
    <ImporterLayout
      submitButtonProps={submitButtonProps}
      headerProps={headerProps}
      onBack={onBack}
    >
      {groupIcons.length > 0 ? (
        <OldImageGroupPicker
          testID={'pickerImage'}
          selectedImageTestID={'selectedImage'}
          selectedImgUrl={avatarUri || groupIcon?.icon || undefined}
          groupIcons={groupIcons}
          onSelectedIcon={(icon) => {
            setAvatarUri(undefined);
            setGroupIcon(icon);
          }}
          onUploadOwnPhoto={pickImage}
        />
      ) : null}
      <InputContent>
        <InputField
          ref={nameRef}
          testID={'inputName'}
          iconName={'users-01'}
          label={t('createGroup.nameLabel')}
          placeholder={t('createGroup.namePlaceholder')}
          value={name}
          onChangeText={(value) => setName(value)}
          blurOnSubmit={false}
          returnKeyType={'next'}
        />
        <SchoolTypePicker
          testID={'pickerSchool'}
          toggleTestID={'buttonPickerToggle'}
          label={t('graduationType')}
          schoolTypes={graduationTypes}
          selectedSchoolType={graduationType}
          initialShowPicker={false}
          onSelectedSchoolType={(schoolType) =>
            setGraduationType(schoolType as GraduationType)
          }
        />
        <SwitchSelector
          testID={'pickerYear'}
          title={t('createGroup.graduationYear')}
          items={years.map((year) => ({ key: year, title: year }))}
          selectedItemKey={yearKey}
          onSelectedItem={setYearKey}
        />
      </InputContent>
    </ImporterLayout>
  );
}
