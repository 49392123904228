/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  fragment InstituteActivityType on InstituteActivityType {\n    year\n    status\n  }\n':
    types.InstituteActivityTypeFragmentDoc,
  '\n  fragment AdminSearchUser on SearchUserType {\n    id\n    firstName\n    lastName\n    phone\n    email\n    avatar\n    phoneCountryCode\n    groups {\n      ...AdminSearchGroup\n    }\n  }\n':
    types.AdminSearchUserFragmentDoc,
  '\n  fragment AdminSearchGroup on SearchGroupType {\n    id\n    name\n    graduationYear\n    type\n    avatar\n    institute {\n      ...AdminSearchInstitute\n    }\n  }\n':
    types.AdminSearchGroupFragmentDoc,
  '\n  fragment AdminSearchInstitute on SearchInstituteType {\n    id\n    name\n    internalId\n    country\n    city\n    zip\n    type\n  }\n':
    types.AdminSearchInstituteFragmentDoc,
  '\n  query AdminSearch($searchString: String!) {\n    adminSearchResults(custom: $searchString) {\n      users {\n        ...AdminSearchUser\n      }\n      groups {\n        ...AdminSearchGroup\n      }\n      institutes {\n        ...AdminSearchInstitute\n      }\n    }\n  }\n':
    types.AdminSearchDocument,
  '\n  fragment AdminGroupMember on GroupMemberNode {\n    id\n    firstName\n    lastName\n    avatar\n    email\n    phone\n    phoneCountryCode\n    role\n    status\n    user {\n      id\n    }\n    teams {\n      id\n      name\n      avatar\n      icon {\n        icon\n      }\n      color {\n        hex\n      }\n      group {\n        id\n      }\n    }\n  }\n':
    types.AdminGroupMemberFragmentDoc,
  '\n  fragment AdminGroupInstitute on InstituteNode {\n    name\n    id\n    internalId\n    type {\n      name\n      graduationTypes {\n        id\n        name\n      }\n    }\n    country\n    street\n    zip\n    city\n  }\n':
    types.AdminGroupInstituteFragmentDoc,
  '\n  fragment AdminGroup on GroupNode {\n    id\n    name\n    avatar\n    created\n    token\n    isActive\n    isImported\n    type {\n      id\n      name\n    }\n    year\n    allMembers {\n      ...AdminGroupMember\n    }\n    institute {\n      ...AdminGroupInstitute\n    }\n  }\n':
    types.AdminGroupFragmentDoc,
  '\n  query AdminGetGroup($id: ID!) {\n    group(id: $id) {\n      ...AdminGroup\n    }\n  }\n':
    types.AdminGetGroupDocument,
  '\n  mutation AdminToggleGroupActive($input: ToggleIsActiveGroupInput!) {\n    toggleIsActiveGroup(input: $input) {\n      group {\n        ...AdminGroup\n      }\n    }\n  }\n':
    types.AdminToggleGroupActiveDocument,
  '\n  mutation AdminMoveGroupToInstitute($input: MoveGroupToInstituteInput!) {\n    moveGroupToInstitute(input: $input) {\n      group {\n        ...AdminGroup\n      }\n    }\n  }\n':
    types.AdminMoveGroupToInstituteDocument,
  '\n  fragment AdminUserTeams on TeamNode {\n    id\n    name\n    avatar\n    icon {\n      icon\n    }\n    color {\n      hex\n    }\n    group {\n      id\n    }\n  }\n':
    types.AdminUserTeamsFragmentDoc,
  '\n  fragment AdminUserGroups on GroupNode {\n    id\n    name\n    avatar\n    year\n    icon {\n      icon\n    }\n    institute {\n      id\n      name\n      internalId\n      country\n    }\n    type {\n      name\n    }\n    users {\n      id\n      status\n      user {\n        id\n      }\n    }\n    teams {\n      id\n      name\n    }\n  }\n':
    types.AdminUserGroupsFragmentDoc,
  '\n  fragment AdminUser on UserNode {\n    id\n    firstName\n    lastName\n    email\n    phoneCountryCode\n    phone\n    gender\n    isActive\n    optIn\n    created\n    isImported\n    isAgent\n    isSuperuser\n    avatar\n    groups {\n      ...AdminUserGroups\n    }\n    teams {\n      ...AdminUserTeams\n    }\n  }\n':
    types.AdminUserFragmentDoc,
  '\n  query AdminGetUser($id: ID!) {\n    user(id: $id) {\n      ...AdminUser\n    }\n  }\n':
    types.AdminGetUserDocument,
  '\n  query AdminGetGradooStaff(\n    $offset: Int\n    $after: String\n    $first: Int\n    $searchNameEmail: String\n  ) {\n    users(\n      offset: $offset\n      after: $after\n      first: $first\n      isStaff: true\n      searchNameEmail: $searchNameEmail\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...AdminUser\n        }\n      }\n      totalCount\n    }\n  }\n':
    types.AdminGetGradooStaffDocument,
  '\n  query AdminGetUsers(\n    $offset: Int\n    $after: String\n    $first: Int\n    $firstName: String\n    $lastName: String\n    $email: String\n    $isAgent: Boolean\n    $isSuperuser: Boolean\n    $isStaff: Boolean\n    $searchNameEmail: String\n  ) {\n    users(\n      offset: $offset\n      after: $after\n      first: $first\n      firstName: $firstName\n      lastName: $lastName\n      email: $email\n      isAgent: $isAgent\n      isSuperuser: $isSuperuser\n      isStaff: $isStaff\n      searchNameEmail: $searchNameEmail\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...AdminUser\n        }\n      }\n      totalCount\n    }\n  }\n':
    types.AdminGetUsersDocument,
  '\n  mutation AdminUpdateUser($input: AdminUpdateUserInput!) {\n    adminUpdateUser(input: $input) {\n      user {\n        ...AdminUser\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n':
    types.AdminUpdateUserDocument,
  '\n  mutation AdminUpgradeUser($input: AdminUpgradeUserInput!) {\n    adminUpgradeUser(input: $input) {\n      user {\n        ...AdminUser\n      }\n    }\n  }\n':
    types.AdminUpgradeUserDocument,
  '\n  mutation AdminDowngradeUser($input: AdminDowngradeUserInput!) {\n    adminDowngradeUser(input: $input) {\n      user {\n        ...AdminUser\n      }\n    }\n  }\n':
    types.AdminDowngradeUserDocument,
  '\n  mutation AdminClearUserFlags($input: AdminClearUserFlagsInput!) {\n    adminClearUserFlags(input: $input) {\n      user {\n        ...AdminUser\n      }\n    }\n  }\n':
    types.AdminClearUserFlagsDocument,
  '\n  mutation AdminForgetPasswordLink($input: AdminForgetPasswordLinkInput!) {\n    adminForgetPasswordLink(input: $input) {\n      forgetPasswordLink\n    }\n  }\n':
    types.AdminForgetPasswordLinkDocument,
  '\n  mutation AdminAddUserToTeam($input: AdminAddUserToTeamInput!) {\n    adminAddUserToTeam(input: $input) {\n      user {\n        ...AdminUser\n      }\n    }\n  }\n':
    types.AdminAddUserToTeamDocument,
  '\n  mutation AdminDeleteUser($input: AdminDeleteUserInput!) {\n    adminDeleteUser(input: $input) {\n      user {\n        ...AdminUser\n      }\n    }\n  }\n':
    types.AdminDeleteUserDocument,
  '\n  fragment AdminInstituteGroup on GroupNode {\n    id\n    name\n    type {\n      name\n    }\n    year\n    avatar\n    icon {\n      icon\n    }\n    users {\n      id\n    }\n    institute {\n      country\n      internalId\n    }\n  }\n':
    types.AdminInstituteGroupFragmentDoc,
  '\n  fragment AdminInstitute on InstituteNode {\n    id\n    name\n    street\n    internalId\n    city\n    zip\n    country\n    website\n    type {\n      id\n      name\n    }\n    marketProfile {\n      id\n      name\n    }\n    groups {\n      ...AdminInstituteGroup\n    }\n    instituteActivity {\n      ...InstituteActivityType\n    }\n  }\n':
    types.AdminInstituteFragmentDoc,
  '\n  query AdminGetInstitute($id: ID!) {\n    institute(id: $id) {\n      ...AdminInstitute\n    }\n  }\n':
    types.AdminGetInstituteDocument,
  '\n  mutation AdminUpdateInstitute($input: AdminUpdateInstituteInput!) {\n    adminUpdateInstitute(input: $input) {\n      institute {\n        ...AdminInstitute\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n':
    types.AdminUpdateInstituteDocument,
  '\n  fragment InstituteTargeting on InstituteTargetingNode {\n    id\n    location\n    activity\n  }\n':
    types.InstituteTargetingFragmentDoc,
  '\n  query InstitutesTargeting($targetingFilters: [TargetingFilterInput]) {\n    institutesTargeting(targetingFilters: $targetingFilters) {\n      usersCount\n      activeGroupsCount\n      inactiveGroupsCount\n      institutes {\n        ...InstituteTargeting\n      }\n    }\n  }\n':
    types.InstitutesTargetingDocument,
  '\n  fragment AuthUser on UserNode {\n    ...User\n    email\n    phoneCountryCode\n    phone\n    emailValidated\n    communicationLanguage\n    isSuperuser\n    countryOfResidence\n    inviteCode\n    externalTokens {\n      intercom\n    }\n  }\n':
    types.AuthUserFragmentDoc,
  '\n  fragment AuthUserGroup on UserGroupNode {\n    ...UserGroup\n    user {\n      ...AuthUser\n    }\n    isYearbookTeamMember\n    isCoreTeamMember\n  }\n':
    types.AuthUserGroupFragmentDoc,
  '\n  query Me {\n    me {\n      ...AuthUser\n    }\n  }\n': types.MeDocument,
  '\n  query AuthUserGroup($id: ID!) {\n    userGroup(id: $id) {\n      ...AuthUserGroup\n    }\n  }\n':
    types.AuthUserGroupDocument,
  '\n  mutation CreateUser($input: CreateUserInput!) {\n    createUser(input: $input) {\n      user {\n        ...AuthUser\n      }\n    }\n  }\n':
    types.CreateUserDocument,
  '\n  mutation CreateToken($input: ObtainJSONWebTokenInput!) {\n    createToken(input: $input) {\n      token\n      refreshToken\n    }\n  }\n':
    types.CreateTokenDocument,
  '\n  mutation UpdateMe($input: UpdateMeInput!) {\n    updateMe(input: $input) {\n      errors {\n        field\n        messages\n      }\n      user {\n        ...AuthUser\n      }\n      token\n      refreshToken\n    }\n  }\n':
    types.UpdateMeDocument,
  '\n  mutation ForgetPassword($input: ForgetPasswordInput!) {\n    forgetPassword(input: $input) {\n      errors {\n        field\n        messages\n      }\n    }\n  }\n':
    types.ForgetPasswordDocument,
  '\n  mutation ResetPassword($input: ResetPasswordInput!) {\n    resetPassword(input: $input) {\n      errors {\n        field\n        messages\n      }\n    }\n  }\n':
    types.ResetPasswordDocument,
  '\n  mutation DeleteUser($input: DeleteUserInput!) {\n    deleteUser(input: $input) {\n      user {\n        ...AuthUser\n      }\n    }\n  }\n':
    types.DeleteUserDocument,
  '\n  query UserExists(\n    $email: String!\n    $phoneCountryCode: String!\n    $phone: String!\n  ) {\n    userExists(\n      email: $email\n      phoneCountryCode: $phoneCountryCode\n      phone: $phone\n    ) {\n      emailExists\n      phoneExists\n    }\n  }\n':
    types.UserExistsDocument,
  '\n  fragment EventAttendee on EventAttendeeNode {\n      id\n      user {\n        id\n        firstName\n        lastName\n        avatar\n        email\n      }\n    }\n':
    types.EventAttendeeFragmentDoc,
  '\n  fragment Event on EventNode {\n    title\n    id\n    location\n    companyName\n    companyLogo\n    bannerImage\n    isSponsored\n    companyName\n    eventAuthor\n    authorUser {\n      id\n      firstName\n      lastName \n      avatar\n      email\n    }\n    authorGroup {\n      id\n      name\n      avatar\n    }\n    authorTeam {\n      id\n      name\n      avatar\n    }\n    authorType\n    eventType\n    description\n    startDateTime\n    endDateTime\n    isAllDay\n    isJoinable\n    isPublic\n    attendees {\n      ...EventAttendee\n    }\n  }\n':
    types.EventFragmentDoc,
  '\n  fragment ExternalEventAttendee on ExternalEventAttendeeNode {\n    id\n    attendee {\n      email\n    }\n    platform\n    eventId\n  }\n':
    types.ExternalEventAttendeeFragmentDoc,
  '\n  query Events($after: String, $first: Int, $fromDate: Date, $isPublic: Boolean) {\n    events(after: $after, first: $first, fromDate: $fromDate, isPublic: $isPublic) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Event\n        }\n      }\n    }\n  }\n':
    types.EventsDocument,
  '\n  query Event($id: ID!) {\n    event(id: $id) {\n      ...Event\n    }\n  }\n':
    types.EventDocument,
  '\n  mutation CreateEvent($input: CreateEventInput!) {\n    createEvent(input: $input) {\n      event {\n        ...Event\n      }\n\n      errors {\n        field\n        messages\n      }\n    }\n  }\n':
    types.CreateEventDocument,
  '\n  mutation JoinEvent($input: JoinEventInput!) {\n    joinEvent(input: $input) {\n      attendee {\n        id\n        event {\n          ...Event\n        }\n        user {\n          id\n        }\n      }\n    }\n  }\n':
    types.JoinEventDocument,
  '\n  mutation LeaveEvent($input: LeaveEventInput!) {\n    leaveEvent(input: $input) {\n      success\n    }\n  }\n':
    types.LeaveEventDocument,
  '\n  mutation EditEvent($input: EditEventInput!) {\n    editEvent(input: $input) {\n      event {\n        ...Event\n      }\n\n      errors {\n        field\n        messages\n      }\n    }\n  }\n':
    types.EditEventDocument,
  '\n  mutation DeleteEvent($input: DeleteEventInput!) {\n    deleteEvent(input: $input) {\n      success\n    }\n  }\n':
    types.DeleteEventDocument,
  '\n  mutation SetEventBanner($input: SetEventBannerInput!) {\n    setEventBanner(input: $input) {\n      event {\n        ...Event\n      }\n    }\n  }\n':
    types.SetEventBannerDocument,
  '\n  mutation SaveExternalEvent($input: SaveExternalEventInput!) {\n    saveExternalEvent(input: $input) {\n      externalEvent {\n        ...ExternalEventAttendee\n      }\n      success\n    }\n  }\n':
    types.SaveExternalEventDocument,
  '\n  mutation RemoveExternalEvent($input: RemoveExternalEventInput!) {\n    removeExternalEvent(input: $input) {\n      externalEvent {\n        ...ExternalEventAttendee\n      }\n      success\n    }\n  }\n':
    types.RemoveExternalEventDocument,
  '\n  mutation ToggleExternalEventSave($input: ToggleExternalEventSaveInput!) {\n    toggleExternalEventSave(input: $input) {\n      externalEvent {\n        ...ExternalEventAttendee\n      }\n      success\n    }\n  }\n':
    types.ToggleExternalEventSaveDocument,
  '\n  fragment CityLocation on CityLocationNode {\n    region\n    country\n  }\n':
    types.CityLocationFragmentDoc,
  '\n  fragment Campaign on CampaignNode {\n    id\n    name\n    utm\n    startDate\n    endDate\n    customer\n    status\n    orderId\n    type\n    isDeleted\n  }\n':
    types.CampaignFragmentDoc,
  '\n  fragment Asset on AssetNode {\n    id\n    assetId\n    channelType\n    name\n    channelData {\n      trackingUrl\n      imageLarge\n      imageSmall\n    }\n    targetingConfig\n    startDate\n    endDate\n    visibility\n    targetingFilters {\n      location {\n        country\n        zip\n        region\n        city\n        radius\n      }\n      user {\n        gender\n        registration {\n          date\n          operator\n        }\n        userIds\n      }\n      group {\n        graduationType\n        instituteType\n        graduationYear\n        members {\n          value\n          operator\n        }\n        groupIds\n        teams\n      }\n      type\n      operator\n    }\n    totalViews\n    totalClicks\n    conversionRate\n  }\n':
    types.AssetFragmentDoc,
  '\n  fragment MatchedAsset on MatchedAssetNode {\n    id\n    assetId\n    channelType\n    name\n    channelData {\n      trackingUrl\n      imageLarge\n      imageSmall\n    }\n    targetingConfig\n    startDate\n    endDate\n    visibility\n    isActive\n    targetingFilters {\n      location {\n        country\n        zip\n        region\n        city\n        radius\n      }\n      user {\n        gender\n        registration {\n          date\n          operator\n        }\n        userIds\n      }\n      group {\n        graduationType\n        instituteType\n        graduationYear\n        members {\n          value\n          operator\n        }\n        groupIds\n        teams\n      }\n      type\n      operator\n    }\n  }\n':
    types.MatchedAssetFragmentDoc,
  '\n  fragment CampaignDetails on CampaignNode {\n    id\n    name\n    utm\n    startDate\n    endDate\n    type\n    customer\n    orderId\n    totalViews\n    totalClicks\n    status\n    assets {\n      ...Asset\n    }\n    conversionRate\n  }\n':
    types.CampaignDetailsFragmentDoc,
  '\n  fragment CountryData on CountryData {\n    country\n    counts {\n      date\n      count\n    }\n  }\n':
    types.CountryDataFragmentDoc,
  '\n  fragment Statistics on StatisticsNode {\n    institutesCount\n    usersCount\n    activeGroupsCount\n    inactiveGroupsCount\n  }\n':
    types.StatisticsFragmentDoc,
  '\n  query Campaigns(\n    $offset: Int\n    $before: String\n    $after: String\n    $first: Int\n    $last: Int\n    $searchFilter: String\n    $orderBy: String\n  ) {\n    campaigns(\n      offset: $offset\n      before: $before\n      after: $after\n      first: $first\n      last: $last\n      searchFilter: $searchFilter\n      orderBy: $orderBy\n    ) {\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n      edges {\n        cursor\n        node {\n          ...Campaign\n        }\n      }\n      totalCount\n    }\n  }\n':
    types.CampaignsDocument,
  '\n  query Campaign($id: ID!) {\n    campaign(id: $id) {\n      ...CampaignDetails\n    }\n  }\n':
    types.CampaignDocument,
  '\n  mutation CreateBannerAsset($input: CreateBannerAssetInput!) {\n    createBannerAsset(input: $input) {\n      asset {\n        ...Asset\n      }\n    }\n  }\n':
    types.CreateBannerAssetDocument,
  '\n  mutation CreateCampaign($input: CreateCampaignInput!) {\n    createCampaign(input: $input) {\n      campaign {\n        ...Campaign\n      }\n    }\n  }\n':
    types.CreateCampaignDocument,
  '\n  query Asset($id: ID!) {\n    asset(id: $id) {\n      ...Asset\n    }\n  }\n':
    types.AssetDocument,
  '\n  mutation EditBannerAsset($input: EditBannerAdAssetInput!) {\n    editBannerAdAsset(input: $input) {\n      asset {\n        ...Asset\n      }\n    }\n  }\n':
    types.EditBannerAssetDocument,
  '\n  mutation DuplicateBannerAsset($input: DuplicateAssetInput!) {\n    duplicateAsset(input: $input) {\n      newAsset {\n        ...Asset\n      }\n    }\n  }\n':
    types.DuplicateBannerAssetDocument,
  '\n  mutation DeleteBannerAsset($input: DeleteAssetInput!) {\n    deleteAsset(input: $input) {\n      success\n      assetId\n    }\n  }\n':
    types.DeleteBannerAssetDocument,
  '\n  query BestMatchAsset($channelType: ChannelType, $userId: String) {\n    bestMatchAssets(channelType: $channelType, userId: $userId) {\n      ...MatchedAsset\n    }\n  }\n':
    types.BestMatchAssetDocument,
  '\n  mutation CreateAssetEvent($input: CreateAssetEventInput!) {\n    createAssetEvent(input: $input) {\n      success\n    }\n  }\n':
    types.CreateAssetEventDocument,
  '\n  query Regions($country: String) {\n    regions(country: $country) {\n      ...CityLocation\n    }\n  }\n':
    types.RegionsDocument,
  '\n  mutation EditCampaign($input: EditCampaignInput!) {\n    editCampaign(input: $input) {\n      campaign {\n        ...Campaign\n      }\n    }\n  }\n':
    types.EditCampaignDocument,
  '\n  mutation DeleteCampaign($input: DeleteCampaignInput!) {\n    deleteCampaign(input: $input) {\n      success\n    }\n  }\n':
    types.DeleteCampaignDocument,
  '\n  query Statistics($filter: DashboardFilterInput) {\n    statistics(filter: $filter) {\n      ...Statistics\n    }\n  }\n':
    types.StatisticsDocument,
  '\n  query Signups($filter: DashboardFilterInput) {\n    signups(filter: $filter) {\n      ...CountryData\n    }\n  }\n':
    types.SignupsDocument,
  '\n  query KPIs($filter: DashboardFilterInput) {\n    kpis(filter: $filter) {\n      averageStudentsPerGroup\n      groupTimeToActive\n      profileQuestionsPerYear {\n        year\n        average\n        instances\n        groups\n      }\n      quotesPerGroupPerYear {\n        year\n        average\n        instances\n        groups\n      }\n      rankingQuestionsPerGroupPerYear {\n        year\n        average\n        instances\n        groups\n      }\n      albumsPerGroupPerYear {\n        year\n        average\n        instances\n        groups\n      }\n      photosPerGroupPerYear {\n        year\n        average\n        instances\n        groups\n      }\n    }\n  }\n':
    types.KPIsDocument,
  '\n  fragment Color on ColorNode {\n    id\n    hex\n  }\n':
    types.ColorFragmentDoc,
  '\n  fragment Icon on IconNode {\n    id\n    icon\n  }\n':
    types.IconFragmentDoc,
  '\n  fragment DefaultAvatar on DefaultAvatarNode {\n    id\n    avatar\n  }\n':
    types.DefaultAvatarFragmentDoc,
  '\n  fragment GroupIcon on GroupIconNode {\n    id\n    icon\n  }\n':
    types.GroupIconFragmentDoc,
  '\n  query Colors($after: String, $first: Int!) {\n    colors(after: $after, first: $first) {\n      edges {\n        node {\n          ...Color\n        }\n      }\n    }\n  }\n':
    types.ColorsDocument,
  '\n  query Icons($after: String, $first: Int!) {\n    icons(after: $after, first: $first) {\n      edges {\n        node {\n          ...Icon\n        }\n      }\n    }\n  }\n':
    types.IconsDocument,
  '\n  query DefaultAvatars($after: String, $first: Int!) {\n    defaultAvatars(after: $after, first: $first) {\n      edges {\n        node {\n          ...DefaultAvatar\n        }\n      }\n    }\n  }\n':
    types.DefaultAvatarsDocument,
  '\n  query GroupIcons($after: String, $first: Int!) {\n    groupIcons(after: $after, first: $first) {\n      edges {\n        node {\n          ...GroupIcon\n        }\n      }\n    }\n  }\n':
    types.GroupIconsDocument,
  '\n  fragment GraduationType on GraduationTypeNode {\n    id\n    name\n  }\n':
    types.GraduationTypeFragmentDoc,
  '\n  fragment Group on GroupNode {\n    id\n    name\n    avatar\n    icon {\n      ...GroupIcon\n    }\n    type {\n      ...GraduationType\n    }\n    year\n    token\n    created\n    institute {\n      id\n      internalId\n      type {\n        id\n        name\n      }\n      country\n      name\n      street\n      zip\n      city\n    }\n  }\n':
    types.GroupFragmentDoc,
  '\n  fragment UserGroup on UserGroupNode {\n    id\n    role\n    avatar\n    defaultAvatar {\n      ...DefaultAvatar\n    }\n    status\n    user {\n      ...User\n    }\n    created\n  }\n':
    types.UserGroupFragmentDoc,
  '\n  fragment GroupUser on UserGroupNode {\n    ...UserGroup\n    group {\n      ...Group\n    }\n  }\n':
    types.GroupUserFragmentDoc,
  '\n  fragment GroupSample on GroupNode {\n    ...Group\n    usersCount\n    sampleMembers {\n      ...UserGroup\n    }\n  }\n':
    types.GroupSampleFragmentDoc,
  '\n  fragment GroupDetail on GroupNode {\n    ...Group\n    usersCount\n    institute {\n      ...Institute\n    }\n  }\n':
    types.GroupDetailFragmentDoc,
  '\n  query GraduationTypes($after: String, $first: Int!) {\n    graduationTypes(after: $after, first: $first) {\n      edges {\n        node {\n          ...GraduationType\n        }\n      }\n    }\n  }\n':
    types.GraduationTypesDocument,
  '\n  query GraduationTypesCountry($id: String, $name: String, $country: String) {\n    graduationTypesCountry(id: $id, name: $name, country: $country) {\n      id\n      name\n      country\n    }\n  }\n':
    types.GraduationTypesCountryDocument,
  '\n  fragment YearbookStatistics on YearbookStatisticsNode {\n    profilePagesFilledIn\n    usersWithQuote\n    lastDeadline\n    contentModules\n    estimatedPages\n    imagesUploaded\n    wordCount\n  }\n':
    types.YearbookStatisticsFragmentDoc,
  '\n  fragment GroupStatistics on GroupNode {\n    id\n    yearbookStatistics {\n      ...YearbookStatistics\n    }\n  }\n':
    types.GroupStatisticsFragmentDoc,
  '\n  query Groups(\n    $after: String\n    $first: Int!\n    $name: String\n    $type: ID\n    $year: Int\n    $institute: ID\n    $marketProfile: ID\n  ) {\n    groups(\n      after: $after\n      first: $first\n      name: $name\n      type: $type\n      year: $year\n      institute: $institute\n      marketProfile: $marketProfile\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Group\n        }\n      }\n    }\n  }\n':
    types.GroupsDocument,
  '\n  query Group($id: ID!) {\n    group(id: $id) {\n      ...GroupDetail\n      teams {\n        id\n        name\n      }\n    }\n  }\n':
    types.GroupDocument,
  '\n  query GroupSample($id: ID!) {\n    group(id: $id) {\n      ...GroupSample\n    }\n  }\n':
    types.GroupSampleDocument,
  '\n  query GroupStatistics($id: ID!) {\n    group(id: $id) {\n      ...GroupStatistics\n    }\n  }\n':
    types.GroupStatisticsDocument,
  '\n  mutation AddUserToGroup($input: AddUserToGroupInput!) {\n    addUserToGroup(input: $input) {\n      userGroup {\n        ...UserGroup\n      }\n    }\n  }\n':
    types.AddUserToGroupDocument,
  '\n  mutation AcceptUserInGroup($input: AcceptUserInGroupInput!) {\n    acceptUserInGroup(input: $input) {\n      userGroup {\n        ...UserGroup\n      }\n    }\n  }\n':
    types.AcceptUserInGroupDocument,
  '\n  mutation RemoveUserFromGroup($input: RemoveUserFromGroupInput!) {\n    removeUserFromGroup(input: $input) {\n      userGroup {\n        ...UserGroup\n      }\n    }\n  }\n':
    types.RemoveUserFromGroupDocument,
  '\n  query UserGroups(\n    $after: String\n    $first: Int!\n    $group: ID\n    $status: GroupsUserGroupStatusChoices\n  ) {\n    userGroups(after: $after, first: $first, group: $group, status: $status) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...UserGroup\n        }\n      }\n    }\n  }\n':
    types.UserGroupsDocument,
  '\n  query GroupUsers(\n    $after: String\n    $first: Int!\n    $user: ID\n    $status: GroupsUserGroupStatusChoices\n  ) {\n    userGroups(after: $after, first: $first, user: $user, status: $status) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...GroupUser\n        }\n      }\n    }\n  }\n':
    types.GroupUsersDocument,
  '\n  query UserGroup($id: ID!) {\n    userGroup(id: $id) {\n      ...UserGroup\n    }\n  }\n':
    types.UserGroupDocument,
  '\n  mutation CreateGroup($input: CreateGroupInput!) {\n    createGroup(input: $input) {\n      group {\n        ...Group\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n':
    types.CreateGroupDocument,
  '\n  mutation UpdateGroup($input: UpdateGroupInput!) {\n    updateGroup(input: $input) {\n      group {\n        ...Group\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n':
    types.UpdateGroupDocument,
  '\n  mutation SetGroupAvatar($input: SetGroupAvatarInput!) {\n    setGroupAvatar(input: $input) {\n      group {\n        ...Group\n      }\n    }\n  }\n':
    types.SetGroupAvatarDocument,
  '\n  mutation SetUserGroupAvatar($input: SetUserGroupAvatarInput!) {\n    setUserGroupAvatar(input: $input) {\n      userGroup {\n        ...UserGroup\n      }\n    }\n  }\n':
    types.SetUserGroupAvatarDocument,
  '\n  mutation UpdateUserGroup($input: UpdateUserGroupInput!) {\n    updateUserGroup(input: $input) {\n      userGroup {\n        ...UserGroup\n      }\n    }\n  }\n':
    types.UpdateUserGroupDocument,
  '\n  mutation ImportToErp($input: ImportToErpInput!) {\n    importToErp(input: $input) {\n      success\n    }\n  }\n':
    types.ImportToErpDocument,
  '\n  fragment LeaderboardUser on LeaderboardUserNode {\n    user {\n      ...User\n    }\n    points\n    position\n  }\n':
    types.LeaderboardUserFragmentDoc,
  '\n  query LeaderboardUsers(\n    $before: String\n    $after: String\n    $first: Int\n    $last: Int\n    $user: ID\n  ) {\n    leaderboardUsers(\n      before: $before\n      after: $after\n      first: $first\n      last: $last\n      user: $user\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...LeaderboardUser\n        }\n      }\n    }\n  }\n':
    types.LeaderboardUsersDocument,
  '\n  mutation ImportFromAbihome(\n    $email: String!\n    $password: String!\n    $groupName: String!\n    $graduationType: String!\n    $graduationYear: Int!\n    $approved: Boolean\n    $iconId: ID\n    $avatar: Upload\n  ) {\n    importAbihome(\n      email: $email\n      password: $password\n      groupName: $groupName\n      graduationType: $graduationType\n      graduationYear: $graduationYear\n      approved: $approved\n      iconId: $iconId\n      avatar: $avatar\n    ) {\n      ok\n      userNumber\n      error\n    }\n  }\n':
    types.ImportFromAbihomeDocument,
  '\n  fragment InstituteType on InstituteTypeNode {\n    id\n    name\n    country\n    graduationTypes {\n      id\n      name\n    }\n  }\n':
    types.InstituteTypeFragmentDoc,
  '\n  fragment Institute on InstituteNode {\n    id\n    internalId\n    name\n    street\n    zip\n    city\n    website\n    type {\n      ...InstituteType\n    }\n    country\n  }\n':
    types.InstituteFragmentDoc,
  '\n  query InstituteTypes(\n    $after: String\n    $first: Int!\n    $name: String\n    $country: InstitutesInstituteCountryChoices\n  ) {\n    instituteTypes(\n      after: $after\n      first: $first\n      name: $name\n      country: $country\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...InstituteType\n        }\n      }\n    }\n  }\n':
    types.InstituteTypesDocument,
  '\n  query Institutes(\n    $after: String\n    $first: Int!\n    $name: String\n    $city: String\n    $type: ID\n    $country: InstitutesInstituteCountryChoices\n    $internalId: String\n    $offset: Int\n  ) {\n    institutes(\n      after: $after\n      first: $first\n      name: $name\n      city: $city\n      type: $type\n      country: $country\n      internalId: $internalId\n      offset: $offset\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Institute\n        }\n      }\n      totalCount\n    }\n  }\n':
    types.InstitutesDocument,
  '\n  query Institute($id: ID!) {\n    institute(id: $id) {\n      ...Institute\n    }\n  }\n':
    types.InstituteDocument,
  '\n  mutation RequestMissingInstitute($input: RequestMissingInstituteInput!) {\n    requestMissingInstitute(input: $input) {\n      responseCode\n      message\n    }\n  }\n':
    types.RequestMissingInstituteDocument,
  '\n  query CitiesZip($query: String, $country: String) {\n    citiesZip(query: $query, country: $country) {\n      name\n    }\n  }\n':
    types.CitiesZipDocument,
  '\n  mutation CreateInstitute($input: CreateInstituteInput!) {\n    createInstitute(input: $input) {\n      responseCode\n      message\n      institute {\n        ...Institute\n      }\n    }\n  }\n':
    types.CreateInstituteDocument,
  '\n  fragment InstituteRequest on InstituteRequestNode {\n    id\n    name\n    street\n    zip\n    city\n    type {\n      id\n      name\n    }\n    country\n    user {\n      id\n      firstName\n      lastName\n      email\n      avatar\n    }\n    status\n    website\n    created\n  }\n':
    types.InstituteRequestFragmentDoc,
  '\n  query InstituteRequests(\n    $offset: Int\n    $before: String\n    $after: String\n    $first: Int\n    $last: Int\n    $name: String\n    $status: Status\n    $type: ID\n    $street: String\n    $country: InstitutesInstituteRequestCountryChoices\n    $zip: String\n    $website: String\n  ) {\n    instituteRequests(\n      offset: $offset\n      before: $before\n      after: $after\n      first: $first\n      last: $last\n      name: $name\n      status: $status\n      type: $type\n      street: $street\n      country: $country\n      zip: $zip\n      website: $website\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...InstituteRequest\n        }\n      }\n    }\n  }\n':
    types.InstituteRequestsDocument,
  '\n  query InstituteRequest($id: ID!) {\n    instituteRequest(id: $id) {\n      ...InstituteRequest\n    }\n  }\n':
    types.InstituteRequestDocument,
  '\n  query FindDuplicateInstitutes(\n    $name: String\n    $street: String\n    $website: String\n    $customSearch: String\n  ) {\n    findDuplicateInstitutes(\n      name: $name\n      street: $street\n      website: $website\n      customSearch: $customSearch\n    ) {\n      ...Institute\n    }\n  }\n':
    types.FindDuplicateInstitutesDocument,
  '\n  mutation AcceptInstituteRequest($input: AdminAcceptInstituteRequestInput!) {\n    adminAcceptInstituteRequest(input: $input) {\n      instituteRequest {\n        ...InstituteRequest\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n':
    types.AcceptInstituteRequestDocument,
  '\n  mutation RejectInstituteRequest($input: AdminRejectInstituteRequestInput!) {\n    adminRejectInstituteRequest(input: $input) {\n      instituteRequest {\n        ...InstituteRequest\n      }\n    }\n  }\n':
    types.RejectInstituteRequestDocument,
  '\n  mutation DeleteInstituteRequest($input: AdminDeleteInstituteRequestInput!) {\n    adminDeleteInstituteRequest(input: $input) {\n      success\n    }\n  }\n':
    types.DeleteInstituteRequestDocument,
  '\n  fragment MarketProfile on MarketProfileNode {\n    id\n    name\n  }\n':
    types.MarketProfileFragmentDoc,
  '\n  query MarketProfiles {\n    marketProfiles {\n      edges {\n        node {\n          ...MarketProfile\n        }\n      }\n    }\n  }\n':
    types.MarketProfilesDocument,
  '\n  fragment Comment on CommentNode {\n    id\n    text\n    owner\n    userGroup {\n      ...UserGroup\n    }\n    team {\n      ...Team\n    }\n    likes(userGroup: $authUserGroupId) {\n      edges {\n        node {\n          userGroup {\n            id\n          }\n        }\n      }\n    }\n    likesCount\n    created\n  }\n':
    types.CommentFragmentDoc,
  '\n  fragment BasePost on PostNode {\n    id\n    owner\n    text\n    createdBy {\n      ...UserGroup\n    }\n    images\n    likesCount\n    team {\n      ...Team\n    }\n    comments {\n      ...Comment\n    }\n    created\n  }\n':
    types.BasePostFragmentDoc,
  '\n  fragment PollVote on PollVoteNode {\n    id\n    userGroup {\n      user {\n        id\n      }\n    }\n  }\n':
    types.PollVoteFragmentDoc,
  '\n  fragment PollOption on PollOptionNode {\n    id\n    text\n    image\n    votes {\n      ...PollVote\n    }\n    percentage\n    votesNumber\n  }\n':
    types.PollOptionFragmentDoc,
  '\n  fragment BasePoll on PollNode {\n    id\n    question\n    deadline\n    answersAllowed\n    randomizeOptions\n    allowRevote\n    allowComments\n    anonymousVotes\n    privateResults\n    options {\n      ...PollOption\n    }\n    created\n  }\n':
    types.BasePollFragmentDoc,
  '\n  fragment Post on PostNode {\n    ...BasePost\n    poll {\n      ...BasePoll\n    }\n    likes(userGroup: $authUserGroupId) {\n      edges {\n        node {\n          userGroup {\n            id\n          }\n        }\n      }\n    }\n  }\n':
    types.PostFragmentDoc,
  '\n  fragment Poll on PollNode {\n    ...BasePoll\n    post {\n      ...BasePost\n      likes(userGroup: $authUserGroupId) {\n        edges {\n          node {\n            userGroup {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.PollFragmentDoc,
  '\n  query Posts(\n    $after: String\n    $first: Int!\n    $owner: Owner\n    $createdBy: ID\n    $team: ID\n    $text: String\n    $createdBy_Group: ID\n    $authUserGroupId: ID!\n  ) {\n    posts(\n      after: $after\n      first: $first\n      owner: $owner\n      createdBy: $createdBy\n      team: $team\n      text: $text\n      createdBy_Group: $createdBy_Group\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Post\n        }\n      }\n    }\n  }\n':
    types.PostsDocument,
  '\n  query Post($id: ID!, $authUserGroupId: ID!) {\n    post(id: $id) {\n      ...Post\n    }\n  }\n':
    types.PostDocument,
  '\n  mutation CreatePost($input: CreatePostInput!, $authUserGroupId: ID!) {\n    createPost(input: $input) {\n      post {\n        ...Post\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n':
    types.CreatePostDocument,
  '\n  mutation DeletePost($input: DeletePostInput!, $authUserGroupId: ID!) {\n    deletePost(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n':
    types.DeletePostDocument,
  '\n  mutation ReportPostContent($input: ReportPostContentInput!) {\n    reportPostContent(input: $input) {\n      success\n      message\n    }\n  }\n':
    types.ReportPostContentDocument,
  '\n  mutation BlockUser($input: BlockUserMutationInput!) {\n    blockUser(input: $input) {\n      success\n      message\n    }\n  }\n':
    types.BlockUserDocument,
  '\n  mutation ReportMutation($input: ReportMutationInput!) {\n    reportMutation(input: $input) {\n      success\n      message\n    }\n  }\n':
    types.ReportMutationDocument,
  '\n  mutation AddImagesToPost(\n    $input: AddImagesToPostInput!\n    $authUserGroupId: ID!\n  ) {\n    addImagesToPost(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n':
    types.AddImagesToPostDocument,
  '\n  query Polls(\n    $after: String\n    $first: Int!\n    $post_Team: ID\n    $post_CreatedBy_Group: ID\n    $authUserGroupId: ID!\n  ) {\n    polls(\n      after: $after\n      first: $first\n      post_Team: $post_Team\n      post_CreatedBy_Group: $post_CreatedBy_Group\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Poll\n        }\n      }\n    }\n  }\n':
    types.PollsDocument,
  '\n  mutation CreatePoll($input: CreatePollInput!, $authUserGroupId: ID!) {\n    createPoll(input: $input) {\n      poll {\n        ...Poll\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n':
    types.CreatePollDocument,
  '\n  mutation DeletePoll($input: DeletePollInput!, $authUserGroupId: ID!) {\n    deletePoll(input: $input) {\n      poll {\n        ...Poll\n      }\n    }\n  }\n':
    types.DeletePollDocument,
  '\n  mutation CreatePollOption($input: CreatePollOptionInput!) {\n    createPollOption(input: $input) {\n      pollOption {\n        ...PollOption\n      }\n    }\n  }\n':
    types.CreatePollOptionDocument,
  '\n  mutation CreatePollVote($input: CreatePollVoteInput!) {\n    createPollVote(input: $input) {\n      pollVote {\n        ...PollVote\n      }\n    }\n  }\n':
    types.CreatePollVoteDocument,
  '\n  mutation UpdatePoll($input: UpdatePollInput!) {\n    updatePoll(input: $input) {\n      poll {\n        ...BasePoll\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n':
    types.UpdatePollDocument,
  '\n  mutation DeletePollVote($input: DeletePollVoteInput!) {\n    deletePollVote(input: $input) {\n      pollVote {\n        ...PollVote\n      }\n    }\n  }\n':
    types.DeletePollVoteDocument,
  '\n  mutation LikePost($input: LikePostInput!, $authUserGroupId: ID!) {\n    likePost(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n':
    types.LikePostDocument,
  '\n  mutation RemoveLikeFromPost(\n    $input: RemoveLikeFromPostInput!\n    $authUserGroupId: ID!\n  ) {\n    removeLikeFromPost(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n':
    types.RemoveLikeFromPostDocument,
  '\n  mutation CommentPost($input: CommentPostInput!, $authUserGroupId: ID!) {\n    commentPost(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n':
    types.CommentPostDocument,
  '\n  mutation LikeComment($input: LikeCommentInput!, $authUserGroupId: ID!) {\n    likeComment(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n':
    types.LikeCommentDocument,
  '\n  mutation RemoveLikeFromComment(\n    $input: RemoveLikeFromCommentInput!\n    $authUserGroupId: ID!\n  ) {\n    removeLikeFromComment(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n':
    types.RemoveLikeFromCommentDocument,
  '\n  mutation DeleteComment($input: DeleteCommentInput!, $authUserGroupId: ID!) {\n    deleteComment(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n':
    types.DeleteCommentDocument,
  '\n  mutation GenerateShopifyUrl($input: GenerateShopifyRedirectUrlInput!) {\n    generateShopifyUrl(input: $input) {\n      redirectUrl\n    }\n  }\n':
    types.GenerateShopifyUrlDocument,
  '\n  fragment TeamUser on UserTeamNode {\n    id\n    isAdmin\n    status\n    avatar\n    defaultAvatar\n    user {\n      ...User\n    }\n    created\n    modified\n  }\n':
    types.TeamUserFragmentDoc,
  '\n  fragment Team on TeamNode {\n    id\n    name\n    type\n    approvalSetting\n    color {\n      ...Color\n    }\n    icon {\n      ...Icon\n    }\n    isHidden\n    created\n  }\n':
    types.TeamFragmentDoc,
  '\n  fragment TeamSample on TeamNode {\n    ...Team\n    usersCount\n    sampleMembers {\n      ...TeamUser\n    }\n  }\n':
    types.TeamSampleFragmentDoc,
  '\n  fragment TeamDetail on TeamNode {\n    ...Team\n    users {\n      ...TeamUser\n    }\n  }\n':
    types.TeamDetailFragmentDoc,
  '\n  query Teams(\n    $after: String\n    $first: Int!\n    $name: String\n    $group: ID\n    $userGroupId: String\n  ) {\n    teams(\n      after: $after\n      first: $first\n      name: $name\n      group: $group\n      userGroupId: $userGroupId\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Team\n        }\n      }\n    }\n  }\n':
    types.TeamsDocument,
  '\n  query TeamSamples($after: String, $first: Int!, $name: String, $group: ID) {\n    teams(after: $after, first: $first, name: $name, group: $group) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...TeamSample\n        }\n      }\n    }\n  }\n':
    types.TeamSamplesDocument,
  '\n  query Team($id: ID!) {\n    team(id: $id) {\n      ...Team\n    }\n  }\n':
    types.TeamDocument,
  '\n  query TeamDetail($id: ID!) {\n    team(id: $id) {\n      ...TeamDetail\n    }\n  }\n':
    types.TeamDetailDocument,
  '\n  mutation AddUserToTeam($input: AddUserToTeamInput!) {\n    addUserToTeam(input: $input) {\n      userTeam {\n        ...TeamUser\n      }\n      teams {\n        ...Team\n        avatar\n        group {\n          ...Group\n        }\n      }\n    }\n  }\n':
    types.AddUserToTeamDocument,
  '\n  mutation RemoveUserFromTeam($input: RemoveUserFromTeamInput!) {\n    removeUserFromTeam(input: $input) {\n      userTeam {\n        ...TeamUser\n      }\n    }\n  }\n':
    types.RemoveUserFromTeamDocument,
  '\n  mutation CreateTeam($input: CreateTeamInput!) {\n    createTeam(input: $input) {\n      team {\n        ...TeamDetail\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n':
    types.CreateTeamDocument,
  '\n  mutation UpdateTeam($input: UpdateTeamInput!) {\n    updateTeam(input: $input) {\n      team {\n        ...TeamDetail\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n':
    types.UpdateTeamDocument,
  '\n  mutation DestroyTeam($input: DestroyTeamInput!) {\n    destroyTeam(input: $input) {\n      team {\n        ...TeamDetail\n      }\n      success\n    }\n  }\n':
    types.DestroyTeamDocument,
  '\n  mutation ActivateTeam($input: ActivateTeamInput!) {\n    activateTeam(input: $input) {\n      team {\n        ...TeamDetail\n      }\n      success\n    }\n  }\n':
    types.ActivateTeamDocument,
  '\n  mutation AcceptUserInTeam($input: AcceptUserInTeamInput!) {\n    acceptUserInTeam(input: $input) {\n      userTeam {\n        ...TeamUser\n      }\n    }\n  }\n':
    types.AcceptUserInTeamDocument,
  '\n  query UserTeam($id: ID!) {\n    userTeam(id: $id) {\n      ...TeamUser\n    }\n  }\n':
    types.UserTeamDocument,
  '\n  fragment User on UserNode {\n    id\n    firstName\n    lastName\n    gender\n  }\n':
    types.UserFragmentDoc,
  '\n  fragment UserWithTeams on UserNode {\n    ...User\n    teams {\n      id\n      name\n      color {\n        hex\n      }\n      icon {\n        id\n        icon\n      }\n    }\n  }\n':
    types.UserWithTeamsFragmentDoc,
  '\n  fragment Module on ModuleNode {\n    id\n    name\n    image\n    type\n    description\n    features\n  }\n':
    types.ModuleFragmentDoc,
  '\n  fragment ModuleInstance on ModuleInstanceNode {\n    id\n    isActive\n    dueDate\n    module {\n      ...Module\n    }\n  }\n':
    types.ModuleInstanceFragmentDoc,
  '\n  fragment PrewordInstance on PrewordInstanceNode {\n    id\n    text\n  }\n':
    types.PrewordInstanceFragmentDoc,
  '\n  fragment ProfilePageSetup on ProfilePageSetupNode {\n    id\n    photoFormat\n    maxChars\n    anonymousComments\n    visibleComments\n  }\n':
    types.ProfilePageSetupFragmentDoc,
  '\n  fragment RankingsSetup on RankingsSetupNode {\n    id\n    resultsTeam {\n      ...Team\n    }\n  }\n':
    types.RankingsSetupFragmentDoc,
  '\n  fragment CollagesSetup on CollagesSetupNode {\n    id\n    createAlbumsTeam {\n      ...Team\n    }\n    addPhotosTeam {\n      ...Team\n    }\n    viewPhotosTeam {\n      ...Team\n    }\n    photosCount\n  }\n':
    types.CollagesSetupFragmentDoc,
  '\n  fragment ReportsSetup on ReportsSetupNode {\n    id\n    allowImages\n  }\n':
    types.ReportsSetupFragmentDoc,
  '\n  fragment QuotesSetup on QuotesSetupNode {\n    id\n    anonymous\n    public\n    maxChars\n  }\n':
    types.QuotesSetupFragmentDoc,
  '\n  fragment CustomPageSetup on CustomPagesSetupNode {\n    id\n    createPagesTeam {\n      ...Team\n    }\n    viewPagesTeam {\n      ...Team\n    }\n    guide\n  }\n':
    types.CustomPageSetupFragmentDoc,
  '\n  fragment ModuleInstanceDetail on ModuleInstanceNode {\n    ...ModuleInstance\n    profilePageSetup {\n      ...ProfilePageSetup\n    }\n    rankingsSetup {\n      ...RankingsSetup\n    }\n    collagesSetup {\n      ...CollagesSetup\n    }\n    reportsSetup {\n      ...ReportsSetup\n    }\n    quotesSetup {\n      ...QuotesSetup\n    }\n    customPagesSetup {\n      ...CustomPageSetup\n    }\n  }\n':
    types.ModuleInstanceDetailFragmentDoc,
  '\n  fragment CustomPage on CustomPagesInstanceNode {\n    id\n    title\n    created\n    modified\n    page\n    userGroup {\n      ...UserGroup\n    }\n    moduleInstance {\n      ...ModuleInstanceDetail\n    }\n    pageFormat\n    pageType\n    pageQuality\n  }\n':
    types.CustomPageFragmentDoc,
  '\n  fragment PrewordResult on PrewordInstanceNode {\n    wordCount\n  }\n':
    types.PrewordResultFragmentDoc,
  '\n  fragment ProfilePageUserSummary on ProfilePageUserSummaryNode {\n    userGroup {\n      ...UserGroup\n    }\n    questionsAnswered\n    imagesUploaded\n  }\n':
    types.ProfilePageUserSummaryFragmentDoc,
  '\n  fragment ProfilePageResult on ProfilePageSetupNode {\n    id\n    usersSummary {\n      ...ProfilePageUserSummary\n    }\n    usersDelivered\n  }\n':
    types.ProfilePageResultFragmentDoc,
  '\n  fragment RankingsResult on RankingsSetupNode {\n    rankingsCount\n  }\n':
    types.RankingsResultFragmentDoc,
  '\n  fragment CollagesResult on CollagesSetupNode {\n    photosCount\n  }\n':
    types.CollagesResultFragmentDoc,
  '\n  fragment ReportsResult on ReportsSetupNode {\n    reportsCount\n  }\n':
    types.ReportsResultFragmentDoc,
  '\n  fragment QuotesResult on QuotesSetupNode {\n    quotesCount\n  }\n':
    types.QuotesResultFragmentDoc,
  '\n  fragment ModuleInstanceResult on ModuleInstanceNode {\n    ...ModuleInstance\n    prewordInstance {\n      ...PrewordResult\n    }\n    profilePageSetup {\n      ...ProfilePageResult\n    }\n    rankingsSetup {\n      ...RankingsResult\n    }\n    collagesSetup {\n      ...CollagesResult\n    }\n    reportsSetup {\n      ...ReportsResult\n    }\n    quotesSetup {\n      ...QuotesResult\n    }\n    customPagesInstances {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CustomPage\n        }\n        cursor\n      }\n    }\n  }\n':
    types.ModuleInstanceResultFragmentDoc,
  '\n  fragment ProfilePagePhoto on ProfilePagePhotoNode {\n    id\n    image\n  }\n':
    types.ProfilePagePhotoFragmentDoc,
  '\n  fragment BaseProfilePagePhotoCategory on ProfilePagePhotoCategoryNode {\n    id\n    name\n  }\n':
    types.BaseProfilePagePhotoCategoryFragmentDoc,
  '\n  fragment ProfilePagePhotoCategory on ProfilePagePhotoCategoryNode {\n    ...BaseProfilePagePhotoCategory\n    profilePagePhotos(userGroup: $ppqUserGroup) {\n      edges {\n        node {\n          ...ProfilePagePhoto\n        }\n      }\n    }\n  }\n':
    types.ProfilePagePhotoCategoryFragmentDoc,
  '\n  fragment ProfilePageQuestionOption on ProfilePageQuestionOptionNode {\n    id\n    text\n  }\n':
    types.ProfilePageQuestionOptionFragmentDoc,
  '\n  fragment ProfilePageAnswer on ProfilePageAnswerNode {\n    id\n    text\n    profilePageQuestionOption {\n      ...ProfilePageQuestionOption\n    }\n    userGroup {\n      id\n    }\n  }\n':
    types.ProfilePageAnswerFragmentDoc,
  '\n  fragment BaseProfilePageQuestion on ProfilePageQuestionNode {\n    id\n    text\n    order\n    profilePageQuestionOptions {\n      ...ProfilePageQuestionOption\n    }\n  }\n':
    types.BaseProfilePageQuestionFragmentDoc,
  '\n  fragment ProfilePageQuestion on ProfilePageQuestionNode {\n    ...BaseProfilePageQuestion\n    profilePageAnswers(userGroup: $ppqUserGroup) {\n      edges {\n        node {\n          ...ProfilePageAnswer\n        }\n      }\n    }\n  }\n':
    types.ProfilePageQuestionFragmentDoc,
  '\n  fragment ProfilePageSuggestedQuestionOption on ProfilePageSuggestedQuestionOptionNode {\n    id\n    text\n  }\n':
    types.ProfilePageSuggestedQuestionOptionFragmentDoc,
  '\n  fragment ProfilePageSuggestedQuestion on ProfilePageSuggestedQuestionNode {\n    id\n    text\n    options {\n      ...ProfilePageSuggestedQuestionOption\n    }\n  }\n':
    types.ProfilePageSuggestedQuestionFragmentDoc,
  '\n  fragment ProfilePageComment on ProfilePageCommentNode {\n    id\n    text\n    comentatorUserGroup {\n      ...UserGroup\n    }\n    profileUserGroup {\n      ...UserGroup\n    }\n    created\n  }\n':
    types.ProfilePageCommentFragmentDoc,
  '\n  fragment CollagesPhoto on CollagesPhotoNode {\n    id\n    photo\n    collagesPhotoLikesCount\n    created\n    userGroup {\n      id\n    }\n    collagesPhotoLikes(userGroup: $authUserGroupId) {\n      edges {\n        node {\n          userGroup {\n            id\n          }\n        }\n      }\n    }\n  }\n':
    types.CollagesPhotoFragmentDoc,
  '\n  fragment CollagesBasePhoto on CollagesPhotoNode {\n    id\n    photo\n    collagesPhotoLikesCount\n    created\n    userGroup {\n      id\n    }\n  }\n':
    types.CollagesBasePhotoFragmentDoc,
  '\n  fragment BaseCollagesAlbum on CollagesAlbumNode {\n    id\n    name\n  }\n':
    types.BaseCollagesAlbumFragmentDoc,
  '\n  fragment CollagesAlbum on CollagesAlbumNode {\n    ...BaseCollagesAlbum\n    photosCount\n    collagesPhotos(after: $cpAfter, first: $cpFirst) {\n      edges {\n        node {\n          ...CollagesBasePhoto\n        }\n      }\n    }\n  }\n':
    types.CollagesAlbumFragmentDoc,
  '\n  fragment CollagesBaseAlbum on CollagesAlbumNode {\n    ...BaseCollagesAlbum\n    photosCount\n  }\n':
    types.CollagesBaseAlbumFragmentDoc,
  '\n  fragment ReportsInstance on ReportsInstanceNode {\n    id\n    title\n    text\n    editTeam {\n      ...Team\n    }\n    viewTeam {\n      ...Team\n    }\n    maxChars\n  }\n':
    types.ReportsInstanceFragmentDoc,
  '\n  fragment BaseQuote on QuotesInstanceNode {\n    id\n    quote\n    userGroup {\n      ...UserGroup\n    }\n    quotesInstanceLikesCount\n    created\n  }\n':
    types.BaseQuoteFragmentDoc,
  '\n  fragment Quote on QuotesInstanceNode {\n    ...BaseQuote\n    quotesInstanceLikes(userGroup: $authUserGroupId) {\n      edges {\n        node {\n          id\n          userGroup {\n            id\n          }\n        }\n      }\n    }\n  }\n':
    types.QuoteFragmentDoc,
  '\n  fragment CustomList on CustomListNode {\n    id\n    title\n  }\n':
    types.CustomListFragmentDoc,
  '\n  fragment CustomListOption on CustomListOptionNode {\n    id\n    text\n  }\n':
    types.CustomListOptionFragmentDoc,
  '\n  fragment RankingQuestionResult on RankingsQuestionResultNode {\n    votee1UserGroup {\n      ...UserGroup\n    }\n    votee2UserGroup {\n      ...UserGroup\n    }\n    voteeListOption {\n      ...CustomListOption\n    }\n    percentage\n  }\n':
    types.RankingQuestionResultFragmentDoc,
  '\n  fragment RankingQuestionResults on RankingsQuestionResultsNode {\n    alreadyVoted\n    possibleVoters\n    options {\n      ...RankingQuestionResult\n    }\n  }\n':
    types.RankingQuestionResultsFragmentDoc,
  '\n  fragment RankingsAnswer on RankingsAnswerNode {\n    id\n    votee1UserGroup {\n      ...UserGroup\n    }\n    votee2UserGroup {\n      ...UserGroup\n    }\n    voteeListOption {\n      ...CustomListOption\n    }\n  }\n':
    types.RankingsAnswerFragmentDoc,
  '\n  fragment RankingQuestion on RankingsQuestionNode {\n    id\n    question\n    answerType\n    allowedVotes\n    onlyOppositeGender\n    customList {\n      id\n    }\n    results {\n      ...RankingQuestionResults\n    }\n    rankingsAnswers(voterUserGroup: $authUserGroupId) {\n      edges {\n        node {\n          ...RankingsAnswer\n        }\n      }\n    }\n  }\n':
    types.RankingQuestionFragmentDoc,
  '\n  fragment RankingBaseQuestion on RankingsQuestionNode {\n    id\n    question\n    answerType\n    allowedVotes\n    onlyOppositeGender\n    customList {\n      id\n    }\n    rankingsAnswers(voterUserGroup: $authUserGroupId) {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n  }\n':
    types.RankingBaseQuestionFragmentDoc,
  '\n  fragment DataExport on DataExportNode {\n    id\n    isodatetime\n    userGroup {\n      ...UserGroup\n    }\n    exportFile\n    exportCollages\n    exportRankings\n    exportProfilePages\n    exportQuotes\n  }\n':
    types.DataExportFragmentDoc,
  '\n  query Modules($after: String, $first: Int!) {\n    modules(after: $after, first: $first) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Module\n        }\n      }\n    }\n  }\n':
    types.ModulesDocument,
  '\n  query Module($id: ID!) {\n    module(id: $id) {\n      ...Module\n    }\n  }\n':
    types.ModuleDocument,
  '\n  query ModuleInstances($after: String, $first: Int!, $group: ID) {\n    moduleInstances(after: $after, first: $first, group: $group) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ModuleInstance\n        }\n      }\n    }\n  }\n':
    types.ModuleInstancesDocument,
  '\n  query ModuleInstance($id: ID!) {\n    moduleInstance(id: $id) {\n      ...ModuleInstanceDetail\n    }\n  }\n':
    types.ModuleInstanceDocument,
  '\n  query ModuleInstanceResults($after: String, $first: Int!, $group: ID) {\n    moduleInstances(after: $after, first: $first, group: $group) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ModuleInstanceResult\n        }\n      }\n    }\n  }\n':
    types.ModuleInstanceResultsDocument,
  '\n  query ModuleInstanceResult($id: ID!) {\n    moduleInstance(id: $id) {\n      ...ModuleInstanceResult\n    }\n  }\n':
    types.ModuleInstanceResultDocument,
  '\n  query ProfilePagePhotoCategories(\n    $after: String\n    $first: Int!\n    $moduleInstance: ID\n    $ppqUserGroup: ID\n  ) {\n    profilePagePhotoCategories(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ProfilePagePhotoCategory\n        }\n      }\n    }\n  }\n':
    types.ProfilePagePhotoCategoriesDocument,
  '\n  query ProfilePageQuestions(\n    $after: String\n    $first: Int!\n    $moduleInstance: ID\n    $ppqUserGroup: ID\n  ) {\n    profilePageQuestions(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ProfilePageQuestion\n        }\n      }\n    }\n  }\n':
    types.ProfilePageQuestionsDocument,
  '\n  query ProfilePageSuggestedQuestions($after: String, $first: Int!) {\n    profilePageSuggestedQuestions(after: $after, first: $first) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ProfilePageSuggestedQuestion\n        }\n      }\n    }\n  }\n':
    types.ProfilePageSuggestedQuestionsDocument,
  '\n  query ProfilePageComments(\n    $after: String\n    $first: Int!\n    $profileUserGroup: ID\n    $comentatorUserGroup: ID\n    $moduleInstance: ID\n  ) {\n    profilePageComments(\n      after: $after\n      first: $first\n      profileUserGroup: $profileUserGroup\n      comentatorUserGroup: $comentatorUserGroup\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ProfilePageComment\n        }\n      }\n    }\n  }\n':
    types.ProfilePageCommentsDocument,
  '\n  query CollagesAlbums(\n    $after: String\n    $first: Int!\n    $moduleInstance: ID\n    $cpAfter: String\n    $cpFirst: Int\n  ) {\n    collagesAlbums(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CollagesAlbum\n        }\n      }\n    }\n  }\n':
    types.CollagesAlbumsDocument,
  '\n  query CollagesAlbum($id: ID!) {\n    collagesAlbum(id: $id) {\n      ...CollagesBaseAlbum\n    }\n  }\n':
    types.CollagesAlbumDocument,
  '\n  query CollagesPhotos(\n    $after: String\n    $first: Int\n    $collagesAlbum: ID\n    $authUserGroupId: ID\n  ) {\n    collagesPhotos(\n      after: $after\n      first: $first\n      collagesAlbum: $collagesAlbum\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CollagesPhoto\n        }\n      }\n    }\n  }\n':
    types.CollagesPhotosDocument,
  '\n  query ReportsInstances($after: String, $first: Int!, $moduleInstance: ID) {\n    reportsInstances(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ReportsInstance\n        }\n      }\n    }\n  }\n':
    types.ReportsInstancesDocument,
  '\n  query ReportsInstance($id: ID!) {\n    reportsInstance(id: $id) {\n      ...ReportsInstance\n    }\n  }\n':
    types.ReportsInstanceDocument,
  '\n  query QuotesInstances(\n    $after: String\n    $first: Int!\n    $moduleInstance: ID\n    $userGroup: ID\n    $authUserGroupId: ID\n    $orderBy: String\n  ) {\n    quotesInstances(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n      userGroup: $userGroup\n      orderBy: $orderBy\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Quote\n        }\n      }\n    }\n  }\n':
    types.QuotesInstancesDocument,
  '\n  query CustomPagesInstances(\n    $after: String\n    $first: Int!\n    $moduleInstance: ID\n    $userGroup: String!\n  ) {\n    customPagesInstances(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n      userGroup: $userGroup\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CustomPage\n        }\n        cursor\n      }\n    }\n  }\n':
    types.CustomPagesInstancesDocument,
  '\n  query CustomPagesInstancesResult(\n    $after: String\n    $first: Int!\n    $moduleInstance: String!\n  ) {\n    customPagesInstancesResult(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CustomPage\n        }\n        cursor\n      }\n    }\n  }\n':
    types.CustomPagesInstancesResultDocument,
  '\n  query CustomLists($after: String, $first: Int!, $moduleInstance: ID) {\n    customLists(after: $after, first: $first, moduleInstance: $moduleInstance) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CustomList\n        }\n      }\n    }\n  }\n':
    types.CustomListsDocument,
  '\n  query CustomListOptions($after: String, $first: Int!, $customList: ID) {\n    customListOptions(after: $after, first: $first, customList: $customList) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CustomListOption\n        }\n      }\n    }\n  }\n':
    types.CustomListOptionsDocument,
  '\n  query RankingsQuestions(\n    $after: String\n    $first: Int!\n    $moduleInstance: ID\n    $authUserGroupId: ID\n  ) {\n    rankingsQuestions(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...RankingQuestion\n        }\n      }\n    }\n  }\n':
    types.RankingsQuestionsDocument,
  '\n  query RankingsQuestion($id: ID!, $authUserGroupId: ID) {\n    rankingsQuestion(id: $id) {\n      ...RankingBaseQuestion\n    }\n  }\n':
    types.RankingsQuestionDocument,
  '\n  query DataExports($after: String, $first: Int!, $group: ID!) {\n    dataExports(after: $after, first: $first, group: $group) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...DataExport\n        }\n      }\n    }\n  }\n':
    types.DataExportsDocument,
  '\n  query CustomPagesInstance($id: ID!) {\n    customPagesInstance(id: $id) {\n      ...CustomPage\n    }\n  }\n':
    types.CustomPagesInstanceDocument,
  '\n  mutation CreateModuleInstance($input: CreateModuleInstanceInput!) {\n    createModuleInstance(input: $input) {\n      errors {\n        field\n        messages\n      }\n      moduleInstance {\n        ...ModuleInstance\n      }\n    }\n  }\n':
    types.CreateModuleInstanceDocument,
  '\n  mutation DestroyModuleInstance($input: DestroyModuleInstanceInput!) {\n    destroyModuleInstance(input: $input) {\n      moduleInstance {\n        ...ModuleInstance\n      }\n    }\n  }\n':
    types.DestroyModuleInstanceDocument,
  '\n  mutation UpdatePrewordInstance($input: UpdatePrewordInstanceInput!) {\n    updatePrewordInstance(input: $input) {\n      errors {\n        field\n        messages\n      }\n      prewordInstance {\n        ...PrewordInstance\n      }\n    }\n  }\n':
    types.UpdatePrewordInstanceDocument,
  '\n  mutation CreateProfilePagePhotoCategory(\n    $input: CreateProfilePagePhotoCategoryInput!\n  ) {\n    createProfilePagePhotoCategory(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePagePhotoCategory {\n        ...BaseProfilePagePhotoCategory\n      }\n    }\n  }\n':
    types.CreateProfilePagePhotoCategoryDocument,
  '\n  mutation UpdateProfilePagePhotoCategory(\n    $input: UpdateProfilePagePhotoCategoryInput!\n  ) {\n    updateProfilePagePhotoCategory(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePagePhotoCategory {\n        ...BaseProfilePagePhotoCategory\n      }\n    }\n  }\n':
    types.UpdateProfilePagePhotoCategoryDocument,
  '\n  mutation DestroyProfilePagePhotoCategory(\n    $input: DestroyProfilePagePhotoCategoryInput!\n  ) {\n    destroyProfilePagePhotoCategory(input: $input) {\n      profilePagePhotoCategory {\n        ...BaseProfilePagePhotoCategory\n      }\n    }\n  }\n':
    types.DestroyProfilePagePhotoCategoryDocument,
  '\n  mutation CreateProfilePagePhoto($input: CreateProfilePagePhotoInput!) {\n    createProfilePagePhoto(input: $input) {\n      profilePagePhoto {\n        ...ProfilePagePhoto\n      }\n    }\n  }\n':
    types.CreateProfilePagePhotoDocument,
  '\n  mutation UpdateProfilePagePhoto($input: UpdateProfilePagePhotoInput!) {\n    updateProfilePagePhoto(input: $input) {\n      profilePagePhoto {\n        ...ProfilePagePhoto\n      }\n    }\n  }\n':
    types.UpdateProfilePagePhotoDocument,
  '\n  mutation DestroyProfilePagePhoto($input: DestroyProfilePagePhotoInput!) {\n    destroyProfilePagePhoto(input: $input) {\n      profilePagePhoto {\n        ...ProfilePagePhoto\n      }\n    }\n  }\n':
    types.DestroyProfilePagePhotoDocument,
  '\n  mutation CreateProfilePageQuestion($input: CreateProfilePageQuestionInput!) {\n    createProfilePageQuestion(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePageQuestion {\n        ...BaseProfilePageQuestion\n      }\n    }\n  }\n':
    types.CreateProfilePageQuestionDocument,
  '\n  mutation UpdateProfilePageQuestion($input: UpdateProfilePageQuestionInput!) {\n    updateProfilePageQuestion(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePageQuestion {\n        ...BaseProfilePageQuestion\n      }\n    }\n  }\n':
    types.UpdateProfilePageQuestionDocument,
  '\n  mutation DestroyProfilePageQuestion(\n    $input: DestroyProfilePageQuestionInput!\n  ) {\n    destroyProfilePageQuestion(input: $input) {\n      profilePageQuestion {\n        ...BaseProfilePageQuestion\n      }\n    }\n  }\n':
    types.DestroyProfilePageQuestionDocument,
  '\n  mutation CreateProfilePageAnswer($input: CreateProfilePageAnswerInput!) {\n    createProfilePageAnswer(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePageAnswer {\n        ...ProfilePageAnswer\n      }\n    }\n  }\n':
    types.CreateProfilePageAnswerDocument,
  '\n  mutation UpdateProfilePageAnswer($input: UpdateProfilePageAnswerInput!) {\n    updateProfilePageAnswer(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePageAnswer {\n        ...ProfilePageAnswer\n      }\n    }\n  }\n':
    types.UpdateProfilePageAnswerDocument,
  '\n  mutation DestroyProfilePageAnswer($input: DestroyProfilePageAnswerInput!) {\n    destroyProfilePageAnswer(input: $input) {\n      clientMutationId\n    }\n  }\n':
    types.DestroyProfilePageAnswerDocument,
  '\n  mutation UpdateProfilePageSetup($input: UpdateProfilePageSetupInput!) {\n    updateProfilePageSetup(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePageSetup {\n        ...ProfilePageSetup\n      }\n    }\n  }\n':
    types.UpdateProfilePageSetupDocument,
  '\n  mutation UpdateModuleInstance($input: UpdateModuleInstanceInput!) {\n    updateModuleInstance(input: $input) {\n      errors {\n        field\n        messages\n      }\n      moduleInstance {\n        ...ModuleInstanceDetail\n      }\n    }\n  }\n':
    types.UpdateModuleInstanceDocument,
  '\n  mutation CreateProfilePageComment($input: CreateProfilePageCommentInput!) {\n    createProfilePageComment(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePageComment {\n        ...ProfilePageComment\n      }\n    }\n  }\n':
    types.CreateProfilePageCommentDocument,
  '\n  mutation UpdateProfilePageComment($input: UpdateProfilePageCommentInput!) {\n    updateProfilePageComment(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePageComment {\n        ...ProfilePageComment\n      }\n    }\n  }\n':
    types.UpdateProfilePageCommentDocument,
  '\n  mutation DestroyProfilePageComment($input: DestroyProfilePageCommentInput!) {\n    destroyProfilePageComment(input: $input) {\n      profilePageComment {\n        ...ProfilePageComment\n      }\n    }\n  }\n':
    types.DestroyProfilePageCommentDocument,
  '\n  mutation MoveProfilePageQuestionToPosition(\n    $input: MoveProfilePageQuestionToPositionInput!\n  ) {\n    moveProfilePageQuestionToPosition(input: $input) {\n      profilePageQuestion {\n        ...BaseProfilePageQuestion\n      }\n    }\n  }\n':
    types.MoveProfilePageQuestionToPositionDocument,
  '\n  mutation AddPhotoToCollagesAlbum(\n    $input: AddPhotoToCollagesAlbumInput!\n    $authUserGroupId: ID\n  ) {\n    addPhotoToCollagesAlbum(input: $input) {\n      photo {\n        ...CollagesPhoto\n      }\n    }\n  }\n':
    types.AddPhotoToCollagesAlbumDocument,
  '\n  mutation RemovePhotoFromCollagesAlbum(\n    $input: RemovePhotoFromCollagesAlbumInput!\n  ) {\n    removePhotoFromCollagesAlbum(input: $input) {\n      success\n    }\n  }\n':
    types.RemovePhotoFromCollagesAlbumDocument,
  '\n  mutation CreateCollagesAlbum($input: CreateCollagesAlbumInput!) {\n    createCollagesAlbum(input: $input) {\n      errors {\n        field\n        messages\n      }\n      collagesAlbum {\n        ...BaseCollagesAlbum\n      }\n    }\n  }\n':
    types.CreateCollagesAlbumDocument,
  '\n  mutation UpdateCollagesAlbum($input: UpdateCollagesAlbumInput!) {\n    updateCollagesAlbum(input: $input) {\n      errors {\n        field\n        messages\n      }\n      collagesAlbum {\n        ...BaseCollagesAlbum\n      }\n    }\n  }\n':
    types.UpdateCollagesAlbumDocument,
  '\n  mutation DestroyCollagesAlbum($input: DestroyCollagesAlbumInput!) {\n    destroyCollagesAlbum(input: $input) {\n      collagesAlbum {\n        ...BaseCollagesAlbum\n      }\n    }\n  }\n':
    types.DestroyCollagesAlbumDocument,
  '\n  mutation LikeCollagesPhoto(\n    $input: LikeCollagesPhotoInput!\n    $authUserGroupId: ID\n  ) {\n    likeCollagesPhoto(input: $input) {\n      collagesPhoto {\n        ...CollagesPhoto\n      }\n    }\n  }\n':
    types.LikeCollagesPhotoDocument,
  '\n  mutation RemoveLikeFromCollagesPhoto(\n    $input: RemoveLikeFromCollagesPhotoInput!\n    $authUserGroupId: ID\n  ) {\n    removeLikeFromCollagesPhoto(input: $input) {\n      collagesPhoto {\n        ...CollagesPhoto\n      }\n    }\n  }\n':
    types.RemoveLikeFromCollagesPhotoDocument,
  '\n  mutation UpdateCollagesSetup($input: UpdateCollagesSetupInput!) {\n    updateCollagesSetup(input: $input) {\n      errors {\n        field\n        messages\n      }\n      collagesSetup {\n        ...CollagesSetup\n      }\n    }\n  }\n':
    types.UpdateCollagesSetupDocument,
  '\n  mutation UpdateReportsSetup($input: UpdateReportsSetupInput!) {\n    updateReportsSetup(input: $input) {\n      errors {\n        field\n        messages\n      }\n      reportsSetup {\n        ...ReportsSetup\n      }\n    }\n  }\n':
    types.UpdateReportsSetupDocument,
  '\n  mutation CreateReportsInstance($input: CreateReportsInstanceInput!) {\n    createReportsInstance(input: $input) {\n      errors {\n        field\n        messages\n      }\n      reportsInstance {\n        ...ReportsInstance\n      }\n    }\n  }\n':
    types.CreateReportsInstanceDocument,
  '\n  mutation UpdateReportsInstance($input: UpdateReportsInstanceInput!) {\n    updateReportsInstance(input: $input) {\n      errors {\n        field\n        messages\n      }\n      reportsInstance {\n        ...ReportsInstance\n      }\n    }\n  }\n':
    types.UpdateReportsInstanceDocument,
  '\n  mutation DestroyReportsInstance($input: DestroyReportsInstanceInput!) {\n    destroyReportsInstance(input: $input) {\n      success\n      reportsInstanceId\n    }\n  }\n':
    types.DestroyReportsInstanceDocument,
  '\n  mutation CreateQuotesInstance($input: CreateQuotesInstanceInput!) {\n    createQuotesInstance(input: $input) {\n      errors {\n        field\n        messages\n      }\n      quotesInstance {\n        ...BaseQuote\n      }\n    }\n  }\n':
    types.CreateQuotesInstanceDocument,
  '\n  mutation DestroyQuotesInstance($input: DestroyQuotesInstanceInput!) {\n    destroyQuotesInstance(input: $input) {\n      quotesInstance {\n        ...BaseQuote\n      }\n    }\n  }\n':
    types.DestroyQuotesInstanceDocument,
  '\n  mutation UpdateQuotesSetup($input: UpdateQuotesSetupInput!) {\n    updateQuotesSetup(input: $input) {\n      errors {\n        field\n        messages\n      }\n      quotesSetup {\n        ...QuotesSetup\n      }\n    }\n  }\n':
    types.UpdateQuotesSetupDocument,
  '\n  mutation LikeQuotesInstance(\n    $input: LikeQuotesInstanceInput!\n    $authUserGroupId: ID\n  ) {\n    likeQuotesInstance(input: $input) {\n      quotesInstance {\n        ...Quote\n      }\n    }\n  }\n':
    types.LikeQuotesInstanceDocument,
  '\n  mutation RemoveLikeFromQuotesInstance(\n    $input: RemoveLikeFromQuotesInstanceInput!\n    $authUserGroupId: ID\n  ) {\n    removeLikeFromQuotesInstance(input: $input) {\n      quotesInstance {\n        ...Quote\n      }\n    }\n  }\n':
    types.RemoveLikeFromQuotesInstanceDocument,
  '\n  mutation UpdateRankingsSetup($input: UpdateRankingsSetupInput!) {\n    updateRankingsSetup(input: $input) {\n      errors {\n        field\n        messages\n      }\n      rankingsSetup {\n        ...RankingsSetup\n      }\n    }\n  }\n':
    types.UpdateRankingsSetupDocument,
  '\n  mutation UpdateCustomPagesSetup($input: UpdateCustomPagesSetupInput!) {\n    updateCustomPagesSetup(input: $input) {\n      errors {\n        field\n        messages\n      }\n      customPagesSetup {\n        ...CustomPageSetup\n      }\n    }\n  }\n':
    types.UpdateCustomPagesSetupDocument,
  '\n  mutation CreateCustomPagesInstance($input: CreateCustomPagesInstanceInput!) {\n    createCustomPagesInstance(input: $input) {\n      customPagesInstance {\n        ...CustomPage\n      }\n    }\n  }\n':
    types.CreateCustomPagesInstanceDocument,
  '\n  mutation UpdateCustomPagesInstance($input: UpdateCustomPagesInstanceInput!) {\n    updateCustomPagesInstance(input: $input) {\n      customPagesInstance {\n        ...CustomPage\n      }\n    }\n  }\n':
    types.UpdateCustomPagesInstanceDocument,
  '\n  mutation DestroyCustomPagesInstance(\n    $input: DestroyCustomPagesInstanceInput!\n  ) {\n    destroyCustomPagesInstance(input: $input) {\n      success\n      customPagesInstanceId\n    }\n  }\n':
    types.DestroyCustomPagesInstanceDocument,
  '\n  mutation CreateRankingsQuestion(\n    $input: CreateRankingsQuestionInput!\n    $authUserGroupId: ID\n  ) {\n    createRankingsQuestion(input: $input) {\n      errors {\n        field\n        messages\n      }\n      rankingsQuestion {\n        ...RankingQuestion\n      }\n    }\n  }\n':
    types.CreateRankingsQuestionDocument,
  '\n  mutation UpdateRankingsQuestion(\n    $input: UpdateRankingsQuestionInput!\n    $authUserGroupId: ID\n  ) {\n    updateRankingsQuestion(input: $input) {\n      errors {\n        field\n        messages\n      }\n      rankingsQuestion {\n        ...RankingQuestion\n      }\n    }\n  }\n':
    types.UpdateRankingsQuestionDocument,
  '\n  mutation DestroyRankingsQuestion(\n    $input: DestroyRankingsQuestionInput!\n    $authUserGroupId: ID\n  ) {\n    destroyRankingsQuestion(input: $input) {\n      rankingsQuestion {\n        ...RankingQuestion\n      }\n    }\n  }\n':
    types.DestroyRankingsQuestionDocument,
  '\n  mutation CreateRankingsAnswer(\n    $input: CreateRankingsAnswerInput!\n    $authUserGroupId: ID\n  ) {\n    createRankingsAnswer(input: $input) {\n      errors {\n        field\n        messages\n      }\n      rankingsAnswer {\n        rankingsQuestion {\n          ...RankingQuestion\n        }\n      }\n    }\n  }\n':
    types.CreateRankingsAnswerDocument,
  '\n  mutation UpdateRankingsAnswer(\n    $input: UpdateRankingsAnswerInput!\n    $authUserGroupId: ID\n  ) {\n    updateRankingsAnswer(input: $input) {\n      errors {\n        field\n        messages\n      }\n      rankingsAnswer {\n        rankingsQuestion {\n          ...RankingQuestion\n        }\n      }\n    }\n  }\n':
    types.UpdateRankingsAnswerDocument,
  '\n  mutation CreateCustomList($input: CreateCustomListInput!) {\n    createCustomList(input: $input) {\n      customList {\n        ...CustomList\n      }\n    }\n  }\n':
    types.CreateCustomListDocument,
  '\n  mutation UpdateCustomList($input: UpdateCustomListInput!) {\n    updateCustomList(input: $input) {\n      customList {\n        ...CustomList\n      }\n    }\n  }\n':
    types.UpdateCustomListDocument,
  '\n  mutation DeleteCustomList($input: DeleteCustomListInput!) {\n    deleteCustomList(input: $input) {\n      customList {\n        ...CustomList\n      }\n    }\n  }\n':
    types.DeleteCustomListDocument,
  '\n  mutation CreateCustomListOption($input: CreateCustomListOptionInput!) {\n    createCustomListOption(input: $input) {\n      customListOption {\n        ...CustomListOption\n      }\n    }\n  }\n':
    types.CreateCustomListOptionDocument,
  '\n  mutation UpdateCustomListOption($input: UpdateCustomListOptionInput!) {\n    updateCustomListOption(input: $input) {\n      customListOption {\n        ...CustomListOption\n      }\n    }\n  }\n':
    types.UpdateCustomListOptionDocument,
  '\n  mutation DeleteCustomListOption($input: DeleteCustomListOptionInput!) {\n    deleteCustomListOption(input: $input) {\n      customListOption {\n        ...CustomListOption\n      }\n    }\n  }\n':
    types.DeleteCustomListOptionDocument,
  '\n  mutation CreateDataExport($input: CreateDataExportInput!) {\n    createDataExport(input: $input) {\n      success\n    }\n  }\n':
    types.CreateDataExportDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment InstituteActivityType on InstituteActivityType {\n    year\n    status\n  }\n',
): (typeof documents)['\n  fragment InstituteActivityType on InstituteActivityType {\n    year\n    status\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment AdminSearchUser on SearchUserType {\n    id\n    firstName\n    lastName\n    phone\n    email\n    avatar\n    phoneCountryCode\n    groups {\n      ...AdminSearchGroup\n    }\n  }\n',
): (typeof documents)['\n  fragment AdminSearchUser on SearchUserType {\n    id\n    firstName\n    lastName\n    phone\n    email\n    avatar\n    phoneCountryCode\n    groups {\n      ...AdminSearchGroup\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment AdminSearchGroup on SearchGroupType {\n    id\n    name\n    graduationYear\n    type\n    avatar\n    institute {\n      ...AdminSearchInstitute\n    }\n  }\n',
): (typeof documents)['\n  fragment AdminSearchGroup on SearchGroupType {\n    id\n    name\n    graduationYear\n    type\n    avatar\n    institute {\n      ...AdminSearchInstitute\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment AdminSearchInstitute on SearchInstituteType {\n    id\n    name\n    internalId\n    country\n    city\n    zip\n    type\n  }\n',
): (typeof documents)['\n  fragment AdminSearchInstitute on SearchInstituteType {\n    id\n    name\n    internalId\n    country\n    city\n    zip\n    type\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query AdminSearch($searchString: String!) {\n    adminSearchResults(custom: $searchString) {\n      users {\n        ...AdminSearchUser\n      }\n      groups {\n        ...AdminSearchGroup\n      }\n      institutes {\n        ...AdminSearchInstitute\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AdminSearch($searchString: String!) {\n    adminSearchResults(custom: $searchString) {\n      users {\n        ...AdminSearchUser\n      }\n      groups {\n        ...AdminSearchGroup\n      }\n      institutes {\n        ...AdminSearchInstitute\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment AdminGroupMember on GroupMemberNode {\n    id\n    firstName\n    lastName\n    avatar\n    email\n    phone\n    phoneCountryCode\n    role\n    status\n    user {\n      id\n    }\n    teams {\n      id\n      name\n      avatar\n      icon {\n        icon\n      }\n      color {\n        hex\n      }\n      group {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment AdminGroupMember on GroupMemberNode {\n    id\n    firstName\n    lastName\n    avatar\n    email\n    phone\n    phoneCountryCode\n    role\n    status\n    user {\n      id\n    }\n    teams {\n      id\n      name\n      avatar\n      icon {\n        icon\n      }\n      color {\n        hex\n      }\n      group {\n        id\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment AdminGroupInstitute on InstituteNode {\n    name\n    id\n    internalId\n    type {\n      name\n      graduationTypes {\n        id\n        name\n      }\n    }\n    country\n    street\n    zip\n    city\n  }\n',
): (typeof documents)['\n  fragment AdminGroupInstitute on InstituteNode {\n    name\n    id\n    internalId\n    type {\n      name\n      graduationTypes {\n        id\n        name\n      }\n    }\n    country\n    street\n    zip\n    city\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment AdminGroup on GroupNode {\n    id\n    name\n    avatar\n    created\n    token\n    isActive\n    isImported\n    type {\n      id\n      name\n    }\n    year\n    allMembers {\n      ...AdminGroupMember\n    }\n    institute {\n      ...AdminGroupInstitute\n    }\n  }\n',
): (typeof documents)['\n  fragment AdminGroup on GroupNode {\n    id\n    name\n    avatar\n    created\n    token\n    isActive\n    isImported\n    type {\n      id\n      name\n    }\n    year\n    allMembers {\n      ...AdminGroupMember\n    }\n    institute {\n      ...AdminGroupInstitute\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query AdminGetGroup($id: ID!) {\n    group(id: $id) {\n      ...AdminGroup\n    }\n  }\n',
): (typeof documents)['\n  query AdminGetGroup($id: ID!) {\n    group(id: $id) {\n      ...AdminGroup\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation AdminToggleGroupActive($input: ToggleIsActiveGroupInput!) {\n    toggleIsActiveGroup(input: $input) {\n      group {\n        ...AdminGroup\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AdminToggleGroupActive($input: ToggleIsActiveGroupInput!) {\n    toggleIsActiveGroup(input: $input) {\n      group {\n        ...AdminGroup\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation AdminMoveGroupToInstitute($input: MoveGroupToInstituteInput!) {\n    moveGroupToInstitute(input: $input) {\n      group {\n        ...AdminGroup\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AdminMoveGroupToInstitute($input: MoveGroupToInstituteInput!) {\n    moveGroupToInstitute(input: $input) {\n      group {\n        ...AdminGroup\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment AdminUserTeams on TeamNode {\n    id\n    name\n    avatar\n    icon {\n      icon\n    }\n    color {\n      hex\n    }\n    group {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment AdminUserTeams on TeamNode {\n    id\n    name\n    avatar\n    icon {\n      icon\n    }\n    color {\n      hex\n    }\n    group {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment AdminUserGroups on GroupNode {\n    id\n    name\n    avatar\n    year\n    icon {\n      icon\n    }\n    institute {\n      id\n      name\n      internalId\n      country\n    }\n    type {\n      name\n    }\n    users {\n      id\n      status\n      user {\n        id\n      }\n    }\n    teams {\n      id\n      name\n    }\n  }\n',
): (typeof documents)['\n  fragment AdminUserGroups on GroupNode {\n    id\n    name\n    avatar\n    year\n    icon {\n      icon\n    }\n    institute {\n      id\n      name\n      internalId\n      country\n    }\n    type {\n      name\n    }\n    users {\n      id\n      status\n      user {\n        id\n      }\n    }\n    teams {\n      id\n      name\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment AdminUser on UserNode {\n    id\n    firstName\n    lastName\n    email\n    phoneCountryCode\n    phone\n    gender\n    isActive\n    optIn\n    created\n    isImported\n    isAgent\n    isSuperuser\n    avatar\n    groups {\n      ...AdminUserGroups\n    }\n    teams {\n      ...AdminUserTeams\n    }\n  }\n',
): (typeof documents)['\n  fragment AdminUser on UserNode {\n    id\n    firstName\n    lastName\n    email\n    phoneCountryCode\n    phone\n    gender\n    isActive\n    optIn\n    created\n    isImported\n    isAgent\n    isSuperuser\n    avatar\n    groups {\n      ...AdminUserGroups\n    }\n    teams {\n      ...AdminUserTeams\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query AdminGetUser($id: ID!) {\n    user(id: $id) {\n      ...AdminUser\n    }\n  }\n',
): (typeof documents)['\n  query AdminGetUser($id: ID!) {\n    user(id: $id) {\n      ...AdminUser\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query AdminGetGradooStaff(\n    $offset: Int\n    $after: String\n    $first: Int\n    $searchNameEmail: String\n  ) {\n    users(\n      offset: $offset\n      after: $after\n      first: $first\n      isStaff: true\n      searchNameEmail: $searchNameEmail\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...AdminUser\n        }\n      }\n      totalCount\n    }\n  }\n',
): (typeof documents)['\n  query AdminGetGradooStaff(\n    $offset: Int\n    $after: String\n    $first: Int\n    $searchNameEmail: String\n  ) {\n    users(\n      offset: $offset\n      after: $after\n      first: $first\n      isStaff: true\n      searchNameEmail: $searchNameEmail\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...AdminUser\n        }\n      }\n      totalCount\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query AdminGetUsers(\n    $offset: Int\n    $after: String\n    $first: Int\n    $firstName: String\n    $lastName: String\n    $email: String\n    $isAgent: Boolean\n    $isSuperuser: Boolean\n    $isStaff: Boolean\n    $searchNameEmail: String\n  ) {\n    users(\n      offset: $offset\n      after: $after\n      first: $first\n      firstName: $firstName\n      lastName: $lastName\n      email: $email\n      isAgent: $isAgent\n      isSuperuser: $isSuperuser\n      isStaff: $isStaff\n      searchNameEmail: $searchNameEmail\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...AdminUser\n        }\n      }\n      totalCount\n    }\n  }\n',
): (typeof documents)['\n  query AdminGetUsers(\n    $offset: Int\n    $after: String\n    $first: Int\n    $firstName: String\n    $lastName: String\n    $email: String\n    $isAgent: Boolean\n    $isSuperuser: Boolean\n    $isStaff: Boolean\n    $searchNameEmail: String\n  ) {\n    users(\n      offset: $offset\n      after: $after\n      first: $first\n      firstName: $firstName\n      lastName: $lastName\n      email: $email\n      isAgent: $isAgent\n      isSuperuser: $isSuperuser\n      isStaff: $isStaff\n      searchNameEmail: $searchNameEmail\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...AdminUser\n        }\n      }\n      totalCount\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation AdminUpdateUser($input: AdminUpdateUserInput!) {\n    adminUpdateUser(input: $input) {\n      user {\n        ...AdminUser\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AdminUpdateUser($input: AdminUpdateUserInput!) {\n    adminUpdateUser(input: $input) {\n      user {\n        ...AdminUser\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation AdminUpgradeUser($input: AdminUpgradeUserInput!) {\n    adminUpgradeUser(input: $input) {\n      user {\n        ...AdminUser\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AdminUpgradeUser($input: AdminUpgradeUserInput!) {\n    adminUpgradeUser(input: $input) {\n      user {\n        ...AdminUser\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation AdminDowngradeUser($input: AdminDowngradeUserInput!) {\n    adminDowngradeUser(input: $input) {\n      user {\n        ...AdminUser\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AdminDowngradeUser($input: AdminDowngradeUserInput!) {\n    adminDowngradeUser(input: $input) {\n      user {\n        ...AdminUser\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation AdminClearUserFlags($input: AdminClearUserFlagsInput!) {\n    adminClearUserFlags(input: $input) {\n      user {\n        ...AdminUser\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AdminClearUserFlags($input: AdminClearUserFlagsInput!) {\n    adminClearUserFlags(input: $input) {\n      user {\n        ...AdminUser\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation AdminForgetPasswordLink($input: AdminForgetPasswordLinkInput!) {\n    adminForgetPasswordLink(input: $input) {\n      forgetPasswordLink\n    }\n  }\n',
): (typeof documents)['\n  mutation AdminForgetPasswordLink($input: AdminForgetPasswordLinkInput!) {\n    adminForgetPasswordLink(input: $input) {\n      forgetPasswordLink\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation AdminAddUserToTeam($input: AdminAddUserToTeamInput!) {\n    adminAddUserToTeam(input: $input) {\n      user {\n        ...AdminUser\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AdminAddUserToTeam($input: AdminAddUserToTeamInput!) {\n    adminAddUserToTeam(input: $input) {\n      user {\n        ...AdminUser\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation AdminDeleteUser($input: AdminDeleteUserInput!) {\n    adminDeleteUser(input: $input) {\n      user {\n        ...AdminUser\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AdminDeleteUser($input: AdminDeleteUserInput!) {\n    adminDeleteUser(input: $input) {\n      user {\n        ...AdminUser\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment AdminInstituteGroup on GroupNode {\n    id\n    name\n    type {\n      name\n    }\n    year\n    avatar\n    icon {\n      icon\n    }\n    users {\n      id\n    }\n    institute {\n      country\n      internalId\n    }\n  }\n',
): (typeof documents)['\n  fragment AdminInstituteGroup on GroupNode {\n    id\n    name\n    type {\n      name\n    }\n    year\n    avatar\n    icon {\n      icon\n    }\n    users {\n      id\n    }\n    institute {\n      country\n      internalId\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment AdminInstitute on InstituteNode {\n    id\n    name\n    street\n    internalId\n    city\n    zip\n    country\n    website\n    type {\n      id\n      name\n    }\n    marketProfile {\n      id\n      name\n    }\n    groups {\n      ...AdminInstituteGroup\n    }\n    instituteActivity {\n      ...InstituteActivityType\n    }\n  }\n',
): (typeof documents)['\n  fragment AdminInstitute on InstituteNode {\n    id\n    name\n    street\n    internalId\n    city\n    zip\n    country\n    website\n    type {\n      id\n      name\n    }\n    marketProfile {\n      id\n      name\n    }\n    groups {\n      ...AdminInstituteGroup\n    }\n    instituteActivity {\n      ...InstituteActivityType\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query AdminGetInstitute($id: ID!) {\n    institute(id: $id) {\n      ...AdminInstitute\n    }\n  }\n',
): (typeof documents)['\n  query AdminGetInstitute($id: ID!) {\n    institute(id: $id) {\n      ...AdminInstitute\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation AdminUpdateInstitute($input: AdminUpdateInstituteInput!) {\n    adminUpdateInstitute(input: $input) {\n      institute {\n        ...AdminInstitute\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AdminUpdateInstitute($input: AdminUpdateInstituteInput!) {\n    adminUpdateInstitute(input: $input) {\n      institute {\n        ...AdminInstitute\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment InstituteTargeting on InstituteTargetingNode {\n    id\n    location\n    activity\n  }\n',
): (typeof documents)['\n  fragment InstituteTargeting on InstituteTargetingNode {\n    id\n    location\n    activity\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query InstitutesTargeting($targetingFilters: [TargetingFilterInput]) {\n    institutesTargeting(targetingFilters: $targetingFilters) {\n      usersCount\n      activeGroupsCount\n      inactiveGroupsCount\n      institutes {\n        ...InstituteTargeting\n      }\n    }\n  }\n',
): (typeof documents)['\n  query InstitutesTargeting($targetingFilters: [TargetingFilterInput]) {\n    institutesTargeting(targetingFilters: $targetingFilters) {\n      usersCount\n      activeGroupsCount\n      inactiveGroupsCount\n      institutes {\n        ...InstituteTargeting\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment AuthUser on UserNode {\n    ...User\n    email\n    phoneCountryCode\n    phone\n    emailValidated\n    communicationLanguage\n    isSuperuser\n    countryOfResidence\n    inviteCode\n    externalTokens {\n      intercom\n    }\n  }\n',
): (typeof documents)['\n  fragment AuthUser on UserNode {\n    ...User\n    email\n    phoneCountryCode\n    phone\n    emailValidated\n    communicationLanguage\n    isSuperuser\n    countryOfResidence\n    inviteCode\n    externalTokens {\n      intercom\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment AuthUserGroup on UserGroupNode {\n    ...UserGroup\n    user {\n      ...AuthUser\n    }\n    isYearbookTeamMember\n    isCoreTeamMember\n  }\n',
): (typeof documents)['\n  fragment AuthUserGroup on UserGroupNode {\n    ...UserGroup\n    user {\n      ...AuthUser\n    }\n    isYearbookTeamMember\n    isCoreTeamMember\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Me {\n    me {\n      ...AuthUser\n    }\n  }\n',
): (typeof documents)['\n  query Me {\n    me {\n      ...AuthUser\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query AuthUserGroup($id: ID!) {\n    userGroup(id: $id) {\n      ...AuthUserGroup\n    }\n  }\n',
): (typeof documents)['\n  query AuthUserGroup($id: ID!) {\n    userGroup(id: $id) {\n      ...AuthUserGroup\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateUser($input: CreateUserInput!) {\n    createUser(input: $input) {\n      user {\n        ...AuthUser\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateUser($input: CreateUserInput!) {\n    createUser(input: $input) {\n      user {\n        ...AuthUser\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateToken($input: ObtainJSONWebTokenInput!) {\n    createToken(input: $input) {\n      token\n      refreshToken\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateToken($input: ObtainJSONWebTokenInput!) {\n    createToken(input: $input) {\n      token\n      refreshToken\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateMe($input: UpdateMeInput!) {\n    updateMe(input: $input) {\n      errors {\n        field\n        messages\n      }\n      user {\n        ...AuthUser\n      }\n      token\n      refreshToken\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateMe($input: UpdateMeInput!) {\n    updateMe(input: $input) {\n      errors {\n        field\n        messages\n      }\n      user {\n        ...AuthUser\n      }\n      token\n      refreshToken\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation ForgetPassword($input: ForgetPasswordInput!) {\n    forgetPassword(input: $input) {\n      errors {\n        field\n        messages\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation ForgetPassword($input: ForgetPasswordInput!) {\n    forgetPassword(input: $input) {\n      errors {\n        field\n        messages\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation ResetPassword($input: ResetPasswordInput!) {\n    resetPassword(input: $input) {\n      errors {\n        field\n        messages\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation ResetPassword($input: ResetPasswordInput!) {\n    resetPassword(input: $input) {\n      errors {\n        field\n        messages\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteUser($input: DeleteUserInput!) {\n    deleteUser(input: $input) {\n      user {\n        ...AuthUser\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteUser($input: DeleteUserInput!) {\n    deleteUser(input: $input) {\n      user {\n        ...AuthUser\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query UserExists(\n    $email: String!\n    $phoneCountryCode: String!\n    $phone: String!\n  ) {\n    userExists(\n      email: $email\n      phoneCountryCode: $phoneCountryCode\n      phone: $phone\n    ) {\n      emailExists\n      phoneExists\n    }\n  }\n',
): (typeof documents)['\n  query UserExists(\n    $email: String!\n    $phoneCountryCode: String!\n    $phone: String!\n  ) {\n    userExists(\n      email: $email\n      phoneCountryCode: $phoneCountryCode\n      phone: $phone\n    ) {\n      emailExists\n      phoneExists\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment EventAttendee on EventAttendeeNode {\n      id\n      user {\n        id\n        firstName\n        lastName\n        avatar\n        email\n      }\n    }\n',
): (typeof documents)['\n  fragment EventAttendee on EventAttendeeNode {\n      id\n      user {\n        id\n        firstName\n        lastName\n        avatar\n        email\n      }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Event on EventNode {\n    title\n    id\n    location\n    companyName\n    companyLogo\n    bannerImage\n    isSponsored\n    companyName\n    eventAuthor\n    authorUser {\n      id\n      firstName\n      lastName \n      avatar\n      email\n    }\n    authorGroup {\n      id\n      name\n      avatar\n    }\n    authorTeam {\n      id\n      name\n      avatar\n    }\n    authorType\n    eventType\n    description\n    startDateTime\n    endDateTime\n    isAllDay\n    isJoinable\n    isPublic\n    attendees {\n      ...EventAttendee\n    }\n  }\n',
): (typeof documents)['\n  fragment Event on EventNode {\n    title\n    id\n    location\n    companyName\n    companyLogo\n    bannerImage\n    isSponsored\n    companyName\n    eventAuthor\n    authorUser {\n      id\n      firstName\n      lastName \n      avatar\n      email\n    }\n    authorGroup {\n      id\n      name\n      avatar\n    }\n    authorTeam {\n      id\n      name\n      avatar\n    }\n    authorType\n    eventType\n    description\n    startDateTime\n    endDateTime\n    isAllDay\n    isJoinable\n    isPublic\n    attendees {\n      ...EventAttendee\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ExternalEventAttendee on ExternalEventAttendeeNode {\n    id\n    attendee {\n      email\n    }\n    platform\n    eventId\n  }\n',
): (typeof documents)['\n  fragment ExternalEventAttendee on ExternalEventAttendeeNode {\n    id\n    attendee {\n      email\n    }\n    platform\n    eventId\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Events($after: String, $first: Int, $fromDate: Date, $isPublic: Boolean) {\n    events(after: $after, first: $first, fromDate: $fromDate, isPublic: $isPublic) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Event\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query Events($after: String, $first: Int, $fromDate: Date, $isPublic: Boolean) {\n    events(after: $after, first: $first, fromDate: $fromDate, isPublic: $isPublic) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Event\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Event($id: ID!) {\n    event(id: $id) {\n      ...Event\n    }\n  }\n',
): (typeof documents)['\n  query Event($id: ID!) {\n    event(id: $id) {\n      ...Event\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateEvent($input: CreateEventInput!) {\n    createEvent(input: $input) {\n      event {\n        ...Event\n      }\n\n      errors {\n        field\n        messages\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateEvent($input: CreateEventInput!) {\n    createEvent(input: $input) {\n      event {\n        ...Event\n      }\n\n      errors {\n        field\n        messages\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation JoinEvent($input: JoinEventInput!) {\n    joinEvent(input: $input) {\n      attendee {\n        id\n        event {\n          ...Event\n        }\n        user {\n          id\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation JoinEvent($input: JoinEventInput!) {\n    joinEvent(input: $input) {\n      attendee {\n        id\n        event {\n          ...Event\n        }\n        user {\n          id\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation LeaveEvent($input: LeaveEventInput!) {\n    leaveEvent(input: $input) {\n      success\n    }\n  }\n',
): (typeof documents)['\n  mutation LeaveEvent($input: LeaveEventInput!) {\n    leaveEvent(input: $input) {\n      success\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation EditEvent($input: EditEventInput!) {\n    editEvent(input: $input) {\n      event {\n        ...Event\n      }\n\n      errors {\n        field\n        messages\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation EditEvent($input: EditEventInput!) {\n    editEvent(input: $input) {\n      event {\n        ...Event\n      }\n\n      errors {\n        field\n        messages\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteEvent($input: DeleteEventInput!) {\n    deleteEvent(input: $input) {\n      success\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteEvent($input: DeleteEventInput!) {\n    deleteEvent(input: $input) {\n      success\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation SetEventBanner($input: SetEventBannerInput!) {\n    setEventBanner(input: $input) {\n      event {\n        ...Event\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation SetEventBanner($input: SetEventBannerInput!) {\n    setEventBanner(input: $input) {\n      event {\n        ...Event\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation SaveExternalEvent($input: SaveExternalEventInput!) {\n    saveExternalEvent(input: $input) {\n      externalEvent {\n        ...ExternalEventAttendee\n      }\n      success\n    }\n  }\n',
): (typeof documents)['\n  mutation SaveExternalEvent($input: SaveExternalEventInput!) {\n    saveExternalEvent(input: $input) {\n      externalEvent {\n        ...ExternalEventAttendee\n      }\n      success\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation RemoveExternalEvent($input: RemoveExternalEventInput!) {\n    removeExternalEvent(input: $input) {\n      externalEvent {\n        ...ExternalEventAttendee\n      }\n      success\n    }\n  }\n',
): (typeof documents)['\n  mutation RemoveExternalEvent($input: RemoveExternalEventInput!) {\n    removeExternalEvent(input: $input) {\n      externalEvent {\n        ...ExternalEventAttendee\n      }\n      success\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation ToggleExternalEventSave($input: ToggleExternalEventSaveInput!) {\n    toggleExternalEventSave(input: $input) {\n      externalEvent {\n        ...ExternalEventAttendee\n      }\n      success\n    }\n  }\n',
): (typeof documents)['\n  mutation ToggleExternalEventSave($input: ToggleExternalEventSaveInput!) {\n    toggleExternalEventSave(input: $input) {\n      externalEvent {\n        ...ExternalEventAttendee\n      }\n      success\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CityLocation on CityLocationNode {\n    region\n    country\n  }\n',
): (typeof documents)['\n  fragment CityLocation on CityLocationNode {\n    region\n    country\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Campaign on CampaignNode {\n    id\n    name\n    utm\n    startDate\n    endDate\n    customer\n    status\n    orderId\n    type\n    isDeleted\n  }\n',
): (typeof documents)['\n  fragment Campaign on CampaignNode {\n    id\n    name\n    utm\n    startDate\n    endDate\n    customer\n    status\n    orderId\n    type\n    isDeleted\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Asset on AssetNode {\n    id\n    assetId\n    channelType\n    name\n    channelData {\n      trackingUrl\n      imageLarge\n      imageSmall\n    }\n    targetingConfig\n    startDate\n    endDate\n    visibility\n    targetingFilters {\n      location {\n        country\n        zip\n        region\n        city\n        radius\n      }\n      user {\n        gender\n        registration {\n          date\n          operator\n        }\n        userIds\n      }\n      group {\n        graduationType\n        instituteType\n        graduationYear\n        members {\n          value\n          operator\n        }\n        groupIds\n        teams\n      }\n      type\n      operator\n    }\n    totalViews\n    totalClicks\n    conversionRate\n  }\n',
): (typeof documents)['\n  fragment Asset on AssetNode {\n    id\n    assetId\n    channelType\n    name\n    channelData {\n      trackingUrl\n      imageLarge\n      imageSmall\n    }\n    targetingConfig\n    startDate\n    endDate\n    visibility\n    targetingFilters {\n      location {\n        country\n        zip\n        region\n        city\n        radius\n      }\n      user {\n        gender\n        registration {\n          date\n          operator\n        }\n        userIds\n      }\n      group {\n        graduationType\n        instituteType\n        graduationYear\n        members {\n          value\n          operator\n        }\n        groupIds\n        teams\n      }\n      type\n      operator\n    }\n    totalViews\n    totalClicks\n    conversionRate\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment MatchedAsset on MatchedAssetNode {\n    id\n    assetId\n    channelType\n    name\n    channelData {\n      trackingUrl\n      imageLarge\n      imageSmall\n    }\n    targetingConfig\n    startDate\n    endDate\n    visibility\n    isActive\n    targetingFilters {\n      location {\n        country\n        zip\n        region\n        city\n        radius\n      }\n      user {\n        gender\n        registration {\n          date\n          operator\n        }\n        userIds\n      }\n      group {\n        graduationType\n        instituteType\n        graduationYear\n        members {\n          value\n          operator\n        }\n        groupIds\n        teams\n      }\n      type\n      operator\n    }\n  }\n',
): (typeof documents)['\n  fragment MatchedAsset on MatchedAssetNode {\n    id\n    assetId\n    channelType\n    name\n    channelData {\n      trackingUrl\n      imageLarge\n      imageSmall\n    }\n    targetingConfig\n    startDate\n    endDate\n    visibility\n    isActive\n    targetingFilters {\n      location {\n        country\n        zip\n        region\n        city\n        radius\n      }\n      user {\n        gender\n        registration {\n          date\n          operator\n        }\n        userIds\n      }\n      group {\n        graduationType\n        instituteType\n        graduationYear\n        members {\n          value\n          operator\n        }\n        groupIds\n        teams\n      }\n      type\n      operator\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CampaignDetails on CampaignNode {\n    id\n    name\n    utm\n    startDate\n    endDate\n    type\n    customer\n    orderId\n    totalViews\n    totalClicks\n    status\n    assets {\n      ...Asset\n    }\n    conversionRate\n  }\n',
): (typeof documents)['\n  fragment CampaignDetails on CampaignNode {\n    id\n    name\n    utm\n    startDate\n    endDate\n    type\n    customer\n    orderId\n    totalViews\n    totalClicks\n    status\n    assets {\n      ...Asset\n    }\n    conversionRate\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CountryData on CountryData {\n    country\n    counts {\n      date\n      count\n    }\n  }\n',
): (typeof documents)['\n  fragment CountryData on CountryData {\n    country\n    counts {\n      date\n      count\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Statistics on StatisticsNode {\n    institutesCount\n    usersCount\n    activeGroupsCount\n    inactiveGroupsCount\n  }\n',
): (typeof documents)['\n  fragment Statistics on StatisticsNode {\n    institutesCount\n    usersCount\n    activeGroupsCount\n    inactiveGroupsCount\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Campaigns(\n    $offset: Int\n    $before: String\n    $after: String\n    $first: Int\n    $last: Int\n    $searchFilter: String\n    $orderBy: String\n  ) {\n    campaigns(\n      offset: $offset\n      before: $before\n      after: $after\n      first: $first\n      last: $last\n      searchFilter: $searchFilter\n      orderBy: $orderBy\n    ) {\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n      edges {\n        cursor\n        node {\n          ...Campaign\n        }\n      }\n      totalCount\n    }\n  }\n',
): (typeof documents)['\n  query Campaigns(\n    $offset: Int\n    $before: String\n    $after: String\n    $first: Int\n    $last: Int\n    $searchFilter: String\n    $orderBy: String\n  ) {\n    campaigns(\n      offset: $offset\n      before: $before\n      after: $after\n      first: $first\n      last: $last\n      searchFilter: $searchFilter\n      orderBy: $orderBy\n    ) {\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n      edges {\n        cursor\n        node {\n          ...Campaign\n        }\n      }\n      totalCount\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Campaign($id: ID!) {\n    campaign(id: $id) {\n      ...CampaignDetails\n    }\n  }\n',
): (typeof documents)['\n  query Campaign($id: ID!) {\n    campaign(id: $id) {\n      ...CampaignDetails\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateBannerAsset($input: CreateBannerAssetInput!) {\n    createBannerAsset(input: $input) {\n      asset {\n        ...Asset\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateBannerAsset($input: CreateBannerAssetInput!) {\n    createBannerAsset(input: $input) {\n      asset {\n        ...Asset\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateCampaign($input: CreateCampaignInput!) {\n    createCampaign(input: $input) {\n      campaign {\n        ...Campaign\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateCampaign($input: CreateCampaignInput!) {\n    createCampaign(input: $input) {\n      campaign {\n        ...Campaign\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Asset($id: ID!) {\n    asset(id: $id) {\n      ...Asset\n    }\n  }\n',
): (typeof documents)['\n  query Asset($id: ID!) {\n    asset(id: $id) {\n      ...Asset\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation EditBannerAsset($input: EditBannerAdAssetInput!) {\n    editBannerAdAsset(input: $input) {\n      asset {\n        ...Asset\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation EditBannerAsset($input: EditBannerAdAssetInput!) {\n    editBannerAdAsset(input: $input) {\n      asset {\n        ...Asset\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DuplicateBannerAsset($input: DuplicateAssetInput!) {\n    duplicateAsset(input: $input) {\n      newAsset {\n        ...Asset\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation DuplicateBannerAsset($input: DuplicateAssetInput!) {\n    duplicateAsset(input: $input) {\n      newAsset {\n        ...Asset\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteBannerAsset($input: DeleteAssetInput!) {\n    deleteAsset(input: $input) {\n      success\n      assetId\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteBannerAsset($input: DeleteAssetInput!) {\n    deleteAsset(input: $input) {\n      success\n      assetId\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query BestMatchAsset($channelType: ChannelType, $userId: String) {\n    bestMatchAssets(channelType: $channelType, userId: $userId) {\n      ...MatchedAsset\n    }\n  }\n',
): (typeof documents)['\n  query BestMatchAsset($channelType: ChannelType, $userId: String) {\n    bestMatchAssets(channelType: $channelType, userId: $userId) {\n      ...MatchedAsset\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateAssetEvent($input: CreateAssetEventInput!) {\n    createAssetEvent(input: $input) {\n      success\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateAssetEvent($input: CreateAssetEventInput!) {\n    createAssetEvent(input: $input) {\n      success\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Regions($country: String) {\n    regions(country: $country) {\n      ...CityLocation\n    }\n  }\n',
): (typeof documents)['\n  query Regions($country: String) {\n    regions(country: $country) {\n      ...CityLocation\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation EditCampaign($input: EditCampaignInput!) {\n    editCampaign(input: $input) {\n      campaign {\n        ...Campaign\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation EditCampaign($input: EditCampaignInput!) {\n    editCampaign(input: $input) {\n      campaign {\n        ...Campaign\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteCampaign($input: DeleteCampaignInput!) {\n    deleteCampaign(input: $input) {\n      success\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteCampaign($input: DeleteCampaignInput!) {\n    deleteCampaign(input: $input) {\n      success\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Statistics($filter: DashboardFilterInput) {\n    statistics(filter: $filter) {\n      ...Statistics\n    }\n  }\n',
): (typeof documents)['\n  query Statistics($filter: DashboardFilterInput) {\n    statistics(filter: $filter) {\n      ...Statistics\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Signups($filter: DashboardFilterInput) {\n    signups(filter: $filter) {\n      ...CountryData\n    }\n  }\n',
): (typeof documents)['\n  query Signups($filter: DashboardFilterInput) {\n    signups(filter: $filter) {\n      ...CountryData\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query KPIs($filter: DashboardFilterInput) {\n    kpis(filter: $filter) {\n      averageStudentsPerGroup\n      groupTimeToActive\n      profileQuestionsPerYear {\n        year\n        average\n        instances\n        groups\n      }\n      quotesPerGroupPerYear {\n        year\n        average\n        instances\n        groups\n      }\n      rankingQuestionsPerGroupPerYear {\n        year\n        average\n        instances\n        groups\n      }\n      albumsPerGroupPerYear {\n        year\n        average\n        instances\n        groups\n      }\n      photosPerGroupPerYear {\n        year\n        average\n        instances\n        groups\n      }\n    }\n  }\n',
): (typeof documents)['\n  query KPIs($filter: DashboardFilterInput) {\n    kpis(filter: $filter) {\n      averageStudentsPerGroup\n      groupTimeToActive\n      profileQuestionsPerYear {\n        year\n        average\n        instances\n        groups\n      }\n      quotesPerGroupPerYear {\n        year\n        average\n        instances\n        groups\n      }\n      rankingQuestionsPerGroupPerYear {\n        year\n        average\n        instances\n        groups\n      }\n      albumsPerGroupPerYear {\n        year\n        average\n        instances\n        groups\n      }\n      photosPerGroupPerYear {\n        year\n        average\n        instances\n        groups\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Color on ColorNode {\n    id\n    hex\n  }\n',
): (typeof documents)['\n  fragment Color on ColorNode {\n    id\n    hex\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Icon on IconNode {\n    id\n    icon\n  }\n',
): (typeof documents)['\n  fragment Icon on IconNode {\n    id\n    icon\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DefaultAvatar on DefaultAvatarNode {\n    id\n    avatar\n  }\n',
): (typeof documents)['\n  fragment DefaultAvatar on DefaultAvatarNode {\n    id\n    avatar\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GroupIcon on GroupIconNode {\n    id\n    icon\n  }\n',
): (typeof documents)['\n  fragment GroupIcon on GroupIconNode {\n    id\n    icon\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Colors($after: String, $first: Int!) {\n    colors(after: $after, first: $first) {\n      edges {\n        node {\n          ...Color\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query Colors($after: String, $first: Int!) {\n    colors(after: $after, first: $first) {\n      edges {\n        node {\n          ...Color\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Icons($after: String, $first: Int!) {\n    icons(after: $after, first: $first) {\n      edges {\n        node {\n          ...Icon\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query Icons($after: String, $first: Int!) {\n    icons(after: $after, first: $first) {\n      edges {\n        node {\n          ...Icon\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query DefaultAvatars($after: String, $first: Int!) {\n    defaultAvatars(after: $after, first: $first) {\n      edges {\n        node {\n          ...DefaultAvatar\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query DefaultAvatars($after: String, $first: Int!) {\n    defaultAvatars(after: $after, first: $first) {\n      edges {\n        node {\n          ...DefaultAvatar\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GroupIcons($after: String, $first: Int!) {\n    groupIcons(after: $after, first: $first) {\n      edges {\n        node {\n          ...GroupIcon\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GroupIcons($after: String, $first: Int!) {\n    groupIcons(after: $after, first: $first) {\n      edges {\n        node {\n          ...GroupIcon\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GraduationType on GraduationTypeNode {\n    id\n    name\n  }\n',
): (typeof documents)['\n  fragment GraduationType on GraduationTypeNode {\n    id\n    name\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Group on GroupNode {\n    id\n    name\n    avatar\n    icon {\n      ...GroupIcon\n    }\n    type {\n      ...GraduationType\n    }\n    year\n    token\n    created\n    institute {\n      id\n      internalId\n      type {\n        id\n        name\n      }\n      country\n      name\n      street\n      zip\n      city\n    }\n  }\n',
): (typeof documents)['\n  fragment Group on GroupNode {\n    id\n    name\n    avatar\n    icon {\n      ...GroupIcon\n    }\n    type {\n      ...GraduationType\n    }\n    year\n    token\n    created\n    institute {\n      id\n      internalId\n      type {\n        id\n        name\n      }\n      country\n      name\n      street\n      zip\n      city\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment UserGroup on UserGroupNode {\n    id\n    role\n    avatar\n    defaultAvatar {\n      ...DefaultAvatar\n    }\n    status\n    user {\n      ...User\n    }\n    created\n  }\n',
): (typeof documents)['\n  fragment UserGroup on UserGroupNode {\n    id\n    role\n    avatar\n    defaultAvatar {\n      ...DefaultAvatar\n    }\n    status\n    user {\n      ...User\n    }\n    created\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GroupUser on UserGroupNode {\n    ...UserGroup\n    group {\n      ...Group\n    }\n  }\n',
): (typeof documents)['\n  fragment GroupUser on UserGroupNode {\n    ...UserGroup\n    group {\n      ...Group\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GroupSample on GroupNode {\n    ...Group\n    usersCount\n    sampleMembers {\n      ...UserGroup\n    }\n  }\n',
): (typeof documents)['\n  fragment GroupSample on GroupNode {\n    ...Group\n    usersCount\n    sampleMembers {\n      ...UserGroup\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GroupDetail on GroupNode {\n    ...Group\n    usersCount\n    institute {\n      ...Institute\n    }\n  }\n',
): (typeof documents)['\n  fragment GroupDetail on GroupNode {\n    ...Group\n    usersCount\n    institute {\n      ...Institute\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GraduationTypes($after: String, $first: Int!) {\n    graduationTypes(after: $after, first: $first) {\n      edges {\n        node {\n          ...GraduationType\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GraduationTypes($after: String, $first: Int!) {\n    graduationTypes(after: $after, first: $first) {\n      edges {\n        node {\n          ...GraduationType\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GraduationTypesCountry($id: String, $name: String, $country: String) {\n    graduationTypesCountry(id: $id, name: $name, country: $country) {\n      id\n      name\n      country\n    }\n  }\n',
): (typeof documents)['\n  query GraduationTypesCountry($id: String, $name: String, $country: String) {\n    graduationTypesCountry(id: $id, name: $name, country: $country) {\n      id\n      name\n      country\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment YearbookStatistics on YearbookStatisticsNode {\n    profilePagesFilledIn\n    usersWithQuote\n    lastDeadline\n    contentModules\n    estimatedPages\n    imagesUploaded\n    wordCount\n  }\n',
): (typeof documents)['\n  fragment YearbookStatistics on YearbookStatisticsNode {\n    profilePagesFilledIn\n    usersWithQuote\n    lastDeadline\n    contentModules\n    estimatedPages\n    imagesUploaded\n    wordCount\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GroupStatistics on GroupNode {\n    id\n    yearbookStatistics {\n      ...YearbookStatistics\n    }\n  }\n',
): (typeof documents)['\n  fragment GroupStatistics on GroupNode {\n    id\n    yearbookStatistics {\n      ...YearbookStatistics\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Groups(\n    $after: String\n    $first: Int!\n    $name: String\n    $type: ID\n    $year: Int\n    $institute: ID\n    $marketProfile: ID\n  ) {\n    groups(\n      after: $after\n      first: $first\n      name: $name\n      type: $type\n      year: $year\n      institute: $institute\n      marketProfile: $marketProfile\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Group\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query Groups(\n    $after: String\n    $first: Int!\n    $name: String\n    $type: ID\n    $year: Int\n    $institute: ID\n    $marketProfile: ID\n  ) {\n    groups(\n      after: $after\n      first: $first\n      name: $name\n      type: $type\n      year: $year\n      institute: $institute\n      marketProfile: $marketProfile\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Group\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Group($id: ID!) {\n    group(id: $id) {\n      ...GroupDetail\n      teams {\n        id\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  query Group($id: ID!) {\n    group(id: $id) {\n      ...GroupDetail\n      teams {\n        id\n        name\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GroupSample($id: ID!) {\n    group(id: $id) {\n      ...GroupSample\n    }\n  }\n',
): (typeof documents)['\n  query GroupSample($id: ID!) {\n    group(id: $id) {\n      ...GroupSample\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GroupStatistics($id: ID!) {\n    group(id: $id) {\n      ...GroupStatistics\n    }\n  }\n',
): (typeof documents)['\n  query GroupStatistics($id: ID!) {\n    group(id: $id) {\n      ...GroupStatistics\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation AddUserToGroup($input: AddUserToGroupInput!) {\n    addUserToGroup(input: $input) {\n      userGroup {\n        ...UserGroup\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AddUserToGroup($input: AddUserToGroupInput!) {\n    addUserToGroup(input: $input) {\n      userGroup {\n        ...UserGroup\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation AcceptUserInGroup($input: AcceptUserInGroupInput!) {\n    acceptUserInGroup(input: $input) {\n      userGroup {\n        ...UserGroup\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AcceptUserInGroup($input: AcceptUserInGroupInput!) {\n    acceptUserInGroup(input: $input) {\n      userGroup {\n        ...UserGroup\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation RemoveUserFromGroup($input: RemoveUserFromGroupInput!) {\n    removeUserFromGroup(input: $input) {\n      userGroup {\n        ...UserGroup\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation RemoveUserFromGroup($input: RemoveUserFromGroupInput!) {\n    removeUserFromGroup(input: $input) {\n      userGroup {\n        ...UserGroup\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query UserGroups(\n    $after: String\n    $first: Int!\n    $group: ID\n    $status: GroupsUserGroupStatusChoices\n  ) {\n    userGroups(after: $after, first: $first, group: $group, status: $status) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...UserGroup\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query UserGroups(\n    $after: String\n    $first: Int!\n    $group: ID\n    $status: GroupsUserGroupStatusChoices\n  ) {\n    userGroups(after: $after, first: $first, group: $group, status: $status) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...UserGroup\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GroupUsers(\n    $after: String\n    $first: Int!\n    $user: ID\n    $status: GroupsUserGroupStatusChoices\n  ) {\n    userGroups(after: $after, first: $first, user: $user, status: $status) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...GroupUser\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GroupUsers(\n    $after: String\n    $first: Int!\n    $user: ID\n    $status: GroupsUserGroupStatusChoices\n  ) {\n    userGroups(after: $after, first: $first, user: $user, status: $status) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...GroupUser\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query UserGroup($id: ID!) {\n    userGroup(id: $id) {\n      ...UserGroup\n    }\n  }\n',
): (typeof documents)['\n  query UserGroup($id: ID!) {\n    userGroup(id: $id) {\n      ...UserGroup\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateGroup($input: CreateGroupInput!) {\n    createGroup(input: $input) {\n      group {\n        ...Group\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateGroup($input: CreateGroupInput!) {\n    createGroup(input: $input) {\n      group {\n        ...Group\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateGroup($input: UpdateGroupInput!) {\n    updateGroup(input: $input) {\n      group {\n        ...Group\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateGroup($input: UpdateGroupInput!) {\n    updateGroup(input: $input) {\n      group {\n        ...Group\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation SetGroupAvatar($input: SetGroupAvatarInput!) {\n    setGroupAvatar(input: $input) {\n      group {\n        ...Group\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation SetGroupAvatar($input: SetGroupAvatarInput!) {\n    setGroupAvatar(input: $input) {\n      group {\n        ...Group\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation SetUserGroupAvatar($input: SetUserGroupAvatarInput!) {\n    setUserGroupAvatar(input: $input) {\n      userGroup {\n        ...UserGroup\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation SetUserGroupAvatar($input: SetUserGroupAvatarInput!) {\n    setUserGroupAvatar(input: $input) {\n      userGroup {\n        ...UserGroup\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateUserGroup($input: UpdateUserGroupInput!) {\n    updateUserGroup(input: $input) {\n      userGroup {\n        ...UserGroup\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateUserGroup($input: UpdateUserGroupInput!) {\n    updateUserGroup(input: $input) {\n      userGroup {\n        ...UserGroup\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation ImportToErp($input: ImportToErpInput!) {\n    importToErp(input: $input) {\n      success\n    }\n  }\n',
): (typeof documents)['\n  mutation ImportToErp($input: ImportToErpInput!) {\n    importToErp(input: $input) {\n      success\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment LeaderboardUser on LeaderboardUserNode {\n    user {\n      ...User\n    }\n    points\n    position\n  }\n',
): (typeof documents)['\n  fragment LeaderboardUser on LeaderboardUserNode {\n    user {\n      ...User\n    }\n    points\n    position\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query LeaderboardUsers(\n    $before: String\n    $after: String\n    $first: Int\n    $last: Int\n    $user: ID\n  ) {\n    leaderboardUsers(\n      before: $before\n      after: $after\n      first: $first\n      last: $last\n      user: $user\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...LeaderboardUser\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query LeaderboardUsers(\n    $before: String\n    $after: String\n    $first: Int\n    $last: Int\n    $user: ID\n  ) {\n    leaderboardUsers(\n      before: $before\n      after: $after\n      first: $first\n      last: $last\n      user: $user\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...LeaderboardUser\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation ImportFromAbihome(\n    $email: String!\n    $password: String!\n    $groupName: String!\n    $graduationType: String!\n    $graduationYear: Int!\n    $approved: Boolean\n    $iconId: ID\n    $avatar: Upload\n  ) {\n    importAbihome(\n      email: $email\n      password: $password\n      groupName: $groupName\n      graduationType: $graduationType\n      graduationYear: $graduationYear\n      approved: $approved\n      iconId: $iconId\n      avatar: $avatar\n    ) {\n      ok\n      userNumber\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation ImportFromAbihome(\n    $email: String!\n    $password: String!\n    $groupName: String!\n    $graduationType: String!\n    $graduationYear: Int!\n    $approved: Boolean\n    $iconId: ID\n    $avatar: Upload\n  ) {\n    importAbihome(\n      email: $email\n      password: $password\n      groupName: $groupName\n      graduationType: $graduationType\n      graduationYear: $graduationYear\n      approved: $approved\n      iconId: $iconId\n      avatar: $avatar\n    ) {\n      ok\n      userNumber\n      error\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment InstituteType on InstituteTypeNode {\n    id\n    name\n    country\n    graduationTypes {\n      id\n      name\n    }\n  }\n',
): (typeof documents)['\n  fragment InstituteType on InstituteTypeNode {\n    id\n    name\n    country\n    graduationTypes {\n      id\n      name\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Institute on InstituteNode {\n    id\n    internalId\n    name\n    street\n    zip\n    city\n    website\n    type {\n      ...InstituteType\n    }\n    country\n  }\n',
): (typeof documents)['\n  fragment Institute on InstituteNode {\n    id\n    internalId\n    name\n    street\n    zip\n    city\n    website\n    type {\n      ...InstituteType\n    }\n    country\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query InstituteTypes(\n    $after: String\n    $first: Int!\n    $name: String\n    $country: InstitutesInstituteCountryChoices\n  ) {\n    instituteTypes(\n      after: $after\n      first: $first\n      name: $name\n      country: $country\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...InstituteType\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query InstituteTypes(\n    $after: String\n    $first: Int!\n    $name: String\n    $country: InstitutesInstituteCountryChoices\n  ) {\n    instituteTypes(\n      after: $after\n      first: $first\n      name: $name\n      country: $country\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...InstituteType\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Institutes(\n    $after: String\n    $first: Int!\n    $name: String\n    $city: String\n    $type: ID\n    $country: InstitutesInstituteCountryChoices\n    $internalId: String\n    $offset: Int\n  ) {\n    institutes(\n      after: $after\n      first: $first\n      name: $name\n      city: $city\n      type: $type\n      country: $country\n      internalId: $internalId\n      offset: $offset\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Institute\n        }\n      }\n      totalCount\n    }\n  }\n',
): (typeof documents)['\n  query Institutes(\n    $after: String\n    $first: Int!\n    $name: String\n    $city: String\n    $type: ID\n    $country: InstitutesInstituteCountryChoices\n    $internalId: String\n    $offset: Int\n  ) {\n    institutes(\n      after: $after\n      first: $first\n      name: $name\n      city: $city\n      type: $type\n      country: $country\n      internalId: $internalId\n      offset: $offset\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Institute\n        }\n      }\n      totalCount\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Institute($id: ID!) {\n    institute(id: $id) {\n      ...Institute\n    }\n  }\n',
): (typeof documents)['\n  query Institute($id: ID!) {\n    institute(id: $id) {\n      ...Institute\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation RequestMissingInstitute($input: RequestMissingInstituteInput!) {\n    requestMissingInstitute(input: $input) {\n      responseCode\n      message\n    }\n  }\n',
): (typeof documents)['\n  mutation RequestMissingInstitute($input: RequestMissingInstituteInput!) {\n    requestMissingInstitute(input: $input) {\n      responseCode\n      message\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query CitiesZip($query: String, $country: String) {\n    citiesZip(query: $query, country: $country) {\n      name\n    }\n  }\n',
): (typeof documents)['\n  query CitiesZip($query: String, $country: String) {\n    citiesZip(query: $query, country: $country) {\n      name\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateInstitute($input: CreateInstituteInput!) {\n    createInstitute(input: $input) {\n      responseCode\n      message\n      institute {\n        ...Institute\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateInstitute($input: CreateInstituteInput!) {\n    createInstitute(input: $input) {\n      responseCode\n      message\n      institute {\n        ...Institute\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment InstituteRequest on InstituteRequestNode {\n    id\n    name\n    street\n    zip\n    city\n    type {\n      id\n      name\n    }\n    country\n    user {\n      id\n      firstName\n      lastName\n      email\n      avatar\n    }\n    status\n    website\n    created\n  }\n',
): (typeof documents)['\n  fragment InstituteRequest on InstituteRequestNode {\n    id\n    name\n    street\n    zip\n    city\n    type {\n      id\n      name\n    }\n    country\n    user {\n      id\n      firstName\n      lastName\n      email\n      avatar\n    }\n    status\n    website\n    created\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query InstituteRequests(\n    $offset: Int\n    $before: String\n    $after: String\n    $first: Int\n    $last: Int\n    $name: String\n    $status: Status\n    $type: ID\n    $street: String\n    $country: InstitutesInstituteRequestCountryChoices\n    $zip: String\n    $website: String\n  ) {\n    instituteRequests(\n      offset: $offset\n      before: $before\n      after: $after\n      first: $first\n      last: $last\n      name: $name\n      status: $status\n      type: $type\n      street: $street\n      country: $country\n      zip: $zip\n      website: $website\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...InstituteRequest\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query InstituteRequests(\n    $offset: Int\n    $before: String\n    $after: String\n    $first: Int\n    $last: Int\n    $name: String\n    $status: Status\n    $type: ID\n    $street: String\n    $country: InstitutesInstituteRequestCountryChoices\n    $zip: String\n    $website: String\n  ) {\n    instituteRequests(\n      offset: $offset\n      before: $before\n      after: $after\n      first: $first\n      last: $last\n      name: $name\n      status: $status\n      type: $type\n      street: $street\n      country: $country\n      zip: $zip\n      website: $website\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...InstituteRequest\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query InstituteRequest($id: ID!) {\n    instituteRequest(id: $id) {\n      ...InstituteRequest\n    }\n  }\n',
): (typeof documents)['\n  query InstituteRequest($id: ID!) {\n    instituteRequest(id: $id) {\n      ...InstituteRequest\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query FindDuplicateInstitutes(\n    $name: String\n    $street: String\n    $website: String\n    $customSearch: String\n  ) {\n    findDuplicateInstitutes(\n      name: $name\n      street: $street\n      website: $website\n      customSearch: $customSearch\n    ) {\n      ...Institute\n    }\n  }\n',
): (typeof documents)['\n  query FindDuplicateInstitutes(\n    $name: String\n    $street: String\n    $website: String\n    $customSearch: String\n  ) {\n    findDuplicateInstitutes(\n      name: $name\n      street: $street\n      website: $website\n      customSearch: $customSearch\n    ) {\n      ...Institute\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation AcceptInstituteRequest($input: AdminAcceptInstituteRequestInput!) {\n    adminAcceptInstituteRequest(input: $input) {\n      instituteRequest {\n        ...InstituteRequest\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AcceptInstituteRequest($input: AdminAcceptInstituteRequestInput!) {\n    adminAcceptInstituteRequest(input: $input) {\n      instituteRequest {\n        ...InstituteRequest\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation RejectInstituteRequest($input: AdminRejectInstituteRequestInput!) {\n    adminRejectInstituteRequest(input: $input) {\n      instituteRequest {\n        ...InstituteRequest\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation RejectInstituteRequest($input: AdminRejectInstituteRequestInput!) {\n    adminRejectInstituteRequest(input: $input) {\n      instituteRequest {\n        ...InstituteRequest\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteInstituteRequest($input: AdminDeleteInstituteRequestInput!) {\n    adminDeleteInstituteRequest(input: $input) {\n      success\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteInstituteRequest($input: AdminDeleteInstituteRequestInput!) {\n    adminDeleteInstituteRequest(input: $input) {\n      success\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment MarketProfile on MarketProfileNode {\n    id\n    name\n  }\n',
): (typeof documents)['\n  fragment MarketProfile on MarketProfileNode {\n    id\n    name\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query MarketProfiles {\n    marketProfiles {\n      edges {\n        node {\n          ...MarketProfile\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query MarketProfiles {\n    marketProfiles {\n      edges {\n        node {\n          ...MarketProfile\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Comment on CommentNode {\n    id\n    text\n    owner\n    userGroup {\n      ...UserGroup\n    }\n    team {\n      ...Team\n    }\n    likes(userGroup: $authUserGroupId) {\n      edges {\n        node {\n          userGroup {\n            id\n          }\n        }\n      }\n    }\n    likesCount\n    created\n  }\n',
): (typeof documents)['\n  fragment Comment on CommentNode {\n    id\n    text\n    owner\n    userGroup {\n      ...UserGroup\n    }\n    team {\n      ...Team\n    }\n    likes(userGroup: $authUserGroupId) {\n      edges {\n        node {\n          userGroup {\n            id\n          }\n        }\n      }\n    }\n    likesCount\n    created\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BasePost on PostNode {\n    id\n    owner\n    text\n    createdBy {\n      ...UserGroup\n    }\n    images\n    likesCount\n    team {\n      ...Team\n    }\n    comments {\n      ...Comment\n    }\n    created\n  }\n',
): (typeof documents)['\n  fragment BasePost on PostNode {\n    id\n    owner\n    text\n    createdBy {\n      ...UserGroup\n    }\n    images\n    likesCount\n    team {\n      ...Team\n    }\n    comments {\n      ...Comment\n    }\n    created\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PollVote on PollVoteNode {\n    id\n    userGroup {\n      user {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment PollVote on PollVoteNode {\n    id\n    userGroup {\n      user {\n        id\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PollOption on PollOptionNode {\n    id\n    text\n    image\n    votes {\n      ...PollVote\n    }\n    percentage\n    votesNumber\n  }\n',
): (typeof documents)['\n  fragment PollOption on PollOptionNode {\n    id\n    text\n    image\n    votes {\n      ...PollVote\n    }\n    percentage\n    votesNumber\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BasePoll on PollNode {\n    id\n    question\n    deadline\n    answersAllowed\n    randomizeOptions\n    allowRevote\n    allowComments\n    anonymousVotes\n    privateResults\n    options {\n      ...PollOption\n    }\n    created\n  }\n',
): (typeof documents)['\n  fragment BasePoll on PollNode {\n    id\n    question\n    deadline\n    answersAllowed\n    randomizeOptions\n    allowRevote\n    allowComments\n    anonymousVotes\n    privateResults\n    options {\n      ...PollOption\n    }\n    created\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Post on PostNode {\n    ...BasePost\n    poll {\n      ...BasePoll\n    }\n    likes(userGroup: $authUserGroupId) {\n      edges {\n        node {\n          userGroup {\n            id\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment Post on PostNode {\n    ...BasePost\n    poll {\n      ...BasePoll\n    }\n    likes(userGroup: $authUserGroupId) {\n      edges {\n        node {\n          userGroup {\n            id\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Poll on PollNode {\n    ...BasePoll\n    post {\n      ...BasePost\n      likes(userGroup: $authUserGroupId) {\n        edges {\n          node {\n            userGroup {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment Poll on PollNode {\n    ...BasePoll\n    post {\n      ...BasePost\n      likes(userGroup: $authUserGroupId) {\n        edges {\n          node {\n            userGroup {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Posts(\n    $after: String\n    $first: Int!\n    $owner: Owner\n    $createdBy: ID\n    $team: ID\n    $text: String\n    $createdBy_Group: ID\n    $authUserGroupId: ID!\n  ) {\n    posts(\n      after: $after\n      first: $first\n      owner: $owner\n      createdBy: $createdBy\n      team: $team\n      text: $text\n      createdBy_Group: $createdBy_Group\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Post\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query Posts(\n    $after: String\n    $first: Int!\n    $owner: Owner\n    $createdBy: ID\n    $team: ID\n    $text: String\n    $createdBy_Group: ID\n    $authUserGroupId: ID!\n  ) {\n    posts(\n      after: $after\n      first: $first\n      owner: $owner\n      createdBy: $createdBy\n      team: $team\n      text: $text\n      createdBy_Group: $createdBy_Group\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Post\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Post($id: ID!, $authUserGroupId: ID!) {\n    post(id: $id) {\n      ...Post\n    }\n  }\n',
): (typeof documents)['\n  query Post($id: ID!, $authUserGroupId: ID!) {\n    post(id: $id) {\n      ...Post\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreatePost($input: CreatePostInput!, $authUserGroupId: ID!) {\n    createPost(input: $input) {\n      post {\n        ...Post\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreatePost($input: CreatePostInput!, $authUserGroupId: ID!) {\n    createPost(input: $input) {\n      post {\n        ...Post\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeletePost($input: DeletePostInput!, $authUserGroupId: ID!) {\n    deletePost(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation DeletePost($input: DeletePostInput!, $authUserGroupId: ID!) {\n    deletePost(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation ReportPostContent($input: ReportPostContentInput!) {\n    reportPostContent(input: $input) {\n      success\n      message\n    }\n  }\n',
): (typeof documents)['\n  mutation ReportPostContent($input: ReportPostContentInput!) {\n    reportPostContent(input: $input) {\n      success\n      message\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation BlockUser($input: BlockUserMutationInput!) {\n    blockUser(input: $input) {\n      success\n      message\n    }\n  }\n',
): (typeof documents)['\n  mutation BlockUser($input: BlockUserMutationInput!) {\n    blockUser(input: $input) {\n      success\n      message\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation ReportMutation($input: ReportMutationInput!) {\n    reportMutation(input: $input) {\n      success\n      message\n    }\n  }\n',
): (typeof documents)['\n  mutation ReportMutation($input: ReportMutationInput!) {\n    reportMutation(input: $input) {\n      success\n      message\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation AddImagesToPost(\n    $input: AddImagesToPostInput!\n    $authUserGroupId: ID!\n  ) {\n    addImagesToPost(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AddImagesToPost(\n    $input: AddImagesToPostInput!\n    $authUserGroupId: ID!\n  ) {\n    addImagesToPost(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Polls(\n    $after: String\n    $first: Int!\n    $post_Team: ID\n    $post_CreatedBy_Group: ID\n    $authUserGroupId: ID!\n  ) {\n    polls(\n      after: $after\n      first: $first\n      post_Team: $post_Team\n      post_CreatedBy_Group: $post_CreatedBy_Group\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Poll\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query Polls(\n    $after: String\n    $first: Int!\n    $post_Team: ID\n    $post_CreatedBy_Group: ID\n    $authUserGroupId: ID!\n  ) {\n    polls(\n      after: $after\n      first: $first\n      post_Team: $post_Team\n      post_CreatedBy_Group: $post_CreatedBy_Group\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Poll\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreatePoll($input: CreatePollInput!, $authUserGroupId: ID!) {\n    createPoll(input: $input) {\n      poll {\n        ...Poll\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreatePoll($input: CreatePollInput!, $authUserGroupId: ID!) {\n    createPoll(input: $input) {\n      poll {\n        ...Poll\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeletePoll($input: DeletePollInput!, $authUserGroupId: ID!) {\n    deletePoll(input: $input) {\n      poll {\n        ...Poll\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation DeletePoll($input: DeletePollInput!, $authUserGroupId: ID!) {\n    deletePoll(input: $input) {\n      poll {\n        ...Poll\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreatePollOption($input: CreatePollOptionInput!) {\n    createPollOption(input: $input) {\n      pollOption {\n        ...PollOption\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreatePollOption($input: CreatePollOptionInput!) {\n    createPollOption(input: $input) {\n      pollOption {\n        ...PollOption\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreatePollVote($input: CreatePollVoteInput!) {\n    createPollVote(input: $input) {\n      pollVote {\n        ...PollVote\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreatePollVote($input: CreatePollVoteInput!) {\n    createPollVote(input: $input) {\n      pollVote {\n        ...PollVote\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdatePoll($input: UpdatePollInput!) {\n    updatePoll(input: $input) {\n      poll {\n        ...BasePoll\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdatePoll($input: UpdatePollInput!) {\n    updatePoll(input: $input) {\n      poll {\n        ...BasePoll\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeletePollVote($input: DeletePollVoteInput!) {\n    deletePollVote(input: $input) {\n      pollVote {\n        ...PollVote\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation DeletePollVote($input: DeletePollVoteInput!) {\n    deletePollVote(input: $input) {\n      pollVote {\n        ...PollVote\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation LikePost($input: LikePostInput!, $authUserGroupId: ID!) {\n    likePost(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation LikePost($input: LikePostInput!, $authUserGroupId: ID!) {\n    likePost(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation RemoveLikeFromPost(\n    $input: RemoveLikeFromPostInput!\n    $authUserGroupId: ID!\n  ) {\n    removeLikeFromPost(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation RemoveLikeFromPost(\n    $input: RemoveLikeFromPostInput!\n    $authUserGroupId: ID!\n  ) {\n    removeLikeFromPost(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CommentPost($input: CommentPostInput!, $authUserGroupId: ID!) {\n    commentPost(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CommentPost($input: CommentPostInput!, $authUserGroupId: ID!) {\n    commentPost(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation LikeComment($input: LikeCommentInput!, $authUserGroupId: ID!) {\n    likeComment(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation LikeComment($input: LikeCommentInput!, $authUserGroupId: ID!) {\n    likeComment(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation RemoveLikeFromComment(\n    $input: RemoveLikeFromCommentInput!\n    $authUserGroupId: ID!\n  ) {\n    removeLikeFromComment(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation RemoveLikeFromComment(\n    $input: RemoveLikeFromCommentInput!\n    $authUserGroupId: ID!\n  ) {\n    removeLikeFromComment(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteComment($input: DeleteCommentInput!, $authUserGroupId: ID!) {\n    deleteComment(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteComment($input: DeleteCommentInput!, $authUserGroupId: ID!) {\n    deleteComment(input: $input) {\n      post {\n        ...Post\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation GenerateShopifyUrl($input: GenerateShopifyRedirectUrlInput!) {\n    generateShopifyUrl(input: $input) {\n      redirectUrl\n    }\n  }\n',
): (typeof documents)['\n  mutation GenerateShopifyUrl($input: GenerateShopifyRedirectUrlInput!) {\n    generateShopifyUrl(input: $input) {\n      redirectUrl\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment TeamUser on UserTeamNode {\n    id\n    isAdmin\n    status\n    avatar\n    defaultAvatar\n    user {\n      ...User\n    }\n    created\n    modified\n  }\n',
): (typeof documents)['\n  fragment TeamUser on UserTeamNode {\n    id\n    isAdmin\n    status\n    avatar\n    defaultAvatar\n    user {\n      ...User\n    }\n    created\n    modified\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Team on TeamNode {\n    id\n    name\n    type\n    approvalSetting\n    color {\n      ...Color\n    }\n    icon {\n      ...Icon\n    }\n    isHidden\n    created\n  }\n',
): (typeof documents)['\n  fragment Team on TeamNode {\n    id\n    name\n    type\n    approvalSetting\n    color {\n      ...Color\n    }\n    icon {\n      ...Icon\n    }\n    isHidden\n    created\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment TeamSample on TeamNode {\n    ...Team\n    usersCount\n    sampleMembers {\n      ...TeamUser\n    }\n  }\n',
): (typeof documents)['\n  fragment TeamSample on TeamNode {\n    ...Team\n    usersCount\n    sampleMembers {\n      ...TeamUser\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment TeamDetail on TeamNode {\n    ...Team\n    users {\n      ...TeamUser\n    }\n  }\n',
): (typeof documents)['\n  fragment TeamDetail on TeamNode {\n    ...Team\n    users {\n      ...TeamUser\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Teams(\n    $after: String\n    $first: Int!\n    $name: String\n    $group: ID\n    $userGroupId: String\n  ) {\n    teams(\n      after: $after\n      first: $first\n      name: $name\n      group: $group\n      userGroupId: $userGroupId\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Team\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query Teams(\n    $after: String\n    $first: Int!\n    $name: String\n    $group: ID\n    $userGroupId: String\n  ) {\n    teams(\n      after: $after\n      first: $first\n      name: $name\n      group: $group\n      userGroupId: $userGroupId\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Team\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query TeamSamples($after: String, $first: Int!, $name: String, $group: ID) {\n    teams(after: $after, first: $first, name: $name, group: $group) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...TeamSample\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query TeamSamples($after: String, $first: Int!, $name: String, $group: ID) {\n    teams(after: $after, first: $first, name: $name, group: $group) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...TeamSample\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Team($id: ID!) {\n    team(id: $id) {\n      ...Team\n    }\n  }\n',
): (typeof documents)['\n  query Team($id: ID!) {\n    team(id: $id) {\n      ...Team\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query TeamDetail($id: ID!) {\n    team(id: $id) {\n      ...TeamDetail\n    }\n  }\n',
): (typeof documents)['\n  query TeamDetail($id: ID!) {\n    team(id: $id) {\n      ...TeamDetail\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation AddUserToTeam($input: AddUserToTeamInput!) {\n    addUserToTeam(input: $input) {\n      userTeam {\n        ...TeamUser\n      }\n      teams {\n        ...Team\n        avatar\n        group {\n          ...Group\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AddUserToTeam($input: AddUserToTeamInput!) {\n    addUserToTeam(input: $input) {\n      userTeam {\n        ...TeamUser\n      }\n      teams {\n        ...Team\n        avatar\n        group {\n          ...Group\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation RemoveUserFromTeam($input: RemoveUserFromTeamInput!) {\n    removeUserFromTeam(input: $input) {\n      userTeam {\n        ...TeamUser\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation RemoveUserFromTeam($input: RemoveUserFromTeamInput!) {\n    removeUserFromTeam(input: $input) {\n      userTeam {\n        ...TeamUser\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateTeam($input: CreateTeamInput!) {\n    createTeam(input: $input) {\n      team {\n        ...TeamDetail\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateTeam($input: CreateTeamInput!) {\n    createTeam(input: $input) {\n      team {\n        ...TeamDetail\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateTeam($input: UpdateTeamInput!) {\n    updateTeam(input: $input) {\n      team {\n        ...TeamDetail\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateTeam($input: UpdateTeamInput!) {\n    updateTeam(input: $input) {\n      team {\n        ...TeamDetail\n      }\n      errors {\n        field\n        messages\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DestroyTeam($input: DestroyTeamInput!) {\n    destroyTeam(input: $input) {\n      team {\n        ...TeamDetail\n      }\n      success\n    }\n  }\n',
): (typeof documents)['\n  mutation DestroyTeam($input: DestroyTeamInput!) {\n    destroyTeam(input: $input) {\n      team {\n        ...TeamDetail\n      }\n      success\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation ActivateTeam($input: ActivateTeamInput!) {\n    activateTeam(input: $input) {\n      team {\n        ...TeamDetail\n      }\n      success\n    }\n  }\n',
): (typeof documents)['\n  mutation ActivateTeam($input: ActivateTeamInput!) {\n    activateTeam(input: $input) {\n      team {\n        ...TeamDetail\n      }\n      success\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation AcceptUserInTeam($input: AcceptUserInTeamInput!) {\n    acceptUserInTeam(input: $input) {\n      userTeam {\n        ...TeamUser\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AcceptUserInTeam($input: AcceptUserInTeamInput!) {\n    acceptUserInTeam(input: $input) {\n      userTeam {\n        ...TeamUser\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query UserTeam($id: ID!) {\n    userTeam(id: $id) {\n      ...TeamUser\n    }\n  }\n',
): (typeof documents)['\n  query UserTeam($id: ID!) {\n    userTeam(id: $id) {\n      ...TeamUser\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment User on UserNode {\n    id\n    firstName\n    lastName\n    gender\n  }\n',
): (typeof documents)['\n  fragment User on UserNode {\n    id\n    firstName\n    lastName\n    gender\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment UserWithTeams on UserNode {\n    ...User\n    teams {\n      id\n      name\n      color {\n        hex\n      }\n      icon {\n        id\n        icon\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment UserWithTeams on UserNode {\n    ...User\n    teams {\n      id\n      name\n      color {\n        hex\n      }\n      icon {\n        id\n        icon\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Module on ModuleNode {\n    id\n    name\n    image\n    type\n    description\n    features\n  }\n',
): (typeof documents)['\n  fragment Module on ModuleNode {\n    id\n    name\n    image\n    type\n    description\n    features\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ModuleInstance on ModuleInstanceNode {\n    id\n    isActive\n    dueDate\n    module {\n      ...Module\n    }\n  }\n',
): (typeof documents)['\n  fragment ModuleInstance on ModuleInstanceNode {\n    id\n    isActive\n    dueDate\n    module {\n      ...Module\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PrewordInstance on PrewordInstanceNode {\n    id\n    text\n  }\n',
): (typeof documents)['\n  fragment PrewordInstance on PrewordInstanceNode {\n    id\n    text\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ProfilePageSetup on ProfilePageSetupNode {\n    id\n    photoFormat\n    maxChars\n    anonymousComments\n    visibleComments\n  }\n',
): (typeof documents)['\n  fragment ProfilePageSetup on ProfilePageSetupNode {\n    id\n    photoFormat\n    maxChars\n    anonymousComments\n    visibleComments\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment RankingsSetup on RankingsSetupNode {\n    id\n    resultsTeam {\n      ...Team\n    }\n  }\n',
): (typeof documents)['\n  fragment RankingsSetup on RankingsSetupNode {\n    id\n    resultsTeam {\n      ...Team\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CollagesSetup on CollagesSetupNode {\n    id\n    createAlbumsTeam {\n      ...Team\n    }\n    addPhotosTeam {\n      ...Team\n    }\n    viewPhotosTeam {\n      ...Team\n    }\n    photosCount\n  }\n',
): (typeof documents)['\n  fragment CollagesSetup on CollagesSetupNode {\n    id\n    createAlbumsTeam {\n      ...Team\n    }\n    addPhotosTeam {\n      ...Team\n    }\n    viewPhotosTeam {\n      ...Team\n    }\n    photosCount\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ReportsSetup on ReportsSetupNode {\n    id\n    allowImages\n  }\n',
): (typeof documents)['\n  fragment ReportsSetup on ReportsSetupNode {\n    id\n    allowImages\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment QuotesSetup on QuotesSetupNode {\n    id\n    anonymous\n    public\n    maxChars\n  }\n',
): (typeof documents)['\n  fragment QuotesSetup on QuotesSetupNode {\n    id\n    anonymous\n    public\n    maxChars\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CustomPageSetup on CustomPagesSetupNode {\n    id\n    createPagesTeam {\n      ...Team\n    }\n    viewPagesTeam {\n      ...Team\n    }\n    guide\n  }\n',
): (typeof documents)['\n  fragment CustomPageSetup on CustomPagesSetupNode {\n    id\n    createPagesTeam {\n      ...Team\n    }\n    viewPagesTeam {\n      ...Team\n    }\n    guide\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ModuleInstanceDetail on ModuleInstanceNode {\n    ...ModuleInstance\n    profilePageSetup {\n      ...ProfilePageSetup\n    }\n    rankingsSetup {\n      ...RankingsSetup\n    }\n    collagesSetup {\n      ...CollagesSetup\n    }\n    reportsSetup {\n      ...ReportsSetup\n    }\n    quotesSetup {\n      ...QuotesSetup\n    }\n    customPagesSetup {\n      ...CustomPageSetup\n    }\n  }\n',
): (typeof documents)['\n  fragment ModuleInstanceDetail on ModuleInstanceNode {\n    ...ModuleInstance\n    profilePageSetup {\n      ...ProfilePageSetup\n    }\n    rankingsSetup {\n      ...RankingsSetup\n    }\n    collagesSetup {\n      ...CollagesSetup\n    }\n    reportsSetup {\n      ...ReportsSetup\n    }\n    quotesSetup {\n      ...QuotesSetup\n    }\n    customPagesSetup {\n      ...CustomPageSetup\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CustomPage on CustomPagesInstanceNode {\n    id\n    title\n    created\n    modified\n    page\n    userGroup {\n      ...UserGroup\n    }\n    moduleInstance {\n      ...ModuleInstanceDetail\n    }\n    pageFormat\n    pageType\n    pageQuality\n  }\n',
): (typeof documents)['\n  fragment CustomPage on CustomPagesInstanceNode {\n    id\n    title\n    created\n    modified\n    page\n    userGroup {\n      ...UserGroup\n    }\n    moduleInstance {\n      ...ModuleInstanceDetail\n    }\n    pageFormat\n    pageType\n    pageQuality\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PrewordResult on PrewordInstanceNode {\n    wordCount\n  }\n',
): (typeof documents)['\n  fragment PrewordResult on PrewordInstanceNode {\n    wordCount\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ProfilePageUserSummary on ProfilePageUserSummaryNode {\n    userGroup {\n      ...UserGroup\n    }\n    questionsAnswered\n    imagesUploaded\n  }\n',
): (typeof documents)['\n  fragment ProfilePageUserSummary on ProfilePageUserSummaryNode {\n    userGroup {\n      ...UserGroup\n    }\n    questionsAnswered\n    imagesUploaded\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ProfilePageResult on ProfilePageSetupNode {\n    id\n    usersSummary {\n      ...ProfilePageUserSummary\n    }\n    usersDelivered\n  }\n',
): (typeof documents)['\n  fragment ProfilePageResult on ProfilePageSetupNode {\n    id\n    usersSummary {\n      ...ProfilePageUserSummary\n    }\n    usersDelivered\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment RankingsResult on RankingsSetupNode {\n    rankingsCount\n  }\n',
): (typeof documents)['\n  fragment RankingsResult on RankingsSetupNode {\n    rankingsCount\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CollagesResult on CollagesSetupNode {\n    photosCount\n  }\n',
): (typeof documents)['\n  fragment CollagesResult on CollagesSetupNode {\n    photosCount\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ReportsResult on ReportsSetupNode {\n    reportsCount\n  }\n',
): (typeof documents)['\n  fragment ReportsResult on ReportsSetupNode {\n    reportsCount\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment QuotesResult on QuotesSetupNode {\n    quotesCount\n  }\n',
): (typeof documents)['\n  fragment QuotesResult on QuotesSetupNode {\n    quotesCount\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ModuleInstanceResult on ModuleInstanceNode {\n    ...ModuleInstance\n    prewordInstance {\n      ...PrewordResult\n    }\n    profilePageSetup {\n      ...ProfilePageResult\n    }\n    rankingsSetup {\n      ...RankingsResult\n    }\n    collagesSetup {\n      ...CollagesResult\n    }\n    reportsSetup {\n      ...ReportsResult\n    }\n    quotesSetup {\n      ...QuotesResult\n    }\n    customPagesInstances {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CustomPage\n        }\n        cursor\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment ModuleInstanceResult on ModuleInstanceNode {\n    ...ModuleInstance\n    prewordInstance {\n      ...PrewordResult\n    }\n    profilePageSetup {\n      ...ProfilePageResult\n    }\n    rankingsSetup {\n      ...RankingsResult\n    }\n    collagesSetup {\n      ...CollagesResult\n    }\n    reportsSetup {\n      ...ReportsResult\n    }\n    quotesSetup {\n      ...QuotesResult\n    }\n    customPagesInstances {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CustomPage\n        }\n        cursor\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ProfilePagePhoto on ProfilePagePhotoNode {\n    id\n    image\n  }\n',
): (typeof documents)['\n  fragment ProfilePagePhoto on ProfilePagePhotoNode {\n    id\n    image\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BaseProfilePagePhotoCategory on ProfilePagePhotoCategoryNode {\n    id\n    name\n  }\n',
): (typeof documents)['\n  fragment BaseProfilePagePhotoCategory on ProfilePagePhotoCategoryNode {\n    id\n    name\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ProfilePagePhotoCategory on ProfilePagePhotoCategoryNode {\n    ...BaseProfilePagePhotoCategory\n    profilePagePhotos(userGroup: $ppqUserGroup) {\n      edges {\n        node {\n          ...ProfilePagePhoto\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment ProfilePagePhotoCategory on ProfilePagePhotoCategoryNode {\n    ...BaseProfilePagePhotoCategory\n    profilePagePhotos(userGroup: $ppqUserGroup) {\n      edges {\n        node {\n          ...ProfilePagePhoto\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ProfilePageQuestionOption on ProfilePageQuestionOptionNode {\n    id\n    text\n  }\n',
): (typeof documents)['\n  fragment ProfilePageQuestionOption on ProfilePageQuestionOptionNode {\n    id\n    text\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ProfilePageAnswer on ProfilePageAnswerNode {\n    id\n    text\n    profilePageQuestionOption {\n      ...ProfilePageQuestionOption\n    }\n    userGroup {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment ProfilePageAnswer on ProfilePageAnswerNode {\n    id\n    text\n    profilePageQuestionOption {\n      ...ProfilePageQuestionOption\n    }\n    userGroup {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BaseProfilePageQuestion on ProfilePageQuestionNode {\n    id\n    text\n    order\n    profilePageQuestionOptions {\n      ...ProfilePageQuestionOption\n    }\n  }\n',
): (typeof documents)['\n  fragment BaseProfilePageQuestion on ProfilePageQuestionNode {\n    id\n    text\n    order\n    profilePageQuestionOptions {\n      ...ProfilePageQuestionOption\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ProfilePageQuestion on ProfilePageQuestionNode {\n    ...BaseProfilePageQuestion\n    profilePageAnswers(userGroup: $ppqUserGroup) {\n      edges {\n        node {\n          ...ProfilePageAnswer\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment ProfilePageQuestion on ProfilePageQuestionNode {\n    ...BaseProfilePageQuestion\n    profilePageAnswers(userGroup: $ppqUserGroup) {\n      edges {\n        node {\n          ...ProfilePageAnswer\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ProfilePageSuggestedQuestionOption on ProfilePageSuggestedQuestionOptionNode {\n    id\n    text\n  }\n',
): (typeof documents)['\n  fragment ProfilePageSuggestedQuestionOption on ProfilePageSuggestedQuestionOptionNode {\n    id\n    text\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ProfilePageSuggestedQuestion on ProfilePageSuggestedQuestionNode {\n    id\n    text\n    options {\n      ...ProfilePageSuggestedQuestionOption\n    }\n  }\n',
): (typeof documents)['\n  fragment ProfilePageSuggestedQuestion on ProfilePageSuggestedQuestionNode {\n    id\n    text\n    options {\n      ...ProfilePageSuggestedQuestionOption\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ProfilePageComment on ProfilePageCommentNode {\n    id\n    text\n    comentatorUserGroup {\n      ...UserGroup\n    }\n    profileUserGroup {\n      ...UserGroup\n    }\n    created\n  }\n',
): (typeof documents)['\n  fragment ProfilePageComment on ProfilePageCommentNode {\n    id\n    text\n    comentatorUserGroup {\n      ...UserGroup\n    }\n    profileUserGroup {\n      ...UserGroup\n    }\n    created\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CollagesPhoto on CollagesPhotoNode {\n    id\n    photo\n    collagesPhotoLikesCount\n    created\n    userGroup {\n      id\n    }\n    collagesPhotoLikes(userGroup: $authUserGroupId) {\n      edges {\n        node {\n          userGroup {\n            id\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment CollagesPhoto on CollagesPhotoNode {\n    id\n    photo\n    collagesPhotoLikesCount\n    created\n    userGroup {\n      id\n    }\n    collagesPhotoLikes(userGroup: $authUserGroupId) {\n      edges {\n        node {\n          userGroup {\n            id\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CollagesBasePhoto on CollagesPhotoNode {\n    id\n    photo\n    collagesPhotoLikesCount\n    created\n    userGroup {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment CollagesBasePhoto on CollagesPhotoNode {\n    id\n    photo\n    collagesPhotoLikesCount\n    created\n    userGroup {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BaseCollagesAlbum on CollagesAlbumNode {\n    id\n    name\n  }\n',
): (typeof documents)['\n  fragment BaseCollagesAlbum on CollagesAlbumNode {\n    id\n    name\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CollagesAlbum on CollagesAlbumNode {\n    ...BaseCollagesAlbum\n    photosCount\n    collagesPhotos(after: $cpAfter, first: $cpFirst) {\n      edges {\n        node {\n          ...CollagesBasePhoto\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment CollagesAlbum on CollagesAlbumNode {\n    ...BaseCollagesAlbum\n    photosCount\n    collagesPhotos(after: $cpAfter, first: $cpFirst) {\n      edges {\n        node {\n          ...CollagesBasePhoto\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CollagesBaseAlbum on CollagesAlbumNode {\n    ...BaseCollagesAlbum\n    photosCount\n  }\n',
): (typeof documents)['\n  fragment CollagesBaseAlbum on CollagesAlbumNode {\n    ...BaseCollagesAlbum\n    photosCount\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ReportsInstance on ReportsInstanceNode {\n    id\n    title\n    text\n    editTeam {\n      ...Team\n    }\n    viewTeam {\n      ...Team\n    }\n    maxChars\n  }\n',
): (typeof documents)['\n  fragment ReportsInstance on ReportsInstanceNode {\n    id\n    title\n    text\n    editTeam {\n      ...Team\n    }\n    viewTeam {\n      ...Team\n    }\n    maxChars\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BaseQuote on QuotesInstanceNode {\n    id\n    quote\n    userGroup {\n      ...UserGroup\n    }\n    quotesInstanceLikesCount\n    created\n  }\n',
): (typeof documents)['\n  fragment BaseQuote on QuotesInstanceNode {\n    id\n    quote\n    userGroup {\n      ...UserGroup\n    }\n    quotesInstanceLikesCount\n    created\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Quote on QuotesInstanceNode {\n    ...BaseQuote\n    quotesInstanceLikes(userGroup: $authUserGroupId) {\n      edges {\n        node {\n          id\n          userGroup {\n            id\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment Quote on QuotesInstanceNode {\n    ...BaseQuote\n    quotesInstanceLikes(userGroup: $authUserGroupId) {\n      edges {\n        node {\n          id\n          userGroup {\n            id\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CustomList on CustomListNode {\n    id\n    title\n  }\n',
): (typeof documents)['\n  fragment CustomList on CustomListNode {\n    id\n    title\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CustomListOption on CustomListOptionNode {\n    id\n    text\n  }\n',
): (typeof documents)['\n  fragment CustomListOption on CustomListOptionNode {\n    id\n    text\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment RankingQuestionResult on RankingsQuestionResultNode {\n    votee1UserGroup {\n      ...UserGroup\n    }\n    votee2UserGroup {\n      ...UserGroup\n    }\n    voteeListOption {\n      ...CustomListOption\n    }\n    percentage\n  }\n',
): (typeof documents)['\n  fragment RankingQuestionResult on RankingsQuestionResultNode {\n    votee1UserGroup {\n      ...UserGroup\n    }\n    votee2UserGroup {\n      ...UserGroup\n    }\n    voteeListOption {\n      ...CustomListOption\n    }\n    percentage\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment RankingQuestionResults on RankingsQuestionResultsNode {\n    alreadyVoted\n    possibleVoters\n    options {\n      ...RankingQuestionResult\n    }\n  }\n',
): (typeof documents)['\n  fragment RankingQuestionResults on RankingsQuestionResultsNode {\n    alreadyVoted\n    possibleVoters\n    options {\n      ...RankingQuestionResult\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment RankingsAnswer on RankingsAnswerNode {\n    id\n    votee1UserGroup {\n      ...UserGroup\n    }\n    votee2UserGroup {\n      ...UserGroup\n    }\n    voteeListOption {\n      ...CustomListOption\n    }\n  }\n',
): (typeof documents)['\n  fragment RankingsAnswer on RankingsAnswerNode {\n    id\n    votee1UserGroup {\n      ...UserGroup\n    }\n    votee2UserGroup {\n      ...UserGroup\n    }\n    voteeListOption {\n      ...CustomListOption\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment RankingQuestion on RankingsQuestionNode {\n    id\n    question\n    answerType\n    allowedVotes\n    onlyOppositeGender\n    customList {\n      id\n    }\n    results {\n      ...RankingQuestionResults\n    }\n    rankingsAnswers(voterUserGroup: $authUserGroupId) {\n      edges {\n        node {\n          ...RankingsAnswer\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment RankingQuestion on RankingsQuestionNode {\n    id\n    question\n    answerType\n    allowedVotes\n    onlyOppositeGender\n    customList {\n      id\n    }\n    results {\n      ...RankingQuestionResults\n    }\n    rankingsAnswers(voterUserGroup: $authUserGroupId) {\n      edges {\n        node {\n          ...RankingsAnswer\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment RankingBaseQuestion on RankingsQuestionNode {\n    id\n    question\n    answerType\n    allowedVotes\n    onlyOppositeGender\n    customList {\n      id\n    }\n    rankingsAnswers(voterUserGroup: $authUserGroupId) {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment RankingBaseQuestion on RankingsQuestionNode {\n    id\n    question\n    answerType\n    allowedVotes\n    onlyOppositeGender\n    customList {\n      id\n    }\n    rankingsAnswers(voterUserGroup: $authUserGroupId) {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DataExport on DataExportNode {\n    id\n    isodatetime\n    userGroup {\n      ...UserGroup\n    }\n    exportFile\n    exportCollages\n    exportRankings\n    exportProfilePages\n    exportQuotes\n  }\n',
): (typeof documents)['\n  fragment DataExport on DataExportNode {\n    id\n    isodatetime\n    userGroup {\n      ...UserGroup\n    }\n    exportFile\n    exportCollages\n    exportRankings\n    exportProfilePages\n    exportQuotes\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Modules($after: String, $first: Int!) {\n    modules(after: $after, first: $first) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Module\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query Modules($after: String, $first: Int!) {\n    modules(after: $after, first: $first) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Module\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Module($id: ID!) {\n    module(id: $id) {\n      ...Module\n    }\n  }\n',
): (typeof documents)['\n  query Module($id: ID!) {\n    module(id: $id) {\n      ...Module\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ModuleInstances($after: String, $first: Int!, $group: ID) {\n    moduleInstances(after: $after, first: $first, group: $group) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ModuleInstance\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ModuleInstances($after: String, $first: Int!, $group: ID) {\n    moduleInstances(after: $after, first: $first, group: $group) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ModuleInstance\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ModuleInstance($id: ID!) {\n    moduleInstance(id: $id) {\n      ...ModuleInstanceDetail\n    }\n  }\n',
): (typeof documents)['\n  query ModuleInstance($id: ID!) {\n    moduleInstance(id: $id) {\n      ...ModuleInstanceDetail\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ModuleInstanceResults($after: String, $first: Int!, $group: ID) {\n    moduleInstances(after: $after, first: $first, group: $group) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ModuleInstanceResult\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ModuleInstanceResults($after: String, $first: Int!, $group: ID) {\n    moduleInstances(after: $after, first: $first, group: $group) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ModuleInstanceResult\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ModuleInstanceResult($id: ID!) {\n    moduleInstance(id: $id) {\n      ...ModuleInstanceResult\n    }\n  }\n',
): (typeof documents)['\n  query ModuleInstanceResult($id: ID!) {\n    moduleInstance(id: $id) {\n      ...ModuleInstanceResult\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ProfilePagePhotoCategories(\n    $after: String\n    $first: Int!\n    $moduleInstance: ID\n    $ppqUserGroup: ID\n  ) {\n    profilePagePhotoCategories(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ProfilePagePhotoCategory\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProfilePagePhotoCategories(\n    $after: String\n    $first: Int!\n    $moduleInstance: ID\n    $ppqUserGroup: ID\n  ) {\n    profilePagePhotoCategories(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ProfilePagePhotoCategory\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ProfilePageQuestions(\n    $after: String\n    $first: Int!\n    $moduleInstance: ID\n    $ppqUserGroup: ID\n  ) {\n    profilePageQuestions(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ProfilePageQuestion\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProfilePageQuestions(\n    $after: String\n    $first: Int!\n    $moduleInstance: ID\n    $ppqUserGroup: ID\n  ) {\n    profilePageQuestions(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ProfilePageQuestion\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ProfilePageSuggestedQuestions($after: String, $first: Int!) {\n    profilePageSuggestedQuestions(after: $after, first: $first) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ProfilePageSuggestedQuestion\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProfilePageSuggestedQuestions($after: String, $first: Int!) {\n    profilePageSuggestedQuestions(after: $after, first: $first) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ProfilePageSuggestedQuestion\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ProfilePageComments(\n    $after: String\n    $first: Int!\n    $profileUserGroup: ID\n    $comentatorUserGroup: ID\n    $moduleInstance: ID\n  ) {\n    profilePageComments(\n      after: $after\n      first: $first\n      profileUserGroup: $profileUserGroup\n      comentatorUserGroup: $comentatorUserGroup\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ProfilePageComment\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProfilePageComments(\n    $after: String\n    $first: Int!\n    $profileUserGroup: ID\n    $comentatorUserGroup: ID\n    $moduleInstance: ID\n  ) {\n    profilePageComments(\n      after: $after\n      first: $first\n      profileUserGroup: $profileUserGroup\n      comentatorUserGroup: $comentatorUserGroup\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ProfilePageComment\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query CollagesAlbums(\n    $after: String\n    $first: Int!\n    $moduleInstance: ID\n    $cpAfter: String\n    $cpFirst: Int\n  ) {\n    collagesAlbums(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CollagesAlbum\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query CollagesAlbums(\n    $after: String\n    $first: Int!\n    $moduleInstance: ID\n    $cpAfter: String\n    $cpFirst: Int\n  ) {\n    collagesAlbums(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CollagesAlbum\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query CollagesAlbum($id: ID!) {\n    collagesAlbum(id: $id) {\n      ...CollagesBaseAlbum\n    }\n  }\n',
): (typeof documents)['\n  query CollagesAlbum($id: ID!) {\n    collagesAlbum(id: $id) {\n      ...CollagesBaseAlbum\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query CollagesPhotos(\n    $after: String\n    $first: Int\n    $collagesAlbum: ID\n    $authUserGroupId: ID\n  ) {\n    collagesPhotos(\n      after: $after\n      first: $first\n      collagesAlbum: $collagesAlbum\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CollagesPhoto\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query CollagesPhotos(\n    $after: String\n    $first: Int\n    $collagesAlbum: ID\n    $authUserGroupId: ID\n  ) {\n    collagesPhotos(\n      after: $after\n      first: $first\n      collagesAlbum: $collagesAlbum\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CollagesPhoto\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ReportsInstances($after: String, $first: Int!, $moduleInstance: ID) {\n    reportsInstances(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ReportsInstance\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ReportsInstances($after: String, $first: Int!, $moduleInstance: ID) {\n    reportsInstances(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ReportsInstance\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ReportsInstance($id: ID!) {\n    reportsInstance(id: $id) {\n      ...ReportsInstance\n    }\n  }\n',
): (typeof documents)['\n  query ReportsInstance($id: ID!) {\n    reportsInstance(id: $id) {\n      ...ReportsInstance\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query QuotesInstances(\n    $after: String\n    $first: Int!\n    $moduleInstance: ID\n    $userGroup: ID\n    $authUserGroupId: ID\n    $orderBy: String\n  ) {\n    quotesInstances(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n      userGroup: $userGroup\n      orderBy: $orderBy\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Quote\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query QuotesInstances(\n    $after: String\n    $first: Int!\n    $moduleInstance: ID\n    $userGroup: ID\n    $authUserGroupId: ID\n    $orderBy: String\n  ) {\n    quotesInstances(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n      userGroup: $userGroup\n      orderBy: $orderBy\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Quote\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query CustomPagesInstances(\n    $after: String\n    $first: Int!\n    $moduleInstance: ID\n    $userGroup: String!\n  ) {\n    customPagesInstances(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n      userGroup: $userGroup\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CustomPage\n        }\n        cursor\n      }\n    }\n  }\n',
): (typeof documents)['\n  query CustomPagesInstances(\n    $after: String\n    $first: Int!\n    $moduleInstance: ID\n    $userGroup: String!\n  ) {\n    customPagesInstances(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n      userGroup: $userGroup\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CustomPage\n        }\n        cursor\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query CustomPagesInstancesResult(\n    $after: String\n    $first: Int!\n    $moduleInstance: String!\n  ) {\n    customPagesInstancesResult(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CustomPage\n        }\n        cursor\n      }\n    }\n  }\n',
): (typeof documents)['\n  query CustomPagesInstancesResult(\n    $after: String\n    $first: Int!\n    $moduleInstance: String!\n  ) {\n    customPagesInstancesResult(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CustomPage\n        }\n        cursor\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query CustomLists($after: String, $first: Int!, $moduleInstance: ID) {\n    customLists(after: $after, first: $first, moduleInstance: $moduleInstance) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CustomList\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query CustomLists($after: String, $first: Int!, $moduleInstance: ID) {\n    customLists(after: $after, first: $first, moduleInstance: $moduleInstance) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CustomList\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query CustomListOptions($after: String, $first: Int!, $customList: ID) {\n    customListOptions(after: $after, first: $first, customList: $customList) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CustomListOption\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query CustomListOptions($after: String, $first: Int!, $customList: ID) {\n    customListOptions(after: $after, first: $first, customList: $customList) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CustomListOption\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query RankingsQuestions(\n    $after: String\n    $first: Int!\n    $moduleInstance: ID\n    $authUserGroupId: ID\n  ) {\n    rankingsQuestions(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...RankingQuestion\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query RankingsQuestions(\n    $after: String\n    $first: Int!\n    $moduleInstance: ID\n    $authUserGroupId: ID\n  ) {\n    rankingsQuestions(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...RankingQuestion\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query RankingsQuestion($id: ID!, $authUserGroupId: ID) {\n    rankingsQuestion(id: $id) {\n      ...RankingBaseQuestion\n    }\n  }\n',
): (typeof documents)['\n  query RankingsQuestion($id: ID!, $authUserGroupId: ID) {\n    rankingsQuestion(id: $id) {\n      ...RankingBaseQuestion\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query DataExports($after: String, $first: Int!, $group: ID!) {\n    dataExports(after: $after, first: $first, group: $group) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...DataExport\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query DataExports($after: String, $first: Int!, $group: ID!) {\n    dataExports(after: $after, first: $first, group: $group) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...DataExport\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query CustomPagesInstance($id: ID!) {\n    customPagesInstance(id: $id) {\n      ...CustomPage\n    }\n  }\n',
): (typeof documents)['\n  query CustomPagesInstance($id: ID!) {\n    customPagesInstance(id: $id) {\n      ...CustomPage\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateModuleInstance($input: CreateModuleInstanceInput!) {\n    createModuleInstance(input: $input) {\n      errors {\n        field\n        messages\n      }\n      moduleInstance {\n        ...ModuleInstance\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateModuleInstance($input: CreateModuleInstanceInput!) {\n    createModuleInstance(input: $input) {\n      errors {\n        field\n        messages\n      }\n      moduleInstance {\n        ...ModuleInstance\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DestroyModuleInstance($input: DestroyModuleInstanceInput!) {\n    destroyModuleInstance(input: $input) {\n      moduleInstance {\n        ...ModuleInstance\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation DestroyModuleInstance($input: DestroyModuleInstanceInput!) {\n    destroyModuleInstance(input: $input) {\n      moduleInstance {\n        ...ModuleInstance\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdatePrewordInstance($input: UpdatePrewordInstanceInput!) {\n    updatePrewordInstance(input: $input) {\n      errors {\n        field\n        messages\n      }\n      prewordInstance {\n        ...PrewordInstance\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdatePrewordInstance($input: UpdatePrewordInstanceInput!) {\n    updatePrewordInstance(input: $input) {\n      errors {\n        field\n        messages\n      }\n      prewordInstance {\n        ...PrewordInstance\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateProfilePagePhotoCategory(\n    $input: CreateProfilePagePhotoCategoryInput!\n  ) {\n    createProfilePagePhotoCategory(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePagePhotoCategory {\n        ...BaseProfilePagePhotoCategory\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateProfilePagePhotoCategory(\n    $input: CreateProfilePagePhotoCategoryInput!\n  ) {\n    createProfilePagePhotoCategory(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePagePhotoCategory {\n        ...BaseProfilePagePhotoCategory\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateProfilePagePhotoCategory(\n    $input: UpdateProfilePagePhotoCategoryInput!\n  ) {\n    updateProfilePagePhotoCategory(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePagePhotoCategory {\n        ...BaseProfilePagePhotoCategory\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateProfilePagePhotoCategory(\n    $input: UpdateProfilePagePhotoCategoryInput!\n  ) {\n    updateProfilePagePhotoCategory(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePagePhotoCategory {\n        ...BaseProfilePagePhotoCategory\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DestroyProfilePagePhotoCategory(\n    $input: DestroyProfilePagePhotoCategoryInput!\n  ) {\n    destroyProfilePagePhotoCategory(input: $input) {\n      profilePagePhotoCategory {\n        ...BaseProfilePagePhotoCategory\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation DestroyProfilePagePhotoCategory(\n    $input: DestroyProfilePagePhotoCategoryInput!\n  ) {\n    destroyProfilePagePhotoCategory(input: $input) {\n      profilePagePhotoCategory {\n        ...BaseProfilePagePhotoCategory\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateProfilePagePhoto($input: CreateProfilePagePhotoInput!) {\n    createProfilePagePhoto(input: $input) {\n      profilePagePhoto {\n        ...ProfilePagePhoto\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateProfilePagePhoto($input: CreateProfilePagePhotoInput!) {\n    createProfilePagePhoto(input: $input) {\n      profilePagePhoto {\n        ...ProfilePagePhoto\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateProfilePagePhoto($input: UpdateProfilePagePhotoInput!) {\n    updateProfilePagePhoto(input: $input) {\n      profilePagePhoto {\n        ...ProfilePagePhoto\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateProfilePagePhoto($input: UpdateProfilePagePhotoInput!) {\n    updateProfilePagePhoto(input: $input) {\n      profilePagePhoto {\n        ...ProfilePagePhoto\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DestroyProfilePagePhoto($input: DestroyProfilePagePhotoInput!) {\n    destroyProfilePagePhoto(input: $input) {\n      profilePagePhoto {\n        ...ProfilePagePhoto\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation DestroyProfilePagePhoto($input: DestroyProfilePagePhotoInput!) {\n    destroyProfilePagePhoto(input: $input) {\n      profilePagePhoto {\n        ...ProfilePagePhoto\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateProfilePageQuestion($input: CreateProfilePageQuestionInput!) {\n    createProfilePageQuestion(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePageQuestion {\n        ...BaseProfilePageQuestion\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateProfilePageQuestion($input: CreateProfilePageQuestionInput!) {\n    createProfilePageQuestion(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePageQuestion {\n        ...BaseProfilePageQuestion\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateProfilePageQuestion($input: UpdateProfilePageQuestionInput!) {\n    updateProfilePageQuestion(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePageQuestion {\n        ...BaseProfilePageQuestion\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateProfilePageQuestion($input: UpdateProfilePageQuestionInput!) {\n    updateProfilePageQuestion(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePageQuestion {\n        ...BaseProfilePageQuestion\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DestroyProfilePageQuestion(\n    $input: DestroyProfilePageQuestionInput!\n  ) {\n    destroyProfilePageQuestion(input: $input) {\n      profilePageQuestion {\n        ...BaseProfilePageQuestion\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation DestroyProfilePageQuestion(\n    $input: DestroyProfilePageQuestionInput!\n  ) {\n    destroyProfilePageQuestion(input: $input) {\n      profilePageQuestion {\n        ...BaseProfilePageQuestion\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateProfilePageAnswer($input: CreateProfilePageAnswerInput!) {\n    createProfilePageAnswer(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePageAnswer {\n        ...ProfilePageAnswer\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateProfilePageAnswer($input: CreateProfilePageAnswerInput!) {\n    createProfilePageAnswer(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePageAnswer {\n        ...ProfilePageAnswer\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateProfilePageAnswer($input: UpdateProfilePageAnswerInput!) {\n    updateProfilePageAnswer(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePageAnswer {\n        ...ProfilePageAnswer\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateProfilePageAnswer($input: UpdateProfilePageAnswerInput!) {\n    updateProfilePageAnswer(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePageAnswer {\n        ...ProfilePageAnswer\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DestroyProfilePageAnswer($input: DestroyProfilePageAnswerInput!) {\n    destroyProfilePageAnswer(input: $input) {\n      clientMutationId\n    }\n  }\n',
): (typeof documents)['\n  mutation DestroyProfilePageAnswer($input: DestroyProfilePageAnswerInput!) {\n    destroyProfilePageAnswer(input: $input) {\n      clientMutationId\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateProfilePageSetup($input: UpdateProfilePageSetupInput!) {\n    updateProfilePageSetup(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePageSetup {\n        ...ProfilePageSetup\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateProfilePageSetup($input: UpdateProfilePageSetupInput!) {\n    updateProfilePageSetup(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePageSetup {\n        ...ProfilePageSetup\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateModuleInstance($input: UpdateModuleInstanceInput!) {\n    updateModuleInstance(input: $input) {\n      errors {\n        field\n        messages\n      }\n      moduleInstance {\n        ...ModuleInstanceDetail\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateModuleInstance($input: UpdateModuleInstanceInput!) {\n    updateModuleInstance(input: $input) {\n      errors {\n        field\n        messages\n      }\n      moduleInstance {\n        ...ModuleInstanceDetail\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateProfilePageComment($input: CreateProfilePageCommentInput!) {\n    createProfilePageComment(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePageComment {\n        ...ProfilePageComment\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateProfilePageComment($input: CreateProfilePageCommentInput!) {\n    createProfilePageComment(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePageComment {\n        ...ProfilePageComment\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateProfilePageComment($input: UpdateProfilePageCommentInput!) {\n    updateProfilePageComment(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePageComment {\n        ...ProfilePageComment\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateProfilePageComment($input: UpdateProfilePageCommentInput!) {\n    updateProfilePageComment(input: $input) {\n      errors {\n        field\n        messages\n      }\n      profilePageComment {\n        ...ProfilePageComment\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DestroyProfilePageComment($input: DestroyProfilePageCommentInput!) {\n    destroyProfilePageComment(input: $input) {\n      profilePageComment {\n        ...ProfilePageComment\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation DestroyProfilePageComment($input: DestroyProfilePageCommentInput!) {\n    destroyProfilePageComment(input: $input) {\n      profilePageComment {\n        ...ProfilePageComment\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation MoveProfilePageQuestionToPosition(\n    $input: MoveProfilePageQuestionToPositionInput!\n  ) {\n    moveProfilePageQuestionToPosition(input: $input) {\n      profilePageQuestion {\n        ...BaseProfilePageQuestion\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation MoveProfilePageQuestionToPosition(\n    $input: MoveProfilePageQuestionToPositionInput!\n  ) {\n    moveProfilePageQuestionToPosition(input: $input) {\n      profilePageQuestion {\n        ...BaseProfilePageQuestion\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation AddPhotoToCollagesAlbum(\n    $input: AddPhotoToCollagesAlbumInput!\n    $authUserGroupId: ID\n  ) {\n    addPhotoToCollagesAlbum(input: $input) {\n      photo {\n        ...CollagesPhoto\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AddPhotoToCollagesAlbum(\n    $input: AddPhotoToCollagesAlbumInput!\n    $authUserGroupId: ID\n  ) {\n    addPhotoToCollagesAlbum(input: $input) {\n      photo {\n        ...CollagesPhoto\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation RemovePhotoFromCollagesAlbum(\n    $input: RemovePhotoFromCollagesAlbumInput!\n  ) {\n    removePhotoFromCollagesAlbum(input: $input) {\n      success\n    }\n  }\n',
): (typeof documents)['\n  mutation RemovePhotoFromCollagesAlbum(\n    $input: RemovePhotoFromCollagesAlbumInput!\n  ) {\n    removePhotoFromCollagesAlbum(input: $input) {\n      success\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateCollagesAlbum($input: CreateCollagesAlbumInput!) {\n    createCollagesAlbum(input: $input) {\n      errors {\n        field\n        messages\n      }\n      collagesAlbum {\n        ...BaseCollagesAlbum\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateCollagesAlbum($input: CreateCollagesAlbumInput!) {\n    createCollagesAlbum(input: $input) {\n      errors {\n        field\n        messages\n      }\n      collagesAlbum {\n        ...BaseCollagesAlbum\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateCollagesAlbum($input: UpdateCollagesAlbumInput!) {\n    updateCollagesAlbum(input: $input) {\n      errors {\n        field\n        messages\n      }\n      collagesAlbum {\n        ...BaseCollagesAlbum\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateCollagesAlbum($input: UpdateCollagesAlbumInput!) {\n    updateCollagesAlbum(input: $input) {\n      errors {\n        field\n        messages\n      }\n      collagesAlbum {\n        ...BaseCollagesAlbum\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DestroyCollagesAlbum($input: DestroyCollagesAlbumInput!) {\n    destroyCollagesAlbum(input: $input) {\n      collagesAlbum {\n        ...BaseCollagesAlbum\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation DestroyCollagesAlbum($input: DestroyCollagesAlbumInput!) {\n    destroyCollagesAlbum(input: $input) {\n      collagesAlbum {\n        ...BaseCollagesAlbum\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation LikeCollagesPhoto(\n    $input: LikeCollagesPhotoInput!\n    $authUserGroupId: ID\n  ) {\n    likeCollagesPhoto(input: $input) {\n      collagesPhoto {\n        ...CollagesPhoto\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation LikeCollagesPhoto(\n    $input: LikeCollagesPhotoInput!\n    $authUserGroupId: ID\n  ) {\n    likeCollagesPhoto(input: $input) {\n      collagesPhoto {\n        ...CollagesPhoto\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation RemoveLikeFromCollagesPhoto(\n    $input: RemoveLikeFromCollagesPhotoInput!\n    $authUserGroupId: ID\n  ) {\n    removeLikeFromCollagesPhoto(input: $input) {\n      collagesPhoto {\n        ...CollagesPhoto\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation RemoveLikeFromCollagesPhoto(\n    $input: RemoveLikeFromCollagesPhotoInput!\n    $authUserGroupId: ID\n  ) {\n    removeLikeFromCollagesPhoto(input: $input) {\n      collagesPhoto {\n        ...CollagesPhoto\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateCollagesSetup($input: UpdateCollagesSetupInput!) {\n    updateCollagesSetup(input: $input) {\n      errors {\n        field\n        messages\n      }\n      collagesSetup {\n        ...CollagesSetup\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateCollagesSetup($input: UpdateCollagesSetupInput!) {\n    updateCollagesSetup(input: $input) {\n      errors {\n        field\n        messages\n      }\n      collagesSetup {\n        ...CollagesSetup\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateReportsSetup($input: UpdateReportsSetupInput!) {\n    updateReportsSetup(input: $input) {\n      errors {\n        field\n        messages\n      }\n      reportsSetup {\n        ...ReportsSetup\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateReportsSetup($input: UpdateReportsSetupInput!) {\n    updateReportsSetup(input: $input) {\n      errors {\n        field\n        messages\n      }\n      reportsSetup {\n        ...ReportsSetup\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateReportsInstance($input: CreateReportsInstanceInput!) {\n    createReportsInstance(input: $input) {\n      errors {\n        field\n        messages\n      }\n      reportsInstance {\n        ...ReportsInstance\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateReportsInstance($input: CreateReportsInstanceInput!) {\n    createReportsInstance(input: $input) {\n      errors {\n        field\n        messages\n      }\n      reportsInstance {\n        ...ReportsInstance\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateReportsInstance($input: UpdateReportsInstanceInput!) {\n    updateReportsInstance(input: $input) {\n      errors {\n        field\n        messages\n      }\n      reportsInstance {\n        ...ReportsInstance\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateReportsInstance($input: UpdateReportsInstanceInput!) {\n    updateReportsInstance(input: $input) {\n      errors {\n        field\n        messages\n      }\n      reportsInstance {\n        ...ReportsInstance\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DestroyReportsInstance($input: DestroyReportsInstanceInput!) {\n    destroyReportsInstance(input: $input) {\n      success\n      reportsInstanceId\n    }\n  }\n',
): (typeof documents)['\n  mutation DestroyReportsInstance($input: DestroyReportsInstanceInput!) {\n    destroyReportsInstance(input: $input) {\n      success\n      reportsInstanceId\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateQuotesInstance($input: CreateQuotesInstanceInput!) {\n    createQuotesInstance(input: $input) {\n      errors {\n        field\n        messages\n      }\n      quotesInstance {\n        ...BaseQuote\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateQuotesInstance($input: CreateQuotesInstanceInput!) {\n    createQuotesInstance(input: $input) {\n      errors {\n        field\n        messages\n      }\n      quotesInstance {\n        ...BaseQuote\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DestroyQuotesInstance($input: DestroyQuotesInstanceInput!) {\n    destroyQuotesInstance(input: $input) {\n      quotesInstance {\n        ...BaseQuote\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation DestroyQuotesInstance($input: DestroyQuotesInstanceInput!) {\n    destroyQuotesInstance(input: $input) {\n      quotesInstance {\n        ...BaseQuote\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateQuotesSetup($input: UpdateQuotesSetupInput!) {\n    updateQuotesSetup(input: $input) {\n      errors {\n        field\n        messages\n      }\n      quotesSetup {\n        ...QuotesSetup\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateQuotesSetup($input: UpdateQuotesSetupInput!) {\n    updateQuotesSetup(input: $input) {\n      errors {\n        field\n        messages\n      }\n      quotesSetup {\n        ...QuotesSetup\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation LikeQuotesInstance(\n    $input: LikeQuotesInstanceInput!\n    $authUserGroupId: ID\n  ) {\n    likeQuotesInstance(input: $input) {\n      quotesInstance {\n        ...Quote\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation LikeQuotesInstance(\n    $input: LikeQuotesInstanceInput!\n    $authUserGroupId: ID\n  ) {\n    likeQuotesInstance(input: $input) {\n      quotesInstance {\n        ...Quote\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation RemoveLikeFromQuotesInstance(\n    $input: RemoveLikeFromQuotesInstanceInput!\n    $authUserGroupId: ID\n  ) {\n    removeLikeFromQuotesInstance(input: $input) {\n      quotesInstance {\n        ...Quote\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation RemoveLikeFromQuotesInstance(\n    $input: RemoveLikeFromQuotesInstanceInput!\n    $authUserGroupId: ID\n  ) {\n    removeLikeFromQuotesInstance(input: $input) {\n      quotesInstance {\n        ...Quote\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateRankingsSetup($input: UpdateRankingsSetupInput!) {\n    updateRankingsSetup(input: $input) {\n      errors {\n        field\n        messages\n      }\n      rankingsSetup {\n        ...RankingsSetup\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateRankingsSetup($input: UpdateRankingsSetupInput!) {\n    updateRankingsSetup(input: $input) {\n      errors {\n        field\n        messages\n      }\n      rankingsSetup {\n        ...RankingsSetup\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateCustomPagesSetup($input: UpdateCustomPagesSetupInput!) {\n    updateCustomPagesSetup(input: $input) {\n      errors {\n        field\n        messages\n      }\n      customPagesSetup {\n        ...CustomPageSetup\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateCustomPagesSetup($input: UpdateCustomPagesSetupInput!) {\n    updateCustomPagesSetup(input: $input) {\n      errors {\n        field\n        messages\n      }\n      customPagesSetup {\n        ...CustomPageSetup\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateCustomPagesInstance($input: CreateCustomPagesInstanceInput!) {\n    createCustomPagesInstance(input: $input) {\n      customPagesInstance {\n        ...CustomPage\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateCustomPagesInstance($input: CreateCustomPagesInstanceInput!) {\n    createCustomPagesInstance(input: $input) {\n      customPagesInstance {\n        ...CustomPage\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateCustomPagesInstance($input: UpdateCustomPagesInstanceInput!) {\n    updateCustomPagesInstance(input: $input) {\n      customPagesInstance {\n        ...CustomPage\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateCustomPagesInstance($input: UpdateCustomPagesInstanceInput!) {\n    updateCustomPagesInstance(input: $input) {\n      customPagesInstance {\n        ...CustomPage\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DestroyCustomPagesInstance(\n    $input: DestroyCustomPagesInstanceInput!\n  ) {\n    destroyCustomPagesInstance(input: $input) {\n      success\n      customPagesInstanceId\n    }\n  }\n',
): (typeof documents)['\n  mutation DestroyCustomPagesInstance(\n    $input: DestroyCustomPagesInstanceInput!\n  ) {\n    destroyCustomPagesInstance(input: $input) {\n      success\n      customPagesInstanceId\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateRankingsQuestion(\n    $input: CreateRankingsQuestionInput!\n    $authUserGroupId: ID\n  ) {\n    createRankingsQuestion(input: $input) {\n      errors {\n        field\n        messages\n      }\n      rankingsQuestion {\n        ...RankingQuestion\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateRankingsQuestion(\n    $input: CreateRankingsQuestionInput!\n    $authUserGroupId: ID\n  ) {\n    createRankingsQuestion(input: $input) {\n      errors {\n        field\n        messages\n      }\n      rankingsQuestion {\n        ...RankingQuestion\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateRankingsQuestion(\n    $input: UpdateRankingsQuestionInput!\n    $authUserGroupId: ID\n  ) {\n    updateRankingsQuestion(input: $input) {\n      errors {\n        field\n        messages\n      }\n      rankingsQuestion {\n        ...RankingQuestion\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateRankingsQuestion(\n    $input: UpdateRankingsQuestionInput!\n    $authUserGroupId: ID\n  ) {\n    updateRankingsQuestion(input: $input) {\n      errors {\n        field\n        messages\n      }\n      rankingsQuestion {\n        ...RankingQuestion\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DestroyRankingsQuestion(\n    $input: DestroyRankingsQuestionInput!\n    $authUserGroupId: ID\n  ) {\n    destroyRankingsQuestion(input: $input) {\n      rankingsQuestion {\n        ...RankingQuestion\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation DestroyRankingsQuestion(\n    $input: DestroyRankingsQuestionInput!\n    $authUserGroupId: ID\n  ) {\n    destroyRankingsQuestion(input: $input) {\n      rankingsQuestion {\n        ...RankingQuestion\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateRankingsAnswer(\n    $input: CreateRankingsAnswerInput!\n    $authUserGroupId: ID\n  ) {\n    createRankingsAnswer(input: $input) {\n      errors {\n        field\n        messages\n      }\n      rankingsAnswer {\n        rankingsQuestion {\n          ...RankingQuestion\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateRankingsAnswer(\n    $input: CreateRankingsAnswerInput!\n    $authUserGroupId: ID\n  ) {\n    createRankingsAnswer(input: $input) {\n      errors {\n        field\n        messages\n      }\n      rankingsAnswer {\n        rankingsQuestion {\n          ...RankingQuestion\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateRankingsAnswer(\n    $input: UpdateRankingsAnswerInput!\n    $authUserGroupId: ID\n  ) {\n    updateRankingsAnswer(input: $input) {\n      errors {\n        field\n        messages\n      }\n      rankingsAnswer {\n        rankingsQuestion {\n          ...RankingQuestion\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateRankingsAnswer(\n    $input: UpdateRankingsAnswerInput!\n    $authUserGroupId: ID\n  ) {\n    updateRankingsAnswer(input: $input) {\n      errors {\n        field\n        messages\n      }\n      rankingsAnswer {\n        rankingsQuestion {\n          ...RankingQuestion\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateCustomList($input: CreateCustomListInput!) {\n    createCustomList(input: $input) {\n      customList {\n        ...CustomList\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateCustomList($input: CreateCustomListInput!) {\n    createCustomList(input: $input) {\n      customList {\n        ...CustomList\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateCustomList($input: UpdateCustomListInput!) {\n    updateCustomList(input: $input) {\n      customList {\n        ...CustomList\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateCustomList($input: UpdateCustomListInput!) {\n    updateCustomList(input: $input) {\n      customList {\n        ...CustomList\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteCustomList($input: DeleteCustomListInput!) {\n    deleteCustomList(input: $input) {\n      customList {\n        ...CustomList\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteCustomList($input: DeleteCustomListInput!) {\n    deleteCustomList(input: $input) {\n      customList {\n        ...CustomList\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateCustomListOption($input: CreateCustomListOptionInput!) {\n    createCustomListOption(input: $input) {\n      customListOption {\n        ...CustomListOption\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateCustomListOption($input: CreateCustomListOptionInput!) {\n    createCustomListOption(input: $input) {\n      customListOption {\n        ...CustomListOption\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateCustomListOption($input: UpdateCustomListOptionInput!) {\n    updateCustomListOption(input: $input) {\n      customListOption {\n        ...CustomListOption\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateCustomListOption($input: UpdateCustomListOptionInput!) {\n    updateCustomListOption(input: $input) {\n      customListOption {\n        ...CustomListOption\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteCustomListOption($input: DeleteCustomListOptionInput!) {\n    deleteCustomListOption(input: $input) {\n      customListOption {\n        ...CustomListOption\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteCustomListOption($input: DeleteCustomListOptionInput!) {\n    deleteCustomListOption(input: $input) {\n      customListOption {\n        ...CustomListOption\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateDataExport($input: CreateDataExportInput!) {\n    createDataExport(input: $input) {\n      success\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateDataExport($input: CreateDataExportInput!) {\n    createDataExport(input: $input) {\n      success\n    }\n  }\n'];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
