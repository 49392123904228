import React, { useEffect, useRef, useState } from 'react';
import { Dimensions, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import AdminHeader from '~/components/AdminHeader';
import Badge from '~/components/Badge';
import Button from '~/components/Button';
import ButtonIcon from '~/components/ButtonIcon';
import IconStyled from '~/components/IconStyled';
import InputField, { InputFieldHandle } from '~/components/InputField';
import { SettingLabelItem, SettingToggleItem } from '~/components/SettingPanel';
import SwitchSelector from '~/components/SwitchSelector';
import UserFilterDate from '~/components/UserFilterDate';
import { WebContainer } from '~/components/WebGrid';
import {
  Container,
  FlexRowCentered,
  FormContainer,
  Spacer,
  Name,
  FlexRow,
  GrownFlex,
  SectionLabel,
  InputFormWrapper,
  Spinner,
} from '~/components/common/style.web';
import { AdsTargeting, AdsTargetingFilter } from '~/data/models/admin';
import { CalendarDateObject } from '~/data/models/calendar';
import { Regions } from '~/data/models/campaign';
import {
  AssetQuery,
  AssetVisibilityType,
  DeleteAssetInput,
  DuplicateAssetInput,
  EditBannerAdAssetInput,
} from '~/data/types/graphql';
import {
  GraduationTypesQuery as GraduationTypes,
  InstituteTypesQuery as InstituteTypes,
} from '~/data/types/graphql';
import { calendarDateObject, formattedDate } from '~/utils/dates';
import { useMeasurableTouchableOpacity } from '~/utils/hooks/useMeasurableTouchableOpacity';
import { t } from '~/utils/i18n';
import { Statistic } from '~/utils/types/adminAds';
import DragAndDrop from '../../EditAd/CreateAssets/CreateDesign/DragAndDrop.web';
import CreateTargeting from '../../EditAd/CreateAssets/CreateTargeting';
import {
  AssetIcon,
  AssetIconContainer,
  AssetNameContainer,
  FlexRowCenteredWrap,
  StatisticsCardsContainer,
  StyledStatisticsCard,
  ButtonWrapper,
  TitleWrapper,
  DetailsWrapper,
  SwitchSelectorSize,
  SwitchSelectorText,
  ActionsContainer,
  VisabilityText,
  MapContainer,
  SectionWrapper,
  SectionsContainer,
  AssetTitleWrapper,
  BiggerImageContainer,
  SmallerImageContainer,
  DeleteIconWrapper,
  ImageContainer,
  ImageWrapper,
  TextWrapper,
  ImageSizeText,
  FormatText,
  UploadBiggerImage,
  UploadSmallerImage,
  SmallerImageWrapper,
  SmallerImageTextAlignWrapper,
  UploadImageContainer,
} from './style';

type EditAssetLayoutProps = {
  asset?: AssetQuery; //eslint-disable-line
  onBack: () => void;
  openDrawer: () => void;
  filters: AdsTargetingFilter[];
  setFilters: React.Dispatch<React.SetStateAction<AdsTargetingFilter[]>>;
  targetingData?: AdsTargeting;
  targetingLoading?: boolean;
  instituteTypes?: InstituteTypes;
  graduationTypes?: GraduationTypes;
  campaignName?: string;
  onUpdateAsset: (data: EditBannerAdAssetInput) => void;
  onDuplicateAsset: (data: DuplicateAssetInput) => void;
  onDeleteAsset: (data: DeleteAssetInput) => void;
  loading: boolean;
  regions: Regions[];
};

const ASSET_VISIBILITY_OPTIONS = Object.keys(AssetVisibilityType).map(
  (key) => ({
    key: AssetVisibilityType[key as keyof typeof AssetVisibilityType],
    title: AssetVisibilityType[key as keyof typeof AssetVisibilityType],
  }),
);

export default function EditAsset({
  asset,
  onBack,
  openDrawer,
  onUpdateAsset,
  onDuplicateAsset,
  onDeleteAsset,
  filters = [],
  setFilters,
  targetingData,
  targetingLoading,
  instituteTypes,
  graduationTypes,
  campaignName,
  loading,
  regions,
}: EditAssetLayoutProps): JSX.Element {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.desktopMin });
  const [targetUrl, setTargetUrl] = useState<string>(
    asset?.asset?.channelData?.[0]?.trackingUrl as string,
  );
  const targetUrlRef = useRef<InputFieldHandle>(null);
  const [visibilityType, setVisibilityType] = useState<AssetVisibilityType>(
    AssetVisibilityType.LIVE,
  );

  const [showStartPicker, setShowStartPicker] = useState<boolean>(false);
  const [showEndPicker, setShowEndPicker] = useState<boolean>(false);
  const startModalizeRef = useRef<View>(null);
  const endModalizeRef = useRef<View>(null);

  const getInitialDueDay = (initialDay?: string) =>
    initialDay
      ? calendarDateObject(formattedDate(initialDay, 'yyyy-MM-dd'))
      : null;

  const [startDay, setStartDay] = useState<CalendarDateObject | null>(
    getInitialDueDay(),
  );
  const [endDay, setEndDay] = useState<CalendarDateObject | null>(
    getInitialDueDay(),
  );

  const [smallerImageUri, setSmallerImageUri] = useState(
    asset?.asset?.channelData?.[0]?.imageSmall as string,
  );
  const [biggerImageUri, setBiggerImageUri] = useState(
    asset?.asset?.channelData?.[0]?.imageLarge as string,
  );
  const [visibility, setVisibility] = useState<boolean>(false);

  const startDateLabel = startDay
    ? formattedDate(startDay.dateString, 'MMM d yyyy')
    : '';

  const endDateLabel = endDay
    ? formattedDate(endDay.dateString, 'MMM d yyyy')
    : '';

  const toggleStartPicker = () => {
    if (startModalizeRef.current) {
      measureButton(startModalizeRef);
    }
    setShowStartPicker(!showStartPicker);
  };

  const toggleEndPicker = () => {
    if (endModalizeRef.current) {
      measureButton(endModalizeRef);
    }
    setShowEndPicker(!showEndPicker);
  };

  const { measureButton, buttonLayout } = useMeasurableTouchableOpacity();

  const addFilter = (filter: AdsTargetingFilter) => {
    setFilters((prevFilters) => [...prevFilters, filter]);
  };

  const updateFilter = (index: number, newFilter: AdsTargetingFilter) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter, i) => (i === index ? newFilter : filter)),
    );
  };

  const removeFilter = (index: number) => {
    setFilters((filters) =>
      filters.filter((_, filterIndex) => filterIndex !== index),
    );
  };

  const enableSubmitButton = true;

  const onSubmitChanges = () => {
    onUpdateAsset({
      id: asset?.asset?.id as string,
      visibility: visibilityType,
      startDate: startDay?.dateString,
      endDate: endDay?.dateString,
      assetLargeImage: biggerImageUri,
      assetSmallImage: smallerImageUri,
      targetingFilters: filters,
      trackingUrl: targetUrl,
    });
  };

  const statistics: Statistic[] = [
    {
      statistic: `${asset?.asset?.totalViews || '0'}`,
      description: t('editAsset.totalViews'),
    },
    {
      statistic: `${asset?.asset?.totalClicks || '0'}`,
      description: t('editAsset.totalClicks'),
    },
    {
      statistic: `${asset?.asset?.conversionRate || '0'}%`,
      description: t('editAsset.totalConversions'),
    },
  ];

  const renderStatisticsCard = ({
    item: statistic,
    index,
  }: {
    item: Statistic;
    index: number;
  }) => {
    const statisticCardWidth = isDesktop
      ? 110
      : statistics.length === index + 1
      ? (82 / 100) * Dimensions.get('window').width
      : 159;
    return (
      <StyledStatisticsCard
        key={`statisticsCard-${index}`}
        statistic={statistic.statistic}
        description={statistic.description}
        width={statisticCardWidth}
      />
    );
  };

  const handleBiggerImageDrop = (file: Blob) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const uri = e.target?.result as string;
      setBiggerImageUri(uri);
    };
    reader.readAsDataURL(file);
  };

  const handleSmallerImageDrop = (file: Blob) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const uri = e.target?.result as string;
      setSmallerImageUri(uri);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (asset) {
      setSmallerImageUri(asset.asset?.channelData?.[0]?.imageSmall as string);
      setBiggerImageUri(asset.asset?.channelData?.[0]?.imageLarge as string);
      setTargetUrl(asset.asset?.channelData?.[0]?.trackingUrl || '');
      setFilters(asset?.asset?.targetingFilters as AdsTargetingFilter[]);
      setVisibilityType(asset?.asset?.visibility ?? AssetVisibilityType.LIVE);
      setVisibility(asset?.asset?.visibility === AssetVisibilityType.SCHEDULE);
      setStartDay(getInitialDueDay(asset?.asset?.startDate));
      setEndDay(getInitialDueDay(asset?.asset?.endDate));
    }
  }, [asset]);

  if (loading) {
    return (
      <Container centerContent>
        <Spinner testID="loadingSpinner" />
      </Container>
    );
  }

  return (
    <Container>
      <AdminHeader
        title={t('screens.editAsset')}
        onBack={onBack}
        breadcrumbs={['Ads', 'EditAd', 'EditAsset']}
        enableSaveChanges={enableSubmitButton}
        onSubmitClick={onSubmitChanges}
        buttonTitle={t('adminPanelSearch.saveChanges')}
        onOpenDrawer={openDrawer}
      />
      <WebContainer isDesktop={isDesktop}>
        <FormContainer isDesktop={isDesktop}>
          <FlexRowCenteredWrap>
            <AssetTitleWrapper>
              <AssetIconContainer isDesktop={isDesktop}>
                <AssetIcon name="icn/teams/rocket" size={isDesktop ? 56 : 24} />
              </AssetIconContainer>
              <Spacer h={isDesktop ? 32 : 10} />

              <AssetNameContainer>
                <Name testID={'assetName'} isDesktop={isDesktop}>
                  {t(`adminPanelAds.assetName.${asset?.asset?.channelType}`)}{' '}
                  {t(`adminPanelAds.assetIds.${asset?.asset?.channelType}`, {
                    assetId: String(asset?.asset?.assetId).padStart(7, '0'),
                  })}
                </Name>
                <Spacer v={isDesktop ? 7 : 5} />
                <FlexRowCentered>
                  <Badge
                    text={campaignName as string}
                    colorTheme={'tertiary'}
                  />
                </FlexRowCentered>
              </AssetNameContainer>
            </AssetTitleWrapper>

            <StatisticsCardsContainer<React.ElementType>
              testID={'statisticsCards'}
              data={statistics}
              renderItem={renderStatisticsCard}
              horizontal
              showsHorizontalScrollIndicator={false}
              isDesktop={isDesktop}
            />
          </FlexRowCenteredWrap>
          <Spacer v={48} />
          <FlexRow isDesktop={isDesktop}>
            <GrownFlex>
              <InputFormWrapper>
                <UploadImageContainer isDesktop={isDesktop}>
                  <ImageWrapper isDesktop={isDesktop}>
                    {biggerImageUri ? (
                      <ImageContainer>
                        {' '}
                        <BiggerImageContainer
                          source={{ uri: biggerImageUri }}
                          isDesktop={isDesktop}
                        />{' '}
                        <DeleteIconWrapper>
                          <ButtonIcon
                            name={'x-close'}
                            size={12}
                            color={theme.color.base.c2}
                            onPress={() => setBiggerImageUri('')}
                          />
                        </DeleteIconWrapper>
                      </ImageContainer>
                    ) : (
                      <UploadBiggerImage isDesktop={isDesktop}>
                        <DragAndDrop
                          onDrop={(file) => {
                            handleBiggerImageDrop(file);
                          }}
                          height={180}
                        >
                          <IconStyled
                            name="upload_primary"
                            size={48}
                            color="none"
                          ></IconStyled>
                        </DragAndDrop>
                      </UploadBiggerImage>
                    )}

                    <TextWrapper>
                      <ImageSizeText>
                        {t('createAssets.createDesign.bigImageResolution')}
                      </ImageSizeText>
                      <FormatText>
                        {t('createAssets.createDesign.format')}
                      </FormatText>
                    </TextWrapper>
                  </ImageWrapper>
                  <Spacer v={40} />
                  <SmallerImageWrapper isDesktop={isDesktop}>
                    <SmallerImageTextAlignWrapper>
                      {smallerImageUri ? (
                        <ImageContainer>
                          <SmallerImageContainer
                            source={{
                              uri: smallerImageUri,
                            }}
                            isDesktop={isDesktop}
                          />
                          <DeleteIconWrapper>
                            <ButtonIcon
                              name={'x-close'}
                              size={12}
                              color={theme.color.base.c2}
                              onPress={() => setSmallerImageUri('')}
                            />
                          </DeleteIconWrapper>
                        </ImageContainer>
                      ) : (
                        <UploadSmallerImage isDesktop={isDesktop}>
                          <DragAndDrop onDrop={handleSmallerImageDrop}>
                            <IconStyled
                              name="upload_primary"
                              size={48}
                              color="none"
                            ></IconStyled>
                          </DragAndDrop>
                        </UploadSmallerImage>
                      )}
                      <SmallerImageTextAlignWrapper>
                        <ImageSizeText>
                          {t('createAssets.createDesign.smallImageResolution')}
                        </ImageSizeText>
                        <FormatText>
                          {t('createAssets.createDesign.format')}
                        </FormatText>
                      </SmallerImageTextAlignWrapper>
                    </SmallerImageTextAlignWrapper>
                  </SmallerImageWrapper>
                </UploadImageContainer>
                <Spacer v={40} />
                <SectionLabel>{t('adminPanelAds.targetUrl')}</SectionLabel>
                <Spacer v={32} />
                <InputField
                  ref={targetUrlRef}
                  testID={'inputTargetUrl'}
                  iconName={'flag-01'}
                  label={t('editAsset.trackingUrl')}
                  placeholder={t('editAsset.trackingUrl')}
                  value={targetUrl}
                  onChangeText={(value) => setTargetUrl(value)}
                  blurOnSubmit={false}
                  returnKeyType={'next'}
                />
                <Spacer v={25} />
                <SectionWrapper isDesktop={isDesktop}>
                  <SectionsContainer>
                    <TitleWrapper>
                      <SectionLabel>{t('editAsset.actions')}</SectionLabel>
                    </TitleWrapper>
                    <ActionsContainer isDesktop={isDesktop}>
                      <ButtonWrapper isDesktop={isDesktop}>
                        <Button
                          text={t('editAsset.duplicateAsset')}
                          onPress={() =>
                            onDuplicateAsset({
                              assetId: asset?.asset?.id,
                            } as DuplicateAssetInput)
                          }
                          size={'md'}
                          type={'secondary-base'}
                          icon={'left'}
                          testID="buttonDuplicateAsset"
                          iconName={'book-open-01'}
                        />
                        <Button
                          text={t('editAsset.deleteAsset')}
                          onPress={() =>
                            onDeleteAsset({
                              assetId: asset?.asset?.id,
                            } as DeleteAssetInput)
                          }
                          size={'md'}
                          type={'destructive'}
                          icon={'left'}
                          testID="buttonDeleteAsset"
                          iconName={'trash-01'}
                        />
                      </ButtonWrapper>
                    </ActionsContainer>
                  </SectionsContainer>
                  <Spacer v={30} />
                  <SectionsContainer>
                    <TitleWrapper>
                      <SectionLabel>{t('editAsset.details')}</SectionLabel>
                    </TitleWrapper>
                    <DetailsWrapper isDesktop={isDesktop}>
                      {isDesktop ? (
                        <SwitchSelectorText>
                          <VisabilityText>
                            {t('editAsset.visibility')}
                          </VisabilityText>
                          <SwitchSelectorSize>
                            <SwitchSelector
                              items={ASSET_VISIBILITY_OPTIONS}
                              selectedItemKey={visibilityType}
                              onSelectedItem={(itemKey) => {
                                setVisibilityType(
                                  itemKey as AssetVisibilityType,
                                );
                                setVisibility(
                                  itemKey === AssetVisibilityType.SCHEDULE,
                                );
                              }}
                            />
                          </SwitchSelectorSize>
                        </SwitchSelectorText>
                      ) : (
                        <SettingToggleItem
                          testID={'visibility'}
                          text={t('editAsset.visibility')}
                          checked={visibility ?? false}
                          onToggleChange={setVisibility}
                        />
                      )}

                      {visibility && (
                        <>
                          <SettingLabelItem
                            buttonRef={startModalizeRef}
                            text={t('editAsset.startDate')}
                            labelText={startDateLabel}
                            onLabelPress={toggleStartPicker}
                          />
                          <SettingLabelItem
                            buttonRef={endModalizeRef}
                            text={t('editAsset.endDate')}
                            labelText={endDateLabel}
                            onLabelPress={toggleEndPicker}
                          />
                        </>
                      )}
                    </DetailsWrapper>
                  </SectionsContainer>
                </SectionWrapper>
              </InputFormWrapper>
              <Spacer v={55} />
              <MapContainer isDesktop={isDesktop}>
                <CreateTargeting
                  addFilter={addFilter}
                  updateFilter={updateFilter}
                  filters={filters}
                  removeFilter={removeFilter}
                  targetingData={targetingData}
                  instituteTypes={instituteTypes}
                  graduationTypes={graduationTypes}
                  targetingLoading={targetingLoading}
                  isDesktop={isDesktop}
                  regions={regions}
                />
              </MapContainer>
            </GrownFlex>
          </FlexRow>
          {!isDesktop && (
            <Button
              testID={'submitUpdateAsset'}
              text={t('adminPanelSearch.saveChanges')}
              onPress={onSubmitChanges}
            />
          )}
        </FormContainer>
      </WebContainer>
      <UserFilterDate
        testID={'startDatePicker'}
        buttonText={t('g.confirmDate')}
        date={startDay}
        togglePicker={toggleStartPicker}
        buttonLayout={buttonLayout}
        showPicker={showStartPicker}
        onConfirmDate={(dateObj: CalendarDateObject) => {
          setStartDay(dateObj);
          setShowStartPicker(false);
        }}
      />
      <UserFilterDate
        testID={'startDatePicker'}
        buttonText={t('g.confirmDate')}
        date={startDay}
        togglePicker={toggleEndPicker}
        buttonLayout={buttonLayout}
        showPicker={showEndPicker}
        onConfirmDate={(dateObj: CalendarDateObject) => {
          setEndDay(dateObj);
          setShowEndPicker(false);
        }}
      />
    </Container>
  );
}
