import React, { useState } from 'react';
import { FlatList } from 'react-native';
import Button from '~/components/Button';
import EventCalendar, { EventCalendarProps } from '~/components/EventCalendar';
import SwitchSelector from '~/components/SwitchSelector';
import UpcomingEventsCard from '~/components/UpcomingEventsCard';
import { Content, Loading } from '~/components/common/style';
import { mapCalendarEventsByDay } from '~/data/mappers';
import { Event } from '~/data/models/calendar';
import { TabScreenLayout } from '~/screens/style';
import { calendarDay } from '~/utils/dates';
import { t } from '~/utils/i18n';
import {
  Header,
  EventsHeaderContainer,
  EventsHeader,
  UpcomingEventsContainer,
  TabContainer,
  EventCardContainer,
} from './style';

export type CalendarLayoutProps = {
  calendarEvents: Event[];
  now?: Date;
  onBack: () => void;
  onAddEvent: EventCalendarProps['onAddEvent'];
  onEventOpen: (eventId: string) => void;
  upcomingEvents: Event[];
  loading?: boolean;
  hasNextPage?: boolean;
  onLoadMore: () => void;
};

enum CalendarViewEnum {
  Calendar = 'calendar',
  UpcomingEvents = 'upcomingEvents',
}

export default function Calendar({
  calendarEvents,
  now = new Date(),
  onBack,
  onAddEvent,
  onEventOpen,
  upcomingEvents,
  loading,
  hasNextPage,
  onLoadMore,
}: CalendarLayoutProps): JSX.Element {
  const [calendarKey, setCalendarKey] = useState<CalendarViewEnum>(
    CalendarViewEnum.Calendar,
  );

  const [selectedDay, setSelectedDay] = useState<string | undefined>();

  const filteredCalendarEvents = calendarEvents.filter(
    (event) =>
      !selectedDay ||
      (!!selectedDay &&
        calendarDay(new Date(event.startDateTime)) === selectedDay),
  );

  const calendarEventsByDay = mapCalendarEventsByDay(calendarEvents);
  const handleDateSelected = (date: string) => {
    setSelectedDay(calendarDay(new Date(date)));
  };

  const renderUpcomingEvents = (event: { item: Event }) => {
    return <UpcomingEventsCard openEvent={onEventOpen} event={event.item} />;
  };

  return (
    <TabScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.calendar')}
      RightComponent={
        <Button
          testID={'buttonSetup'}
          text={t('g.addEvent')}
          size={'sm'}
          onPress={onAddEvent}
        />
      }
      onBackPress={onBack}
    >
      <TabContainer>
        <SwitchSelector
          items={[
            {
              key: CalendarViewEnum.UpcomingEvents,
              title: t('calendar.upcomingEvents'),
            },
            { key: CalendarViewEnum.Calendar, title: t('calendar.calendar') },
          ]}
          selectedItemKey={calendarKey}
          onSelectedItem={(key) => {
            setCalendarKey(key as CalendarViewEnum);
          }}
        />
      </TabContainer>
      {calendarKey === CalendarViewEnum.Calendar ? (
        <FlatList
          testID="calendarFlatList"
          ListHeaderComponent={
            <>
              <EventCalendar
                testID={'eventCalendar'}
                calendarEventsByDay={calendarEventsByDay}
                now={now}
                onAddEvent={onAddEvent}
                onDateSelected={handleDateSelected}
              />
              <Header>
                <EventsHeaderContainer>
                  <EventsHeader title={t('calendar.eventsSection')} />
                  <Button
                    testID="addEventButton"
                    text={t('g.addEvent')}
                    icon={'left'}
                    iconName={'plus'}
                    size={'sm'}
                    onPress={() => onAddEvent()}
                  />
                </EventsHeaderContainer>
              </Header>
            </>
          }
          data={filteredCalendarEvents}
          renderItem={({ item }) => (
            <EventCardContainer key={item.id}>
              {renderUpcomingEvents({ item })}
            </EventCardContainer>
          )}
          ListFooterComponent={<Content>{loading && <Loading />}</Content>}
          onEndReachedThreshold={0.3}
          onEndReached={() => {
            !selectedDay && hasNextPage && onLoadMore();
          }}
        />
      ) : (
        <UpcomingEventsContainer>
          <FlatList
            data={upcomingEvents}
            renderItem={({ item }) => (
              <EventCardContainer key={item.id}>
                {renderUpcomingEvents({ item })}
              </EventCardContainer>
            )}
          />
        </UpcomingEventsContainer>
      )}
    </TabScreenLayout>
  );
}
