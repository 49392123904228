import React from 'react';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import GroupCell from '~/components/GroupCell';
import GroupRequestCell from '~/components/GroupRequestCell';
import UnderlineButton from '~/components/UnderlineButton';
import { GroupUser } from '~/data/models/group';
import { StackScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import {
  ScrollView,
  NoGroupImage,
  NoGroupDescp,
  RequestTitle,
  SectionTitle,
  Footer,
  InfoContent,
  Loading,
  ErrorMessage,
  RefreshControl,
  ColorBox,
  NoGroupContent,
  NoGroupTitle,
} from './style';

export type LobbyLayoutProps = {
  userName: string;
  groupUsersAccepted: GroupUser[];
  groupUsersPending: GroupUser[];
  loading?: boolean;
  errorMessage?: string;
  onLogout: () => void;
  onSelectedGroup: (groupId: string, userGroupId: string) => void;
  onJoinGroup: () => void;
  onMessenger: () => void;
  onRemoveRequest: (groupId: string) => void;
  onRetry: () => void;
  onRefresh: () => void;
};

export default function Lobby({
  userName,
  groupUsersAccepted,
  groupUsersPending,
  loading = false,
  errorMessage = undefined,
  onLogout,
  onSelectedGroup,
  onJoinGroup,
  onRemoveRequest,
  onRetry,
  onRefresh,
  onMessenger,
}: LobbyLayoutProps): JSX.Element {
  const noGroup =
    groupUsersAccepted.length == 0 && groupUsersPending.length == 0;

  const isReadyToRender = !loading && !errorMessage;

  return (
    <StackScreenLayout
      title={t('gradoo')}
      type={'main'}
      RightComponent={
        <Button
          testID="buttonLogout"
          size="sm"
          text={t('g.logout')}
          type="secondary-base"
          onPress={onLogout}
        />
      }
      BgComponent={<ColorBox />}
    >
      <RequestTitle>{`${t('hey')} ${userName} ✌🏼`}</RequestTitle>
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={onRefresh} />
        }
      >
        {(() => {
          if (loading) {
            return (
              <InfoContent>
                <Loading />
              </InfoContent>
            );
          } else if (errorMessage) {
            return (
              <InfoContent>
                <ErrorMessage>{errorMessage}</ErrorMessage>
                <UnderlineButton
                  testID={'buttonRetry'}
                  type={'light'}
                  text={t('retry')}
                  onPress={onRetry}
                />
              </InfoContent>
            );
          }
          return (
            <>
              {groupUsersAccepted && groupUsersAccepted.length > 0 ? (
                <>
                  <SectionTitle>{t('lobby.sectionGroups')}</SectionTitle>
                  {groupUsersAccepted.map((groupUser) => (
                    <GroupCell
                      key={groupUser.id}
                      type={'primary'}
                      group={groupUser.group}
                      onPress={() =>
                        groupUser.group &&
                        onSelectedGroup(groupUser.group.id, groupUser.id)
                      }
                    />
                  ))}
                </>
              ) : null}
              {groupUsersPending && groupUsersPending.length > 0 ? (
                <>
                  <SectionTitle>{t('lobby.sectionRequests')}</SectionTitle>
                  {groupUsersPending.map((groupUser, i) => (
                    <GroupRequestCell
                      testID={`groupRequestCell:${i}`}
                      key={groupUser.id}
                      groupUser={groupUser}
                      onRemove={() =>
                        groupUser.group && onRemoveRequest(groupUser.group.id)
                      }
                    />
                  ))}
                </>
              ) : null}
            </>
          );
        })()}
      </ScrollView>
      {isReadyToRender && noGroup ? (
        <NoGroupContent>
          <NoGroupImage source={general.abschluss} />
          <NoGroupTitle>{t('lobby.nothingHereYet')}</NoGroupTitle>
          <NoGroupDescp>{t('lobby.noGroupDescp')}</NoGroupDescp>
        </NoGroupContent>
      ) : null}
      {isReadyToRender ? (
        <Footer>
          {noGroup && (
            <Button
              testID="buttonSupport"
              text={t('lobby.getInTouchWithSupport')}
              type="secondary-base"
              bottomMargin={16}
              flex
              flexScreenContainer="stack"
              size="2xl"
              onPress={onMessenger}
            />
          )}
          <Button
            testID={'buttonJoinGroup'}
            text={noGroup ? t('lobby.joinGroup') : t('lobby.joinAnotherGroup')}
            type="secondary-contrast"
            flex
            flexScreenContainer="stack"
            size="2xl"
            onPress={onJoinGroup}
          />
        </Footer>
      ) : null}
    </StackScreenLayout>
  );
}
