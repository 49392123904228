export const flags = {
  DE: require('./flags/DE.png'),
  AT: require('./flags/AT.png'),
  TR: require('./flags/TR.png'),
} as const;

export const general = {
  brand_logo: require('./v2/brand_logo.png'),
  stickers: require('./v2/stickers.png'),
  stickerCheersToUs: require('./v2/CheersToUs.png'),
  stickers_row: require('./v2/stickers_row.png'),
  lock: require('./v2/lock.png'),
  ranking: require('./v2/ranking.png'),
  flame: require('./v2/flame4x.png'),
  launchLogo: require('./general/launch_logo.png'),
  share: require('./general/share.png'),
  congrats: require('./general/congrats.png'),
  bg1: require('./general/bg1.png'),
  abschluss: require('./general/abschluss.png'),
  gameCards: require('./general/game_cards.png'),
  listCheck: require('./general/list_check.png'),
  empty: require('./general/empty.png'),
  gradooIcon: require('./general/gradoo-icon.png'),
  burger: require('./general/burger.png'),
  yearbook_slide_1: require('./general/yearbook_slide_1.png'),
  yearbook_slide_2: require('./general/yearbook_slide_2.png'),
  yearbook_slide_3: require('./general/yearbook_slide_3.png'),
  yearbook_slide_4: require('./general/yearbook_slide_4.png'),
  yearbook_slide_tr_1: require('./general/yearbook_slide_tr_1.png'),
  yearbook_slide_tr_2: require('./general/yearbook_slide_tr_2.png'),
  whatsapp: require('./general/whatsapp.png'),
  appstore: require('./general/appstore.png'),
  googleplay: require('./general/googleplay.png'),
  gradooWhiteLogo: require('./general/gradoo-white-logo.png'),
  ranking_fallback: require('./general/ranking_fallback.png'),
  abihomeMock: require('./general/abihome-mock.png'),
  gradooMock: require('./general/gradoo-mock.png'),
  importerLogos: require('./general/importer-logos.png'),
  importerLogosMobile: require('./general/importer-logos-mobile.png'),
  reports_fallback: require('./general/reports_fallback.png'),
  alarm: require('./general/alarm.png'),
  exportData: require('./general/export-data.png'),
  exporting: require('./general/exporting.png'),
  excel: require('./general/excel.png'),
  xml: require('./general/xml.png'),
  zip: require('./general/zip.png'),
  workInprogress: require('./general/work_in_progress.png'),
  keyOption: require('./general/keyOption.png'),
  design_data_1: require('./general/design_data_1.png'),
  community_avatars: require('./general/community-avatars.png'),
  double_hearts: require('./general/double-hearts.png'),
  megaphone_sticker: require('./v2/megaphone_sticker.png'),
  invite_success: require('./general/invite-success.png'),
  nopic: require('./general/nopic.png'),
  institute_request_sent: require('./general/institute-request-sent.png'),
  help_avatar: require('./general/help-avatar.png'),
  lobby_hearts: require('./v2/lobby_hearts.png'),
  check: require('./v2/check.png'),
} as const;

export const onboarding = {
  core1: require('./onboarding/core-1.png'),
  core1_de: require('./onboarding/core-1-de.png'),
  core2: require('./onboarding/core-2.png'),
  core2_de: require('./onboarding/core-2-de.png'),
  core3: require('./onboarding/core-3.png'),
  core3_de: require('./onboarding/core-3-de.png'),
  core5: require('./onboarding/core-5.png'),
  core6: require('./onboarding/core-6.png'),
} as const;

export const map = {
  schoolGrey: require('./map/school_grey.png'),
  schoolOrange: require('./map/school_orange.png'),
  schoolGreen: require('./map/school_green.png'),
} as const;
