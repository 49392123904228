import React, { useRef, useState } from 'react';
import { Modal, View } from 'react-native';
import { useMeasurableTouchableOpacity } from '~/utils/hooks/useMeasurableTouchableOpacity';
import {
  Container,
  ButtonContainer,
  Text,
  ArrowButtonIcon,
  PickerContainer,
  ButtonContent,
  ShowButton,
  DropdownContainer,
  DataScroll,
} from './style';

export type OptionsInfo = {
  key: string;
  name: string;
};

type LanguageDropdownProps = {
  testID?: string;
  selectedLocale: string;
  initialShowPicker?: boolean;
  onSelectedLocale: (locale: string) => void;
  selectableOptions: OptionsInfo[];
};

export default function Dropdown({
  testID,
  selectedLocale,
  initialShowPicker = false,
  onSelectedLocale,
  selectableOptions,
}: LanguageDropdownProps): JSX.Element {
  const [showPicker, setShowPicker] = useState<boolean>(initialShowPicker);
  const buttonRef = useRef<View>(null);
  const { measureButton, buttonLayout } = useMeasurableTouchableOpacity();
  const selectedLocaleInfo = selectableOptions.find(
    (selectableOptions) => selectableOptions.key === selectedLocale,
  );

  const togglePicker = () => {
    if (buttonRef.current) {
      measureButton(buttonRef);
    }
    setShowPicker(!showPicker);
  };

  return (
    <Container testID={testID}>
      <ButtonContainer
        testID={`${testID}_button`}
        ref={buttonRef}
        onPress={togglePicker}
      >
        <Text>{selectedLocaleInfo?.name}</Text>
        <ArrowButtonIcon onPress={togglePicker} />
      </ButtonContainer>
      <Modal visible={showPicker} transparent onRequestClose={togglePicker}>
        <ShowButton activeOpacity={1} onPressOut={togglePicker}>
          <DropdownContainer layout={buttonLayout}>
            <PickerContainer>
              <DataScroll>
                {selectableOptions.map((selectableOptions) => (
                  <ButtonContent
                    testID={selectableOptions.key}
                    key={selectableOptions.key}
                    onPress={() => {
                      onSelectedLocale(selectableOptions.key);
                      togglePicker();
                    }}
                  >
                    <Text>{selectableOptions.name}</Text>
                  </ButtonContent>
                ))}
              </DataScroll>
            </PickerContainer>
          </DropdownContainer>
        </ShowButton>
      </Modal>
    </Container>
  );
}
