import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-svg';
import IconStyled from '~/components/IconStyled';
import { MAX_ALLOWED_ZIPCODES } from '~/data/constants';
import { AdsTargetingFilter } from '~/data/models/admin';
import { CalendarDateObject } from '~/data/models/calendar';
import {
  TargetingFilterUserGenderOperation,
  TargetingFilterUserRegistrationOperation,
} from '~/data/types/graphql';
import { calendarDateObject, formattedDate } from '~/utils/dates';
import { useMeasurableTouchableOpacity } from '~/utils/hooks/useMeasurableTouchableOpacity';
import useUploadCSV from '~/utils/hooks/useUploadCSV';
import { t } from '~/utils/i18n';
import CsvInputField from '../CsvInputField';
import Dropdown, { OptionsInfo } from '../Dropdown';
import InputField from '../InputField';
import Snackbar from '../Snackbar';
import UserFilterDate from '../UserFilterDate';
import {
  Container,
  Title,
  TitleContainer,
  HeaderContainer,
  DeleteButton,
  LabelContainer,
  Label,
  DropdownWrapper,
  RegistrationDate,
} from './style';

type UserTargetingBlockProps = {
  filterData: AdsTargetingFilter;
  index: number;
  updateFilter: (index: number, filter: AdsTargetingFilter) => void;
  removeFilter: (index: number) => void;
};

const globalOption: OptionsInfo = {
  key: 'all',
  name: 'All',
};

const genderOptions: OptionsInfo[] = Object.values(
  TargetingFilterUserGenderOperation,
).map((gender) => ({
  key: gender,
  name: t(`createAssets.createTargeting.userTargetingBlock.${gender}`),
}));

const timingOptions: OptionsInfo[] = [
  {
    key: 'All',
    name: 'ALL',
  },
  {
    key: TargetingFilterUserRegistrationOperation.LATER_THAN,
    name: t('createAssets.createTargeting.userTargetingBlock.laterThan'),
  },
  {
    key: TargetingFilterUserRegistrationOperation.EXACTLY,
    name: t('createAssets.createTargeting.userTargetingBlock.exactly'),
  },
  {
    key: TargetingFilterUserRegistrationOperation.BEFORE,
    name: t('createAssets.createTargeting.userTargetingBlock.before'),
  },
];

export default function UserTargetingBlock({
  filterData,
  index,
  updateFilter,
  removeFilter,
}: UserTargetingBlockProps): JSX.Element {
  const [userId, setUserId] = useState<(string | null)[]>(
    filterData?.user?.userIds || [],
  );
  const [registrationTiming, setRegistrationTiming] = useState<string>();
  const [showPicker, setShowPicker] = useState<boolean>(false);

  const getInitialDueDay = (initialDay?: string) =>
    initialDay
      ? calendarDateObject(formattedDate(initialDay, 'yyyy-MM-dd'))
      : null;
  const [startDay, setStartDay] = useState<CalendarDateObject | null>(
    getInitialDueDay(),
  );

  const allOptions: OptionsInfo[] = [globalOption, ...genderOptions];

  const startModalizeRef = useRef<View>(null);

  const {
    uploadingState,
    handleUploadCSV,
    removeUploadedFile,
    uploadedCSVFileName,
    uploadedCSVFileLength,
    uploadedInput,
  } = useUploadCSV();

  useEffect(() => {
    updateUser(index, uploadedInput, userId);
  }, [uploadedInput, userId]);

  useEffect(() => {
    updateRegistrationDate(
      index,
      startDay?.dateString,
      registrationTiming as TargetingFilterUserRegistrationOperation,
    );
  }, [registrationTiming, startDay]);
  const showRegistrationDate =
    registrationTiming !== 'All' && registrationTiming;

  const updateGender = (idx: number, gender: string) => {
    if (gender === 'all') {
      const updatedFilterData: AdsTargetingFilter = {
        ...filterData,
        user: {
          ...filterData.user,
          gender: null,
        },
      };

      updateFilter(idx, updatedFilterData);
      return;
    }
    const updatedFilterData: AdsTargetingFilter = {
      ...filterData,
      user: {
        ...filterData.user,
        gender: gender as TargetingFilterUserGenderOperation,
      },
    };

    updateFilter(idx, updatedFilterData);
  };

  const updateRegistrationDate = (
    idx: number,
    registrationDate?: string,
    registrationTiming?: TargetingFilterUserRegistrationOperation,
  ) => {
    let registrationOperator:
      | TargetingFilterUserRegistrationOperation
      | undefined = registrationTiming;
    let registrationDateValue: string | undefined = registrationDate;
    if ((registrationTiming as string) == 'All') {
      registrationDateValue = undefined;
      registrationOperator = undefined;
    }

    const momentDate = registrationDateValue
      ? new Date(registrationDateValue)
      : null;
    const isoDate = momentDate ? momentDate.toISOString() : null;

    const updatedFilterData: AdsTargetingFilter = {
      ...filterData,
      user: {
        ...filterData.user,
        registration: {
          date: isoDate,
          operator: registrationOperator,
        },
      },
    };

    updateFilter(idx, updatedFilterData);
  };

  const togglePicker = () => {
    measureButton(startModalizeRef);

    setShowPicker(!showPicker);
  };

  const { measureButton, buttonLayout } = useMeasurableTouchableOpacity();

  const handleUserIdInputChange = (userId: string) => {
    const users = userId.split(',');

    if (users.length > MAX_ALLOWED_ZIPCODES) {
      Snackbar.show(
        t('createAssets.createTargeting.locationFilter.cantInputZipCode'),
      );
    } else {
      setUserId(users);
    }
  };

  const updateUser = (
    idx: number,
    userUploaded: (string | number)[],
    userInput: (string | null)[],
  ) => {
    // Convert null values in zipInput to a placeholder string
    const userInputProcessed = userInput.map((item) =>
      item === null ? 'null' : item,
    );

    // Concatenate the two arrays
    const combinedUser = userUploaded.concat(userInputProcessed);

    // Convert the array of mixed types to an array of strings
    const userAsNumberArray = combinedUser.map((item) => String(item));

    if (userInput.length === 1 && userInput[0] === '') {
      const updatedFilterData: AdsTargetingFilter = {
        ...filterData,
        user: {
          ...filterData.user,
          userIds: null,
        },
      };
      updateFilter(idx, updatedFilterData);
      return;
    }

    const updatedFilterData: AdsTargetingFilter = {
      ...filterData,
      user: {
        ...filterData.user,
        userIds: userAsNumberArray,
      },
    };
    updateFilter(idx, updatedFilterData);
  };

  const usersInputValue = userId
    .map((item) => (item === null ? '' : item))
    .join(',');

  return (
    <Container>
      <HeaderContainer>
        <TitleContainer>
          <IconStyled name="location_dark" size={14} />
          <Title>
            {t('createAssets.createTargeting.userTargetingBlock.title')}
          </Title>
        </TitleContainer>
        <DeleteButton onPress={() => removeFilter(index)}>
          {t('createAssets.createTargeting.locationFilter.deleteBtn')}
        </DeleteButton>
      </HeaderContainer>
      <LabelContainer>
        <Label>
          {t('createAssets.createTargeting.userTargetingBlock.gender')}
        </Label>
        <Dropdown
          testID={'genderDropdown'}
          selectedLocale={filterData?.user?.gender ?? 'all'}
          onSelectedLocale={(selectedGender) =>
            updateGender(index, selectedGender)
          }
          selectableOptions={allOptions}
        />
      </LabelContainer>
      <LabelContainer>
        <Label>
          {t(
            'createAssets.createTargeting.userTargetingBlock.registrationDate',
          )}
        </Label>
        <DropdownWrapper>
          <Dropdown
            testID={'registrationDateDropdown'}
            selectedLocale={registrationTiming ?? 'All'}
            onSelectedLocale={(timing) => {
              setRegistrationTiming(timing);
            }}
            selectableOptions={timingOptions}
          />
          {showRegistrationDate && (
            <RegistrationDate
              testID={'registrationDate'}
              ref={startModalizeRef}
              onPress={togglePicker}
            >
              <Text>
                {filterData.user?.registration?.date
                  ? formattedDate(
                      filterData.user?.registration?.date,
                      'dd.MM.yyyy',
                    )
                  : ''}
              </Text>
            </RegistrationDate>
          )}
        </DropdownWrapper>
      </LabelContainer>
      <LabelContainer>
        <Label>
          {t('createAssets.createTargeting.userTargetingBlock.userId')}
        </Label>
        <CsvInputField
          uploadingState={uploadingState}
          handleUploadCSV={handleUploadCSV}
          removeUploadedFile={removeUploadedFile}
          uploadedCSVFileName={uploadedCSVFileName}
          uploadedCSVFileLength={uploadedCSVFileLength}
        />
      </LabelContainer>
      <InputField
        testID={'assetsUserIdInput'}
        value={usersInputValue ?? ''}
        onChangeText={(id) => handleUserIdInputChange(id)}
        placeholder={t(
          'createAssets.createTargeting.userTargetingBlock.placeholderId',
        )}
        size={'small'}
      />

      <UserFilterDate
        testID={'startDatePicker'}
        buttonText={t('g.confirmDate')}
        date={startDay}
        mode={'single'}
        togglePicker={togglePicker}
        buttonLayout={buttonLayout}
        showPicker={showPicker}
        onConfirmDate={(dateObj: CalendarDateObject) => {
          setStartDay(dateObj);
          setShowPicker(false);
        }}
      />
    </Container>
  );
}
