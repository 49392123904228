import React, { useState } from 'react';
import { Image as ExpoImage, ImageProps, ImageLoadEventData } from 'expo-image';

interface ImageProp extends ImageProps {
  autoHeightFromWidth?: number;
  autoHeightMax?: number;
  autoWidthFromHeight?: number;
  autoWidthMax?: number;
}

export default function Image({
  autoHeightFromWidth,
  autoHeightMax,
  autoWidthFromHeight,
  autoWidthMax,
  ...props
}: ImageProp): JSX.Element {
  const [height, setHeight] = useState(
    autoHeightFromWidth ? autoHeightFromWidth * 0.75 : 0,
  );
  const [width, setWidth] = useState(
    autoWidthFromHeight ? autoWidthFromHeight * 0.75 : 0,
  );

  const onLoad = (e: ImageLoadEventData) => {
    const {
      source: { width, height },
    } = e;
    autoHeightFromWidth && setHeight((height / width) * autoHeightFromWidth);
    autoWidthFromHeight && setWidth((width / height) * autoWidthFromHeight);
  };

  return (
    <ExpoImage
      {...props}
      onLoad={autoHeightFromWidth || autoWidthFromHeight ? onLoad : undefined}
      style={[
        autoHeightFromWidth
          ? {
              width: autoHeightFromWidth,
              height:
                autoHeightMax && height > autoHeightMax
                  ? autoHeightMax
                  : height,
            }
          : autoWidthFromHeight
          ? {
              width:
                autoWidthMax && width > autoWidthMax ? autoWidthMax : width,
              height: autoWidthFromHeight,
            }
          : {},
        props.style,
      ]}
    />
  );
}
