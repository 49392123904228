import { ReactNativeFile } from 'apollo-upload-client';
import { Platform } from 'react-native';
import VersionCheck from 'react-native-version-check';
import { Event } from '~/data/models/calendar';
import { BestMatchedAsset } from '~/data/models/campaign';
import { Group } from '~/data/models/group';
import { Post } from '~/data/models/post';
import { AuthUser } from '~/data/models/user';
import { ServiceParams } from '~/navigation/types';
import packageJson from './../../../package.json';

export function getFileName(uri: string): string {
  return uri.split('/').pop() || `picture-${Date.now()}.jpg`;
}

export function getFileSize(_bytes: string | number) {
  const bytes = Number(_bytes);
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
}

export async function getImageFile(
  uri: string,
  type?: string | null,
  name?: string | null,
): Promise<ReactNativeFile | Blob> {
  if (Platform.OS === 'web') {
    //on web expo-image-picker returns base64 in the uri github.com/expo/expo/issues/9984
    const res = await fetch(uri);
    const blob = await res.blob();
    const newFile = new File([blob], getFileName(''), { type: blob.type });
    return newFile;
  } else {
    return new ReactNativeFile({
      uri,
      type: type ?? 'image/jpeg',
      name: name ?? getFileName(uri),
    });
  }
}

export function getCurrentVersion(includeBuildNumber?: boolean): string {
  if (Platform.OS === 'web') {
    return packageJson.version;
  }
  return `${VersionCheck.getCurrentVersion()}${
    includeBuildNumber ? `-${VersionCheck.getCurrentBuildNumber()}` : ''
  }`;
}

export async function versionNeedUpdated(): Promise<boolean | undefined> {
  try {
    if (Platform.OS === 'web') {
      return undefined;
    }
    const needUpdateData = await VersionCheck.needUpdate();
    return needUpdateData?.isNeeded;
  } catch (e) {
    return undefined;
  }
}

export function getInviteUrl(groupId: string, token: string): string {
  return `https://gradoo.app/invite?g=${groupId}&t=${token}`;
}

export function getReferAFriendUrl(inviteCode: string): string {
  return `https://gradoo.app/sign-up/name?r=` + inviteCode;
}

export function redirectToLayoutCreatorWithToken({
  token,
  refreshToken,
}: {
  token: string;
  refreshToken: string;
}): string {
  return (window.location.href = `${process.env.EXPO_PUBLIC_LAYOUT_CREATOR_URL}/?token=${token}&refreshToken=${refreshToken}`);
}

type GroupInfo = {
  id: string;
  year: number;
};

export function redirectToCC(authUser: AuthUser, groups: Group[]): string {
  const groupInfos: GroupInfo[] = groups.map((group) => ({
    year: group?.year,
    id: group?.institute?.internalId,
  }));
  const name = `${authUser.firstName} ${authUser.lastName}`;
  const phone = `${authUser.phoneCountryCode} ${authUser.phone}`;
  const email = authUser.email;

  return (window.location.href = `${
    process.env.EXPO_PUBLIC_CC_URL
  }/?groups=${JSON.stringify(
    groupInfos,
  )}&name=${name}&phone=${phone}&email=${email}`);
}

export function redirectToShop(redirectUrl: string): string {
  return (window.location.href = redirectUrl);
}

// Just to make sure we don't pass unnecessary params between screens
export function getServiceParamsObj(
  params: Record<string, unknown>,
): ServiceParams {
  if (typeof params === 'object') {
    const SERVICES: Array<keyof ServiceParams> = [
      'cc',
      'layoutcreator',
      'shopReturnUrl',
    ];
    let serviceParamsObj: ServiceParams = {};
    SERVICES.forEach((service) => {
      if (service in params && params[service]) {
        serviceParamsObj = {
          ...serviceParamsObj,
          [service]: params[service],
        };
      }
    });
    return serviceParamsObj;
  }
  return {};
}

export function extractParametersFromString(searchString: string): string[] {
  const separatedString = searchString.split(' ');
  const allParameters: string[] = [];

  separatedString.forEach((item) => {
    if (item.includes(':')) {
      const parameter = item.split(':', 1);
      allParameters.push(parameter[0]);
    }
  });

  return allParameters;
}

export function getDistinctValuesByProperty<T>(
  data: Array<T>,
  propertyName: keyof T,
): Array<T> {
  const distinctValues: Array<T> = [];
  const propertyValues = new Set();

  for (const item of data) {
    if (!propertyValues.has(item[propertyName])) {
      propertyValues.add(item[propertyName]);
      distinctValues.push(item);
    }
  }
  return distinctValues;
}

export function getAvatarAndName(event: Event) {
  let authorName = '';
  let avatar = '';
  if (event?.authorType === 'USER') {
    authorName =
      event?.authorUser?.firstName + ' ' + event?.authorUser?.lastName;
    avatar = event?.authorUser?.avatar as string;
  } else if (event?.authorType === 'GROUP') {
    authorName = event?.authorGroup?.name as string;
    avatar = event?.authorGroup?.avatar as string;
  } else if (event?.authorType === 'TEAM') {
    authorName = event?.authorTeam?.name as string;
    avatar = event?.authorTeam?.avatar as string;
  }

  return { authorName, avatar };
}

export const removeKeyFromObject = (obj: object, keyToRemove: string) =>
  JSON.parse(
    JSON.stringify(obj, (key, val) => (key === keyToRemove ? undefined : val)),
  );

export const waitFor = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const shuffleAdsIntoPosts = (
  array: (BestMatchedAsset | Post)[],
): (BestMatchedAsset | Post)[] => {
  const assetNodes: BestMatchedAsset[] = [];
  const postNodes: Post[] = [];

  // Separate the array into asset nodes and post nodes
  for (const item of array) {
    if (item.__typename === 'MatchedAssetNode') {
      assetNodes.push(item);
    } else if (item.__typename === 'PostNode') {
      postNodes.push(item);
    }
  }

  const shuffledArray: (BestMatchedAsset | Post)[] = [];
  let postIndex = 0;
  let assetIndex = 0;
  let postsSinceLastAd = 0;

  // Add initial advertisement node if there a post node exists
  if (assetIndex < assetNodes.length && postNodes.length > 0) {
    shuffledArray.push(assetNodes[assetIndex]);
    assetIndex++;
  }

  // for the rest add one add per 4 posts
  while (postIndex < postNodes.length) {
    for (let i = 0; i < 5 && postIndex < postNodes.length; i++) {
      shuffledArray.push(postNodes[postIndex]);
      postIndex++;
      postsSinceLastAd++;

      if (postsSinceLastAd === 4 && assetIndex < assetNodes.length) {
        shuffledArray.push(assetNodes[assetIndex]);
        assetIndex++;
        postsSinceLastAd = 0;
      }
    }
  }

  return shuffledArray;
};

export function shuffleArray<T>(array: T[]) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
