import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { NO_PAG_LIST_OFFSET } from '~/data/constants';
import { GroupIcon } from '~/data/models/custom';
import { GraduationType } from '~/data/models/group';
import { GROUP_ICONS } from '~/data/operations/custom';
import { GROUP, SET_GROUP_AVATAR, UPDATE_GROUP } from '~/data/operations/group';
import { ProfileStackParamList } from '~/navigation/types';
import { getImageFile } from '~/utils/helpers';
import { EditGroupInput } from '../GroupSetup/layout';
import GroupSettingsLayout, { GroupSettingsLoadingLayout } from './layout';

type GroupSettingsNavProp = StackNavigationProp<
  ProfileStackParamList,
  'GroupSettings'
>;

export default function GroupSettings(): JSX.Element {
  const navigation = useNavigation<GroupSettingsNavProp>();

  const [updateGroup] = useMutation(UPDATE_GROUP);
  const { authGroupId, loading: authLoading } = useAuth();

  const { data: groupData } = useQuery(GROUP, {
    skip: !authGroupId,
    variables: {
      id: authGroupId,
    },
  });

  const group = groupData?.group;

  const graduationTypes =
    (group?.institute?.type.graduationTypes as GraduationType[]) || [];

  const [setGroupAvatar, { loading: setGroupAvatarLoading }] =
    useMutation(SET_GROUP_AVATAR);
  const { data: groupIconsData } = useQuery(GROUP_ICONS, {
    variables: {
      first: NO_PAG_LIST_OFFSET,
    },
  });

  const groupIcons: GroupIcon[] =
    (groupIconsData?.groupIcons?.edges.map(
      (edge) => edge?.node,
    ) as GroupIcon[]) || [];
  const onUpdateGroup = async ({ name, typeId, year }: EditGroupInput) => {
    try {
      const { data } = await updateGroup({
        variables: {
          input: {
            id: authGroupId,
            name,
            type: typeId,
            year: parseInt(year),
          },
        },
      });
      const messages = data?.updateGroup?.errors?.map(
        (error) => error?.messages[0],
      );
      const errorMessage = messages?.[0];
      if (errorMessage) {
        Snackbar.show(errorMessage);
        return;
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onUpdateAvatar = async (uri: string | null, iconId: string | null) => {
    try {
      const imageFile = uri ? await getImageFile(uri) : null;

      await setGroupAvatar({
        variables: {
          input: {
            id: authGroupId,
            avatar: imageFile,
            iconId,
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };
  if (!group || authLoading) {
    return <GroupSettingsLoadingLayout onBack={() => navigation.goBack()} />;
  }

  return (
    <GroupSettingsLayout
      onBack={() => navigation.goBack()}
      group={group}
      graduationTypes={graduationTypes}
      setGroupAvatarLoading={setGroupAvatarLoading}
      onUpdateGroup={onUpdateGroup}
      onUpdateAvatar={onUpdateAvatar}
      groupIcons={groupIcons}
    />
  );
}
