'use dom';
import './styles.css';
import React from 'react';
import { CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListNode, ListItemNode } from '@lexical/list';
import {
  TRANSFORMERS,
  $convertFromMarkdownString,
  $convertToMarkdownString,
} from '@lexical/markdown';
import { TextMatchTransformer } from '@lexical/markdown';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableNode, TableCellNode, TableRowNode } from '@lexical/table';
import { $createImageNode, $isImageNode, ImageNode } from './ImageNode';
import ToolbarPlugin from './plugins/ToolbarPlugin';

const formatSrc = (src: string) => {
  //<img>BASE64</img>
  const base64Str = src.replace('<img>', '').replace('</img>', '');
  if (base64Str && !base64Str.includes('base64'))
    return `data:image/png;base64,${base64Str}`;
  return base64Str;
};

const IMAGE_TRANSFORMER: TextMatchTransformer = {
  dependencies: [ImageNode],
  export: (node) => {
    if (!$isImageNode(node)) return null;
    if (!(node instanceof ImageNode)) return null;
    return `<img>${node.__src}</img>`;
  },
  importRegExp: /<img>([\s\S]*?)<\/img>/g,
  regExp: /<img>([\s\S]*?)<\/img>/g,
  replace: (textNode, match) => {
    const [_src] = match;
    const src = formatSrc(_src);
    const imageNode = $createImageNode(src, 'image');
    textNode.replace(imageNode);
  },
  trigger: '<img>',
  type: 'text-match',
};

const theme = {
  code: 'editor-code',
  heading: {
    h1: 'editor-heading-h1',
    h2: 'editor-heading-h2',
    h3: 'editor-heading-h3',
    h4: 'editor-heading-h4',
    h5: 'editor-heading-h5',
  },
  image: 'editor-image',
  link: 'editor-link',
  list: {
    listitem: 'editor-listitem',
    nested: {
      listitem: 'editor-nested-listitem',
    },
    ol: 'editor-list-ol',
    ul: 'editor-list-ul',
  },
  paragraph: 'editor-paragraph',
  placeholder: 'editor-placeholder',
  quote: 'editor-quote',
  text: {
    bold: 'editor-text-bold',
    code: 'editor-text-code',
    hashtag: 'editor-text-hashtag',
    italic: 'editor-text-italic',
    overflowed: 'editor-text-overflowed',
    strikethrough: 'editor-text-strikethrough',
    underline: 'editor-text-underline',
    underlineStrikethrough: 'editor-text-underlineStrikethrough',
  },
};

const CUSTOM_TRANSFORMERS = [...TRANSFORMERS, IMAGE_TRANSFORMER];

const editorConfig = {
  namespace: 'Rich text editor in React',
  nodes: [
    LinkNode,
    AutoLinkNode,
    ListNode,
    ListItemNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    HorizontalRuleNode,
    CodeNode,
    HeadingNode,
    LinkNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    ImageNode,
  ],
  // Handling of errors during update
  onError(error: Error) {
    throw error;
  },
  // The editor theme
  theme,
};

type MarkdownEditorProps = {
  inputTestID?: string;
  initialMarkdownText?: string;
  placeholder?: string;
  saving?: boolean;
  saveEnabled?: boolean;
  disabled?: boolean;
  maxCharCount?: number;
  currentCharCount?: number;
  allowImages?: boolean;
  onEndEditing: () => void;
  onChangeMarkdown?: (text: string) => void;
  dom: import('expo/dom').DOMProps;
};

/*
we're using a different markdown format in the custom react-native-markdown-editor

custom markdown:
#Title
##Heading
<img>BASE64</img>

lexical markdown:
# Title
## Heading
<img>data:image/png;base64,BASE64</img>
*/

const convertFromCustomMarkdown = (customMarkdown: string) => {
  return customMarkdown.replace(/^(#+)([^ \n])/gm, '$1 $2');
};

const convertToCustomMarkdown = (markdown: string) => {
  return markdown.replace(/^(#+)\s+/gm, '$1');
};

export default function Editor({
  initialMarkdownText = '',
  placeholder = '',
  disabled,
  saving,
  saveEnabled,
  maxCharCount,
  currentCharCount,
  allowImages,
  onChangeMarkdown,
  onEndEditing,
}: MarkdownEditorProps) {
  return (
    <LexicalComposer
      initialConfig={{
        ...editorConfig,
        editorState: () =>
          $convertFromMarkdownString(
            convertFromCustomMarkdown(initialMarkdownText),
            CUSTOM_TRANSFORMERS,
          ),
      }}
    >
      {!disabled && (
        <ToolbarPlugin
          saving={saving}
          saveEnabled={saveEnabled}
          maxCharCount={maxCharCount}
          currentCharCount={currentCharCount}
          allowImages={allowImages}
          onSave={onEndEditing}
        />
      )}
      <div className="editor-container">
        <MarkdownShortcutPlugin transformers={CUSTOM_TRANSFORMERS} />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                className="editor-input"
                aria-placeholder={placeholder}
                placeholder={
                  <div className="editor-placeholder">{placeholder}</div>
                }
              />
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <OnChangePlugin
            onChange={(editorState) => {
              editorState.read(() => {
                const _markdown = $convertToMarkdownString(CUSTOM_TRANSFORMERS);
                const markdown = convertToCustomMarkdown(_markdown);
                onChangeMarkdown && onChangeMarkdown(markdown);
              });
            }}
            ignoreHistoryMergeTagChange
            ignoreSelectionChange
          />
          <HistoryPlugin />
          <AutoFocusPlugin />
        </div>
      </div>
    </LexicalComposer>
  );
}
