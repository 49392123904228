import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import Badge from '~/components/Badge';
import Button from '~/components/Button';
import InputField from '~/components/InputField';
import PDFViewer from '~/components/PDFViewer';
import PhotoSizeCell from '~/components/PhotoSizeCells';
import { Spacer } from '~/components/common/style.web';
import {
  CustomPagesInstanceQuery,
  PageFormat,
  PageType,
  UpdateCustomPagesInstanceInput,
} from '~/data/types/graphql';
import { TabScreenLayout } from '~/screens/style';
import { getFileSize } from '~/utils/helpers';
import { t } from '~/utils/i18n';
import {
  FileDetails,
  getFileDetail,
  getFileWithDetailsFromLibrary,
  getImageFromLibrary,
} from '~/utils/image-picker';
import { ImageQualityBadgeColor } from '../../CreateCustomPage/layout';
import {
  ButtonCreate,
  Section,
  ImageContainer,
  Row,
  ImageContent,
  ImageBanner,
  DeleteIconContainer,
  DeleteIcon,
  FormatSection,
  PhotoFormatContainer,
  PhotoFormatDescription,
  ImageDescriptionContainer,
  ImageDescription,
} from '../../CreateCustomPage/layout/style';
import { PHOTO_FORMAT_CELL_WIDTH } from '../../ProfilePageSetup/layout/style';
import { ContainerWrapper } from './style';

type EditCustomPageLayoutProps = {
  onBack: () => void;
  customPageData?: CustomPagesInstanceQuery;
  onEditCustomPage: (data: UpdateCustomPagesInstanceInput) => void;
  onDeleteCustomPage: () => void;
};

const photoFormats: PageFormat[] = [
  PageFormat.PORTRAIT,
  PageFormat.LANDSCAPE,
  PageFormat.CUSTOM,
];
export default function EditCustomPage({
  onBack,
  onEditCustomPage,
  onDeleteCustomPage,
  customPageData,
}: EditCustomPageLayoutProps): JSX.Element {
  const [customPageTitle, setCustomPageTitle] = useState(
    customPageData?.customPagesInstance?.title || '',
  );
  const [imgUri, setImgUri] = useState<FileDetails>({
    uri: customPageData?.customPagesInstance?.page || '',
    quality: customPageData?.customPagesInstance?.pageQuality || undefined,
    type: customPageData?.customPagesInstance?.pageType || undefined,
  });
  const [selectedPhotoFormat, setSelectedPhotoFormat] = useState<PageFormat>(
    customPageData?.customPagesInstance?.pageFormat || PageFormat.PORTRAIT,
  );

  // we will use this when we have a function to check the image quality
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customPageImageQuality, setCustomPageImageQuality] = useState(
    customPageData?.customPagesInstance?.pageQuality || '',
  );

  const onPickImage = async () => {
    const imgUris = await getImageFromLibrary();
    const imgUri = imgUris?.[0];
    const imageDetail = await getFileDetail({
      uri: imgUri,
      type: PageType.IMAGE,
    });
    setImgUri(imageDetail);
  };

  const onPickDocument = async () => {
    const imgUri = await getFileWithDetailsFromLibrary();
    setImgUri(imgUri?.[0]);
  };
  const isImgUriPdf =
    imgUri?.type === 'application/pdf' || imgUri?.type === PageType.PDF;
  useEffect(() => {
    const initialTitle = customPageData?.customPagesInstance?.title;
    const initialUri = customPageData?.customPagesInstance?.page;
    const initialPageFormat = customPageData?.customPagesInstance?.pageFormat;

    const hasTitleChanged =
      !!customPageTitle && !!initialTitle && customPageTitle !== initialTitle;
    const hasUriChanged =
      !!imgUri.uri && !!initialUri && imgUri.uri !== initialUri;
    const hasPageFormatChanged =
      !!selectedPhotoFormat &&
      !!initialPageFormat &&
      selectedPhotoFormat !== initialPageFormat;
    if (hasTitleChanged || hasUriChanged || hasPageFormatChanged) {
      onEditCustomPage({
        id: customPageData?.customPagesInstance?.id || '',
        title: customPageTitle,
        pageFormat: selectedPhotoFormat,
        page: imgUri?.uri as string,
        pageType: imgUri?.type,
        pageQuality: imgUri?.quality,
      });
    }
  }, [customPageTitle, imgUri, selectedPhotoFormat]);

  const onRemoveImage = () => {
    setImgUri({
      name: '',
      type: '',
      uri: '',
      size: '',
      quality: undefined,
    });
  };

  return (
    <TabScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.createCustomPage')}
      onBackPress={onBack}
    >
      <ContainerWrapper showsVerticalScrollIndicator={false}>
        <Section title={t('createCustomPage.titleSection')} />
        <InputField
          testID={'CustomPageTitle'}
          value={customPageTitle}
          onChangeText={(query) => setCustomPageTitle(query)}
          label={t('createCustomPage.titleInput')}
        />
        <ImageContainer>
          <ImageContent>
            {isImgUriPdf ? (
              <PDFViewer
                uri={imgUri?.uri}
                hidePreview={true}
                height={170}
                width={121}
                name={imgUri?.name}
              />
            ) : (
              <ImageBanner
                key={imgUri?.uri}
                testID={`PagePhotoCategoryCardImage`}
                source={{ uri: imgUri?.uri }}
              ></ImageBanner>
            )}
          </ImageContent>
          {imgUri?.uri && (
            <DeleteIconContainer onPress={onRemoveImage}>
              <DeleteIcon />
            </DeleteIconContainer>
          )}
          <ImageDescriptionContainer>
            {imgUri?.uri && (
              <ImageDescription>
                {`${t('createCustomPage.imageType')} ${
                  imgUri?.type ? t(`createCustomPage.${imgUri.type}`) : ''
                }`}
              </ImageDescription>
            )}

            {imgUri?.uri && !isImgUriPdf && (
              <>
                <ImageDescription>{`${t('createCustomPage.imageSize')} ${
                  imgUri?.size ? getFileSize(imgUri?.size) : ''
                }`}</ImageDescription>
                <ImageDescription>
                  {imgUri?.quality && (
                    <Badge
                      colorTheme={
                        ImageQualityBadgeColor[imgUri?.quality as string]
                      }
                      text={customPageImageQuality as string}
                    />
                  )}
                </ImageDescription>
              </>
            )}
          </ImageDescriptionContainer>
        </ImageContainer>
        <Row>
          <Button
            testID="buttonUploadPdf"
            text={t('createCustomPage.uploadPdf')}
            onPress={onPickDocument}
            type="secondary-base"
            size="sm"
          />
          {Platform.OS === 'ios' ? (
            <Button
              testID="buttonUploadImage"
              text={t('createCustomPage.uploadImage')}
              onPress={onPickImage}
              type="secondary-base"
              size="sm"
            />
          ) : null}
        </Row>
        <FormatSection title={t('createCustomPage.formatSection')} />
        <PhotoFormatContainer>
          {photoFormats.map((photoFormat) => (
            <PhotoSizeCell
              key={photoFormat}
              testID={`buttonFormat${photoFormat}`}
              format={photoFormat}
              selected={photoFormat === selectedPhotoFormat}
              width={PHOTO_FORMAT_CELL_WIDTH}
              onPress={() => setSelectedPhotoFormat(photoFormat)}
            />
          ))}
        </PhotoFormatContainer>
        <PhotoFormatDescription>
          {t('createCustomPage.formatDescription')}
        </PhotoFormatDescription>
        <FormatSection title={t('createCustomPage.dangerZone')} />
        <Spacer v={15} />
        <ButtonCreate
          text={t('createCustomPage.deleteBtn')}
          onPress={onDeleteCustomPage}
          size="lg"
          minWidth={360}
          flex
          type={'destructive'}
        />
        <Spacer v={20} />
      </ContainerWrapper>
    </TabScreenLayout>
  );
}
