import React, {
  useState,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from 'react';
import DateTimePicker, {
  DateTimePickerEvent,
} from '@react-native-community/datetimepicker';
import { Platform } from 'react-native';
import { Modalize } from 'react-native-modalize';
import { Portal } from 'react-native-portalize';
import Button from '~/components/Button';
import { formattedDate } from '~/utils/dates';
import { Container, Content, Title, WebContent } from './style';

//ref https://github.com/dima-bu/react-time-input/blob/master/src/timeInput.jsx
export const isValid = (val: string) => {
  const regexp = /^\d{0,2}?:?\d{0,2}$/;

  const [hoursStr, minutesStr] = val.split(':');

  if (!regexp.test(val)) {
    return false;
  }

  const hours = Number(hoursStr);
  const minutes = Number(minutesStr);

  const isValidHour = (hour: number) =>
    Number.isInteger(hour) && hour >= 0 && hour < 24;
  const isValidMinutes = (minutes: number) =>
    (Number.isInteger(minutes) && hours >= 0 && hours < 24) ||
    Number.isNaN(minutes);

  if (!isValidHour(hours) || !isValidMinutes(minutes)) {
    return false;
  }

  if (minutes < 10 && Number(minutesStr[0]) > 5) {
    return false;
  }

  const valArr = val.indexOf(':') !== -1 ? val.split(':') : [val];

  // check mm and HH
  if (
    valArr[0] &&
    valArr[0].length &&
    (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)
  ) {
    return false;
  }

  if (
    valArr[1] &&
    valArr[1].length &&
    (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)
  ) {
    return false;
  }

  return true;
};

export type ModalTimePickerHandler = Modalize;

export type ModalTimePickerProps = {
  testID?: string;
  title: string;
  buttonText: string;
  time?: Date;
  contentOnly?: boolean;
  onConfirmTime: (time: Date) => void;
};

const timeStrToDate = (time: string) => new Date(`2000-01-01T${time}:00`);

const ModalTimePicker: ForwardRefRenderFunction<
  ModalTimePickerHandler,
  ModalTimePickerProps
> = (
  {
    testID,
    title,
    buttonText,
    time = timeStrToDate('10:00'),
    contentOnly,
    onConfirmTime,
  },
  ref,
) => {
  const [open, setOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState<Date>(time);
  const [selectedTimeStr, setSelectedTimeStr] = useState<string>(
    formattedDate(time, 'HH:mm'),
  );

  useImperativeHandle(ref, () => ({
    open() {
      Platform.OS === 'android' && setOpen(true);
    },
    close() {
      Platform.OS === 'android' && setOpen(false);
    },
  }));

  const onSelectedTime = (event: DateTimePickerEvent, date?: Date) => {
    if (Platform.OS === 'android' && event.type === 'dismissed') {
      setOpen(false);
    } else if (Platform.OS === 'android' && event.type === 'set') {
      setOpen(false);
      date && onConfirmTime(date);
    }
    date && setSelectedTime(date);
  };

  const onChangeHandler = (val: string) => {
    if (val == selectedTimeStr) {
      return;
    }
    if (isValid(val)) {
      if (
        val.length === 2 &&
        selectedTimeStr.length !== 3 &&
        val.indexOf(':') === -1
      ) {
        val = val + ':';
      }

      if (val.length === 2 && selectedTimeStr.length === 3) {
        val = val.slice(0, 1);
      }

      if (val.length > 5) {
        return false;
      }

      setSelectedTimeStr(val);

      if (val.length === 5) {
        onConfirmTime(timeStrToDate(val));
      }
    }
  };

  const renderContent = () => {
    return (
      <Content>
        <Title>{title}</Title>
        {(() => {
          if (Platform.OS === 'web') {
            return (
              <WebContent>
                <input
                  type={'tel'}
                  value={selectedTimeStr}
                  onChange={(e) => onChangeHandler(e.target.value)}
                  style={{
                    height: 40,
                    fontSize: 20,
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                />
              </WebContent>
            );
          }
          return (
            <DateTimePicker
              mode={'time'}
              value={selectedTime}
              display={'spinner'}
              onChange={onSelectedTime}
            />
          );
        })()}
        <Button
          size={'xl'}
          text={buttonText}
          type={'primary-brand-02'}
          state={selectedTime ? 'default' : 'disabled'}
          flex
          flexScreenContainer="stack"
          onPress={() => {
            if (Platform.OS === 'web') {
              onConfirmTime(timeStrToDate(selectedTimeStr));
            }
            if (selectedTime) {
              onConfirmTime(selectedTime);
            }
          }}
        />
      </Content>
    );
  };

  if (contentOnly) {
    return renderContent();
  }

  if (Platform.OS === 'android') {
    return (
      <Container testID={testID}>
        {open ? (
          <DateTimePicker
            mode={'time'}
            value={selectedTime}
            display={'spinner'}
            onChange={onSelectedTime}
          />
        ) : null}
      </Container>
    );
  }

  return (
    <Container testID={testID}>
      <Portal>
        <Modalize ref={ref} adjustToContentHeight handlePosition={'inside'}>
          {renderContent()}
        </Modalize>
      </Portal>
    </Container>
  );
};

export default forwardRef(ModalTimePicker);
