import { Image } from 'react-native';
import { PageFormat, PageQuality, PageType } from '~/data/types/graphql';
import { getFileName } from '~/utils/helpers';

export async function getFileDetail({
  uri,
  name = getFileName(uri),
  type,
  size,
}: {
  uri: string;
  name?: string | null;
  type?: string | null;
  size?: number | null;
}) {
  const convertPixelsToMillimeters = (pixels: number): number => {
    const PIXELS_PER_INCH = 160;
    const MILLIMETERS_PER_INCH = 25.4;
    return pixels / (PIXELS_PER_INCH * (1 / MILLIMETERS_PER_INCH));
  };

  const fileExtension = type?.split('/')[1];
  const isImageFile = type?.startsWith('image/');

  const fileDetail = {
    uri,
    name: name,
    type: fileExtension === 'pdf' ? PageType.PDF : PageType.IMAGE,
    size: '',
    quality: undefined as PageQuality | undefined,
  };

  if (isImageFile) {
    const { width, height } = await getImageDimensions(uri);
    const widthMM = convertPixelsToMillimeters(width).toFixed(0);
    const heightMM = convertPixelsToMillimeters(height).toFixed(0);
    fileDetail.size = `${widthMM}x${heightMM} mm`;
    fileDetail.quality = determineImageQuality(width, height);
  } else {
    fileDetail.size = size ? size.toString() : '';
  }
  return fileDetail;
}

async function getImageDimensions(
  uri: string,
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    Image.getSize(
      uri,
      (width, height) => {
        resolve({ width, height });
      },
      (error) => {
        reject(error);
      },
    );
  });
}

const MM_TO_INCH = 25.4;
const SIZE_IN_INCHES = {
  [PageFormat.PORTRAIT]: [210 / MM_TO_INCH, 297 / MM_TO_INCH],
  [PageFormat.LANDSCAPE]: [210 / MM_TO_INCH, 148.5 / MM_TO_INCH],
  [PageFormat.CUSTOM]: [210 / MM_TO_INCH, 297 / MM_TO_INCH],
};

const PPI_GOOD = 300;
const PPI_CRITICAL = 200;

const calculateRequiredPixels = (inches: number, ppi: number): number =>
  inches * ppi;

const determineImageQuality = (
  widthPx: number,
  heightPx: number,
): PageQuality => {
  const isLandscape = widthPx > heightPx;
  const pageFormat: PageFormat = isLandscape
    ? PageFormat.LANDSCAPE
    : PageFormat.PORTRAIT;

  const [widthIn, heightIn] = SIZE_IN_INCHES[pageFormat];
  const requiredWidthPx = calculateRequiredPixels(widthIn, PPI_GOOD);
  const requiredHeightPx = calculateRequiredPixels(heightIn, PPI_GOOD);
  const criticalWidthPx = calculateRequiredPixels(widthIn, PPI_CRITICAL);
  const criticalHeightPx = calculateRequiredPixels(heightIn, PPI_CRITICAL);

  const qualityChecks = [
    {
      condition: widthPx >= requiredWidthPx && heightPx >= requiredHeightPx,
      quality: PageQuality.GOOD,
    },

    {
      condition: widthPx >= criticalWidthPx && heightPx >= criticalHeightPx,
      quality: PageQuality.CRITICAL,
    },
  ];

  const qualityCheck = qualityChecks.find((check) => check.condition);

  return qualityCheck ? qualityCheck.quality : PageQuality.POOR;
};
