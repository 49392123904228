import { LinearGradient, LinearGradientProps } from 'expo-linear-gradient';
import styled, { DefaultTheme } from 'styled-components/native';
import Icon from '~/components/Icon';

interface IVariations {
  checked: boolean;
}

interface CellStateHelper {
  bgColors: (
    checked: boolean,
    theme: DefaultTheme,
  ) => [string, string, ...string[]];
}

export const byStateHelper: CellStateHelper = {
  bgColors: (
    checked: boolean,
    theme: DefaultTheme,
  ): [string, string, ...string[]] => {
    if (checked) {
      return [theme.color.base.c3, theme.color.base.c2];
    }
    return [theme.color.base.c3, theme.color.base.c3];
  },
};

export const PressableContainer = styled.Pressable``;

interface ILNContainer {
  checked: boolean;
}

export const LNContainer = styled(LinearGradient).attrs<
  ILNContainer,
  LinearGradientProps
>(({ checked, theme }) => ({
  colors: byStateHelper.bgColors(checked, theme),
  start: { x: 0, y: 0 },
  end: { x: 1, y: 0 },
}))<ILNContainer>`
  border-radius: 5px;
  padding: 1px;
`;

export const Container = styled.View<IVariations>`
  background-color: ${({ theme, checked }) =>
    checked ? theme.color.base.c1 : theme.color.base.c0};
  padding: 16px;
  border-radius: 5px;
`;

export const Name = styled.Text`
  color: ${({ theme }) => theme.color.base.c10};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  line-height: 20px;
`;

export const Row = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const Address = styled.Text`
  color: ${({ theme }) => theme.color.base.c7};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 14px;
  line-height: 18px;
`;

export const City = styled.Text`
  color: ${({ theme }) => theme.color.base.c7};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 14px;
  line-height: 18px;
`;

export const TextContainer = styled.View`
  flex: 1;
`;

export const ArrowIcon = styled(Icon).attrs<IVariations>(
  ({ theme, checked }) => ({
    size: 24,
    color: checked ? theme.color.brand_02 : theme.color.base.c10,
  }),
)<IVariations>`
  padding-horizontal: 2px;
`;
