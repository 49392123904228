import { useMutation, useQuery } from '@apollo/client';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { getImageFile } from '~/utils/helpers';
import { DEFAULT_ALBUM_PHOTOS_OFFSET, DEFAULT_LIST_OFFSET } from '../constants';
import { CollagesAlbum, CollagesPhoto } from '../models/yearbook';
import {
  ADD_PHOTO_TO_COLLAGES_ALBUM,
  COLLAGES_PHOTOS,
  REMOVE_PHOTO_FROM_COLLAGES_ALBUM,
} from '../operations/yearbook';
import {
  readCollagesAlbumsQuery,
  readCollagesPhotosQuery,
  writeCollagesAlbumsQuery,
  writeCollagesPhotosQuery,
} from '../operations/yearbook/helpers';

type CollagesPhotosHookOptions = {
  moduleInstanceId: string;
  collageAlbumId: string;
};

type CollagesPhotosHookProps = {
  collagesPhotos: CollagesPhoto[];
  loading: boolean;
  hasNextPage?: boolean;
  deletePhotoLoading: boolean;
  onRefresh: () => void;
  onLoadMore: () => void;
  onUploadPhoto: (photoUri: string, onFinished: () => void) => Promise<void>;
  onDeletePhoto: (photoId: string, onFinished: () => void) => Promise<void>;
};

const useCollagesPhotos = (
  options: CollagesPhotosHookOptions,
): CollagesPhotosHookProps => {
  const { moduleInstanceId, collageAlbumId } = options;

  const { authUserGroupId } = useAuth();

  const collagesPhotosVar = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
    collagesAlbum: collageAlbumId,
    authUserGroupId,
  };

  const {
    data: collagesPhotosData,
    loading,
    refetch: refetchCollagesPhotos,
    fetchMore: fetchMoreCollagesPhotos,
  } = useQuery(COLLAGES_PHOTOS, {
    variables: collagesPhotosVar,
    notifyOnNetworkStatusChange: true,
  });

  const collagesPhotos: CollagesPhoto[] =
    (collagesPhotosData?.collagesPhotos?.edges.map(
      (edge) => edge?.node,
    ) as CollagesPhoto[]) || [];
  const hasNextPage = collagesPhotosData?.collagesPhotos?.pageInfo.hasNextPage;
  const endCursor = collagesPhotosData?.collagesPhotos?.pageInfo.endCursor;

  const [addPhotoToCollagesAlbum] = useMutation(ADD_PHOTO_TO_COLLAGES_ALBUM, {
    update(cache, { data }) {
      const newPhoto = data?.addPhotoToCollagesAlbum?.photo;

      //updates collages photos query
      const currentCollagesPhotoQuery = readCollagesPhotosQuery({
        cache,
        variables: collagesPhotosVar,
      });
      const currentCollagesPhotoCount =
        currentCollagesPhotoQuery?.collagesPhotos?.edges?.length || 0;
      if (currentCollagesPhotoQuery?.collagesPhotos && newPhoto) {
        writeCollagesPhotosQuery({
          cache,
          variables: collagesPhotosVar,
          data: {
            __typename: 'Query',
            collagesPhotos: {
              ...currentCollagesPhotoQuery.collagesPhotos,
              edges: [
                {
                  __typename: 'CollagesPhotoNodeEdge',
                  node: newPhoto,
                },
                ...currentCollagesPhotoQuery.collagesPhotos.edges,
              ],
            },
          },
        });
      }

      //updates collages query
      const collagesAlbumsVar = {
        after: null,
        first: DEFAULT_LIST_OFFSET,
        cpFirst: DEFAULT_ALBUM_PHOTOS_OFFSET,
        moduleInstance: moduleInstanceId,
        authUserGroupId,
      };

      const currentCollagesAlbumQuery = readCollagesAlbumsQuery({
        cache,
        variables: collagesAlbumsVar,
      });
      if (currentCollagesAlbumQuery?.collagesAlbums && newPhoto) {
        const updatedCollagesAlbumQueryEdges =
          currentCollagesAlbumQuery.collagesAlbums.edges.map((edge) => {
            if (edge?.node?.id === collageAlbumId) {
              const collagesPhotos = edge.node?.collagesPhotos || {
                __typename: 'CollagesPhotoNodeConnection',
                edges: [],
              };
              const udpatedCollagesAlbum: CollagesAlbum = {
                ...edge.node,
                photosCount: currentCollagesPhotoCount + 1,
                collagesPhotos: {
                  ...collagesPhotos,
                  edges: [
                    {
                      __typename: 'CollagesPhotoNodeEdge',
                      node: newPhoto,
                    },
                    ...collagesPhotos.edges,
                  ],
                },
              };
              return {
                ...edge,
                node: udpatedCollagesAlbum,
              };
            }
            return edge;
          });

        writeCollagesAlbumsQuery({
          cache,
          variables: collagesAlbumsVar,
          data: {
            ...currentCollagesAlbumQuery,
            collagesAlbums: {
              ...currentCollagesAlbumQuery.collagesAlbums,
              edges: updatedCollagesAlbumQueryEdges,
            },
          },
        });
      }
    },
  });
  const [removePhotoFromCollagesAlbum, { loading: deletePhotoLoading }] =
    useMutation(REMOVE_PHOTO_FROM_COLLAGES_ALBUM);

  const onRefresh = () => {
    refetchCollagesPhotos();
  };

  const onLoadMore = () => {
    hasNextPage &&
      fetchMoreCollagesPhotos({
        variables: {
          ...collagesPhotosVar,
          after: endCursor,
        },
      });
  };

  const onUploadPhoto = async (photoUri: string, onFinished: () => void) => {
    try {
      const photo = await getImageFile(photoUri);
      await addPhotoToCollagesAlbum({
        variables: {
          input: {
            id: collageAlbumId,
            photo,
          },
          authUserGroupId,
        },
      });
      onFinished();
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onDeletePhoto = async (photoId: string, onFinished: () => void) => {
    try {
      await removePhotoFromCollagesAlbum({
        variables: {
          input: {
            id: photoId,
          },
        },
        update(cache, { data }) {
          if (!data?.removePhotoFromCollagesAlbum?.success) {
            return;
          }

          //updates collages photos query
          const currentCollagesPhotoQuery = readCollagesPhotosQuery({
            cache,
            variables: collagesPhotosVar,
          });
          const currentCollagesPhotoCount =
            currentCollagesPhotoQuery?.collagesPhotos?.edges?.length;
          if (currentCollagesPhotoQuery?.collagesPhotos) {
            const fileteredEdgePhotos =
              currentCollagesPhotoQuery.collagesPhotos.edges.filter(
                (edge) => edge?.node?.id !== photoId,
              );
            writeCollagesPhotosQuery({
              cache,
              variables: collagesPhotosVar,
              data: {
                __typename: 'Query',
                collagesPhotos: {
                  ...currentCollagesPhotoQuery.collagesPhotos,
                  edges: fileteredEdgePhotos,
                },
              },
            });
          }

          //updates collages query
          const collagesAlbumsVar = {
            after: null,
            first: DEFAULT_LIST_OFFSET,
            cpFirst: DEFAULT_ALBUM_PHOTOS_OFFSET,
            moduleInstance: moduleInstanceId,
            authUserGroupId,
          };

          const currentCollagesAlbumQuery = readCollagesAlbumsQuery({
            cache,
            variables: collagesAlbumsVar,
          });

          if (
            currentCollagesAlbumQuery?.collagesAlbums &&
            currentCollagesPhotoCount
          ) {
            const updatedCollagesAlbumQueryEdges =
              currentCollagesAlbumQuery.collagesAlbums.edges.map((edge) => {
                if (
                  edge?.node?.id === collageAlbumId &&
                  edge.node?.collagesPhotos?.edges
                ) {
                  const udpatedCollagesAlbum: CollagesAlbum = {
                    ...edge.node,
                    photosCount: currentCollagesPhotoCount - 1,
                    collagesPhotos: {
                      ...edge.node.collagesPhotos,
                      edges: [
                        ...edge.node.collagesPhotos.edges.filter(
                          (edge) => edge?.node?.id !== photoId,
                        ),
                      ],
                    },
                  };

                  return {
                    ...edge,
                    node: udpatedCollagesAlbum,
                  };
                }
                return edge;
              });

            writeCollagesAlbumsQuery({
              cache,
              variables: collagesAlbumsVar,
              data: {
                ...currentCollagesAlbumQuery,
                collagesAlbums: {
                  ...currentCollagesAlbumQuery.collagesAlbums,
                  edges: updatedCollagesAlbumQueryEdges,
                },
              },
            });
          }
        },
      });
      onFinished();
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };
  return {
    collagesPhotos,
    loading,
    hasNextPage,
    deletePhotoLoading,
    onRefresh,
    onLoadMore,
    onUploadPhoto,
    onDeletePhoto,
  };
};

export default useCollagesPhotos;
