import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  CompositeNavigationProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import useGroupUsers from '~/data/hooks/useGroupUsers';
import useTeams from '~/data/hooks/useTeams';
import { Event } from '~/data/models/calendar';
import {
  DELETE_EVENT,
  EDIT_EVENT,
  EVENT,
  SET_EVENT_BANNER,
} from '~/data/operations/calendar';
import { EditEventInput } from '~/data/types/graphql';
import { PlanningStackParamList, RootStackParamList } from '~/navigation/types';
import { getImageFile } from '~/utils/helpers';
import EditEventLayout from './layout';

type EditEventNavProp = CompositeNavigationProp<
  StackNavigationProp<RootStackParamList, 'EditEvent'>,
  CompositeNavigationProp<
    StackNavigationProp<RootStackParamList>,
    StackNavigationProp<PlanningStackParamList>
  >
>;
type EditEventRouteProp = RouteProp<RootStackParamList, 'EditEvent'>;

export default function EditEvent(): JSX.Element {
  const navigation = useNavigation<EditEventNavProp>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const {
    params: { eventId },
  } = useRoute<EditEventRouteProp>();

  const { authUserGroup } = useAuth();
  const { groupUsers } = useGroupUsers();
  const { teams } = useTeams();

  const [editEvent] = useMutation(EDIT_EVENT);
  const [deleteEvent] = useMutation(DELETE_EVENT);
  const [setEventBanner] = useMutation(SET_EVENT_BANNER);

  const { data } = useQuery(EVENT, {
    variables: { id: eventId },
  });

  const event = data?.event as Event;

  const onEditEvent = async ({
    title,
    bannerImage,
    location,
    startDateTime,
    endDateTime,
    isAllDay,
    eventType,
    authorType,
    authorUser,
    authorGroup,
    authorTeam,
    isJoinable,
    isPublic,
    description,
  }: EditEventInput) => {
    try {
      setSubmitting(true);
      const { data } = await editEvent({
        variables: {
          input: {
            id: eventId,
            title,
            bannerImage,
            location,
            startDateTime,
            endDateTime,
            isAllDay,
            eventType,
            authorType,
            authorUser,
            authorGroup,
            authorTeam,
            isJoinable,
            isPublic,
            description,
          },
        },
      });

      if (data?.editEvent?.errors) {
        Snackbar.show(data?.editEvent?.errors[0]?.messages[0] as string);
      }

      if (!eventId) {
        const messages = data?.editEvent?.errors?.map(
          (error) => error?.messages[0],
        );
        const errorMessage = messages?.[0] as string;

        Snackbar.show(errorMessage);
        return;
      }

      const bannerImageUri = bannerImage;
      if (bannerImageUri) {
        const imageFile = bannerImageUri
          ? await getImageFile(bannerImageUri)
          : null;
        await setEventBanner({
          variables: {
            input: {
              id: eventId,
              bannerImage: imageFile,
            },
          },
        });
      }
      navigation.goBack();
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const onDeleteEvent = async () => {
    try {
      setSubmitting(true);
      const { data } = await deleteEvent({
        variables: {
          input: {
            id: eventId,
          },
        },
      });

      const isSuccess = data?.deleteEvent?.success || false;
      if (isSuccess) {
        navigation.navigate('Calendar');
        return;
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <EditEventLayout
      event={event}
      loggedUserGroup={authUserGroup}
      teams={teams}
      groupsUser={groupUsers}
      loading={submitting}
      onBack={() => navigation.goBack()}
      onUpdateEvent={onEditEvent}
      onDeleteEvent={onDeleteEvent}
    />
  );
}
