import styled from 'styled-components/native';
import {
  NEWS_ITEM_HEIGHT,
  NEWS_ITEM_WIDTH,
} from '~/components/BannerCarousel/style';
import Image from '~/components/Image';
import TrackingFlatlist from '~/components/TrackingFlatList';

export const Header = styled.View``;

export const Section = styled.View`
  margin-top: 24px;
  margin-bottom: 16px;
  padding-horizontal: 16px;
`;

export const List = styled.FlatList`
  flex: 1;
`;

export const BannerList = styled(TrackingFlatlist)`
  padding-top: 16px;
  padding-left: 16px;
  padding-bottom: 8px;
`;

export const AssetImage = styled(Image)`
  width: ${NEWS_ITEM_WIDTH}px;
  height: ${NEWS_ITEM_HEIGHT}px;
  border-radius: 5px;
`;
