import styled from 'styled-components/native';
import Button from '~/components/Button';
import Icon from '~/components/Icon';
import ImageComp from '~/components/Image';
import SectionHeader from '~/components/SectionHeader';

export const ScrollView = styled.ScrollView`
  padding-horizontal: 16px;
`;
export const Section = styled(SectionHeader)`
  margin-top: 40px;
`;

export const FormatSection = styled(SectionHeader)`
  margin-top: 30px;
`;

export const ImageContent = styled.View`
  padding-top: 32px;
`;

export const ImageBanner = styled(ImageComp)`
  background-color: ${({ theme }) => theme.color.base.c3};
  aspect-ratio: 1.8;
  align-items: center;
  justify-content: center;
  height: 171px;
  max-width: 121px;
`;

export const DeleteIcon = styled(Icon).attrs(() => ({
  name: 'x-close',
  size: 20,
}))``;

export const DeleteIconContainer = styled.TouchableOpacity`
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 20px;
  left: 110px;
  right: 12px;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.color.brand_01};
`;

export const ImageContainer = styled.View`
  flex-direction: row;
  margin-bottom: 16px;
`;

export const Row = styled.View`
  flex-direction: row;
  gap: 8px;
`;

export const PhotoFormatContainer = styled.View`
  flex-direction: row;
  margin: 16px 0 27px 0;
  justify-content: space-between;
`;

export const PhotoFormatDescription = styled.Text`
  color: ${({ theme }) => theme.color.base.c8};
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  line-height: 20px;
`;

export const ButtonCreate = styled(Button)`
  margin-bottom: 0px;
  position: absolute;
`;

export const ImageDescriptionContainer = styled.View`
  margin-left: 21px;
  justify-content: flex-end;
  align-items: end;
`;

export const ImageDescription = styled.Text`
  color: ${({ theme }) => theme.color.base.c8};
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  line-height: 20px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
`;

export const NoImageUploaded = styled.View`
  width: 121px;
  height: 171px;
  background-color: ${({ theme }) => theme.color.base.c3};
`;
