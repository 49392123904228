import 'expo-asset';
import 'setimmediate'; //https://github.com/software-mansion/react-native-reanimated/issues/4140
import 'react-native-root-siblings';
import { registerRootComponent } from 'expo';
import App from './App';

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
