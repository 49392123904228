/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: { input: any; output: any };
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: { input: any; output: any };
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: { input: any; output: any };
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: { input: any; output: any };
  /**
   *
   * `Geometry` scalar may be represented in a few ways:
   * - Well-known text (WKT)
   * - Hexadecimal (HEX)
   * - GeoJSON
   *
   */
  Geometry: { input: any; output: any };
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: { input: any; output: any };
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: { input: any; output: any };
};

export type AcceptUserInGroupInput = {
  accept: Scalars['Boolean']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type AcceptUserInGroupPayload = {
  __typename: 'AcceptUserInGroupPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  userGroup: Maybe<UserGroupNode>;
};

export type AcceptUserInTeamInput = {
  accept: Scalars['Boolean']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type AcceptUserInTeamPayload = {
  __typename: 'AcceptUserInTeamPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  userTeam: Maybe<UserTeamNode>;
};

export type ActivateModuleInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type ActivateModuleInstancePayload = {
  __typename: 'ActivateModuleInstancePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  moduleInstance: Maybe<ModuleInstanceNode>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type ActivateTeamInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type ActivateTeamPayload = {
  __typename: 'ActivateTeamPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
  team: Maybe<TeamNode>;
};

export type AddImagesToPostInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  images?: InputMaybe<Scalars['Upload']['input']>;
};

export type AddImagesToPostPayload = {
  __typename: 'AddImagesToPostPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  post: Maybe<PostNode>;
};

export type AddPhotoToCollagesAlbumInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** CollagesAlbum ID */
  id: Scalars['ID']['input'];
  photo?: InputMaybe<Scalars['Upload']['input']>;
};

export type AddPhotoToCollagesAlbumPayload = {
  __typename: 'AddPhotoToCollagesAlbumPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  photo: Maybe<CollagesPhotoNode>;
};

export type AddPhotosToCollagesAlbumInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  photos?: InputMaybe<Scalars['Upload']['input']>;
};

export type AddPhotosToCollagesAlbumPayload = {
  __typename: 'AddPhotosToCollagesAlbumPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  collagesAlbum: Maybe<CollagesAlbumNode>;
};

export type AddUserToGroupInput = {
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  defaultAvatarId?: InputMaybe<Scalars['ID']['input']>;
  groupId: Scalars['ID']['input'];
  role: Scalars['String']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type AddUserToGroupPayload = {
  __typename: 'AddUserToGroupPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  userGroup: Maybe<UserGroupNode>;
};

export type AddUserToTeamInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type AddUserToTeamPayload = {
  __typename: 'AddUserToTeamPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  teams: Maybe<Array<Maybe<TeamNode>>>;
  userTeam: Maybe<UserTeamNode>;
};

export type AdminAcceptInstituteRequestInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  street?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['ID']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type AdminAcceptInstituteRequestPayload = {
  __typename: 'AdminAcceptInstituteRequestPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  instituteRequest: Maybe<InstituteRequestNode>;
};

export type AdminAddUserToTeamInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type AdminAddUserToTeamPayload = {
  __typename: 'AdminAddUserToTeamPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  user: Maybe<UserNode>;
};

export type AdminClearUserFlagsInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type AdminClearUserFlagsPayload = {
  __typename: 'AdminClearUserFlagsPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
  user: Maybe<UserNode>;
};

export type AdminDeleteInstituteRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type AdminDeleteInstituteRequestPayload = {
  __typename: 'AdminDeleteInstituteRequestPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type AdminDeleteUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type AdminDeleteUserPayload = {
  __typename: 'AdminDeleteUserPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
  user: Maybe<UserNode>;
};

export type AdminDowngradeUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type AdminDowngradeUserPayload = {
  __typename: 'AdminDowngradeUserPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
  user: Maybe<UserNode>;
};

export type AdminForgetPasswordLinkInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type AdminForgetPasswordLinkPayload = {
  __typename: 'AdminForgetPasswordLinkPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  forgetPasswordLink: Maybe<Scalars['String']['output']>;
};

export type AdminRejectInstituteRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  existingInstitute: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type AdminRejectInstituteRequestPayload = {
  __typename: 'AdminRejectInstituteRequestPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  instituteRequest: Maybe<InstituteRequestNode>;
  message: Maybe<Scalars['String']['output']>;
  responseCode: Maybe<Scalars['Int']['output']>;
};

export type AdminSetBrandLookInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  colorId: Scalars['ID']['input'];
  fontId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type AdminSetBrandLookPayload = {
  __typename: 'AdminSetBrandLookPayload';
  brandLookSelection: Maybe<BrandLookSelectionNode>;
  clientMutationId: Maybe<Scalars['String']['output']>;
};

export type AdminUpdateInstituteInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  instituteTypeName: Scalars['String']['input'];
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  marketProfile?: InputMaybe<Scalars['ID']['input']>;
  marketProfileName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  street?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['ID']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUpdateInstitutePayload = {
  __typename: 'AdminUpdateInstitutePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  institute: Maybe<InstituteNode>;
  message: Maybe<Scalars['String']['output']>;
  responseCode: Maybe<Scalars['Int']['output']>;
};

export type AdminUpdateUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  communicationLanguage?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneCountryCode?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUpdateUserPayload = {
  __typename: 'AdminUpdateUserPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  user: Maybe<UserNode>;
};

export type AdminUpgradeUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  toSuperuser?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * This mutation upgrades normal user to agent, or agent to super user. If you want to immediately upgrade normal
 * user to superuser, you need to provide to_superuser=True. In any case, if user is already superuser, an error is returned
 * that user is already a superuser.
 */
export type AdminUpgradeUserPayload = {
  __typename: 'AdminUpgradeUserPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
  user: Maybe<UserNode>;
};

/** An enumeration. */
export enum AdvertisementsAssetChannelTypeChoices {
  /** Banner Ads */
  BANNER_ADS = 'BANNER_ADS',
  /** Career Post */
  CAREER_POST = 'CAREER_POST',
  /** Sponsored Events */
  SPONSORED_EVENTS = 'SPONSORED_EVENTS',
  /** Sponsored Poll */
  SPONSORED_POLL = 'SPONSORED_POLL',
  /** Sponsored Post */
  SPONSORED_POST = 'SPONSORED_POST',
  /** Sponsored Quiz */
  SPONSORED_QUIZ = 'SPONSORED_QUIZ',
}

/** An enumeration. */
export enum AllowedVoteType {
  EVERYBODY = 'EVERYBODY',
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  OTHER = 'OTHER',
}

/** An enumeration. */
export enum AnswerType {
  COUPLE = 'COUPLE',
  LIST = 'LIST',
  PERSON = 'PERSON',
}

/** An enumeration. */
export enum ApprovalSetting {
  ANY_MEMBER = 'ANY_MEMBER',
  AUTO_APPROVAL = 'AUTO_APPROVAL',
  ONLY_ADMIN = 'ONLY_ADMIN',
}

export type AssetNode = Node & {
  __typename: 'AssetNode';
  assetId: Scalars['Int']['output'];
  campaign: Maybe<CampaignNode>;
  channelData: Maybe<Array<Maybe<BannerAd>>>;
  channelType: AdvertisementsAssetChannelTypeChoices;
  conversionRate: Maybe<Scalars['Float']['output']>;
  endDate: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  startDate: Maybe<Scalars['DateTime']['output']>;
  targetingConfig: Scalars['JSONString']['output'];
  targetingFilters: Maybe<Array<Maybe<TargetingFilterType>>>;
  totalClicks: Maybe<Scalars['Int']['output']>;
  totalViews: Maybe<Scalars['Int']['output']>;
  visibility: Maybe<AssetVisibilityType>;
};

/** An enumeration. */
export enum AssetVisibilityType {
  LIVE = 'LIVE',
  OFFLINE = 'OFFLINE',
  SCHEDULE = 'SCHEDULE',
}

/** An enumeration. */
export enum AuthorType {
  GROUP = 'GROUP',
  TEAM = 'TEAM',
  USER = 'USER',
}

export type BannerAd = {
  __typename: 'BannerAd';
  imageLarge: Maybe<Scalars['String']['output']>;
  imageSmall: Maybe<Scalars['String']['output']>;
  trackingUrl: Maybe<Scalars['String']['output']>;
};

export type BlockUserMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type BlockUserMutationPayload = {
  __typename: 'BlockUserMutationPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  message: Maybe<Scalars['String']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type BrandColorNode = Node & {
  __typename: 'BrandColorNode';
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isDefault: Maybe<Scalars['Boolean']['output']>;
  modified: Scalars['DateTime']['output'];
  name: Maybe<Scalars['String']['output']>;
  primary: Maybe<Scalars['String']['output']>;
  secondary: Maybe<Scalars['String']['output']>;
  status: Maybe<ColorStatus>;
};

export type BrandColorNodeConnection = {
  __typename: 'BrandColorNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BrandColorNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `BrandColorNode` and its cursor. */
export type BrandColorNodeEdge = {
  __typename: 'BrandColorNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<BrandColorNode>;
};

export type BrandFontNode = Node & {
  __typename: 'BrandFontNode';
  created: Scalars['DateTime']['output'];
  file: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isDefault: Maybe<Scalars['Boolean']['output']>;
  modified: Scalars['DateTime']['output'];
  name: Maybe<Scalars['String']['output']>;
  secondary: Maybe<Scalars['String']['output']>;
  status: Maybe<FontStatus>;
  weight: Maybe<Scalars['Int']['output']>;
};

export type BrandFontNodeConnection = {
  __typename: 'BrandFontNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BrandFontNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `BrandFontNode` and its cursor. */
export type BrandFontNodeEdge = {
  __typename: 'BrandFontNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<BrandFontNode>;
};

export type BrandLookSelectionHistoryNode = Node & {
  __typename: 'BrandLookSelectionHistoryNode';
  color: Maybe<BrandColorNode>;
  created: Scalars['DateTime']['output'];
  font: Maybe<BrandFontNode>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  user: Maybe<UserNode>;
};

export type BrandLookSelectionHistoryNodeConnection = {
  __typename: 'BrandLookSelectionHistoryNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BrandLookSelectionHistoryNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `BrandLookSelectionHistoryNode` and its cursor. */
export type BrandLookSelectionHistoryNodeEdge = {
  __typename: 'BrandLookSelectionHistoryNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<BrandLookSelectionHistoryNode>;
};

export type BrandLookSelectionNode = Node & {
  __typename: 'BrandLookSelectionNode';
  color: Maybe<BrandColorNode>;
  created: Scalars['DateTime']['output'];
  font: Maybe<BrandFontNode>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  user: Maybe<UserNode>;
};

export type BrandLookSelectionNodeConnection = {
  __typename: 'BrandLookSelectionNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BrandLookSelectionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `BrandLookSelectionNode` and its cursor. */
export type BrandLookSelectionNodeEdge = {
  __typename: 'BrandLookSelectionNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<BrandLookSelectionNode>;
};

export type CampaignNode = Node & {
  __typename: 'CampaignNode';
  assets: Maybe<Array<Maybe<AssetNode>>>;
  conversionRate: Maybe<Scalars['Float']['output']>;
  customer: Maybe<Scalars['String']['output']>;
  endDate: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  orderId: Maybe<Scalars['String']['output']>;
  startDate: Scalars['DateTime']['output'];
  status: Maybe<CampaignStatus>;
  totalClicks: Maybe<Scalars['Int']['output']>;
  totalViews: Maybe<Scalars['Int']['output']>;
  type: Maybe<CampaignType>;
  utm: Scalars['String']['output'];
};

export type CampaignNodeConnection = {
  __typename: 'CampaignNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CampaignNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `CampaignNode` and its cursor. */
export type CampaignNodeEdge = {
  __typename: 'CampaignNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<CampaignNode>;
};

/** An enumeration. */
export enum CampaignStatus {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
  INACTIVE = 'INACTIVE',
}

/** An enumeration. */
export enum CampaignType {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
}

/** An enumeration. */
export enum ChannelType {
  BANNER_ADS = 'BANNER_ADS',
  CAREER_POST = 'CAREER_POST',
  SPONSORED_EVENTS = 'SPONSORED_EVENTS',
  SPONSORED_POLL = 'SPONSORED_POLL',
  SPONSORED_POST = 'SPONSORED_POST',
  SPONSORED_QUIZ = 'SPONSORED_QUIZ',
}

export type CityLocationNode = Node & {
  __typename: 'CityLocationNode';
  city: Scalars['String']['output'];
  country: InstitutesCityLocationCountryChoices;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  location: Maybe<Scalars['Geometry']['output']>;
  region: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type CityLocationNodeConnection = {
  __typename: 'CityLocationNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CityLocationNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CityLocationNode` and its cursor. */
export type CityLocationNodeEdge = {
  __typename: 'CityLocationNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<CityLocationNode>;
};

export type CityNode = {
  __typename: 'CityNode';
  name: Maybe<Scalars['String']['output']>;
};

export type CollagesAlbumNode = Node & {
  __typename: 'CollagesAlbumNode';
  collagesPhotos: Maybe<CollagesPhotoNodeConnection>;
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  moduleInstance: ModuleInstanceNode;
  name: Scalars['String']['output'];
  photosCount: Maybe<Scalars['Int']['output']>;
  suggestedNames: Maybe<Array<Maybe<CollagesAlbumSuggestedNameNode>>>;
};

export type CollagesAlbumNodecollagesPhotosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CollagesAlbumNodeConnection = {
  __typename: 'CollagesAlbumNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CollagesAlbumNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CollagesAlbumNode` and its cursor. */
export type CollagesAlbumNodeEdge = {
  __typename: 'CollagesAlbumNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<CollagesAlbumNode>;
};

export type CollagesAlbumSuggestedNameNode = Node & {
  __typename: 'CollagesAlbumSuggestedNameNode';
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  name: Maybe<Scalars['String']['output']>;
};

export type CollagesAlbumSuggestedNameNodeConnection = {
  __typename: 'CollagesAlbumSuggestedNameNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CollagesAlbumSuggestedNameNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CollagesAlbumSuggestedNameNode` and its cursor. */
export type CollagesAlbumSuggestedNameNodeEdge = {
  __typename: 'CollagesAlbumSuggestedNameNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<CollagesAlbumSuggestedNameNode>;
};

export type CollagesPhotoLikeNode = Node & {
  __typename: 'CollagesPhotoLikeNode';
  collagesPhoto: Maybe<CollagesPhotoNode>;
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  userGroup: Maybe<UserGroupNode>;
};

export type CollagesPhotoLikeNodeConnection = {
  __typename: 'CollagesPhotoLikeNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CollagesPhotoLikeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CollagesPhotoLikeNode` and its cursor. */
export type CollagesPhotoLikeNodeEdge = {
  __typename: 'CollagesPhotoLikeNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<CollagesPhotoLikeNode>;
};

export type CollagesPhotoNode = Node & {
  __typename: 'CollagesPhotoNode';
  collagesAlbum: CollagesAlbumNode;
  collagesPhotoLikes: Maybe<CollagesPhotoLikeNodeConnection>;
  collagesPhotoLikesCount: Maybe<Scalars['Int']['output']>;
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  photo: Scalars['String']['output'];
  userGroup: UserGroupNode;
};

export type CollagesPhotoNodecollagesPhotoLikesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  userGroup?: InputMaybe<Scalars['ID']['input']>;
};

export type CollagesPhotoNodeConnection = {
  __typename: 'CollagesPhotoNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CollagesPhotoNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CollagesPhotoNode` and its cursor. */
export type CollagesPhotoNodeEdge = {
  __typename: 'CollagesPhotoNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<CollagesPhotoNode>;
};

export type CollagesSetupNode = Node & {
  __typename: 'CollagesSetupNode';
  addPhotosTeam: Maybe<TeamNode>;
  createAlbumsTeam: Maybe<TeamNode>;
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  moduleInstance: ModuleInstanceNode;
  photosCount: Maybe<Scalars['Int']['output']>;
  viewPhotosTeam: Maybe<TeamNode>;
};

export type ColorNode = Node & {
  __typename: 'ColorNode';
  created: Scalars['DateTime']['output'];
  hex: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
};

export type ColorNodeConnection = {
  __typename: 'ColorNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ColorNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ColorNode` and its cursor. */
export type ColorNodeEdge = {
  __typename: 'ColorNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<ColorNode>;
};

/** An enumeration. */
export enum ColorStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type CommentLikeNode = Node & {
  __typename: 'CommentLikeNode';
  comment: Maybe<CommentNode>;
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  userGroup: Maybe<UserGroupNode>;
};

export type CommentLikeNodeConnection = {
  __typename: 'CommentLikeNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CommentLikeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CommentLikeNode` and its cursor. */
export type CommentLikeNodeEdge = {
  __typename: 'CommentLikeNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<CommentLikeNode>;
};

export type CommentNode = Node & {
  __typename: 'CommentNode';
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  likes: Maybe<CommentLikeNodeConnection>;
  likesCount: Maybe<Scalars['Int']['output']>;
  modified: Scalars['DateTime']['output'];
  owner: Maybe<Owner>;
  post: Maybe<PostNode>;
  team: Maybe<TeamNode>;
  text: Scalars['String']['output'];
  userGroup: Maybe<UserGroupNode>;
};

export type CommentNodelikesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  userGroup?: InputMaybe<Scalars['ID']['input']>;
};

export type CommentNodeConnection = {
  __typename: 'CommentNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CommentNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CommentNode` and its cursor. */
export type CommentNodeEdge = {
  __typename: 'CommentNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<CommentNode>;
};

export type CommentPostInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  owner?: InputMaybe<Owner>;
  team?: InputMaybe<Scalars['ID']['input']>;
  text: Scalars['String']['input'];
};

export type CommentPostPayload = {
  __typename: 'CommentPostPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  post: Maybe<PostNode>;
};

export type CountryData = {
  __typename: 'CountryData';
  country: Maybe<Scalars['String']['output']>;
  counts: Maybe<Array<Maybe<DateCount>>>;
};

export type CreateAssetEventInput = {
  assetId: Scalars['ID']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventType: EventTypeEnum;
  userId: Scalars['ID']['input'];
  variant?: InputMaybe<VariantTypeEnum>;
};

export type CreateAssetEventPayload = {
  __typename: 'CreateAssetEventPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type CreateBannerAssetInput = {
  assetLargeImage: Scalars['Upload']['input'];
  assetSmallImage: Scalars['Upload']['input'];
  campaign: Scalars['ID']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  targetingFilters?: InputMaybe<Array<InputMaybe<TargetingFilterInput>>>;
  trackingUrl: Scalars['String']['input'];
};

export type CreateBannerAssetPayload = {
  __typename: 'CreateBannerAssetPayload';
  asset: Maybe<AssetNode>;
  clientMutationId: Maybe<Scalars['String']['output']>;
};

export type CreateCampaignInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customer?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['DateTime']['input'];
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  orderId?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
  totalClicks?: InputMaybe<Scalars['Int']['input']>;
  totalViews?: InputMaybe<Scalars['Int']['input']>;
  type: Scalars['String']['input'];
  utm: Scalars['String']['input'];
};

export type CreateCampaignPayload = {
  __typename: 'CreateCampaignPayload';
  campaign: Maybe<CampaignNode>;
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
};

export type CreateCollagesAlbumInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  moduleInstance: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type CreateCollagesAlbumPayload = {
  __typename: 'CreateCollagesAlbumPayload';
  album: Maybe<CollagesAlbumNode>;
  clientMutationId: Maybe<Scalars['String']['output']>;
  collagesAlbum: Maybe<CollagesAlbumNode>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
};

export type CreateCustomListInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  moduleId: Scalars['ID']['input'];
  options?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: Scalars['String']['input'];
};

export type CreateCustomListOptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customListId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type CreateCustomListOptionPayload = {
  __typename: 'CreateCustomListOptionPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  customListOption: Maybe<CustomListOptionNode>;
};

export type CreateCustomListPayload = {
  __typename: 'CreateCustomListPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  customList: Maybe<CustomListNode>;
};

export type CreateCustomPagesInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Module Instance ID */
  moduleInstance: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Upload']['input']>;
  pageFormat: Scalars['String']['input'];
  pageQuality?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['String']['input']>;
  pageType?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type CreateCustomPagesInstancePayload = {
  __typename: 'CreateCustomPagesInstancePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  customPagesInstance: Maybe<CustomPagesInstanceNode>;
};

export type CreateDataExportInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  exportCollages?: InputMaybe<Scalars['Boolean']['input']>;
  exportProfilePages?: InputMaybe<Scalars['Boolean']['input']>;
  exportQuotes?: InputMaybe<Scalars['Boolean']['input']>;
  exportRankings?: InputMaybe<Scalars['Boolean']['input']>;
  exportReports?: InputMaybe<Scalars['Boolean']['input']>;
  groupId: Scalars['ID']['input'];
  onlyAgent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateDataExportPayload = {
  __typename: 'CreateDataExportPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type CreateDataPolicyAcceptanceInput = {
  agreed?: InputMaybe<Scalars['Boolean']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ip: Scalars['String']['input'];
  user: Scalars['ID']['input'];
  version: Scalars['String']['input'];
};

export type CreateDataPolicyAcceptancePayload = {
  __typename: 'CreateDataPolicyAcceptancePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  dataPolicyAcceptance: Maybe<DataPolicyAcceptanceNode>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  user: Maybe<DataPolicyAcceptanceNode>;
};

export type CreateEventInput = {
  authorGroup?: InputMaybe<Scalars['ID']['input']>;
  authorTeam?: InputMaybe<Scalars['ID']['input']>;
  authorType: Scalars['String']['input'];
  authorUser?: InputMaybe<Scalars['ID']['input']>;
  bannerImage?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyLogo?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  ctaLink?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  endDateTime: Scalars['DateTime']['input'];
  eventType: Scalars['String']['input'];
  isAllDay?: InputMaybe<Scalars['Boolean']['input']>;
  isJoinable?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isSponsored?: InputMaybe<Scalars['Boolean']['input']>;
  location: Scalars['String']['input'];
  startDateTime: Scalars['DateTime']['input'];
  title: Scalars['String']['input'];
};

export type CreateEventPayload = {
  __typename: 'CreateEventPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  event: Maybe<EventNode>;
};

export type CreateGroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  institute: Scalars['ID']['input'];
  marketProfile?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  role: Scalars['String']['input'];
  type?: InputMaybe<Scalars['ID']['input']>;
  year: Scalars['Int']['input'];
};

export type CreateGroupPayload = {
  __typename: 'CreateGroupPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  group: Maybe<GroupNode>;
};

export type CreateInstituteInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  instituteTypeName: Scalars['String']['input'];
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  marketProfileName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  street?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type CreateInstitutePayload = {
  __typename: 'CreateInstitutePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  institute: Maybe<InstituteNode>;
  message: Maybe<Scalars['String']['output']>;
  responseCode: Maybe<Scalars['Int']['output']>;
};

export type CreateModuleInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  group: Scalars['ID']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  module: Scalars['ID']['input'];
};

export type CreateModuleInstancePayload = {
  __typename: 'CreateModuleInstancePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  instance: Maybe<ModuleInstanceNode>;
  moduleInstance: Maybe<ModuleInstanceNode>;
};

export type CreatePollInput = {
  allowComments?: InputMaybe<Scalars['Boolean']['input']>;
  allowRevote?: InputMaybe<Scalars['Boolean']['input']>;
  anonymousVotes?: InputMaybe<Scalars['Boolean']['input']>;
  answersAllowed: Scalars['Int']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deadline: Scalars['DateTime']['input'];
  group: Scalars['ID']['input'];
  owner: Scalars['String']['input'];
  privateResults?: InputMaybe<Scalars['Boolean']['input']>;
  question: Scalars['String']['input'];
  randomizeOptions?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
};

export type CreatePollOptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['Upload']['input']>;
  poll: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type CreatePollOptionPayload = {
  __typename: 'CreatePollOptionPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  pollOption: Maybe<PollOptionNode>;
};

export type CreatePollPayload = {
  __typename: 'CreatePollPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  poll: Maybe<PollNode>;
};

export type CreatePollVoteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pollOption: Scalars['ID']['input'];
};

export type CreatePollVotePayload = {
  __typename: 'CreatePollVotePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  pollVote: Maybe<PollVoteNode>;
};

export type CreatePostInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  group: Scalars['ID']['input'];
  owner: Scalars['String']['input'];
  team?: InputMaybe<Scalars['ID']['input']>;
  text: Scalars['String']['input'];
};

export type CreatePostPayload = {
  __typename: 'CreatePostPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  post: Maybe<PostNode>;
};

export type CreateProfilePageAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  profilePageQuestion: Scalars['ID']['input'];
  profilePageQuestionOption?: InputMaybe<Scalars['ID']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CreateProfilePageAnswerPayload = {
  __typename: 'CreateProfilePageAnswerPayload';
  answer: Maybe<ProfilePageAnswerNode>;
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  profilePageAnswer: Maybe<ProfilePageAnswerNode>;
};

export type CreateProfilePageCommentInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  moduleInstance: Scalars['ID']['input'];
  profileUserGroup: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type CreateProfilePageCommentPayload = {
  __typename: 'CreateProfilePageCommentPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  comment: Maybe<ProfilePageCommentNode>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  profilePageComment: Maybe<ProfilePageCommentNode>;
};

export type CreateProfilePagePhotoCategoryInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  moduleInstance: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CreateProfilePagePhotoCategoryPayload = {
  __typename: 'CreateProfilePagePhotoCategoryPayload';
  category: Maybe<ProfilePagePhotoCategoryNode>;
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  profilePagePhotoCategory: Maybe<ProfilePagePhotoCategoryNode>;
};

export type CreateProfilePagePhotoInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['Upload']['input']>;
  profilePagePhotoCategory: Scalars['ID']['input'];
};

export type CreateProfilePagePhotoPayload = {
  __typename: 'CreateProfilePagePhotoPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  profilePagePhoto: Maybe<ProfilePagePhotoNode>;
};

export type CreateProfilePageQuestionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  moduleInstance: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type CreateProfilePageQuestionOptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  profilePageQuestion: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type CreateProfilePageQuestionOptionPayload = {
  __typename: 'CreateProfilePageQuestionOptionPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  option: Maybe<ProfilePageQuestionOptionNode>;
  profilePageQuestionOption: Maybe<ProfilePageQuestionOptionNode>;
};

export type CreateProfilePageQuestionPayload = {
  __typename: 'CreateProfilePageQuestionPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  profilePageQuestion: Maybe<ProfilePageQuestionNode>;
  question: Maybe<ProfilePageQuestionNode>;
};

export type CreateQuotesInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  moduleInstance: Scalars['ID']['input'];
  quote: Scalars['String']['input'];
};

export type CreateQuotesInstancePayload = {
  __typename: 'CreateQuotesInstancePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  quote: Maybe<QuotesInstanceNode>;
  quotesInstance: Maybe<QuotesInstanceNode>;
};

export type CreateRankingsAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  rankingsQuestion: Scalars['ID']['input'];
  votee1UserGroup?: InputMaybe<Scalars['ID']['input']>;
  votee2UserGroup?: InputMaybe<Scalars['ID']['input']>;
  voteeListOption?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateRankingsAnswerPayload = {
  __typename: 'CreateRankingsAnswerPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  rankingsAnswer: Maybe<RankingsAnswerNode>;
};

export type CreateRankingsQuestionInput = {
  allowedVotes?: InputMaybe<Scalars['String']['input']>;
  answerType: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customList?: InputMaybe<Scalars['ID']['input']>;
  moduleInstance: Scalars['ID']['input'];
  onlyOppositeGender?: InputMaybe<Scalars['Boolean']['input']>;
  question: Scalars['String']['input'];
};

export type CreateRankingsQuestionPayload = {
  __typename: 'CreateRankingsQuestionPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  question: Maybe<RankingsQuestionNode>;
  rankingsQuestion: Maybe<RankingsQuestionNode>;
};

export type CreateReportsInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  editTeam?: InputMaybe<Scalars['ID']['input']>;
  maxChars: Scalars['Int']['input'];
  moduleInstance: Scalars['ID']['input'];
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
  viewTeam?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateReportsInstancePayload = {
  __typename: 'CreateReportsInstancePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  instance: Maybe<ReportsInstanceNode>;
  reportsInstance: Maybe<ReportsInstanceNode>;
};

export type CreateTeamInput = {
  approvalSetting?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  color: Scalars['ID']['input'];
  group: Scalars['ID']['input'];
  icon: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type CreateTeamPayload = {
  __typename: 'CreateTeamPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  team: Maybe<TeamNode>;
};

export type CreateTermsAcceptanceInput = {
  agreed?: InputMaybe<Scalars['Boolean']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ip: Scalars['String']['input'];
  user: Scalars['ID']['input'];
  version: Scalars['String']['input'];
};

export type CreateTermsAcceptancePayload = {
  __typename: 'CreateTermsAcceptancePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  termsAcceptance: Maybe<TermsAcceptanceNode>;
  user: Maybe<TermsAcceptanceNode>;
};

export type CreateUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  communicationLanguage?: InputMaybe<Scalars['String']['input']>;
  countryOfResidence?: InputMaybe<Scalars['String']['input']>;
  dataPolicyAcceptance: Scalars['Boolean']['input'];
  dataPolicyVersion: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<Scalars['String']['input']>;
  inviteCode?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneCountryCode?: InputMaybe<Scalars['String']['input']>;
  referredBy?: InputMaybe<Scalars['String']['input']>;
  subscribeNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
  termsAcceptance: Scalars['Boolean']['input'];
  termsVersion: Scalars['String']['input'];
};

export type CreateUserPayload = {
  __typename: 'CreateUserPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  user: Maybe<UserNode>;
};

export type CustomListNode = Node & {
  __typename: 'CustomListNode';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  moduleInstance: ModuleInstanceNode;
  options: Maybe<Array<Maybe<CustomListOptionNode>>>;
  title: Maybe<Scalars['String']['output']>;
};

export type CustomListNodeConnection = {
  __typename: 'CustomListNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomListNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CustomListNode` and its cursor. */
export type CustomListNodeEdge = {
  __typename: 'CustomListNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<CustomListNode>;
};

export type CustomListOptionNode = Node & {
  __typename: 'CustomListOptionNode';
  customList: CustomListNode;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  text: Maybe<Scalars['String']['output']>;
};

export type CustomListOptionNodeConnection = {
  __typename: 'CustomListOptionNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomListOptionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CustomListOptionNode` and its cursor. */
export type CustomListOptionNodeEdge = {
  __typename: 'CustomListOptionNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<CustomListOptionNode>;
};

export type CustomPagesInstanceNode = Node & {
  __typename: 'CustomPagesInstanceNode';
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  moduleInstance: ModuleInstanceNode;
  page: Maybe<Scalars['String']['output']>;
  pageFormat: Maybe<PageFormat>;
  pageQuality: Maybe<PageQuality>;
  pageSize: Maybe<Scalars['String']['output']>;
  pageType: Maybe<PageType>;
  title: Scalars['String']['output'];
  userGroup: Maybe<UserGroupNode>;
};

export type CustomPagesInstanceNodeConnection = {
  __typename: 'CustomPagesInstanceNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomPagesInstanceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CustomPagesInstanceNode` and its cursor. */
export type CustomPagesInstanceNodeEdge = {
  __typename: 'CustomPagesInstanceNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<CustomPagesInstanceNode>;
};

export type CustomPagesSetupNode = Node & {
  __typename: 'CustomPagesSetupNode';
  createPagesTeam: Maybe<TeamNode>;
  created: Scalars['DateTime']['output'];
  /** Intro messages to classmates to explain what to upload. */
  guide: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  moduleInstance: ModuleInstanceNode;
  viewPagesTeam: Maybe<TeamNode>;
};

export type DashboardFilterInput = {
  country?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  graduationType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  graduationYear?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  instituteType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};

export type DataExportNode = Node & {
  __typename: 'DataExportNode';
  exportCollages: Maybe<Scalars['Boolean']['output']>;
  exportFile: Maybe<Scalars['String']['output']>;
  exportProfilePages: Maybe<Scalars['Boolean']['output']>;
  exportQuotes: Maybe<Scalars['Boolean']['output']>;
  exportRankings: Maybe<Scalars['Boolean']['output']>;
  exportReports: Maybe<Scalars['Boolean']['output']>;
  exportedFile: Maybe<Scalars['String']['output']>;
  group: Maybe<GroupNode>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  internalId: Maybe<Scalars['String']['output']>;
  isodatetime: Maybe<Scalars['String']['output']>;
  userGroup: Maybe<UserGroupNode>;
};

export type DataExportNodeConnection = {
  __typename: 'DataExportNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DataExportNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DataExportNode` and its cursor. */
export type DataExportNodeEdge = {
  __typename: 'DataExportNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<DataExportNode>;
};

export type DataPolicyAcceptanceNode = Node & {
  __typename: 'DataPolicyAcceptanceNode';
  agreed: Scalars['Boolean']['output'];
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  ip: Scalars['String']['output'];
  modified: Scalars['DateTime']['output'];
  user: UserNode;
  version: Scalars['String']['output'];
};

export type DateCount = {
  __typename: 'DateCount';
  count: Maybe<Scalars['Int']['output']>;
  date: Maybe<Scalars['String']['output']>;
};

export type DeactivateModuleInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeactivateModuleInstancePayload = {
  __typename: 'DeactivateModuleInstancePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  moduleInstance: Maybe<ModuleInstanceNode>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type DefaultAvatarNode = Node & {
  __typename: 'DefaultAvatarNode';
  avatar: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
};

export type DefaultAvatarNodeConnection = {
  __typename: 'DefaultAvatarNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DefaultAvatarNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DefaultAvatarNode` and its cursor. */
export type DefaultAvatarNodeEdge = {
  __typename: 'DefaultAvatarNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<DefaultAvatarNode>;
};

export type DeleteAssetInput = {
  assetId: Scalars['ID']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteAssetPayload = {
  __typename: 'DeleteAssetPayload';
  assetId: Maybe<Scalars['ID']['output']>;
  clientMutationId: Maybe<Scalars['String']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteCampaignInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteCampaignPayload = {
  __typename: 'DeleteCampaignPayload';
  campaign: Maybe<CampaignNode>;
  clientMutationId: Maybe<Scalars['String']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteCommentInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteCommentPayload = {
  __typename: 'DeleteCommentPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  post: Maybe<PostNode>;
};

export type DeleteCustomListInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteCustomListOptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteCustomListOptionPayload = {
  __typename: 'DeleteCustomListOptionPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  customListOption: Maybe<CustomListOptionNode>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteCustomListPayload = {
  __typename: 'DeleteCustomListPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  customList: Maybe<CustomListNode>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteEventPayload = {
  __typename: 'DeleteEventPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type DeletePollInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeletePollOptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeletePollOptionPayload = {
  __typename: 'DeletePollOptionPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  pollOption: Maybe<PollOptionNode>;
};

export type DeletePollPayload = {
  __typename: 'DeletePollPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  poll: Maybe<PollNode>;
};

export type DeletePollVoteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pollOptionId: Scalars['ID']['input'];
};

export type DeletePollVotePayload = {
  __typename: 'DeletePollVotePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  pollVote: Maybe<PollVoteNode>;
};

export type DeletePostInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeletePostPayload = {
  __typename: 'DeletePostPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  post: Maybe<PostNode>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteUserPayload = {
  __typename: 'DeleteUserPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
  user: Maybe<UserNode>;
};

export type DestroyCollagesAlbumInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DestroyCollagesAlbumPayload = {
  __typename: 'DestroyCollagesAlbumPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  collagesAlbum: Maybe<CollagesAlbumNode>;
};

export type DestroyCustomPagesInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DestroyCustomPagesInstancePayload = {
  __typename: 'DestroyCustomPagesInstancePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  customPagesInstanceId: Maybe<Scalars['ID']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type DestroyGroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DestroyGroupPayload = {
  __typename: 'DestroyGroupPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  group: Maybe<GroupNode>;
};

export type DestroyModuleInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DestroyModuleInstancePayload = {
  __typename: 'DestroyModuleInstancePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  moduleInstance: Maybe<ModuleInstanceNode>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type DestroyProfilePageAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DestroyProfilePageAnswerPayload = {
  __typename: 'DestroyProfilePageAnswerPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  profilePageAnswer: Maybe<ProfilePageAnswerNode>;
};

export type DestroyProfilePageCommentInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DestroyProfilePageCommentPayload = {
  __typename: 'DestroyProfilePageCommentPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  profilePageComment: Maybe<ProfilePageCommentNode>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type DestroyProfilePagePhotoCategoryInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DestroyProfilePagePhotoCategoryPayload = {
  __typename: 'DestroyProfilePagePhotoCategoryPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  profilePagePhotoCategory: Maybe<ProfilePagePhotoCategoryNode>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type DestroyProfilePagePhotoInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DestroyProfilePagePhotoPayload = {
  __typename: 'DestroyProfilePagePhotoPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  profilePagePhoto: Maybe<ProfilePagePhotoNode>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type DestroyProfilePageQuestionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DestroyProfilePageQuestionOptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DestroyProfilePageQuestionOptionPayload = {
  __typename: 'DestroyProfilePageQuestionOptionPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  profilePageQuestionOption: Maybe<ProfilePageQuestionOptionNode>;
};

export type DestroyProfilePageQuestionPayload = {
  __typename: 'DestroyProfilePageQuestionPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  profilePageQuestion: Maybe<ProfilePageQuestionNode>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type DestroyQuotesInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DestroyQuotesInstancePayload = {
  __typename: 'DestroyQuotesInstancePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  quotesInstance: Maybe<QuotesInstanceNode>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type DestroyRankingsAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DestroyRankingsAnswerPayload = {
  __typename: 'DestroyRankingsAnswerPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  rankingsAnswer: Maybe<RankingsAnswerNode>;
};

export type DestroyRankingsQuestionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DestroyRankingsQuestionPayload = {
  __typename: 'DestroyRankingsQuestionPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  rankingsQuestion: Maybe<RankingsQuestionNode>;
};

export type DestroyReportsInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DestroyReportsInstancePayload = {
  __typename: 'DestroyReportsInstancePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  reportsInstanceId: Maybe<Scalars['ID']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type DestroyTeamInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DestroyTeamPayload = {
  __typename: 'DestroyTeamPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
  team: Maybe<TeamNode>;
};

/** Debugging information for the current query. */
export type DjangoDebug = {
  __typename: 'DjangoDebug';
  /** Raise exceptions for this API query. */
  exceptions: Maybe<Array<Maybe<DjangoDebugException>>>;
  /** Executed SQL queries for this API query. */
  sql: Maybe<Array<Maybe<DjangoDebugSQL>>>;
};

/** Represents a single exception raised. */
export type DjangoDebugException = {
  __typename: 'DjangoDebugException';
  /** The class of the exception */
  excType: Scalars['String']['output'];
  /** The message of the exception */
  message: Scalars['String']['output'];
  /** The stack trace */
  stack: Scalars['String']['output'];
};

/** Represents a single database query made to a Django managed DB. */
export type DjangoDebugSQL = {
  __typename: 'DjangoDebugSQL';
  /** The Django database alias (e.g. 'default'). */
  alias: Scalars['String']['output'];
  /** Duration of this database query in seconds. */
  duration: Scalars['Float']['output'];
  /** Postgres connection encoding if available. */
  encoding: Maybe<Scalars['String']['output']>;
  /** Whether this database query was a SELECT. */
  isSelect: Scalars['Boolean']['output'];
  /** Whether this database query took more than 10 seconds. */
  isSlow: Scalars['Boolean']['output'];
  /** Postgres isolation level if available. */
  isoLevel: Maybe<Scalars['String']['output']>;
  /** JSON encoded database query parameters. */
  params: Scalars['String']['output'];
  /** The raw SQL of this query, without params. */
  rawSql: Scalars['String']['output'];
  /** The actual SQL sent to this database. */
  sql: Maybe<Scalars['String']['output']>;
  /** Start time of this database query. */
  startTime: Scalars['Float']['output'];
  /** Stop time of this database query. */
  stopTime: Scalars['Float']['output'];
  /** Postgres transaction ID if available. */
  transId: Maybe<Scalars['String']['output']>;
  /** Postgres transaction status if available. */
  transStatus: Maybe<Scalars['String']['output']>;
  /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
  vendor: Scalars['String']['output'];
};

export type DuplicateAssetInput = {
  assetId: Scalars['ID']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type DuplicateAssetPayload = {
  __typename: 'DuplicateAssetPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  newAsset: Maybe<AssetNode>;
};

export type EditBannerAdAssetInput = {
  assetLargeImage?: InputMaybe<Scalars['Upload']['input']>;
  assetSmallImage?: InputMaybe<Scalars['Upload']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  targetingFilters?: InputMaybe<Array<InputMaybe<TargetingFilterInput>>>;
  trackingUrl?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<AssetVisibilityType>;
};

export type EditBannerAdAssetPayload = {
  __typename: 'EditBannerAdAssetPayload';
  asset: Maybe<AssetNode>;
  clientMutationId: Maybe<Scalars['String']['output']>;
};

export type EditCampaignInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customer?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  orderId?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
  type: Scalars['String']['input'];
  utm: Scalars['String']['input'];
};

export type EditCampaignPayload = {
  __typename: 'EditCampaignPayload';
  campaign: Maybe<CampaignNode>;
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
};

export type EditCommentInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type EditCommentPayload = {
  __typename: 'EditCommentPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  post: Maybe<PostNode>;
};

export type EditEventInput = {
  authorGroup?: InputMaybe<Scalars['ID']['input']>;
  authorTeam?: InputMaybe<Scalars['ID']['input']>;
  authorType: Scalars['String']['input'];
  authorUser?: InputMaybe<Scalars['ID']['input']>;
  bannerImage?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyLogo?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  ctaLink?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDateTime: Scalars['DateTime']['input'];
  eventType: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  isAllDay?: InputMaybe<Scalars['Boolean']['input']>;
  isJoinable?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isSponsored?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  startDateTime: Scalars['DateTime']['input'];
  title: Scalars['String']['input'];
};

export type EditEventPayload = {
  __typename: 'EditEventPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  event: Maybe<EventNode>;
};

export type ErrorType = {
  __typename: 'ErrorType';
  field: Scalars['String']['output'];
  messages: Array<Scalars['String']['output']>;
};

export type EventAttendeeNode = Node & {
  __typename: 'EventAttendeeNode';
  event: EventNode;
  eventId: EventNode;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  user: UserNode;
};

export type EventAttendeeNodeConnection = {
  __typename: 'EventAttendeeNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EventAttendeeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `EventAttendeeNode` and its cursor. */
export type EventAttendeeNodeEdge = {
  __typename: 'EventAttendeeNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<EventAttendeeNode>;
};

export type EventNode = Node & {
  __typename: 'EventNode';
  attendees: Maybe<Array<Maybe<EventAttendeeNode>>>;
  authorGroup: Maybe<GroupNode>;
  authorTeam: Maybe<TeamNode>;
  authorType: EventsEventAuthorTypeChoices;
  authorUser: Maybe<UserNode>;
  bannerImage: Maybe<Scalars['String']['output']>;
  companyLogo: Maybe<Scalars['String']['output']>;
  companyName: Maybe<Scalars['String']['output']>;
  ctaLink: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  endDateTime: Scalars['DateTime']['output'];
  eventAuthor: Maybe<AuthorType>;
  eventType: Maybe<EventType>;
  eventattendeeSet: EventAttendeeNodeConnection;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isAllDay: Scalars['Boolean']['output'];
  isJoinable: Scalars['Boolean']['output'];
  isPublic: Scalars['Boolean']['output'];
  isSponsored: Scalars['Boolean']['output'];
  location: Maybe<Scalars['String']['output']>;
  startDateTime: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
};

export type EventNodeeventattendeeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type EventNodeConnection = {
  __typename: 'EventNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EventNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `EventNode` and its cursor. */
export type EventNodeEdge = {
  __typename: 'EventNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<EventNode>;
};

/** An enumeration. */
export enum EventType {
  EVENT = 'EVENT',
  MEETING = 'MEETING',
  PARTY = 'PARTY',
}

export enum EventTypeEnum {
  CLICK = 'CLICK',
  VIEW = 'VIEW',
}

/** An enumeration. */
export enum EventsEventAuthorTypeChoices {
  /** Group */
  GROUP = 'GROUP',
  /** Team */
  TEAM = 'TEAM',
  /** Benutzer */
  USER = 'USER',
}

export type ExternalEventAttendeeNode = Node & {
  __typename: 'ExternalEventAttendeeNode';
  attendee: UserNode;
  eventId: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  platform: Maybe<Platform>;
};

export type ExternalEventAttendeeNodeConnection = {
  __typename: 'ExternalEventAttendeeNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExternalEventAttendeeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ExternalEventAttendeeNode` and its cursor. */
export type ExternalEventAttendeeNodeEdge = {
  __typename: 'ExternalEventAttendeeNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<ExternalEventAttendeeNode>;
};

export type ExternalTokensNode = {
  __typename: 'ExternalTokensNode';
  intercom: Maybe<Scalars['String']['output']>;
  shopify: Maybe<Scalars['String']['output']>;
};

/** An enumeration. */
export enum FontStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type ForgetPasswordInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

export type ForgetPasswordPayload = {
  __typename: 'ForgetPasswordPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  message: Maybe<Scalars['String']['output']>;
  user: Maybe<UserNode>;
};

export type GenerateShopifyRedirectUrlInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  returnUrl?: InputMaybe<Scalars['String']['input']>;
};

export type GenerateShopifyRedirectUrlPayload = {
  __typename: 'GenerateShopifyRedirectUrlPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  redirectUrl: Maybe<Scalars['String']['output']>;
};

export type GraduationTypeCountryNode = {
  __typename: 'GraduationTypeCountryNode';
  country: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

export type GraduationTypeNode = Node & {
  __typename: 'GraduationTypeNode';
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
};

export type GraduationTypeNodeConnection = {
  __typename: 'GraduationTypeNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GraduationTypeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `GraduationTypeNode` and its cursor. */
export type GraduationTypeNodeEdge = {
  __typename: 'GraduationTypeNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<GraduationTypeNode>;
};

export type GroupIconNode = Node & {
  __typename: 'GroupIconNode';
  created: Scalars['DateTime']['output'];
  icon: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
};

export type GroupIconNodeConnection = {
  __typename: 'GroupIconNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GroupIconNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `GroupIconNode` and its cursor. */
export type GroupIconNodeEdge = {
  __typename: 'GroupIconNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<GroupIconNode>;
};

export type GroupMemberNode = Node & {
  __typename: 'GroupMemberNode';
  avatar: Maybe<Scalars['String']['output']>;
  collagesalbumSet: CollagesAlbumNodeConnection;
  collagesphotoSet: CollagesPhotoNodeConnection;
  collagesphotolikeSet: CollagesPhotoLikeNodeConnection;
  commentSet: CommentNodeConnection;
  commentlikeSet: CommentLikeNodeConnection;
  created: Scalars['DateTime']['output'];
  custompagesinstanceSet: CustomPagesInstanceNodeConnection;
  defaultAvatar: Maybe<DefaultAvatarNode>;
  email: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  group: GroupNode;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  lastName: Maybe<Scalars['String']['output']>;
  likeSet: LikeNodeConnection;
  modified: Scalars['DateTime']['output'];
  phone: Maybe<Scalars['String']['output']>;
  phoneCountryCode: Maybe<Scalars['String']['output']>;
  pollvoteSet: PollVoteNodeConnection;
  postSet: PostNodeConnection;
  profilePageCommentsGiven: ProfilePageCommentNodeConnection;
  profilePageCommentsReceived: ProfilePageCommentNodeConnection;
  profilepageanswerSet: ProfilePageAnswerNodeConnection;
  profilepagephotoSet: ProfilePagePhotoNodeConnection;
  quotesinstanceSet: QuotesInstanceNodeConnection;
  quotesinstancelikeSet: QuotesInstanceLikeNodeConnection;
  rankingsAnswerGiven: RankingsAnswerNodeConnection;
  rankingsAnswerVotedFor1: RankingsAnswerNodeConnection;
  rankingsAnswerVotedFor2: RankingsAnswerNodeConnection;
  role: GroupsUserGroupRoleChoices;
  status: GroupsUserGroupStatusChoices;
  teams: Maybe<Array<Maybe<TeamNode>>>;
  user: UserNode;
};

export type GroupMemberNodecollagesalbumSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupMemberNodecollagesphotoSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupMemberNodecollagesphotolikeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupMemberNodecommentSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupMemberNodecommentlikeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupMemberNodecustompagesinstanceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupMemberNodelikeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupMemberNodepollvoteSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupMemberNodepostSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupMemberNodeprofilePageCommentsGivenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupMemberNodeprofilePageCommentsReceivedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupMemberNodeprofilepageanswerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupMemberNodeprofilepagephotoSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupMemberNodequotesinstanceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupMemberNodequotesinstancelikeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupMemberNoderankingsAnswerGivenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupMemberNoderankingsAnswerVotedFor1Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupMemberNoderankingsAnswerVotedFor2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupNode = Node & {
  __typename: 'GroupNode';
  allMembers: Array<Maybe<GroupMemberNode>>;
  avatar: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  icon: Maybe<GroupIconNode>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  institute: InstituteNode;
  isActive: Maybe<Scalars['Boolean']['output']>;
  isImported: Maybe<Scalars['Boolean']['output']>;
  marketProfile: Maybe<MarketProfileNode>;
  members: Array<Maybe<GroupMemberNode>>;
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  sampleMembers: Maybe<Array<Maybe<UserGroupNode>>>;
  teams: Maybe<Array<Maybe<TeamNode>>>;
  token: Maybe<Scalars['String']['output']>;
  type: GraduationTypeNode;
  users: Maybe<Array<Maybe<UserGroupNode>>>;
  usersCount: Maybe<Scalars['Int']['output']>;
  /** Who can create a new team? */
  whoCanCreateTeam: Maybe<TeamNode>;
  year: Scalars['Int']['output'];
  yearbookStatistics: Maybe<YearbookStatisticsNode>;
};

export type GroupNodeConnection = {
  __typename: 'GroupNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GroupNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `GroupNode` and its cursor. */
export type GroupNodeEdge = {
  __typename: 'GroupNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<GroupNode>;
};

/** An enumeration. */
export enum GroupsUserGroupRoleChoices {
  /** Student */
  STUDENT = 'STUDENT',
  /** Teacher */
  TEACHER = 'TEACHER',
}

/** An enumeration. */
export enum GroupsUserGroupStatusChoices {
  /** Accepted */
  ACCEPTED = 'ACCEPTED',
  /** Pending */
  PENDING = 'PENDING',
  /** Rejected */
  REJECTED = 'REJECTED',
}

export type IconNode = Node & {
  __typename: 'IconNode';
  created: Scalars['DateTime']['output'];
  icon: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
};

export type IconNodeConnection = {
  __typename: 'IconNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IconNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `IconNode` and its cursor. */
export type IconNodeEdge = {
  __typename: 'IconNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<IconNode>;
};

export type ImportToErpInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['ID']['input'];
  instituteId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type ImportToErpPayload = {
  __typename: 'ImportToErpPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type Importer = {
  __typename: 'Importer';
  error: Maybe<Scalars['String']['output']>;
  ok: Maybe<Scalars['Boolean']['output']>;
  userNumber: Maybe<Scalars['Int']['output']>;
};

export type InstituteActivityType = {
  __typename: 'InstituteActivityType';
  status: Maybe<InstituteActivityTypeEnum>;
  year: Maybe<Scalars['Int']['output']>;
};

export enum InstituteActivityTypeEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type InstituteNode = Node & {
  __typename: 'InstituteNode';
  city: Maybe<Scalars['String']['output']>;
  country: InstitutesInstituteCountryChoices;
  created: Scalars['DateTime']['output'];
  groups: Maybe<Array<Maybe<GroupNode>>>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  instituteActivity: Maybe<Array<Maybe<InstituteActivityType>>>;
  internalId: Scalars['String']['output'];
  isPrivate: Scalars['Boolean']['output'];
  location: Maybe<Scalars['Geometry']['output']>;
  marketProfile: Maybe<MarketProfileNode>;
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  street: Maybe<Scalars['String']['output']>;
  type: InstituteTypeNode;
  website: Maybe<Scalars['String']['output']>;
  zip: Maybe<Scalars['String']['output']>;
};

export type InstituteNodeConnection = {
  __typename: 'InstituteNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InstituteNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `InstituteNode` and its cursor. */
export type InstituteNodeEdge = {
  __typename: 'InstituteNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<InstituteNode>;
};

export type InstituteRequestNode = Node & {
  __typename: 'InstituteRequestNode';
  city: Maybe<Scalars['String']['output']>;
  country: InstitutesInstituteRequestCountryChoices;
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: Maybe<Status>;
  street: Maybe<Scalars['String']['output']>;
  type: InstituteTypeNode;
  user: UserNode;
  website: Maybe<Scalars['String']['output']>;
  zip: Maybe<Scalars['String']['output']>;
};

export type InstituteRequestNodeConnection = {
  __typename: 'InstituteRequestNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InstituteRequestNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `InstituteRequestNode` and its cursor. */
export type InstituteRequestNodeEdge = {
  __typename: 'InstituteRequestNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<InstituteRequestNode>;
};

/** An enumeration. */
export enum InstituteTargetingActivityEnum {
  ACTIVE = 'ACTIVE',
  EMPTY = 'EMPTY',
  INACTIVE = 'INACTIVE',
}

export type InstituteTargetingNode = Node & {
  __typename: 'InstituteTargetingNode';
  activity: Maybe<InstituteTargetingActivityEnum>;
  city: Maybe<Scalars['String']['output']>;
  country: InstitutesInstituteCountryChoices;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  internalId: Scalars['String']['output'];
  location: Maybe<Scalars['Geometry']['output']>;
  name: Scalars['String']['output'];
  type: InstituteTypeNode;
};

export type InstituteTypeNode = Node & {
  __typename: 'InstituteTypeNode';
  country: Maybe<InstitutesInstituteTypeCountryChoices>;
  created: Scalars['DateTime']['output'];
  graduationTypes: Maybe<Array<Maybe<GraduationTypeNode>>>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
};

export type InstituteTypeNodeConnection = {
  __typename: 'InstituteTypeNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InstituteTypeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `InstituteTypeNode` and its cursor. */
export type InstituteTypeNodeEdge = {
  __typename: 'InstituteTypeNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<InstituteTypeNode>;
};

/** An enumeration. */
export enum InstitutesCityLocationCountryChoices {
  /** Andorra */
  AD = 'AD',
  /** Vereinigte Arabische Emirate */
  AE = 'AE',
  /** Afghanistan */
  AF = 'AF',
  /** Antigua und Barbuda */
  AG = 'AG',
  /** Anguilla */
  AI = 'AI',
  /** Albanien */
  AL = 'AL',
  /** Armenien */
  AM = 'AM',
  /** Angola */
  AO = 'AO',
  /** Antarktis */
  AQ = 'AQ',
  /** Argentinien */
  AR = 'AR',
  /** Amerikanisch-Samoa */
  AS = 'AS',
  /** Österreich */
  AT = 'AT',
  /** Australien */
  AU = 'AU',
  /** Aruba */
  AW = 'AW',
  /** Åland-Inseln */
  AX = 'AX',
  /** Aserbaidschan */
  AZ = 'AZ',
  /** Bosnien und Herzegowina */
  BA = 'BA',
  /** Barbados */
  BB = 'BB',
  /** Bangladesch */
  BD = 'BD',
  /** Belgien */
  BE = 'BE',
  /** Burkina Faso */
  BF = 'BF',
  /** Bulgarien */
  BG = 'BG',
  /** Bahrain */
  BH = 'BH',
  /** Burundi */
  BI = 'BI',
  /** Benin */
  BJ = 'BJ',
  /** Saint-Barthélemy */
  BL = 'BL',
  /** Bermuda */
  BM = 'BM',
  /** Brunei */
  BN = 'BN',
  /** Bolivien */
  BO = 'BO',
  /** Bonaire, Sint Eustatius und Saba */
  BQ = 'BQ',
  /** Brasilien */
  BR = 'BR',
  /** Bahamas */
  BS = 'BS',
  /** Bhutan */
  BT = 'BT',
  /** Bouvetinsel */
  BV = 'BV',
  /** Botswana */
  BW = 'BW',
  /** Weißrussland */
  BY = 'BY',
  /** Belize */
  BZ = 'BZ',
  /** Kanada */
  CA = 'CA',
  /** Kokosinseln (Keelinginseln) */
  CC = 'CC',
  /** Kongo (Demokratische Republik) */
  CD = 'CD',
  /** Zentralafrikanische Republik */
  CF = 'CF',
  /** Kongo */
  CG = 'CG',
  /** Schweiz */
  CH = 'CH',
  /** Côte d'Ivoire */
  CI = 'CI',
  /** Cookinseln */
  CK = 'CK',
  /** Chile */
  CL = 'CL',
  /** Kamerun */
  CM = 'CM',
  /** China */
  CN = 'CN',
  /** Kolumbien */
  CO = 'CO',
  /** Costa Rica */
  CR = 'CR',
  /** Kuba */
  CU = 'CU',
  /** Kap Verde */
  CV = 'CV',
  /** Curaçao */
  CW = 'CW',
  /** Weihnachtsinsel */
  CX = 'CX',
  /** Zypern */
  CY = 'CY',
  /** Tschechien */
  CZ = 'CZ',
  /** Deutschland */
  DE = 'DE',
  /** Dschibuti */
  DJ = 'DJ',
  /** Dänemark */
  DK = 'DK',
  /** Dominica */
  DM = 'DM',
  /** Dominikanische Republik */
  DO = 'DO',
  /** Algerien */
  DZ = 'DZ',
  /** Ecuador */
  EC = 'EC',
  /** Estland */
  EE = 'EE',
  /** Ägypten */
  EG = 'EG',
  /** Westsahara */
  EH = 'EH',
  /** Eritrea */
  ER = 'ER',
  /** Spanien */
  ES = 'ES',
  /** Äthiopien */
  ET = 'ET',
  /** Finnland */
  FI = 'FI',
  /** Fidschi */
  FJ = 'FJ',
  /** Falklandinseln (Malwinen) */
  FK = 'FK',
  /** Mikronesien (Föderierte Staaten von) */
  FM = 'FM',
  /** Faröerinseln */
  FO = 'FO',
  /** Frankreich */
  FR = 'FR',
  /** Gabun */
  GA = 'GA',
  /** Vereinigtes Königreich */
  GB = 'GB',
  /** Granada */
  GD = 'GD',
  /** Georgien */
  GE = 'GE',
  /** Französisch Guinea */
  GF = 'GF',
  /** Guernsey */
  GG = 'GG',
  /** Ghana */
  GH = 'GH',
  /** Gibraltar */
  GI = 'GI',
  /** Grönland */
  GL = 'GL',
  /** Gambia */
  GM = 'GM',
  /** Guinea */
  GN = 'GN',
  /** Guadeloupe */
  GP = 'GP',
  /** Äquatorialguinea */
  GQ = 'GQ',
  /** Griechenland */
  GR = 'GR',
  /** Südgeorgien und die Südlichen Sandwichinseln */
  GS = 'GS',
  /** Guatemala */
  GT = 'GT',
  /** Guam */
  GU = 'GU',
  /** Guinea-Bissau */
  GW = 'GW',
  /** Guyana */
  GY = 'GY',
  /** Hong Kong */
  HK = 'HK',
  /** Heard und McDonaldinseln */
  HM = 'HM',
  /** Honduras */
  HN = 'HN',
  /** Kroatien */
  HR = 'HR',
  /** Haiti */
  HT = 'HT',
  /** Ungarn */
  HU = 'HU',
  /** Indonesien */
  ID = 'ID',
  /** Irland */
  IE = 'IE',
  /** Israel */
  IL = 'IL',
  /** Isle of Man */
  IM = 'IM',
  /** Indien */
  IN = 'IN',
  /** Britisches Territorium im Indischen Ozean */
  IO = 'IO',
  /** Irak */
  IQ = 'IQ',
  /** Iran (Islamische Republik) */
  IR = 'IR',
  /** Island */
  IS = 'IS',
  /** Italien */
  IT = 'IT',
  /** Jersey */
  JE = 'JE',
  /** Jamaika */
  JM = 'JM',
  /** Jordanien */
  JO = 'JO',
  /** Japan */
  JP = 'JP',
  /** Kenia */
  KE = 'KE',
  /** Kirgisistan */
  KG = 'KG',
  /** Kambodscha */
  KH = 'KH',
  /** Kirivati */
  KI = 'KI',
  /** Komoren */
  KM = 'KM',
  /** St. Kitts und Nevis */
  KN = 'KN',
  /** Nordkorea */
  KP = 'KP',
  /** Südkorea */
  KR = 'KR',
  /** Kuwait */
  KW = 'KW',
  /** Kaimaninseln */
  KY = 'KY',
  /** Kasachstan */
  KZ = 'KZ',
  /** Laos, Demokratische Volksrepublik */
  LA = 'LA',
  /** Libanon */
  LB = 'LB',
  /** St. Lucia */
  LC = 'LC',
  /** Liechtenstein */
  LI = 'LI',
  /** Sri Lanka */
  LK = 'LK',
  /** Liberia */
  LR = 'LR',
  /** Lesotho */
  LS = 'LS',
  /** Litauen */
  LT = 'LT',
  /** Luxemburg */
  LU = 'LU',
  /** Lettland */
  LV = 'LV',
  /** Libyen */
  LY = 'LY',
  /** Marokko */
  MA = 'MA',
  /** Monaco */
  MC = 'MC',
  /** Moldawien */
  MD = 'MD',
  /** Montenegro */
  ME = 'ME',
  /** St. Martin (französischer Teil) */
  MF = 'MF',
  /** Madagaskar */
  MG = 'MG',
  /** Marshallinseln */
  MH = 'MH',
  /** Mazedonien */
  MK = 'MK',
  /** Mali */
  ML = 'ML',
  /** Myanmar */
  MM = 'MM',
  /** Mongolei */
  MN = 'MN',
  /** Macao */
  MO = 'MO',
  /** Commonwealth der Nördlichen Marianen */
  MP = 'MP',
  /** Martinique */
  MQ = 'MQ',
  /** Mauretanien */
  MR = 'MR',
  /** Montserrat */
  MS = 'MS',
  /** Malta */
  MT = 'MT',
  /** Mauritius */
  MU = 'MU',
  /** Malediven */
  MV = 'MV',
  /** Malawi */
  MW = 'MW',
  /** Mexiko */
  MX = 'MX',
  /** Malaysia */
  MY = 'MY',
  /** Mozambique */
  MZ = 'MZ',
  /** Namibia */
  NA = 'NA',
  /** Neukaledonien */
  NC = 'NC',
  /** Niger */
  NE = 'NE',
  /** Norfolkinsel */
  NF = 'NF',
  /** Nigeria */
  NG = 'NG',
  /** Nicaragua */
  NI = 'NI',
  /** Niederlande */
  NL = 'NL',
  /** Norwegen */
  NO = 'NO',
  /** Nepal */
  NP = 'NP',
  /** Nauru */
  NR = 'NR',
  /** Niue */
  NU = 'NU',
  /** Neuseeland */
  NZ = 'NZ',
  /** Oman */
  OM = 'OM',
  /** Panama */
  PA = 'PA',
  /** Peru */
  PE = 'PE',
  /** Französisch-Polynesien */
  PF = 'PF',
  /** Papua Neu Guinea */
  PG = 'PG',
  /** Philippinen */
  PH = 'PH',
  /** Pakistan */
  PK = 'PK',
  /** Polen */
  PL = 'PL',
  /** Saint-Pierre und Miquelon */
  PM = 'PM',
  /** Pitcairn */
  PN = 'PN',
  /** Puerto Rico */
  PR = 'PR',
  /** Palästina */
  PS = 'PS',
  /** Portugal */
  PT = 'PT',
  /** Palau */
  PW = 'PW',
  /** Paraguay */
  PY = 'PY',
  /** Katar */
  QA = 'QA',
  /** Réunion */
  RE = 'RE',
  /** Rumänien */
  RO = 'RO',
  /** Serbien */
  RS = 'RS',
  /** Russland */
  RU = 'RU',
  /** Ruanda */
  RW = 'RW',
  /** Saudi Arabien */
  SA = 'SA',
  /** Salomonen */
  SB = 'SB',
  /** Seychellen */
  SC = 'SC',
  /** Sudan */
  SD = 'SD',
  /** Schweden */
  SE = 'SE',
  /** Singapur */
  SG = 'SG',
  /** St. Helena, Ascension und Tristan da Cunha */
  SH = 'SH',
  /** Slowenien */
  SI = 'SI',
  /** Spitzbergen und Jan Mayen */
  SJ = 'SJ',
  /** Slowakei */
  SK = 'SK',
  /** Sierra Leone */
  SL = 'SL',
  /** San Marino */
  SM = 'SM',
  /** Senegal */
  SN = 'SN',
  /** Somalia */
  SO = 'SO',
  /** Surinam */
  SR = 'SR',
  /** Südsudan */
  SS = 'SS',
  /** São Tomé und Príncipe */
  ST = 'ST',
  /** El Salvador */
  SV = 'SV',
  /** Sint Maarten (niederländischer Teil) */
  SX = 'SX',
  /** Syrien */
  SY = 'SY',
  /** Swasiland */
  SZ = 'SZ',
  /** Turks- und Caicosinseln */
  TC = 'TC',
  /** Tschad */
  TD = 'TD',
  /** Französische Süd- und Antarktisgebiete */
  TF = 'TF',
  /** Togo */
  TG = 'TG',
  /** Thailand */
  TH = 'TH',
  /** Tadschikistan */
  TJ = 'TJ',
  /** Tokelau */
  TK = 'TK',
  /** Osttimor */
  TL = 'TL',
  /** Turkmenistan */
  TM = 'TM',
  /** Tunesien */
  TN = 'TN',
  /** Tonga */
  TO = 'TO',
  /** Türkei */
  TR = 'TR',
  /** Trinidad und Tobago */
  TT = 'TT',
  /** Tuvalu */
  TV = 'TV',
  /** Taiwan (Provinz Chinas) */
  TW = 'TW',
  /** Tansania */
  TZ = 'TZ',
  /** Ukraine */
  UA = 'UA',
  /** Uganda */
  UG = 'UG',
  /** USA - Sonstige Kleine Inseln */
  UM = 'UM',
  /** Vereinigte Staaten von Amerika */
  US = 'US',
  /** Uruguay */
  UY = 'UY',
  /** Usbekistan */
  UZ = 'UZ',
  /** Vatikanstadt */
  VA = 'VA',
  /** St. Vincent und die Grenadinen */
  VC = 'VC',
  /** Venezuela (Bolivarische Republik) */
  VE = 'VE',
  /** Britische Jungferninseln */
  VG = 'VG',
  /** Amerikanische Jungferninseln */
  VI = 'VI',
  /** Vietnam */
  VN = 'VN',
  /** Vanuatu */
  VU = 'VU',
  /** Wallis und Futuna */
  WF = 'WF',
  /** Samoa */
  WS = 'WS',
  /** Jemen */
  YE = 'YE',
  /** Mayotte */
  YT = 'YT',
  /** Südafrika */
  ZA = 'ZA',
  /** Sambia */
  ZM = 'ZM',
  /** Simbabwe */
  ZW = 'ZW',
}

/** An enumeration. */
export enum InstitutesInstituteCountryChoices {
  /** Andorra */
  AD = 'AD',
  /** Vereinigte Arabische Emirate */
  AE = 'AE',
  /** Afghanistan */
  AF = 'AF',
  /** Antigua und Barbuda */
  AG = 'AG',
  /** Anguilla */
  AI = 'AI',
  /** Albanien */
  AL = 'AL',
  /** Armenien */
  AM = 'AM',
  /** Angola */
  AO = 'AO',
  /** Antarktis */
  AQ = 'AQ',
  /** Argentinien */
  AR = 'AR',
  /** Amerikanisch-Samoa */
  AS = 'AS',
  /** Österreich */
  AT = 'AT',
  /** Australien */
  AU = 'AU',
  /** Aruba */
  AW = 'AW',
  /** Åland-Inseln */
  AX = 'AX',
  /** Aserbaidschan */
  AZ = 'AZ',
  /** Bosnien und Herzegowina */
  BA = 'BA',
  /** Barbados */
  BB = 'BB',
  /** Bangladesch */
  BD = 'BD',
  /** Belgien */
  BE = 'BE',
  /** Burkina Faso */
  BF = 'BF',
  /** Bulgarien */
  BG = 'BG',
  /** Bahrain */
  BH = 'BH',
  /** Burundi */
  BI = 'BI',
  /** Benin */
  BJ = 'BJ',
  /** Saint-Barthélemy */
  BL = 'BL',
  /** Bermuda */
  BM = 'BM',
  /** Brunei */
  BN = 'BN',
  /** Bolivien */
  BO = 'BO',
  /** Bonaire, Sint Eustatius und Saba */
  BQ = 'BQ',
  /** Brasilien */
  BR = 'BR',
  /** Bahamas */
  BS = 'BS',
  /** Bhutan */
  BT = 'BT',
  /** Bouvetinsel */
  BV = 'BV',
  /** Botswana */
  BW = 'BW',
  /** Weißrussland */
  BY = 'BY',
  /** Belize */
  BZ = 'BZ',
  /** Kanada */
  CA = 'CA',
  /** Kokosinseln (Keelinginseln) */
  CC = 'CC',
  /** Kongo (Demokratische Republik) */
  CD = 'CD',
  /** Zentralafrikanische Republik */
  CF = 'CF',
  /** Kongo */
  CG = 'CG',
  /** Schweiz */
  CH = 'CH',
  /** Côte d'Ivoire */
  CI = 'CI',
  /** Cookinseln */
  CK = 'CK',
  /** Chile */
  CL = 'CL',
  /** Kamerun */
  CM = 'CM',
  /** China */
  CN = 'CN',
  /** Kolumbien */
  CO = 'CO',
  /** Costa Rica */
  CR = 'CR',
  /** Kuba */
  CU = 'CU',
  /** Kap Verde */
  CV = 'CV',
  /** Curaçao */
  CW = 'CW',
  /** Weihnachtsinsel */
  CX = 'CX',
  /** Zypern */
  CY = 'CY',
  /** Tschechien */
  CZ = 'CZ',
  /** Deutschland */
  DE = 'DE',
  /** Dschibuti */
  DJ = 'DJ',
  /** Dänemark */
  DK = 'DK',
  /** Dominica */
  DM = 'DM',
  /** Dominikanische Republik */
  DO = 'DO',
  /** Algerien */
  DZ = 'DZ',
  /** Ecuador */
  EC = 'EC',
  /** Estland */
  EE = 'EE',
  /** Ägypten */
  EG = 'EG',
  /** Westsahara */
  EH = 'EH',
  /** Eritrea */
  ER = 'ER',
  /** Spanien */
  ES = 'ES',
  /** Äthiopien */
  ET = 'ET',
  /** Finnland */
  FI = 'FI',
  /** Fidschi */
  FJ = 'FJ',
  /** Falklandinseln (Malwinen) */
  FK = 'FK',
  /** Mikronesien (Föderierte Staaten von) */
  FM = 'FM',
  /** Faröerinseln */
  FO = 'FO',
  /** Frankreich */
  FR = 'FR',
  /** Gabun */
  GA = 'GA',
  /** Vereinigtes Königreich */
  GB = 'GB',
  /** Granada */
  GD = 'GD',
  /** Georgien */
  GE = 'GE',
  /** Französisch Guinea */
  GF = 'GF',
  /** Guernsey */
  GG = 'GG',
  /** Ghana */
  GH = 'GH',
  /** Gibraltar */
  GI = 'GI',
  /** Grönland */
  GL = 'GL',
  /** Gambia */
  GM = 'GM',
  /** Guinea */
  GN = 'GN',
  /** Guadeloupe */
  GP = 'GP',
  /** Äquatorialguinea */
  GQ = 'GQ',
  /** Griechenland */
  GR = 'GR',
  /** Südgeorgien und die Südlichen Sandwichinseln */
  GS = 'GS',
  /** Guatemala */
  GT = 'GT',
  /** Guam */
  GU = 'GU',
  /** Guinea-Bissau */
  GW = 'GW',
  /** Guyana */
  GY = 'GY',
  /** Hong Kong */
  HK = 'HK',
  /** Heard und McDonaldinseln */
  HM = 'HM',
  /** Honduras */
  HN = 'HN',
  /** Kroatien */
  HR = 'HR',
  /** Haiti */
  HT = 'HT',
  /** Ungarn */
  HU = 'HU',
  /** Indonesien */
  ID = 'ID',
  /** Irland */
  IE = 'IE',
  /** Israel */
  IL = 'IL',
  /** Isle of Man */
  IM = 'IM',
  /** Indien */
  IN = 'IN',
  /** Britisches Territorium im Indischen Ozean */
  IO = 'IO',
  /** Irak */
  IQ = 'IQ',
  /** Iran (Islamische Republik) */
  IR = 'IR',
  /** Island */
  IS = 'IS',
  /** Italien */
  IT = 'IT',
  /** Jersey */
  JE = 'JE',
  /** Jamaika */
  JM = 'JM',
  /** Jordanien */
  JO = 'JO',
  /** Japan */
  JP = 'JP',
  /** Kenia */
  KE = 'KE',
  /** Kirgisistan */
  KG = 'KG',
  /** Kambodscha */
  KH = 'KH',
  /** Kirivati */
  KI = 'KI',
  /** Komoren */
  KM = 'KM',
  /** St. Kitts und Nevis */
  KN = 'KN',
  /** Nordkorea */
  KP = 'KP',
  /** Südkorea */
  KR = 'KR',
  /** Kuwait */
  KW = 'KW',
  /** Kaimaninseln */
  KY = 'KY',
  /** Kasachstan */
  KZ = 'KZ',
  /** Laos, Demokratische Volksrepublik */
  LA = 'LA',
  /** Libanon */
  LB = 'LB',
  /** St. Lucia */
  LC = 'LC',
  /** Liechtenstein */
  LI = 'LI',
  /** Sri Lanka */
  LK = 'LK',
  /** Liberia */
  LR = 'LR',
  /** Lesotho */
  LS = 'LS',
  /** Litauen */
  LT = 'LT',
  /** Luxemburg */
  LU = 'LU',
  /** Lettland */
  LV = 'LV',
  /** Libyen */
  LY = 'LY',
  /** Marokko */
  MA = 'MA',
  /** Monaco */
  MC = 'MC',
  /** Moldawien */
  MD = 'MD',
  /** Montenegro */
  ME = 'ME',
  /** St. Martin (französischer Teil) */
  MF = 'MF',
  /** Madagaskar */
  MG = 'MG',
  /** Marshallinseln */
  MH = 'MH',
  /** Mazedonien */
  MK = 'MK',
  /** Mali */
  ML = 'ML',
  /** Myanmar */
  MM = 'MM',
  /** Mongolei */
  MN = 'MN',
  /** Macao */
  MO = 'MO',
  /** Commonwealth der Nördlichen Marianen */
  MP = 'MP',
  /** Martinique */
  MQ = 'MQ',
  /** Mauretanien */
  MR = 'MR',
  /** Montserrat */
  MS = 'MS',
  /** Malta */
  MT = 'MT',
  /** Mauritius */
  MU = 'MU',
  /** Malediven */
  MV = 'MV',
  /** Malawi */
  MW = 'MW',
  /** Mexiko */
  MX = 'MX',
  /** Malaysia */
  MY = 'MY',
  /** Mozambique */
  MZ = 'MZ',
  /** Namibia */
  NA = 'NA',
  /** Neukaledonien */
  NC = 'NC',
  /** Niger */
  NE = 'NE',
  /** Norfolkinsel */
  NF = 'NF',
  /** Nigeria */
  NG = 'NG',
  /** Nicaragua */
  NI = 'NI',
  /** Niederlande */
  NL = 'NL',
  /** Norwegen */
  NO = 'NO',
  /** Nepal */
  NP = 'NP',
  /** Nauru */
  NR = 'NR',
  /** Niue */
  NU = 'NU',
  /** Neuseeland */
  NZ = 'NZ',
  /** Oman */
  OM = 'OM',
  /** Panama */
  PA = 'PA',
  /** Peru */
  PE = 'PE',
  /** Französisch-Polynesien */
  PF = 'PF',
  /** Papua Neu Guinea */
  PG = 'PG',
  /** Philippinen */
  PH = 'PH',
  /** Pakistan */
  PK = 'PK',
  /** Polen */
  PL = 'PL',
  /** Saint-Pierre und Miquelon */
  PM = 'PM',
  /** Pitcairn */
  PN = 'PN',
  /** Puerto Rico */
  PR = 'PR',
  /** Palästina */
  PS = 'PS',
  /** Portugal */
  PT = 'PT',
  /** Palau */
  PW = 'PW',
  /** Paraguay */
  PY = 'PY',
  /** Katar */
  QA = 'QA',
  /** Réunion */
  RE = 'RE',
  /** Rumänien */
  RO = 'RO',
  /** Serbien */
  RS = 'RS',
  /** Russland */
  RU = 'RU',
  /** Ruanda */
  RW = 'RW',
  /** Saudi Arabien */
  SA = 'SA',
  /** Salomonen */
  SB = 'SB',
  /** Seychellen */
  SC = 'SC',
  /** Sudan */
  SD = 'SD',
  /** Schweden */
  SE = 'SE',
  /** Singapur */
  SG = 'SG',
  /** St. Helena, Ascension und Tristan da Cunha */
  SH = 'SH',
  /** Slowenien */
  SI = 'SI',
  /** Spitzbergen und Jan Mayen */
  SJ = 'SJ',
  /** Slowakei */
  SK = 'SK',
  /** Sierra Leone */
  SL = 'SL',
  /** San Marino */
  SM = 'SM',
  /** Senegal */
  SN = 'SN',
  /** Somalia */
  SO = 'SO',
  /** Surinam */
  SR = 'SR',
  /** Südsudan */
  SS = 'SS',
  /** São Tomé und Príncipe */
  ST = 'ST',
  /** El Salvador */
  SV = 'SV',
  /** Sint Maarten (niederländischer Teil) */
  SX = 'SX',
  /** Syrien */
  SY = 'SY',
  /** Swasiland */
  SZ = 'SZ',
  /** Turks- und Caicosinseln */
  TC = 'TC',
  /** Tschad */
  TD = 'TD',
  /** Französische Süd- und Antarktisgebiete */
  TF = 'TF',
  /** Togo */
  TG = 'TG',
  /** Thailand */
  TH = 'TH',
  /** Tadschikistan */
  TJ = 'TJ',
  /** Tokelau */
  TK = 'TK',
  /** Osttimor */
  TL = 'TL',
  /** Turkmenistan */
  TM = 'TM',
  /** Tunesien */
  TN = 'TN',
  /** Tonga */
  TO = 'TO',
  /** Türkei */
  TR = 'TR',
  /** Trinidad und Tobago */
  TT = 'TT',
  /** Tuvalu */
  TV = 'TV',
  /** Taiwan (Provinz Chinas) */
  TW = 'TW',
  /** Tansania */
  TZ = 'TZ',
  /** Ukraine */
  UA = 'UA',
  /** Uganda */
  UG = 'UG',
  /** USA - Sonstige Kleine Inseln */
  UM = 'UM',
  /** Vereinigte Staaten von Amerika */
  US = 'US',
  /** Uruguay */
  UY = 'UY',
  /** Usbekistan */
  UZ = 'UZ',
  /** Vatikanstadt */
  VA = 'VA',
  /** St. Vincent und die Grenadinen */
  VC = 'VC',
  /** Venezuela (Bolivarische Republik) */
  VE = 'VE',
  /** Britische Jungferninseln */
  VG = 'VG',
  /** Amerikanische Jungferninseln */
  VI = 'VI',
  /** Vietnam */
  VN = 'VN',
  /** Vanuatu */
  VU = 'VU',
  /** Wallis und Futuna */
  WF = 'WF',
  /** Samoa */
  WS = 'WS',
  /** Jemen */
  YE = 'YE',
  /** Mayotte */
  YT = 'YT',
  /** Südafrika */
  ZA = 'ZA',
  /** Sambia */
  ZM = 'ZM',
  /** Simbabwe */
  ZW = 'ZW',
}

/** An enumeration. */
export enum InstitutesInstituteRequestCountryChoices {
  /** Andorra */
  AD = 'AD',
  /** Vereinigte Arabische Emirate */
  AE = 'AE',
  /** Afghanistan */
  AF = 'AF',
  /** Antigua und Barbuda */
  AG = 'AG',
  /** Anguilla */
  AI = 'AI',
  /** Albanien */
  AL = 'AL',
  /** Armenien */
  AM = 'AM',
  /** Angola */
  AO = 'AO',
  /** Antarktis */
  AQ = 'AQ',
  /** Argentinien */
  AR = 'AR',
  /** Amerikanisch-Samoa */
  AS = 'AS',
  /** Österreich */
  AT = 'AT',
  /** Australien */
  AU = 'AU',
  /** Aruba */
  AW = 'AW',
  /** Åland-Inseln */
  AX = 'AX',
  /** Aserbaidschan */
  AZ = 'AZ',
  /** Bosnien und Herzegowina */
  BA = 'BA',
  /** Barbados */
  BB = 'BB',
  /** Bangladesch */
  BD = 'BD',
  /** Belgien */
  BE = 'BE',
  /** Burkina Faso */
  BF = 'BF',
  /** Bulgarien */
  BG = 'BG',
  /** Bahrain */
  BH = 'BH',
  /** Burundi */
  BI = 'BI',
  /** Benin */
  BJ = 'BJ',
  /** Saint-Barthélemy */
  BL = 'BL',
  /** Bermuda */
  BM = 'BM',
  /** Brunei */
  BN = 'BN',
  /** Bolivien */
  BO = 'BO',
  /** Bonaire, Sint Eustatius und Saba */
  BQ = 'BQ',
  /** Brasilien */
  BR = 'BR',
  /** Bahamas */
  BS = 'BS',
  /** Bhutan */
  BT = 'BT',
  /** Bouvetinsel */
  BV = 'BV',
  /** Botswana */
  BW = 'BW',
  /** Weißrussland */
  BY = 'BY',
  /** Belize */
  BZ = 'BZ',
  /** Kanada */
  CA = 'CA',
  /** Kokosinseln (Keelinginseln) */
  CC = 'CC',
  /** Kongo (Demokratische Republik) */
  CD = 'CD',
  /** Zentralafrikanische Republik */
  CF = 'CF',
  /** Kongo */
  CG = 'CG',
  /** Schweiz */
  CH = 'CH',
  /** Côte d'Ivoire */
  CI = 'CI',
  /** Cookinseln */
  CK = 'CK',
  /** Chile */
  CL = 'CL',
  /** Kamerun */
  CM = 'CM',
  /** China */
  CN = 'CN',
  /** Kolumbien */
  CO = 'CO',
  /** Costa Rica */
  CR = 'CR',
  /** Kuba */
  CU = 'CU',
  /** Kap Verde */
  CV = 'CV',
  /** Curaçao */
  CW = 'CW',
  /** Weihnachtsinsel */
  CX = 'CX',
  /** Zypern */
  CY = 'CY',
  /** Tschechien */
  CZ = 'CZ',
  /** Deutschland */
  DE = 'DE',
  /** Dschibuti */
  DJ = 'DJ',
  /** Dänemark */
  DK = 'DK',
  /** Dominica */
  DM = 'DM',
  /** Dominikanische Republik */
  DO = 'DO',
  /** Algerien */
  DZ = 'DZ',
  /** Ecuador */
  EC = 'EC',
  /** Estland */
  EE = 'EE',
  /** Ägypten */
  EG = 'EG',
  /** Westsahara */
  EH = 'EH',
  /** Eritrea */
  ER = 'ER',
  /** Spanien */
  ES = 'ES',
  /** Äthiopien */
  ET = 'ET',
  /** Finnland */
  FI = 'FI',
  /** Fidschi */
  FJ = 'FJ',
  /** Falklandinseln (Malwinen) */
  FK = 'FK',
  /** Mikronesien (Föderierte Staaten von) */
  FM = 'FM',
  /** Faröerinseln */
  FO = 'FO',
  /** Frankreich */
  FR = 'FR',
  /** Gabun */
  GA = 'GA',
  /** Vereinigtes Königreich */
  GB = 'GB',
  /** Granada */
  GD = 'GD',
  /** Georgien */
  GE = 'GE',
  /** Französisch Guinea */
  GF = 'GF',
  /** Guernsey */
  GG = 'GG',
  /** Ghana */
  GH = 'GH',
  /** Gibraltar */
  GI = 'GI',
  /** Grönland */
  GL = 'GL',
  /** Gambia */
  GM = 'GM',
  /** Guinea */
  GN = 'GN',
  /** Guadeloupe */
  GP = 'GP',
  /** Äquatorialguinea */
  GQ = 'GQ',
  /** Griechenland */
  GR = 'GR',
  /** Südgeorgien und die Südlichen Sandwichinseln */
  GS = 'GS',
  /** Guatemala */
  GT = 'GT',
  /** Guam */
  GU = 'GU',
  /** Guinea-Bissau */
  GW = 'GW',
  /** Guyana */
  GY = 'GY',
  /** Hong Kong */
  HK = 'HK',
  /** Heard und McDonaldinseln */
  HM = 'HM',
  /** Honduras */
  HN = 'HN',
  /** Kroatien */
  HR = 'HR',
  /** Haiti */
  HT = 'HT',
  /** Ungarn */
  HU = 'HU',
  /** Indonesien */
  ID = 'ID',
  /** Irland */
  IE = 'IE',
  /** Israel */
  IL = 'IL',
  /** Isle of Man */
  IM = 'IM',
  /** Indien */
  IN = 'IN',
  /** Britisches Territorium im Indischen Ozean */
  IO = 'IO',
  /** Irak */
  IQ = 'IQ',
  /** Iran (Islamische Republik) */
  IR = 'IR',
  /** Island */
  IS = 'IS',
  /** Italien */
  IT = 'IT',
  /** Jersey */
  JE = 'JE',
  /** Jamaika */
  JM = 'JM',
  /** Jordanien */
  JO = 'JO',
  /** Japan */
  JP = 'JP',
  /** Kenia */
  KE = 'KE',
  /** Kirgisistan */
  KG = 'KG',
  /** Kambodscha */
  KH = 'KH',
  /** Kirivati */
  KI = 'KI',
  /** Komoren */
  KM = 'KM',
  /** St. Kitts und Nevis */
  KN = 'KN',
  /** Nordkorea */
  KP = 'KP',
  /** Südkorea */
  KR = 'KR',
  /** Kuwait */
  KW = 'KW',
  /** Kaimaninseln */
  KY = 'KY',
  /** Kasachstan */
  KZ = 'KZ',
  /** Laos, Demokratische Volksrepublik */
  LA = 'LA',
  /** Libanon */
  LB = 'LB',
  /** St. Lucia */
  LC = 'LC',
  /** Liechtenstein */
  LI = 'LI',
  /** Sri Lanka */
  LK = 'LK',
  /** Liberia */
  LR = 'LR',
  /** Lesotho */
  LS = 'LS',
  /** Litauen */
  LT = 'LT',
  /** Luxemburg */
  LU = 'LU',
  /** Lettland */
  LV = 'LV',
  /** Libyen */
  LY = 'LY',
  /** Marokko */
  MA = 'MA',
  /** Monaco */
  MC = 'MC',
  /** Moldawien */
  MD = 'MD',
  /** Montenegro */
  ME = 'ME',
  /** St. Martin (französischer Teil) */
  MF = 'MF',
  /** Madagaskar */
  MG = 'MG',
  /** Marshallinseln */
  MH = 'MH',
  /** Mazedonien */
  MK = 'MK',
  /** Mali */
  ML = 'ML',
  /** Myanmar */
  MM = 'MM',
  /** Mongolei */
  MN = 'MN',
  /** Macao */
  MO = 'MO',
  /** Commonwealth der Nördlichen Marianen */
  MP = 'MP',
  /** Martinique */
  MQ = 'MQ',
  /** Mauretanien */
  MR = 'MR',
  /** Montserrat */
  MS = 'MS',
  /** Malta */
  MT = 'MT',
  /** Mauritius */
  MU = 'MU',
  /** Malediven */
  MV = 'MV',
  /** Malawi */
  MW = 'MW',
  /** Mexiko */
  MX = 'MX',
  /** Malaysia */
  MY = 'MY',
  /** Mozambique */
  MZ = 'MZ',
  /** Namibia */
  NA = 'NA',
  /** Neukaledonien */
  NC = 'NC',
  /** Niger */
  NE = 'NE',
  /** Norfolkinsel */
  NF = 'NF',
  /** Nigeria */
  NG = 'NG',
  /** Nicaragua */
  NI = 'NI',
  /** Niederlande */
  NL = 'NL',
  /** Norwegen */
  NO = 'NO',
  /** Nepal */
  NP = 'NP',
  /** Nauru */
  NR = 'NR',
  /** Niue */
  NU = 'NU',
  /** Neuseeland */
  NZ = 'NZ',
  /** Oman */
  OM = 'OM',
  /** Panama */
  PA = 'PA',
  /** Peru */
  PE = 'PE',
  /** Französisch-Polynesien */
  PF = 'PF',
  /** Papua Neu Guinea */
  PG = 'PG',
  /** Philippinen */
  PH = 'PH',
  /** Pakistan */
  PK = 'PK',
  /** Polen */
  PL = 'PL',
  /** Saint-Pierre und Miquelon */
  PM = 'PM',
  /** Pitcairn */
  PN = 'PN',
  /** Puerto Rico */
  PR = 'PR',
  /** Palästina */
  PS = 'PS',
  /** Portugal */
  PT = 'PT',
  /** Palau */
  PW = 'PW',
  /** Paraguay */
  PY = 'PY',
  /** Katar */
  QA = 'QA',
  /** Réunion */
  RE = 'RE',
  /** Rumänien */
  RO = 'RO',
  /** Serbien */
  RS = 'RS',
  /** Russland */
  RU = 'RU',
  /** Ruanda */
  RW = 'RW',
  /** Saudi Arabien */
  SA = 'SA',
  /** Salomonen */
  SB = 'SB',
  /** Seychellen */
  SC = 'SC',
  /** Sudan */
  SD = 'SD',
  /** Schweden */
  SE = 'SE',
  /** Singapur */
  SG = 'SG',
  /** St. Helena, Ascension und Tristan da Cunha */
  SH = 'SH',
  /** Slowenien */
  SI = 'SI',
  /** Spitzbergen und Jan Mayen */
  SJ = 'SJ',
  /** Slowakei */
  SK = 'SK',
  /** Sierra Leone */
  SL = 'SL',
  /** San Marino */
  SM = 'SM',
  /** Senegal */
  SN = 'SN',
  /** Somalia */
  SO = 'SO',
  /** Surinam */
  SR = 'SR',
  /** Südsudan */
  SS = 'SS',
  /** São Tomé und Príncipe */
  ST = 'ST',
  /** El Salvador */
  SV = 'SV',
  /** Sint Maarten (niederländischer Teil) */
  SX = 'SX',
  /** Syrien */
  SY = 'SY',
  /** Swasiland */
  SZ = 'SZ',
  /** Turks- und Caicosinseln */
  TC = 'TC',
  /** Tschad */
  TD = 'TD',
  /** Französische Süd- und Antarktisgebiete */
  TF = 'TF',
  /** Togo */
  TG = 'TG',
  /** Thailand */
  TH = 'TH',
  /** Tadschikistan */
  TJ = 'TJ',
  /** Tokelau */
  TK = 'TK',
  /** Osttimor */
  TL = 'TL',
  /** Turkmenistan */
  TM = 'TM',
  /** Tunesien */
  TN = 'TN',
  /** Tonga */
  TO = 'TO',
  /** Türkei */
  TR = 'TR',
  /** Trinidad und Tobago */
  TT = 'TT',
  /** Tuvalu */
  TV = 'TV',
  /** Taiwan (Provinz Chinas) */
  TW = 'TW',
  /** Tansania */
  TZ = 'TZ',
  /** Ukraine */
  UA = 'UA',
  /** Uganda */
  UG = 'UG',
  /** USA - Sonstige Kleine Inseln */
  UM = 'UM',
  /** Vereinigte Staaten von Amerika */
  US = 'US',
  /** Uruguay */
  UY = 'UY',
  /** Usbekistan */
  UZ = 'UZ',
  /** Vatikanstadt */
  VA = 'VA',
  /** St. Vincent und die Grenadinen */
  VC = 'VC',
  /** Venezuela (Bolivarische Republik) */
  VE = 'VE',
  /** Britische Jungferninseln */
  VG = 'VG',
  /** Amerikanische Jungferninseln */
  VI = 'VI',
  /** Vietnam */
  VN = 'VN',
  /** Vanuatu */
  VU = 'VU',
  /** Wallis und Futuna */
  WF = 'WF',
  /** Samoa */
  WS = 'WS',
  /** Jemen */
  YE = 'YE',
  /** Mayotte */
  YT = 'YT',
  /** Südafrika */
  ZA = 'ZA',
  /** Sambia */
  ZM = 'ZM',
  /** Simbabwe */
  ZW = 'ZW',
}

/** An enumeration. */
export enum InstitutesInstituteTypeCountryChoices {
  /** Andorra */
  AD = 'AD',
  /** Vereinigte Arabische Emirate */
  AE = 'AE',
  /** Afghanistan */
  AF = 'AF',
  /** Antigua und Barbuda */
  AG = 'AG',
  /** Anguilla */
  AI = 'AI',
  /** Albanien */
  AL = 'AL',
  /** Armenien */
  AM = 'AM',
  /** Angola */
  AO = 'AO',
  /** Antarktis */
  AQ = 'AQ',
  /** Argentinien */
  AR = 'AR',
  /** Amerikanisch-Samoa */
  AS = 'AS',
  /** Österreich */
  AT = 'AT',
  /** Australien */
  AU = 'AU',
  /** Aruba */
  AW = 'AW',
  /** Åland-Inseln */
  AX = 'AX',
  /** Aserbaidschan */
  AZ = 'AZ',
  /** Bosnien und Herzegowina */
  BA = 'BA',
  /** Barbados */
  BB = 'BB',
  /** Bangladesch */
  BD = 'BD',
  /** Belgien */
  BE = 'BE',
  /** Burkina Faso */
  BF = 'BF',
  /** Bulgarien */
  BG = 'BG',
  /** Bahrain */
  BH = 'BH',
  /** Burundi */
  BI = 'BI',
  /** Benin */
  BJ = 'BJ',
  /** Saint-Barthélemy */
  BL = 'BL',
  /** Bermuda */
  BM = 'BM',
  /** Brunei */
  BN = 'BN',
  /** Bolivien */
  BO = 'BO',
  /** Bonaire, Sint Eustatius und Saba */
  BQ = 'BQ',
  /** Brasilien */
  BR = 'BR',
  /** Bahamas */
  BS = 'BS',
  /** Bhutan */
  BT = 'BT',
  /** Bouvetinsel */
  BV = 'BV',
  /** Botswana */
  BW = 'BW',
  /** Weißrussland */
  BY = 'BY',
  /** Belize */
  BZ = 'BZ',
  /** Kanada */
  CA = 'CA',
  /** Kokosinseln (Keelinginseln) */
  CC = 'CC',
  /** Kongo (Demokratische Republik) */
  CD = 'CD',
  /** Zentralafrikanische Republik */
  CF = 'CF',
  /** Kongo */
  CG = 'CG',
  /** Schweiz */
  CH = 'CH',
  /** Côte d'Ivoire */
  CI = 'CI',
  /** Cookinseln */
  CK = 'CK',
  /** Chile */
  CL = 'CL',
  /** Kamerun */
  CM = 'CM',
  /** China */
  CN = 'CN',
  /** Kolumbien */
  CO = 'CO',
  /** Costa Rica */
  CR = 'CR',
  /** Kuba */
  CU = 'CU',
  /** Kap Verde */
  CV = 'CV',
  /** Curaçao */
  CW = 'CW',
  /** Weihnachtsinsel */
  CX = 'CX',
  /** Zypern */
  CY = 'CY',
  /** Tschechien */
  CZ = 'CZ',
  /** Deutschland */
  DE = 'DE',
  /** Dschibuti */
  DJ = 'DJ',
  /** Dänemark */
  DK = 'DK',
  /** Dominica */
  DM = 'DM',
  /** Dominikanische Republik */
  DO = 'DO',
  /** Algerien */
  DZ = 'DZ',
  /** Ecuador */
  EC = 'EC',
  /** Estland */
  EE = 'EE',
  /** Ägypten */
  EG = 'EG',
  /** Westsahara */
  EH = 'EH',
  /** Eritrea */
  ER = 'ER',
  /** Spanien */
  ES = 'ES',
  /** Äthiopien */
  ET = 'ET',
  /** Finnland */
  FI = 'FI',
  /** Fidschi */
  FJ = 'FJ',
  /** Falklandinseln (Malwinen) */
  FK = 'FK',
  /** Mikronesien (Föderierte Staaten von) */
  FM = 'FM',
  /** Faröerinseln */
  FO = 'FO',
  /** Frankreich */
  FR = 'FR',
  /** Gabun */
  GA = 'GA',
  /** Vereinigtes Königreich */
  GB = 'GB',
  /** Granada */
  GD = 'GD',
  /** Georgien */
  GE = 'GE',
  /** Französisch Guinea */
  GF = 'GF',
  /** Guernsey */
  GG = 'GG',
  /** Ghana */
  GH = 'GH',
  /** Gibraltar */
  GI = 'GI',
  /** Grönland */
  GL = 'GL',
  /** Gambia */
  GM = 'GM',
  /** Guinea */
  GN = 'GN',
  /** Guadeloupe */
  GP = 'GP',
  /** Äquatorialguinea */
  GQ = 'GQ',
  /** Griechenland */
  GR = 'GR',
  /** Südgeorgien und die Südlichen Sandwichinseln */
  GS = 'GS',
  /** Guatemala */
  GT = 'GT',
  /** Guam */
  GU = 'GU',
  /** Guinea-Bissau */
  GW = 'GW',
  /** Guyana */
  GY = 'GY',
  /** Hong Kong */
  HK = 'HK',
  /** Heard und McDonaldinseln */
  HM = 'HM',
  /** Honduras */
  HN = 'HN',
  /** Kroatien */
  HR = 'HR',
  /** Haiti */
  HT = 'HT',
  /** Ungarn */
  HU = 'HU',
  /** Indonesien */
  ID = 'ID',
  /** Irland */
  IE = 'IE',
  /** Israel */
  IL = 'IL',
  /** Isle of Man */
  IM = 'IM',
  /** Indien */
  IN = 'IN',
  /** Britisches Territorium im Indischen Ozean */
  IO = 'IO',
  /** Irak */
  IQ = 'IQ',
  /** Iran (Islamische Republik) */
  IR = 'IR',
  /** Island */
  IS = 'IS',
  /** Italien */
  IT = 'IT',
  /** Jersey */
  JE = 'JE',
  /** Jamaika */
  JM = 'JM',
  /** Jordanien */
  JO = 'JO',
  /** Japan */
  JP = 'JP',
  /** Kenia */
  KE = 'KE',
  /** Kirgisistan */
  KG = 'KG',
  /** Kambodscha */
  KH = 'KH',
  /** Kirivati */
  KI = 'KI',
  /** Komoren */
  KM = 'KM',
  /** St. Kitts und Nevis */
  KN = 'KN',
  /** Nordkorea */
  KP = 'KP',
  /** Südkorea */
  KR = 'KR',
  /** Kuwait */
  KW = 'KW',
  /** Kaimaninseln */
  KY = 'KY',
  /** Kasachstan */
  KZ = 'KZ',
  /** Laos, Demokratische Volksrepublik */
  LA = 'LA',
  /** Libanon */
  LB = 'LB',
  /** St. Lucia */
  LC = 'LC',
  /** Liechtenstein */
  LI = 'LI',
  /** Sri Lanka */
  LK = 'LK',
  /** Liberia */
  LR = 'LR',
  /** Lesotho */
  LS = 'LS',
  /** Litauen */
  LT = 'LT',
  /** Luxemburg */
  LU = 'LU',
  /** Lettland */
  LV = 'LV',
  /** Libyen */
  LY = 'LY',
  /** Marokko */
  MA = 'MA',
  /** Monaco */
  MC = 'MC',
  /** Moldawien */
  MD = 'MD',
  /** Montenegro */
  ME = 'ME',
  /** St. Martin (französischer Teil) */
  MF = 'MF',
  /** Madagaskar */
  MG = 'MG',
  /** Marshallinseln */
  MH = 'MH',
  /** Mazedonien */
  MK = 'MK',
  /** Mali */
  ML = 'ML',
  /** Myanmar */
  MM = 'MM',
  /** Mongolei */
  MN = 'MN',
  /** Macao */
  MO = 'MO',
  /** Commonwealth der Nördlichen Marianen */
  MP = 'MP',
  /** Martinique */
  MQ = 'MQ',
  /** Mauretanien */
  MR = 'MR',
  /** Montserrat */
  MS = 'MS',
  /** Malta */
  MT = 'MT',
  /** Mauritius */
  MU = 'MU',
  /** Malediven */
  MV = 'MV',
  /** Malawi */
  MW = 'MW',
  /** Mexiko */
  MX = 'MX',
  /** Malaysia */
  MY = 'MY',
  /** Mozambique */
  MZ = 'MZ',
  /** Namibia */
  NA = 'NA',
  /** Neukaledonien */
  NC = 'NC',
  /** Niger */
  NE = 'NE',
  /** Norfolkinsel */
  NF = 'NF',
  /** Nigeria */
  NG = 'NG',
  /** Nicaragua */
  NI = 'NI',
  /** Niederlande */
  NL = 'NL',
  /** Norwegen */
  NO = 'NO',
  /** Nepal */
  NP = 'NP',
  /** Nauru */
  NR = 'NR',
  /** Niue */
  NU = 'NU',
  /** Neuseeland */
  NZ = 'NZ',
  /** Oman */
  OM = 'OM',
  /** Panama */
  PA = 'PA',
  /** Peru */
  PE = 'PE',
  /** Französisch-Polynesien */
  PF = 'PF',
  /** Papua Neu Guinea */
  PG = 'PG',
  /** Philippinen */
  PH = 'PH',
  /** Pakistan */
  PK = 'PK',
  /** Polen */
  PL = 'PL',
  /** Saint-Pierre und Miquelon */
  PM = 'PM',
  /** Pitcairn */
  PN = 'PN',
  /** Puerto Rico */
  PR = 'PR',
  /** Palästina */
  PS = 'PS',
  /** Portugal */
  PT = 'PT',
  /** Palau */
  PW = 'PW',
  /** Paraguay */
  PY = 'PY',
  /** Katar */
  QA = 'QA',
  /** Réunion */
  RE = 'RE',
  /** Rumänien */
  RO = 'RO',
  /** Serbien */
  RS = 'RS',
  /** Russland */
  RU = 'RU',
  /** Ruanda */
  RW = 'RW',
  /** Saudi Arabien */
  SA = 'SA',
  /** Salomonen */
  SB = 'SB',
  /** Seychellen */
  SC = 'SC',
  /** Sudan */
  SD = 'SD',
  /** Schweden */
  SE = 'SE',
  /** Singapur */
  SG = 'SG',
  /** St. Helena, Ascension und Tristan da Cunha */
  SH = 'SH',
  /** Slowenien */
  SI = 'SI',
  /** Spitzbergen und Jan Mayen */
  SJ = 'SJ',
  /** Slowakei */
  SK = 'SK',
  /** Sierra Leone */
  SL = 'SL',
  /** San Marino */
  SM = 'SM',
  /** Senegal */
  SN = 'SN',
  /** Somalia */
  SO = 'SO',
  /** Surinam */
  SR = 'SR',
  /** Südsudan */
  SS = 'SS',
  /** São Tomé und Príncipe */
  ST = 'ST',
  /** El Salvador */
  SV = 'SV',
  /** Sint Maarten (niederländischer Teil) */
  SX = 'SX',
  /** Syrien */
  SY = 'SY',
  /** Swasiland */
  SZ = 'SZ',
  /** Turks- und Caicosinseln */
  TC = 'TC',
  /** Tschad */
  TD = 'TD',
  /** Französische Süd- und Antarktisgebiete */
  TF = 'TF',
  /** Togo */
  TG = 'TG',
  /** Thailand */
  TH = 'TH',
  /** Tadschikistan */
  TJ = 'TJ',
  /** Tokelau */
  TK = 'TK',
  /** Osttimor */
  TL = 'TL',
  /** Turkmenistan */
  TM = 'TM',
  /** Tunesien */
  TN = 'TN',
  /** Tonga */
  TO = 'TO',
  /** Türkei */
  TR = 'TR',
  /** Trinidad und Tobago */
  TT = 'TT',
  /** Tuvalu */
  TV = 'TV',
  /** Taiwan (Provinz Chinas) */
  TW = 'TW',
  /** Tansania */
  TZ = 'TZ',
  /** Ukraine */
  UA = 'UA',
  /** Uganda */
  UG = 'UG',
  /** USA - Sonstige Kleine Inseln */
  UM = 'UM',
  /** Vereinigte Staaten von Amerika */
  US = 'US',
  /** Uruguay */
  UY = 'UY',
  /** Usbekistan */
  UZ = 'UZ',
  /** Vatikanstadt */
  VA = 'VA',
  /** St. Vincent und die Grenadinen */
  VC = 'VC',
  /** Venezuela (Bolivarische Republik) */
  VE = 'VE',
  /** Britische Jungferninseln */
  VG = 'VG',
  /** Amerikanische Jungferninseln */
  VI = 'VI',
  /** Vietnam */
  VN = 'VN',
  /** Vanuatu */
  VU = 'VU',
  /** Wallis und Futuna */
  WF = 'WF',
  /** Samoa */
  WS = 'WS',
  /** Jemen */
  YE = 'YE',
  /** Mayotte */
  YT = 'YT',
  /** Südafrika */
  ZA = 'ZA',
  /** Sambia */
  ZM = 'ZM',
  /** Simbabwe */
  ZW = 'ZW',
}

export type JoinEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
};

export type JoinEventPayload = {
  __typename: 'JoinEventPayload';
  attendee: Maybe<EventAttendeeNode>;
  clientMutationId: Maybe<Scalars['String']['output']>;
};

export type KPIsNode = {
  __typename: 'KPIsNode';
  albumsPerGroupPerYear: Maybe<Array<Maybe<YearlyAverage>>>;
  averageStudentsPerGroup: Maybe<Scalars['Float']['output']>;
  groupTimeToActive: Maybe<Scalars['Float']['output']>;
  photosPerGroupPerYear: Maybe<Array<Maybe<YearlyAverage>>>;
  profileQuestionsPerYear: Maybe<Array<Maybe<YearlyAverage>>>;
  quotesPerGroupPerYear: Maybe<Array<Maybe<YearlyAverage>>>;
  rankingQuestionsPerGroupPerYear: Maybe<Array<Maybe<YearlyAverage>>>;
};

/** An enumeration. */
export enum Language {
  ENGLISH = 'ENGLISH',
  GERMAN = 'GERMAN',
  TURKISH = 'TURKISH',
}

export type LeaderboardUserNode = Node & {
  __typename: 'LeaderboardUserNode';
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  points: Scalars['Int']['output'];
  position: Maybe<Scalars['Int']['output']>;
  user: UserNode;
};

export type LeaderboardUserNodeConnection = {
  __typename: 'LeaderboardUserNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LeaderboardUserNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `LeaderboardUserNode` and its cursor. */
export type LeaderboardUserNodeEdge = {
  __typename: 'LeaderboardUserNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<LeaderboardUserNode>;
};

export type LeaveEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
};

export type LeaveEventPayload = {
  __typename: 'LeaveEventPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type LeaveFromTeamInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['ID']['input'];
};

export type LeaveFromTeamPayload = {
  __typename: 'LeaveFromTeamPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
  userTeam: Maybe<UserTeamNode>;
};

export type LikeCollagesPhotoInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type LikeCollagesPhotoPayload = {
  __typename: 'LikeCollagesPhotoPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  collagesPhoto: Maybe<CollagesPhotoNode>;
};

export type LikeCommentInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type LikeCommentPayload = {
  __typename: 'LikeCommentPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  post: Maybe<PostNode>;
};

export type LikeNode = Node & {
  __typename: 'LikeNode';
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  post: Maybe<PostNode>;
  userGroup: Maybe<UserGroupNode>;
};

export type LikeNodeConnection = {
  __typename: 'LikeNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LikeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `LikeNode` and its cursor. */
export type LikeNodeEdge = {
  __typename: 'LikeNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<LikeNode>;
};

export type LikePostInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type LikePostPayload = {
  __typename: 'LikePostPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  post: Maybe<PostNode>;
};

export type LikeQuotesInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type LikeQuotesInstancePayload = {
  __typename: 'LikeQuotesInstancePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  quotesInstance: Maybe<QuotesInstanceNode>;
};

export type MarketProfileNode = Node & {
  __typename: 'MarketProfileNode';
  created: Scalars['DateTime']['output'];
  groups: Maybe<Array<Maybe<GroupNode>>>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  institutes: Maybe<Array<Maybe<InstituteNode>>>;
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
};

export type MarketProfileNodeConnection = {
  __typename: 'MarketProfileNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MarketProfileNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `MarketProfileNode` and its cursor. */
export type MarketProfileNodeEdge = {
  __typename: 'MarketProfileNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<MarketProfileNode>;
};

export type MatchedAssetNode = Node & {
  __typename: 'MatchedAssetNode';
  assetId: Scalars['Int']['output'];
  campaign: Maybe<CampaignNode>;
  channelData: Maybe<Array<Maybe<BannerAd>>>;
  channelType: AdvertisementsAssetChannelTypeChoices;
  endDate: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  startDate: Maybe<Scalars['DateTime']['output']>;
  targetingConfig: Scalars['JSONString']['output'];
  targetingFilters: Maybe<Array<Maybe<TargetingFilterType>>>;
  visibility: Maybe<AssetVisibilityType>;
};

export type ModuleInstanceNode = Node & {
  __typename: 'ModuleInstanceNode';
  collagesAlbums: Maybe<CollagesAlbumNodeConnection>;
  collagesSetup: Maybe<CollagesSetupNode>;
  created: Scalars['DateTime']['output'];
  customLists: Maybe<CustomListNodeConnection>;
  customPagesInstances: Maybe<CustomPagesInstanceNodeConnection>;
  customPagesSetup: Maybe<CustomPagesSetupNode>;
  dueDate: Maybe<Scalars['DateTime']['output']>;
  group: GroupNode;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  modified: Scalars['DateTime']['output'];
  module: ModuleNode;
  prewordInstance: Maybe<PrewordInstanceNode>;
  profilePageComments: Maybe<ProfilePageCommentNodeConnection>;
  profilePagePhotoCategories: Maybe<ProfilePagePhotoCategoryNodeConnection>;
  profilePageQuestions: Maybe<ProfilePageQuestionNodeConnection>;
  profilePageSetup: Maybe<ProfilePageSetupNode>;
  quotesInstances: Maybe<QuotesInstanceNodeConnection>;
  quotesSetup: Maybe<QuotesSetupNode>;
  rankingsQuestions: Maybe<RankingsQuestionNodeConnection>;
  rankingsSetup: Maybe<RankingsSetupNode>;
  reportsInstances: Maybe<ReportsInstanceNodeConnection>;
  reportsSetup: Maybe<ReportsSetupNode>;
};

export type ModuleInstanceNodecollagesAlbumsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ModuleInstanceNodecustomListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ModuleInstanceNodecustomPagesInstancesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ModuleInstanceNodeprofilePageCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  comentatorUserGroup?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  moduleInstance?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  profileUserGroup?: InputMaybe<Scalars['ID']['input']>;
};

export type ModuleInstanceNodeprofilePagePhotoCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ModuleInstanceNodeprofilePageQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ModuleInstanceNodequotesInstancesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ModuleInstanceNoderankingsQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ModuleInstanceNodereportsInstancesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ModuleInstanceNodeConnection = {
  __typename: 'ModuleInstanceNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ModuleInstanceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ModuleInstanceNode` and its cursor. */
export type ModuleInstanceNodeEdge = {
  __typename: 'ModuleInstanceNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<ModuleInstanceNode>;
};

export type ModuleNode = Node & {
  __typename: 'ModuleNode';
  created: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  features: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  image: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  modified: Scalars['DateTime']['output'];
  name: Maybe<Scalars['String']['output']>;
  type: Maybe<ModuleType>;
};

export type ModuleNodeConnection = {
  __typename: 'ModuleNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ModuleNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ModuleNode` and its cursor. */
export type ModuleNodeEdge = {
  __typename: 'ModuleNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<ModuleNode>;
};

/** An enumeration. */
export enum ModuleType {
  COLLAGES = 'COLLAGES',
  CUSTOM_PAGES = 'CUSTOM_PAGES',
  FOREWORD = 'FOREWORD',
  PREWORD = 'PREWORD',
  PROFILE_PAGE = 'PROFILE_PAGE',
  QUOTES = 'QUOTES',
  RANKINGS = 'RANKINGS',
  REPORTS = 'REPORTS',
}

export type MoveGroupToInstituteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['ID']['input'];
  instituteId: Scalars['ID']['input'];
};

export type MoveGroupToInstitutePayload = {
  __typename: 'MoveGroupToInstitutePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  group: Maybe<GroupNode>;
};

export type MoveProfilePageQuestionToPositionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  newPosition: Scalars['Int']['input'];
};

export type MoveProfilePageQuestionToPositionPayload = {
  __typename: 'MoveProfilePageQuestionToPositionPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  profilePageQuestion: Maybe<ProfilePageQuestionNode>;
};

export type Mutation = {
  __typename: 'Mutation';
  acceptUserInGroup: Maybe<AcceptUserInGroupPayload>;
  acceptUserInTeam: Maybe<AcceptUserInTeamPayload>;
  activateModuleInstance: Maybe<ActivateModuleInstancePayload>;
  activateTeam: Maybe<ActivateTeamPayload>;
  addImagesToPost: Maybe<AddImagesToPostPayload>;
  addPhotoToCollagesAlbum: Maybe<AddPhotoToCollagesAlbumPayload>;
  addPhotosToCollagesAlbum: Maybe<AddPhotosToCollagesAlbumPayload>;
  addUserToGroup: Maybe<AddUserToGroupPayload>;
  addUserToTeam: Maybe<AddUserToTeamPayload>;
  adminAcceptInstituteRequest: Maybe<AdminAcceptInstituteRequestPayload>;
  adminAddUserToTeam: Maybe<AdminAddUserToTeamPayload>;
  adminClearUserFlags: Maybe<AdminClearUserFlagsPayload>;
  adminDeleteInstituteRequest: Maybe<AdminDeleteInstituteRequestPayload>;
  adminDeleteUser: Maybe<AdminDeleteUserPayload>;
  adminDowngradeUser: Maybe<AdminDowngradeUserPayload>;
  adminForgetPasswordLink: Maybe<AdminForgetPasswordLinkPayload>;
  adminRejectInstituteRequest: Maybe<AdminRejectInstituteRequestPayload>;
  adminSetBrandLook: Maybe<AdminSetBrandLookPayload>;
  adminUpdateInstitute: Maybe<AdminUpdateInstitutePayload>;
  adminUpdateUser: Maybe<AdminUpdateUserPayload>;
  /**
   * This mutation upgrades normal user to agent, or agent to super user. If you want to immediately upgrade normal
   * user to superuser, you need to provide to_superuser=True. In any case, if user is already superuser, an error is returned
   * that user is already a superuser.
   */
  adminUpgradeUser: Maybe<AdminUpgradeUserPayload>;
  blockUser: Maybe<BlockUserMutationPayload>;
  commentPost: Maybe<CommentPostPayload>;
  createAssetEvent: Maybe<CreateAssetEventPayload>;
  createBannerAsset: Maybe<CreateBannerAssetPayload>;
  createCampaign: Maybe<CreateCampaignPayload>;
  createCollagesAlbum: Maybe<CreateCollagesAlbumPayload>;
  createCustomList: Maybe<CreateCustomListPayload>;
  createCustomListOption: Maybe<CreateCustomListOptionPayload>;
  createCustomPagesInstance: Maybe<CreateCustomPagesInstancePayload>;
  createDataExport: Maybe<CreateDataExportPayload>;
  createDataPolicyAcceptance: Maybe<CreateDataPolicyAcceptancePayload>;
  createEvent: Maybe<CreateEventPayload>;
  createGroup: Maybe<CreateGroupPayload>;
  createInstitute: Maybe<CreateInstitutePayload>;
  createModuleInstance: Maybe<CreateModuleInstancePayload>;
  createPoll: Maybe<CreatePollPayload>;
  createPollOption: Maybe<CreatePollOptionPayload>;
  createPollVote: Maybe<CreatePollVotePayload>;
  createPost: Maybe<CreatePostPayload>;
  createProfilePageAnswer: Maybe<CreateProfilePageAnswerPayload>;
  createProfilePageComment: Maybe<CreateProfilePageCommentPayload>;
  createProfilePagePhoto: Maybe<CreateProfilePagePhotoPayload>;
  createProfilePagePhotoCategory: Maybe<CreateProfilePagePhotoCategoryPayload>;
  createProfilePageQuestion: Maybe<CreateProfilePageQuestionPayload>;
  createProfilePageQuestionOption: Maybe<CreateProfilePageQuestionOptionPayload>;
  createQuotesInstance: Maybe<CreateQuotesInstancePayload>;
  createRankingsAnswer: Maybe<CreateRankingsAnswerPayload>;
  createRankingsQuestion: Maybe<CreateRankingsQuestionPayload>;
  createReportsInstance: Maybe<CreateReportsInstancePayload>;
  createTeam: Maybe<CreateTeamPayload>;
  createTermsAcceptance: Maybe<CreateTermsAcceptancePayload>;
  createToken: Maybe<ObtainJSONWebTokenPayload>;
  createUser: Maybe<CreateUserPayload>;
  deactivateModuleInstance: Maybe<DeactivateModuleInstancePayload>;
  deleteAsset: Maybe<DeleteAssetPayload>;
  deleteCampaign: Maybe<DeleteCampaignPayload>;
  deleteComment: Maybe<DeleteCommentPayload>;
  deleteCustomList: Maybe<DeleteCustomListPayload>;
  deleteCustomListOption: Maybe<DeleteCustomListOptionPayload>;
  deleteEvent: Maybe<DeleteEventPayload>;
  deletePoll: Maybe<DeletePollPayload>;
  deletePollOption: Maybe<DeletePollOptionPayload>;
  deletePollVote: Maybe<DeletePollVotePayload>;
  deletePost: Maybe<DeletePostPayload>;
  deleteUser: Maybe<DeleteUserPayload>;
  destroyCollagesAlbum: Maybe<DestroyCollagesAlbumPayload>;
  destroyCustomPagesInstance: Maybe<DestroyCustomPagesInstancePayload>;
  destroyGroup: Maybe<DestroyGroupPayload>;
  destroyModuleInstance: Maybe<DestroyModuleInstancePayload>;
  destroyProfilePageAnswer: Maybe<DestroyProfilePageAnswerPayload>;
  destroyProfilePageComment: Maybe<DestroyProfilePageCommentPayload>;
  destroyProfilePagePhoto: Maybe<DestroyProfilePagePhotoPayload>;
  destroyProfilePagePhotoCategory: Maybe<DestroyProfilePagePhotoCategoryPayload>;
  destroyProfilePageQuestion: Maybe<DestroyProfilePageQuestionPayload>;
  destroyProfilePageQuestionOption: Maybe<DestroyProfilePageQuestionOptionPayload>;
  destroyQuotesInstance: Maybe<DestroyQuotesInstancePayload>;
  destroyRankingsAnswer: Maybe<DestroyRankingsAnswerPayload>;
  destroyRankingsQuestion: Maybe<DestroyRankingsQuestionPayload>;
  destroyReportsInstance: Maybe<DestroyReportsInstancePayload>;
  destroyTeam: Maybe<DestroyTeamPayload>;
  duplicateAsset: Maybe<DuplicateAssetPayload>;
  editBannerAdAsset: Maybe<EditBannerAdAssetPayload>;
  editCampaign: Maybe<EditCampaignPayload>;
  editComment: Maybe<EditCommentPayload>;
  editEvent: Maybe<EditEventPayload>;
  forgetPassword: Maybe<ForgetPasswordPayload>;
  generateShopifyUrl: Maybe<GenerateShopifyRedirectUrlPayload>;
  importAbihome: Maybe<Importer>;
  importToErp: Maybe<ImportToErpPayload>;
  joinEvent: Maybe<JoinEventPayload>;
  leaveEvent: Maybe<LeaveEventPayload>;
  leaveFromTeam: Maybe<LeaveFromTeamPayload>;
  likeCollagesPhoto: Maybe<LikeCollagesPhotoPayload>;
  likeComment: Maybe<LikeCommentPayload>;
  likePost: Maybe<LikePostPayload>;
  likeQuotesInstance: Maybe<LikeQuotesInstancePayload>;
  moveGroupToInstitute: Maybe<MoveGroupToInstitutePayload>;
  moveProfilePageQuestionToPosition: Maybe<MoveProfilePageQuestionToPositionPayload>;
  refreshGroupToken: Maybe<RefreshGroupTokenPayload>;
  refreshToken: Maybe<RefreshPayload>;
  removeExternalEvent: Maybe<RemoveExternalEventPayload>;
  removeImageFromPost: Maybe<RemoveImageFromPostPayload>;
  removeLikeFromCollagesPhoto: Maybe<RemoveLikeFromCollagesPhotoPayload>;
  removeLikeFromComment: Maybe<RemoveLikeFromCommentPayload>;
  removeLikeFromPost: Maybe<RemoveLikeFromPostPayload>;
  removeLikeFromQuotesInstance: Maybe<RemoveLikeFromQuotesInstancePayload>;
  removePhotoFromCollagesAlbum: Maybe<RemovePhotoFromCollagesAlbumPayload>;
  removeUserFromGroup: Maybe<RemoveUserFromGroupPayload>;
  removeUserFromTeam: Maybe<RemoveUserFromTeamPayload>;
  reportMutation: Maybe<ReportMutationPayload>;
  reportPostContent: Maybe<ReportPostContentPayload>;
  requestMissingInstitute: Maybe<RequestMissingInstitutePayload>;
  resetPassword: Maybe<ResetPasswordPayload>;
  revokeToken: Maybe<RevokePayload>;
  saveExternalEvent: Maybe<SaveExternalEventPayload>;
  setBrandLook: Maybe<SetBrandLookPayload>;
  setEventBanner: Maybe<SetEventBannerPayload>;
  setGroupAvatar: Maybe<SetGroupAvatarPayload>;
  setUserGroupAvatar: Maybe<SetUserGroupAvatarPayload>;
  toggleExternalEventSave: Maybe<ToggleExternalEventSavePayload>;
  toggleIsActiveGroup: Maybe<ToggleIsActiveGroupPayload>;
  tokenAuth: Maybe<ObtainJSONWebTokenPayload>;
  updateCollagesAlbum: Maybe<UpdateCollagesAlbumPayload>;
  updateCollagesSetup: Maybe<UpdateCollagesSetupPayload>;
  updateCustomList: Maybe<UpdateCustomListPayload>;
  updateCustomListOption: Maybe<UpdateCustomListOptionPayload>;
  updateCustomPagesInstance: Maybe<UpdateCustomPagesInstancePayload>;
  updateCustomPagesSetup: Maybe<UpdateCustomPagesSetupPayload>;
  updateGroup: Maybe<UpdateGroupPayload>;
  updateMe: Maybe<UpdateMePayload>;
  updateModuleInstance: Maybe<UpdateModuleInstancePayload>;
  updatePassword: Maybe<UpdatePasswordPayload>;
  updatePoll: Maybe<UpdatePollPayload>;
  updatePollOption: Maybe<UpdatePollOptionPayload>;
  updatePost: Maybe<UpdatePostPayload>;
  updatePrewordInstance: Maybe<UpdatePrewordInstancePayload>;
  updateProfilePageAnswer: Maybe<UpdateProfilePageAnswerPayload>;
  updateProfilePageComment: Maybe<UpdateProfilePageCommentPayload>;
  updateProfilePagePhoto: Maybe<UpdateProfilePagePhotoPayload>;
  updateProfilePagePhotoCategory: Maybe<UpdateProfilePagePhotoCategoryPayload>;
  updateProfilePageQuestion: Maybe<UpdateProfilePageQuestionPayload>;
  updateProfilePageQuestionOption: Maybe<UpdateProfilePageQuestionOptionPayload>;
  updateProfilePageSetup: Maybe<UpdateProfilePageSetupPayload>;
  updateQuotesInstance: Maybe<UpdateQuotesInstancePayload>;
  updateQuotesSetup: Maybe<UpdateQuotesSetupPayload>;
  updateRankingsAnswer: Maybe<UpdateRankingsAnswerPayload>;
  updateRankingsQuestion: Maybe<UpdateRankingsQuestionPayload>;
  updateRankingsSetup: Maybe<UpdateRankingsSetupPayload>;
  updateReportsInstance: Maybe<UpdateReportsInstancePayload>;
  updateReportsSetup: Maybe<UpdateReportsSetupPayload>;
  updateTeam: Maybe<UpdateTeamPayload>;
  updateUserGroup: Maybe<UpdateUserGroupPayload>;
  updateUserGroupNotification: Maybe<UpdateUserGroupNotificationPayload>;
  updateUserTeamAdmin: Maybe<UpdateUserTeamAdminPayload>;
  verifyToken: Maybe<VerifyPayload>;
};

export type MutationacceptUserInGroupArgs = {
  input: AcceptUserInGroupInput;
};

export type MutationacceptUserInTeamArgs = {
  input: AcceptUserInTeamInput;
};

export type MutationactivateModuleInstanceArgs = {
  input: ActivateModuleInstanceInput;
};

export type MutationactivateTeamArgs = {
  input: ActivateTeamInput;
};

export type MutationaddImagesToPostArgs = {
  input: AddImagesToPostInput;
};

export type MutationaddPhotoToCollagesAlbumArgs = {
  input: AddPhotoToCollagesAlbumInput;
};

export type MutationaddPhotosToCollagesAlbumArgs = {
  input: AddPhotosToCollagesAlbumInput;
};

export type MutationaddUserToGroupArgs = {
  input: AddUserToGroupInput;
};

export type MutationaddUserToTeamArgs = {
  input: AddUserToTeamInput;
};

export type MutationadminAcceptInstituteRequestArgs = {
  input: AdminAcceptInstituteRequestInput;
};

export type MutationadminAddUserToTeamArgs = {
  input: AdminAddUserToTeamInput;
};

export type MutationadminClearUserFlagsArgs = {
  input: AdminClearUserFlagsInput;
};

export type MutationadminDeleteInstituteRequestArgs = {
  input: AdminDeleteInstituteRequestInput;
};

export type MutationadminDeleteUserArgs = {
  input: AdminDeleteUserInput;
};

export type MutationadminDowngradeUserArgs = {
  input: AdminDowngradeUserInput;
};

export type MutationadminForgetPasswordLinkArgs = {
  input: AdminForgetPasswordLinkInput;
};

export type MutationadminRejectInstituteRequestArgs = {
  input: AdminRejectInstituteRequestInput;
};

export type MutationadminSetBrandLookArgs = {
  input: AdminSetBrandLookInput;
};

export type MutationadminUpdateInstituteArgs = {
  input: AdminUpdateInstituteInput;
};

export type MutationadminUpdateUserArgs = {
  input: AdminUpdateUserInput;
};

export type MutationadminUpgradeUserArgs = {
  input: AdminUpgradeUserInput;
};

export type MutationblockUserArgs = {
  input: BlockUserMutationInput;
};

export type MutationcommentPostArgs = {
  input: CommentPostInput;
};

export type MutationcreateAssetEventArgs = {
  input: CreateAssetEventInput;
};

export type MutationcreateBannerAssetArgs = {
  input: CreateBannerAssetInput;
};

export type MutationcreateCampaignArgs = {
  input: CreateCampaignInput;
};

export type MutationcreateCollagesAlbumArgs = {
  input: CreateCollagesAlbumInput;
};

export type MutationcreateCustomListArgs = {
  input: CreateCustomListInput;
};

export type MutationcreateCustomListOptionArgs = {
  input: CreateCustomListOptionInput;
};

export type MutationcreateCustomPagesInstanceArgs = {
  input: CreateCustomPagesInstanceInput;
};

export type MutationcreateDataExportArgs = {
  input: CreateDataExportInput;
};

export type MutationcreateDataPolicyAcceptanceArgs = {
  input: CreateDataPolicyAcceptanceInput;
};

export type MutationcreateEventArgs = {
  input: CreateEventInput;
};

export type MutationcreateGroupArgs = {
  input: CreateGroupInput;
};

export type MutationcreateInstituteArgs = {
  input: CreateInstituteInput;
};

export type MutationcreateModuleInstanceArgs = {
  input: CreateModuleInstanceInput;
};

export type MutationcreatePollArgs = {
  input: CreatePollInput;
};

export type MutationcreatePollOptionArgs = {
  input: CreatePollOptionInput;
};

export type MutationcreatePollVoteArgs = {
  input: CreatePollVoteInput;
};

export type MutationcreatePostArgs = {
  input: CreatePostInput;
};

export type MutationcreateProfilePageAnswerArgs = {
  input: CreateProfilePageAnswerInput;
};

export type MutationcreateProfilePageCommentArgs = {
  input: CreateProfilePageCommentInput;
};

export type MutationcreateProfilePagePhotoArgs = {
  input: CreateProfilePagePhotoInput;
};

export type MutationcreateProfilePagePhotoCategoryArgs = {
  input: CreateProfilePagePhotoCategoryInput;
};

export type MutationcreateProfilePageQuestionArgs = {
  input: CreateProfilePageQuestionInput;
};

export type MutationcreateProfilePageQuestionOptionArgs = {
  input: CreateProfilePageQuestionOptionInput;
};

export type MutationcreateQuotesInstanceArgs = {
  input: CreateQuotesInstanceInput;
};

export type MutationcreateRankingsAnswerArgs = {
  input: CreateRankingsAnswerInput;
};

export type MutationcreateRankingsQuestionArgs = {
  input: CreateRankingsQuestionInput;
};

export type MutationcreateReportsInstanceArgs = {
  input: CreateReportsInstanceInput;
};

export type MutationcreateTeamArgs = {
  input: CreateTeamInput;
};

export type MutationcreateTermsAcceptanceArgs = {
  input: CreateTermsAcceptanceInput;
};

export type MutationcreateTokenArgs = {
  input: ObtainJSONWebTokenInput;
};

export type MutationcreateUserArgs = {
  input: CreateUserInput;
};

export type MutationdeactivateModuleInstanceArgs = {
  input: DeactivateModuleInstanceInput;
};

export type MutationdeleteAssetArgs = {
  input: DeleteAssetInput;
};

export type MutationdeleteCampaignArgs = {
  input: DeleteCampaignInput;
};

export type MutationdeleteCommentArgs = {
  input: DeleteCommentInput;
};

export type MutationdeleteCustomListArgs = {
  input: DeleteCustomListInput;
};

export type MutationdeleteCustomListOptionArgs = {
  input: DeleteCustomListOptionInput;
};

export type MutationdeleteEventArgs = {
  input: DeleteEventInput;
};

export type MutationdeletePollArgs = {
  input: DeletePollInput;
};

export type MutationdeletePollOptionArgs = {
  input: DeletePollOptionInput;
};

export type MutationdeletePollVoteArgs = {
  input: DeletePollVoteInput;
};

export type MutationdeletePostArgs = {
  input: DeletePostInput;
};

export type MutationdeleteUserArgs = {
  input: DeleteUserInput;
};

export type MutationdestroyCollagesAlbumArgs = {
  input: DestroyCollagesAlbumInput;
};

export type MutationdestroyCustomPagesInstanceArgs = {
  input: DestroyCustomPagesInstanceInput;
};

export type MutationdestroyGroupArgs = {
  input: DestroyGroupInput;
};

export type MutationdestroyModuleInstanceArgs = {
  input: DestroyModuleInstanceInput;
};

export type MutationdestroyProfilePageAnswerArgs = {
  input: DestroyProfilePageAnswerInput;
};

export type MutationdestroyProfilePageCommentArgs = {
  input: DestroyProfilePageCommentInput;
};

export type MutationdestroyProfilePagePhotoArgs = {
  input: DestroyProfilePagePhotoInput;
};

export type MutationdestroyProfilePagePhotoCategoryArgs = {
  input: DestroyProfilePagePhotoCategoryInput;
};

export type MutationdestroyProfilePageQuestionArgs = {
  input: DestroyProfilePageQuestionInput;
};

export type MutationdestroyProfilePageQuestionOptionArgs = {
  input: DestroyProfilePageQuestionOptionInput;
};

export type MutationdestroyQuotesInstanceArgs = {
  input: DestroyQuotesInstanceInput;
};

export type MutationdestroyRankingsAnswerArgs = {
  input: DestroyRankingsAnswerInput;
};

export type MutationdestroyRankingsQuestionArgs = {
  input: DestroyRankingsQuestionInput;
};

export type MutationdestroyReportsInstanceArgs = {
  input: DestroyReportsInstanceInput;
};

export type MutationdestroyTeamArgs = {
  input: DestroyTeamInput;
};

export type MutationduplicateAssetArgs = {
  input: DuplicateAssetInput;
};

export type MutationeditBannerAdAssetArgs = {
  input: EditBannerAdAssetInput;
};

export type MutationeditCampaignArgs = {
  input: EditCampaignInput;
};

export type MutationeditCommentArgs = {
  input: EditCommentInput;
};

export type MutationeditEventArgs = {
  input: EditEventInput;
};

export type MutationforgetPasswordArgs = {
  input: ForgetPasswordInput;
};

export type MutationgenerateShopifyUrlArgs = {
  input: GenerateShopifyRedirectUrlInput;
};

export type MutationimportAbihomeArgs = {
  approved?: InputMaybe<Scalars['Boolean']['input']>;
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  email: Scalars['String']['input'];
  graduationType: Scalars['String']['input'];
  graduationYear: Scalars['Int']['input'];
  groupName: Scalars['String']['input'];
  iconId?: InputMaybe<Scalars['ID']['input']>;
  password: Scalars['String']['input'];
};

export type MutationimportToErpArgs = {
  input: ImportToErpInput;
};

export type MutationjoinEventArgs = {
  input: JoinEventInput;
};

export type MutationleaveEventArgs = {
  input: LeaveEventInput;
};

export type MutationleaveFromTeamArgs = {
  input: LeaveFromTeamInput;
};

export type MutationlikeCollagesPhotoArgs = {
  input: LikeCollagesPhotoInput;
};

export type MutationlikeCommentArgs = {
  input: LikeCommentInput;
};

export type MutationlikePostArgs = {
  input: LikePostInput;
};

export type MutationlikeQuotesInstanceArgs = {
  input: LikeQuotesInstanceInput;
};

export type MutationmoveGroupToInstituteArgs = {
  input: MoveGroupToInstituteInput;
};

export type MutationmoveProfilePageQuestionToPositionArgs = {
  input: MoveProfilePageQuestionToPositionInput;
};

export type MutationrefreshGroupTokenArgs = {
  input: RefreshGroupTokenInput;
};

export type MutationrefreshTokenArgs = {
  input: RefreshInput;
};

export type MutationremoveExternalEventArgs = {
  input: RemoveExternalEventInput;
};

export type MutationremoveImageFromPostArgs = {
  input: RemoveImageFromPostInput;
};

export type MutationremoveLikeFromCollagesPhotoArgs = {
  input: RemoveLikeFromCollagesPhotoInput;
};

export type MutationremoveLikeFromCommentArgs = {
  input: RemoveLikeFromCommentInput;
};

export type MutationremoveLikeFromPostArgs = {
  input: RemoveLikeFromPostInput;
};

export type MutationremoveLikeFromQuotesInstanceArgs = {
  input: RemoveLikeFromQuotesInstanceInput;
};

export type MutationremovePhotoFromCollagesAlbumArgs = {
  input: RemovePhotoFromCollagesAlbumInput;
};

export type MutationremoveUserFromGroupArgs = {
  input: RemoveUserFromGroupInput;
};

export type MutationremoveUserFromTeamArgs = {
  input: RemoveUserFromTeamInput;
};

export type MutationreportMutationArgs = {
  input: ReportMutationInput;
};

export type MutationreportPostContentArgs = {
  input: ReportPostContentInput;
};

export type MutationrequestMissingInstituteArgs = {
  input: RequestMissingInstituteInput;
};

export type MutationresetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationrevokeTokenArgs = {
  input: RevokeInput;
};

export type MutationsaveExternalEventArgs = {
  input: SaveExternalEventInput;
};

export type MutationsetBrandLookArgs = {
  input: SetBrandLookInput;
};

export type MutationsetEventBannerArgs = {
  input: SetEventBannerInput;
};

export type MutationsetGroupAvatarArgs = {
  input: SetGroupAvatarInput;
};

export type MutationsetUserGroupAvatarArgs = {
  input: SetUserGroupAvatarInput;
};

export type MutationtoggleExternalEventSaveArgs = {
  input: ToggleExternalEventSaveInput;
};

export type MutationtoggleIsActiveGroupArgs = {
  input: ToggleIsActiveGroupInput;
};

export type MutationtokenAuthArgs = {
  input: ObtainJSONWebTokenInput;
};

export type MutationupdateCollagesAlbumArgs = {
  input: UpdateCollagesAlbumInput;
};

export type MutationupdateCollagesSetupArgs = {
  input: UpdateCollagesSetupInput;
};

export type MutationupdateCustomListArgs = {
  input: UpdateCustomListInput;
};

export type MutationupdateCustomListOptionArgs = {
  input: UpdateCustomListOptionInput;
};

export type MutationupdateCustomPagesInstanceArgs = {
  input: UpdateCustomPagesInstanceInput;
};

export type MutationupdateCustomPagesSetupArgs = {
  input: UpdateCustomPagesSetupInput;
};

export type MutationupdateGroupArgs = {
  input: UpdateGroupInput;
};

export type MutationupdateMeArgs = {
  input: UpdateMeInput;
};

export type MutationupdateModuleInstanceArgs = {
  input: UpdateModuleInstanceInput;
};

export type MutationupdatePasswordArgs = {
  input: UpdatePasswordInput;
};

export type MutationupdatePollArgs = {
  input: UpdatePollInput;
};

export type MutationupdatePollOptionArgs = {
  input: UpdatePollOptionInput;
};

export type MutationupdatePostArgs = {
  input: UpdatePostInput;
};

export type MutationupdatePrewordInstanceArgs = {
  input: UpdatePrewordInstanceInput;
};

export type MutationupdateProfilePageAnswerArgs = {
  input: UpdateProfilePageAnswerInput;
};

export type MutationupdateProfilePageCommentArgs = {
  input: UpdateProfilePageCommentInput;
};

export type MutationupdateProfilePagePhotoArgs = {
  input: UpdateProfilePagePhotoInput;
};

export type MutationupdateProfilePagePhotoCategoryArgs = {
  input: UpdateProfilePagePhotoCategoryInput;
};

export type MutationupdateProfilePageQuestionArgs = {
  input: UpdateProfilePageQuestionInput;
};

export type MutationupdateProfilePageQuestionOptionArgs = {
  input: UpdateProfilePageQuestionOptionInput;
};

export type MutationupdateProfilePageSetupArgs = {
  input: UpdateProfilePageSetupInput;
};

export type MutationupdateQuotesInstanceArgs = {
  input: UpdateQuotesInstanceInput;
};

export type MutationupdateQuotesSetupArgs = {
  input: UpdateQuotesSetupInput;
};

export type MutationupdateRankingsAnswerArgs = {
  input: UpdateRankingsAnswerInput;
};

export type MutationupdateRankingsQuestionArgs = {
  input: UpdateRankingsQuestionInput;
};

export type MutationupdateRankingsSetupArgs = {
  input: UpdateRankingsSetupInput;
};

export type MutationupdateReportsInstanceArgs = {
  input: UpdateReportsInstanceInput;
};

export type MutationupdateReportsSetupArgs = {
  input: UpdateReportsSetupInput;
};

export type MutationupdateTeamArgs = {
  input: UpdateTeamInput;
};

export type MutationupdateUserGroupArgs = {
  input: UpdateUserGroupInput;
};

export type MutationupdateUserGroupNotificationArgs = {
  input: UpdateUserGroupNotificationInput;
};

export type MutationupdateUserTeamAdminArgs = {
  input: UpdateUserTeamAdminInput;
};

export type MutationverifyTokenArgs = {
  input: VerifyInput;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID']['output'];
};

export type NotificationNode = Node & {
  __typename: 'NotificationNode';
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
};

export type NotificationNodeConnection = {
  __typename: 'NotificationNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NotificationNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `NotificationNode` and its cursor. */
export type NotificationNodeEdge = {
  __typename: 'NotificationNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<NotificationNode>;
};

export type ObtainJSONWebTokenInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type ObtainJSONWebTokenPayload = {
  __typename: 'ObtainJSONWebTokenPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  payload: Scalars['GenericScalar']['output'];
  refreshExpiresIn: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

/** An enumeration. */
export enum Owner {
  TEAM = 'TEAM',
  USER = 'USER',
}

/** An enumeration. */
export enum PageFormat {
  CUSTOM = 'CUSTOM',
  LANDSCAPE = 'LANDSCAPE',
  PORTRAIT = 'PORTRAIT',
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** An enumeration. */
export enum PageQuality {
  CRITICAL = 'CRITICAL',
  GOOD = 'GOOD',
  POOR = 'POOR',
}

/** An enumeration. */
export enum PageType {
  IMAGE = 'IMAGE',
  PDF = 'PDF',
}

/** An enumeration. */
export enum PhotoFormat {
  AS_UPLOADED = 'AS_UPLOADED',
  PORTRAIT = 'PORTRAIT',
  SQUARE = 'SQUARE',
}

/** An enumeration. */
export enum Platform {
  CONTENTFUL = 'CONTENTFUL',
}

export type PollNode = Node & {
  __typename: 'PollNode';
  allowComments: Scalars['Boolean']['output'];
  allowRevote: Scalars['Boolean']['output'];
  anonymousVotes: Scalars['Boolean']['output'];
  answersAllowed: Scalars['Int']['output'];
  created: Scalars['DateTime']['output'];
  deadline: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  options: Maybe<Array<Maybe<PollOptionNode>>>;
  post: Maybe<PostNode>;
  privateResults: Scalars['Boolean']['output'];
  question: Scalars['String']['output'];
  randomizeOptions: Scalars['Boolean']['output'];
  totalVotes: Maybe<Scalars['Int']['output']>;
};

export type PollNodeConnection = {
  __typename: 'PollNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PollNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PollNode` and its cursor. */
export type PollNodeEdge = {
  __typename: 'PollNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<PollNode>;
};

export type PollOptionNode = Node & {
  __typename: 'PollOptionNode';
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  image: Maybe<Scalars['String']['output']>;
  modified: Scalars['DateTime']['output'];
  percentage: Maybe<Scalars['Float']['output']>;
  poll: Maybe<PollNode>;
  text: Scalars['String']['output'];
  votes: Maybe<Array<Maybe<PollVoteNode>>>;
  votesNumber: Maybe<Scalars['Int']['output']>;
};

export type PollVoteNode = Node & {
  __typename: 'PollVoteNode';
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  pollOption: Maybe<PollOptionNode>;
  userGroup: Maybe<UserGroupNode>;
};

export type PollVoteNodeConnection = {
  __typename: 'PollVoteNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PollVoteNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PollVoteNode` and its cursor. */
export type PollVoteNodeEdge = {
  __typename: 'PollVoteNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<PollVoteNode>;
};

export type PostNode = Node & {
  __typename: 'PostNode';
  comments: Maybe<Array<Maybe<CommentNode>>>;
  created: Scalars['DateTime']['output'];
  createdBy: Maybe<UserGroupNode>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  images: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  likes: Maybe<LikeNodeConnection>;
  likesCount: Maybe<Scalars['Int']['output']>;
  modified: Scalars['DateTime']['output'];
  owner: Maybe<Owner>;
  poll: Maybe<PollNode>;
  team: Maybe<TeamNode>;
  text: Scalars['String']['output'];
};

export type PostNodelikesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  userGroup?: InputMaybe<Scalars['ID']['input']>;
};

export type PostNodeConnection = {
  __typename: 'PostNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PostNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PostNode` and its cursor. */
export type PostNodeEdge = {
  __typename: 'PostNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<PostNode>;
};

export type PrewordInstanceNode = Node & {
  __typename: 'PrewordInstanceNode';
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  moduleInstance: ModuleInstanceNode;
  text: Scalars['String']['output'];
  wordCount: Maybe<Scalars['Int']['output']>;
};

export type ProfilePageAnswerNode = Node & {
  __typename: 'ProfilePageAnswerNode';
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  profilePageQuestion: ProfilePageQuestionNode;
  profilePageQuestionOption: Maybe<ProfilePageQuestionOptionNode>;
  text: Maybe<Scalars['String']['output']>;
  userGroup: UserGroupNode;
};

export type ProfilePageAnswerNodeConnection = {
  __typename: 'ProfilePageAnswerNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfilePageAnswerNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ProfilePageAnswerNode` and its cursor. */
export type ProfilePageAnswerNodeEdge = {
  __typename: 'ProfilePageAnswerNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<ProfilePageAnswerNode>;
};

export type ProfilePageCommentNode = Node & {
  __typename: 'ProfilePageCommentNode';
  comentatorUserGroup: UserGroupNode;
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  moduleInstance: ModuleInstanceNode;
  profileUserGroup: UserGroupNode;
  text: Scalars['String']['output'];
};

export type ProfilePageCommentNodeConnection = {
  __typename: 'ProfilePageCommentNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfilePageCommentNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ProfilePageCommentNode` and its cursor. */
export type ProfilePageCommentNodeEdge = {
  __typename: 'ProfilePageCommentNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<ProfilePageCommentNode>;
};

export type ProfilePagePhotoCategoryNode = Node & {
  __typename: 'ProfilePagePhotoCategoryNode';
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  moduleInstance: ModuleInstanceNode;
  name: Maybe<Scalars['String']['output']>;
  profilePagePhotos: Maybe<ProfilePagePhotoNodeConnection>;
  suggestedNames: Maybe<
    Array<Maybe<ProfilePagePhotoCategorySuggestedNameNode>>
  >;
};

export type ProfilePagePhotoCategoryNodeprofilePagePhotosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  profilePagePhotoCategory?: InputMaybe<Scalars['ID']['input']>;
  userGroup?: InputMaybe<Scalars['ID']['input']>;
};

export type ProfilePagePhotoCategoryNodeConnection = {
  __typename: 'ProfilePagePhotoCategoryNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfilePagePhotoCategoryNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ProfilePagePhotoCategoryNode` and its cursor. */
export type ProfilePagePhotoCategoryNodeEdge = {
  __typename: 'ProfilePagePhotoCategoryNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<ProfilePagePhotoCategoryNode>;
};

export type ProfilePagePhotoCategorySuggestedNameNode = Node & {
  __typename: 'ProfilePagePhotoCategorySuggestedNameNode';
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  name: Maybe<Scalars['String']['output']>;
};

export type ProfilePagePhotoCategorySuggestedNameNodeConnection = {
  __typename: 'ProfilePagePhotoCategorySuggestedNameNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfilePagePhotoCategorySuggestedNameNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ProfilePagePhotoCategorySuggestedNameNode` and its cursor. */
export type ProfilePagePhotoCategorySuggestedNameNodeEdge = {
  __typename: 'ProfilePagePhotoCategorySuggestedNameNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<ProfilePagePhotoCategorySuggestedNameNode>;
};

export type ProfilePagePhotoNode = Node & {
  __typename: 'ProfilePagePhotoNode';
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  image: Maybe<Scalars['String']['output']>;
  modified: Scalars['DateTime']['output'];
  profilePagePhotoCategory: ProfilePagePhotoCategoryNode;
  userGroup: UserGroupNode;
};

export type ProfilePagePhotoNodeConnection = {
  __typename: 'ProfilePagePhotoNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfilePagePhotoNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ProfilePagePhotoNode` and its cursor. */
export type ProfilePagePhotoNodeEdge = {
  __typename: 'ProfilePagePhotoNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<ProfilePagePhotoNode>;
};

export type ProfilePageQuestionNode = Node & {
  __typename: 'ProfilePageQuestionNode';
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  moduleInstance: ModuleInstanceNode;
  order: Scalars['Int']['output'];
  profilePageAnswers: Maybe<ProfilePageAnswerNodeConnection>;
  profilePageQuestionOptions: Maybe<
    Array<Maybe<ProfilePageQuestionOptionNode>>
  >;
  text: Scalars['String']['output'];
};

export type ProfilePageQuestionNodeprofilePageAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  profilePageQuestion?: InputMaybe<Scalars['ID']['input']>;
  userGroup?: InputMaybe<Scalars['ID']['input']>;
};

export type ProfilePageQuestionNodeConnection = {
  __typename: 'ProfilePageQuestionNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfilePageQuestionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ProfilePageQuestionNode` and its cursor. */
export type ProfilePageQuestionNodeEdge = {
  __typename: 'ProfilePageQuestionNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<ProfilePageQuestionNode>;
};

export type ProfilePageQuestionOptionNode = Node & {
  __typename: 'ProfilePageQuestionOptionNode';
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  profilePageQuestion: ProfilePageQuestionNode;
  text: Scalars['String']['output'];
};

export type ProfilePageSetupNode = Node & {
  __typename: 'ProfilePageSetupNode';
  anonymousComments: Scalars['Boolean']['output'];
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  maxChars: Scalars['Int']['output'];
  modified: Scalars['DateTime']['output'];
  moduleInstance: ModuleInstanceNode;
  photoFormat: Maybe<PhotoFormat>;
  usersDelivered: Maybe<Scalars['Int']['output']>;
  usersSummary: Maybe<Array<Maybe<ProfilePageUserSummaryNode>>>;
  visibleComments: Scalars['Boolean']['output'];
};

export type ProfilePageSuggestedQuestionNode = Node & {
  __typename: 'ProfilePageSuggestedQuestionNode';
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  options: Maybe<Array<Maybe<ProfilePageSuggestedQuestionOptionNode>>>;
  text: Scalars['String']['output'];
};

export type ProfilePageSuggestedQuestionNodeConnection = {
  __typename: 'ProfilePageSuggestedQuestionNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfilePageSuggestedQuestionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ProfilePageSuggestedQuestionNode` and its cursor. */
export type ProfilePageSuggestedQuestionNodeEdge = {
  __typename: 'ProfilePageSuggestedQuestionNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<ProfilePageSuggestedQuestionNode>;
};

export type ProfilePageSuggestedQuestionOptionNode = Node & {
  __typename: 'ProfilePageSuggestedQuestionOptionNode';
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  question: ProfilePageSuggestedQuestionNode;
  text: Scalars['String']['output'];
};

export type ProfilePageUserSummaryNode = {
  __typename: 'ProfilePageUserSummaryNode';
  imagesUploaded: Maybe<Scalars['Int']['output']>;
  questionsAnswered: Maybe<Scalars['Int']['output']>;
  userGroup: UserGroupNode;
};

export type Query = {
  __typename: 'Query';
  _debug: Maybe<DjangoDebug>;
  activeGroups: Maybe<Array<Maybe<CountryData>>>;
  adminSearchResults: Maybe<SearchStructure3>;
  asset: Maybe<AssetNode>;
  bestMatchAssets: Maybe<Array<Maybe<MatchedAssetNode>>>;
  brandLookColor: Maybe<BrandColorNode>;
  brandLookColors: Maybe<BrandColorNodeConnection>;
  brandLookFont: Maybe<BrandFontNode>;
  brandLookFonts: Maybe<BrandFontNodeConnection>;
  brandLookSelection: Maybe<BrandLookSelectionNode>;
  brandLookSelectionHistory: Maybe<BrandLookSelectionHistoryNode>;
  brandLookSelections: Maybe<BrandLookSelectionNodeConnection>;
  brandLookSelectionsHistory: Maybe<BrandLookSelectionHistoryNodeConnection>;
  campaign: Maybe<CampaignNode>;
  campaigns: Maybe<CampaignNodeConnection>;
  citiesZip: Maybe<Array<Maybe<CityNode>>>;
  cityLocation: Maybe<CityLocationNode>;
  cityLocations: Maybe<CityLocationNodeConnection>;
  collagesAlbum: Maybe<CollagesAlbumNode>;
  collagesAlbumSuggestedNames: Maybe<CollagesAlbumSuggestedNameNodeConnection>;
  collagesAlbums: Maybe<CollagesAlbumNodeConnection>;
  collagesPhotos: Maybe<CollagesPhotoNodeConnection>;
  color: Maybe<ColorNode>;
  colors: Maybe<ColorNodeConnection>;
  customList: Maybe<CustomListNode>;
  customListOptions: Maybe<CustomListOptionNodeConnection>;
  customLists: Maybe<CustomListNodeConnection>;
  customPagesInstance: Maybe<CustomPagesInstanceNode>;
  customPagesInstances: Maybe<CustomPagesInstanceNodeConnection>;
  customPagesInstancesResult: Maybe<CustomPagesInstanceNodeConnection>;
  customPagesSetup: Maybe<CustomPagesSetupNode>;
  dataExport: Maybe<DataExportNode>;
  dataExports: Maybe<DataExportNodeConnection>;
  defaultAvatar: Maybe<DefaultAvatarNode>;
  defaultAvatars: Maybe<DefaultAvatarNodeConnection>;
  event: Maybe<EventNode>;
  events: Maybe<EventNodeConnection>;
  externalEventAttendees: Maybe<ExternalEventAttendeeNodeConnection>;
  findDuplicateInstitutes: Maybe<Array<Maybe<InstituteNode>>>;
  generateFreshUrl: Maybe<Scalars['String']['output']>;
  graduationType: Maybe<GraduationTypeNode>;
  graduationTypes: Maybe<GraduationTypeNodeConnection>;
  graduationTypesCountry: Maybe<Array<Maybe<GraduationTypeCountryNode>>>;
  group: Maybe<GroupNode>;
  groupIcon: Maybe<GroupIconNode>;
  groupIcons: Maybe<GroupIconNodeConnection>;
  groups: Maybe<GroupNodeConnection>;
  icon: Maybe<IconNode>;
  icons: Maybe<IconNodeConnection>;
  inactiveGroups: Maybe<Array<Maybe<CountryData>>>;
  institute: Maybe<InstituteNode>;
  instituteRequest: Maybe<InstituteRequestNode>;
  instituteRequests: Maybe<InstituteRequestNodeConnection>;
  instituteType: Maybe<InstituteTypeNode>;
  instituteTypes: Maybe<InstituteTypeNodeConnection>;
  institutes: Maybe<InstituteNodeConnection>;
  institutesTargeting: Maybe<TargetingNode>;
  institutesWithOneGroup: Maybe<Array<Maybe<CountryData>>>;
  kpis: Maybe<KPIsNode>;
  leaderboardUsers: Maybe<LeaderboardUserNodeConnection>;
  marketProfile: Maybe<MarketProfileNode>;
  marketProfiles: Maybe<MarketProfileNodeConnection>;
  me: Maybe<UserNode>;
  module: Maybe<ModuleNode>;
  moduleInstance: Maybe<ModuleInstanceNode>;
  moduleInstances: Maybe<ModuleInstanceNodeConnection>;
  modules: Maybe<ModuleNodeConnection>;
  notification: Maybe<NotificationNode>;
  notifications: Maybe<NotificationNodeConnection>;
  number: Maybe<Scalars['Int']['output']>;
  polls: Maybe<PollNodeConnection>;
  post: Maybe<PostNode>;
  posts: Maybe<PostNodeConnection>;
  profilePageComment: Maybe<ProfilePageCommentNode>;
  profilePageComments: Maybe<ProfilePageCommentNodeConnection>;
  profilePagePhoto: Maybe<ProfilePagePhotoNode>;
  profilePagePhotoCategories: Maybe<ProfilePagePhotoCategoryNodeConnection>;
  profilePagePhotoCategory: Maybe<ProfilePagePhotoCategoryNode>;
  profilePagePhotoCategorySuggestedNames: Maybe<ProfilePagePhotoCategorySuggestedNameNodeConnection>;
  profilePagePhotos: Maybe<ProfilePagePhotoNodeConnection>;
  profilePageQuestion: Maybe<ProfilePageQuestionNode>;
  profilePageQuestions: Maybe<ProfilePageQuestionNodeConnection>;
  profilePageSuggestedQuestion: Maybe<ProfilePageSuggestedQuestionNode>;
  profilePageSuggestedQuestions: Maybe<ProfilePageSuggestedQuestionNodeConnection>;
  quotesInstance: Maybe<QuotesInstanceNode>;
  quotesInstances: Maybe<QuotesInstanceNodeConnection>;
  quotesResult: Maybe<QuotesInstanceNodeConnection>;
  rankingsQuestion: Maybe<RankingsQuestionNode>;
  rankingsQuestions: Maybe<RankingsQuestionNodeConnection>;
  regions: Maybe<Array<Maybe<CityLocationNode>>>;
  reportsInstance: Maybe<ReportsInstanceNode>;
  reportsInstances: Maybe<ReportsInstanceNodeConnection>;
  runAssetCronJobChecks: Maybe<Scalars['Boolean']['output']>;
  runCampaignCronJobChecks: Maybe<Scalars['Boolean']['output']>;
  signups: Maybe<Array<Maybe<CountryData>>>;
  statistics: Maybe<StatisticsNode>;
  team: Maybe<TeamNode>;
  teams: Maybe<TeamNodeConnection>;
  user: Maybe<UserNode>;
  userExists: Maybe<UserExistsNode>;
  userGroup: Maybe<UserGroupNode>;
  userGroups: Maybe<UserGroupNodeConnection>;
  userTeam: Maybe<UserTeamNode>;
  users: Maybe<UserNodeConnection>;
  usersWithoutGroup: Maybe<Array<Maybe<CountryData>>>;
};

export type QueryactiveGroupsArgs = {
  filter?: InputMaybe<DashboardFilterInput>;
};

export type QueryadminSearchResultsArgs = {
  city?: InputMaybe<Scalars['String']['input']>;
  custom?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  gid?: InputMaybe<Scalars['String']['input']>;
  gname?: InputMaybe<Scalars['String']['input']>;
  gtype?: InputMaybe<Scalars['String']['input']>;
  itype?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  output?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type QueryassetArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerybestMatchAssetsArgs = {
  channelType?: InputMaybe<ChannelType>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type QuerybrandLookColorArgs = {
  id: Scalars['ID']['input'];
};

export type QuerybrandLookColorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  primary?: InputMaybe<Scalars['String']['input']>;
  secondary?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ColorStatus>;
};

export type QuerybrandLookFontArgs = {
  id: Scalars['ID']['input'];
};

export type QuerybrandLookFontsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<FontStatus>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerybrandLookSelectionArgs = {
  id: Scalars['ID']['input'];
};

export type QuerybrandLookSelectionHistoryArgs = {
  id: Scalars['ID']['input'];
};

export type QuerybrandLookSelectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['ID']['input']>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  font?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerybrandLookSelectionsHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['ID']['input']>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  created_Gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_Lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  font?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerycampaignArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerycampaignsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customer?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  searchFilter?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<CampaignStatus>;
  totalClicks?: InputMaybe<Scalars['Int']['input']>;
  totalViews?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<CampaignType>;
  utm?: InputMaybe<Scalars['String']['input']>;
};

export type QuerycitiesZipArgs = {
  country?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type QuerycityLocationArgs = {
  id: Scalars['ID']['input'];
};

export type QuerycityLocationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<InstitutesCityLocationCountryChoices>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type QuerycollagesAlbumArgs = {
  id: Scalars['ID']['input'];
};

export type QuerycollagesAlbumSuggestedNamesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  marketProfile?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerycollagesAlbumsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  moduleInstance?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  userGroup?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerycollagesPhotosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  collagesAlbum?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerycolorArgs = {
  id: Scalars['ID']['input'];
};

export type QuerycolorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerycustomListArgs = {
  id: Scalars['ID']['input'];
};

export type QuerycustomListOptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customList?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerycustomListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  moduleInstance?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerycustomPagesInstanceArgs = {
  id: Scalars['ID']['input'];
};

export type QuerycustomPagesInstancesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  moduleInstance?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  userGroup: Scalars['String']['input'];
};

export type QuerycustomPagesInstancesResultArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  moduleInstance: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerycustomPagesSetupArgs = {
  id: Scalars['ID']['input'];
};

export type QuerydataExportArgs = {
  id: Scalars['ID']['input'];
};

export type QuerydataExportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  group?: InputMaybe<Scalars['ID']['input']>;
  isodatetime?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerydefaultAvatarArgs = {
  id: Scalars['ID']['input'];
};

export type QuerydefaultAvatarsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryeventArgs = {
  id: Scalars['ID']['input'];
};

export type QueryeventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  authorType?: InputMaybe<EventsEventAuthorTypeChoices>;
  before?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  eventType?: InputMaybe<EventType>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  isJoinable?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locationContains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  radius?: InputMaybe<Scalars['Decimal']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  titleContains?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryexternalEventAttendeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  attendee?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryfindDuplicateInstitutesArgs = {
  customSearch?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type QuerygenerateFreshUrlArgs = {
  url?: InputMaybe<Scalars['String']['input']>;
};

export type QuerygraduationTypeArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygraduationTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerygraduationTypesCountryArgs = {
  country?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QuerygroupArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygroupIconArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygroupIconsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerygroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  institute?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  marketProfile?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['ID']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryiconArgs = {
  id: Scalars['ID']['input'];
};

export type QueryiconsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryinactiveGroupsArgs = {
  filter?: InputMaybe<DashboardFilterInput>;
};

export type QueryinstituteArgs = {
  id: Scalars['ID']['input'];
};

export type QueryinstituteRequestArgs = {
  id: Scalars['ID']['input'];
};

export type QueryinstituteRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<InstitutesInstituteRequestCountryChoices>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Status>;
  street?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['ID']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type QueryinstituteTypeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryinstituteTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<InstitutesInstituteCountryChoices>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryinstitutesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<InstitutesInstituteCountryChoices>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  internalId?: InputMaybe<Scalars['String']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['ID']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type QueryinstitutesTargetingArgs = {
  targetingFilters?: InputMaybe<Array<InputMaybe<TargetingFilterInput>>>;
};

export type QueryinstitutesWithOneGroupArgs = {
  filter?: InputMaybe<DashboardFilterInput>;
};

export type QuerykpisArgs = {
  filter?: InputMaybe<DashboardFilterInput>;
};

export type QueryleaderboardUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerymarketProfileArgs = {
  id: Scalars['ID']['input'];
};

export type QuerymarketProfilesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerymoduleArgs = {
  id: Scalars['ID']['input'];
};

export type QuerymoduleInstanceArgs = {
  id: Scalars['ID']['input'];
};

export type QuerymoduleInstancesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  group?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerymodulesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerynotificationArgs = {
  id: Scalars['ID']['input'];
};

export type QuerynotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerypollsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  allowComments?: InputMaybe<Scalars['Boolean']['input']>;
  allowRevote?: InputMaybe<Scalars['Boolean']['input']>;
  anonymousVotes?: InputMaybe<Scalars['Boolean']['input']>;
  answersAllowed?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  deadline?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  post?: InputMaybe<Scalars['ID']['input']>;
  post_CreatedBy_Group?: InputMaybe<Scalars['ID']['input']>;
  post_Team?: InputMaybe<Scalars['ID']['input']>;
  privateResults?: InputMaybe<Scalars['Boolean']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  randomizeOptions?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerypostArgs = {
  id: Scalars['ID']['input'];
};

export type QuerypostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdBy_Group?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  owner?: InputMaybe<Owner>;
  team?: InputMaybe<Scalars['ID']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type QueryprofilePageCommentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryprofilePageCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  comentatorUserGroup?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  moduleInstance?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  profileUserGroup?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryprofilePagePhotoArgs = {
  id: Scalars['ID']['input'];
};

export type QueryprofilePagePhotoCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  moduleInstance?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryprofilePagePhotoCategoryArgs = {
  id: Scalars['ID']['input'];
};

export type QueryprofilePagePhotoCategorySuggestedNamesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  marketProfile?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryprofilePagePhotosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  profilePagePhotoCategory?: InputMaybe<Scalars['ID']['input']>;
  userGroup?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryprofilePageQuestionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryprofilePageQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  moduleInstance?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryprofilePageSuggestedQuestionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryprofilePageSuggestedQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryquotesInstanceArgs = {
  id: Scalars['ID']['input'];
};

export type QueryquotesInstancesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  moduleInstance?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  quote?: InputMaybe<Scalars['String']['input']>;
  userGroup?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryquotesResultArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  moduleInstance?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  quote?: InputMaybe<Scalars['String']['input']>;
};

export type QueryrankingsQuestionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryrankingsQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  allowedVotes?: InputMaybe<AllowedVoteType>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  moduleInstance?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  onlyOppositeGender?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

export type QueryregionsArgs = {
  country?: InputMaybe<Scalars['String']['input']>;
};

export type QueryreportsInstanceArgs = {
  id: Scalars['ID']['input'];
};

export type QueryreportsInstancesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  moduleInstance?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerysignupsArgs = {
  filter?: InputMaybe<DashboardFilterInput>;
};

export type QuerystatisticsArgs = {
  filter?: InputMaybe<DashboardFilterInput>;
};

export type QueryteamArgs = {
  id: Scalars['ID']['input'];
};

export type QueryteamsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  group?: InputMaybe<Scalars['ID']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  userGroupId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryuserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryuserExistsArgs = {
  email: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  phoneCountryCode: Scalars['String']['input'];
};

export type QueryuserGroupArgs = {
  id: Scalars['ID']['input'];
};

export type QueryuserGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  group?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<GroupsUserGroupRoleChoices>;
  status?: InputMaybe<GroupsUserGroupStatusChoices>;
  user?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryuserTeamArgs = {
  id: Scalars['ID']['input'];
};

export type QueryusersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAgent?: InputMaybe<Scalars['Boolean']['input']>;
  isStaff?: InputMaybe<Scalars['Boolean']['input']>;
  isSuperuser?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchNameEmail?: InputMaybe<Scalars['String']['input']>;
};

export type QueryusersWithoutGroupArgs = {
  filter?: InputMaybe<DashboardFilterInput>;
};

export type QuotesInstanceLikeNode = Node & {
  __typename: 'QuotesInstanceLikeNode';
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  quotesInstance: Maybe<QuotesInstanceNode>;
  userGroup: Maybe<UserGroupNode>;
};

export type QuotesInstanceLikeNodeConnection = {
  __typename: 'QuotesInstanceLikeNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<QuotesInstanceLikeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `QuotesInstanceLikeNode` and its cursor. */
export type QuotesInstanceLikeNodeEdge = {
  __typename: 'QuotesInstanceLikeNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<QuotesInstanceLikeNode>;
};

export type QuotesInstanceNode = Node & {
  __typename: 'QuotesInstanceNode';
  /** This refers to if the quotes setup was set as anonymous when the quote was written. */
  anonymous: Maybe<Scalars['Boolean']['output']>;
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  moduleInstance: ModuleInstanceNode;
  quote: Scalars['String']['output'];
  quotesInstanceLikes: Maybe<QuotesInstanceLikeNodeConnection>;
  quotesInstanceLikesCount: Maybe<Scalars['Int']['output']>;
  userGroup: UserGroupNode;
};

export type QuotesInstanceNodequotesInstanceLikesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  userGroup?: InputMaybe<Scalars['ID']['input']>;
};

export type QuotesInstanceNodeConnection = {
  __typename: 'QuotesInstanceNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<QuotesInstanceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `QuotesInstanceNode` and its cursor. */
export type QuotesInstanceNodeEdge = {
  __typename: 'QuotesInstanceNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<QuotesInstanceNode>;
};

export type QuotesSetupNode = Node & {
  __typename: 'QuotesSetupNode';
  anonymous: Scalars['Boolean']['output'];
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  maxChars: Scalars['Int']['output'];
  modified: Scalars['DateTime']['output'];
  moduleInstance: ModuleInstanceNode;
  public: Scalars['Boolean']['output'];
  quotesCount: Maybe<Scalars['Int']['output']>;
};

export type RankingsAnswerNode = Node & {
  __typename: 'RankingsAnswerNode';
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  rankingsQuestion: RankingsQuestionNode;
  votee1UserGroup: Maybe<UserGroupNode>;
  votee2UserGroup: Maybe<UserGroupNode>;
  voteeListOption: Maybe<CustomListOptionNode>;
  voterUserGroup: UserGroupNode;
};

export type RankingsAnswerNodeConnection = {
  __typename: 'RankingsAnswerNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RankingsAnswerNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `RankingsAnswerNode` and its cursor. */
export type RankingsAnswerNodeEdge = {
  __typename: 'RankingsAnswerNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<RankingsAnswerNode>;
};

export type RankingsQuestionNode = Node & {
  __typename: 'RankingsQuestionNode';
  allowedVotes: Maybe<AllowedVoteType>;
  answerType: Maybe<AnswerType>;
  created: Scalars['DateTime']['output'];
  customList: Maybe<CustomListNode>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  moduleInstance: ModuleInstanceNode;
  onlyOppositeGender: Maybe<Scalars['Boolean']['output']>;
  question: Scalars['String']['output'];
  rankingsAnswers: Maybe<RankingsAnswerNodeConnection>;
  results: Maybe<RankingsQuestionResultsNode>;
};

export type RankingsQuestionNoderankingsAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  rankingsQuestion?: InputMaybe<Scalars['ID']['input']>;
  voterUserGroup?: InputMaybe<Scalars['ID']['input']>;
};

export type RankingsQuestionNodeConnection = {
  __typename: 'RankingsQuestionNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RankingsQuestionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `RankingsQuestionNode` and its cursor. */
export type RankingsQuestionNodeEdge = {
  __typename: 'RankingsQuestionNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<RankingsQuestionNode>;
};

export type RankingsQuestionResultNode = {
  __typename: 'RankingsQuestionResultNode';
  percentage: Maybe<Scalars['Float']['output']>;
  votee1UserGroup: Maybe<UserGroupNode>;
  votee2UserGroup: Maybe<UserGroupNode>;
  voteeListOption: Maybe<CustomListOptionNode>;
  votesNumber: Maybe<Scalars['Int']['output']>;
};

export type RankingsQuestionResultsNode = {
  __typename: 'RankingsQuestionResultsNode';
  alreadyVoted: Maybe<Scalars['Int']['output']>;
  options: Maybe<Array<Maybe<RankingsQuestionResultNode>>>;
  possibleVoters: Maybe<Scalars['Int']['output']>;
};

export type RankingsSetupNode = Node & {
  __typename: 'RankingsSetupNode';
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  moduleInstance: ModuleInstanceNode;
  rankingsCount: Maybe<Scalars['Int']['output']>;
  resultsTeam: Maybe<TeamNode>;
};

export type RefreshGroupTokenInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type RefreshGroupTokenPayload = {
  __typename: 'RefreshGroupTokenPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  group: Maybe<GroupNode>;
};

export type RefreshInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
};

export type RefreshPayload = {
  __typename: 'RefreshPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  payload: Scalars['GenericScalar']['output'];
  refreshExpiresIn: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type RemoveExternalEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
};

export type RemoveExternalEventPayload = {
  __typename: 'RemoveExternalEventPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  externalEvent: Maybe<ExternalEventAttendeeNode>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type RemoveImageFromPostInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  imageName: Scalars['String']['input'];
};

export type RemoveImageFromPostPayload = {
  __typename: 'RemoveImageFromPostPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  post: Maybe<PostNode>;
};

export type RemoveLikeFromCollagesPhotoInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type RemoveLikeFromCollagesPhotoPayload = {
  __typename: 'RemoveLikeFromCollagesPhotoPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  collagesPhoto: Maybe<CollagesPhotoNode>;
};

export type RemoveLikeFromCommentInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type RemoveLikeFromCommentPayload = {
  __typename: 'RemoveLikeFromCommentPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  post: Maybe<PostNode>;
};

export type RemoveLikeFromPostInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type RemoveLikeFromPostPayload = {
  __typename: 'RemoveLikeFromPostPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  post: Maybe<PostNode>;
};

export type RemoveLikeFromQuotesInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type RemoveLikeFromQuotesInstancePayload = {
  __typename: 'RemoveLikeFromQuotesInstancePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  quotesInstance: Maybe<QuotesInstanceNode>;
};

export type RemovePhotoFromCollagesAlbumInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type RemovePhotoFromCollagesAlbumPayload = {
  __typename: 'RemovePhotoFromCollagesAlbumPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  collagesAlbum: Maybe<CollagesAlbumNode>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type RemoveUserFromGroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type RemoveUserFromGroupPayload = {
  __typename: 'RemoveUserFromGroupPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
  userGroup: Maybe<UserGroupNode>;
};

export type RemoveUserFromTeamInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type RemoveUserFromTeamPayload = {
  __typename: 'RemoveUserFromTeamPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
  userTeam: Maybe<UserTeamNode>;
};

export type ReportMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ReportMutationPayload = {
  __typename: 'ReportMutationPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  message: Maybe<Scalars['String']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type ReportPostContentInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ReportPostContentPayload = {
  __typename: 'ReportPostContentPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  message: Maybe<Scalars['String']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type ReportsInstanceNode = Node & {
  __typename: 'ReportsInstanceNode';
  created: Scalars['DateTime']['output'];
  editTeam: Maybe<TeamNode>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  maxChars: Scalars['Int']['output'];
  modified: Scalars['DateTime']['output'];
  moduleInstance: ModuleInstanceNode;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  viewTeam: Maybe<TeamNode>;
};

export type ReportsInstanceNodeConnection = {
  __typename: 'ReportsInstanceNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReportsInstanceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ReportsInstanceNode` and its cursor. */
export type ReportsInstanceNodeEdge = {
  __typename: 'ReportsInstanceNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<ReportsInstanceNode>;
};

export type ReportsSetupNode = Node & {
  __typename: 'ReportsSetupNode';
  allowImages: Scalars['Boolean']['output'];
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  moduleInstance: ModuleInstanceNode;
  reportsCount: Maybe<Scalars['Int']['output']>;
};

export type RequestMissingInstituteInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  street?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['ID']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type RequestMissingInstitutePayload = {
  __typename: 'RequestMissingInstitutePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  instituteRequest: Maybe<InstituteRequestNode>;
  message: Maybe<Scalars['String']['output']>;
  responseCode: Maybe<Scalars['Int']['output']>;
};

export type ResetPasswordInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type ResetPasswordPayload = {
  __typename: 'ResetPasswordPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  user: Maybe<UserNode>;
};

export type RevokeInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
};

export type RevokePayload = {
  __typename: 'RevokePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  revoked: Scalars['Int']['output'];
};

export type SaveExternalEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
};

export type SaveExternalEventPayload = {
  __typename: 'SaveExternalEventPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  externalEvent: Maybe<ExternalEventAttendeeNode>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type SearchGroupType = Node & {
  __typename: 'SearchGroupType';
  avatar: Maybe<Scalars['String']['output']>;
  graduationYear: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  institute: SearchInstituteType;
  name: Scalars['String']['output'];
  type: Maybe<Scalars['String']['output']>;
};

export type SearchInstituteType = Node & {
  __typename: 'SearchInstituteType';
  city: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  internalId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Maybe<Scalars['String']['output']>;
  zip: Maybe<Scalars['String']['output']>;
};

export type SearchStructure3 = {
  __typename: 'SearchStructure3';
  groups: Array<Maybe<SearchGroupType>>;
  institutes: Array<Maybe<SearchInstituteType>>;
  users: Array<Maybe<SearchUserType>>;
};

export type SearchUserType = Node & {
  __typename: 'SearchUserType';
  avatar: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  groups: Maybe<Array<Maybe<SearchGroupType>>>;
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  phone: Maybe<Scalars['String']['output']>;
  phoneCountryCode: Maybe<Scalars['String']['output']>;
};

export type SetBrandLookInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  colorId: Scalars['ID']['input'];
  fontId: Scalars['ID']['input'];
};

export type SetBrandLookPayload = {
  __typename: 'SetBrandLookPayload';
  brandLookSelection: Maybe<BrandLookSelectionNode>;
  clientMutationId: Maybe<Scalars['String']['output']>;
};

export type SetEventBannerInput = {
  bannerImage?: InputMaybe<Scalars['Upload']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type SetEventBannerPayload = {
  __typename: 'SetEventBannerPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  event: Maybe<EventNode>;
};

export type SetGroupAvatarInput = {
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  iconId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type SetGroupAvatarPayload = {
  __typename: 'SetGroupAvatarPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  group: Maybe<GroupNode>;
};

export type SetUserGroupAvatarInput = {
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  defaultAvatarId?: InputMaybe<Scalars['ID']['input']>;
  userGroupId: Scalars['ID']['input'];
};

export type SetUserGroupAvatarPayload = {
  __typename: 'SetUserGroupAvatarPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  userGroup: Maybe<UserGroupNode>;
};

export type StatisticsNode = {
  __typename: 'StatisticsNode';
  activeGroupsCount: Maybe<Scalars['Int']['output']>;
  inactiveGroupsCount: Maybe<Scalars['Int']['output']>;
  institutesCount: Maybe<Scalars['Int']['output']>;
  usersCount: Maybe<Scalars['Int']['output']>;
};

/** An enumeration. */
export enum Status {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export type TargetingFilterGroupInput = {
  graduationType?: InputMaybe<Scalars['ID']['input']>;
  graduationYear?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  groupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  instituteType?: InputMaybe<Scalars['ID']['input']>;
  members?: InputMaybe<TargetingFilterGroupMembersInput>;
  teams?: InputMaybe<Array<InputMaybe<TeamType>>>;
};

export type TargetingFilterGroupMembersInput = {
  operator?: InputMaybe<TargetingFilterGroupMembersOperation>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** An enumeration. */
export enum TargetingFilterGroupMembersOperation {
  BELOW = 'BELOW',
  EXACTLY = 'EXACTLY',
  LARGER_THAN = 'LARGER_THAN',
}

export type TargetingFilterGroupMembersType = {
  __typename: 'TargetingFilterGroupMembersType';
  operator: Maybe<TargetingFilterGroupMembersOperation>;
  value: Maybe<Scalars['Int']['output']>;
};

export type TargetingFilterGroupType = {
  __typename: 'TargetingFilterGroupType';
  graduationType: Maybe<Scalars['ID']['output']>;
  graduationYear: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  groupIds: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  instituteType: Maybe<Scalars['ID']['output']>;
  members: Maybe<TargetingFilterGroupMembersType>;
  teams: Maybe<Array<Maybe<TeamType>>>;
};

export type TargetingFilterInput = {
  group?: InputMaybe<TargetingFilterGroupInput>;
  location?: InputMaybe<TargetingFilterLocationInput>;
  operator: Scalars['String']['input'];
  type: Scalars['String']['input'];
  user?: InputMaybe<TargetingFilterUserInput>;
};

export type TargetingFilterLocationInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  radius?: InputMaybe<Scalars['Int']['input']>;
  region?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  zip?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type TargetingFilterLocationType = {
  __typename: 'TargetingFilterLocationType';
  city: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  radius: Maybe<Scalars['Int']['output']>;
  region: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  zip: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

export type TargetingFilterType = {
  __typename: 'TargetingFilterType';
  group: Maybe<TargetingFilterGroupType>;
  location: Maybe<TargetingFilterLocationType>;
  operator: Scalars['String']['output'];
  type: Scalars['String']['output'];
  user: Maybe<TargetingFilterUserType>;
};

/** An enumeration. */
export enum TargetingFilterUserGenderOperation {
  DIVERSE = 'DIVERSE',
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NOTSET = 'NOTSET',
}

export type TargetingFilterUserInput = {
  gender?: InputMaybe<TargetingFilterUserGenderOperation>;
  registration?: InputMaybe<TargetingFilterUserRegistrationInput>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type TargetingFilterUserRegistrationInput = {
  date?: InputMaybe<Scalars['String']['input']>;
  operator?: InputMaybe<TargetingFilterUserRegistrationOperation>;
};

/** An enumeration. */
export enum TargetingFilterUserRegistrationOperation {
  BEFORE = 'BEFORE',
  EXACTLY = 'EXACTLY',
  LATER_THAN = 'LATER_THAN',
}

export type TargetingFilterUserRegistrationType = {
  __typename: 'TargetingFilterUserRegistrationType';
  date: Maybe<Scalars['String']['output']>;
  operator: Maybe<TargetingFilterUserRegistrationOperation>;
};

export type TargetingFilterUserType = {
  __typename: 'TargetingFilterUserType';
  gender: Maybe<TargetingFilterUserGenderOperation>;
  registration: Maybe<TargetingFilterUserRegistrationType>;
  userIds: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type TargetingNode = {
  __typename: 'TargetingNode';
  activeGroupsCount: Maybe<Scalars['Int']['output']>;
  inactiveGroupsCount: Maybe<Scalars['Int']['output']>;
  institutes: Maybe<Array<Maybe<InstituteTargetingNode>>>;
  usersCount: Maybe<Scalars['Int']['output']>;
};

export type TeamNode = Node & {
  __typename: 'TeamNode';
  approvalSetting: Maybe<ApprovalSetting>;
  avatar: Maybe<Scalars['String']['output']>;
  color: Maybe<ColorNode>;
  created: Scalars['DateTime']['output'];
  group: GroupNode;
  icon: Maybe<IconNode>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isHidden: Scalars['Boolean']['output'];
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  sampleMembers: Maybe<Array<Maybe<UserTeamNode>>>;
  type: Maybe<TeamType>;
  users: Maybe<Array<Maybe<UserTeamNode>>>;
  usersCount: Maybe<Scalars['Int']['output']>;
};

export type TeamNodeConnection = {
  __typename: 'TeamNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeamNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TeamNode` and its cursor. */
export type TeamNodeEdge = {
  __typename: 'TeamNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<TeamNode>;
};

/** An enumeration. */
export enum TeamType {
  CLOTHING = 'CLOTHING',
  CORE = 'CORE',
  FINANCE = 'FINANCE',
  OTHER = 'OTHER',
  PROM = 'PROM',
  YEARBOOK = 'YEARBOOK',
}

export type TermsAcceptanceNode = Node & {
  __typename: 'TermsAcceptanceNode';
  agreed: Scalars['Boolean']['output'];
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  ip: Scalars['String']['output'];
  modified: Scalars['DateTime']['output'];
  user: UserNode;
  version: Scalars['String']['output'];
};

export type ToggleExternalEventSaveInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
};

export type ToggleExternalEventSavePayload = {
  __typename: 'ToggleExternalEventSavePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  externalEvent: Maybe<ExternalEventAttendeeNode>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type ToggleIsActiveGroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['ID']['input'];
  isActive: Scalars['Boolean']['input'];
};

export type ToggleIsActiveGroupPayload = {
  __typename: 'ToggleIsActiveGroupPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  group: Maybe<GroupNode>;
};

export type UpdateCollagesAlbumInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateCollagesAlbumPayload = {
  __typename: 'UpdateCollagesAlbumPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  collagesAlbum: Maybe<CollagesAlbumNode>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateCollagesSetupInput = {
  addPhotosTeam?: InputMaybe<Scalars['ID']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createAlbumsTeam?: InputMaybe<Scalars['ID']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  isActive: Scalars['Boolean']['input'];
  viewPhotosTeam?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateCollagesSetupPayload = {
  __typename: 'UpdateCollagesSetupPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  collagesSetup: Maybe<CollagesSetupNode>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateCustomListInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type UpdateCustomListOptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type UpdateCustomListOptionPayload = {
  __typename: 'UpdateCustomListOptionPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  customListOption: Maybe<CustomListOptionNode>;
};

export type UpdateCustomListPayload = {
  __typename: 'UpdateCustomListPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  customList: Maybe<CustomListNode>;
};

export type UpdateCustomPagesInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** CustomPagesInstance ID */
  id: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Upload']['input']>;
  pageFormat?: InputMaybe<Scalars['String']['input']>;
  pageQuality?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['String']['input']>;
  pageType?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCustomPagesInstancePayload = {
  __typename: 'UpdateCustomPagesInstancePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  customPagesInstance: Maybe<CustomPagesInstanceNode>;
};

export type UpdateCustomPagesSetupInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createPagesTeam?: InputMaybe<Scalars['ID']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Intro messages to classmates to explain what to upload. */
  guide?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isActive: Scalars['Boolean']['input'];
  viewPagesTeam?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateCustomPagesSetupPayload = {
  __typename: 'UpdateCustomPagesSetupPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  customPagesSetup: Maybe<CustomPagesSetupNode>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateGroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  type?: InputMaybe<Scalars['ID']['input']>;
  whoCanCreateTeam?: InputMaybe<Scalars['ID']['input']>;
  year: Scalars['Int']['input'];
};

export type UpdateGroupPayload = {
  __typename: 'UpdateGroupPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  group: Maybe<GroupNode>;
};

export type UpdateMeInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  communicationLanguage?: InputMaybe<Scalars['String']['input']>;
  countryOfResidence?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneCountryCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMePayload = {
  __typename: 'UpdateMePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  refreshToken: Maybe<Scalars['String']['output']>;
  token: Maybe<Scalars['String']['output']>;
  user: Maybe<UserNode>;
};

export type UpdateModuleInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateModuleInstancePayload = {
  __typename: 'UpdateModuleInstancePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  moduleInstance: Maybe<ModuleInstanceNode>;
};

export type UpdatePasswordInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  oldPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export type UpdatePasswordPayload = {
  __typename: 'UpdatePasswordPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  user: Maybe<UserNode>;
};

export type UpdatePollInput = {
  allowComments?: InputMaybe<Scalars['Boolean']['input']>;
  allowRevote?: InputMaybe<Scalars['Boolean']['input']>;
  anonymousVotes?: InputMaybe<Scalars['Boolean']['input']>;
  answersAllowed: Scalars['Int']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deadline: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  owner: Scalars['String']['input'];
  privateResults?: InputMaybe<Scalars['Boolean']['input']>;
  question: Scalars['String']['input'];
  randomizeOptions?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdatePollOptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  image?: InputMaybe<Scalars['Upload']['input']>;
  text: Scalars['String']['input'];
};

export type UpdatePollOptionPayload = {
  __typename: 'UpdatePollOptionPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  pollOption: Maybe<PollOptionNode>;
};

export type UpdatePollPayload = {
  __typename: 'UpdatePollPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  poll: Maybe<PollNode>;
};

export type UpdatePostInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  owner: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type UpdatePostPayload = {
  __typename: 'UpdatePostPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  post: Maybe<PostNode>;
};

export type UpdatePrewordInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  isActive: Scalars['Boolean']['input'];
  text: Scalars['String']['input'];
};

export type UpdatePrewordInstancePayload = {
  __typename: 'UpdatePrewordInstancePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  prewordInstance: Maybe<PrewordInstanceNode>;
};

export type UpdateProfilePageAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  profilePageQuestionOption?: InputMaybe<Scalars['ID']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProfilePageAnswerPayload = {
  __typename: 'UpdateProfilePageAnswerPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  profilePageAnswer: Maybe<ProfilePageAnswerNode>;
};

export type UpdateProfilePageCommentInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type UpdateProfilePageCommentPayload = {
  __typename: 'UpdateProfilePageCommentPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  profilePageComment: Maybe<ProfilePageCommentNode>;
};

export type UpdateProfilePagePhotoCategoryInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProfilePagePhotoCategoryPayload = {
  __typename: 'UpdateProfilePagePhotoCategoryPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  profilePagePhotoCategory: Maybe<ProfilePagePhotoCategoryNode>;
};

export type UpdateProfilePagePhotoInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  image?: InputMaybe<Scalars['Upload']['input']>;
};

export type UpdateProfilePagePhotoPayload = {
  __typename: 'UpdateProfilePagePhotoPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  profilePagePhoto: Maybe<ProfilePagePhotoNode>;
};

export type UpdateProfilePageQuestionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type UpdateProfilePageQuestionOptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type UpdateProfilePageQuestionOptionPayload = {
  __typename: 'UpdateProfilePageQuestionOptionPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  profilePageQuestionOption: Maybe<ProfilePageQuestionOptionNode>;
};

export type UpdateProfilePageQuestionPayload = {
  __typename: 'UpdateProfilePageQuestionPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  profilePageQuestion: Maybe<ProfilePageQuestionNode>;
};

export type UpdateProfilePageSetupInput = {
  anonymousComments?: InputMaybe<Scalars['Boolean']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  isActive: Scalars['Boolean']['input'];
  maxChars: Scalars['Int']['input'];
  photoFormat?: InputMaybe<Scalars['String']['input']>;
  visibleComments?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateProfilePageSetupPayload = {
  __typename: 'UpdateProfilePageSetupPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  profilePageSetup: Maybe<ProfilePageSetupNode>;
};

export type UpdateQuotesInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  quote: Scalars['String']['input'];
};

export type UpdateQuotesInstancePayload = {
  __typename: 'UpdateQuotesInstancePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  quotesInstance: Maybe<QuotesInstanceNode>;
};

export type UpdateQuotesSetupInput = {
  anonymous?: InputMaybe<Scalars['Boolean']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  isActive: Scalars['Boolean']['input'];
  maxChars: Scalars['Int']['input'];
  public?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateQuotesSetupPayload = {
  __typename: 'UpdateQuotesSetupPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  quotesSetup: Maybe<QuotesSetupNode>;
};

export type UpdateRankingsAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  votee1UserGroup?: InputMaybe<Scalars['ID']['input']>;
  votee2UserGroup?: InputMaybe<Scalars['ID']['input']>;
  voteeListOption?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateRankingsAnswerPayload = {
  __typename: 'UpdateRankingsAnswerPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  rankingsAnswer: Maybe<RankingsAnswerNode>;
};

export type UpdateRankingsQuestionInput = {
  allowedVotes?: InputMaybe<Scalars['String']['input']>;
  answerType: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customList?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  onlyOppositeGender?: InputMaybe<Scalars['Boolean']['input']>;
  question: Scalars['String']['input'];
};

export type UpdateRankingsQuestionPayload = {
  __typename: 'UpdateRankingsQuestionPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  rankingsQuestion: Maybe<RankingsQuestionNode>;
};

export type UpdateRankingsSetupInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  isActive: Scalars['Boolean']['input'];
  resultsTeam?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateRankingsSetupPayload = {
  __typename: 'UpdateRankingsSetupPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  rankingsSetup: Maybe<RankingsSetupNode>;
};

export type UpdateReportsInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  editTeam?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  maxChars: Scalars['Int']['input'];
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
  viewTeam?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateReportsInstancePayload = {
  __typename: 'UpdateReportsInstancePayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  reportsInstance: Maybe<ReportsInstanceNode>;
};

export type UpdateReportsSetupInput = {
  allowImages?: InputMaybe<Scalars['Boolean']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  isActive: Scalars['Boolean']['input'];
};

export type UpdateReportsSetupPayload = {
  __typename: 'UpdateReportsSetupPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  reportsSetup: Maybe<ReportsSetupNode>;
};

export type UpdateTeamInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  color: Scalars['ID']['input'];
  icon: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateTeamPayload = {
  __typename: 'UpdateTeamPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  team: Maybe<TeamNode>;
};

export type UpdateUserGroupInput = {
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  defaultAvatarId?: InputMaybe<Scalars['ID']['input']>;
  groupId: Scalars['ID']['input'];
  role: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type UpdateUserGroupNotificationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['ID']['input'];
  isActive: Scalars['Boolean']['input'];
  notificationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type UpdateUserGroupNotificationPayload = {
  __typename: 'UpdateUserGroupNotificationPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  notification: Maybe<NotificationNode>;
};

export type UpdateUserGroupPayload = {
  __typename: 'UpdateUserGroupPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  userGroup: Maybe<UserGroupNode>;
};

export type UpdateUserTeamAdminInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  isAdmin: Scalars['Boolean']['input'];
  teamId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type UpdateUserTeamAdminPayload = {
  __typename: 'UpdateUserTeamAdminPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  userTeam: Maybe<UserTeamNode>;
};

export type UserExistsNode = {
  __typename: 'UserExistsNode';
  emailExists: Maybe<Scalars['Boolean']['output']>;
  phoneExists: Maybe<Scalars['Boolean']['output']>;
};

export type UserGroupNode = Node & {
  __typename: 'UserGroupNode';
  avatar: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  defaultAvatar: Maybe<DefaultAvatarNode>;
  group: Maybe<GroupNode>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isCoreTeamMember: Maybe<Scalars['Boolean']['output']>;
  isYearbookTeamMember: Maybe<Scalars['Boolean']['output']>;
  modified: Scalars['DateTime']['output'];
  notifications: Maybe<Array<Maybe<NotificationNode>>>;
  role: Maybe<UserType>;
  status: Maybe<Status>;
  user: UserNode;
};

export type UserGroupNodeConnection = {
  __typename: 'UserGroupNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserGroupNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UserGroupNode` and its cursor. */
export type UserGroupNodeEdge = {
  __typename: 'UserGroupNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<UserGroupNode>;
};

export type UserNode = Node & {
  __typename: 'UserNode';
  avatar: Maybe<Scalars['String']['output']>;
  brandLook: Maybe<BrandLookSelectionNode>;
  communicationLanguage: Maybe<Language>;
  countryOfResidence: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  dataPolicyAcceptances: Maybe<Array<Maybe<DataPolicyAcceptanceNode>>>;
  email: Scalars['String']['output'];
  emailValidated: Scalars['Boolean']['output'];
  externalEvents: ExternalEventAttendeeNodeConnection;
  externalTokens: Maybe<ExternalTokensNode>;
  firstName: Scalars['String']['output'];
  gender: Maybe<UsersUserGenderChoices>;
  groups: Maybe<Array<Maybe<GroupNode>>>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  inviteCode: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  isAgent: Scalars['Boolean']['output'];
  isImported: Maybe<Scalars['Boolean']['output']>;
  isSuperuser: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  leaderboardPosition: Maybe<Scalars['Int']['output']>;
  modified: Scalars['DateTime']['output'];
  optIn: Maybe<Scalars['Boolean']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  phoneCountryCode: Maybe<Scalars['String']['output']>;
  refferralsNum: Maybe<Scalars['Int']['output']>;
  teams: Maybe<Array<Maybe<TeamNode>>>;
  termsAcceptances: Maybe<Array<Maybe<TermsAcceptanceNode>>>;
  userGroups: Maybe<Array<Maybe<UsersGroupNode>>>;
};

export type UserNodeexternalEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type UserNodeConnection = {
  __typename: 'UserNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `UserNode` and its cursor. */
export type UserNodeEdge = {
  __typename: 'UserNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<UserNode>;
};

export type UserTeamNode = Node & {
  __typename: 'UserTeamNode';
  avatar: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  defaultAvatar: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isAdmin: Scalars['Boolean']['output'];
  modified: Scalars['DateTime']['output'];
  status: Maybe<Status>;
  team: Maybe<TeamNode>;
  user: UserNode;
};

/** An enumeration. */
export enum UserType {
  STUDENT = 'STUDENT',
  TEACHER = 'TEACHER',
}

export type UsersGroupNode = {
  __typename: 'UsersGroupNode';
  avatar: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  institute: InstituteNode;
  isActive: Maybe<Scalars['Boolean']['output']>;
  members: Maybe<Array<Maybe<GroupMemberNode>>>;
  name: Maybe<Scalars['String']['output']>;
  role: Maybe<UserType>;
  status: Maybe<Status>;
  teams: Maybe<Array<Maybe<TeamNode>>>;
  type: Maybe<GraduationTypeNode>;
  users: Maybe<Array<Maybe<UserGroupNode>>>;
  year: Maybe<Scalars['Int']['output']>;
};

/** An enumeration. */
export enum UsersUserGenderChoices {
  /** Female */
  FEMALE = 'FEMALE',
  /** Male */
  MALE = 'MALE',
  /** Sonstiges */
  OTHER = 'OTHER',
}

export enum VariantTypeEnum {
  LARGE = 'LARGE',
  SMALL = 'SMALL',
}

export type VerifyInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type VerifyPayload = {
  __typename: 'VerifyPayload';
  clientMutationId: Maybe<Scalars['String']['output']>;
  payload: Scalars['GenericScalar']['output'];
};

export type YearbookStatisticsNode = {
  __typename: 'YearbookStatisticsNode';
  contentModules: Maybe<Scalars['Int']['output']>;
  estimatedPages: Maybe<Scalars['Int']['output']>;
  imagesUploaded: Maybe<Scalars['Int']['output']>;
  lastDeadline: Maybe<Scalars['DateTime']['output']>;
  profilePagesFilledIn: Maybe<Scalars['Float']['output']>;
  usersWithQuote: Maybe<Scalars['Float']['output']>;
  wordCount: Maybe<Scalars['Int']['output']>;
};

export type YearlyAverage = {
  __typename: 'YearlyAverage';
  average: Maybe<Scalars['Float']['output']>;
  groups: Maybe<Scalars['Int']['output']>;
  instances: Maybe<Scalars['Int']['output']>;
  year: Maybe<Scalars['Int']['output']>;
};

export type InstituteActivityTypeFragment = {
  __typename: 'InstituteActivityType';
  year: number | null;
  status: InstituteActivityTypeEnum | null;
};

export type AdminSearchUserFragment = {
  __typename: 'SearchUserType';
  id: string;
  firstName: string;
  lastName: string;
  phone: string | null;
  email: string;
  avatar: string | null;
  phoneCountryCode: string | null;
  groups: Array<{
    __typename: 'SearchGroupType';
    id: string;
    name: string;
    graduationYear: string;
    type: string | null;
    avatar: string | null;
    institute: {
      __typename: 'SearchInstituteType';
      id: string;
      name: string;
      internalId: string;
      country: string;
      city: string | null;
      zip: string | null;
      type: string | null;
    };
  } | null> | null;
};

export type AdminSearchGroupFragment = {
  __typename: 'SearchGroupType';
  id: string;
  name: string;
  graduationYear: string;
  type: string | null;
  avatar: string | null;
  institute: {
    __typename: 'SearchInstituteType';
    id: string;
    name: string;
    internalId: string;
    country: string;
    city: string | null;
    zip: string | null;
    type: string | null;
  };
};

export type AdminSearchInstituteFragment = {
  __typename: 'SearchInstituteType';
  id: string;
  name: string;
  internalId: string;
  country: string;
  city: string | null;
  zip: string | null;
  type: string | null;
};

export type AdminSearchQueryVariables = Exact<{
  searchString: Scalars['String']['input'];
}>;

export type AdminSearchQuery = {
  __typename: 'Query';
  adminSearchResults: {
    __typename: 'SearchStructure3';
    users: Array<{
      __typename: 'SearchUserType';
      id: string;
      firstName: string;
      lastName: string;
      phone: string | null;
      email: string;
      avatar: string | null;
      phoneCountryCode: string | null;
      groups: Array<{
        __typename: 'SearchGroupType';
        id: string;
        name: string;
        graduationYear: string;
        type: string | null;
        avatar: string | null;
        institute: {
          __typename: 'SearchInstituteType';
          id: string;
          name: string;
          internalId: string;
          country: string;
          city: string | null;
          zip: string | null;
          type: string | null;
        };
      } | null> | null;
    } | null>;
    groups: Array<{
      __typename: 'SearchGroupType';
      id: string;
      name: string;
      graduationYear: string;
      type: string | null;
      avatar: string | null;
      institute: {
        __typename: 'SearchInstituteType';
        id: string;
        name: string;
        internalId: string;
        country: string;
        city: string | null;
        zip: string | null;
        type: string | null;
      };
    } | null>;
    institutes: Array<{
      __typename: 'SearchInstituteType';
      id: string;
      name: string;
      internalId: string;
      country: string;
      city: string | null;
      zip: string | null;
      type: string | null;
    } | null>;
  } | null;
};

export type AdminGroupMemberFragment = {
  __typename: 'GroupMemberNode';
  id: string;
  firstName: string | null;
  lastName: string | null;
  avatar: string | null;
  email: string | null;
  phone: string | null;
  phoneCountryCode: string | null;
  role: GroupsUserGroupRoleChoices;
  status: GroupsUserGroupStatusChoices;
  user: { __typename: 'UserNode'; id: string };
  teams: Array<{
    __typename: 'TeamNode';
    id: string;
    name: string;
    avatar: string | null;
    icon: { __typename: 'IconNode'; icon: string } | null;
    color: { __typename: 'ColorNode'; hex: string } | null;
    group: { __typename: 'GroupNode'; id: string };
  } | null> | null;
};

export type AdminGroupInstituteFragment = {
  __typename: 'InstituteNode';
  name: string;
  id: string;
  internalId: string;
  country: InstitutesInstituteCountryChoices;
  street: string | null;
  zip: string | null;
  city: string | null;
  type: {
    __typename: 'InstituteTypeNode';
    name: string;
    graduationTypes: Array<{
      __typename: 'GraduationTypeNode';
      id: string;
      name: string;
    } | null> | null;
  };
};

export type AdminGroupFragment = {
  __typename: 'GroupNode';
  id: string;
  name: string;
  avatar: string | null;
  created: any;
  token: string | null;
  isActive: boolean | null;
  isImported: boolean | null;
  year: number;
  type: { __typename: 'GraduationTypeNode'; id: string; name: string };
  allMembers: Array<{
    __typename: 'GroupMemberNode';
    id: string;
    firstName: string | null;
    lastName: string | null;
    avatar: string | null;
    email: string | null;
    phone: string | null;
    phoneCountryCode: string | null;
    role: GroupsUserGroupRoleChoices;
    status: GroupsUserGroupStatusChoices;
    user: { __typename: 'UserNode'; id: string };
    teams: Array<{
      __typename: 'TeamNode';
      id: string;
      name: string;
      avatar: string | null;
      icon: { __typename: 'IconNode'; icon: string } | null;
      color: { __typename: 'ColorNode'; hex: string } | null;
      group: { __typename: 'GroupNode'; id: string };
    } | null> | null;
  } | null>;
  institute: {
    __typename: 'InstituteNode';
    name: string;
    id: string;
    internalId: string;
    country: InstitutesInstituteCountryChoices;
    street: string | null;
    zip: string | null;
    city: string | null;
    type: {
      __typename: 'InstituteTypeNode';
      name: string;
      graduationTypes: Array<{
        __typename: 'GraduationTypeNode';
        id: string;
        name: string;
      } | null> | null;
    };
  };
};

export type AdminGetGroupQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminGetGroupQuery = {
  __typename: 'Query';
  group: {
    __typename: 'GroupNode';
    id: string;
    name: string;
    avatar: string | null;
    created: any;
    token: string | null;
    isActive: boolean | null;
    isImported: boolean | null;
    year: number;
    type: { __typename: 'GraduationTypeNode'; id: string; name: string };
    allMembers: Array<{
      __typename: 'GroupMemberNode';
      id: string;
      firstName: string | null;
      lastName: string | null;
      avatar: string | null;
      email: string | null;
      phone: string | null;
      phoneCountryCode: string | null;
      role: GroupsUserGroupRoleChoices;
      status: GroupsUserGroupStatusChoices;
      user: { __typename: 'UserNode'; id: string };
      teams: Array<{
        __typename: 'TeamNode';
        id: string;
        name: string;
        avatar: string | null;
        icon: { __typename: 'IconNode'; icon: string } | null;
        color: { __typename: 'ColorNode'; hex: string } | null;
        group: { __typename: 'GroupNode'; id: string };
      } | null> | null;
    } | null>;
    institute: {
      __typename: 'InstituteNode';
      name: string;
      id: string;
      internalId: string;
      country: InstitutesInstituteCountryChoices;
      street: string | null;
      zip: string | null;
      city: string | null;
      type: {
        __typename: 'InstituteTypeNode';
        name: string;
        graduationTypes: Array<{
          __typename: 'GraduationTypeNode';
          id: string;
          name: string;
        } | null> | null;
      };
    };
  } | null;
};

export type AdminToggleGroupActiveMutationVariables = Exact<{
  input: ToggleIsActiveGroupInput;
}>;

export type AdminToggleGroupActiveMutation = {
  __typename: 'Mutation';
  toggleIsActiveGroup: {
    __typename: 'ToggleIsActiveGroupPayload';
    group: {
      __typename: 'GroupNode';
      id: string;
      name: string;
      avatar: string | null;
      created: any;
      token: string | null;
      isActive: boolean | null;
      isImported: boolean | null;
      year: number;
      type: { __typename: 'GraduationTypeNode'; id: string; name: string };
      allMembers: Array<{
        __typename: 'GroupMemberNode';
        id: string;
        firstName: string | null;
        lastName: string | null;
        avatar: string | null;
        email: string | null;
        phone: string | null;
        phoneCountryCode: string | null;
        role: GroupsUserGroupRoleChoices;
        status: GroupsUserGroupStatusChoices;
        user: { __typename: 'UserNode'; id: string };
        teams: Array<{
          __typename: 'TeamNode';
          id: string;
          name: string;
          avatar: string | null;
          icon: { __typename: 'IconNode'; icon: string } | null;
          color: { __typename: 'ColorNode'; hex: string } | null;
          group: { __typename: 'GroupNode'; id: string };
        } | null> | null;
      } | null>;
      institute: {
        __typename: 'InstituteNode';
        name: string;
        id: string;
        internalId: string;
        country: InstitutesInstituteCountryChoices;
        street: string | null;
        zip: string | null;
        city: string | null;
        type: {
          __typename: 'InstituteTypeNode';
          name: string;
          graduationTypes: Array<{
            __typename: 'GraduationTypeNode';
            id: string;
            name: string;
          } | null> | null;
        };
      };
    } | null;
  } | null;
};

export type AdminMoveGroupToInstituteMutationVariables = Exact<{
  input: MoveGroupToInstituteInput;
}>;

export type AdminMoveGroupToInstituteMutation = {
  __typename: 'Mutation';
  moveGroupToInstitute: {
    __typename: 'MoveGroupToInstitutePayload';
    group: {
      __typename: 'GroupNode';
      id: string;
      name: string;
      avatar: string | null;
      created: any;
      token: string | null;
      isActive: boolean | null;
      isImported: boolean | null;
      year: number;
      type: { __typename: 'GraduationTypeNode'; id: string; name: string };
      allMembers: Array<{
        __typename: 'GroupMemberNode';
        id: string;
        firstName: string | null;
        lastName: string | null;
        avatar: string | null;
        email: string | null;
        phone: string | null;
        phoneCountryCode: string | null;
        role: GroupsUserGroupRoleChoices;
        status: GroupsUserGroupStatusChoices;
        user: { __typename: 'UserNode'; id: string };
        teams: Array<{
          __typename: 'TeamNode';
          id: string;
          name: string;
          avatar: string | null;
          icon: { __typename: 'IconNode'; icon: string } | null;
          color: { __typename: 'ColorNode'; hex: string } | null;
          group: { __typename: 'GroupNode'; id: string };
        } | null> | null;
      } | null>;
      institute: {
        __typename: 'InstituteNode';
        name: string;
        id: string;
        internalId: string;
        country: InstitutesInstituteCountryChoices;
        street: string | null;
        zip: string | null;
        city: string | null;
        type: {
          __typename: 'InstituteTypeNode';
          name: string;
          graduationTypes: Array<{
            __typename: 'GraduationTypeNode';
            id: string;
            name: string;
          } | null> | null;
        };
      };
    } | null;
  } | null;
};

export type AdminUserTeamsFragment = {
  __typename: 'TeamNode';
  id: string;
  name: string;
  avatar: string | null;
  icon: { __typename: 'IconNode'; icon: string } | null;
  color: { __typename: 'ColorNode'; hex: string } | null;
  group: { __typename: 'GroupNode'; id: string };
};

export type AdminUserGroupsFragment = {
  __typename: 'GroupNode';
  id: string;
  name: string;
  avatar: string | null;
  year: number;
  icon: { __typename: 'GroupIconNode'; icon: string | null } | null;
  institute: {
    __typename: 'InstituteNode';
    id: string;
    name: string;
    internalId: string;
    country: InstitutesInstituteCountryChoices;
  };
  type: { __typename: 'GraduationTypeNode'; name: string };
  users: Array<{
    __typename: 'UserGroupNode';
    id: string;
    status: Status | null;
    user: { __typename: 'UserNode'; id: string };
  } | null> | null;
  teams: Array<{
    __typename: 'TeamNode';
    id: string;
    name: string;
  } | null> | null;
};

export type AdminUserFragment = {
  __typename: 'UserNode';
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneCountryCode: string | null;
  phone: string | null;
  gender: UsersUserGenderChoices | null;
  isActive: boolean;
  optIn: boolean | null;
  created: any;
  isImported: boolean | null;
  isAgent: boolean;
  isSuperuser: boolean;
  avatar: string | null;
  groups: Array<{
    __typename: 'GroupNode';
    id: string;
    name: string;
    avatar: string | null;
    year: number;
    icon: { __typename: 'GroupIconNode'; icon: string | null } | null;
    institute: {
      __typename: 'InstituteNode';
      id: string;
      name: string;
      internalId: string;
      country: InstitutesInstituteCountryChoices;
    };
    type: { __typename: 'GraduationTypeNode'; name: string };
    users: Array<{
      __typename: 'UserGroupNode';
      id: string;
      status: Status | null;
      user: { __typename: 'UserNode'; id: string };
    } | null> | null;
    teams: Array<{
      __typename: 'TeamNode';
      id: string;
      name: string;
    } | null> | null;
  } | null> | null;
  teams: Array<{
    __typename: 'TeamNode';
    id: string;
    name: string;
    avatar: string | null;
    icon: { __typename: 'IconNode'; icon: string } | null;
    color: { __typename: 'ColorNode'; hex: string } | null;
    group: { __typename: 'GroupNode'; id: string };
  } | null> | null;
};

export type AdminGetUserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminGetUserQuery = {
  __typename: 'Query';
  user: {
    __typename: 'UserNode';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneCountryCode: string | null;
    phone: string | null;
    gender: UsersUserGenderChoices | null;
    isActive: boolean;
    optIn: boolean | null;
    created: any;
    isImported: boolean | null;
    isAgent: boolean;
    isSuperuser: boolean;
    avatar: string | null;
    groups: Array<{
      __typename: 'GroupNode';
      id: string;
      name: string;
      avatar: string | null;
      year: number;
      icon: { __typename: 'GroupIconNode'; icon: string | null } | null;
      institute: {
        __typename: 'InstituteNode';
        id: string;
        name: string;
        internalId: string;
        country: InstitutesInstituteCountryChoices;
      };
      type: { __typename: 'GraduationTypeNode'; name: string };
      users: Array<{
        __typename: 'UserGroupNode';
        id: string;
        status: Status | null;
        user: { __typename: 'UserNode'; id: string };
      } | null> | null;
      teams: Array<{
        __typename: 'TeamNode';
        id: string;
        name: string;
      } | null> | null;
    } | null> | null;
    teams: Array<{
      __typename: 'TeamNode';
      id: string;
      name: string;
      avatar: string | null;
      icon: { __typename: 'IconNode'; icon: string } | null;
      color: { __typename: 'ColorNode'; hex: string } | null;
      group: { __typename: 'GroupNode'; id: string };
    } | null> | null;
  } | null;
};

export type AdminGetGradooStaffQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  searchNameEmail?: InputMaybe<Scalars['String']['input']>;
}>;

export type AdminGetGradooStaffQuery = {
  __typename: 'Query';
  users: {
    __typename: 'UserNodeConnection';
    totalCount: number;
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'UserNodeEdge';
      node: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        phoneCountryCode: string | null;
        phone: string | null;
        gender: UsersUserGenderChoices | null;
        isActive: boolean;
        optIn: boolean | null;
        created: any;
        isImported: boolean | null;
        isAgent: boolean;
        isSuperuser: boolean;
        avatar: string | null;
        groups: Array<{
          __typename: 'GroupNode';
          id: string;
          name: string;
          avatar: string | null;
          year: number;
          icon: { __typename: 'GroupIconNode'; icon: string | null } | null;
          institute: {
            __typename: 'InstituteNode';
            id: string;
            name: string;
            internalId: string;
            country: InstitutesInstituteCountryChoices;
          };
          type: { __typename: 'GraduationTypeNode'; name: string };
          users: Array<{
            __typename: 'UserGroupNode';
            id: string;
            status: Status | null;
            user: { __typename: 'UserNode'; id: string };
          } | null> | null;
          teams: Array<{
            __typename: 'TeamNode';
            id: string;
            name: string;
          } | null> | null;
        } | null> | null;
        teams: Array<{
          __typename: 'TeamNode';
          id: string;
          name: string;
          avatar: string | null;
          icon: { __typename: 'IconNode'; icon: string } | null;
          color: { __typename: 'ColorNode'; hex: string } | null;
          group: { __typename: 'GroupNode'; id: string };
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type AdminGetUsersQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  isAgent?: InputMaybe<Scalars['Boolean']['input']>;
  isSuperuser?: InputMaybe<Scalars['Boolean']['input']>;
  isStaff?: InputMaybe<Scalars['Boolean']['input']>;
  searchNameEmail?: InputMaybe<Scalars['String']['input']>;
}>;

export type AdminGetUsersQuery = {
  __typename: 'Query';
  users: {
    __typename: 'UserNodeConnection';
    totalCount: number;
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'UserNodeEdge';
      node: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        phoneCountryCode: string | null;
        phone: string | null;
        gender: UsersUserGenderChoices | null;
        isActive: boolean;
        optIn: boolean | null;
        created: any;
        isImported: boolean | null;
        isAgent: boolean;
        isSuperuser: boolean;
        avatar: string | null;
        groups: Array<{
          __typename: 'GroupNode';
          id: string;
          name: string;
          avatar: string | null;
          year: number;
          icon: { __typename: 'GroupIconNode'; icon: string | null } | null;
          institute: {
            __typename: 'InstituteNode';
            id: string;
            name: string;
            internalId: string;
            country: InstitutesInstituteCountryChoices;
          };
          type: { __typename: 'GraduationTypeNode'; name: string };
          users: Array<{
            __typename: 'UserGroupNode';
            id: string;
            status: Status | null;
            user: { __typename: 'UserNode'; id: string };
          } | null> | null;
          teams: Array<{
            __typename: 'TeamNode';
            id: string;
            name: string;
          } | null> | null;
        } | null> | null;
        teams: Array<{
          __typename: 'TeamNode';
          id: string;
          name: string;
          avatar: string | null;
          icon: { __typename: 'IconNode'; icon: string } | null;
          color: { __typename: 'ColorNode'; hex: string } | null;
          group: { __typename: 'GroupNode'; id: string };
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type AdminUpdateUserMutationVariables = Exact<{
  input: AdminUpdateUserInput;
}>;

export type AdminUpdateUserMutation = {
  __typename: 'Mutation';
  adminUpdateUser: {
    __typename: 'AdminUpdateUserPayload';
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      phoneCountryCode: string | null;
      phone: string | null;
      gender: UsersUserGenderChoices | null;
      isActive: boolean;
      optIn: boolean | null;
      created: any;
      isImported: boolean | null;
      isAgent: boolean;
      isSuperuser: boolean;
      avatar: string | null;
      groups: Array<{
        __typename: 'GroupNode';
        id: string;
        name: string;
        avatar: string | null;
        year: number;
        icon: { __typename: 'GroupIconNode'; icon: string | null } | null;
        institute: {
          __typename: 'InstituteNode';
          id: string;
          name: string;
          internalId: string;
          country: InstitutesInstituteCountryChoices;
        };
        type: { __typename: 'GraduationTypeNode'; name: string };
        users: Array<{
          __typename: 'UserGroupNode';
          id: string;
          status: Status | null;
          user: { __typename: 'UserNode'; id: string };
        } | null> | null;
        teams: Array<{
          __typename: 'TeamNode';
          id: string;
          name: string;
        } | null> | null;
      } | null> | null;
      teams: Array<{
        __typename: 'TeamNode';
        id: string;
        name: string;
        avatar: string | null;
        icon: { __typename: 'IconNode'; icon: string } | null;
        color: { __typename: 'ColorNode'; hex: string } | null;
        group: { __typename: 'GroupNode'; id: string };
      } | null> | null;
    } | null;
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
  } | null;
};

export type AdminUpgradeUserMutationVariables = Exact<{
  input: AdminUpgradeUserInput;
}>;

export type AdminUpgradeUserMutation = {
  __typename: 'Mutation';
  adminUpgradeUser: {
    __typename: 'AdminUpgradeUserPayload';
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      phoneCountryCode: string | null;
      phone: string | null;
      gender: UsersUserGenderChoices | null;
      isActive: boolean;
      optIn: boolean | null;
      created: any;
      isImported: boolean | null;
      isAgent: boolean;
      isSuperuser: boolean;
      avatar: string | null;
      groups: Array<{
        __typename: 'GroupNode';
        id: string;
        name: string;
        avatar: string | null;
        year: number;
        icon: { __typename: 'GroupIconNode'; icon: string | null } | null;
        institute: {
          __typename: 'InstituteNode';
          id: string;
          name: string;
          internalId: string;
          country: InstitutesInstituteCountryChoices;
        };
        type: { __typename: 'GraduationTypeNode'; name: string };
        users: Array<{
          __typename: 'UserGroupNode';
          id: string;
          status: Status | null;
          user: { __typename: 'UserNode'; id: string };
        } | null> | null;
        teams: Array<{
          __typename: 'TeamNode';
          id: string;
          name: string;
        } | null> | null;
      } | null> | null;
      teams: Array<{
        __typename: 'TeamNode';
        id: string;
        name: string;
        avatar: string | null;
        icon: { __typename: 'IconNode'; icon: string } | null;
        color: { __typename: 'ColorNode'; hex: string } | null;
        group: { __typename: 'GroupNode'; id: string };
      } | null> | null;
    } | null;
  } | null;
};

export type AdminDowngradeUserMutationVariables = Exact<{
  input: AdminDowngradeUserInput;
}>;

export type AdminDowngradeUserMutation = {
  __typename: 'Mutation';
  adminDowngradeUser: {
    __typename: 'AdminDowngradeUserPayload';
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      phoneCountryCode: string | null;
      phone: string | null;
      gender: UsersUserGenderChoices | null;
      isActive: boolean;
      optIn: boolean | null;
      created: any;
      isImported: boolean | null;
      isAgent: boolean;
      isSuperuser: boolean;
      avatar: string | null;
      groups: Array<{
        __typename: 'GroupNode';
        id: string;
        name: string;
        avatar: string | null;
        year: number;
        icon: { __typename: 'GroupIconNode'; icon: string | null } | null;
        institute: {
          __typename: 'InstituteNode';
          id: string;
          name: string;
          internalId: string;
          country: InstitutesInstituteCountryChoices;
        };
        type: { __typename: 'GraduationTypeNode'; name: string };
        users: Array<{
          __typename: 'UserGroupNode';
          id: string;
          status: Status | null;
          user: { __typename: 'UserNode'; id: string };
        } | null> | null;
        teams: Array<{
          __typename: 'TeamNode';
          id: string;
          name: string;
        } | null> | null;
      } | null> | null;
      teams: Array<{
        __typename: 'TeamNode';
        id: string;
        name: string;
        avatar: string | null;
        icon: { __typename: 'IconNode'; icon: string } | null;
        color: { __typename: 'ColorNode'; hex: string } | null;
        group: { __typename: 'GroupNode'; id: string };
      } | null> | null;
    } | null;
  } | null;
};

export type AdminClearUserFlagsMutationVariables = Exact<{
  input: AdminClearUserFlagsInput;
}>;

export type AdminClearUserFlagsMutation = {
  __typename: 'Mutation';
  adminClearUserFlags: {
    __typename: 'AdminClearUserFlagsPayload';
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      phoneCountryCode: string | null;
      phone: string | null;
      gender: UsersUserGenderChoices | null;
      isActive: boolean;
      optIn: boolean | null;
      created: any;
      isImported: boolean | null;
      isAgent: boolean;
      isSuperuser: boolean;
      avatar: string | null;
      groups: Array<{
        __typename: 'GroupNode';
        id: string;
        name: string;
        avatar: string | null;
        year: number;
        icon: { __typename: 'GroupIconNode'; icon: string | null } | null;
        institute: {
          __typename: 'InstituteNode';
          id: string;
          name: string;
          internalId: string;
          country: InstitutesInstituteCountryChoices;
        };
        type: { __typename: 'GraduationTypeNode'; name: string };
        users: Array<{
          __typename: 'UserGroupNode';
          id: string;
          status: Status | null;
          user: { __typename: 'UserNode'; id: string };
        } | null> | null;
        teams: Array<{
          __typename: 'TeamNode';
          id: string;
          name: string;
        } | null> | null;
      } | null> | null;
      teams: Array<{
        __typename: 'TeamNode';
        id: string;
        name: string;
        avatar: string | null;
        icon: { __typename: 'IconNode'; icon: string } | null;
        color: { __typename: 'ColorNode'; hex: string } | null;
        group: { __typename: 'GroupNode'; id: string };
      } | null> | null;
    } | null;
  } | null;
};

export type AdminForgetPasswordLinkMutationVariables = Exact<{
  input: AdminForgetPasswordLinkInput;
}>;

export type AdminForgetPasswordLinkMutation = {
  __typename: 'Mutation';
  adminForgetPasswordLink: {
    __typename: 'AdminForgetPasswordLinkPayload';
    forgetPasswordLink: string | null;
  } | null;
};

export type AdminAddUserToTeamMutationVariables = Exact<{
  input: AdminAddUserToTeamInput;
}>;

export type AdminAddUserToTeamMutation = {
  __typename: 'Mutation';
  adminAddUserToTeam: {
    __typename: 'AdminAddUserToTeamPayload';
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      phoneCountryCode: string | null;
      phone: string | null;
      gender: UsersUserGenderChoices | null;
      isActive: boolean;
      optIn: boolean | null;
      created: any;
      isImported: boolean | null;
      isAgent: boolean;
      isSuperuser: boolean;
      avatar: string | null;
      groups: Array<{
        __typename: 'GroupNode';
        id: string;
        name: string;
        avatar: string | null;
        year: number;
        icon: { __typename: 'GroupIconNode'; icon: string | null } | null;
        institute: {
          __typename: 'InstituteNode';
          id: string;
          name: string;
          internalId: string;
          country: InstitutesInstituteCountryChoices;
        };
        type: { __typename: 'GraduationTypeNode'; name: string };
        users: Array<{
          __typename: 'UserGroupNode';
          id: string;
          status: Status | null;
          user: { __typename: 'UserNode'; id: string };
        } | null> | null;
        teams: Array<{
          __typename: 'TeamNode';
          id: string;
          name: string;
        } | null> | null;
      } | null> | null;
      teams: Array<{
        __typename: 'TeamNode';
        id: string;
        name: string;
        avatar: string | null;
        icon: { __typename: 'IconNode'; icon: string } | null;
        color: { __typename: 'ColorNode'; hex: string } | null;
        group: { __typename: 'GroupNode'; id: string };
      } | null> | null;
    } | null;
  } | null;
};

export type AdminDeleteUserMutationVariables = Exact<{
  input: AdminDeleteUserInput;
}>;

export type AdminDeleteUserMutation = {
  __typename: 'Mutation';
  adminDeleteUser: {
    __typename: 'AdminDeleteUserPayload';
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      phoneCountryCode: string | null;
      phone: string | null;
      gender: UsersUserGenderChoices | null;
      isActive: boolean;
      optIn: boolean | null;
      created: any;
      isImported: boolean | null;
      isAgent: boolean;
      isSuperuser: boolean;
      avatar: string | null;
      groups: Array<{
        __typename: 'GroupNode';
        id: string;
        name: string;
        avatar: string | null;
        year: number;
        icon: { __typename: 'GroupIconNode'; icon: string | null } | null;
        institute: {
          __typename: 'InstituteNode';
          id: string;
          name: string;
          internalId: string;
          country: InstitutesInstituteCountryChoices;
        };
        type: { __typename: 'GraduationTypeNode'; name: string };
        users: Array<{
          __typename: 'UserGroupNode';
          id: string;
          status: Status | null;
          user: { __typename: 'UserNode'; id: string };
        } | null> | null;
        teams: Array<{
          __typename: 'TeamNode';
          id: string;
          name: string;
        } | null> | null;
      } | null> | null;
      teams: Array<{
        __typename: 'TeamNode';
        id: string;
        name: string;
        avatar: string | null;
        icon: { __typename: 'IconNode'; icon: string } | null;
        color: { __typename: 'ColorNode'; hex: string } | null;
        group: { __typename: 'GroupNode'; id: string };
      } | null> | null;
    } | null;
  } | null;
};

export type AdminInstituteGroupFragment = {
  __typename: 'GroupNode';
  id: string;
  name: string;
  year: number;
  avatar: string | null;
  type: { __typename: 'GraduationTypeNode'; name: string };
  icon: { __typename: 'GroupIconNode'; icon: string | null } | null;
  users: Array<{ __typename: 'UserGroupNode'; id: string } | null> | null;
  institute: {
    __typename: 'InstituteNode';
    country: InstitutesInstituteCountryChoices;
    internalId: string;
  };
};

export type AdminInstituteFragment = {
  __typename: 'InstituteNode';
  id: string;
  name: string;
  street: string | null;
  internalId: string;
  city: string | null;
  zip: string | null;
  country: InstitutesInstituteCountryChoices;
  website: string | null;
  type: { __typename: 'InstituteTypeNode'; id: string; name: string };
  marketProfile: {
    __typename: 'MarketProfileNode';
    id: string;
    name: string;
  } | null;
  groups: Array<{
    __typename: 'GroupNode';
    id: string;
    name: string;
    year: number;
    avatar: string | null;
    type: { __typename: 'GraduationTypeNode'; name: string };
    icon: { __typename: 'GroupIconNode'; icon: string | null } | null;
    users: Array<{ __typename: 'UserGroupNode'; id: string } | null> | null;
    institute: {
      __typename: 'InstituteNode';
      country: InstitutesInstituteCountryChoices;
      internalId: string;
    };
  } | null> | null;
  instituteActivity: Array<{
    __typename: 'InstituteActivityType';
    year: number | null;
    status: InstituteActivityTypeEnum | null;
  } | null> | null;
};

export type AdminGetInstituteQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminGetInstituteQuery = {
  __typename: 'Query';
  institute: {
    __typename: 'InstituteNode';
    id: string;
    name: string;
    street: string | null;
    internalId: string;
    city: string | null;
    zip: string | null;
    country: InstitutesInstituteCountryChoices;
    website: string | null;
    type: { __typename: 'InstituteTypeNode'; id: string; name: string };
    marketProfile: {
      __typename: 'MarketProfileNode';
      id: string;
      name: string;
    } | null;
    groups: Array<{
      __typename: 'GroupNode';
      id: string;
      name: string;
      year: number;
      avatar: string | null;
      type: { __typename: 'GraduationTypeNode'; name: string };
      icon: { __typename: 'GroupIconNode'; icon: string | null } | null;
      users: Array<{ __typename: 'UserGroupNode'; id: string } | null> | null;
      institute: {
        __typename: 'InstituteNode';
        country: InstitutesInstituteCountryChoices;
        internalId: string;
      };
    } | null> | null;
    instituteActivity: Array<{
      __typename: 'InstituteActivityType';
      year: number | null;
      status: InstituteActivityTypeEnum | null;
    } | null> | null;
  } | null;
};

export type AdminUpdateInstituteMutationVariables = Exact<{
  input: AdminUpdateInstituteInput;
}>;

export type AdminUpdateInstituteMutation = {
  __typename: 'Mutation';
  adminUpdateInstitute: {
    __typename: 'AdminUpdateInstitutePayload';
    institute: {
      __typename: 'InstituteNode';
      id: string;
      name: string;
      street: string | null;
      internalId: string;
      city: string | null;
      zip: string | null;
      country: InstitutesInstituteCountryChoices;
      website: string | null;
      type: { __typename: 'InstituteTypeNode'; id: string; name: string };
      marketProfile: {
        __typename: 'MarketProfileNode';
        id: string;
        name: string;
      } | null;
      groups: Array<{
        __typename: 'GroupNode';
        id: string;
        name: string;
        year: number;
        avatar: string | null;
        type: { __typename: 'GraduationTypeNode'; name: string };
        icon: { __typename: 'GroupIconNode'; icon: string | null } | null;
        users: Array<{ __typename: 'UserGroupNode'; id: string } | null> | null;
        institute: {
          __typename: 'InstituteNode';
          country: InstitutesInstituteCountryChoices;
          internalId: string;
        };
      } | null> | null;
      instituteActivity: Array<{
        __typename: 'InstituteActivityType';
        year: number | null;
        status: InstituteActivityTypeEnum | null;
      } | null> | null;
    } | null;
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
  } | null;
};

export type InstituteTargetingFragment = {
  __typename: 'InstituteTargetingNode';
  id: string;
  location: any | null;
  activity: InstituteTargetingActivityEnum | null;
};

export type InstitutesTargetingQueryVariables = Exact<{
  targetingFilters?: InputMaybe<
    Array<InputMaybe<TargetingFilterInput>> | InputMaybe<TargetingFilterInput>
  >;
}>;

export type InstitutesTargetingQuery = {
  __typename: 'Query';
  institutesTargeting: {
    __typename: 'TargetingNode';
    usersCount: number | null;
    activeGroupsCount: number | null;
    inactiveGroupsCount: number | null;
    institutes: Array<{
      __typename: 'InstituteTargetingNode';
      id: string;
      location: any | null;
      activity: InstituteTargetingActivityEnum | null;
    } | null> | null;
  } | null;
};

export type AuthUserFragment = {
  __typename: 'UserNode';
  email: string;
  phoneCountryCode: string | null;
  phone: string | null;
  emailValidated: boolean;
  communicationLanguage: Language | null;
  isSuperuser: boolean;
  countryOfResidence: string | null;
  inviteCode: string | null;
  id: string;
  firstName: string;
  lastName: string;
  gender: UsersUserGenderChoices | null;
  externalTokens: {
    __typename: 'ExternalTokensNode';
    intercom: string | null;
  } | null;
};

export type AuthUserGroupFragment = {
  __typename: 'UserGroupNode';
  isYearbookTeamMember: boolean | null;
  isCoreTeamMember: boolean | null;
  id: string;
  role: UserType | null;
  avatar: string | null;
  status: Status | null;
  created: any;
  user: {
    __typename: 'UserNode';
    email: string;
    phoneCountryCode: string | null;
    phone: string | null;
    emailValidated: boolean;
    communicationLanguage: Language | null;
    isSuperuser: boolean;
    countryOfResidence: string | null;
    inviteCode: string | null;
    id: string;
    firstName: string;
    lastName: string;
    gender: UsersUserGenderChoices | null;
    externalTokens: {
      __typename: 'ExternalTokensNode';
      intercom: string | null;
    } | null;
  };
  defaultAvatar: {
    __typename: 'DefaultAvatarNode';
    id: string;
    avatar: string | null;
  } | null;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename: 'Query';
  me: {
    __typename: 'UserNode';
    email: string;
    phoneCountryCode: string | null;
    phone: string | null;
    emailValidated: boolean;
    communicationLanguage: Language | null;
    isSuperuser: boolean;
    countryOfResidence: string | null;
    inviteCode: string | null;
    id: string;
    firstName: string;
    lastName: string;
    gender: UsersUserGenderChoices | null;
    externalTokens: {
      __typename: 'ExternalTokensNode';
      intercom: string | null;
    } | null;
  } | null;
};

export type AuthUserGroupQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AuthUserGroupQuery = {
  __typename: 'Query';
  userGroup: {
    __typename: 'UserGroupNode';
    isYearbookTeamMember: boolean | null;
    isCoreTeamMember: boolean | null;
    id: string;
    role: UserType | null;
    avatar: string | null;
    status: Status | null;
    created: any;
    user: {
      __typename: 'UserNode';
      email: string;
      phoneCountryCode: string | null;
      phone: string | null;
      emailValidated: boolean;
      communicationLanguage: Language | null;
      isSuperuser: boolean;
      countryOfResidence: string | null;
      inviteCode: string | null;
      id: string;
      firstName: string;
      lastName: string;
      gender: UsersUserGenderChoices | null;
      externalTokens: {
        __typename: 'ExternalTokensNode';
        intercom: string | null;
      } | null;
    };
    defaultAvatar: {
      __typename: 'DefaultAvatarNode';
      id: string;
      avatar: string | null;
    } | null;
  } | null;
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename: 'Mutation';
  createUser: {
    __typename: 'CreateUserPayload';
    user: {
      __typename: 'UserNode';
      email: string;
      phoneCountryCode: string | null;
      phone: string | null;
      emailValidated: boolean;
      communicationLanguage: Language | null;
      isSuperuser: boolean;
      countryOfResidence: string | null;
      inviteCode: string | null;
      id: string;
      firstName: string;
      lastName: string;
      gender: UsersUserGenderChoices | null;
      externalTokens: {
        __typename: 'ExternalTokensNode';
        intercom: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateTokenMutationVariables = Exact<{
  input: ObtainJSONWebTokenInput;
}>;

export type CreateTokenMutation = {
  __typename: 'Mutation';
  createToken: {
    __typename: 'ObtainJSONWebTokenPayload';
    token: string;
    refreshToken: string;
  } | null;
};

export type UpdateMeMutationVariables = Exact<{
  input: UpdateMeInput;
}>;

export type UpdateMeMutation = {
  __typename: 'Mutation';
  updateMe: {
    __typename: 'UpdateMePayload';
    token: string | null;
    refreshToken: string | null;
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    user: {
      __typename: 'UserNode';
      email: string;
      phoneCountryCode: string | null;
      phone: string | null;
      emailValidated: boolean;
      communicationLanguage: Language | null;
      isSuperuser: boolean;
      countryOfResidence: string | null;
      inviteCode: string | null;
      id: string;
      firstName: string;
      lastName: string;
      gender: UsersUserGenderChoices | null;
      externalTokens: {
        __typename: 'ExternalTokensNode';
        intercom: string | null;
      } | null;
    } | null;
  } | null;
};

export type ForgetPasswordMutationVariables = Exact<{
  input: ForgetPasswordInput;
}>;

export type ForgetPasswordMutation = {
  __typename: 'Mutation';
  forgetPassword: {
    __typename: 'ForgetPasswordPayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
  } | null;
};

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = {
  __typename: 'Mutation';
  resetPassword: {
    __typename: 'ResetPasswordPayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
  } | null;
};

export type DeleteUserMutationVariables = Exact<{
  input: DeleteUserInput;
}>;

export type DeleteUserMutation = {
  __typename: 'Mutation';
  deleteUser: {
    __typename: 'DeleteUserPayload';
    user: {
      __typename: 'UserNode';
      email: string;
      phoneCountryCode: string | null;
      phone: string | null;
      emailValidated: boolean;
      communicationLanguage: Language | null;
      isSuperuser: boolean;
      countryOfResidence: string | null;
      inviteCode: string | null;
      id: string;
      firstName: string;
      lastName: string;
      gender: UsersUserGenderChoices | null;
      externalTokens: {
        __typename: 'ExternalTokensNode';
        intercom: string | null;
      } | null;
    } | null;
  } | null;
};

export type UserExistsQueryVariables = Exact<{
  email: Scalars['String']['input'];
  phoneCountryCode: Scalars['String']['input'];
  phone: Scalars['String']['input'];
}>;

export type UserExistsQuery = {
  __typename: 'Query';
  userExists: {
    __typename: 'UserExistsNode';
    emailExists: boolean | null;
    phoneExists: boolean | null;
  } | null;
};

export type EventAttendeeFragment = {
  __typename: 'EventAttendeeNode';
  id: string;
  user: {
    __typename: 'UserNode';
    id: string;
    firstName: string;
    lastName: string;
    avatar: string | null;
    email: string;
  };
};

export type EventFragment = {
  __typename: 'EventNode';
  title: string;
  id: string;
  location: string | null;
  companyName: string | null;
  companyLogo: string | null;
  bannerImage: string | null;
  isSponsored: boolean;
  eventAuthor: AuthorType | null;
  authorType: EventsEventAuthorTypeChoices;
  eventType: EventType | null;
  description: string | null;
  startDateTime: any;
  endDateTime: any;
  isAllDay: boolean;
  isJoinable: boolean;
  isPublic: boolean;
  authorUser: {
    __typename: 'UserNode';
    id: string;
    firstName: string;
    lastName: string;
    avatar: string | null;
    email: string;
  } | null;
  authorGroup: {
    __typename: 'GroupNode';
    id: string;
    name: string;
    avatar: string | null;
  } | null;
  authorTeam: {
    __typename: 'TeamNode';
    id: string;
    name: string;
    avatar: string | null;
  } | null;
  attendees: Array<{
    __typename: 'EventAttendeeNode';
    id: string;
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      avatar: string | null;
      email: string;
    };
  } | null> | null;
};

export type ExternalEventAttendeeFragment = {
  __typename: 'ExternalEventAttendeeNode';
  id: string;
  platform: Platform | null;
  eventId: string | null;
  attendee: { __typename: 'UserNode'; email: string };
};

export type EventsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type EventsQuery = {
  __typename: 'Query';
  events: {
    __typename: 'EventNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'EventNodeEdge';
      node: {
        __typename: 'EventNode';
        title: string;
        id: string;
        location: string | null;
        companyName: string | null;
        companyLogo: string | null;
        bannerImage: string | null;
        isSponsored: boolean;
        eventAuthor: AuthorType | null;
        authorType: EventsEventAuthorTypeChoices;
        eventType: EventType | null;
        description: string | null;
        startDateTime: any;
        endDateTime: any;
        isAllDay: boolean;
        isJoinable: boolean;
        isPublic: boolean;
        authorUser: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          avatar: string | null;
          email: string;
        } | null;
        authorGroup: {
          __typename: 'GroupNode';
          id: string;
          name: string;
          avatar: string | null;
        } | null;
        authorTeam: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          avatar: string | null;
        } | null;
        attendees: Array<{
          __typename: 'EventAttendeeNode';
          id: string;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            avatar: string | null;
            email: string;
          };
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type EventQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type EventQuery = {
  __typename: 'Query';
  event: {
    __typename: 'EventNode';
    title: string;
    id: string;
    location: string | null;
    companyName: string | null;
    companyLogo: string | null;
    bannerImage: string | null;
    isSponsored: boolean;
    eventAuthor: AuthorType | null;
    authorType: EventsEventAuthorTypeChoices;
    eventType: EventType | null;
    description: string | null;
    startDateTime: any;
    endDateTime: any;
    isAllDay: boolean;
    isJoinable: boolean;
    isPublic: boolean;
    authorUser: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      avatar: string | null;
      email: string;
    } | null;
    authorGroup: {
      __typename: 'GroupNode';
      id: string;
      name: string;
      avatar: string | null;
    } | null;
    authorTeam: {
      __typename: 'TeamNode';
      id: string;
      name: string;
      avatar: string | null;
    } | null;
    attendees: Array<{
      __typename: 'EventAttendeeNode';
      id: string;
      user: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        avatar: string | null;
        email: string;
      };
    } | null> | null;
  } | null;
};

export type CreateEventMutationVariables = Exact<{
  input: CreateEventInput;
}>;

export type CreateEventMutation = {
  __typename: 'Mutation';
  createEvent: {
    __typename: 'CreateEventPayload';
    event: {
      __typename: 'EventNode';
      title: string;
      id: string;
      location: string | null;
      companyName: string | null;
      companyLogo: string | null;
      bannerImage: string | null;
      isSponsored: boolean;
      eventAuthor: AuthorType | null;
      authorType: EventsEventAuthorTypeChoices;
      eventType: EventType | null;
      description: string | null;
      startDateTime: any;
      endDateTime: any;
      isAllDay: boolean;
      isJoinable: boolean;
      isPublic: boolean;
      authorUser: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        avatar: string | null;
        email: string;
      } | null;
      authorGroup: {
        __typename: 'GroupNode';
        id: string;
        name: string;
        avatar: string | null;
      } | null;
      authorTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        avatar: string | null;
      } | null;
      attendees: Array<{
        __typename: 'EventAttendeeNode';
        id: string;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          avatar: string | null;
          email: string;
        };
      } | null> | null;
    } | null;
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
  } | null;
};

export type JoinEventMutationVariables = Exact<{
  input: JoinEventInput;
}>;

export type JoinEventMutation = {
  __typename: 'Mutation';
  joinEvent: {
    __typename: 'JoinEventPayload';
    attendee: {
      __typename: 'EventAttendeeNode';
      id: string;
      event: {
        __typename: 'EventNode';
        title: string;
        id: string;
        location: string | null;
        companyName: string | null;
        companyLogo: string | null;
        bannerImage: string | null;
        isSponsored: boolean;
        eventAuthor: AuthorType | null;
        authorType: EventsEventAuthorTypeChoices;
        eventType: EventType | null;
        description: string | null;
        startDateTime: any;
        endDateTime: any;
        isAllDay: boolean;
        isJoinable: boolean;
        isPublic: boolean;
        authorUser: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          avatar: string | null;
          email: string;
        } | null;
        authorGroup: {
          __typename: 'GroupNode';
          id: string;
          name: string;
          avatar: string | null;
        } | null;
        authorTeam: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          avatar: string | null;
        } | null;
        attendees: Array<{
          __typename: 'EventAttendeeNode';
          id: string;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            avatar: string | null;
            email: string;
          };
        } | null> | null;
      };
      user: { __typename: 'UserNode'; id: string };
    } | null;
  } | null;
};

export type LeaveEventMutationVariables = Exact<{
  input: LeaveEventInput;
}>;

export type LeaveEventMutation = {
  __typename: 'Mutation';
  leaveEvent: {
    __typename: 'LeaveEventPayload';
    success: boolean | null;
  } | null;
};

export type EditEventMutationVariables = Exact<{
  input: EditEventInput;
}>;

export type EditEventMutation = {
  __typename: 'Mutation';
  editEvent: {
    __typename: 'EditEventPayload';
    event: {
      __typename: 'EventNode';
      title: string;
      id: string;
      location: string | null;
      companyName: string | null;
      companyLogo: string | null;
      bannerImage: string | null;
      isSponsored: boolean;
      eventAuthor: AuthorType | null;
      authorType: EventsEventAuthorTypeChoices;
      eventType: EventType | null;
      description: string | null;
      startDateTime: any;
      endDateTime: any;
      isAllDay: boolean;
      isJoinable: boolean;
      isPublic: boolean;
      authorUser: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        avatar: string | null;
        email: string;
      } | null;
      authorGroup: {
        __typename: 'GroupNode';
        id: string;
        name: string;
        avatar: string | null;
      } | null;
      authorTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        avatar: string | null;
      } | null;
      attendees: Array<{
        __typename: 'EventAttendeeNode';
        id: string;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          avatar: string | null;
          email: string;
        };
      } | null> | null;
    } | null;
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
  } | null;
};

export type DeleteEventMutationVariables = Exact<{
  input: DeleteEventInput;
}>;

export type DeleteEventMutation = {
  __typename: 'Mutation';
  deleteEvent: {
    __typename: 'DeleteEventPayload';
    success: boolean | null;
  } | null;
};

export type SetEventBannerMutationVariables = Exact<{
  input: SetEventBannerInput;
}>;

export type SetEventBannerMutation = {
  __typename: 'Mutation';
  setEventBanner: {
    __typename: 'SetEventBannerPayload';
    event: {
      __typename: 'EventNode';
      title: string;
      id: string;
      location: string | null;
      companyName: string | null;
      companyLogo: string | null;
      bannerImage: string | null;
      isSponsored: boolean;
      eventAuthor: AuthorType | null;
      authorType: EventsEventAuthorTypeChoices;
      eventType: EventType | null;
      description: string | null;
      startDateTime: any;
      endDateTime: any;
      isAllDay: boolean;
      isJoinable: boolean;
      isPublic: boolean;
      authorUser: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        avatar: string | null;
        email: string;
      } | null;
      authorGroup: {
        __typename: 'GroupNode';
        id: string;
        name: string;
        avatar: string | null;
      } | null;
      authorTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        avatar: string | null;
      } | null;
      attendees: Array<{
        __typename: 'EventAttendeeNode';
        id: string;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          avatar: string | null;
          email: string;
        };
      } | null> | null;
    } | null;
  } | null;
};

export type SaveExternalEventMutationVariables = Exact<{
  input: SaveExternalEventInput;
}>;

export type SaveExternalEventMutation = {
  __typename: 'Mutation';
  saveExternalEvent: {
    __typename: 'SaveExternalEventPayload';
    success: boolean | null;
    externalEvent: {
      __typename: 'ExternalEventAttendeeNode';
      id: string;
      platform: Platform | null;
      eventId: string | null;
      attendee: { __typename: 'UserNode'; email: string };
    } | null;
  } | null;
};

export type RemoveExternalEventMutationVariables = Exact<{
  input: RemoveExternalEventInput;
}>;

export type RemoveExternalEventMutation = {
  __typename: 'Mutation';
  removeExternalEvent: {
    __typename: 'RemoveExternalEventPayload';
    success: boolean | null;
    externalEvent: {
      __typename: 'ExternalEventAttendeeNode';
      id: string;
      platform: Platform | null;
      eventId: string | null;
      attendee: { __typename: 'UserNode'; email: string };
    } | null;
  } | null;
};

export type ToggleExternalEventSaveMutationVariables = Exact<{
  input: ToggleExternalEventSaveInput;
}>;

export type ToggleExternalEventSaveMutation = {
  __typename: 'Mutation';
  toggleExternalEventSave: {
    __typename: 'ToggleExternalEventSavePayload';
    success: boolean | null;
    externalEvent: {
      __typename: 'ExternalEventAttendeeNode';
      id: string;
      platform: Platform | null;
      eventId: string | null;
      attendee: { __typename: 'UserNode'; email: string };
    } | null;
  } | null;
};

export type CityLocationFragment = {
  __typename: 'CityLocationNode';
  region: string;
  country: InstitutesCityLocationCountryChoices;
};

export type CampaignFragment = {
  __typename: 'CampaignNode';
  id: string;
  name: string;
  utm: string;
  startDate: any;
  endDate: any;
  customer: string | null;
  status: CampaignStatus | null;
  orderId: string | null;
  type: CampaignType | null;
  isDeleted: boolean;
};

export type AssetFragment = {
  __typename: 'AssetNode';
  id: string;
  assetId: number;
  channelType: AdvertisementsAssetChannelTypeChoices;
  name: string;
  targetingConfig: any;
  startDate: any | null;
  endDate: any | null;
  visibility: AssetVisibilityType | null;
  totalViews: number | null;
  totalClicks: number | null;
  conversionRate: number | null;
  channelData: Array<{
    __typename: 'BannerAd';
    trackingUrl: string | null;
    imageLarge: string | null;
    imageSmall: string | null;
  } | null> | null;
  targetingFilters: Array<{
    __typename: 'TargetingFilterType';
    type: string;
    operator: string;
    location: {
      __typename: 'TargetingFilterLocationType';
      country: string | null;
      zip: Array<number | null> | null;
      region: Array<string | null> | null;
      city: string | null;
      radius: number | null;
    } | null;
    user: {
      __typename: 'TargetingFilterUserType';
      gender: TargetingFilterUserGenderOperation | null;
      userIds: Array<string | null> | null;
      registration: {
        __typename: 'TargetingFilterUserRegistrationType';
        date: string | null;
        operator: TargetingFilterUserRegistrationOperation | null;
      } | null;
    } | null;
    group: {
      __typename: 'TargetingFilterGroupType';
      graduationType: string | null;
      instituteType: string | null;
      graduationYear: Array<number | null> | null;
      groupIds: Array<string | null> | null;
      teams: Array<TeamType | null> | null;
      members: {
        __typename: 'TargetingFilterGroupMembersType';
        value: number | null;
        operator: TargetingFilterGroupMembersOperation | null;
      } | null;
    } | null;
  } | null> | null;
};

export type MatchedAssetFragment = {
  __typename: 'MatchedAssetNode';
  id: string;
  assetId: number;
  channelType: AdvertisementsAssetChannelTypeChoices;
  name: string;
  targetingConfig: any;
  startDate: any | null;
  endDate: any | null;
  visibility: AssetVisibilityType | null;
  isActive: boolean;
  channelData: Array<{
    __typename: 'BannerAd';
    trackingUrl: string | null;
    imageLarge: string | null;
    imageSmall: string | null;
  } | null> | null;
  targetingFilters: Array<{
    __typename: 'TargetingFilterType';
    type: string;
    operator: string;
    location: {
      __typename: 'TargetingFilterLocationType';
      country: string | null;
      zip: Array<number | null> | null;
      region: Array<string | null> | null;
      city: string | null;
      radius: number | null;
    } | null;
    user: {
      __typename: 'TargetingFilterUserType';
      gender: TargetingFilterUserGenderOperation | null;
      userIds: Array<string | null> | null;
      registration: {
        __typename: 'TargetingFilterUserRegistrationType';
        date: string | null;
        operator: TargetingFilterUserRegistrationOperation | null;
      } | null;
    } | null;
    group: {
      __typename: 'TargetingFilterGroupType';
      graduationType: string | null;
      instituteType: string | null;
      graduationYear: Array<number | null> | null;
      groupIds: Array<string | null> | null;
      teams: Array<TeamType | null> | null;
      members: {
        __typename: 'TargetingFilterGroupMembersType';
        value: number | null;
        operator: TargetingFilterGroupMembersOperation | null;
      } | null;
    } | null;
  } | null> | null;
};

export type CampaignDetailsFragment = {
  __typename: 'CampaignNode';
  id: string;
  name: string;
  utm: string;
  startDate: any;
  endDate: any;
  type: CampaignType | null;
  customer: string | null;
  orderId: string | null;
  totalViews: number | null;
  totalClicks: number | null;
  status: CampaignStatus | null;
  conversionRate: number | null;
  assets: Array<{
    __typename: 'AssetNode';
    id: string;
    assetId: number;
    channelType: AdvertisementsAssetChannelTypeChoices;
    name: string;
    targetingConfig: any;
    startDate: any | null;
    endDate: any | null;
    visibility: AssetVisibilityType | null;
    totalViews: number | null;
    totalClicks: number | null;
    conversionRate: number | null;
    channelData: Array<{
      __typename: 'BannerAd';
      trackingUrl: string | null;
      imageLarge: string | null;
      imageSmall: string | null;
    } | null> | null;
    targetingFilters: Array<{
      __typename: 'TargetingFilterType';
      type: string;
      operator: string;
      location: {
        __typename: 'TargetingFilterLocationType';
        country: string | null;
        zip: Array<number | null> | null;
        region: Array<string | null> | null;
        city: string | null;
        radius: number | null;
      } | null;
      user: {
        __typename: 'TargetingFilterUserType';
        gender: TargetingFilterUserGenderOperation | null;
        userIds: Array<string | null> | null;
        registration: {
          __typename: 'TargetingFilterUserRegistrationType';
          date: string | null;
          operator: TargetingFilterUserRegistrationOperation | null;
        } | null;
      } | null;
      group: {
        __typename: 'TargetingFilterGroupType';
        graduationType: string | null;
        instituteType: string | null;
        graduationYear: Array<number | null> | null;
        groupIds: Array<string | null> | null;
        teams: Array<TeamType | null> | null;
        members: {
          __typename: 'TargetingFilterGroupMembersType';
          value: number | null;
          operator: TargetingFilterGroupMembersOperation | null;
        } | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type CountryDataFragment = {
  __typename: 'CountryData';
  country: string | null;
  counts: Array<{
    __typename: 'DateCount';
    date: string | null;
    count: number | null;
  } | null> | null;
};

export type StatisticsFragment = {
  __typename: 'StatisticsNode';
  institutesCount: number | null;
  usersCount: number | null;
  activeGroupsCount: number | null;
  inactiveGroupsCount: number | null;
};

export type CampaignsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchFilter?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
}>;

export type CampaignsQuery = {
  __typename: 'Query';
  campaigns: {
    __typename: 'CampaignNodeConnection';
    totalCount: number;
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string | null;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'CampaignNodeEdge';
      cursor: string;
      node: {
        __typename: 'CampaignNode';
        id: string;
        name: string;
        utm: string;
        startDate: any;
        endDate: any;
        customer: string | null;
        status: CampaignStatus | null;
        orderId: string | null;
        type: CampaignType | null;
        isDeleted: boolean;
      } | null;
    } | null>;
  } | null;
};

export type CampaignQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CampaignQuery = {
  __typename: 'Query';
  campaign: {
    __typename: 'CampaignNode';
    id: string;
    name: string;
    utm: string;
    startDate: any;
    endDate: any;
    type: CampaignType | null;
    customer: string | null;
    orderId: string | null;
    totalViews: number | null;
    totalClicks: number | null;
    status: CampaignStatus | null;
    conversionRate: number | null;
    assets: Array<{
      __typename: 'AssetNode';
      id: string;
      assetId: number;
      channelType: AdvertisementsAssetChannelTypeChoices;
      name: string;
      targetingConfig: any;
      startDate: any | null;
      endDate: any | null;
      visibility: AssetVisibilityType | null;
      totalViews: number | null;
      totalClicks: number | null;
      conversionRate: number | null;
      channelData: Array<{
        __typename: 'BannerAd';
        trackingUrl: string | null;
        imageLarge: string | null;
        imageSmall: string | null;
      } | null> | null;
      targetingFilters: Array<{
        __typename: 'TargetingFilterType';
        type: string;
        operator: string;
        location: {
          __typename: 'TargetingFilterLocationType';
          country: string | null;
          zip: Array<number | null> | null;
          region: Array<string | null> | null;
          city: string | null;
          radius: number | null;
        } | null;
        user: {
          __typename: 'TargetingFilterUserType';
          gender: TargetingFilterUserGenderOperation | null;
          userIds: Array<string | null> | null;
          registration: {
            __typename: 'TargetingFilterUserRegistrationType';
            date: string | null;
            operator: TargetingFilterUserRegistrationOperation | null;
          } | null;
        } | null;
        group: {
          __typename: 'TargetingFilterGroupType';
          graduationType: string | null;
          instituteType: string | null;
          graduationYear: Array<number | null> | null;
          groupIds: Array<string | null> | null;
          teams: Array<TeamType | null> | null;
          members: {
            __typename: 'TargetingFilterGroupMembersType';
            value: number | null;
            operator: TargetingFilterGroupMembersOperation | null;
          } | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CreateBannerAssetMutationVariables = Exact<{
  input: CreateBannerAssetInput;
}>;

export type CreateBannerAssetMutation = {
  __typename: 'Mutation';
  createBannerAsset: {
    __typename: 'CreateBannerAssetPayload';
    asset: {
      __typename: 'AssetNode';
      id: string;
      assetId: number;
      channelType: AdvertisementsAssetChannelTypeChoices;
      name: string;
      targetingConfig: any;
      startDate: any | null;
      endDate: any | null;
      visibility: AssetVisibilityType | null;
      totalViews: number | null;
      totalClicks: number | null;
      conversionRate: number | null;
      channelData: Array<{
        __typename: 'BannerAd';
        trackingUrl: string | null;
        imageLarge: string | null;
        imageSmall: string | null;
      } | null> | null;
      targetingFilters: Array<{
        __typename: 'TargetingFilterType';
        type: string;
        operator: string;
        location: {
          __typename: 'TargetingFilterLocationType';
          country: string | null;
          zip: Array<number | null> | null;
          region: Array<string | null> | null;
          city: string | null;
          radius: number | null;
        } | null;
        user: {
          __typename: 'TargetingFilterUserType';
          gender: TargetingFilterUserGenderOperation | null;
          userIds: Array<string | null> | null;
          registration: {
            __typename: 'TargetingFilterUserRegistrationType';
            date: string | null;
            operator: TargetingFilterUserRegistrationOperation | null;
          } | null;
        } | null;
        group: {
          __typename: 'TargetingFilterGroupType';
          graduationType: string | null;
          instituteType: string | null;
          graduationYear: Array<number | null> | null;
          groupIds: Array<string | null> | null;
          teams: Array<TeamType | null> | null;
          members: {
            __typename: 'TargetingFilterGroupMembersType';
            value: number | null;
            operator: TargetingFilterGroupMembersOperation | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CreateCampaignMutationVariables = Exact<{
  input: CreateCampaignInput;
}>;

export type CreateCampaignMutation = {
  __typename: 'Mutation';
  createCampaign: {
    __typename: 'CreateCampaignPayload';
    campaign: {
      __typename: 'CampaignNode';
      id: string;
      name: string;
      utm: string;
      startDate: any;
      endDate: any;
      customer: string | null;
      status: CampaignStatus | null;
      orderId: string | null;
      type: CampaignType | null;
      isDeleted: boolean;
    } | null;
  } | null;
};

export type AssetQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AssetQuery = {
  __typename: 'Query';
  asset: {
    __typename: 'AssetNode';
    id: string;
    assetId: number;
    channelType: AdvertisementsAssetChannelTypeChoices;
    name: string;
    targetingConfig: any;
    startDate: any | null;
    endDate: any | null;
    visibility: AssetVisibilityType | null;
    totalViews: number | null;
    totalClicks: number | null;
    conversionRate: number | null;
    channelData: Array<{
      __typename: 'BannerAd';
      trackingUrl: string | null;
      imageLarge: string | null;
      imageSmall: string | null;
    } | null> | null;
    targetingFilters: Array<{
      __typename: 'TargetingFilterType';
      type: string;
      operator: string;
      location: {
        __typename: 'TargetingFilterLocationType';
        country: string | null;
        zip: Array<number | null> | null;
        region: Array<string | null> | null;
        city: string | null;
        radius: number | null;
      } | null;
      user: {
        __typename: 'TargetingFilterUserType';
        gender: TargetingFilterUserGenderOperation | null;
        userIds: Array<string | null> | null;
        registration: {
          __typename: 'TargetingFilterUserRegistrationType';
          date: string | null;
          operator: TargetingFilterUserRegistrationOperation | null;
        } | null;
      } | null;
      group: {
        __typename: 'TargetingFilterGroupType';
        graduationType: string | null;
        instituteType: string | null;
        graduationYear: Array<number | null> | null;
        groupIds: Array<string | null> | null;
        teams: Array<TeamType | null> | null;
        members: {
          __typename: 'TargetingFilterGroupMembersType';
          value: number | null;
          operator: TargetingFilterGroupMembersOperation | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type EditBannerAssetMutationVariables = Exact<{
  input: EditBannerAdAssetInput;
}>;

export type EditBannerAssetMutation = {
  __typename: 'Mutation';
  editBannerAdAsset: {
    __typename: 'EditBannerAdAssetPayload';
    asset: {
      __typename: 'AssetNode';
      id: string;
      assetId: number;
      channelType: AdvertisementsAssetChannelTypeChoices;
      name: string;
      targetingConfig: any;
      startDate: any | null;
      endDate: any | null;
      visibility: AssetVisibilityType | null;
      totalViews: number | null;
      totalClicks: number | null;
      conversionRate: number | null;
      channelData: Array<{
        __typename: 'BannerAd';
        trackingUrl: string | null;
        imageLarge: string | null;
        imageSmall: string | null;
      } | null> | null;
      targetingFilters: Array<{
        __typename: 'TargetingFilterType';
        type: string;
        operator: string;
        location: {
          __typename: 'TargetingFilterLocationType';
          country: string | null;
          zip: Array<number | null> | null;
          region: Array<string | null> | null;
          city: string | null;
          radius: number | null;
        } | null;
        user: {
          __typename: 'TargetingFilterUserType';
          gender: TargetingFilterUserGenderOperation | null;
          userIds: Array<string | null> | null;
          registration: {
            __typename: 'TargetingFilterUserRegistrationType';
            date: string | null;
            operator: TargetingFilterUserRegistrationOperation | null;
          } | null;
        } | null;
        group: {
          __typename: 'TargetingFilterGroupType';
          graduationType: string | null;
          instituteType: string | null;
          graduationYear: Array<number | null> | null;
          groupIds: Array<string | null> | null;
          teams: Array<TeamType | null> | null;
          members: {
            __typename: 'TargetingFilterGroupMembersType';
            value: number | null;
            operator: TargetingFilterGroupMembersOperation | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type DuplicateBannerAssetMutationVariables = Exact<{
  input: DuplicateAssetInput;
}>;

export type DuplicateBannerAssetMutation = {
  __typename: 'Mutation';
  duplicateAsset: {
    __typename: 'DuplicateAssetPayload';
    newAsset: {
      __typename: 'AssetNode';
      id: string;
      assetId: number;
      channelType: AdvertisementsAssetChannelTypeChoices;
      name: string;
      targetingConfig: any;
      startDate: any | null;
      endDate: any | null;
      visibility: AssetVisibilityType | null;
      totalViews: number | null;
      totalClicks: number | null;
      conversionRate: number | null;
      channelData: Array<{
        __typename: 'BannerAd';
        trackingUrl: string | null;
        imageLarge: string | null;
        imageSmall: string | null;
      } | null> | null;
      targetingFilters: Array<{
        __typename: 'TargetingFilterType';
        type: string;
        operator: string;
        location: {
          __typename: 'TargetingFilterLocationType';
          country: string | null;
          zip: Array<number | null> | null;
          region: Array<string | null> | null;
          city: string | null;
          radius: number | null;
        } | null;
        user: {
          __typename: 'TargetingFilterUserType';
          gender: TargetingFilterUserGenderOperation | null;
          userIds: Array<string | null> | null;
          registration: {
            __typename: 'TargetingFilterUserRegistrationType';
            date: string | null;
            operator: TargetingFilterUserRegistrationOperation | null;
          } | null;
        } | null;
        group: {
          __typename: 'TargetingFilterGroupType';
          graduationType: string | null;
          instituteType: string | null;
          graduationYear: Array<number | null> | null;
          groupIds: Array<string | null> | null;
          teams: Array<TeamType | null> | null;
          members: {
            __typename: 'TargetingFilterGroupMembersType';
            value: number | null;
            operator: TargetingFilterGroupMembersOperation | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type DeleteBannerAssetMutationVariables = Exact<{
  input: DeleteAssetInput;
}>;

export type DeleteBannerAssetMutation = {
  __typename: 'Mutation';
  deleteAsset: {
    __typename: 'DeleteAssetPayload';
    success: boolean | null;
    assetId: string | null;
  } | null;
};

export type BestMatchAssetQueryVariables = Exact<{
  channelType?: InputMaybe<ChannelType>;
  userId?: InputMaybe<Scalars['String']['input']>;
}>;

export type BestMatchAssetQuery = {
  __typename: 'Query';
  bestMatchAssets: Array<{
    __typename: 'MatchedAssetNode';
    id: string;
    assetId: number;
    channelType: AdvertisementsAssetChannelTypeChoices;
    name: string;
    targetingConfig: any;
    startDate: any | null;
    endDate: any | null;
    visibility: AssetVisibilityType | null;
    isActive: boolean;
    channelData: Array<{
      __typename: 'BannerAd';
      trackingUrl: string | null;
      imageLarge: string | null;
      imageSmall: string | null;
    } | null> | null;
    targetingFilters: Array<{
      __typename: 'TargetingFilterType';
      type: string;
      operator: string;
      location: {
        __typename: 'TargetingFilterLocationType';
        country: string | null;
        zip: Array<number | null> | null;
        region: Array<string | null> | null;
        city: string | null;
        radius: number | null;
      } | null;
      user: {
        __typename: 'TargetingFilterUserType';
        gender: TargetingFilterUserGenderOperation | null;
        userIds: Array<string | null> | null;
        registration: {
          __typename: 'TargetingFilterUserRegistrationType';
          date: string | null;
          operator: TargetingFilterUserRegistrationOperation | null;
        } | null;
      } | null;
      group: {
        __typename: 'TargetingFilterGroupType';
        graduationType: string | null;
        instituteType: string | null;
        graduationYear: Array<number | null> | null;
        groupIds: Array<string | null> | null;
        teams: Array<TeamType | null> | null;
        members: {
          __typename: 'TargetingFilterGroupMembersType';
          value: number | null;
          operator: TargetingFilterGroupMembersOperation | null;
        } | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type CreateAssetEventMutationVariables = Exact<{
  input: CreateAssetEventInput;
}>;

export type CreateAssetEventMutation = {
  __typename: 'Mutation';
  createAssetEvent: {
    __typename: 'CreateAssetEventPayload';
    success: boolean | null;
  } | null;
};

export type RegionsQueryVariables = Exact<{
  country?: InputMaybe<Scalars['String']['input']>;
}>;

export type RegionsQuery = {
  __typename: 'Query';
  regions: Array<{
    __typename: 'CityLocationNode';
    region: string;
    country: InstitutesCityLocationCountryChoices;
  } | null> | null;
};

export type EditCampaignMutationVariables = Exact<{
  input: EditCampaignInput;
}>;

export type EditCampaignMutation = {
  __typename: 'Mutation';
  editCampaign: {
    __typename: 'EditCampaignPayload';
    campaign: {
      __typename: 'CampaignNode';
      id: string;
      name: string;
      utm: string;
      startDate: any;
      endDate: any;
      customer: string | null;
      status: CampaignStatus | null;
      orderId: string | null;
      type: CampaignType | null;
      isDeleted: boolean;
    } | null;
  } | null;
};

export type DeleteCampaignMutationVariables = Exact<{
  input: DeleteCampaignInput;
}>;

export type DeleteCampaignMutation = {
  __typename: 'Mutation';
  deleteCampaign: {
    __typename: 'DeleteCampaignPayload';
    success: boolean | null;
  } | null;
};

export type StatisticsQueryVariables = Exact<{
  filter?: InputMaybe<DashboardFilterInput>;
}>;

export type StatisticsQuery = {
  __typename: 'Query';
  statistics: {
    __typename: 'StatisticsNode';
    institutesCount: number | null;
    usersCount: number | null;
    activeGroupsCount: number | null;
    inactiveGroupsCount: number | null;
  } | null;
};

export type SignupsQueryVariables = Exact<{
  filter?: InputMaybe<DashboardFilterInput>;
}>;

export type SignupsQuery = {
  __typename: 'Query';
  signups: Array<{
    __typename: 'CountryData';
    country: string | null;
    counts: Array<{
      __typename: 'DateCount';
      date: string | null;
      count: number | null;
    } | null> | null;
  } | null> | null;
};

export type KPIsQueryVariables = Exact<{
  filter?: InputMaybe<DashboardFilterInput>;
}>;

export type KPIsQuery = {
  __typename: 'Query';
  kpis: {
    __typename: 'KPIsNode';
    averageStudentsPerGroup: number | null;
    groupTimeToActive: number | null;
    profileQuestionsPerYear: Array<{
      __typename: 'YearlyAverage';
      year: number | null;
      average: number | null;
      instances: number | null;
      groups: number | null;
    } | null> | null;
    quotesPerGroupPerYear: Array<{
      __typename: 'YearlyAverage';
      year: number | null;
      average: number | null;
      instances: number | null;
      groups: number | null;
    } | null> | null;
    rankingQuestionsPerGroupPerYear: Array<{
      __typename: 'YearlyAverage';
      year: number | null;
      average: number | null;
      instances: number | null;
      groups: number | null;
    } | null> | null;
    albumsPerGroupPerYear: Array<{
      __typename: 'YearlyAverage';
      year: number | null;
      average: number | null;
      instances: number | null;
      groups: number | null;
    } | null> | null;
    photosPerGroupPerYear: Array<{
      __typename: 'YearlyAverage';
      year: number | null;
      average: number | null;
      instances: number | null;
      groups: number | null;
    } | null> | null;
  } | null;
};

export type ColorFragment = {
  __typename: 'ColorNode';
  id: string;
  hex: string;
};

export type IconFragment = { __typename: 'IconNode'; id: string; icon: string };

export type DefaultAvatarFragment = {
  __typename: 'DefaultAvatarNode';
  id: string;
  avatar: string | null;
};

export type GroupIconFragment = {
  __typename: 'GroupIconNode';
  id: string;
  icon: string | null;
};

export type ColorsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;

export type ColorsQuery = {
  __typename: 'Query';
  colors: {
    __typename: 'ColorNodeConnection';
    edges: Array<{
      __typename: 'ColorNodeEdge';
      node: { __typename: 'ColorNode'; id: string; hex: string } | null;
    } | null>;
  } | null;
};

export type IconsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;

export type IconsQuery = {
  __typename: 'Query';
  icons: {
    __typename: 'IconNodeConnection';
    edges: Array<{
      __typename: 'IconNodeEdge';
      node: { __typename: 'IconNode'; id: string; icon: string } | null;
    } | null>;
  } | null;
};

export type DefaultAvatarsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;

export type DefaultAvatarsQuery = {
  __typename: 'Query';
  defaultAvatars: {
    __typename: 'DefaultAvatarNodeConnection';
    edges: Array<{
      __typename: 'DefaultAvatarNodeEdge';
      node: {
        __typename: 'DefaultAvatarNode';
        id: string;
        avatar: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GroupIconsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;

export type GroupIconsQuery = {
  __typename: 'Query';
  groupIcons: {
    __typename: 'GroupIconNodeConnection';
    edges: Array<{
      __typename: 'GroupIconNodeEdge';
      node: {
        __typename: 'GroupIconNode';
        id: string;
        icon: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GraduationTypeFragment = {
  __typename: 'GraduationTypeNode';
  id: string;
  name: string;
};

export type GroupFragment = {
  __typename: 'GroupNode';
  id: string;
  name: string;
  avatar: string | null;
  year: number;
  token: string | null;
  created: any;
  icon: { __typename: 'GroupIconNode'; id: string; icon: string | null } | null;
  type: { __typename: 'GraduationTypeNode'; id: string; name: string };
  institute: {
    __typename: 'InstituteNode';
    id: string;
    internalId: string;
    country: InstitutesInstituteCountryChoices;
    name: string;
    street: string | null;
    zip: string | null;
    city: string | null;
    type: { __typename: 'InstituteTypeNode'; id: string; name: string };
  };
};

export type UserGroupFragment = {
  __typename: 'UserGroupNode';
  id: string;
  role: UserType | null;
  avatar: string | null;
  status: Status | null;
  created: any;
  defaultAvatar: {
    __typename: 'DefaultAvatarNode';
    id: string;
    avatar: string | null;
  } | null;
  user: {
    __typename: 'UserNode';
    id: string;
    firstName: string;
    lastName: string;
    gender: UsersUserGenderChoices | null;
  };
};

export type GroupUserFragment = {
  __typename: 'UserGroupNode';
  id: string;
  role: UserType | null;
  avatar: string | null;
  status: Status | null;
  created: any;
  group: {
    __typename: 'GroupNode';
    id: string;
    name: string;
    avatar: string | null;
    year: number;
    token: string | null;
    created: any;
    icon: {
      __typename: 'GroupIconNode';
      id: string;
      icon: string | null;
    } | null;
    type: { __typename: 'GraduationTypeNode'; id: string; name: string };
    institute: {
      __typename: 'InstituteNode';
      id: string;
      internalId: string;
      country: InstitutesInstituteCountryChoices;
      name: string;
      street: string | null;
      zip: string | null;
      city: string | null;
      type: { __typename: 'InstituteTypeNode'; id: string; name: string };
    };
  } | null;
  defaultAvatar: {
    __typename: 'DefaultAvatarNode';
    id: string;
    avatar: string | null;
  } | null;
  user: {
    __typename: 'UserNode';
    id: string;
    firstName: string;
    lastName: string;
    gender: UsersUserGenderChoices | null;
  };
};

export type GroupSampleFragment = {
  __typename: 'GroupNode';
  usersCount: number | null;
  id: string;
  name: string;
  avatar: string | null;
  year: number;
  token: string | null;
  created: any;
  sampleMembers: Array<{
    __typename: 'UserGroupNode';
    id: string;
    role: UserType | null;
    avatar: string | null;
    status: Status | null;
    created: any;
    defaultAvatar: {
      __typename: 'DefaultAvatarNode';
      id: string;
      avatar: string | null;
    } | null;
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      gender: UsersUserGenderChoices | null;
    };
  } | null> | null;
  icon: { __typename: 'GroupIconNode'; id: string; icon: string | null } | null;
  type: { __typename: 'GraduationTypeNode'; id: string; name: string };
  institute: {
    __typename: 'InstituteNode';
    id: string;
    internalId: string;
    country: InstitutesInstituteCountryChoices;
    name: string;
    street: string | null;
    zip: string | null;
    city: string | null;
    type: { __typename: 'InstituteTypeNode'; id: string; name: string };
  };
};

export type GroupDetailFragment = {
  __typename: 'GroupNode';
  usersCount: number | null;
  id: string;
  name: string;
  avatar: string | null;
  year: number;
  token: string | null;
  created: any;
  institute: {
    __typename: 'InstituteNode';
    id: string;
    internalId: string;
    country: InstitutesInstituteCountryChoices;
    name: string;
    street: string | null;
    zip: string | null;
    city: string | null;
    website: string | null;
    type: {
      __typename: 'InstituteTypeNode';
      id: string;
      name: string;
      country: InstitutesInstituteTypeCountryChoices | null;
      graduationTypes: Array<{
        __typename: 'GraduationTypeNode';
        id: string;
        name: string;
      } | null> | null;
    };
  };
  icon: { __typename: 'GroupIconNode'; id: string; icon: string | null } | null;
  type: { __typename: 'GraduationTypeNode'; id: string; name: string };
};

export type GraduationTypesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;

export type GraduationTypesQuery = {
  __typename: 'Query';
  graduationTypes: {
    __typename: 'GraduationTypeNodeConnection';
    edges: Array<{
      __typename: 'GraduationTypeNodeEdge';
      node: {
        __typename: 'GraduationTypeNode';
        id: string;
        name: string;
      } | null;
    } | null>;
  } | null;
};

export type GraduationTypesCountryQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
}>;

export type GraduationTypesCountryQuery = {
  __typename: 'Query';
  graduationTypesCountry: Array<{
    __typename: 'GraduationTypeCountryNode';
    id: string | null;
    name: string | null;
    country: string | null;
  } | null> | null;
};

export type YearbookStatisticsFragment = {
  __typename: 'YearbookStatisticsNode';
  profilePagesFilledIn: number | null;
  usersWithQuote: number | null;
  lastDeadline: any | null;
  contentModules: number | null;
  estimatedPages: number | null;
  imagesUploaded: number | null;
  wordCount: number | null;
};

export type GroupStatisticsFragment = {
  __typename: 'GroupNode';
  id: string;
  yearbookStatistics: {
    __typename: 'YearbookStatisticsNode';
    profilePagesFilledIn: number | null;
    usersWithQuote: number | null;
    lastDeadline: any | null;
    contentModules: number | null;
    estimatedPages: number | null;
    imagesUploaded: number | null;
    wordCount: number | null;
  } | null;
};

export type GroupsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['ID']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
  institute?: InputMaybe<Scalars['ID']['input']>;
  marketProfile?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GroupsQuery = {
  __typename: 'Query';
  groups: {
    __typename: 'GroupNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'GroupNodeEdge';
      node: {
        __typename: 'GroupNode';
        id: string;
        name: string;
        avatar: string | null;
        year: number;
        token: string | null;
        created: any;
        icon: {
          __typename: 'GroupIconNode';
          id: string;
          icon: string | null;
        } | null;
        type: { __typename: 'GraduationTypeNode'; id: string; name: string };
        institute: {
          __typename: 'InstituteNode';
          id: string;
          internalId: string;
          country: InstitutesInstituteCountryChoices;
          name: string;
          street: string | null;
          zip: string | null;
          city: string | null;
          type: { __typename: 'InstituteTypeNode'; id: string; name: string };
        };
      } | null;
    } | null>;
  } | null;
};

export type GroupQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GroupQuery = {
  __typename: 'Query';
  group: {
    __typename: 'GroupNode';
    usersCount: number | null;
    id: string;
    name: string;
    avatar: string | null;
    year: number;
    token: string | null;
    created: any;
    teams: Array<{
      __typename: 'TeamNode';
      id: string;
      name: string;
    } | null> | null;
    institute: {
      __typename: 'InstituteNode';
      id: string;
      internalId: string;
      country: InstitutesInstituteCountryChoices;
      name: string;
      street: string | null;
      zip: string | null;
      city: string | null;
      website: string | null;
      type: {
        __typename: 'InstituteTypeNode';
        id: string;
        name: string;
        country: InstitutesInstituteTypeCountryChoices | null;
        graduationTypes: Array<{
          __typename: 'GraduationTypeNode';
          id: string;
          name: string;
        } | null> | null;
      };
    };
    icon: {
      __typename: 'GroupIconNode';
      id: string;
      icon: string | null;
    } | null;
    type: { __typename: 'GraduationTypeNode'; id: string; name: string };
  } | null;
};

export type GroupSampleQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GroupSampleQuery = {
  __typename: 'Query';
  group: {
    __typename: 'GroupNode';
    usersCount: number | null;
    id: string;
    name: string;
    avatar: string | null;
    year: number;
    token: string | null;
    created: any;
    sampleMembers: Array<{
      __typename: 'UserGroupNode';
      id: string;
      role: UserType | null;
      avatar: string | null;
      status: Status | null;
      created: any;
      defaultAvatar: {
        __typename: 'DefaultAvatarNode';
        id: string;
        avatar: string | null;
      } | null;
      user: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        gender: UsersUserGenderChoices | null;
      };
    } | null> | null;
    icon: {
      __typename: 'GroupIconNode';
      id: string;
      icon: string | null;
    } | null;
    type: { __typename: 'GraduationTypeNode'; id: string; name: string };
    institute: {
      __typename: 'InstituteNode';
      id: string;
      internalId: string;
      country: InstitutesInstituteCountryChoices;
      name: string;
      street: string | null;
      zip: string | null;
      city: string | null;
      type: { __typename: 'InstituteTypeNode'; id: string; name: string };
    };
  } | null;
};

export type GroupStatisticsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GroupStatisticsQuery = {
  __typename: 'Query';
  group: {
    __typename: 'GroupNode';
    id: string;
    yearbookStatistics: {
      __typename: 'YearbookStatisticsNode';
      profilePagesFilledIn: number | null;
      usersWithQuote: number | null;
      lastDeadline: any | null;
      contentModules: number | null;
      estimatedPages: number | null;
      imagesUploaded: number | null;
      wordCount: number | null;
    } | null;
  } | null;
};

export type AddUserToGroupMutationVariables = Exact<{
  input: AddUserToGroupInput;
}>;

export type AddUserToGroupMutation = {
  __typename: 'Mutation';
  addUserToGroup: {
    __typename: 'AddUserToGroupPayload';
    userGroup: {
      __typename: 'UserGroupNode';
      id: string;
      role: UserType | null;
      avatar: string | null;
      status: Status | null;
      created: any;
      defaultAvatar: {
        __typename: 'DefaultAvatarNode';
        id: string;
        avatar: string | null;
      } | null;
      user: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        gender: UsersUserGenderChoices | null;
      };
    } | null;
  } | null;
};

export type AcceptUserInGroupMutationVariables = Exact<{
  input: AcceptUserInGroupInput;
}>;

export type AcceptUserInGroupMutation = {
  __typename: 'Mutation';
  acceptUserInGroup: {
    __typename: 'AcceptUserInGroupPayload';
    userGroup: {
      __typename: 'UserGroupNode';
      id: string;
      role: UserType | null;
      avatar: string | null;
      status: Status | null;
      created: any;
      defaultAvatar: {
        __typename: 'DefaultAvatarNode';
        id: string;
        avatar: string | null;
      } | null;
      user: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        gender: UsersUserGenderChoices | null;
      };
    } | null;
  } | null;
};

export type RemoveUserFromGroupMutationVariables = Exact<{
  input: RemoveUserFromGroupInput;
}>;

export type RemoveUserFromGroupMutation = {
  __typename: 'Mutation';
  removeUserFromGroup: {
    __typename: 'RemoveUserFromGroupPayload';
    userGroup: {
      __typename: 'UserGroupNode';
      id: string;
      role: UserType | null;
      avatar: string | null;
      status: Status | null;
      created: any;
      defaultAvatar: {
        __typename: 'DefaultAvatarNode';
        id: string;
        avatar: string | null;
      } | null;
      user: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        gender: UsersUserGenderChoices | null;
      };
    } | null;
  } | null;
};

export type UserGroupsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  group?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<GroupsUserGroupStatusChoices>;
}>;

export type UserGroupsQuery = {
  __typename: 'Query';
  userGroups: {
    __typename: 'UserGroupNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'UserGroupNodeEdge';
      node: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      } | null;
    } | null>;
  } | null;
};

export type GroupUsersQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  user?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<GroupsUserGroupStatusChoices>;
}>;

export type GroupUsersQuery = {
  __typename: 'Query';
  userGroups: {
    __typename: 'UserGroupNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'UserGroupNodeEdge';
      node: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        group: {
          __typename: 'GroupNode';
          id: string;
          name: string;
          avatar: string | null;
          year: number;
          token: string | null;
          created: any;
          icon: {
            __typename: 'GroupIconNode';
            id: string;
            icon: string | null;
          } | null;
          type: { __typename: 'GraduationTypeNode'; id: string; name: string };
          institute: {
            __typename: 'InstituteNode';
            id: string;
            internalId: string;
            country: InstitutesInstituteCountryChoices;
            name: string;
            street: string | null;
            zip: string | null;
            city: string | null;
            type: { __typename: 'InstituteTypeNode'; id: string; name: string };
          };
        } | null;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      } | null;
    } | null>;
  } | null;
};

export type UserGroupQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type UserGroupQuery = {
  __typename: 'Query';
  userGroup: {
    __typename: 'UserGroupNode';
    id: string;
    role: UserType | null;
    avatar: string | null;
    status: Status | null;
    created: any;
    defaultAvatar: {
      __typename: 'DefaultAvatarNode';
      id: string;
      avatar: string | null;
    } | null;
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      gender: UsersUserGenderChoices | null;
    };
  } | null;
};

export type CreateGroupMutationVariables = Exact<{
  input: CreateGroupInput;
}>;

export type CreateGroupMutation = {
  __typename: 'Mutation';
  createGroup: {
    __typename: 'CreateGroupPayload';
    group: {
      __typename: 'GroupNode';
      id: string;
      name: string;
      avatar: string | null;
      year: number;
      token: string | null;
      created: any;
      icon: {
        __typename: 'GroupIconNode';
        id: string;
        icon: string | null;
      } | null;
      type: { __typename: 'GraduationTypeNode'; id: string; name: string };
      institute: {
        __typename: 'InstituteNode';
        id: string;
        internalId: string;
        country: InstitutesInstituteCountryChoices;
        name: string;
        street: string | null;
        zip: string | null;
        city: string | null;
        type: { __typename: 'InstituteTypeNode'; id: string; name: string };
      };
    } | null;
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
  } | null;
};

export type UpdateGroupMutationVariables = Exact<{
  input: UpdateGroupInput;
}>;

export type UpdateGroupMutation = {
  __typename: 'Mutation';
  updateGroup: {
    __typename: 'UpdateGroupPayload';
    group: {
      __typename: 'GroupNode';
      id: string;
      name: string;
      avatar: string | null;
      year: number;
      token: string | null;
      created: any;
      icon: {
        __typename: 'GroupIconNode';
        id: string;
        icon: string | null;
      } | null;
      type: { __typename: 'GraduationTypeNode'; id: string; name: string };
      institute: {
        __typename: 'InstituteNode';
        id: string;
        internalId: string;
        country: InstitutesInstituteCountryChoices;
        name: string;
        street: string | null;
        zip: string | null;
        city: string | null;
        type: { __typename: 'InstituteTypeNode'; id: string; name: string };
      };
    } | null;
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
  } | null;
};

export type SetGroupAvatarMutationVariables = Exact<{
  input: SetGroupAvatarInput;
}>;

export type SetGroupAvatarMutation = {
  __typename: 'Mutation';
  setGroupAvatar: {
    __typename: 'SetGroupAvatarPayload';
    group: {
      __typename: 'GroupNode';
      id: string;
      name: string;
      avatar: string | null;
      year: number;
      token: string | null;
      created: any;
      icon: {
        __typename: 'GroupIconNode';
        id: string;
        icon: string | null;
      } | null;
      type: { __typename: 'GraduationTypeNode'; id: string; name: string };
      institute: {
        __typename: 'InstituteNode';
        id: string;
        internalId: string;
        country: InstitutesInstituteCountryChoices;
        name: string;
        street: string | null;
        zip: string | null;
        city: string | null;
        type: { __typename: 'InstituteTypeNode'; id: string; name: string };
      };
    } | null;
  } | null;
};

export type SetUserGroupAvatarMutationVariables = Exact<{
  input: SetUserGroupAvatarInput;
}>;

export type SetUserGroupAvatarMutation = {
  __typename: 'Mutation';
  setUserGroupAvatar: {
    __typename: 'SetUserGroupAvatarPayload';
    userGroup: {
      __typename: 'UserGroupNode';
      id: string;
      role: UserType | null;
      avatar: string | null;
      status: Status | null;
      created: any;
      defaultAvatar: {
        __typename: 'DefaultAvatarNode';
        id: string;
        avatar: string | null;
      } | null;
      user: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        gender: UsersUserGenderChoices | null;
      };
    } | null;
  } | null;
};

export type UpdateUserGroupMutationVariables = Exact<{
  input: UpdateUserGroupInput;
}>;

export type UpdateUserGroupMutation = {
  __typename: 'Mutation';
  updateUserGroup: {
    __typename: 'UpdateUserGroupPayload';
    userGroup: {
      __typename: 'UserGroupNode';
      id: string;
      role: UserType | null;
      avatar: string | null;
      status: Status | null;
      created: any;
      defaultAvatar: {
        __typename: 'DefaultAvatarNode';
        id: string;
        avatar: string | null;
      } | null;
      user: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        gender: UsersUserGenderChoices | null;
      };
    } | null;
  } | null;
};

export type ImportToErpMutationVariables = Exact<{
  input: ImportToErpInput;
}>;

export type ImportToErpMutation = {
  __typename: 'Mutation';
  importToErp: {
    __typename: 'ImportToErpPayload';
    success: boolean | null;
  } | null;
};

export type LeaderboardUserFragment = {
  __typename: 'LeaderboardUserNode';
  points: number;
  position: number | null;
  user: {
    __typename: 'UserNode';
    id: string;
    firstName: string;
    lastName: string;
    gender: UsersUserGenderChoices | null;
  };
};

export type LeaderboardUsersQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
}>;

export type LeaderboardUsersQuery = {
  __typename: 'Query';
  leaderboardUsers: {
    __typename: 'LeaderboardUserNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'LeaderboardUserNodeEdge';
      node: {
        __typename: 'LeaderboardUserNode';
        points: number;
        position: number | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      } | null;
    } | null>;
  } | null;
};

export type ImportFromAbihomeMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  groupName: Scalars['String']['input'];
  graduationType: Scalars['String']['input'];
  graduationYear: Scalars['Int']['input'];
  approved?: InputMaybe<Scalars['Boolean']['input']>;
  iconId?: InputMaybe<Scalars['ID']['input']>;
  avatar?: InputMaybe<Scalars['Upload']['input']>;
}>;

export type ImportFromAbihomeMutation = {
  __typename: 'Mutation';
  importAbihome: {
    __typename: 'Importer';
    ok: boolean | null;
    userNumber: number | null;
    error: string | null;
  } | null;
};

export type InstituteTypeFragment = {
  __typename: 'InstituteTypeNode';
  id: string;
  name: string;
  country: InstitutesInstituteTypeCountryChoices | null;
  graduationTypes: Array<{
    __typename: 'GraduationTypeNode';
    id: string;
    name: string;
  } | null> | null;
};

export type InstituteFragment = {
  __typename: 'InstituteNode';
  id: string;
  internalId: string;
  name: string;
  street: string | null;
  zip: string | null;
  city: string | null;
  website: string | null;
  country: InstitutesInstituteCountryChoices;
  type: {
    __typename: 'InstituteTypeNode';
    id: string;
    name: string;
    country: InstitutesInstituteTypeCountryChoices | null;
    graduationTypes: Array<{
      __typename: 'GraduationTypeNode';
      id: string;
      name: string;
    } | null> | null;
  };
};

export type InstituteTypesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<InstitutesInstituteCountryChoices>;
}>;

export type InstituteTypesQuery = {
  __typename: 'Query';
  instituteTypes: {
    __typename: 'InstituteTypeNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'InstituteTypeNodeEdge';
      node: {
        __typename: 'InstituteTypeNode';
        id: string;
        name: string;
        country: InstitutesInstituteTypeCountryChoices | null;
        graduationTypes: Array<{
          __typename: 'GraduationTypeNode';
          id: string;
          name: string;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type InstitutesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['ID']['input']>;
  country?: InputMaybe<InstitutesInstituteCountryChoices>;
  internalId?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type InstitutesQuery = {
  __typename: 'Query';
  institutes: {
    __typename: 'InstituteNodeConnection';
    totalCount: number;
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'InstituteNodeEdge';
      node: {
        __typename: 'InstituteNode';
        id: string;
        internalId: string;
        name: string;
        street: string | null;
        zip: string | null;
        city: string | null;
        website: string | null;
        country: InstitutesInstituteCountryChoices;
        type: {
          __typename: 'InstituteTypeNode';
          id: string;
          name: string;
          country: InstitutesInstituteTypeCountryChoices | null;
          graduationTypes: Array<{
            __typename: 'GraduationTypeNode';
            id: string;
            name: string;
          } | null> | null;
        };
      } | null;
    } | null>;
  } | null;
};

export type InstituteQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type InstituteQuery = {
  __typename: 'Query';
  institute: {
    __typename: 'InstituteNode';
    id: string;
    internalId: string;
    name: string;
    street: string | null;
    zip: string | null;
    city: string | null;
    website: string | null;
    country: InstitutesInstituteCountryChoices;
    type: {
      __typename: 'InstituteTypeNode';
      id: string;
      name: string;
      country: InstitutesInstituteTypeCountryChoices | null;
      graduationTypes: Array<{
        __typename: 'GraduationTypeNode';
        id: string;
        name: string;
      } | null> | null;
    };
  } | null;
};

export type RequestMissingInstituteMutationVariables = Exact<{
  input: RequestMissingInstituteInput;
}>;

export type RequestMissingInstituteMutation = {
  __typename: 'Mutation';
  requestMissingInstitute: {
    __typename: 'RequestMissingInstitutePayload';
    responseCode: number | null;
    message: string | null;
  } | null;
};

export type CitiesZipQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
}>;

export type CitiesZipQuery = {
  __typename: 'Query';
  citiesZip: Array<{
    __typename: 'CityNode';
    name: string | null;
  } | null> | null;
};

export type CreateInstituteMutationVariables = Exact<{
  input: CreateInstituteInput;
}>;

export type CreateInstituteMutation = {
  __typename: 'Mutation';
  createInstitute: {
    __typename: 'CreateInstitutePayload';
    responseCode: number | null;
    message: string | null;
    institute: {
      __typename: 'InstituteNode';
      id: string;
      internalId: string;
      name: string;
      street: string | null;
      zip: string | null;
      city: string | null;
      website: string | null;
      country: InstitutesInstituteCountryChoices;
      type: {
        __typename: 'InstituteTypeNode';
        id: string;
        name: string;
        country: InstitutesInstituteTypeCountryChoices | null;
        graduationTypes: Array<{
          __typename: 'GraduationTypeNode';
          id: string;
          name: string;
        } | null> | null;
      };
    } | null;
  } | null;
};

export type InstituteRequestFragment = {
  __typename: 'InstituteRequestNode';
  id: string;
  name: string;
  street: string | null;
  zip: string | null;
  city: string | null;
  country: InstitutesInstituteRequestCountryChoices;
  status: Status | null;
  website: string | null;
  created: any;
  type: { __typename: 'InstituteTypeNode'; id: string; name: string };
  user: {
    __typename: 'UserNode';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    avatar: string | null;
  };
};

export type InstituteRequestsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  type?: InputMaybe<Scalars['ID']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<InstitutesInstituteRequestCountryChoices>;
  zip?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
}>;

export type InstituteRequestsQuery = {
  __typename: 'Query';
  instituteRequests: {
    __typename: 'InstituteRequestNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'InstituteRequestNodeEdge';
      node: {
        __typename: 'InstituteRequestNode';
        id: string;
        name: string;
        street: string | null;
        zip: string | null;
        city: string | null;
        country: InstitutesInstituteRequestCountryChoices;
        status: Status | null;
        website: string | null;
        created: any;
        type: { __typename: 'InstituteTypeNode'; id: string; name: string };
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          avatar: string | null;
        };
      } | null;
    } | null>;
  } | null;
};

export type InstituteRequestQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type InstituteRequestQuery = {
  __typename: 'Query';
  instituteRequest: {
    __typename: 'InstituteRequestNode';
    id: string;
    name: string;
    street: string | null;
    zip: string | null;
    city: string | null;
    country: InstitutesInstituteRequestCountryChoices;
    status: Status | null;
    website: string | null;
    created: any;
    type: { __typename: 'InstituteTypeNode'; id: string; name: string };
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      avatar: string | null;
    };
  } | null;
};

export type FindDuplicateInstitutesQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  customSearch?: InputMaybe<Scalars['String']['input']>;
}>;

export type FindDuplicateInstitutesQuery = {
  __typename: 'Query';
  findDuplicateInstitutes: Array<{
    __typename: 'InstituteNode';
    id: string;
    internalId: string;
    name: string;
    street: string | null;
    zip: string | null;
    city: string | null;
    website: string | null;
    country: InstitutesInstituteCountryChoices;
    type: {
      __typename: 'InstituteTypeNode';
      id: string;
      name: string;
      country: InstitutesInstituteTypeCountryChoices | null;
      graduationTypes: Array<{
        __typename: 'GraduationTypeNode';
        id: string;
        name: string;
      } | null> | null;
    };
  } | null> | null;
};

export type AcceptInstituteRequestMutationVariables = Exact<{
  input: AdminAcceptInstituteRequestInput;
}>;

export type AcceptInstituteRequestMutation = {
  __typename: 'Mutation';
  adminAcceptInstituteRequest: {
    __typename: 'AdminAcceptInstituteRequestPayload';
    instituteRequest: {
      __typename: 'InstituteRequestNode';
      id: string;
      name: string;
      street: string | null;
      zip: string | null;
      city: string | null;
      country: InstitutesInstituteRequestCountryChoices;
      status: Status | null;
      website: string | null;
      created: any;
      type: { __typename: 'InstituteTypeNode'; id: string; name: string };
      user: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        avatar: string | null;
      };
    } | null;
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
  } | null;
};

export type RejectInstituteRequestMutationVariables = Exact<{
  input: AdminRejectInstituteRequestInput;
}>;

export type RejectInstituteRequestMutation = {
  __typename: 'Mutation';
  adminRejectInstituteRequest: {
    __typename: 'AdminRejectInstituteRequestPayload';
    instituteRequest: {
      __typename: 'InstituteRequestNode';
      id: string;
      name: string;
      street: string | null;
      zip: string | null;
      city: string | null;
      country: InstitutesInstituteRequestCountryChoices;
      status: Status | null;
      website: string | null;
      created: any;
      type: { __typename: 'InstituteTypeNode'; id: string; name: string };
      user: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        avatar: string | null;
      };
    } | null;
  } | null;
};

export type DeleteInstituteRequestMutationVariables = Exact<{
  input: AdminDeleteInstituteRequestInput;
}>;

export type DeleteInstituteRequestMutation = {
  __typename: 'Mutation';
  adminDeleteInstituteRequest: {
    __typename: 'AdminDeleteInstituteRequestPayload';
    success: boolean | null;
  } | null;
};

export type MarketProfileFragment = {
  __typename: 'MarketProfileNode';
  id: string;
  name: string;
};

export type MarketProfilesQueryVariables = Exact<{ [key: string]: never }>;

export type MarketProfilesQuery = {
  __typename: 'Query';
  marketProfiles: {
    __typename: 'MarketProfileNodeConnection';
    edges: Array<{
      __typename: 'MarketProfileNodeEdge';
      node: {
        __typename: 'MarketProfileNode';
        id: string;
        name: string;
      } | null;
    } | null>;
  } | null;
};

export type CommentFragment = {
  __typename: 'CommentNode';
  id: string;
  text: string;
  owner: Owner | null;
  likesCount: number | null;
  created: any;
  userGroup: {
    __typename: 'UserGroupNode';
    id: string;
    role: UserType | null;
    avatar: string | null;
    status: Status | null;
    created: any;
    defaultAvatar: {
      __typename: 'DefaultAvatarNode';
      id: string;
      avatar: string | null;
    } | null;
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      gender: UsersUserGenderChoices | null;
    };
  } | null;
  team: {
    __typename: 'TeamNode';
    id: string;
    name: string;
    type: TeamType | null;
    approvalSetting: ApprovalSetting | null;
    isHidden: boolean;
    created: any;
    color: { __typename: 'ColorNode'; id: string; hex: string } | null;
    icon: { __typename: 'IconNode'; id: string; icon: string } | null;
  } | null;
  likes: {
    __typename: 'CommentLikeNodeConnection';
    edges: Array<{
      __typename: 'CommentLikeNodeEdge';
      node: {
        __typename: 'CommentLikeNode';
        userGroup: { __typename: 'UserGroupNode'; id: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type BasePostFragment = {
  __typename: 'PostNode';
  id: string;
  owner: Owner | null;
  text: string;
  images: Array<string | null> | null;
  likesCount: number | null;
  created: any;
  createdBy: {
    __typename: 'UserGroupNode';
    id: string;
    role: UserType | null;
    avatar: string | null;
    status: Status | null;
    created: any;
    defaultAvatar: {
      __typename: 'DefaultAvatarNode';
      id: string;
      avatar: string | null;
    } | null;
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      gender: UsersUserGenderChoices | null;
    };
  } | null;
  team: {
    __typename: 'TeamNode';
    id: string;
    name: string;
    type: TeamType | null;
    approvalSetting: ApprovalSetting | null;
    isHidden: boolean;
    created: any;
    color: { __typename: 'ColorNode'; id: string; hex: string } | null;
    icon: { __typename: 'IconNode'; id: string; icon: string } | null;
  } | null;
  comments: Array<{
    __typename: 'CommentNode';
    id: string;
    text: string;
    owner: Owner | null;
    likesCount: number | null;
    created: any;
    userGroup: {
      __typename: 'UserGroupNode';
      id: string;
      role: UserType | null;
      avatar: string | null;
      status: Status | null;
      created: any;
      defaultAvatar: {
        __typename: 'DefaultAvatarNode';
        id: string;
        avatar: string | null;
      } | null;
      user: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        gender: UsersUserGenderChoices | null;
      };
    } | null;
    team: {
      __typename: 'TeamNode';
      id: string;
      name: string;
      type: TeamType | null;
      approvalSetting: ApprovalSetting | null;
      isHidden: boolean;
      created: any;
      color: { __typename: 'ColorNode'; id: string; hex: string } | null;
      icon: { __typename: 'IconNode'; id: string; icon: string } | null;
    } | null;
    likes: {
      __typename: 'CommentLikeNodeConnection';
      edges: Array<{
        __typename: 'CommentLikeNodeEdge';
        node: {
          __typename: 'CommentLikeNode';
          userGroup: { __typename: 'UserGroupNode'; id: string } | null;
        } | null;
      } | null>;
    } | null;
  } | null> | null;
};

export type PollVoteFragment = {
  __typename: 'PollVoteNode';
  id: string;
  userGroup: {
    __typename: 'UserGroupNode';
    user: { __typename: 'UserNode'; id: string };
  } | null;
};

export type PollOptionFragment = {
  __typename: 'PollOptionNode';
  id: string;
  text: string;
  image: string | null;
  percentage: number | null;
  votesNumber: number | null;
  votes: Array<{
    __typename: 'PollVoteNode';
    id: string;
    userGroup: {
      __typename: 'UserGroupNode';
      user: { __typename: 'UserNode'; id: string };
    } | null;
  } | null> | null;
};

export type BasePollFragment = {
  __typename: 'PollNode';
  id: string;
  question: string;
  deadline: any;
  answersAllowed: number;
  randomizeOptions: boolean;
  allowRevote: boolean;
  allowComments: boolean;
  anonymousVotes: boolean;
  privateResults: boolean;
  created: any;
  options: Array<{
    __typename: 'PollOptionNode';
    id: string;
    text: string;
    image: string | null;
    percentage: number | null;
    votesNumber: number | null;
    votes: Array<{
      __typename: 'PollVoteNode';
      id: string;
      userGroup: {
        __typename: 'UserGroupNode';
        user: { __typename: 'UserNode'; id: string };
      } | null;
    } | null> | null;
  } | null> | null;
};

export type PostFragment = {
  __typename: 'PostNode';
  id: string;
  owner: Owner | null;
  text: string;
  images: Array<string | null> | null;
  likesCount: number | null;
  created: any;
  poll: {
    __typename: 'PollNode';
    id: string;
    question: string;
    deadline: any;
    answersAllowed: number;
    randomizeOptions: boolean;
    allowRevote: boolean;
    allowComments: boolean;
    anonymousVotes: boolean;
    privateResults: boolean;
    created: any;
    options: Array<{
      __typename: 'PollOptionNode';
      id: string;
      text: string;
      image: string | null;
      percentage: number | null;
      votesNumber: number | null;
      votes: Array<{
        __typename: 'PollVoteNode';
        id: string;
        userGroup: {
          __typename: 'UserGroupNode';
          user: { __typename: 'UserNode'; id: string };
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
  likes: {
    __typename: 'LikeNodeConnection';
    edges: Array<{
      __typename: 'LikeNodeEdge';
      node: {
        __typename: 'LikeNode';
        userGroup: { __typename: 'UserGroupNode'; id: string } | null;
      } | null;
    } | null>;
  } | null;
  createdBy: {
    __typename: 'UserGroupNode';
    id: string;
    role: UserType | null;
    avatar: string | null;
    status: Status | null;
    created: any;
    defaultAvatar: {
      __typename: 'DefaultAvatarNode';
      id: string;
      avatar: string | null;
    } | null;
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      gender: UsersUserGenderChoices | null;
    };
  } | null;
  team: {
    __typename: 'TeamNode';
    id: string;
    name: string;
    type: TeamType | null;
    approvalSetting: ApprovalSetting | null;
    isHidden: boolean;
    created: any;
    color: { __typename: 'ColorNode'; id: string; hex: string } | null;
    icon: { __typename: 'IconNode'; id: string; icon: string } | null;
  } | null;
  comments: Array<{
    __typename: 'CommentNode';
    id: string;
    text: string;
    owner: Owner | null;
    likesCount: number | null;
    created: any;
    userGroup: {
      __typename: 'UserGroupNode';
      id: string;
      role: UserType | null;
      avatar: string | null;
      status: Status | null;
      created: any;
      defaultAvatar: {
        __typename: 'DefaultAvatarNode';
        id: string;
        avatar: string | null;
      } | null;
      user: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        gender: UsersUserGenderChoices | null;
      };
    } | null;
    team: {
      __typename: 'TeamNode';
      id: string;
      name: string;
      type: TeamType | null;
      approvalSetting: ApprovalSetting | null;
      isHidden: boolean;
      created: any;
      color: { __typename: 'ColorNode'; id: string; hex: string } | null;
      icon: { __typename: 'IconNode'; id: string; icon: string } | null;
    } | null;
    likes: {
      __typename: 'CommentLikeNodeConnection';
      edges: Array<{
        __typename: 'CommentLikeNodeEdge';
        node: {
          __typename: 'CommentLikeNode';
          userGroup: { __typename: 'UserGroupNode'; id: string } | null;
        } | null;
      } | null>;
    } | null;
  } | null> | null;
};

export type PollFragment = {
  __typename: 'PollNode';
  id: string;
  question: string;
  deadline: any;
  answersAllowed: number;
  randomizeOptions: boolean;
  allowRevote: boolean;
  allowComments: boolean;
  anonymousVotes: boolean;
  privateResults: boolean;
  created: any;
  post: {
    __typename: 'PostNode';
    id: string;
    owner: Owner | null;
    text: string;
    images: Array<string | null> | null;
    likesCount: number | null;
    created: any;
    likes: {
      __typename: 'LikeNodeConnection';
      edges: Array<{
        __typename: 'LikeNodeEdge';
        node: {
          __typename: 'LikeNode';
          userGroup: { __typename: 'UserGroupNode'; id: string } | null;
        } | null;
      } | null>;
    } | null;
    createdBy: {
      __typename: 'UserGroupNode';
      id: string;
      role: UserType | null;
      avatar: string | null;
      status: Status | null;
      created: any;
      defaultAvatar: {
        __typename: 'DefaultAvatarNode';
        id: string;
        avatar: string | null;
      } | null;
      user: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        gender: UsersUserGenderChoices | null;
      };
    } | null;
    team: {
      __typename: 'TeamNode';
      id: string;
      name: string;
      type: TeamType | null;
      approvalSetting: ApprovalSetting | null;
      isHidden: boolean;
      created: any;
      color: { __typename: 'ColorNode'; id: string; hex: string } | null;
      icon: { __typename: 'IconNode'; id: string; icon: string } | null;
    } | null;
    comments: Array<{
      __typename: 'CommentNode';
      id: string;
      text: string;
      owner: Owner | null;
      likesCount: number | null;
      created: any;
      userGroup: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      } | null;
      team: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
      likes: {
        __typename: 'CommentLikeNodeConnection';
        edges: Array<{
          __typename: 'CommentLikeNodeEdge';
          node: {
            __typename: 'CommentLikeNode';
            userGroup: { __typename: 'UserGroupNode'; id: string } | null;
          } | null;
        } | null>;
      } | null;
    } | null> | null;
  } | null;
  options: Array<{
    __typename: 'PollOptionNode';
    id: string;
    text: string;
    image: string | null;
    percentage: number | null;
    votesNumber: number | null;
    votes: Array<{
      __typename: 'PollVoteNode';
      id: string;
      userGroup: {
        __typename: 'UserGroupNode';
        user: { __typename: 'UserNode'; id: string };
      } | null;
    } | null> | null;
  } | null> | null;
};

export type PostsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  owner?: InputMaybe<Owner>;
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  createdBy_Group?: InputMaybe<Scalars['ID']['input']>;
  authUserGroupId: Scalars['ID']['input'];
}>;

export type PostsQuery = {
  __typename: 'Query';
  posts: {
    __typename: 'PostNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'PostNodeEdge';
      node: {
        __typename: 'PostNode';
        id: string;
        owner: Owner | null;
        text: string;
        images: Array<string | null> | null;
        likesCount: number | null;
        created: any;
        poll: {
          __typename: 'PollNode';
          id: string;
          question: string;
          deadline: any;
          answersAllowed: number;
          randomizeOptions: boolean;
          allowRevote: boolean;
          allowComments: boolean;
          anonymousVotes: boolean;
          privateResults: boolean;
          created: any;
          options: Array<{
            __typename: 'PollOptionNode';
            id: string;
            text: string;
            image: string | null;
            percentage: number | null;
            votesNumber: number | null;
            votes: Array<{
              __typename: 'PollVoteNode';
              id: string;
              userGroup: {
                __typename: 'UserGroupNode';
                user: { __typename: 'UserNode'; id: string };
              } | null;
            } | null> | null;
          } | null> | null;
        } | null;
        likes: {
          __typename: 'LikeNodeConnection';
          edges: Array<{
            __typename: 'LikeNodeEdge';
            node: {
              __typename: 'LikeNode';
              userGroup: { __typename: 'UserGroupNode'; id: string } | null;
            } | null;
          } | null>;
        } | null;
        createdBy: {
          __typename: 'UserGroupNode';
          id: string;
          role: UserType | null;
          avatar: string | null;
          status: Status | null;
          created: any;
          defaultAvatar: {
            __typename: 'DefaultAvatarNode';
            id: string;
            avatar: string | null;
          } | null;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            gender: UsersUserGenderChoices | null;
          };
        } | null;
        team: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
        comments: Array<{
          __typename: 'CommentNode';
          id: string;
          text: string;
          owner: Owner | null;
          likesCount: number | null;
          created: any;
          userGroup: {
            __typename: 'UserGroupNode';
            id: string;
            role: UserType | null;
            avatar: string | null;
            status: Status | null;
            created: any;
            defaultAvatar: {
              __typename: 'DefaultAvatarNode';
              id: string;
              avatar: string | null;
            } | null;
            user: {
              __typename: 'UserNode';
              id: string;
              firstName: string;
              lastName: string;
              gender: UsersUserGenderChoices | null;
            };
          } | null;
          team: {
            __typename: 'TeamNode';
            id: string;
            name: string;
            type: TeamType | null;
            approvalSetting: ApprovalSetting | null;
            isHidden: boolean;
            created: any;
            color: { __typename: 'ColorNode'; id: string; hex: string } | null;
            icon: { __typename: 'IconNode'; id: string; icon: string } | null;
          } | null;
          likes: {
            __typename: 'CommentLikeNodeConnection';
            edges: Array<{
              __typename: 'CommentLikeNodeEdge';
              node: {
                __typename: 'CommentLikeNode';
                userGroup: { __typename: 'UserGroupNode'; id: string } | null;
              } | null;
            } | null>;
          } | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type PostQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  authUserGroupId: Scalars['ID']['input'];
}>;

export type PostQuery = {
  __typename: 'Query';
  post: {
    __typename: 'PostNode';
    id: string;
    owner: Owner | null;
    text: string;
    images: Array<string | null> | null;
    likesCount: number | null;
    created: any;
    poll: {
      __typename: 'PollNode';
      id: string;
      question: string;
      deadline: any;
      answersAllowed: number;
      randomizeOptions: boolean;
      allowRevote: boolean;
      allowComments: boolean;
      anonymousVotes: boolean;
      privateResults: boolean;
      created: any;
      options: Array<{
        __typename: 'PollOptionNode';
        id: string;
        text: string;
        image: string | null;
        percentage: number | null;
        votesNumber: number | null;
        votes: Array<{
          __typename: 'PollVoteNode';
          id: string;
          userGroup: {
            __typename: 'UserGroupNode';
            user: { __typename: 'UserNode'; id: string };
          } | null;
        } | null> | null;
      } | null> | null;
    } | null;
    likes: {
      __typename: 'LikeNodeConnection';
      edges: Array<{
        __typename: 'LikeNodeEdge';
        node: {
          __typename: 'LikeNode';
          userGroup: { __typename: 'UserGroupNode'; id: string } | null;
        } | null;
      } | null>;
    } | null;
    createdBy: {
      __typename: 'UserGroupNode';
      id: string;
      role: UserType | null;
      avatar: string | null;
      status: Status | null;
      created: any;
      defaultAvatar: {
        __typename: 'DefaultAvatarNode';
        id: string;
        avatar: string | null;
      } | null;
      user: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        gender: UsersUserGenderChoices | null;
      };
    } | null;
    team: {
      __typename: 'TeamNode';
      id: string;
      name: string;
      type: TeamType | null;
      approvalSetting: ApprovalSetting | null;
      isHidden: boolean;
      created: any;
      color: { __typename: 'ColorNode'; id: string; hex: string } | null;
      icon: { __typename: 'IconNode'; id: string; icon: string } | null;
    } | null;
    comments: Array<{
      __typename: 'CommentNode';
      id: string;
      text: string;
      owner: Owner | null;
      likesCount: number | null;
      created: any;
      userGroup: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      } | null;
      team: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
      likes: {
        __typename: 'CommentLikeNodeConnection';
        edges: Array<{
          __typename: 'CommentLikeNodeEdge';
          node: {
            __typename: 'CommentLikeNode';
            userGroup: { __typename: 'UserGroupNode'; id: string } | null;
          } | null;
        } | null>;
      } | null;
    } | null> | null;
  } | null;
};

export type CreatePostMutationVariables = Exact<{
  input: CreatePostInput;
  authUserGroupId: Scalars['ID']['input'];
}>;

export type CreatePostMutation = {
  __typename: 'Mutation';
  createPost: {
    __typename: 'CreatePostPayload';
    post: {
      __typename: 'PostNode';
      id: string;
      owner: Owner | null;
      text: string;
      images: Array<string | null> | null;
      likesCount: number | null;
      created: any;
      poll: {
        __typename: 'PollNode';
        id: string;
        question: string;
        deadline: any;
        answersAllowed: number;
        randomizeOptions: boolean;
        allowRevote: boolean;
        allowComments: boolean;
        anonymousVotes: boolean;
        privateResults: boolean;
        created: any;
        options: Array<{
          __typename: 'PollOptionNode';
          id: string;
          text: string;
          image: string | null;
          percentage: number | null;
          votesNumber: number | null;
          votes: Array<{
            __typename: 'PollVoteNode';
            id: string;
            userGroup: {
              __typename: 'UserGroupNode';
              user: { __typename: 'UserNode'; id: string };
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
      likes: {
        __typename: 'LikeNodeConnection';
        edges: Array<{
          __typename: 'LikeNodeEdge';
          node: {
            __typename: 'LikeNode';
            userGroup: { __typename: 'UserGroupNode'; id: string } | null;
          } | null;
        } | null>;
      } | null;
      createdBy: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      } | null;
      team: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
      comments: Array<{
        __typename: 'CommentNode';
        id: string;
        text: string;
        owner: Owner | null;
        likesCount: number | null;
        created: any;
        userGroup: {
          __typename: 'UserGroupNode';
          id: string;
          role: UserType | null;
          avatar: string | null;
          status: Status | null;
          created: any;
          defaultAvatar: {
            __typename: 'DefaultAvatarNode';
            id: string;
            avatar: string | null;
          } | null;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            gender: UsersUserGenderChoices | null;
          };
        } | null;
        team: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
        likes: {
          __typename: 'CommentLikeNodeConnection';
          edges: Array<{
            __typename: 'CommentLikeNodeEdge';
            node: {
              __typename: 'CommentLikeNode';
              userGroup: { __typename: 'UserGroupNode'; id: string } | null;
            } | null;
          } | null>;
        } | null;
      } | null> | null;
    } | null;
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
  } | null;
};

export type DeletePostMutationVariables = Exact<{
  input: DeletePostInput;
  authUserGroupId: Scalars['ID']['input'];
}>;

export type DeletePostMutation = {
  __typename: 'Mutation';
  deletePost: {
    __typename: 'DeletePostPayload';
    post: {
      __typename: 'PostNode';
      id: string;
      owner: Owner | null;
      text: string;
      images: Array<string | null> | null;
      likesCount: number | null;
      created: any;
      poll: {
        __typename: 'PollNode';
        id: string;
        question: string;
        deadline: any;
        answersAllowed: number;
        randomizeOptions: boolean;
        allowRevote: boolean;
        allowComments: boolean;
        anonymousVotes: boolean;
        privateResults: boolean;
        created: any;
        options: Array<{
          __typename: 'PollOptionNode';
          id: string;
          text: string;
          image: string | null;
          percentage: number | null;
          votesNumber: number | null;
          votes: Array<{
            __typename: 'PollVoteNode';
            id: string;
            userGroup: {
              __typename: 'UserGroupNode';
              user: { __typename: 'UserNode'; id: string };
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
      likes: {
        __typename: 'LikeNodeConnection';
        edges: Array<{
          __typename: 'LikeNodeEdge';
          node: {
            __typename: 'LikeNode';
            userGroup: { __typename: 'UserGroupNode'; id: string } | null;
          } | null;
        } | null>;
      } | null;
      createdBy: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      } | null;
      team: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
      comments: Array<{
        __typename: 'CommentNode';
        id: string;
        text: string;
        owner: Owner | null;
        likesCount: number | null;
        created: any;
        userGroup: {
          __typename: 'UserGroupNode';
          id: string;
          role: UserType | null;
          avatar: string | null;
          status: Status | null;
          created: any;
          defaultAvatar: {
            __typename: 'DefaultAvatarNode';
            id: string;
            avatar: string | null;
          } | null;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            gender: UsersUserGenderChoices | null;
          };
        } | null;
        team: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
        likes: {
          __typename: 'CommentLikeNodeConnection';
          edges: Array<{
            __typename: 'CommentLikeNodeEdge';
            node: {
              __typename: 'CommentLikeNode';
              userGroup: { __typename: 'UserGroupNode'; id: string } | null;
            } | null;
          } | null>;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ReportPostContentMutationVariables = Exact<{
  input: ReportPostContentInput;
}>;

export type ReportPostContentMutation = {
  __typename: 'Mutation';
  reportPostContent: {
    __typename: 'ReportPostContentPayload';
    success: boolean | null;
    message: string | null;
  } | null;
};

export type BlockUserMutationVariables = Exact<{
  input: BlockUserMutationInput;
}>;

export type BlockUserMutation = {
  __typename: 'Mutation';
  blockUser: {
    __typename: 'BlockUserMutationPayload';
    success: boolean | null;
    message: string | null;
  } | null;
};

export type ReportMutationMutationVariables = Exact<{
  input: ReportMutationInput;
}>;

export type ReportMutationMutation = {
  __typename: 'Mutation';
  reportMutation: {
    __typename: 'ReportMutationPayload';
    success: boolean | null;
    message: string | null;
  } | null;
};

export type AddImagesToPostMutationVariables = Exact<{
  input: AddImagesToPostInput;
  authUserGroupId: Scalars['ID']['input'];
}>;

export type AddImagesToPostMutation = {
  __typename: 'Mutation';
  addImagesToPost: {
    __typename: 'AddImagesToPostPayload';
    post: {
      __typename: 'PostNode';
      id: string;
      owner: Owner | null;
      text: string;
      images: Array<string | null> | null;
      likesCount: number | null;
      created: any;
      poll: {
        __typename: 'PollNode';
        id: string;
        question: string;
        deadline: any;
        answersAllowed: number;
        randomizeOptions: boolean;
        allowRevote: boolean;
        allowComments: boolean;
        anonymousVotes: boolean;
        privateResults: boolean;
        created: any;
        options: Array<{
          __typename: 'PollOptionNode';
          id: string;
          text: string;
          image: string | null;
          percentage: number | null;
          votesNumber: number | null;
          votes: Array<{
            __typename: 'PollVoteNode';
            id: string;
            userGroup: {
              __typename: 'UserGroupNode';
              user: { __typename: 'UserNode'; id: string };
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
      likes: {
        __typename: 'LikeNodeConnection';
        edges: Array<{
          __typename: 'LikeNodeEdge';
          node: {
            __typename: 'LikeNode';
            userGroup: { __typename: 'UserGroupNode'; id: string } | null;
          } | null;
        } | null>;
      } | null;
      createdBy: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      } | null;
      team: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
      comments: Array<{
        __typename: 'CommentNode';
        id: string;
        text: string;
        owner: Owner | null;
        likesCount: number | null;
        created: any;
        userGroup: {
          __typename: 'UserGroupNode';
          id: string;
          role: UserType | null;
          avatar: string | null;
          status: Status | null;
          created: any;
          defaultAvatar: {
            __typename: 'DefaultAvatarNode';
            id: string;
            avatar: string | null;
          } | null;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            gender: UsersUserGenderChoices | null;
          };
        } | null;
        team: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
        likes: {
          __typename: 'CommentLikeNodeConnection';
          edges: Array<{
            __typename: 'CommentLikeNodeEdge';
            node: {
              __typename: 'CommentLikeNode';
              userGroup: { __typename: 'UserGroupNode'; id: string } | null;
            } | null;
          } | null>;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type PollsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  post_Team?: InputMaybe<Scalars['ID']['input']>;
  post_CreatedBy_Group?: InputMaybe<Scalars['ID']['input']>;
  authUserGroupId: Scalars['ID']['input'];
}>;

export type PollsQuery = {
  __typename: 'Query';
  polls: {
    __typename: 'PollNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'PollNodeEdge';
      node: {
        __typename: 'PollNode';
        id: string;
        question: string;
        deadline: any;
        answersAllowed: number;
        randomizeOptions: boolean;
        allowRevote: boolean;
        allowComments: boolean;
        anonymousVotes: boolean;
        privateResults: boolean;
        created: any;
        post: {
          __typename: 'PostNode';
          id: string;
          owner: Owner | null;
          text: string;
          images: Array<string | null> | null;
          likesCount: number | null;
          created: any;
          likes: {
            __typename: 'LikeNodeConnection';
            edges: Array<{
              __typename: 'LikeNodeEdge';
              node: {
                __typename: 'LikeNode';
                userGroup: { __typename: 'UserGroupNode'; id: string } | null;
              } | null;
            } | null>;
          } | null;
          createdBy: {
            __typename: 'UserGroupNode';
            id: string;
            role: UserType | null;
            avatar: string | null;
            status: Status | null;
            created: any;
            defaultAvatar: {
              __typename: 'DefaultAvatarNode';
              id: string;
              avatar: string | null;
            } | null;
            user: {
              __typename: 'UserNode';
              id: string;
              firstName: string;
              lastName: string;
              gender: UsersUserGenderChoices | null;
            };
          } | null;
          team: {
            __typename: 'TeamNode';
            id: string;
            name: string;
            type: TeamType | null;
            approvalSetting: ApprovalSetting | null;
            isHidden: boolean;
            created: any;
            color: { __typename: 'ColorNode'; id: string; hex: string } | null;
            icon: { __typename: 'IconNode'; id: string; icon: string } | null;
          } | null;
          comments: Array<{
            __typename: 'CommentNode';
            id: string;
            text: string;
            owner: Owner | null;
            likesCount: number | null;
            created: any;
            userGroup: {
              __typename: 'UserGroupNode';
              id: string;
              role: UserType | null;
              avatar: string | null;
              status: Status | null;
              created: any;
              defaultAvatar: {
                __typename: 'DefaultAvatarNode';
                id: string;
                avatar: string | null;
              } | null;
              user: {
                __typename: 'UserNode';
                id: string;
                firstName: string;
                lastName: string;
                gender: UsersUserGenderChoices | null;
              };
            } | null;
            team: {
              __typename: 'TeamNode';
              id: string;
              name: string;
              type: TeamType | null;
              approvalSetting: ApprovalSetting | null;
              isHidden: boolean;
              created: any;
              color: {
                __typename: 'ColorNode';
                id: string;
                hex: string;
              } | null;
              icon: { __typename: 'IconNode'; id: string; icon: string } | null;
            } | null;
            likes: {
              __typename: 'CommentLikeNodeConnection';
              edges: Array<{
                __typename: 'CommentLikeNodeEdge';
                node: {
                  __typename: 'CommentLikeNode';
                  userGroup: { __typename: 'UserGroupNode'; id: string } | null;
                } | null;
              } | null>;
            } | null;
          } | null> | null;
        } | null;
        options: Array<{
          __typename: 'PollOptionNode';
          id: string;
          text: string;
          image: string | null;
          percentage: number | null;
          votesNumber: number | null;
          votes: Array<{
            __typename: 'PollVoteNode';
            id: string;
            userGroup: {
              __typename: 'UserGroupNode';
              user: { __typename: 'UserNode'; id: string };
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type CreatePollMutationVariables = Exact<{
  input: CreatePollInput;
  authUserGroupId: Scalars['ID']['input'];
}>;

export type CreatePollMutation = {
  __typename: 'Mutation';
  createPoll: {
    __typename: 'CreatePollPayload';
    poll: {
      __typename: 'PollNode';
      id: string;
      question: string;
      deadline: any;
      answersAllowed: number;
      randomizeOptions: boolean;
      allowRevote: boolean;
      allowComments: boolean;
      anonymousVotes: boolean;
      privateResults: boolean;
      created: any;
      post: {
        __typename: 'PostNode';
        id: string;
        owner: Owner | null;
        text: string;
        images: Array<string | null> | null;
        likesCount: number | null;
        created: any;
        likes: {
          __typename: 'LikeNodeConnection';
          edges: Array<{
            __typename: 'LikeNodeEdge';
            node: {
              __typename: 'LikeNode';
              userGroup: { __typename: 'UserGroupNode'; id: string } | null;
            } | null;
          } | null>;
        } | null;
        createdBy: {
          __typename: 'UserGroupNode';
          id: string;
          role: UserType | null;
          avatar: string | null;
          status: Status | null;
          created: any;
          defaultAvatar: {
            __typename: 'DefaultAvatarNode';
            id: string;
            avatar: string | null;
          } | null;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            gender: UsersUserGenderChoices | null;
          };
        } | null;
        team: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
        comments: Array<{
          __typename: 'CommentNode';
          id: string;
          text: string;
          owner: Owner | null;
          likesCount: number | null;
          created: any;
          userGroup: {
            __typename: 'UserGroupNode';
            id: string;
            role: UserType | null;
            avatar: string | null;
            status: Status | null;
            created: any;
            defaultAvatar: {
              __typename: 'DefaultAvatarNode';
              id: string;
              avatar: string | null;
            } | null;
            user: {
              __typename: 'UserNode';
              id: string;
              firstName: string;
              lastName: string;
              gender: UsersUserGenderChoices | null;
            };
          } | null;
          team: {
            __typename: 'TeamNode';
            id: string;
            name: string;
            type: TeamType | null;
            approvalSetting: ApprovalSetting | null;
            isHidden: boolean;
            created: any;
            color: { __typename: 'ColorNode'; id: string; hex: string } | null;
            icon: { __typename: 'IconNode'; id: string; icon: string } | null;
          } | null;
          likes: {
            __typename: 'CommentLikeNodeConnection';
            edges: Array<{
              __typename: 'CommentLikeNodeEdge';
              node: {
                __typename: 'CommentLikeNode';
                userGroup: { __typename: 'UserGroupNode'; id: string } | null;
              } | null;
            } | null>;
          } | null;
        } | null> | null;
      } | null;
      options: Array<{
        __typename: 'PollOptionNode';
        id: string;
        text: string;
        image: string | null;
        percentage: number | null;
        votesNumber: number | null;
        votes: Array<{
          __typename: 'PollVoteNode';
          id: string;
          userGroup: {
            __typename: 'UserGroupNode';
            user: { __typename: 'UserNode'; id: string };
          } | null;
        } | null> | null;
      } | null> | null;
    } | null;
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
  } | null;
};

export type DeletePollMutationVariables = Exact<{
  input: DeletePollInput;
  authUserGroupId: Scalars['ID']['input'];
}>;

export type DeletePollMutation = {
  __typename: 'Mutation';
  deletePoll: {
    __typename: 'DeletePollPayload';
    poll: {
      __typename: 'PollNode';
      id: string;
      question: string;
      deadline: any;
      answersAllowed: number;
      randomizeOptions: boolean;
      allowRevote: boolean;
      allowComments: boolean;
      anonymousVotes: boolean;
      privateResults: boolean;
      created: any;
      post: {
        __typename: 'PostNode';
        id: string;
        owner: Owner | null;
        text: string;
        images: Array<string | null> | null;
        likesCount: number | null;
        created: any;
        likes: {
          __typename: 'LikeNodeConnection';
          edges: Array<{
            __typename: 'LikeNodeEdge';
            node: {
              __typename: 'LikeNode';
              userGroup: { __typename: 'UserGroupNode'; id: string } | null;
            } | null;
          } | null>;
        } | null;
        createdBy: {
          __typename: 'UserGroupNode';
          id: string;
          role: UserType | null;
          avatar: string | null;
          status: Status | null;
          created: any;
          defaultAvatar: {
            __typename: 'DefaultAvatarNode';
            id: string;
            avatar: string | null;
          } | null;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            gender: UsersUserGenderChoices | null;
          };
        } | null;
        team: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
        comments: Array<{
          __typename: 'CommentNode';
          id: string;
          text: string;
          owner: Owner | null;
          likesCount: number | null;
          created: any;
          userGroup: {
            __typename: 'UserGroupNode';
            id: string;
            role: UserType | null;
            avatar: string | null;
            status: Status | null;
            created: any;
            defaultAvatar: {
              __typename: 'DefaultAvatarNode';
              id: string;
              avatar: string | null;
            } | null;
            user: {
              __typename: 'UserNode';
              id: string;
              firstName: string;
              lastName: string;
              gender: UsersUserGenderChoices | null;
            };
          } | null;
          team: {
            __typename: 'TeamNode';
            id: string;
            name: string;
            type: TeamType | null;
            approvalSetting: ApprovalSetting | null;
            isHidden: boolean;
            created: any;
            color: { __typename: 'ColorNode'; id: string; hex: string } | null;
            icon: { __typename: 'IconNode'; id: string; icon: string } | null;
          } | null;
          likes: {
            __typename: 'CommentLikeNodeConnection';
            edges: Array<{
              __typename: 'CommentLikeNodeEdge';
              node: {
                __typename: 'CommentLikeNode';
                userGroup: { __typename: 'UserGroupNode'; id: string } | null;
              } | null;
            } | null>;
          } | null;
        } | null> | null;
      } | null;
      options: Array<{
        __typename: 'PollOptionNode';
        id: string;
        text: string;
        image: string | null;
        percentage: number | null;
        votesNumber: number | null;
        votes: Array<{
          __typename: 'PollVoteNode';
          id: string;
          userGroup: {
            __typename: 'UserGroupNode';
            user: { __typename: 'UserNode'; id: string };
          } | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CreatePollOptionMutationVariables = Exact<{
  input: CreatePollOptionInput;
}>;

export type CreatePollOptionMutation = {
  __typename: 'Mutation';
  createPollOption: {
    __typename: 'CreatePollOptionPayload';
    pollOption: {
      __typename: 'PollOptionNode';
      id: string;
      text: string;
      image: string | null;
      percentage: number | null;
      votesNumber: number | null;
      votes: Array<{
        __typename: 'PollVoteNode';
        id: string;
        userGroup: {
          __typename: 'UserGroupNode';
          user: { __typename: 'UserNode'; id: string };
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CreatePollVoteMutationVariables = Exact<{
  input: CreatePollVoteInput;
}>;

export type CreatePollVoteMutation = {
  __typename: 'Mutation';
  createPollVote: {
    __typename: 'CreatePollVotePayload';
    pollVote: {
      __typename: 'PollVoteNode';
      id: string;
      userGroup: {
        __typename: 'UserGroupNode';
        user: { __typename: 'UserNode'; id: string };
      } | null;
    } | null;
  } | null;
};

export type UpdatePollMutationVariables = Exact<{
  input: UpdatePollInput;
}>;

export type UpdatePollMutation = {
  __typename: 'Mutation';
  updatePoll: {
    __typename: 'UpdatePollPayload';
    poll: {
      __typename: 'PollNode';
      id: string;
      question: string;
      deadline: any;
      answersAllowed: number;
      randomizeOptions: boolean;
      allowRevote: boolean;
      allowComments: boolean;
      anonymousVotes: boolean;
      privateResults: boolean;
      created: any;
      options: Array<{
        __typename: 'PollOptionNode';
        id: string;
        text: string;
        image: string | null;
        percentage: number | null;
        votesNumber: number | null;
        votes: Array<{
          __typename: 'PollVoteNode';
          id: string;
          userGroup: {
            __typename: 'UserGroupNode';
            user: { __typename: 'UserNode'; id: string };
          } | null;
        } | null> | null;
      } | null> | null;
    } | null;
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
  } | null;
};

export type DeletePollVoteMutationVariables = Exact<{
  input: DeletePollVoteInput;
}>;

export type DeletePollVoteMutation = {
  __typename: 'Mutation';
  deletePollVote: {
    __typename: 'DeletePollVotePayload';
    pollVote: {
      __typename: 'PollVoteNode';
      id: string;
      userGroup: {
        __typename: 'UserGroupNode';
        user: { __typename: 'UserNode'; id: string };
      } | null;
    } | null;
  } | null;
};

export type LikePostMutationVariables = Exact<{
  input: LikePostInput;
  authUserGroupId: Scalars['ID']['input'];
}>;

export type LikePostMutation = {
  __typename: 'Mutation';
  likePost: {
    __typename: 'LikePostPayload';
    post: {
      __typename: 'PostNode';
      id: string;
      owner: Owner | null;
      text: string;
      images: Array<string | null> | null;
      likesCount: number | null;
      created: any;
      poll: {
        __typename: 'PollNode';
        id: string;
        question: string;
        deadline: any;
        answersAllowed: number;
        randomizeOptions: boolean;
        allowRevote: boolean;
        allowComments: boolean;
        anonymousVotes: boolean;
        privateResults: boolean;
        created: any;
        options: Array<{
          __typename: 'PollOptionNode';
          id: string;
          text: string;
          image: string | null;
          percentage: number | null;
          votesNumber: number | null;
          votes: Array<{
            __typename: 'PollVoteNode';
            id: string;
            userGroup: {
              __typename: 'UserGroupNode';
              user: { __typename: 'UserNode'; id: string };
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
      likes: {
        __typename: 'LikeNodeConnection';
        edges: Array<{
          __typename: 'LikeNodeEdge';
          node: {
            __typename: 'LikeNode';
            userGroup: { __typename: 'UserGroupNode'; id: string } | null;
          } | null;
        } | null>;
      } | null;
      createdBy: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      } | null;
      team: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
      comments: Array<{
        __typename: 'CommentNode';
        id: string;
        text: string;
        owner: Owner | null;
        likesCount: number | null;
        created: any;
        userGroup: {
          __typename: 'UserGroupNode';
          id: string;
          role: UserType | null;
          avatar: string | null;
          status: Status | null;
          created: any;
          defaultAvatar: {
            __typename: 'DefaultAvatarNode';
            id: string;
            avatar: string | null;
          } | null;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            gender: UsersUserGenderChoices | null;
          };
        } | null;
        team: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
        likes: {
          __typename: 'CommentLikeNodeConnection';
          edges: Array<{
            __typename: 'CommentLikeNodeEdge';
            node: {
              __typename: 'CommentLikeNode';
              userGroup: { __typename: 'UserGroupNode'; id: string } | null;
            } | null;
          } | null>;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type RemoveLikeFromPostMutationVariables = Exact<{
  input: RemoveLikeFromPostInput;
  authUserGroupId: Scalars['ID']['input'];
}>;

export type RemoveLikeFromPostMutation = {
  __typename: 'Mutation';
  removeLikeFromPost: {
    __typename: 'RemoveLikeFromPostPayload';
    post: {
      __typename: 'PostNode';
      id: string;
      owner: Owner | null;
      text: string;
      images: Array<string | null> | null;
      likesCount: number | null;
      created: any;
      poll: {
        __typename: 'PollNode';
        id: string;
        question: string;
        deadline: any;
        answersAllowed: number;
        randomizeOptions: boolean;
        allowRevote: boolean;
        allowComments: boolean;
        anonymousVotes: boolean;
        privateResults: boolean;
        created: any;
        options: Array<{
          __typename: 'PollOptionNode';
          id: string;
          text: string;
          image: string | null;
          percentage: number | null;
          votesNumber: number | null;
          votes: Array<{
            __typename: 'PollVoteNode';
            id: string;
            userGroup: {
              __typename: 'UserGroupNode';
              user: { __typename: 'UserNode'; id: string };
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
      likes: {
        __typename: 'LikeNodeConnection';
        edges: Array<{
          __typename: 'LikeNodeEdge';
          node: {
            __typename: 'LikeNode';
            userGroup: { __typename: 'UserGroupNode'; id: string } | null;
          } | null;
        } | null>;
      } | null;
      createdBy: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      } | null;
      team: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
      comments: Array<{
        __typename: 'CommentNode';
        id: string;
        text: string;
        owner: Owner | null;
        likesCount: number | null;
        created: any;
        userGroup: {
          __typename: 'UserGroupNode';
          id: string;
          role: UserType | null;
          avatar: string | null;
          status: Status | null;
          created: any;
          defaultAvatar: {
            __typename: 'DefaultAvatarNode';
            id: string;
            avatar: string | null;
          } | null;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            gender: UsersUserGenderChoices | null;
          };
        } | null;
        team: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
        likes: {
          __typename: 'CommentLikeNodeConnection';
          edges: Array<{
            __typename: 'CommentLikeNodeEdge';
            node: {
              __typename: 'CommentLikeNode';
              userGroup: { __typename: 'UserGroupNode'; id: string } | null;
            } | null;
          } | null>;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CommentPostMutationVariables = Exact<{
  input: CommentPostInput;
  authUserGroupId: Scalars['ID']['input'];
}>;

export type CommentPostMutation = {
  __typename: 'Mutation';
  commentPost: {
    __typename: 'CommentPostPayload';
    post: {
      __typename: 'PostNode';
      id: string;
      owner: Owner | null;
      text: string;
      images: Array<string | null> | null;
      likesCount: number | null;
      created: any;
      poll: {
        __typename: 'PollNode';
        id: string;
        question: string;
        deadline: any;
        answersAllowed: number;
        randomizeOptions: boolean;
        allowRevote: boolean;
        allowComments: boolean;
        anonymousVotes: boolean;
        privateResults: boolean;
        created: any;
        options: Array<{
          __typename: 'PollOptionNode';
          id: string;
          text: string;
          image: string | null;
          percentage: number | null;
          votesNumber: number | null;
          votes: Array<{
            __typename: 'PollVoteNode';
            id: string;
            userGroup: {
              __typename: 'UserGroupNode';
              user: { __typename: 'UserNode'; id: string };
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
      likes: {
        __typename: 'LikeNodeConnection';
        edges: Array<{
          __typename: 'LikeNodeEdge';
          node: {
            __typename: 'LikeNode';
            userGroup: { __typename: 'UserGroupNode'; id: string } | null;
          } | null;
        } | null>;
      } | null;
      createdBy: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      } | null;
      team: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
      comments: Array<{
        __typename: 'CommentNode';
        id: string;
        text: string;
        owner: Owner | null;
        likesCount: number | null;
        created: any;
        userGroup: {
          __typename: 'UserGroupNode';
          id: string;
          role: UserType | null;
          avatar: string | null;
          status: Status | null;
          created: any;
          defaultAvatar: {
            __typename: 'DefaultAvatarNode';
            id: string;
            avatar: string | null;
          } | null;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            gender: UsersUserGenderChoices | null;
          };
        } | null;
        team: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
        likes: {
          __typename: 'CommentLikeNodeConnection';
          edges: Array<{
            __typename: 'CommentLikeNodeEdge';
            node: {
              __typename: 'CommentLikeNode';
              userGroup: { __typename: 'UserGroupNode'; id: string } | null;
            } | null;
          } | null>;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type LikeCommentMutationVariables = Exact<{
  input: LikeCommentInput;
  authUserGroupId: Scalars['ID']['input'];
}>;

export type LikeCommentMutation = {
  __typename: 'Mutation';
  likeComment: {
    __typename: 'LikeCommentPayload';
    post: {
      __typename: 'PostNode';
      id: string;
      owner: Owner | null;
      text: string;
      images: Array<string | null> | null;
      likesCount: number | null;
      created: any;
      poll: {
        __typename: 'PollNode';
        id: string;
        question: string;
        deadline: any;
        answersAllowed: number;
        randomizeOptions: boolean;
        allowRevote: boolean;
        allowComments: boolean;
        anonymousVotes: boolean;
        privateResults: boolean;
        created: any;
        options: Array<{
          __typename: 'PollOptionNode';
          id: string;
          text: string;
          image: string | null;
          percentage: number | null;
          votesNumber: number | null;
          votes: Array<{
            __typename: 'PollVoteNode';
            id: string;
            userGroup: {
              __typename: 'UserGroupNode';
              user: { __typename: 'UserNode'; id: string };
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
      likes: {
        __typename: 'LikeNodeConnection';
        edges: Array<{
          __typename: 'LikeNodeEdge';
          node: {
            __typename: 'LikeNode';
            userGroup: { __typename: 'UserGroupNode'; id: string } | null;
          } | null;
        } | null>;
      } | null;
      createdBy: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      } | null;
      team: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
      comments: Array<{
        __typename: 'CommentNode';
        id: string;
        text: string;
        owner: Owner | null;
        likesCount: number | null;
        created: any;
        userGroup: {
          __typename: 'UserGroupNode';
          id: string;
          role: UserType | null;
          avatar: string | null;
          status: Status | null;
          created: any;
          defaultAvatar: {
            __typename: 'DefaultAvatarNode';
            id: string;
            avatar: string | null;
          } | null;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            gender: UsersUserGenderChoices | null;
          };
        } | null;
        team: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
        likes: {
          __typename: 'CommentLikeNodeConnection';
          edges: Array<{
            __typename: 'CommentLikeNodeEdge';
            node: {
              __typename: 'CommentLikeNode';
              userGroup: { __typename: 'UserGroupNode'; id: string } | null;
            } | null;
          } | null>;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type RemoveLikeFromCommentMutationVariables = Exact<{
  input: RemoveLikeFromCommentInput;
  authUserGroupId: Scalars['ID']['input'];
}>;

export type RemoveLikeFromCommentMutation = {
  __typename: 'Mutation';
  removeLikeFromComment: {
    __typename: 'RemoveLikeFromCommentPayload';
    post: {
      __typename: 'PostNode';
      id: string;
      owner: Owner | null;
      text: string;
      images: Array<string | null> | null;
      likesCount: number | null;
      created: any;
      poll: {
        __typename: 'PollNode';
        id: string;
        question: string;
        deadline: any;
        answersAllowed: number;
        randomizeOptions: boolean;
        allowRevote: boolean;
        allowComments: boolean;
        anonymousVotes: boolean;
        privateResults: boolean;
        created: any;
        options: Array<{
          __typename: 'PollOptionNode';
          id: string;
          text: string;
          image: string | null;
          percentage: number | null;
          votesNumber: number | null;
          votes: Array<{
            __typename: 'PollVoteNode';
            id: string;
            userGroup: {
              __typename: 'UserGroupNode';
              user: { __typename: 'UserNode'; id: string };
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
      likes: {
        __typename: 'LikeNodeConnection';
        edges: Array<{
          __typename: 'LikeNodeEdge';
          node: {
            __typename: 'LikeNode';
            userGroup: { __typename: 'UserGroupNode'; id: string } | null;
          } | null;
        } | null>;
      } | null;
      createdBy: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      } | null;
      team: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
      comments: Array<{
        __typename: 'CommentNode';
        id: string;
        text: string;
        owner: Owner | null;
        likesCount: number | null;
        created: any;
        userGroup: {
          __typename: 'UserGroupNode';
          id: string;
          role: UserType | null;
          avatar: string | null;
          status: Status | null;
          created: any;
          defaultAvatar: {
            __typename: 'DefaultAvatarNode';
            id: string;
            avatar: string | null;
          } | null;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            gender: UsersUserGenderChoices | null;
          };
        } | null;
        team: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
        likes: {
          __typename: 'CommentLikeNodeConnection';
          edges: Array<{
            __typename: 'CommentLikeNodeEdge';
            node: {
              __typename: 'CommentLikeNode';
              userGroup: { __typename: 'UserGroupNode'; id: string } | null;
            } | null;
          } | null>;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type DeleteCommentMutationVariables = Exact<{
  input: DeleteCommentInput;
  authUserGroupId: Scalars['ID']['input'];
}>;

export type DeleteCommentMutation = {
  __typename: 'Mutation';
  deleteComment: {
    __typename: 'DeleteCommentPayload';
    post: {
      __typename: 'PostNode';
      id: string;
      owner: Owner | null;
      text: string;
      images: Array<string | null> | null;
      likesCount: number | null;
      created: any;
      poll: {
        __typename: 'PollNode';
        id: string;
        question: string;
        deadline: any;
        answersAllowed: number;
        randomizeOptions: boolean;
        allowRevote: boolean;
        allowComments: boolean;
        anonymousVotes: boolean;
        privateResults: boolean;
        created: any;
        options: Array<{
          __typename: 'PollOptionNode';
          id: string;
          text: string;
          image: string | null;
          percentage: number | null;
          votesNumber: number | null;
          votes: Array<{
            __typename: 'PollVoteNode';
            id: string;
            userGroup: {
              __typename: 'UserGroupNode';
              user: { __typename: 'UserNode'; id: string };
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
      likes: {
        __typename: 'LikeNodeConnection';
        edges: Array<{
          __typename: 'LikeNodeEdge';
          node: {
            __typename: 'LikeNode';
            userGroup: { __typename: 'UserGroupNode'; id: string } | null;
          } | null;
        } | null>;
      } | null;
      createdBy: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      } | null;
      team: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
      comments: Array<{
        __typename: 'CommentNode';
        id: string;
        text: string;
        owner: Owner | null;
        likesCount: number | null;
        created: any;
        userGroup: {
          __typename: 'UserGroupNode';
          id: string;
          role: UserType | null;
          avatar: string | null;
          status: Status | null;
          created: any;
          defaultAvatar: {
            __typename: 'DefaultAvatarNode';
            id: string;
            avatar: string | null;
          } | null;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            gender: UsersUserGenderChoices | null;
          };
        } | null;
        team: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
        likes: {
          __typename: 'CommentLikeNodeConnection';
          edges: Array<{
            __typename: 'CommentLikeNodeEdge';
            node: {
              __typename: 'CommentLikeNode';
              userGroup: { __typename: 'UserGroupNode'; id: string } | null;
            } | null;
          } | null>;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GenerateShopifyUrlMutationVariables = Exact<{
  input: GenerateShopifyRedirectUrlInput;
}>;

export type GenerateShopifyUrlMutation = {
  __typename: 'Mutation';
  generateShopifyUrl: {
    __typename: 'GenerateShopifyRedirectUrlPayload';
    redirectUrl: string | null;
  } | null;
};

export type TeamUserFragment = {
  __typename: 'UserTeamNode';
  id: string;
  isAdmin: boolean;
  status: Status | null;
  avatar: string | null;
  defaultAvatar: string | null;
  created: any;
  modified: any;
  user: {
    __typename: 'UserNode';
    id: string;
    firstName: string;
    lastName: string;
    gender: UsersUserGenderChoices | null;
  };
};

export type TeamFragment = {
  __typename: 'TeamNode';
  id: string;
  name: string;
  type: TeamType | null;
  approvalSetting: ApprovalSetting | null;
  isHidden: boolean;
  created: any;
  color: { __typename: 'ColorNode'; id: string; hex: string } | null;
  icon: { __typename: 'IconNode'; id: string; icon: string } | null;
};

export type TeamSampleFragment = {
  __typename: 'TeamNode';
  usersCount: number | null;
  id: string;
  name: string;
  type: TeamType | null;
  approvalSetting: ApprovalSetting | null;
  isHidden: boolean;
  created: any;
  sampleMembers: Array<{
    __typename: 'UserTeamNode';
    id: string;
    isAdmin: boolean;
    status: Status | null;
    avatar: string | null;
    defaultAvatar: string | null;
    created: any;
    modified: any;
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      gender: UsersUserGenderChoices | null;
    };
  } | null> | null;
  color: { __typename: 'ColorNode'; id: string; hex: string } | null;
  icon: { __typename: 'IconNode'; id: string; icon: string } | null;
};

export type TeamDetailFragment = {
  __typename: 'TeamNode';
  id: string;
  name: string;
  type: TeamType | null;
  approvalSetting: ApprovalSetting | null;
  isHidden: boolean;
  created: any;
  users: Array<{
    __typename: 'UserTeamNode';
    id: string;
    isAdmin: boolean;
    status: Status | null;
    avatar: string | null;
    defaultAvatar: string | null;
    created: any;
    modified: any;
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      gender: UsersUserGenderChoices | null;
    };
  } | null> | null;
  color: { __typename: 'ColorNode'; id: string; hex: string } | null;
  icon: { __typename: 'IconNode'; id: string; icon: string } | null;
};

export type TeamsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['ID']['input']>;
  userGroupId?: InputMaybe<Scalars['String']['input']>;
}>;

export type TeamsQuery = {
  __typename: 'Query';
  teams: {
    __typename: 'TeamNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'TeamNodeEdge';
      node: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type TeamSamplesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['ID']['input']>;
}>;

export type TeamSamplesQuery = {
  __typename: 'Query';
  teams: {
    __typename: 'TeamNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'TeamNodeEdge';
      node: {
        __typename: 'TeamNode';
        usersCount: number | null;
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        sampleMembers: Array<{
          __typename: 'UserTeamNode';
          id: string;
          isAdmin: boolean;
          status: Status | null;
          avatar: string | null;
          defaultAvatar: string | null;
          created: any;
          modified: any;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            gender: UsersUserGenderChoices | null;
          };
        } | null> | null;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type TeamQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type TeamQuery = {
  __typename: 'Query';
  team: {
    __typename: 'TeamNode';
    id: string;
    name: string;
    type: TeamType | null;
    approvalSetting: ApprovalSetting | null;
    isHidden: boolean;
    created: any;
    color: { __typename: 'ColorNode'; id: string; hex: string } | null;
    icon: { __typename: 'IconNode'; id: string; icon: string } | null;
  } | null;
};

export type TeamDetailQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type TeamDetailQuery = {
  __typename: 'Query';
  team: {
    __typename: 'TeamNode';
    id: string;
    name: string;
    type: TeamType | null;
    approvalSetting: ApprovalSetting | null;
    isHidden: boolean;
    created: any;
    users: Array<{
      __typename: 'UserTeamNode';
      id: string;
      isAdmin: boolean;
      status: Status | null;
      avatar: string | null;
      defaultAvatar: string | null;
      created: any;
      modified: any;
      user: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        gender: UsersUserGenderChoices | null;
      };
    } | null> | null;
    color: { __typename: 'ColorNode'; id: string; hex: string } | null;
    icon: { __typename: 'IconNode'; id: string; icon: string } | null;
  } | null;
};

export type AddUserToTeamMutationVariables = Exact<{
  input: AddUserToTeamInput;
}>;

export type AddUserToTeamMutation = {
  __typename: 'Mutation';
  addUserToTeam: {
    __typename: 'AddUserToTeamPayload';
    userTeam: {
      __typename: 'UserTeamNode';
      id: string;
      isAdmin: boolean;
      status: Status | null;
      avatar: string | null;
      defaultAvatar: string | null;
      created: any;
      modified: any;
      user: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        gender: UsersUserGenderChoices | null;
      };
    } | null;
    teams: Array<{
      __typename: 'TeamNode';
      avatar: string | null;
      id: string;
      name: string;
      type: TeamType | null;
      approvalSetting: ApprovalSetting | null;
      isHidden: boolean;
      created: any;
      group: {
        __typename: 'GroupNode';
        id: string;
        name: string;
        avatar: string | null;
        year: number;
        token: string | null;
        created: any;
        icon: {
          __typename: 'GroupIconNode';
          id: string;
          icon: string | null;
        } | null;
        type: { __typename: 'GraduationTypeNode'; id: string; name: string };
        institute: {
          __typename: 'InstituteNode';
          id: string;
          internalId: string;
          country: InstitutesInstituteCountryChoices;
          name: string;
          street: string | null;
          zip: string | null;
          city: string | null;
          type: { __typename: 'InstituteTypeNode'; id: string; name: string };
        };
      };
      color: { __typename: 'ColorNode'; id: string; hex: string } | null;
      icon: { __typename: 'IconNode'; id: string; icon: string } | null;
    } | null> | null;
  } | null;
};

export type RemoveUserFromTeamMutationVariables = Exact<{
  input: RemoveUserFromTeamInput;
}>;

export type RemoveUserFromTeamMutation = {
  __typename: 'Mutation';
  removeUserFromTeam: {
    __typename: 'RemoveUserFromTeamPayload';
    userTeam: {
      __typename: 'UserTeamNode';
      id: string;
      isAdmin: boolean;
      status: Status | null;
      avatar: string | null;
      defaultAvatar: string | null;
      created: any;
      modified: any;
      user: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        gender: UsersUserGenderChoices | null;
      };
    } | null;
  } | null;
};

export type CreateTeamMutationVariables = Exact<{
  input: CreateTeamInput;
}>;

export type CreateTeamMutation = {
  __typename: 'Mutation';
  createTeam: {
    __typename: 'CreateTeamPayload';
    team: {
      __typename: 'TeamNode';
      id: string;
      name: string;
      type: TeamType | null;
      approvalSetting: ApprovalSetting | null;
      isHidden: boolean;
      created: any;
      users: Array<{
        __typename: 'UserTeamNode';
        id: string;
        isAdmin: boolean;
        status: Status | null;
        avatar: string | null;
        defaultAvatar: string | null;
        created: any;
        modified: any;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      } | null> | null;
      color: { __typename: 'ColorNode'; id: string; hex: string } | null;
      icon: { __typename: 'IconNode'; id: string; icon: string } | null;
    } | null;
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
  } | null;
};

export type UpdateTeamMutationVariables = Exact<{
  input: UpdateTeamInput;
}>;

export type UpdateTeamMutation = {
  __typename: 'Mutation';
  updateTeam: {
    __typename: 'UpdateTeamPayload';
    team: {
      __typename: 'TeamNode';
      id: string;
      name: string;
      type: TeamType | null;
      approvalSetting: ApprovalSetting | null;
      isHidden: boolean;
      created: any;
      users: Array<{
        __typename: 'UserTeamNode';
        id: string;
        isAdmin: boolean;
        status: Status | null;
        avatar: string | null;
        defaultAvatar: string | null;
        created: any;
        modified: any;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      } | null> | null;
      color: { __typename: 'ColorNode'; id: string; hex: string } | null;
      icon: { __typename: 'IconNode'; id: string; icon: string } | null;
    } | null;
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
  } | null;
};

export type DestroyTeamMutationVariables = Exact<{
  input: DestroyTeamInput;
}>;

export type DestroyTeamMutation = {
  __typename: 'Mutation';
  destroyTeam: {
    __typename: 'DestroyTeamPayload';
    success: boolean | null;
    team: {
      __typename: 'TeamNode';
      id: string;
      name: string;
      type: TeamType | null;
      approvalSetting: ApprovalSetting | null;
      isHidden: boolean;
      created: any;
      users: Array<{
        __typename: 'UserTeamNode';
        id: string;
        isAdmin: boolean;
        status: Status | null;
        avatar: string | null;
        defaultAvatar: string | null;
        created: any;
        modified: any;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      } | null> | null;
      color: { __typename: 'ColorNode'; id: string; hex: string } | null;
      icon: { __typename: 'IconNode'; id: string; icon: string } | null;
    } | null;
  } | null;
};

export type ActivateTeamMutationVariables = Exact<{
  input: ActivateTeamInput;
}>;

export type ActivateTeamMutation = {
  __typename: 'Mutation';
  activateTeam: {
    __typename: 'ActivateTeamPayload';
    success: boolean | null;
    team: {
      __typename: 'TeamNode';
      id: string;
      name: string;
      type: TeamType | null;
      approvalSetting: ApprovalSetting | null;
      isHidden: boolean;
      created: any;
      users: Array<{
        __typename: 'UserTeamNode';
        id: string;
        isAdmin: boolean;
        status: Status | null;
        avatar: string | null;
        defaultAvatar: string | null;
        created: any;
        modified: any;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      } | null> | null;
      color: { __typename: 'ColorNode'; id: string; hex: string } | null;
      icon: { __typename: 'IconNode'; id: string; icon: string } | null;
    } | null;
  } | null;
};

export type AcceptUserInTeamMutationVariables = Exact<{
  input: AcceptUserInTeamInput;
}>;

export type AcceptUserInTeamMutation = {
  __typename: 'Mutation';
  acceptUserInTeam: {
    __typename: 'AcceptUserInTeamPayload';
    userTeam: {
      __typename: 'UserTeamNode';
      id: string;
      isAdmin: boolean;
      status: Status | null;
      avatar: string | null;
      defaultAvatar: string | null;
      created: any;
      modified: any;
      user: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        gender: UsersUserGenderChoices | null;
      };
    } | null;
  } | null;
};

export type UserTeamQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type UserTeamQuery = {
  __typename: 'Query';
  userTeam: {
    __typename: 'UserTeamNode';
    id: string;
    isAdmin: boolean;
    status: Status | null;
    avatar: string | null;
    defaultAvatar: string | null;
    created: any;
    modified: any;
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      gender: UsersUserGenderChoices | null;
    };
  } | null;
};

export type UserFragment = {
  __typename: 'UserNode';
  id: string;
  firstName: string;
  lastName: string;
  gender: UsersUserGenderChoices | null;
};

export type UserWithTeamsFragment = {
  __typename: 'UserNode';
  id: string;
  firstName: string;
  lastName: string;
  gender: UsersUserGenderChoices | null;
  teams: Array<{
    __typename: 'TeamNode';
    id: string;
    name: string;
    color: { __typename: 'ColorNode'; hex: string } | null;
    icon: { __typename: 'IconNode'; id: string; icon: string } | null;
  } | null> | null;
};

export type ModuleFragment = {
  __typename: 'ModuleNode';
  id: string;
  name: string | null;
  image: string | null;
  type: ModuleType | null;
  description: string | null;
  features: Array<string | null> | null;
};

export type ModuleInstanceFragment = {
  __typename: 'ModuleInstanceNode';
  id: string;
  isActive: boolean;
  dueDate: any | null;
  module: {
    __typename: 'ModuleNode';
    id: string;
    name: string | null;
    image: string | null;
    type: ModuleType | null;
    description: string | null;
    features: Array<string | null> | null;
  };
};

export type PrewordInstanceFragment = {
  __typename: 'PrewordInstanceNode';
  id: string;
  text: string;
};

export type ProfilePageSetupFragment = {
  __typename: 'ProfilePageSetupNode';
  id: string;
  photoFormat: PhotoFormat | null;
  maxChars: number;
  anonymousComments: boolean;
  visibleComments: boolean;
};

export type RankingsSetupFragment = {
  __typename: 'RankingsSetupNode';
  id: string;
  resultsTeam: {
    __typename: 'TeamNode';
    id: string;
    name: string;
    type: TeamType | null;
    approvalSetting: ApprovalSetting | null;
    isHidden: boolean;
    created: any;
    color: { __typename: 'ColorNode'; id: string; hex: string } | null;
    icon: { __typename: 'IconNode'; id: string; icon: string } | null;
  } | null;
};

export type CollagesSetupFragment = {
  __typename: 'CollagesSetupNode';
  id: string;
  photosCount: number | null;
  createAlbumsTeam: {
    __typename: 'TeamNode';
    id: string;
    name: string;
    type: TeamType | null;
    approvalSetting: ApprovalSetting | null;
    isHidden: boolean;
    created: any;
    color: { __typename: 'ColorNode'; id: string; hex: string } | null;
    icon: { __typename: 'IconNode'; id: string; icon: string } | null;
  } | null;
  addPhotosTeam: {
    __typename: 'TeamNode';
    id: string;
    name: string;
    type: TeamType | null;
    approvalSetting: ApprovalSetting | null;
    isHidden: boolean;
    created: any;
    color: { __typename: 'ColorNode'; id: string; hex: string } | null;
    icon: { __typename: 'IconNode'; id: string; icon: string } | null;
  } | null;
  viewPhotosTeam: {
    __typename: 'TeamNode';
    id: string;
    name: string;
    type: TeamType | null;
    approvalSetting: ApprovalSetting | null;
    isHidden: boolean;
    created: any;
    color: { __typename: 'ColorNode'; id: string; hex: string } | null;
    icon: { __typename: 'IconNode'; id: string; icon: string } | null;
  } | null;
};

export type ReportsSetupFragment = {
  __typename: 'ReportsSetupNode';
  id: string;
  allowImages: boolean;
};

export type QuotesSetupFragment = {
  __typename: 'QuotesSetupNode';
  id: string;
  anonymous: boolean;
  public: boolean;
  maxChars: number;
};

export type CustomPageSetupFragment = {
  __typename: 'CustomPagesSetupNode';
  id: string;
  guide: string | null;
  createPagesTeam: {
    __typename: 'TeamNode';
    id: string;
    name: string;
    type: TeamType | null;
    approvalSetting: ApprovalSetting | null;
    isHidden: boolean;
    created: any;
    color: { __typename: 'ColorNode'; id: string; hex: string } | null;
    icon: { __typename: 'IconNode'; id: string; icon: string } | null;
  } | null;
  viewPagesTeam: {
    __typename: 'TeamNode';
    id: string;
    name: string;
    type: TeamType | null;
    approvalSetting: ApprovalSetting | null;
    isHidden: boolean;
    created: any;
    color: { __typename: 'ColorNode'; id: string; hex: string } | null;
    icon: { __typename: 'IconNode'; id: string; icon: string } | null;
  } | null;
};

export type ModuleInstanceDetailFragment = {
  __typename: 'ModuleInstanceNode';
  id: string;
  isActive: boolean;
  dueDate: any | null;
  profilePageSetup: {
    __typename: 'ProfilePageSetupNode';
    id: string;
    photoFormat: PhotoFormat | null;
    maxChars: number;
    anonymousComments: boolean;
    visibleComments: boolean;
  } | null;
  rankingsSetup: {
    __typename: 'RankingsSetupNode';
    id: string;
    resultsTeam: {
      __typename: 'TeamNode';
      id: string;
      name: string;
      type: TeamType | null;
      approvalSetting: ApprovalSetting | null;
      isHidden: boolean;
      created: any;
      color: { __typename: 'ColorNode'; id: string; hex: string } | null;
      icon: { __typename: 'IconNode'; id: string; icon: string } | null;
    } | null;
  } | null;
  collagesSetup: {
    __typename: 'CollagesSetupNode';
    id: string;
    photosCount: number | null;
    createAlbumsTeam: {
      __typename: 'TeamNode';
      id: string;
      name: string;
      type: TeamType | null;
      approvalSetting: ApprovalSetting | null;
      isHidden: boolean;
      created: any;
      color: { __typename: 'ColorNode'; id: string; hex: string } | null;
      icon: { __typename: 'IconNode'; id: string; icon: string } | null;
    } | null;
    addPhotosTeam: {
      __typename: 'TeamNode';
      id: string;
      name: string;
      type: TeamType | null;
      approvalSetting: ApprovalSetting | null;
      isHidden: boolean;
      created: any;
      color: { __typename: 'ColorNode'; id: string; hex: string } | null;
      icon: { __typename: 'IconNode'; id: string; icon: string } | null;
    } | null;
    viewPhotosTeam: {
      __typename: 'TeamNode';
      id: string;
      name: string;
      type: TeamType | null;
      approvalSetting: ApprovalSetting | null;
      isHidden: boolean;
      created: any;
      color: { __typename: 'ColorNode'; id: string; hex: string } | null;
      icon: { __typename: 'IconNode'; id: string; icon: string } | null;
    } | null;
  } | null;
  reportsSetup: {
    __typename: 'ReportsSetupNode';
    id: string;
    allowImages: boolean;
  } | null;
  quotesSetup: {
    __typename: 'QuotesSetupNode';
    id: string;
    anonymous: boolean;
    public: boolean;
    maxChars: number;
  } | null;
  customPagesSetup: {
    __typename: 'CustomPagesSetupNode';
    id: string;
    guide: string | null;
    createPagesTeam: {
      __typename: 'TeamNode';
      id: string;
      name: string;
      type: TeamType | null;
      approvalSetting: ApprovalSetting | null;
      isHidden: boolean;
      created: any;
      color: { __typename: 'ColorNode'; id: string; hex: string } | null;
      icon: { __typename: 'IconNode'; id: string; icon: string } | null;
    } | null;
    viewPagesTeam: {
      __typename: 'TeamNode';
      id: string;
      name: string;
      type: TeamType | null;
      approvalSetting: ApprovalSetting | null;
      isHidden: boolean;
      created: any;
      color: { __typename: 'ColorNode'; id: string; hex: string } | null;
      icon: { __typename: 'IconNode'; id: string; icon: string } | null;
    } | null;
  } | null;
  module: {
    __typename: 'ModuleNode';
    id: string;
    name: string | null;
    image: string | null;
    type: ModuleType | null;
    description: string | null;
    features: Array<string | null> | null;
  };
};

export type CustomPageFragment = {
  __typename: 'CustomPagesInstanceNode';
  id: string;
  title: string;
  created: any;
  modified: any;
  page: string | null;
  pageFormat: PageFormat | null;
  pageType: PageType | null;
  pageQuality: PageQuality | null;
  userGroup: {
    __typename: 'UserGroupNode';
    id: string;
    role: UserType | null;
    avatar: string | null;
    status: Status | null;
    created: any;
    defaultAvatar: {
      __typename: 'DefaultAvatarNode';
      id: string;
      avatar: string | null;
    } | null;
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      gender: UsersUserGenderChoices | null;
    };
  } | null;
  moduleInstance: {
    __typename: 'ModuleInstanceNode';
    id: string;
    isActive: boolean;
    dueDate: any | null;
    profilePageSetup: {
      __typename: 'ProfilePageSetupNode';
      id: string;
      photoFormat: PhotoFormat | null;
      maxChars: number;
      anonymousComments: boolean;
      visibleComments: boolean;
    } | null;
    rankingsSetup: {
      __typename: 'RankingsSetupNode';
      id: string;
      resultsTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
    } | null;
    collagesSetup: {
      __typename: 'CollagesSetupNode';
      id: string;
      photosCount: number | null;
      createAlbumsTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
      addPhotosTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
      viewPhotosTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
    } | null;
    reportsSetup: {
      __typename: 'ReportsSetupNode';
      id: string;
      allowImages: boolean;
    } | null;
    quotesSetup: {
      __typename: 'QuotesSetupNode';
      id: string;
      anonymous: boolean;
      public: boolean;
      maxChars: number;
    } | null;
    customPagesSetup: {
      __typename: 'CustomPagesSetupNode';
      id: string;
      guide: string | null;
      createPagesTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
      viewPagesTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
    } | null;
    module: {
      __typename: 'ModuleNode';
      id: string;
      name: string | null;
      image: string | null;
      type: ModuleType | null;
      description: string | null;
      features: Array<string | null> | null;
    };
  };
};

export type PrewordResultFragment = {
  __typename: 'PrewordInstanceNode';
  wordCount: number | null;
};

export type ProfilePageUserSummaryFragment = {
  __typename: 'ProfilePageUserSummaryNode';
  questionsAnswered: number | null;
  imagesUploaded: number | null;
  userGroup: {
    __typename: 'UserGroupNode';
    id: string;
    role: UserType | null;
    avatar: string | null;
    status: Status | null;
    created: any;
    defaultAvatar: {
      __typename: 'DefaultAvatarNode';
      id: string;
      avatar: string | null;
    } | null;
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      gender: UsersUserGenderChoices | null;
    };
  };
};

export type ProfilePageResultFragment = {
  __typename: 'ProfilePageSetupNode';
  id: string;
  usersDelivered: number | null;
  usersSummary: Array<{
    __typename: 'ProfilePageUserSummaryNode';
    questionsAnswered: number | null;
    imagesUploaded: number | null;
    userGroup: {
      __typename: 'UserGroupNode';
      id: string;
      role: UserType | null;
      avatar: string | null;
      status: Status | null;
      created: any;
      defaultAvatar: {
        __typename: 'DefaultAvatarNode';
        id: string;
        avatar: string | null;
      } | null;
      user: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        gender: UsersUserGenderChoices | null;
      };
    };
  } | null> | null;
};

export type RankingsResultFragment = {
  __typename: 'RankingsSetupNode';
  rankingsCount: number | null;
};

export type CollagesResultFragment = {
  __typename: 'CollagesSetupNode';
  photosCount: number | null;
};

export type ReportsResultFragment = {
  __typename: 'ReportsSetupNode';
  reportsCount: number | null;
};

export type QuotesResultFragment = {
  __typename: 'QuotesSetupNode';
  quotesCount: number | null;
};

export type ModuleInstanceResultFragment = {
  __typename: 'ModuleInstanceNode';
  id: string;
  isActive: boolean;
  dueDate: any | null;
  prewordInstance: {
    __typename: 'PrewordInstanceNode';
    wordCount: number | null;
  } | null;
  profilePageSetup: {
    __typename: 'ProfilePageSetupNode';
    id: string;
    usersDelivered: number | null;
    usersSummary: Array<{
      __typename: 'ProfilePageUserSummaryNode';
      questionsAnswered: number | null;
      imagesUploaded: number | null;
      userGroup: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      };
    } | null> | null;
  } | null;
  rankingsSetup: {
    __typename: 'RankingsSetupNode';
    rankingsCount: number | null;
  } | null;
  collagesSetup: {
    __typename: 'CollagesSetupNode';
    photosCount: number | null;
  } | null;
  reportsSetup: {
    __typename: 'ReportsSetupNode';
    reportsCount: number | null;
  } | null;
  quotesSetup: {
    __typename: 'QuotesSetupNode';
    quotesCount: number | null;
  } | null;
  customPagesInstances: {
    __typename: 'CustomPagesInstanceNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'CustomPagesInstanceNodeEdge';
      cursor: string;
      node: {
        __typename: 'CustomPagesInstanceNode';
        id: string;
        title: string;
        created: any;
        modified: any;
        page: string | null;
        pageFormat: PageFormat | null;
        pageType: PageType | null;
        pageQuality: PageQuality | null;
        userGroup: {
          __typename: 'UserGroupNode';
          id: string;
          role: UserType | null;
          avatar: string | null;
          status: Status | null;
          created: any;
          defaultAvatar: {
            __typename: 'DefaultAvatarNode';
            id: string;
            avatar: string | null;
          } | null;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            gender: UsersUserGenderChoices | null;
          };
        } | null;
        moduleInstance: {
          __typename: 'ModuleInstanceNode';
          id: string;
          isActive: boolean;
          dueDate: any | null;
          profilePageSetup: {
            __typename: 'ProfilePageSetupNode';
            id: string;
            photoFormat: PhotoFormat | null;
            maxChars: number;
            anonymousComments: boolean;
            visibleComments: boolean;
          } | null;
          rankingsSetup: {
            __typename: 'RankingsSetupNode';
            id: string;
            resultsTeam: {
              __typename: 'TeamNode';
              id: string;
              name: string;
              type: TeamType | null;
              approvalSetting: ApprovalSetting | null;
              isHidden: boolean;
              created: any;
              color: {
                __typename: 'ColorNode';
                id: string;
                hex: string;
              } | null;
              icon: { __typename: 'IconNode'; id: string; icon: string } | null;
            } | null;
          } | null;
          collagesSetup: {
            __typename: 'CollagesSetupNode';
            id: string;
            photosCount: number | null;
            createAlbumsTeam: {
              __typename: 'TeamNode';
              id: string;
              name: string;
              type: TeamType | null;
              approvalSetting: ApprovalSetting | null;
              isHidden: boolean;
              created: any;
              color: {
                __typename: 'ColorNode';
                id: string;
                hex: string;
              } | null;
              icon: { __typename: 'IconNode'; id: string; icon: string } | null;
            } | null;
            addPhotosTeam: {
              __typename: 'TeamNode';
              id: string;
              name: string;
              type: TeamType | null;
              approvalSetting: ApprovalSetting | null;
              isHidden: boolean;
              created: any;
              color: {
                __typename: 'ColorNode';
                id: string;
                hex: string;
              } | null;
              icon: { __typename: 'IconNode'; id: string; icon: string } | null;
            } | null;
            viewPhotosTeam: {
              __typename: 'TeamNode';
              id: string;
              name: string;
              type: TeamType | null;
              approvalSetting: ApprovalSetting | null;
              isHidden: boolean;
              created: any;
              color: {
                __typename: 'ColorNode';
                id: string;
                hex: string;
              } | null;
              icon: { __typename: 'IconNode'; id: string; icon: string } | null;
            } | null;
          } | null;
          reportsSetup: {
            __typename: 'ReportsSetupNode';
            id: string;
            allowImages: boolean;
          } | null;
          quotesSetup: {
            __typename: 'QuotesSetupNode';
            id: string;
            anonymous: boolean;
            public: boolean;
            maxChars: number;
          } | null;
          customPagesSetup: {
            __typename: 'CustomPagesSetupNode';
            id: string;
            guide: string | null;
            createPagesTeam: {
              __typename: 'TeamNode';
              id: string;
              name: string;
              type: TeamType | null;
              approvalSetting: ApprovalSetting | null;
              isHidden: boolean;
              created: any;
              color: {
                __typename: 'ColorNode';
                id: string;
                hex: string;
              } | null;
              icon: { __typename: 'IconNode'; id: string; icon: string } | null;
            } | null;
            viewPagesTeam: {
              __typename: 'TeamNode';
              id: string;
              name: string;
              type: TeamType | null;
              approvalSetting: ApprovalSetting | null;
              isHidden: boolean;
              created: any;
              color: {
                __typename: 'ColorNode';
                id: string;
                hex: string;
              } | null;
              icon: { __typename: 'IconNode'; id: string; icon: string } | null;
            } | null;
          } | null;
          module: {
            __typename: 'ModuleNode';
            id: string;
            name: string | null;
            image: string | null;
            type: ModuleType | null;
            description: string | null;
            features: Array<string | null> | null;
          };
        };
      } | null;
    } | null>;
  } | null;
  module: {
    __typename: 'ModuleNode';
    id: string;
    name: string | null;
    image: string | null;
    type: ModuleType | null;
    description: string | null;
    features: Array<string | null> | null;
  };
};

export type ProfilePagePhotoFragment = {
  __typename: 'ProfilePagePhotoNode';
  id: string;
  image: string | null;
};

export type BaseProfilePagePhotoCategoryFragment = {
  __typename: 'ProfilePagePhotoCategoryNode';
  id: string;
  name: string | null;
};

export type ProfilePagePhotoCategoryFragment = {
  __typename: 'ProfilePagePhotoCategoryNode';
  id: string;
  name: string | null;
  profilePagePhotos: {
    __typename: 'ProfilePagePhotoNodeConnection';
    edges: Array<{
      __typename: 'ProfilePagePhotoNodeEdge';
      node: {
        __typename: 'ProfilePagePhotoNode';
        id: string;
        image: string | null;
      } | null;
    } | null>;
  } | null;
};

export type ProfilePageQuestionOptionFragment = {
  __typename: 'ProfilePageQuestionOptionNode';
  id: string;
  text: string;
};

export type ProfilePageAnswerFragment = {
  __typename: 'ProfilePageAnswerNode';
  id: string;
  text: string | null;
  profilePageQuestionOption: {
    __typename: 'ProfilePageQuestionOptionNode';
    id: string;
    text: string;
  } | null;
  userGroup: { __typename: 'UserGroupNode'; id: string };
};

export type BaseProfilePageQuestionFragment = {
  __typename: 'ProfilePageQuestionNode';
  id: string;
  text: string;
  order: number;
  profilePageQuestionOptions: Array<{
    __typename: 'ProfilePageQuestionOptionNode';
    id: string;
    text: string;
  } | null> | null;
};

export type ProfilePageQuestionFragment = {
  __typename: 'ProfilePageQuestionNode';
  id: string;
  text: string;
  order: number;
  profilePageAnswers: {
    __typename: 'ProfilePageAnswerNodeConnection';
    edges: Array<{
      __typename: 'ProfilePageAnswerNodeEdge';
      node: {
        __typename: 'ProfilePageAnswerNode';
        id: string;
        text: string | null;
        profilePageQuestionOption: {
          __typename: 'ProfilePageQuestionOptionNode';
          id: string;
          text: string;
        } | null;
        userGroup: { __typename: 'UserGroupNode'; id: string };
      } | null;
    } | null>;
  } | null;
  profilePageQuestionOptions: Array<{
    __typename: 'ProfilePageQuestionOptionNode';
    id: string;
    text: string;
  } | null> | null;
};

export type ProfilePageSuggestedQuestionOptionFragment = {
  __typename: 'ProfilePageSuggestedQuestionOptionNode';
  id: string;
  text: string;
};

export type ProfilePageSuggestedQuestionFragment = {
  __typename: 'ProfilePageSuggestedQuestionNode';
  id: string;
  text: string;
  options: Array<{
    __typename: 'ProfilePageSuggestedQuestionOptionNode';
    id: string;
    text: string;
  } | null> | null;
};

export type ProfilePageCommentFragment = {
  __typename: 'ProfilePageCommentNode';
  id: string;
  text: string;
  created: any;
  comentatorUserGroup: {
    __typename: 'UserGroupNode';
    id: string;
    role: UserType | null;
    avatar: string | null;
    status: Status | null;
    created: any;
    defaultAvatar: {
      __typename: 'DefaultAvatarNode';
      id: string;
      avatar: string | null;
    } | null;
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      gender: UsersUserGenderChoices | null;
    };
  };
  profileUserGroup: {
    __typename: 'UserGroupNode';
    id: string;
    role: UserType | null;
    avatar: string | null;
    status: Status | null;
    created: any;
    defaultAvatar: {
      __typename: 'DefaultAvatarNode';
      id: string;
      avatar: string | null;
    } | null;
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      gender: UsersUserGenderChoices | null;
    };
  };
};

export type CollagesPhotoFragment = {
  __typename: 'CollagesPhotoNode';
  id: string;
  photo: string;
  collagesPhotoLikesCount: number | null;
  created: any;
  userGroup: { __typename: 'UserGroupNode'; id: string };
  collagesPhotoLikes: {
    __typename: 'CollagesPhotoLikeNodeConnection';
    edges: Array<{
      __typename: 'CollagesPhotoLikeNodeEdge';
      node: {
        __typename: 'CollagesPhotoLikeNode';
        userGroup: { __typename: 'UserGroupNode'; id: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type CollagesBasePhotoFragment = {
  __typename: 'CollagesPhotoNode';
  id: string;
  photo: string;
  collagesPhotoLikesCount: number | null;
  created: any;
  userGroup: { __typename: 'UserGroupNode'; id: string };
};

export type BaseCollagesAlbumFragment = {
  __typename: 'CollagesAlbumNode';
  id: string;
  name: string;
};

export type CollagesAlbumFragment = {
  __typename: 'CollagesAlbumNode';
  photosCount: number | null;
  id: string;
  name: string;
  collagesPhotos: {
    __typename: 'CollagesPhotoNodeConnection';
    edges: Array<{
      __typename: 'CollagesPhotoNodeEdge';
      node: {
        __typename: 'CollagesPhotoNode';
        id: string;
        photo: string;
        collagesPhotoLikesCount: number | null;
        created: any;
        userGroup: { __typename: 'UserGroupNode'; id: string };
      } | null;
    } | null>;
  } | null;
};

export type CollagesBaseAlbumFragment = {
  __typename: 'CollagesAlbumNode';
  photosCount: number | null;
  id: string;
  name: string;
};

export type ReportsInstanceFragment = {
  __typename: 'ReportsInstanceNode';
  id: string;
  title: string;
  text: string;
  maxChars: number;
  editTeam: {
    __typename: 'TeamNode';
    id: string;
    name: string;
    type: TeamType | null;
    approvalSetting: ApprovalSetting | null;
    isHidden: boolean;
    created: any;
    color: { __typename: 'ColorNode'; id: string; hex: string } | null;
    icon: { __typename: 'IconNode'; id: string; icon: string } | null;
  } | null;
  viewTeam: {
    __typename: 'TeamNode';
    id: string;
    name: string;
    type: TeamType | null;
    approvalSetting: ApprovalSetting | null;
    isHidden: boolean;
    created: any;
    color: { __typename: 'ColorNode'; id: string; hex: string } | null;
    icon: { __typename: 'IconNode'; id: string; icon: string } | null;
  } | null;
};

export type BaseQuoteFragment = {
  __typename: 'QuotesInstanceNode';
  id: string;
  quote: string;
  quotesInstanceLikesCount: number | null;
  created: any;
  userGroup: {
    __typename: 'UserGroupNode';
    id: string;
    role: UserType | null;
    avatar: string | null;
    status: Status | null;
    created: any;
    defaultAvatar: {
      __typename: 'DefaultAvatarNode';
      id: string;
      avatar: string | null;
    } | null;
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      gender: UsersUserGenderChoices | null;
    };
  };
};

export type QuoteFragment = {
  __typename: 'QuotesInstanceNode';
  id: string;
  quote: string;
  quotesInstanceLikesCount: number | null;
  created: any;
  quotesInstanceLikes: {
    __typename: 'QuotesInstanceLikeNodeConnection';
    edges: Array<{
      __typename: 'QuotesInstanceLikeNodeEdge';
      node: {
        __typename: 'QuotesInstanceLikeNode';
        id: string;
        userGroup: { __typename: 'UserGroupNode'; id: string } | null;
      } | null;
    } | null>;
  } | null;
  userGroup: {
    __typename: 'UserGroupNode';
    id: string;
    role: UserType | null;
    avatar: string | null;
    status: Status | null;
    created: any;
    defaultAvatar: {
      __typename: 'DefaultAvatarNode';
      id: string;
      avatar: string | null;
    } | null;
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      gender: UsersUserGenderChoices | null;
    };
  };
};

export type CustomListFragment = {
  __typename: 'CustomListNode';
  id: string;
  title: string | null;
};

export type CustomListOptionFragment = {
  __typename: 'CustomListOptionNode';
  id: string;
  text: string | null;
};

export type RankingQuestionResultFragment = {
  __typename: 'RankingsQuestionResultNode';
  percentage: number | null;
  votee1UserGroup: {
    __typename: 'UserGroupNode';
    id: string;
    role: UserType | null;
    avatar: string | null;
    status: Status | null;
    created: any;
    defaultAvatar: {
      __typename: 'DefaultAvatarNode';
      id: string;
      avatar: string | null;
    } | null;
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      gender: UsersUserGenderChoices | null;
    };
  } | null;
  votee2UserGroup: {
    __typename: 'UserGroupNode';
    id: string;
    role: UserType | null;
    avatar: string | null;
    status: Status | null;
    created: any;
    defaultAvatar: {
      __typename: 'DefaultAvatarNode';
      id: string;
      avatar: string | null;
    } | null;
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      gender: UsersUserGenderChoices | null;
    };
  } | null;
  voteeListOption: {
    __typename: 'CustomListOptionNode';
    id: string;
    text: string | null;
  } | null;
};

export type RankingQuestionResultsFragment = {
  __typename: 'RankingsQuestionResultsNode';
  alreadyVoted: number | null;
  possibleVoters: number | null;
  options: Array<{
    __typename: 'RankingsQuestionResultNode';
    percentage: number | null;
    votee1UserGroup: {
      __typename: 'UserGroupNode';
      id: string;
      role: UserType | null;
      avatar: string | null;
      status: Status | null;
      created: any;
      defaultAvatar: {
        __typename: 'DefaultAvatarNode';
        id: string;
        avatar: string | null;
      } | null;
      user: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        gender: UsersUserGenderChoices | null;
      };
    } | null;
    votee2UserGroup: {
      __typename: 'UserGroupNode';
      id: string;
      role: UserType | null;
      avatar: string | null;
      status: Status | null;
      created: any;
      defaultAvatar: {
        __typename: 'DefaultAvatarNode';
        id: string;
        avatar: string | null;
      } | null;
      user: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        gender: UsersUserGenderChoices | null;
      };
    } | null;
    voteeListOption: {
      __typename: 'CustomListOptionNode';
      id: string;
      text: string | null;
    } | null;
  } | null> | null;
};

export type RankingsAnswerFragment = {
  __typename: 'RankingsAnswerNode';
  id: string;
  votee1UserGroup: {
    __typename: 'UserGroupNode';
    id: string;
    role: UserType | null;
    avatar: string | null;
    status: Status | null;
    created: any;
    defaultAvatar: {
      __typename: 'DefaultAvatarNode';
      id: string;
      avatar: string | null;
    } | null;
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      gender: UsersUserGenderChoices | null;
    };
  } | null;
  votee2UserGroup: {
    __typename: 'UserGroupNode';
    id: string;
    role: UserType | null;
    avatar: string | null;
    status: Status | null;
    created: any;
    defaultAvatar: {
      __typename: 'DefaultAvatarNode';
      id: string;
      avatar: string | null;
    } | null;
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      gender: UsersUserGenderChoices | null;
    };
  } | null;
  voteeListOption: {
    __typename: 'CustomListOptionNode';
    id: string;
    text: string | null;
  } | null;
};

export type RankingQuestionFragment = {
  __typename: 'RankingsQuestionNode';
  id: string;
  question: string;
  answerType: AnswerType | null;
  allowedVotes: AllowedVoteType | null;
  onlyOppositeGender: boolean | null;
  customList: { __typename: 'CustomListNode'; id: string } | null;
  results: {
    __typename: 'RankingsQuestionResultsNode';
    alreadyVoted: number | null;
    possibleVoters: number | null;
    options: Array<{
      __typename: 'RankingsQuestionResultNode';
      percentage: number | null;
      votee1UserGroup: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      } | null;
      votee2UserGroup: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      } | null;
      voteeListOption: {
        __typename: 'CustomListOptionNode';
        id: string;
        text: string | null;
      } | null;
    } | null> | null;
  } | null;
  rankingsAnswers: {
    __typename: 'RankingsAnswerNodeConnection';
    edges: Array<{
      __typename: 'RankingsAnswerNodeEdge';
      node: {
        __typename: 'RankingsAnswerNode';
        id: string;
        votee1UserGroup: {
          __typename: 'UserGroupNode';
          id: string;
          role: UserType | null;
          avatar: string | null;
          status: Status | null;
          created: any;
          defaultAvatar: {
            __typename: 'DefaultAvatarNode';
            id: string;
            avatar: string | null;
          } | null;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            gender: UsersUserGenderChoices | null;
          };
        } | null;
        votee2UserGroup: {
          __typename: 'UserGroupNode';
          id: string;
          role: UserType | null;
          avatar: string | null;
          status: Status | null;
          created: any;
          defaultAvatar: {
            __typename: 'DefaultAvatarNode';
            id: string;
            avatar: string | null;
          } | null;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            gender: UsersUserGenderChoices | null;
          };
        } | null;
        voteeListOption: {
          __typename: 'CustomListOptionNode';
          id: string;
          text: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type RankingBaseQuestionFragment = {
  __typename: 'RankingsQuestionNode';
  id: string;
  question: string;
  answerType: AnswerType | null;
  allowedVotes: AllowedVoteType | null;
  onlyOppositeGender: boolean | null;
  customList: { __typename: 'CustomListNode'; id: string } | null;
  rankingsAnswers: {
    __typename: 'RankingsAnswerNodeConnection';
    edges: Array<{
      __typename: 'RankingsAnswerNodeEdge';
      node: { __typename: 'RankingsAnswerNode'; id: string } | null;
    } | null>;
  } | null;
};

export type DataExportFragment = {
  __typename: 'DataExportNode';
  id: string;
  isodatetime: string | null;
  exportFile: string | null;
  exportCollages: boolean | null;
  exportRankings: boolean | null;
  exportProfilePages: boolean | null;
  exportQuotes: boolean | null;
  userGroup: {
    __typename: 'UserGroupNode';
    id: string;
    role: UserType | null;
    avatar: string | null;
    status: Status | null;
    created: any;
    defaultAvatar: {
      __typename: 'DefaultAvatarNode';
      id: string;
      avatar: string | null;
    } | null;
    user: {
      __typename: 'UserNode';
      id: string;
      firstName: string;
      lastName: string;
      gender: UsersUserGenderChoices | null;
    };
  } | null;
};

export type ModulesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;

export type ModulesQuery = {
  __typename: 'Query';
  modules: {
    __typename: 'ModuleNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'ModuleNodeEdge';
      node: {
        __typename: 'ModuleNode';
        id: string;
        name: string | null;
        image: string | null;
        type: ModuleType | null;
        description: string | null;
        features: Array<string | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type ModuleQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ModuleQuery = {
  __typename: 'Query';
  module: {
    __typename: 'ModuleNode';
    id: string;
    name: string | null;
    image: string | null;
    type: ModuleType | null;
    description: string | null;
    features: Array<string | null> | null;
  } | null;
};

export type ModuleInstancesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  group?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ModuleInstancesQuery = {
  __typename: 'Query';
  moduleInstances: {
    __typename: 'ModuleInstanceNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'ModuleInstanceNodeEdge';
      node: {
        __typename: 'ModuleInstanceNode';
        id: string;
        isActive: boolean;
        dueDate: any | null;
        module: {
          __typename: 'ModuleNode';
          id: string;
          name: string | null;
          image: string | null;
          type: ModuleType | null;
          description: string | null;
          features: Array<string | null> | null;
        };
      } | null;
    } | null>;
  } | null;
};

export type ModuleInstanceQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ModuleInstanceQuery = {
  __typename: 'Query';
  moduleInstance: {
    __typename: 'ModuleInstanceNode';
    id: string;
    isActive: boolean;
    dueDate: any | null;
    profilePageSetup: {
      __typename: 'ProfilePageSetupNode';
      id: string;
      photoFormat: PhotoFormat | null;
      maxChars: number;
      anonymousComments: boolean;
      visibleComments: boolean;
    } | null;
    rankingsSetup: {
      __typename: 'RankingsSetupNode';
      id: string;
      resultsTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
    } | null;
    collagesSetup: {
      __typename: 'CollagesSetupNode';
      id: string;
      photosCount: number | null;
      createAlbumsTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
      addPhotosTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
      viewPhotosTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
    } | null;
    reportsSetup: {
      __typename: 'ReportsSetupNode';
      id: string;
      allowImages: boolean;
    } | null;
    quotesSetup: {
      __typename: 'QuotesSetupNode';
      id: string;
      anonymous: boolean;
      public: boolean;
      maxChars: number;
    } | null;
    customPagesSetup: {
      __typename: 'CustomPagesSetupNode';
      id: string;
      guide: string | null;
      createPagesTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
      viewPagesTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
    } | null;
    module: {
      __typename: 'ModuleNode';
      id: string;
      name: string | null;
      image: string | null;
      type: ModuleType | null;
      description: string | null;
      features: Array<string | null> | null;
    };
  } | null;
};

export type ModuleInstanceResultsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  group?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ModuleInstanceResultsQuery = {
  __typename: 'Query';
  moduleInstances: {
    __typename: 'ModuleInstanceNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'ModuleInstanceNodeEdge';
      node: {
        __typename: 'ModuleInstanceNode';
        id: string;
        isActive: boolean;
        dueDate: any | null;
        prewordInstance: {
          __typename: 'PrewordInstanceNode';
          wordCount: number | null;
        } | null;
        profilePageSetup: {
          __typename: 'ProfilePageSetupNode';
          id: string;
          usersDelivered: number | null;
          usersSummary: Array<{
            __typename: 'ProfilePageUserSummaryNode';
            questionsAnswered: number | null;
            imagesUploaded: number | null;
            userGroup: {
              __typename: 'UserGroupNode';
              id: string;
              role: UserType | null;
              avatar: string | null;
              status: Status | null;
              created: any;
              defaultAvatar: {
                __typename: 'DefaultAvatarNode';
                id: string;
                avatar: string | null;
              } | null;
              user: {
                __typename: 'UserNode';
                id: string;
                firstName: string;
                lastName: string;
                gender: UsersUserGenderChoices | null;
              };
            };
          } | null> | null;
        } | null;
        rankingsSetup: {
          __typename: 'RankingsSetupNode';
          rankingsCount: number | null;
        } | null;
        collagesSetup: {
          __typename: 'CollagesSetupNode';
          photosCount: number | null;
        } | null;
        reportsSetup: {
          __typename: 'ReportsSetupNode';
          reportsCount: number | null;
        } | null;
        quotesSetup: {
          __typename: 'QuotesSetupNode';
          quotesCount: number | null;
        } | null;
        customPagesInstances: {
          __typename: 'CustomPagesInstanceNodeConnection';
          pageInfo: {
            __typename: 'PageInfo';
            hasNextPage: boolean;
            endCursor: string | null;
          };
          edges: Array<{
            __typename: 'CustomPagesInstanceNodeEdge';
            cursor: string;
            node: {
              __typename: 'CustomPagesInstanceNode';
              id: string;
              title: string;
              created: any;
              modified: any;
              page: string | null;
              pageFormat: PageFormat | null;
              pageType: PageType | null;
              pageQuality: PageQuality | null;
              userGroup: {
                __typename: 'UserGroupNode';
                id: string;
                role: UserType | null;
                avatar: string | null;
                status: Status | null;
                created: any;
                defaultAvatar: {
                  __typename: 'DefaultAvatarNode';
                  id: string;
                  avatar: string | null;
                } | null;
                user: {
                  __typename: 'UserNode';
                  id: string;
                  firstName: string;
                  lastName: string;
                  gender: UsersUserGenderChoices | null;
                };
              } | null;
              moduleInstance: {
                __typename: 'ModuleInstanceNode';
                id: string;
                isActive: boolean;
                dueDate: any | null;
                profilePageSetup: {
                  __typename: 'ProfilePageSetupNode';
                  id: string;
                  photoFormat: PhotoFormat | null;
                  maxChars: number;
                  anonymousComments: boolean;
                  visibleComments: boolean;
                } | null;
                rankingsSetup: {
                  __typename: 'RankingsSetupNode';
                  id: string;
                  resultsTeam: {
                    __typename: 'TeamNode';
                    id: string;
                    name: string;
                    type: TeamType | null;
                    approvalSetting: ApprovalSetting | null;
                    isHidden: boolean;
                    created: any;
                    color: {
                      __typename: 'ColorNode';
                      id: string;
                      hex: string;
                    } | null;
                    icon: {
                      __typename: 'IconNode';
                      id: string;
                      icon: string;
                    } | null;
                  } | null;
                } | null;
                collagesSetup: {
                  __typename: 'CollagesSetupNode';
                  id: string;
                  photosCount: number | null;
                  createAlbumsTeam: {
                    __typename: 'TeamNode';
                    id: string;
                    name: string;
                    type: TeamType | null;
                    approvalSetting: ApprovalSetting | null;
                    isHidden: boolean;
                    created: any;
                    color: {
                      __typename: 'ColorNode';
                      id: string;
                      hex: string;
                    } | null;
                    icon: {
                      __typename: 'IconNode';
                      id: string;
                      icon: string;
                    } | null;
                  } | null;
                  addPhotosTeam: {
                    __typename: 'TeamNode';
                    id: string;
                    name: string;
                    type: TeamType | null;
                    approvalSetting: ApprovalSetting | null;
                    isHidden: boolean;
                    created: any;
                    color: {
                      __typename: 'ColorNode';
                      id: string;
                      hex: string;
                    } | null;
                    icon: {
                      __typename: 'IconNode';
                      id: string;
                      icon: string;
                    } | null;
                  } | null;
                  viewPhotosTeam: {
                    __typename: 'TeamNode';
                    id: string;
                    name: string;
                    type: TeamType | null;
                    approvalSetting: ApprovalSetting | null;
                    isHidden: boolean;
                    created: any;
                    color: {
                      __typename: 'ColorNode';
                      id: string;
                      hex: string;
                    } | null;
                    icon: {
                      __typename: 'IconNode';
                      id: string;
                      icon: string;
                    } | null;
                  } | null;
                } | null;
                reportsSetup: {
                  __typename: 'ReportsSetupNode';
                  id: string;
                  allowImages: boolean;
                } | null;
                quotesSetup: {
                  __typename: 'QuotesSetupNode';
                  id: string;
                  anonymous: boolean;
                  public: boolean;
                  maxChars: number;
                } | null;
                customPagesSetup: {
                  __typename: 'CustomPagesSetupNode';
                  id: string;
                  guide: string | null;
                  createPagesTeam: {
                    __typename: 'TeamNode';
                    id: string;
                    name: string;
                    type: TeamType | null;
                    approvalSetting: ApprovalSetting | null;
                    isHidden: boolean;
                    created: any;
                    color: {
                      __typename: 'ColorNode';
                      id: string;
                      hex: string;
                    } | null;
                    icon: {
                      __typename: 'IconNode';
                      id: string;
                      icon: string;
                    } | null;
                  } | null;
                  viewPagesTeam: {
                    __typename: 'TeamNode';
                    id: string;
                    name: string;
                    type: TeamType | null;
                    approvalSetting: ApprovalSetting | null;
                    isHidden: boolean;
                    created: any;
                    color: {
                      __typename: 'ColorNode';
                      id: string;
                      hex: string;
                    } | null;
                    icon: {
                      __typename: 'IconNode';
                      id: string;
                      icon: string;
                    } | null;
                  } | null;
                } | null;
                module: {
                  __typename: 'ModuleNode';
                  id: string;
                  name: string | null;
                  image: string | null;
                  type: ModuleType | null;
                  description: string | null;
                  features: Array<string | null> | null;
                };
              };
            } | null;
          } | null>;
        } | null;
        module: {
          __typename: 'ModuleNode';
          id: string;
          name: string | null;
          image: string | null;
          type: ModuleType | null;
          description: string | null;
          features: Array<string | null> | null;
        };
      } | null;
    } | null>;
  } | null;
};

export type ModuleInstanceResultQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ModuleInstanceResultQuery = {
  __typename: 'Query';
  moduleInstance: {
    __typename: 'ModuleInstanceNode';
    id: string;
    isActive: boolean;
    dueDate: any | null;
    prewordInstance: {
      __typename: 'PrewordInstanceNode';
      wordCount: number | null;
    } | null;
    profilePageSetup: {
      __typename: 'ProfilePageSetupNode';
      id: string;
      usersDelivered: number | null;
      usersSummary: Array<{
        __typename: 'ProfilePageUserSummaryNode';
        questionsAnswered: number | null;
        imagesUploaded: number | null;
        userGroup: {
          __typename: 'UserGroupNode';
          id: string;
          role: UserType | null;
          avatar: string | null;
          status: Status | null;
          created: any;
          defaultAvatar: {
            __typename: 'DefaultAvatarNode';
            id: string;
            avatar: string | null;
          } | null;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            gender: UsersUserGenderChoices | null;
          };
        };
      } | null> | null;
    } | null;
    rankingsSetup: {
      __typename: 'RankingsSetupNode';
      rankingsCount: number | null;
    } | null;
    collagesSetup: {
      __typename: 'CollagesSetupNode';
      photosCount: number | null;
    } | null;
    reportsSetup: {
      __typename: 'ReportsSetupNode';
      reportsCount: number | null;
    } | null;
    quotesSetup: {
      __typename: 'QuotesSetupNode';
      quotesCount: number | null;
    } | null;
    customPagesInstances: {
      __typename: 'CustomPagesInstanceNodeConnection';
      pageInfo: {
        __typename: 'PageInfo';
        hasNextPage: boolean;
        endCursor: string | null;
      };
      edges: Array<{
        __typename: 'CustomPagesInstanceNodeEdge';
        cursor: string;
        node: {
          __typename: 'CustomPagesInstanceNode';
          id: string;
          title: string;
          created: any;
          modified: any;
          page: string | null;
          pageFormat: PageFormat | null;
          pageType: PageType | null;
          pageQuality: PageQuality | null;
          userGroup: {
            __typename: 'UserGroupNode';
            id: string;
            role: UserType | null;
            avatar: string | null;
            status: Status | null;
            created: any;
            defaultAvatar: {
              __typename: 'DefaultAvatarNode';
              id: string;
              avatar: string | null;
            } | null;
            user: {
              __typename: 'UserNode';
              id: string;
              firstName: string;
              lastName: string;
              gender: UsersUserGenderChoices | null;
            };
          } | null;
          moduleInstance: {
            __typename: 'ModuleInstanceNode';
            id: string;
            isActive: boolean;
            dueDate: any | null;
            profilePageSetup: {
              __typename: 'ProfilePageSetupNode';
              id: string;
              photoFormat: PhotoFormat | null;
              maxChars: number;
              anonymousComments: boolean;
              visibleComments: boolean;
            } | null;
            rankingsSetup: {
              __typename: 'RankingsSetupNode';
              id: string;
              resultsTeam: {
                __typename: 'TeamNode';
                id: string;
                name: string;
                type: TeamType | null;
                approvalSetting: ApprovalSetting | null;
                isHidden: boolean;
                created: any;
                color: {
                  __typename: 'ColorNode';
                  id: string;
                  hex: string;
                } | null;
                icon: {
                  __typename: 'IconNode';
                  id: string;
                  icon: string;
                } | null;
              } | null;
            } | null;
            collagesSetup: {
              __typename: 'CollagesSetupNode';
              id: string;
              photosCount: number | null;
              createAlbumsTeam: {
                __typename: 'TeamNode';
                id: string;
                name: string;
                type: TeamType | null;
                approvalSetting: ApprovalSetting | null;
                isHidden: boolean;
                created: any;
                color: {
                  __typename: 'ColorNode';
                  id: string;
                  hex: string;
                } | null;
                icon: {
                  __typename: 'IconNode';
                  id: string;
                  icon: string;
                } | null;
              } | null;
              addPhotosTeam: {
                __typename: 'TeamNode';
                id: string;
                name: string;
                type: TeamType | null;
                approvalSetting: ApprovalSetting | null;
                isHidden: boolean;
                created: any;
                color: {
                  __typename: 'ColorNode';
                  id: string;
                  hex: string;
                } | null;
                icon: {
                  __typename: 'IconNode';
                  id: string;
                  icon: string;
                } | null;
              } | null;
              viewPhotosTeam: {
                __typename: 'TeamNode';
                id: string;
                name: string;
                type: TeamType | null;
                approvalSetting: ApprovalSetting | null;
                isHidden: boolean;
                created: any;
                color: {
                  __typename: 'ColorNode';
                  id: string;
                  hex: string;
                } | null;
                icon: {
                  __typename: 'IconNode';
                  id: string;
                  icon: string;
                } | null;
              } | null;
            } | null;
            reportsSetup: {
              __typename: 'ReportsSetupNode';
              id: string;
              allowImages: boolean;
            } | null;
            quotesSetup: {
              __typename: 'QuotesSetupNode';
              id: string;
              anonymous: boolean;
              public: boolean;
              maxChars: number;
            } | null;
            customPagesSetup: {
              __typename: 'CustomPagesSetupNode';
              id: string;
              guide: string | null;
              createPagesTeam: {
                __typename: 'TeamNode';
                id: string;
                name: string;
                type: TeamType | null;
                approvalSetting: ApprovalSetting | null;
                isHidden: boolean;
                created: any;
                color: {
                  __typename: 'ColorNode';
                  id: string;
                  hex: string;
                } | null;
                icon: {
                  __typename: 'IconNode';
                  id: string;
                  icon: string;
                } | null;
              } | null;
              viewPagesTeam: {
                __typename: 'TeamNode';
                id: string;
                name: string;
                type: TeamType | null;
                approvalSetting: ApprovalSetting | null;
                isHidden: boolean;
                created: any;
                color: {
                  __typename: 'ColorNode';
                  id: string;
                  hex: string;
                } | null;
                icon: {
                  __typename: 'IconNode';
                  id: string;
                  icon: string;
                } | null;
              } | null;
            } | null;
            module: {
              __typename: 'ModuleNode';
              id: string;
              name: string | null;
              image: string | null;
              type: ModuleType | null;
              description: string | null;
              features: Array<string | null> | null;
            };
          };
        } | null;
      } | null>;
    } | null;
    module: {
      __typename: 'ModuleNode';
      id: string;
      name: string | null;
      image: string | null;
      type: ModuleType | null;
      description: string | null;
      features: Array<string | null> | null;
    };
  } | null;
};

export type ProfilePagePhotoCategoriesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  moduleInstance?: InputMaybe<Scalars['ID']['input']>;
  ppqUserGroup?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ProfilePagePhotoCategoriesQuery = {
  __typename: 'Query';
  profilePagePhotoCategories: {
    __typename: 'ProfilePagePhotoCategoryNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'ProfilePagePhotoCategoryNodeEdge';
      node: {
        __typename: 'ProfilePagePhotoCategoryNode';
        id: string;
        name: string | null;
        profilePagePhotos: {
          __typename: 'ProfilePagePhotoNodeConnection';
          edges: Array<{
            __typename: 'ProfilePagePhotoNodeEdge';
            node: {
              __typename: 'ProfilePagePhotoNode';
              id: string;
              image: string | null;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type ProfilePageQuestionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  moduleInstance?: InputMaybe<Scalars['ID']['input']>;
  ppqUserGroup?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ProfilePageQuestionsQuery = {
  __typename: 'Query';
  profilePageQuestions: {
    __typename: 'ProfilePageQuestionNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'ProfilePageQuestionNodeEdge';
      node: {
        __typename: 'ProfilePageQuestionNode';
        id: string;
        text: string;
        order: number;
        profilePageAnswers: {
          __typename: 'ProfilePageAnswerNodeConnection';
          edges: Array<{
            __typename: 'ProfilePageAnswerNodeEdge';
            node: {
              __typename: 'ProfilePageAnswerNode';
              id: string;
              text: string | null;
              profilePageQuestionOption: {
                __typename: 'ProfilePageQuestionOptionNode';
                id: string;
                text: string;
              } | null;
              userGroup: { __typename: 'UserGroupNode'; id: string };
            } | null;
          } | null>;
        } | null;
        profilePageQuestionOptions: Array<{
          __typename: 'ProfilePageQuestionOptionNode';
          id: string;
          text: string;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type ProfilePageSuggestedQuestionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;

export type ProfilePageSuggestedQuestionsQuery = {
  __typename: 'Query';
  profilePageSuggestedQuestions: {
    __typename: 'ProfilePageSuggestedQuestionNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'ProfilePageSuggestedQuestionNodeEdge';
      node: {
        __typename: 'ProfilePageSuggestedQuestionNode';
        id: string;
        text: string;
        options: Array<{
          __typename: 'ProfilePageSuggestedQuestionOptionNode';
          id: string;
          text: string;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type ProfilePageCommentsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  profileUserGroup?: InputMaybe<Scalars['ID']['input']>;
  comentatorUserGroup?: InputMaybe<Scalars['ID']['input']>;
  moduleInstance?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ProfilePageCommentsQuery = {
  __typename: 'Query';
  profilePageComments: {
    __typename: 'ProfilePageCommentNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'ProfilePageCommentNodeEdge';
      node: {
        __typename: 'ProfilePageCommentNode';
        id: string;
        text: string;
        created: any;
        comentatorUserGroup: {
          __typename: 'UserGroupNode';
          id: string;
          role: UserType | null;
          avatar: string | null;
          status: Status | null;
          created: any;
          defaultAvatar: {
            __typename: 'DefaultAvatarNode';
            id: string;
            avatar: string | null;
          } | null;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            gender: UsersUserGenderChoices | null;
          };
        };
        profileUserGroup: {
          __typename: 'UserGroupNode';
          id: string;
          role: UserType | null;
          avatar: string | null;
          status: Status | null;
          created: any;
          defaultAvatar: {
            __typename: 'DefaultAvatarNode';
            id: string;
            avatar: string | null;
          } | null;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            gender: UsersUserGenderChoices | null;
          };
        };
      } | null;
    } | null>;
  } | null;
};

export type CollagesAlbumsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  moduleInstance?: InputMaybe<Scalars['ID']['input']>;
  cpAfter?: InputMaybe<Scalars['String']['input']>;
  cpFirst?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CollagesAlbumsQuery = {
  __typename: 'Query';
  collagesAlbums: {
    __typename: 'CollagesAlbumNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'CollagesAlbumNodeEdge';
      node: {
        __typename: 'CollagesAlbumNode';
        photosCount: number | null;
        id: string;
        name: string;
        collagesPhotos: {
          __typename: 'CollagesPhotoNodeConnection';
          edges: Array<{
            __typename: 'CollagesPhotoNodeEdge';
            node: {
              __typename: 'CollagesPhotoNode';
              id: string;
              photo: string;
              collagesPhotoLikesCount: number | null;
              created: any;
              userGroup: { __typename: 'UserGroupNode'; id: string };
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type CollagesAlbumQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CollagesAlbumQuery = {
  __typename: 'Query';
  collagesAlbum: {
    __typename: 'CollagesAlbumNode';
    photosCount: number | null;
    id: string;
    name: string;
  } | null;
};

export type CollagesPhotosQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  collagesAlbum?: InputMaybe<Scalars['ID']['input']>;
  authUserGroupId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CollagesPhotosQuery = {
  __typename: 'Query';
  collagesPhotos: {
    __typename: 'CollagesPhotoNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'CollagesPhotoNodeEdge';
      node: {
        __typename: 'CollagesPhotoNode';
        id: string;
        photo: string;
        collagesPhotoLikesCount: number | null;
        created: any;
        userGroup: { __typename: 'UserGroupNode'; id: string };
        collagesPhotoLikes: {
          __typename: 'CollagesPhotoLikeNodeConnection';
          edges: Array<{
            __typename: 'CollagesPhotoLikeNodeEdge';
            node: {
              __typename: 'CollagesPhotoLikeNode';
              userGroup: { __typename: 'UserGroupNode'; id: string } | null;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type ReportsInstancesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  moduleInstance?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ReportsInstancesQuery = {
  __typename: 'Query';
  reportsInstances: {
    __typename: 'ReportsInstanceNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'ReportsInstanceNodeEdge';
      node: {
        __typename: 'ReportsInstanceNode';
        id: string;
        title: string;
        text: string;
        maxChars: number;
        editTeam: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
        viewTeam: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type ReportsInstanceQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ReportsInstanceQuery = {
  __typename: 'Query';
  reportsInstance: {
    __typename: 'ReportsInstanceNode';
    id: string;
    title: string;
    text: string;
    maxChars: number;
    editTeam: {
      __typename: 'TeamNode';
      id: string;
      name: string;
      type: TeamType | null;
      approvalSetting: ApprovalSetting | null;
      isHidden: boolean;
      created: any;
      color: { __typename: 'ColorNode'; id: string; hex: string } | null;
      icon: { __typename: 'IconNode'; id: string; icon: string } | null;
    } | null;
    viewTeam: {
      __typename: 'TeamNode';
      id: string;
      name: string;
      type: TeamType | null;
      approvalSetting: ApprovalSetting | null;
      isHidden: boolean;
      created: any;
      color: { __typename: 'ColorNode'; id: string; hex: string } | null;
      icon: { __typename: 'IconNode'; id: string; icon: string } | null;
    } | null;
  } | null;
};

export type QuotesInstancesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  moduleInstance?: InputMaybe<Scalars['ID']['input']>;
  userGroup?: InputMaybe<Scalars['ID']['input']>;
  authUserGroupId?: InputMaybe<Scalars['ID']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
}>;

export type QuotesInstancesQuery = {
  __typename: 'Query';
  quotesInstances: {
    __typename: 'QuotesInstanceNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'QuotesInstanceNodeEdge';
      node: {
        __typename: 'QuotesInstanceNode';
        id: string;
        quote: string;
        quotesInstanceLikesCount: number | null;
        created: any;
        quotesInstanceLikes: {
          __typename: 'QuotesInstanceLikeNodeConnection';
          edges: Array<{
            __typename: 'QuotesInstanceLikeNodeEdge';
            node: {
              __typename: 'QuotesInstanceLikeNode';
              id: string;
              userGroup: { __typename: 'UserGroupNode'; id: string } | null;
            } | null;
          } | null>;
        } | null;
        userGroup: {
          __typename: 'UserGroupNode';
          id: string;
          role: UserType | null;
          avatar: string | null;
          status: Status | null;
          created: any;
          defaultAvatar: {
            __typename: 'DefaultAvatarNode';
            id: string;
            avatar: string | null;
          } | null;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            gender: UsersUserGenderChoices | null;
          };
        };
      } | null;
    } | null>;
  } | null;
};

export type CustomPagesInstancesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  moduleInstance?: InputMaybe<Scalars['ID']['input']>;
  userGroup: Scalars['String']['input'];
}>;

export type CustomPagesInstancesQuery = {
  __typename: 'Query';
  customPagesInstances: {
    __typename: 'CustomPagesInstanceNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'CustomPagesInstanceNodeEdge';
      cursor: string;
      node: {
        __typename: 'CustomPagesInstanceNode';
        id: string;
        title: string;
        created: any;
        modified: any;
        page: string | null;
        pageFormat: PageFormat | null;
        pageType: PageType | null;
        pageQuality: PageQuality | null;
        userGroup: {
          __typename: 'UserGroupNode';
          id: string;
          role: UserType | null;
          avatar: string | null;
          status: Status | null;
          created: any;
          defaultAvatar: {
            __typename: 'DefaultAvatarNode';
            id: string;
            avatar: string | null;
          } | null;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            gender: UsersUserGenderChoices | null;
          };
        } | null;
        moduleInstance: {
          __typename: 'ModuleInstanceNode';
          id: string;
          isActive: boolean;
          dueDate: any | null;
          profilePageSetup: {
            __typename: 'ProfilePageSetupNode';
            id: string;
            photoFormat: PhotoFormat | null;
            maxChars: number;
            anonymousComments: boolean;
            visibleComments: boolean;
          } | null;
          rankingsSetup: {
            __typename: 'RankingsSetupNode';
            id: string;
            resultsTeam: {
              __typename: 'TeamNode';
              id: string;
              name: string;
              type: TeamType | null;
              approvalSetting: ApprovalSetting | null;
              isHidden: boolean;
              created: any;
              color: {
                __typename: 'ColorNode';
                id: string;
                hex: string;
              } | null;
              icon: { __typename: 'IconNode'; id: string; icon: string } | null;
            } | null;
          } | null;
          collagesSetup: {
            __typename: 'CollagesSetupNode';
            id: string;
            photosCount: number | null;
            createAlbumsTeam: {
              __typename: 'TeamNode';
              id: string;
              name: string;
              type: TeamType | null;
              approvalSetting: ApprovalSetting | null;
              isHidden: boolean;
              created: any;
              color: {
                __typename: 'ColorNode';
                id: string;
                hex: string;
              } | null;
              icon: { __typename: 'IconNode'; id: string; icon: string } | null;
            } | null;
            addPhotosTeam: {
              __typename: 'TeamNode';
              id: string;
              name: string;
              type: TeamType | null;
              approvalSetting: ApprovalSetting | null;
              isHidden: boolean;
              created: any;
              color: {
                __typename: 'ColorNode';
                id: string;
                hex: string;
              } | null;
              icon: { __typename: 'IconNode'; id: string; icon: string } | null;
            } | null;
            viewPhotosTeam: {
              __typename: 'TeamNode';
              id: string;
              name: string;
              type: TeamType | null;
              approvalSetting: ApprovalSetting | null;
              isHidden: boolean;
              created: any;
              color: {
                __typename: 'ColorNode';
                id: string;
                hex: string;
              } | null;
              icon: { __typename: 'IconNode'; id: string; icon: string } | null;
            } | null;
          } | null;
          reportsSetup: {
            __typename: 'ReportsSetupNode';
            id: string;
            allowImages: boolean;
          } | null;
          quotesSetup: {
            __typename: 'QuotesSetupNode';
            id: string;
            anonymous: boolean;
            public: boolean;
            maxChars: number;
          } | null;
          customPagesSetup: {
            __typename: 'CustomPagesSetupNode';
            id: string;
            guide: string | null;
            createPagesTeam: {
              __typename: 'TeamNode';
              id: string;
              name: string;
              type: TeamType | null;
              approvalSetting: ApprovalSetting | null;
              isHidden: boolean;
              created: any;
              color: {
                __typename: 'ColorNode';
                id: string;
                hex: string;
              } | null;
              icon: { __typename: 'IconNode'; id: string; icon: string } | null;
            } | null;
            viewPagesTeam: {
              __typename: 'TeamNode';
              id: string;
              name: string;
              type: TeamType | null;
              approvalSetting: ApprovalSetting | null;
              isHidden: boolean;
              created: any;
              color: {
                __typename: 'ColorNode';
                id: string;
                hex: string;
              } | null;
              icon: { __typename: 'IconNode'; id: string; icon: string } | null;
            } | null;
          } | null;
          module: {
            __typename: 'ModuleNode';
            id: string;
            name: string | null;
            image: string | null;
            type: ModuleType | null;
            description: string | null;
            features: Array<string | null> | null;
          };
        };
      } | null;
    } | null>;
  } | null;
};

export type CustomPagesInstancesResultQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  moduleInstance: Scalars['String']['input'];
}>;

export type CustomPagesInstancesResultQuery = {
  __typename: 'Query';
  customPagesInstancesResult: {
    __typename: 'CustomPagesInstanceNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'CustomPagesInstanceNodeEdge';
      cursor: string;
      node: {
        __typename: 'CustomPagesInstanceNode';
        id: string;
        title: string;
        created: any;
        modified: any;
        page: string | null;
        pageFormat: PageFormat | null;
        pageType: PageType | null;
        pageQuality: PageQuality | null;
        userGroup: {
          __typename: 'UserGroupNode';
          id: string;
          role: UserType | null;
          avatar: string | null;
          status: Status | null;
          created: any;
          defaultAvatar: {
            __typename: 'DefaultAvatarNode';
            id: string;
            avatar: string | null;
          } | null;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            gender: UsersUserGenderChoices | null;
          };
        } | null;
        moduleInstance: {
          __typename: 'ModuleInstanceNode';
          id: string;
          isActive: boolean;
          dueDate: any | null;
          profilePageSetup: {
            __typename: 'ProfilePageSetupNode';
            id: string;
            photoFormat: PhotoFormat | null;
            maxChars: number;
            anonymousComments: boolean;
            visibleComments: boolean;
          } | null;
          rankingsSetup: {
            __typename: 'RankingsSetupNode';
            id: string;
            resultsTeam: {
              __typename: 'TeamNode';
              id: string;
              name: string;
              type: TeamType | null;
              approvalSetting: ApprovalSetting | null;
              isHidden: boolean;
              created: any;
              color: {
                __typename: 'ColorNode';
                id: string;
                hex: string;
              } | null;
              icon: { __typename: 'IconNode'; id: string; icon: string } | null;
            } | null;
          } | null;
          collagesSetup: {
            __typename: 'CollagesSetupNode';
            id: string;
            photosCount: number | null;
            createAlbumsTeam: {
              __typename: 'TeamNode';
              id: string;
              name: string;
              type: TeamType | null;
              approvalSetting: ApprovalSetting | null;
              isHidden: boolean;
              created: any;
              color: {
                __typename: 'ColorNode';
                id: string;
                hex: string;
              } | null;
              icon: { __typename: 'IconNode'; id: string; icon: string } | null;
            } | null;
            addPhotosTeam: {
              __typename: 'TeamNode';
              id: string;
              name: string;
              type: TeamType | null;
              approvalSetting: ApprovalSetting | null;
              isHidden: boolean;
              created: any;
              color: {
                __typename: 'ColorNode';
                id: string;
                hex: string;
              } | null;
              icon: { __typename: 'IconNode'; id: string; icon: string } | null;
            } | null;
            viewPhotosTeam: {
              __typename: 'TeamNode';
              id: string;
              name: string;
              type: TeamType | null;
              approvalSetting: ApprovalSetting | null;
              isHidden: boolean;
              created: any;
              color: {
                __typename: 'ColorNode';
                id: string;
                hex: string;
              } | null;
              icon: { __typename: 'IconNode'; id: string; icon: string } | null;
            } | null;
          } | null;
          reportsSetup: {
            __typename: 'ReportsSetupNode';
            id: string;
            allowImages: boolean;
          } | null;
          quotesSetup: {
            __typename: 'QuotesSetupNode';
            id: string;
            anonymous: boolean;
            public: boolean;
            maxChars: number;
          } | null;
          customPagesSetup: {
            __typename: 'CustomPagesSetupNode';
            id: string;
            guide: string | null;
            createPagesTeam: {
              __typename: 'TeamNode';
              id: string;
              name: string;
              type: TeamType | null;
              approvalSetting: ApprovalSetting | null;
              isHidden: boolean;
              created: any;
              color: {
                __typename: 'ColorNode';
                id: string;
                hex: string;
              } | null;
              icon: { __typename: 'IconNode'; id: string; icon: string } | null;
            } | null;
            viewPagesTeam: {
              __typename: 'TeamNode';
              id: string;
              name: string;
              type: TeamType | null;
              approvalSetting: ApprovalSetting | null;
              isHidden: boolean;
              created: any;
              color: {
                __typename: 'ColorNode';
                id: string;
                hex: string;
              } | null;
              icon: { __typename: 'IconNode'; id: string; icon: string } | null;
            } | null;
          } | null;
          module: {
            __typename: 'ModuleNode';
            id: string;
            name: string | null;
            image: string | null;
            type: ModuleType | null;
            description: string | null;
            features: Array<string | null> | null;
          };
        };
      } | null;
    } | null>;
  } | null;
};

export type CustomListsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  moduleInstance?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CustomListsQuery = {
  __typename: 'Query';
  customLists: {
    __typename: 'CustomListNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'CustomListNodeEdge';
      node: {
        __typename: 'CustomListNode';
        id: string;
        title: string | null;
      } | null;
    } | null>;
  } | null;
};

export type CustomListOptionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  customList?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CustomListOptionsQuery = {
  __typename: 'Query';
  customListOptions: {
    __typename: 'CustomListOptionNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'CustomListOptionNodeEdge';
      node: {
        __typename: 'CustomListOptionNode';
        id: string;
        text: string | null;
      } | null;
    } | null>;
  } | null;
};

export type RankingsQuestionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  moduleInstance?: InputMaybe<Scalars['ID']['input']>;
  authUserGroupId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type RankingsQuestionsQuery = {
  __typename: 'Query';
  rankingsQuestions: {
    __typename: 'RankingsQuestionNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'RankingsQuestionNodeEdge';
      node: {
        __typename: 'RankingsQuestionNode';
        id: string;
        question: string;
        answerType: AnswerType | null;
        allowedVotes: AllowedVoteType | null;
        onlyOppositeGender: boolean | null;
        customList: { __typename: 'CustomListNode'; id: string } | null;
        results: {
          __typename: 'RankingsQuestionResultsNode';
          alreadyVoted: number | null;
          possibleVoters: number | null;
          options: Array<{
            __typename: 'RankingsQuestionResultNode';
            percentage: number | null;
            votee1UserGroup: {
              __typename: 'UserGroupNode';
              id: string;
              role: UserType | null;
              avatar: string | null;
              status: Status | null;
              created: any;
              defaultAvatar: {
                __typename: 'DefaultAvatarNode';
                id: string;
                avatar: string | null;
              } | null;
              user: {
                __typename: 'UserNode';
                id: string;
                firstName: string;
                lastName: string;
                gender: UsersUserGenderChoices | null;
              };
            } | null;
            votee2UserGroup: {
              __typename: 'UserGroupNode';
              id: string;
              role: UserType | null;
              avatar: string | null;
              status: Status | null;
              created: any;
              defaultAvatar: {
                __typename: 'DefaultAvatarNode';
                id: string;
                avatar: string | null;
              } | null;
              user: {
                __typename: 'UserNode';
                id: string;
                firstName: string;
                lastName: string;
                gender: UsersUserGenderChoices | null;
              };
            } | null;
            voteeListOption: {
              __typename: 'CustomListOptionNode';
              id: string;
              text: string | null;
            } | null;
          } | null> | null;
        } | null;
        rankingsAnswers: {
          __typename: 'RankingsAnswerNodeConnection';
          edges: Array<{
            __typename: 'RankingsAnswerNodeEdge';
            node: {
              __typename: 'RankingsAnswerNode';
              id: string;
              votee1UserGroup: {
                __typename: 'UserGroupNode';
                id: string;
                role: UserType | null;
                avatar: string | null;
                status: Status | null;
                created: any;
                defaultAvatar: {
                  __typename: 'DefaultAvatarNode';
                  id: string;
                  avatar: string | null;
                } | null;
                user: {
                  __typename: 'UserNode';
                  id: string;
                  firstName: string;
                  lastName: string;
                  gender: UsersUserGenderChoices | null;
                };
              } | null;
              votee2UserGroup: {
                __typename: 'UserGroupNode';
                id: string;
                role: UserType | null;
                avatar: string | null;
                status: Status | null;
                created: any;
                defaultAvatar: {
                  __typename: 'DefaultAvatarNode';
                  id: string;
                  avatar: string | null;
                } | null;
                user: {
                  __typename: 'UserNode';
                  id: string;
                  firstName: string;
                  lastName: string;
                  gender: UsersUserGenderChoices | null;
                };
              } | null;
              voteeListOption: {
                __typename: 'CustomListOptionNode';
                id: string;
                text: string | null;
              } | null;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type RankingsQuestionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  authUserGroupId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type RankingsQuestionQuery = {
  __typename: 'Query';
  rankingsQuestion: {
    __typename: 'RankingsQuestionNode';
    id: string;
    question: string;
    answerType: AnswerType | null;
    allowedVotes: AllowedVoteType | null;
    onlyOppositeGender: boolean | null;
    customList: { __typename: 'CustomListNode'; id: string } | null;
    rankingsAnswers: {
      __typename: 'RankingsAnswerNodeConnection';
      edges: Array<{
        __typename: 'RankingsAnswerNodeEdge';
        node: { __typename: 'RankingsAnswerNode'; id: string } | null;
      } | null>;
    } | null;
  } | null;
};

export type DataExportsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  group: Scalars['ID']['input'];
}>;

export type DataExportsQuery = {
  __typename: 'Query';
  dataExports: {
    __typename: 'DataExportNodeConnection';
    pageInfo: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: 'DataExportNodeEdge';
      node: {
        __typename: 'DataExportNode';
        id: string;
        isodatetime: string | null;
        exportFile: string | null;
        exportCollages: boolean | null;
        exportRankings: boolean | null;
        exportProfilePages: boolean | null;
        exportQuotes: boolean | null;
        userGroup: {
          __typename: 'UserGroupNode';
          id: string;
          role: UserType | null;
          avatar: string | null;
          status: Status | null;
          created: any;
          defaultAvatar: {
            __typename: 'DefaultAvatarNode';
            id: string;
            avatar: string | null;
          } | null;
          user: {
            __typename: 'UserNode';
            id: string;
            firstName: string;
            lastName: string;
            gender: UsersUserGenderChoices | null;
          };
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type CustomPagesInstanceQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CustomPagesInstanceQuery = {
  __typename: 'Query';
  customPagesInstance: {
    __typename: 'CustomPagesInstanceNode';
    id: string;
    title: string;
    created: any;
    modified: any;
    page: string | null;
    pageFormat: PageFormat | null;
    pageType: PageType | null;
    pageQuality: PageQuality | null;
    userGroup: {
      __typename: 'UserGroupNode';
      id: string;
      role: UserType | null;
      avatar: string | null;
      status: Status | null;
      created: any;
      defaultAvatar: {
        __typename: 'DefaultAvatarNode';
        id: string;
        avatar: string | null;
      } | null;
      user: {
        __typename: 'UserNode';
        id: string;
        firstName: string;
        lastName: string;
        gender: UsersUserGenderChoices | null;
      };
    } | null;
    moduleInstance: {
      __typename: 'ModuleInstanceNode';
      id: string;
      isActive: boolean;
      dueDate: any | null;
      profilePageSetup: {
        __typename: 'ProfilePageSetupNode';
        id: string;
        photoFormat: PhotoFormat | null;
        maxChars: number;
        anonymousComments: boolean;
        visibleComments: boolean;
      } | null;
      rankingsSetup: {
        __typename: 'RankingsSetupNode';
        id: string;
        resultsTeam: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
      } | null;
      collagesSetup: {
        __typename: 'CollagesSetupNode';
        id: string;
        photosCount: number | null;
        createAlbumsTeam: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
        addPhotosTeam: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
        viewPhotosTeam: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
      } | null;
      reportsSetup: {
        __typename: 'ReportsSetupNode';
        id: string;
        allowImages: boolean;
      } | null;
      quotesSetup: {
        __typename: 'QuotesSetupNode';
        id: string;
        anonymous: boolean;
        public: boolean;
        maxChars: number;
      } | null;
      customPagesSetup: {
        __typename: 'CustomPagesSetupNode';
        id: string;
        guide: string | null;
        createPagesTeam: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
        viewPagesTeam: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
      } | null;
      module: {
        __typename: 'ModuleNode';
        id: string;
        name: string | null;
        image: string | null;
        type: ModuleType | null;
        description: string | null;
        features: Array<string | null> | null;
      };
    };
  } | null;
};

export type CreateModuleInstanceMutationVariables = Exact<{
  input: CreateModuleInstanceInput;
}>;

export type CreateModuleInstanceMutation = {
  __typename: 'Mutation';
  createModuleInstance: {
    __typename: 'CreateModuleInstancePayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    moduleInstance: {
      __typename: 'ModuleInstanceNode';
      id: string;
      isActive: boolean;
      dueDate: any | null;
      module: {
        __typename: 'ModuleNode';
        id: string;
        name: string | null;
        image: string | null;
        type: ModuleType | null;
        description: string | null;
        features: Array<string | null> | null;
      };
    } | null;
  } | null;
};

export type DestroyModuleInstanceMutationVariables = Exact<{
  input: DestroyModuleInstanceInput;
}>;

export type DestroyModuleInstanceMutation = {
  __typename: 'Mutation';
  destroyModuleInstance: {
    __typename: 'DestroyModuleInstancePayload';
    moduleInstance: {
      __typename: 'ModuleInstanceNode';
      id: string;
      isActive: boolean;
      dueDate: any | null;
      module: {
        __typename: 'ModuleNode';
        id: string;
        name: string | null;
        image: string | null;
        type: ModuleType | null;
        description: string | null;
        features: Array<string | null> | null;
      };
    } | null;
  } | null;
};

export type UpdatePrewordInstanceMutationVariables = Exact<{
  input: UpdatePrewordInstanceInput;
}>;

export type UpdatePrewordInstanceMutation = {
  __typename: 'Mutation';
  updatePrewordInstance: {
    __typename: 'UpdatePrewordInstancePayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    prewordInstance: {
      __typename: 'PrewordInstanceNode';
      id: string;
      text: string;
    } | null;
  } | null;
};

export type CreateProfilePagePhotoCategoryMutationVariables = Exact<{
  input: CreateProfilePagePhotoCategoryInput;
}>;

export type CreateProfilePagePhotoCategoryMutation = {
  __typename: 'Mutation';
  createProfilePagePhotoCategory: {
    __typename: 'CreateProfilePagePhotoCategoryPayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    profilePagePhotoCategory: {
      __typename: 'ProfilePagePhotoCategoryNode';
      id: string;
      name: string | null;
    } | null;
  } | null;
};

export type UpdateProfilePagePhotoCategoryMutationVariables = Exact<{
  input: UpdateProfilePagePhotoCategoryInput;
}>;

export type UpdateProfilePagePhotoCategoryMutation = {
  __typename: 'Mutation';
  updateProfilePagePhotoCategory: {
    __typename: 'UpdateProfilePagePhotoCategoryPayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    profilePagePhotoCategory: {
      __typename: 'ProfilePagePhotoCategoryNode';
      id: string;
      name: string | null;
    } | null;
  } | null;
};

export type DestroyProfilePagePhotoCategoryMutationVariables = Exact<{
  input: DestroyProfilePagePhotoCategoryInput;
}>;

export type DestroyProfilePagePhotoCategoryMutation = {
  __typename: 'Mutation';
  destroyProfilePagePhotoCategory: {
    __typename: 'DestroyProfilePagePhotoCategoryPayload';
    profilePagePhotoCategory: {
      __typename: 'ProfilePagePhotoCategoryNode';
      id: string;
      name: string | null;
    } | null;
  } | null;
};

export type CreateProfilePagePhotoMutationVariables = Exact<{
  input: CreateProfilePagePhotoInput;
}>;

export type CreateProfilePagePhotoMutation = {
  __typename: 'Mutation';
  createProfilePagePhoto: {
    __typename: 'CreateProfilePagePhotoPayload';
    profilePagePhoto: {
      __typename: 'ProfilePagePhotoNode';
      id: string;
      image: string | null;
    } | null;
  } | null;
};

export type UpdateProfilePagePhotoMutationVariables = Exact<{
  input: UpdateProfilePagePhotoInput;
}>;

export type UpdateProfilePagePhotoMutation = {
  __typename: 'Mutation';
  updateProfilePagePhoto: {
    __typename: 'UpdateProfilePagePhotoPayload';
    profilePagePhoto: {
      __typename: 'ProfilePagePhotoNode';
      id: string;
      image: string | null;
    } | null;
  } | null;
};

export type DestroyProfilePagePhotoMutationVariables = Exact<{
  input: DestroyProfilePagePhotoInput;
}>;

export type DestroyProfilePagePhotoMutation = {
  __typename: 'Mutation';
  destroyProfilePagePhoto: {
    __typename: 'DestroyProfilePagePhotoPayload';
    profilePagePhoto: {
      __typename: 'ProfilePagePhotoNode';
      id: string;
      image: string | null;
    } | null;
  } | null;
};

export type CreateProfilePageQuestionMutationVariables = Exact<{
  input: CreateProfilePageQuestionInput;
}>;

export type CreateProfilePageQuestionMutation = {
  __typename: 'Mutation';
  createProfilePageQuestion: {
    __typename: 'CreateProfilePageQuestionPayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    profilePageQuestion: {
      __typename: 'ProfilePageQuestionNode';
      id: string;
      text: string;
      order: number;
      profilePageQuestionOptions: Array<{
        __typename: 'ProfilePageQuestionOptionNode';
        id: string;
        text: string;
      } | null> | null;
    } | null;
  } | null;
};

export type UpdateProfilePageQuestionMutationVariables = Exact<{
  input: UpdateProfilePageQuestionInput;
}>;

export type UpdateProfilePageQuestionMutation = {
  __typename: 'Mutation';
  updateProfilePageQuestion: {
    __typename: 'UpdateProfilePageQuestionPayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    profilePageQuestion: {
      __typename: 'ProfilePageQuestionNode';
      id: string;
      text: string;
      order: number;
      profilePageQuestionOptions: Array<{
        __typename: 'ProfilePageQuestionOptionNode';
        id: string;
        text: string;
      } | null> | null;
    } | null;
  } | null;
};

export type DestroyProfilePageQuestionMutationVariables = Exact<{
  input: DestroyProfilePageQuestionInput;
}>;

export type DestroyProfilePageQuestionMutation = {
  __typename: 'Mutation';
  destroyProfilePageQuestion: {
    __typename: 'DestroyProfilePageQuestionPayload';
    profilePageQuestion: {
      __typename: 'ProfilePageQuestionNode';
      id: string;
      text: string;
      order: number;
      profilePageQuestionOptions: Array<{
        __typename: 'ProfilePageQuestionOptionNode';
        id: string;
        text: string;
      } | null> | null;
    } | null;
  } | null;
};

export type CreateProfilePageAnswerMutationVariables = Exact<{
  input: CreateProfilePageAnswerInput;
}>;

export type CreateProfilePageAnswerMutation = {
  __typename: 'Mutation';
  createProfilePageAnswer: {
    __typename: 'CreateProfilePageAnswerPayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    profilePageAnswer: {
      __typename: 'ProfilePageAnswerNode';
      id: string;
      text: string | null;
      profilePageQuestionOption: {
        __typename: 'ProfilePageQuestionOptionNode';
        id: string;
        text: string;
      } | null;
      userGroup: { __typename: 'UserGroupNode'; id: string };
    } | null;
  } | null;
};

export type UpdateProfilePageAnswerMutationVariables = Exact<{
  input: UpdateProfilePageAnswerInput;
}>;

export type UpdateProfilePageAnswerMutation = {
  __typename: 'Mutation';
  updateProfilePageAnswer: {
    __typename: 'UpdateProfilePageAnswerPayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    profilePageAnswer: {
      __typename: 'ProfilePageAnswerNode';
      id: string;
      text: string | null;
      profilePageQuestionOption: {
        __typename: 'ProfilePageQuestionOptionNode';
        id: string;
        text: string;
      } | null;
      userGroup: { __typename: 'UserGroupNode'; id: string };
    } | null;
  } | null;
};

export type DestroyProfilePageAnswerMutationVariables = Exact<{
  input: DestroyProfilePageAnswerInput;
}>;

export type DestroyProfilePageAnswerMutation = {
  __typename: 'Mutation';
  destroyProfilePageAnswer: {
    __typename: 'DestroyProfilePageAnswerPayload';
    clientMutationId: string | null;
  } | null;
};

export type UpdateProfilePageSetupMutationVariables = Exact<{
  input: UpdateProfilePageSetupInput;
}>;

export type UpdateProfilePageSetupMutation = {
  __typename: 'Mutation';
  updateProfilePageSetup: {
    __typename: 'UpdateProfilePageSetupPayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    profilePageSetup: {
      __typename: 'ProfilePageSetupNode';
      id: string;
      photoFormat: PhotoFormat | null;
      maxChars: number;
      anonymousComments: boolean;
      visibleComments: boolean;
    } | null;
  } | null;
};

export type UpdateModuleInstanceMutationVariables = Exact<{
  input: UpdateModuleInstanceInput;
}>;

export type UpdateModuleInstanceMutation = {
  __typename: 'Mutation';
  updateModuleInstance: {
    __typename: 'UpdateModuleInstancePayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    moduleInstance: {
      __typename: 'ModuleInstanceNode';
      id: string;
      isActive: boolean;
      dueDate: any | null;
      profilePageSetup: {
        __typename: 'ProfilePageSetupNode';
        id: string;
        photoFormat: PhotoFormat | null;
        maxChars: number;
        anonymousComments: boolean;
        visibleComments: boolean;
      } | null;
      rankingsSetup: {
        __typename: 'RankingsSetupNode';
        id: string;
        resultsTeam: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
      } | null;
      collagesSetup: {
        __typename: 'CollagesSetupNode';
        id: string;
        photosCount: number | null;
        createAlbumsTeam: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
        addPhotosTeam: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
        viewPhotosTeam: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
      } | null;
      reportsSetup: {
        __typename: 'ReportsSetupNode';
        id: string;
        allowImages: boolean;
      } | null;
      quotesSetup: {
        __typename: 'QuotesSetupNode';
        id: string;
        anonymous: boolean;
        public: boolean;
        maxChars: number;
      } | null;
      customPagesSetup: {
        __typename: 'CustomPagesSetupNode';
        id: string;
        guide: string | null;
        createPagesTeam: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
        viewPagesTeam: {
          __typename: 'TeamNode';
          id: string;
          name: string;
          type: TeamType | null;
          approvalSetting: ApprovalSetting | null;
          isHidden: boolean;
          created: any;
          color: { __typename: 'ColorNode'; id: string; hex: string } | null;
          icon: { __typename: 'IconNode'; id: string; icon: string } | null;
        } | null;
      } | null;
      module: {
        __typename: 'ModuleNode';
        id: string;
        name: string | null;
        image: string | null;
        type: ModuleType | null;
        description: string | null;
        features: Array<string | null> | null;
      };
    } | null;
  } | null;
};

export type CreateProfilePageCommentMutationVariables = Exact<{
  input: CreateProfilePageCommentInput;
}>;

export type CreateProfilePageCommentMutation = {
  __typename: 'Mutation';
  createProfilePageComment: {
    __typename: 'CreateProfilePageCommentPayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    profilePageComment: {
      __typename: 'ProfilePageCommentNode';
      id: string;
      text: string;
      created: any;
      comentatorUserGroup: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      };
      profileUserGroup: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      };
    } | null;
  } | null;
};

export type UpdateProfilePageCommentMutationVariables = Exact<{
  input: UpdateProfilePageCommentInput;
}>;

export type UpdateProfilePageCommentMutation = {
  __typename: 'Mutation';
  updateProfilePageComment: {
    __typename: 'UpdateProfilePageCommentPayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    profilePageComment: {
      __typename: 'ProfilePageCommentNode';
      id: string;
      text: string;
      created: any;
      comentatorUserGroup: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      };
      profileUserGroup: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      };
    } | null;
  } | null;
};

export type DestroyProfilePageCommentMutationVariables = Exact<{
  input: DestroyProfilePageCommentInput;
}>;

export type DestroyProfilePageCommentMutation = {
  __typename: 'Mutation';
  destroyProfilePageComment: {
    __typename: 'DestroyProfilePageCommentPayload';
    profilePageComment: {
      __typename: 'ProfilePageCommentNode';
      id: string;
      text: string;
      created: any;
      comentatorUserGroup: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      };
      profileUserGroup: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      };
    } | null;
  } | null;
};

export type MoveProfilePageQuestionToPositionMutationVariables = Exact<{
  input: MoveProfilePageQuestionToPositionInput;
}>;

export type MoveProfilePageQuestionToPositionMutation = {
  __typename: 'Mutation';
  moveProfilePageQuestionToPosition: {
    __typename: 'MoveProfilePageQuestionToPositionPayload';
    profilePageQuestion: {
      __typename: 'ProfilePageQuestionNode';
      id: string;
      text: string;
      order: number;
      profilePageQuestionOptions: Array<{
        __typename: 'ProfilePageQuestionOptionNode';
        id: string;
        text: string;
      } | null> | null;
    } | null;
  } | null;
};

export type AddPhotoToCollagesAlbumMutationVariables = Exact<{
  input: AddPhotoToCollagesAlbumInput;
  authUserGroupId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type AddPhotoToCollagesAlbumMutation = {
  __typename: 'Mutation';
  addPhotoToCollagesAlbum: {
    __typename: 'AddPhotoToCollagesAlbumPayload';
    photo: {
      __typename: 'CollagesPhotoNode';
      id: string;
      photo: string;
      collagesPhotoLikesCount: number | null;
      created: any;
      userGroup: { __typename: 'UserGroupNode'; id: string };
      collagesPhotoLikes: {
        __typename: 'CollagesPhotoLikeNodeConnection';
        edges: Array<{
          __typename: 'CollagesPhotoLikeNodeEdge';
          node: {
            __typename: 'CollagesPhotoLikeNode';
            userGroup: { __typename: 'UserGroupNode'; id: string } | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type RemovePhotoFromCollagesAlbumMutationVariables = Exact<{
  input: RemovePhotoFromCollagesAlbumInput;
}>;

export type RemovePhotoFromCollagesAlbumMutation = {
  __typename: 'Mutation';
  removePhotoFromCollagesAlbum: {
    __typename: 'RemovePhotoFromCollagesAlbumPayload';
    success: boolean | null;
  } | null;
};

export type CreateCollagesAlbumMutationVariables = Exact<{
  input: CreateCollagesAlbumInput;
}>;

export type CreateCollagesAlbumMutation = {
  __typename: 'Mutation';
  createCollagesAlbum: {
    __typename: 'CreateCollagesAlbumPayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    collagesAlbum: {
      __typename: 'CollagesAlbumNode';
      id: string;
      name: string;
    } | null;
  } | null;
};

export type UpdateCollagesAlbumMutationVariables = Exact<{
  input: UpdateCollagesAlbumInput;
}>;

export type UpdateCollagesAlbumMutation = {
  __typename: 'Mutation';
  updateCollagesAlbum: {
    __typename: 'UpdateCollagesAlbumPayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    collagesAlbum: {
      __typename: 'CollagesAlbumNode';
      id: string;
      name: string;
    } | null;
  } | null;
};

export type DestroyCollagesAlbumMutationVariables = Exact<{
  input: DestroyCollagesAlbumInput;
}>;

export type DestroyCollagesAlbumMutation = {
  __typename: 'Mutation';
  destroyCollagesAlbum: {
    __typename: 'DestroyCollagesAlbumPayload';
    collagesAlbum: {
      __typename: 'CollagesAlbumNode';
      id: string;
      name: string;
    } | null;
  } | null;
};

export type LikeCollagesPhotoMutationVariables = Exact<{
  input: LikeCollagesPhotoInput;
  authUserGroupId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type LikeCollagesPhotoMutation = {
  __typename: 'Mutation';
  likeCollagesPhoto: {
    __typename: 'LikeCollagesPhotoPayload';
    collagesPhoto: {
      __typename: 'CollagesPhotoNode';
      id: string;
      photo: string;
      collagesPhotoLikesCount: number | null;
      created: any;
      userGroup: { __typename: 'UserGroupNode'; id: string };
      collagesPhotoLikes: {
        __typename: 'CollagesPhotoLikeNodeConnection';
        edges: Array<{
          __typename: 'CollagesPhotoLikeNodeEdge';
          node: {
            __typename: 'CollagesPhotoLikeNode';
            userGroup: { __typename: 'UserGroupNode'; id: string } | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type RemoveLikeFromCollagesPhotoMutationVariables = Exact<{
  input: RemoveLikeFromCollagesPhotoInput;
  authUserGroupId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type RemoveLikeFromCollagesPhotoMutation = {
  __typename: 'Mutation';
  removeLikeFromCollagesPhoto: {
    __typename: 'RemoveLikeFromCollagesPhotoPayload';
    collagesPhoto: {
      __typename: 'CollagesPhotoNode';
      id: string;
      photo: string;
      collagesPhotoLikesCount: number | null;
      created: any;
      userGroup: { __typename: 'UserGroupNode'; id: string };
      collagesPhotoLikes: {
        __typename: 'CollagesPhotoLikeNodeConnection';
        edges: Array<{
          __typename: 'CollagesPhotoLikeNodeEdge';
          node: {
            __typename: 'CollagesPhotoLikeNode';
            userGroup: { __typename: 'UserGroupNode'; id: string } | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type UpdateCollagesSetupMutationVariables = Exact<{
  input: UpdateCollagesSetupInput;
}>;

export type UpdateCollagesSetupMutation = {
  __typename: 'Mutation';
  updateCollagesSetup: {
    __typename: 'UpdateCollagesSetupPayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    collagesSetup: {
      __typename: 'CollagesSetupNode';
      id: string;
      photosCount: number | null;
      createAlbumsTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
      addPhotosTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
      viewPhotosTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateReportsSetupMutationVariables = Exact<{
  input: UpdateReportsSetupInput;
}>;

export type UpdateReportsSetupMutation = {
  __typename: 'Mutation';
  updateReportsSetup: {
    __typename: 'UpdateReportsSetupPayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    reportsSetup: {
      __typename: 'ReportsSetupNode';
      id: string;
      allowImages: boolean;
    } | null;
  } | null;
};

export type CreateReportsInstanceMutationVariables = Exact<{
  input: CreateReportsInstanceInput;
}>;

export type CreateReportsInstanceMutation = {
  __typename: 'Mutation';
  createReportsInstance: {
    __typename: 'CreateReportsInstancePayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    reportsInstance: {
      __typename: 'ReportsInstanceNode';
      id: string;
      title: string;
      text: string;
      maxChars: number;
      editTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
      viewTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateReportsInstanceMutationVariables = Exact<{
  input: UpdateReportsInstanceInput;
}>;

export type UpdateReportsInstanceMutation = {
  __typename: 'Mutation';
  updateReportsInstance: {
    __typename: 'UpdateReportsInstancePayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    reportsInstance: {
      __typename: 'ReportsInstanceNode';
      id: string;
      title: string;
      text: string;
      maxChars: number;
      editTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
      viewTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
    } | null;
  } | null;
};

export type DestroyReportsInstanceMutationVariables = Exact<{
  input: DestroyReportsInstanceInput;
}>;

export type DestroyReportsInstanceMutation = {
  __typename: 'Mutation';
  destroyReportsInstance: {
    __typename: 'DestroyReportsInstancePayload';
    success: boolean | null;
    reportsInstanceId: string | null;
  } | null;
};

export type CreateQuotesInstanceMutationVariables = Exact<{
  input: CreateQuotesInstanceInput;
}>;

export type CreateQuotesInstanceMutation = {
  __typename: 'Mutation';
  createQuotesInstance: {
    __typename: 'CreateQuotesInstancePayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    quotesInstance: {
      __typename: 'QuotesInstanceNode';
      id: string;
      quote: string;
      quotesInstanceLikesCount: number | null;
      created: any;
      userGroup: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      };
    } | null;
  } | null;
};

export type DestroyQuotesInstanceMutationVariables = Exact<{
  input: DestroyQuotesInstanceInput;
}>;

export type DestroyQuotesInstanceMutation = {
  __typename: 'Mutation';
  destroyQuotesInstance: {
    __typename: 'DestroyQuotesInstancePayload';
    quotesInstance: {
      __typename: 'QuotesInstanceNode';
      id: string;
      quote: string;
      quotesInstanceLikesCount: number | null;
      created: any;
      userGroup: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      };
    } | null;
  } | null;
};

export type UpdateQuotesSetupMutationVariables = Exact<{
  input: UpdateQuotesSetupInput;
}>;

export type UpdateQuotesSetupMutation = {
  __typename: 'Mutation';
  updateQuotesSetup: {
    __typename: 'UpdateQuotesSetupPayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    quotesSetup: {
      __typename: 'QuotesSetupNode';
      id: string;
      anonymous: boolean;
      public: boolean;
      maxChars: number;
    } | null;
  } | null;
};

export type LikeQuotesInstanceMutationVariables = Exact<{
  input: LikeQuotesInstanceInput;
  authUserGroupId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type LikeQuotesInstanceMutation = {
  __typename: 'Mutation';
  likeQuotesInstance: {
    __typename: 'LikeQuotesInstancePayload';
    quotesInstance: {
      __typename: 'QuotesInstanceNode';
      id: string;
      quote: string;
      quotesInstanceLikesCount: number | null;
      created: any;
      quotesInstanceLikes: {
        __typename: 'QuotesInstanceLikeNodeConnection';
        edges: Array<{
          __typename: 'QuotesInstanceLikeNodeEdge';
          node: {
            __typename: 'QuotesInstanceLikeNode';
            id: string;
            userGroup: { __typename: 'UserGroupNode'; id: string } | null;
          } | null;
        } | null>;
      } | null;
      userGroup: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      };
    } | null;
  } | null;
};

export type RemoveLikeFromQuotesInstanceMutationVariables = Exact<{
  input: RemoveLikeFromQuotesInstanceInput;
  authUserGroupId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type RemoveLikeFromQuotesInstanceMutation = {
  __typename: 'Mutation';
  removeLikeFromQuotesInstance: {
    __typename: 'RemoveLikeFromQuotesInstancePayload';
    quotesInstance: {
      __typename: 'QuotesInstanceNode';
      id: string;
      quote: string;
      quotesInstanceLikesCount: number | null;
      created: any;
      quotesInstanceLikes: {
        __typename: 'QuotesInstanceLikeNodeConnection';
        edges: Array<{
          __typename: 'QuotesInstanceLikeNodeEdge';
          node: {
            __typename: 'QuotesInstanceLikeNode';
            id: string;
            userGroup: { __typename: 'UserGroupNode'; id: string } | null;
          } | null;
        } | null>;
      } | null;
      userGroup: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      };
    } | null;
  } | null;
};

export type UpdateRankingsSetupMutationVariables = Exact<{
  input: UpdateRankingsSetupInput;
}>;

export type UpdateRankingsSetupMutation = {
  __typename: 'Mutation';
  updateRankingsSetup: {
    __typename: 'UpdateRankingsSetupPayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    rankingsSetup: {
      __typename: 'RankingsSetupNode';
      id: string;
      resultsTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateCustomPagesSetupMutationVariables = Exact<{
  input: UpdateCustomPagesSetupInput;
}>;

export type UpdateCustomPagesSetupMutation = {
  __typename: 'Mutation';
  updateCustomPagesSetup: {
    __typename: 'UpdateCustomPagesSetupPayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    customPagesSetup: {
      __typename: 'CustomPagesSetupNode';
      id: string;
      guide: string | null;
      createPagesTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
      viewPagesTeam: {
        __typename: 'TeamNode';
        id: string;
        name: string;
        type: TeamType | null;
        approvalSetting: ApprovalSetting | null;
        isHidden: boolean;
        created: any;
        color: { __typename: 'ColorNode'; id: string; hex: string } | null;
        icon: { __typename: 'IconNode'; id: string; icon: string } | null;
      } | null;
    } | null;
  } | null;
};

export type CreateCustomPagesInstanceMutationVariables = Exact<{
  input: CreateCustomPagesInstanceInput;
}>;

export type CreateCustomPagesInstanceMutation = {
  __typename: 'Mutation';
  createCustomPagesInstance: {
    __typename: 'CreateCustomPagesInstancePayload';
    customPagesInstance: {
      __typename: 'CustomPagesInstanceNode';
      id: string;
      title: string;
      created: any;
      modified: any;
      page: string | null;
      pageFormat: PageFormat | null;
      pageType: PageType | null;
      pageQuality: PageQuality | null;
      userGroup: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      } | null;
      moduleInstance: {
        __typename: 'ModuleInstanceNode';
        id: string;
        isActive: boolean;
        dueDate: any | null;
        profilePageSetup: {
          __typename: 'ProfilePageSetupNode';
          id: string;
          photoFormat: PhotoFormat | null;
          maxChars: number;
          anonymousComments: boolean;
          visibleComments: boolean;
        } | null;
        rankingsSetup: {
          __typename: 'RankingsSetupNode';
          id: string;
          resultsTeam: {
            __typename: 'TeamNode';
            id: string;
            name: string;
            type: TeamType | null;
            approvalSetting: ApprovalSetting | null;
            isHidden: boolean;
            created: any;
            color: { __typename: 'ColorNode'; id: string; hex: string } | null;
            icon: { __typename: 'IconNode'; id: string; icon: string } | null;
          } | null;
        } | null;
        collagesSetup: {
          __typename: 'CollagesSetupNode';
          id: string;
          photosCount: number | null;
          createAlbumsTeam: {
            __typename: 'TeamNode';
            id: string;
            name: string;
            type: TeamType | null;
            approvalSetting: ApprovalSetting | null;
            isHidden: boolean;
            created: any;
            color: { __typename: 'ColorNode'; id: string; hex: string } | null;
            icon: { __typename: 'IconNode'; id: string; icon: string } | null;
          } | null;
          addPhotosTeam: {
            __typename: 'TeamNode';
            id: string;
            name: string;
            type: TeamType | null;
            approvalSetting: ApprovalSetting | null;
            isHidden: boolean;
            created: any;
            color: { __typename: 'ColorNode'; id: string; hex: string } | null;
            icon: { __typename: 'IconNode'; id: string; icon: string } | null;
          } | null;
          viewPhotosTeam: {
            __typename: 'TeamNode';
            id: string;
            name: string;
            type: TeamType | null;
            approvalSetting: ApprovalSetting | null;
            isHidden: boolean;
            created: any;
            color: { __typename: 'ColorNode'; id: string; hex: string } | null;
            icon: { __typename: 'IconNode'; id: string; icon: string } | null;
          } | null;
        } | null;
        reportsSetup: {
          __typename: 'ReportsSetupNode';
          id: string;
          allowImages: boolean;
        } | null;
        quotesSetup: {
          __typename: 'QuotesSetupNode';
          id: string;
          anonymous: boolean;
          public: boolean;
          maxChars: number;
        } | null;
        customPagesSetup: {
          __typename: 'CustomPagesSetupNode';
          id: string;
          guide: string | null;
          createPagesTeam: {
            __typename: 'TeamNode';
            id: string;
            name: string;
            type: TeamType | null;
            approvalSetting: ApprovalSetting | null;
            isHidden: boolean;
            created: any;
            color: { __typename: 'ColorNode'; id: string; hex: string } | null;
            icon: { __typename: 'IconNode'; id: string; icon: string } | null;
          } | null;
          viewPagesTeam: {
            __typename: 'TeamNode';
            id: string;
            name: string;
            type: TeamType | null;
            approvalSetting: ApprovalSetting | null;
            isHidden: boolean;
            created: any;
            color: { __typename: 'ColorNode'; id: string; hex: string } | null;
            icon: { __typename: 'IconNode'; id: string; icon: string } | null;
          } | null;
        } | null;
        module: {
          __typename: 'ModuleNode';
          id: string;
          name: string | null;
          image: string | null;
          type: ModuleType | null;
          description: string | null;
          features: Array<string | null> | null;
        };
      };
    } | null;
  } | null;
};

export type UpdateCustomPagesInstanceMutationVariables = Exact<{
  input: UpdateCustomPagesInstanceInput;
}>;

export type UpdateCustomPagesInstanceMutation = {
  __typename: 'Mutation';
  updateCustomPagesInstance: {
    __typename: 'UpdateCustomPagesInstancePayload';
    customPagesInstance: {
      __typename: 'CustomPagesInstanceNode';
      id: string;
      title: string;
      created: any;
      modified: any;
      page: string | null;
      pageFormat: PageFormat | null;
      pageType: PageType | null;
      pageQuality: PageQuality | null;
      userGroup: {
        __typename: 'UserGroupNode';
        id: string;
        role: UserType | null;
        avatar: string | null;
        status: Status | null;
        created: any;
        defaultAvatar: {
          __typename: 'DefaultAvatarNode';
          id: string;
          avatar: string | null;
        } | null;
        user: {
          __typename: 'UserNode';
          id: string;
          firstName: string;
          lastName: string;
          gender: UsersUserGenderChoices | null;
        };
      } | null;
      moduleInstance: {
        __typename: 'ModuleInstanceNode';
        id: string;
        isActive: boolean;
        dueDate: any | null;
        profilePageSetup: {
          __typename: 'ProfilePageSetupNode';
          id: string;
          photoFormat: PhotoFormat | null;
          maxChars: number;
          anonymousComments: boolean;
          visibleComments: boolean;
        } | null;
        rankingsSetup: {
          __typename: 'RankingsSetupNode';
          id: string;
          resultsTeam: {
            __typename: 'TeamNode';
            id: string;
            name: string;
            type: TeamType | null;
            approvalSetting: ApprovalSetting | null;
            isHidden: boolean;
            created: any;
            color: { __typename: 'ColorNode'; id: string; hex: string } | null;
            icon: { __typename: 'IconNode'; id: string; icon: string } | null;
          } | null;
        } | null;
        collagesSetup: {
          __typename: 'CollagesSetupNode';
          id: string;
          photosCount: number | null;
          createAlbumsTeam: {
            __typename: 'TeamNode';
            id: string;
            name: string;
            type: TeamType | null;
            approvalSetting: ApprovalSetting | null;
            isHidden: boolean;
            created: any;
            color: { __typename: 'ColorNode'; id: string; hex: string } | null;
            icon: { __typename: 'IconNode'; id: string; icon: string } | null;
          } | null;
          addPhotosTeam: {
            __typename: 'TeamNode';
            id: string;
            name: string;
            type: TeamType | null;
            approvalSetting: ApprovalSetting | null;
            isHidden: boolean;
            created: any;
            color: { __typename: 'ColorNode'; id: string; hex: string } | null;
            icon: { __typename: 'IconNode'; id: string; icon: string } | null;
          } | null;
          viewPhotosTeam: {
            __typename: 'TeamNode';
            id: string;
            name: string;
            type: TeamType | null;
            approvalSetting: ApprovalSetting | null;
            isHidden: boolean;
            created: any;
            color: { __typename: 'ColorNode'; id: string; hex: string } | null;
            icon: { __typename: 'IconNode'; id: string; icon: string } | null;
          } | null;
        } | null;
        reportsSetup: {
          __typename: 'ReportsSetupNode';
          id: string;
          allowImages: boolean;
        } | null;
        quotesSetup: {
          __typename: 'QuotesSetupNode';
          id: string;
          anonymous: boolean;
          public: boolean;
          maxChars: number;
        } | null;
        customPagesSetup: {
          __typename: 'CustomPagesSetupNode';
          id: string;
          guide: string | null;
          createPagesTeam: {
            __typename: 'TeamNode';
            id: string;
            name: string;
            type: TeamType | null;
            approvalSetting: ApprovalSetting | null;
            isHidden: boolean;
            created: any;
            color: { __typename: 'ColorNode'; id: string; hex: string } | null;
            icon: { __typename: 'IconNode'; id: string; icon: string } | null;
          } | null;
          viewPagesTeam: {
            __typename: 'TeamNode';
            id: string;
            name: string;
            type: TeamType | null;
            approvalSetting: ApprovalSetting | null;
            isHidden: boolean;
            created: any;
            color: { __typename: 'ColorNode'; id: string; hex: string } | null;
            icon: { __typename: 'IconNode'; id: string; icon: string } | null;
          } | null;
        } | null;
        module: {
          __typename: 'ModuleNode';
          id: string;
          name: string | null;
          image: string | null;
          type: ModuleType | null;
          description: string | null;
          features: Array<string | null> | null;
        };
      };
    } | null;
  } | null;
};

export type DestroyCustomPagesInstanceMutationVariables = Exact<{
  input: DestroyCustomPagesInstanceInput;
}>;

export type DestroyCustomPagesInstanceMutation = {
  __typename: 'Mutation';
  destroyCustomPagesInstance: {
    __typename: 'DestroyCustomPagesInstancePayload';
    success: boolean | null;
    customPagesInstanceId: string | null;
  } | null;
};

export type CreateRankingsQuestionMutationVariables = Exact<{
  input: CreateRankingsQuestionInput;
  authUserGroupId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CreateRankingsQuestionMutation = {
  __typename: 'Mutation';
  createRankingsQuestion: {
    __typename: 'CreateRankingsQuestionPayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    rankingsQuestion: {
      __typename: 'RankingsQuestionNode';
      id: string;
      question: string;
      answerType: AnswerType | null;
      allowedVotes: AllowedVoteType | null;
      onlyOppositeGender: boolean | null;
      customList: { __typename: 'CustomListNode'; id: string } | null;
      results: {
        __typename: 'RankingsQuestionResultsNode';
        alreadyVoted: number | null;
        possibleVoters: number | null;
        options: Array<{
          __typename: 'RankingsQuestionResultNode';
          percentage: number | null;
          votee1UserGroup: {
            __typename: 'UserGroupNode';
            id: string;
            role: UserType | null;
            avatar: string | null;
            status: Status | null;
            created: any;
            defaultAvatar: {
              __typename: 'DefaultAvatarNode';
              id: string;
              avatar: string | null;
            } | null;
            user: {
              __typename: 'UserNode';
              id: string;
              firstName: string;
              lastName: string;
              gender: UsersUserGenderChoices | null;
            };
          } | null;
          votee2UserGroup: {
            __typename: 'UserGroupNode';
            id: string;
            role: UserType | null;
            avatar: string | null;
            status: Status | null;
            created: any;
            defaultAvatar: {
              __typename: 'DefaultAvatarNode';
              id: string;
              avatar: string | null;
            } | null;
            user: {
              __typename: 'UserNode';
              id: string;
              firstName: string;
              lastName: string;
              gender: UsersUserGenderChoices | null;
            };
          } | null;
          voteeListOption: {
            __typename: 'CustomListOptionNode';
            id: string;
            text: string | null;
          } | null;
        } | null> | null;
      } | null;
      rankingsAnswers: {
        __typename: 'RankingsAnswerNodeConnection';
        edges: Array<{
          __typename: 'RankingsAnswerNodeEdge';
          node: {
            __typename: 'RankingsAnswerNode';
            id: string;
            votee1UserGroup: {
              __typename: 'UserGroupNode';
              id: string;
              role: UserType | null;
              avatar: string | null;
              status: Status | null;
              created: any;
              defaultAvatar: {
                __typename: 'DefaultAvatarNode';
                id: string;
                avatar: string | null;
              } | null;
              user: {
                __typename: 'UserNode';
                id: string;
                firstName: string;
                lastName: string;
                gender: UsersUserGenderChoices | null;
              };
            } | null;
            votee2UserGroup: {
              __typename: 'UserGroupNode';
              id: string;
              role: UserType | null;
              avatar: string | null;
              status: Status | null;
              created: any;
              defaultAvatar: {
                __typename: 'DefaultAvatarNode';
                id: string;
                avatar: string | null;
              } | null;
              user: {
                __typename: 'UserNode';
                id: string;
                firstName: string;
                lastName: string;
                gender: UsersUserGenderChoices | null;
              };
            } | null;
            voteeListOption: {
              __typename: 'CustomListOptionNode';
              id: string;
              text: string | null;
            } | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type UpdateRankingsQuestionMutationVariables = Exact<{
  input: UpdateRankingsQuestionInput;
  authUserGroupId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type UpdateRankingsQuestionMutation = {
  __typename: 'Mutation';
  updateRankingsQuestion: {
    __typename: 'UpdateRankingsQuestionPayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    rankingsQuestion: {
      __typename: 'RankingsQuestionNode';
      id: string;
      question: string;
      answerType: AnswerType | null;
      allowedVotes: AllowedVoteType | null;
      onlyOppositeGender: boolean | null;
      customList: { __typename: 'CustomListNode'; id: string } | null;
      results: {
        __typename: 'RankingsQuestionResultsNode';
        alreadyVoted: number | null;
        possibleVoters: number | null;
        options: Array<{
          __typename: 'RankingsQuestionResultNode';
          percentage: number | null;
          votee1UserGroup: {
            __typename: 'UserGroupNode';
            id: string;
            role: UserType | null;
            avatar: string | null;
            status: Status | null;
            created: any;
            defaultAvatar: {
              __typename: 'DefaultAvatarNode';
              id: string;
              avatar: string | null;
            } | null;
            user: {
              __typename: 'UserNode';
              id: string;
              firstName: string;
              lastName: string;
              gender: UsersUserGenderChoices | null;
            };
          } | null;
          votee2UserGroup: {
            __typename: 'UserGroupNode';
            id: string;
            role: UserType | null;
            avatar: string | null;
            status: Status | null;
            created: any;
            defaultAvatar: {
              __typename: 'DefaultAvatarNode';
              id: string;
              avatar: string | null;
            } | null;
            user: {
              __typename: 'UserNode';
              id: string;
              firstName: string;
              lastName: string;
              gender: UsersUserGenderChoices | null;
            };
          } | null;
          voteeListOption: {
            __typename: 'CustomListOptionNode';
            id: string;
            text: string | null;
          } | null;
        } | null> | null;
      } | null;
      rankingsAnswers: {
        __typename: 'RankingsAnswerNodeConnection';
        edges: Array<{
          __typename: 'RankingsAnswerNodeEdge';
          node: {
            __typename: 'RankingsAnswerNode';
            id: string;
            votee1UserGroup: {
              __typename: 'UserGroupNode';
              id: string;
              role: UserType | null;
              avatar: string | null;
              status: Status | null;
              created: any;
              defaultAvatar: {
                __typename: 'DefaultAvatarNode';
                id: string;
                avatar: string | null;
              } | null;
              user: {
                __typename: 'UserNode';
                id: string;
                firstName: string;
                lastName: string;
                gender: UsersUserGenderChoices | null;
              };
            } | null;
            votee2UserGroup: {
              __typename: 'UserGroupNode';
              id: string;
              role: UserType | null;
              avatar: string | null;
              status: Status | null;
              created: any;
              defaultAvatar: {
                __typename: 'DefaultAvatarNode';
                id: string;
                avatar: string | null;
              } | null;
              user: {
                __typename: 'UserNode';
                id: string;
                firstName: string;
                lastName: string;
                gender: UsersUserGenderChoices | null;
              };
            } | null;
            voteeListOption: {
              __typename: 'CustomListOptionNode';
              id: string;
              text: string | null;
            } | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type DestroyRankingsQuestionMutationVariables = Exact<{
  input: DestroyRankingsQuestionInput;
  authUserGroupId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type DestroyRankingsQuestionMutation = {
  __typename: 'Mutation';
  destroyRankingsQuestion: {
    __typename: 'DestroyRankingsQuestionPayload';
    rankingsQuestion: {
      __typename: 'RankingsQuestionNode';
      id: string;
      question: string;
      answerType: AnswerType | null;
      allowedVotes: AllowedVoteType | null;
      onlyOppositeGender: boolean | null;
      customList: { __typename: 'CustomListNode'; id: string } | null;
      results: {
        __typename: 'RankingsQuestionResultsNode';
        alreadyVoted: number | null;
        possibleVoters: number | null;
        options: Array<{
          __typename: 'RankingsQuestionResultNode';
          percentage: number | null;
          votee1UserGroup: {
            __typename: 'UserGroupNode';
            id: string;
            role: UserType | null;
            avatar: string | null;
            status: Status | null;
            created: any;
            defaultAvatar: {
              __typename: 'DefaultAvatarNode';
              id: string;
              avatar: string | null;
            } | null;
            user: {
              __typename: 'UserNode';
              id: string;
              firstName: string;
              lastName: string;
              gender: UsersUserGenderChoices | null;
            };
          } | null;
          votee2UserGroup: {
            __typename: 'UserGroupNode';
            id: string;
            role: UserType | null;
            avatar: string | null;
            status: Status | null;
            created: any;
            defaultAvatar: {
              __typename: 'DefaultAvatarNode';
              id: string;
              avatar: string | null;
            } | null;
            user: {
              __typename: 'UserNode';
              id: string;
              firstName: string;
              lastName: string;
              gender: UsersUserGenderChoices | null;
            };
          } | null;
          voteeListOption: {
            __typename: 'CustomListOptionNode';
            id: string;
            text: string | null;
          } | null;
        } | null> | null;
      } | null;
      rankingsAnswers: {
        __typename: 'RankingsAnswerNodeConnection';
        edges: Array<{
          __typename: 'RankingsAnswerNodeEdge';
          node: {
            __typename: 'RankingsAnswerNode';
            id: string;
            votee1UserGroup: {
              __typename: 'UserGroupNode';
              id: string;
              role: UserType | null;
              avatar: string | null;
              status: Status | null;
              created: any;
              defaultAvatar: {
                __typename: 'DefaultAvatarNode';
                id: string;
                avatar: string | null;
              } | null;
              user: {
                __typename: 'UserNode';
                id: string;
                firstName: string;
                lastName: string;
                gender: UsersUserGenderChoices | null;
              };
            } | null;
            votee2UserGroup: {
              __typename: 'UserGroupNode';
              id: string;
              role: UserType | null;
              avatar: string | null;
              status: Status | null;
              created: any;
              defaultAvatar: {
                __typename: 'DefaultAvatarNode';
                id: string;
                avatar: string | null;
              } | null;
              user: {
                __typename: 'UserNode';
                id: string;
                firstName: string;
                lastName: string;
                gender: UsersUserGenderChoices | null;
              };
            } | null;
            voteeListOption: {
              __typename: 'CustomListOptionNode';
              id: string;
              text: string | null;
            } | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type CreateRankingsAnswerMutationVariables = Exact<{
  input: CreateRankingsAnswerInput;
  authUserGroupId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CreateRankingsAnswerMutation = {
  __typename: 'Mutation';
  createRankingsAnswer: {
    __typename: 'CreateRankingsAnswerPayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    rankingsAnswer: {
      __typename: 'RankingsAnswerNode';
      rankingsQuestion: {
        __typename: 'RankingsQuestionNode';
        id: string;
        question: string;
        answerType: AnswerType | null;
        allowedVotes: AllowedVoteType | null;
        onlyOppositeGender: boolean | null;
        customList: { __typename: 'CustomListNode'; id: string } | null;
        results: {
          __typename: 'RankingsQuestionResultsNode';
          alreadyVoted: number | null;
          possibleVoters: number | null;
          options: Array<{
            __typename: 'RankingsQuestionResultNode';
            percentage: number | null;
            votee1UserGroup: {
              __typename: 'UserGroupNode';
              id: string;
              role: UserType | null;
              avatar: string | null;
              status: Status | null;
              created: any;
              defaultAvatar: {
                __typename: 'DefaultAvatarNode';
                id: string;
                avatar: string | null;
              } | null;
              user: {
                __typename: 'UserNode';
                id: string;
                firstName: string;
                lastName: string;
                gender: UsersUserGenderChoices | null;
              };
            } | null;
            votee2UserGroup: {
              __typename: 'UserGroupNode';
              id: string;
              role: UserType | null;
              avatar: string | null;
              status: Status | null;
              created: any;
              defaultAvatar: {
                __typename: 'DefaultAvatarNode';
                id: string;
                avatar: string | null;
              } | null;
              user: {
                __typename: 'UserNode';
                id: string;
                firstName: string;
                lastName: string;
                gender: UsersUserGenderChoices | null;
              };
            } | null;
            voteeListOption: {
              __typename: 'CustomListOptionNode';
              id: string;
              text: string | null;
            } | null;
          } | null> | null;
        } | null;
        rankingsAnswers: {
          __typename: 'RankingsAnswerNodeConnection';
          edges: Array<{
            __typename: 'RankingsAnswerNodeEdge';
            node: {
              __typename: 'RankingsAnswerNode';
              id: string;
              votee1UserGroup: {
                __typename: 'UserGroupNode';
                id: string;
                role: UserType | null;
                avatar: string | null;
                status: Status | null;
                created: any;
                defaultAvatar: {
                  __typename: 'DefaultAvatarNode';
                  id: string;
                  avatar: string | null;
                } | null;
                user: {
                  __typename: 'UserNode';
                  id: string;
                  firstName: string;
                  lastName: string;
                  gender: UsersUserGenderChoices | null;
                };
              } | null;
              votee2UserGroup: {
                __typename: 'UserGroupNode';
                id: string;
                role: UserType | null;
                avatar: string | null;
                status: Status | null;
                created: any;
                defaultAvatar: {
                  __typename: 'DefaultAvatarNode';
                  id: string;
                  avatar: string | null;
                } | null;
                user: {
                  __typename: 'UserNode';
                  id: string;
                  firstName: string;
                  lastName: string;
                  gender: UsersUserGenderChoices | null;
                };
              } | null;
              voteeListOption: {
                __typename: 'CustomListOptionNode';
                id: string;
                text: string | null;
              } | null;
            } | null;
          } | null>;
        } | null;
      };
    } | null;
  } | null;
};

export type UpdateRankingsAnswerMutationVariables = Exact<{
  input: UpdateRankingsAnswerInput;
  authUserGroupId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type UpdateRankingsAnswerMutation = {
  __typename: 'Mutation';
  updateRankingsAnswer: {
    __typename: 'UpdateRankingsAnswerPayload';
    errors: Array<{
      __typename: 'ErrorType';
      field: string;
      messages: Array<string>;
    } | null> | null;
    rankingsAnswer: {
      __typename: 'RankingsAnswerNode';
      rankingsQuestion: {
        __typename: 'RankingsQuestionNode';
        id: string;
        question: string;
        answerType: AnswerType | null;
        allowedVotes: AllowedVoteType | null;
        onlyOppositeGender: boolean | null;
        customList: { __typename: 'CustomListNode'; id: string } | null;
        results: {
          __typename: 'RankingsQuestionResultsNode';
          alreadyVoted: number | null;
          possibleVoters: number | null;
          options: Array<{
            __typename: 'RankingsQuestionResultNode';
            percentage: number | null;
            votee1UserGroup: {
              __typename: 'UserGroupNode';
              id: string;
              role: UserType | null;
              avatar: string | null;
              status: Status | null;
              created: any;
              defaultAvatar: {
                __typename: 'DefaultAvatarNode';
                id: string;
                avatar: string | null;
              } | null;
              user: {
                __typename: 'UserNode';
                id: string;
                firstName: string;
                lastName: string;
                gender: UsersUserGenderChoices | null;
              };
            } | null;
            votee2UserGroup: {
              __typename: 'UserGroupNode';
              id: string;
              role: UserType | null;
              avatar: string | null;
              status: Status | null;
              created: any;
              defaultAvatar: {
                __typename: 'DefaultAvatarNode';
                id: string;
                avatar: string | null;
              } | null;
              user: {
                __typename: 'UserNode';
                id: string;
                firstName: string;
                lastName: string;
                gender: UsersUserGenderChoices | null;
              };
            } | null;
            voteeListOption: {
              __typename: 'CustomListOptionNode';
              id: string;
              text: string | null;
            } | null;
          } | null> | null;
        } | null;
        rankingsAnswers: {
          __typename: 'RankingsAnswerNodeConnection';
          edges: Array<{
            __typename: 'RankingsAnswerNodeEdge';
            node: {
              __typename: 'RankingsAnswerNode';
              id: string;
              votee1UserGroup: {
                __typename: 'UserGroupNode';
                id: string;
                role: UserType | null;
                avatar: string | null;
                status: Status | null;
                created: any;
                defaultAvatar: {
                  __typename: 'DefaultAvatarNode';
                  id: string;
                  avatar: string | null;
                } | null;
                user: {
                  __typename: 'UserNode';
                  id: string;
                  firstName: string;
                  lastName: string;
                  gender: UsersUserGenderChoices | null;
                };
              } | null;
              votee2UserGroup: {
                __typename: 'UserGroupNode';
                id: string;
                role: UserType | null;
                avatar: string | null;
                status: Status | null;
                created: any;
                defaultAvatar: {
                  __typename: 'DefaultAvatarNode';
                  id: string;
                  avatar: string | null;
                } | null;
                user: {
                  __typename: 'UserNode';
                  id: string;
                  firstName: string;
                  lastName: string;
                  gender: UsersUserGenderChoices | null;
                };
              } | null;
              voteeListOption: {
                __typename: 'CustomListOptionNode';
                id: string;
                text: string | null;
              } | null;
            } | null;
          } | null>;
        } | null;
      };
    } | null;
  } | null;
};

export type CreateCustomListMutationVariables = Exact<{
  input: CreateCustomListInput;
}>;

export type CreateCustomListMutation = {
  __typename: 'Mutation';
  createCustomList: {
    __typename: 'CreateCustomListPayload';
    customList: {
      __typename: 'CustomListNode';
      id: string;
      title: string | null;
    } | null;
  } | null;
};

export type UpdateCustomListMutationVariables = Exact<{
  input: UpdateCustomListInput;
}>;

export type UpdateCustomListMutation = {
  __typename: 'Mutation';
  updateCustomList: {
    __typename: 'UpdateCustomListPayload';
    customList: {
      __typename: 'CustomListNode';
      id: string;
      title: string | null;
    } | null;
  } | null;
};

export type DeleteCustomListMutationVariables = Exact<{
  input: DeleteCustomListInput;
}>;

export type DeleteCustomListMutation = {
  __typename: 'Mutation';
  deleteCustomList: {
    __typename: 'DeleteCustomListPayload';
    customList: {
      __typename: 'CustomListNode';
      id: string;
      title: string | null;
    } | null;
  } | null;
};

export type CreateCustomListOptionMutationVariables = Exact<{
  input: CreateCustomListOptionInput;
}>;

export type CreateCustomListOptionMutation = {
  __typename: 'Mutation';
  createCustomListOption: {
    __typename: 'CreateCustomListOptionPayload';
    customListOption: {
      __typename: 'CustomListOptionNode';
      id: string;
      text: string | null;
    } | null;
  } | null;
};

export type UpdateCustomListOptionMutationVariables = Exact<{
  input: UpdateCustomListOptionInput;
}>;

export type UpdateCustomListOptionMutation = {
  __typename: 'Mutation';
  updateCustomListOption: {
    __typename: 'UpdateCustomListOptionPayload';
    customListOption: {
      __typename: 'CustomListOptionNode';
      id: string;
      text: string | null;
    } | null;
  } | null;
};

export type DeleteCustomListOptionMutationVariables = Exact<{
  input: DeleteCustomListOptionInput;
}>;

export type DeleteCustomListOptionMutation = {
  __typename: 'Mutation';
  deleteCustomListOption: {
    __typename: 'DeleteCustomListOptionPayload';
    customListOption: {
      __typename: 'CustomListOptionNode';
      id: string;
      text: string | null;
    } | null;
  } | null;
};

export type CreateDataExportMutationVariables = Exact<{
  input: CreateDataExportInput;
}>;

export type CreateDataExportMutation = {
  __typename: 'Mutation';
  createDataExport: {
    __typename: 'CreateDataExportPayload';
    success: boolean | null;
  } | null;
};

export const AdminSearchInstituteFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminSearchInstitute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchInstituteType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminSearchInstituteFragment, unknown>;
export const AdminSearchGroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminSearchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchGroupType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'graduationYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminSearchInstitute' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminSearchInstitute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchInstituteType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminSearchGroupFragment, unknown>;
export const AdminSearchUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminSearchUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchUserType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminSearchGroup' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminSearchInstitute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchInstituteType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminSearchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchGroupType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'graduationYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminSearchInstitute' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminSearchUserFragment, unknown>;
export const AdminGroupMemberFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminGroupMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupMemberNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'icon' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'color' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminGroupMemberFragment, unknown>;
export const AdminGroupInstituteFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminGroupInstitute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'graduationTypes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminGroupInstituteFragment, unknown>;
export const AdminGroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isImported' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminGroupMember' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminGroupInstitute' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminGroupMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupMemberNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'icon' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'color' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminGroupInstitute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'graduationTypes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminGroupFragment, unknown>;
export const AdminUserGroupsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUserGroups' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminUserGroupsFragment, unknown>;
export const AdminUserTeamsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUserTeams' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminUserTeamsFragment, unknown>;
export const AdminUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optIn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isImported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSuperuser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminUserGroups' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminUserTeams' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUserGroups' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUserTeams' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminUserFragment, unknown>;
export const AdminInstituteGroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminInstituteGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminInstituteGroupFragment, unknown>;
export const InstituteActivityTypeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstituteActivityType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteActivityType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstituteActivityTypeFragment, unknown>;
export const AdminInstituteFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminInstitute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminInstituteGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instituteActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InstituteActivityType' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminInstituteGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstituteActivityType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteActivityType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminInstituteFragment, unknown>;
export const InstituteTargetingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstituteTargeting' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteTargetingNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstituteTargetingFragment, unknown>;
export const DefaultAvatarFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DefaultAvatarFragment, unknown>;
export const UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFragment, unknown>;
export const UserGroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserGroupFragment, unknown>;
export const AuthUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'User' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailValidated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communicationLanguage' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isSuperuser' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryOfResidence' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalTokens' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'intercom' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthUserFragment, unknown>;
export const AuthUserGroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthUserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'UserGroup' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AuthUser' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isYearbookTeamMember' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isCoreTeamMember' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'User' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailValidated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communicationLanguage' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isSuperuser' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryOfResidence' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalTokens' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'intercom' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthUserGroupFragment, unknown>;
export const EventAttendeeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EventAttendee' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EventAttendeeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EventAttendeeFragment, unknown>;
export const EventFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Event' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EventNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyLogo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bannerImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSponsored' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventAuthor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'authorType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAllDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isJoinable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EventAttendee' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EventAttendee' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EventAttendeeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EventFragment, unknown>;
export const ExternalEventAttendeeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExternalEventAttendee' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExternalEventAttendeeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'platform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExternalEventAttendeeFragment, unknown>;
export const CityLocationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CityLocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CityLocationNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'region' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CityLocationFragment, unknown>;
export const CampaignFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'utm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CampaignFragment, unknown>;
export const MatchedAssetFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatchedAsset' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatchedAssetNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'trackingUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageLarge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageSmall' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'targetingConfig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetingFilters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'region' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'radius' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gender' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'date' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'operator' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userIds' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graduationType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instituteType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graduationYear' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'operator' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupIds' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'teams' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'operator' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MatchedAssetFragment, unknown>;
export const AssetFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Asset' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'trackingUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageLarge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageSmall' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'targetingConfig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetingFilters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'region' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'radius' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gender' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'date' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'operator' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userIds' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graduationType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instituteType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graduationYear' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'operator' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupIds' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'teams' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'operator' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalViews' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalClicks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'conversionRate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AssetFragment, unknown>;
export const CampaignDetailsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignDetails' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'utm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalViews' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalClicks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Asset' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'conversionRate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Asset' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'trackingUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageLarge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageSmall' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'targetingConfig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetingFilters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'region' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'radius' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gender' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'date' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'operator' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userIds' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graduationType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instituteType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graduationYear' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'operator' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupIds' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'teams' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'operator' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalViews' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalClicks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'conversionRate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CampaignDetailsFragment, unknown>;
export const CountryDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CountryData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CountryData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'counts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CountryDataFragment, unknown>;
export const StatisticsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Statistics' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StatisticsNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'institutesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activeGroupsCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inactiveGroupsCount' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StatisticsFragment, unknown>;
export const GroupIconFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupIcon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupIconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupIconFragment, unknown>;
export const GraduationTypeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GraduationType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GraduationTypeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GraduationTypeFragment, unknown>;
export const GroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupIcon' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GraduationType' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupIcon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupIconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GraduationType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GraduationTypeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupFragment, unknown>;
export const GroupUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'UserGroup' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Group' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupIcon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupIconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GraduationType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GraduationTypeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupIcon' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GraduationType' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupUserFragment, unknown>;
export const GroupSampleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupSample' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Group' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sampleMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupIcon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupIconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GraduationType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GraduationTypeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupIcon' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GraduationType' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupSampleFragment, unknown>;
export const InstituteTypeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstituteType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteTypeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'graduationTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstituteTypeFragment, unknown>;
export const InstituteFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Institute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InstituteType' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstituteType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteTypeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'graduationTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstituteFragment, unknown>;
export const GroupDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupDetail' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Group' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Institute' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupIcon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupIconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GraduationType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GraduationTypeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstituteType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteTypeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'graduationTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupIcon' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GraduationType' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Institute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InstituteType' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupDetailFragment, unknown>;
export const YearbookStatisticsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'YearbookStatistics' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YearbookStatisticsNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePagesFilledIn' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'usersWithQuote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastDeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentModules' } },
          { kind: 'Field', name: { kind: 'Name', value: 'estimatedPages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imagesUploaded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wordCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<YearbookStatisticsFragment, unknown>;
export const GroupStatisticsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupStatistics' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'yearbookStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'YearbookStatistics' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'YearbookStatistics' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YearbookStatisticsNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePagesFilledIn' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'usersWithQuote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastDeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentModules' } },
          { kind: 'Field', name: { kind: 'Name', value: 'estimatedPages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imagesUploaded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wordCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupStatisticsFragment, unknown>;
export const LeaderboardUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeaderboardUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LeaderboardUserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'points' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeaderboardUserFragment, unknown>;
export const InstituteRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstituteRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteRequestNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstituteRequestFragment, unknown>;
export const MarketProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MarketProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketProfileNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarketProfileFragment, unknown>;
export const ColorFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ColorFragment, unknown>;
export const IconFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IconFragment, unknown>;
export const TeamFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TeamFragment, unknown>;
export const CommentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Comment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommentNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CommentFragment, unknown>;
export const BasePostFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Comment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Comment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommentNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BasePostFragment, unknown>;
export const PollVoteFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollVote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollVoteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PollVoteFragment, unknown>;
export const PollOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollVote' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'votesNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollVote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollVoteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PollOptionFragment, unknown>;
export const BasePollFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePoll' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answersAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'randomizeOptions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowRevote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousVotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privateResults' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollVote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollVoteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollVote' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'votesNumber' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BasePollFragment, unknown>;
export const PostFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Post' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasePost' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poll' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BasePoll' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Comment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommentNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollVote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollVoteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollVote' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'votesNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Comment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePoll' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answersAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'randomizeOptions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowRevote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousVotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privateResults' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PostFragment, unknown>;
export const PollFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Poll' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasePoll' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'post' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BasePost' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'likes' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'userGroup' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'authUserGroupId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userGroup' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollVote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollVoteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollVote' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'votesNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Comment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommentNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePoll' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answersAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'randomizeOptions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowRevote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousVotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privateResults' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Comment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PollFragment, unknown>;
export const TeamUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserTeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdmin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultAvatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TeamUserFragment, unknown>;
export const TeamSampleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamSample' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sampleMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TeamUser' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserTeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdmin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultAvatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TeamSampleFragment, unknown>;
export const TeamDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamDetail' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TeamUser' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserTeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdmin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultAvatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TeamDetailFragment, unknown>;
export const UserWithTeamsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserWithTeams' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'User' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'color' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'icon' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserWithTeamsFragment, unknown>;
export const PrewordInstanceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrewordInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrewordInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PrewordInstanceFragment, unknown>;
export const ModuleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'features' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModuleFragment, unknown>;
export const ModuleInstanceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'module' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Module' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'features' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModuleInstanceFragment, unknown>;
export const PrewordResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrewordResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrewordInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'wordCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PrewordResultFragment, unknown>;
export const ProfilePageUserSummaryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageUserSummary' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageUserSummaryNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'questionsAnswered' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imagesUploaded' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfilePageUserSummaryFragment, unknown>;
export const ProfilePageResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageUserSummary' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'usersDelivered' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageUserSummary' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageUserSummaryNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'questionsAnswered' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imagesUploaded' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfilePageResultFragment, unknown>;
export const RankingsResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'rankingsCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RankingsResultFragment, unknown>;
export const CollagesResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'photosCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollagesResultFragment, unknown>;
export const ReportsResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reportsCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportsResultFragment, unknown>;
export const QuotesResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuotesResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'quotesCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuotesResultFragment, unknown>;
export const ProfilePageSetupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photoFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibleComments' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfilePageSetupFragment, unknown>;
export const RankingsSetupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resultsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RankingsSetupFragment, unknown>;
export const CollagesSetupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAlbumsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'photosCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollagesSetupFragment, unknown>;
export const ReportsSetupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowImages' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportsSetupFragment, unknown>;
export const QuotesSetupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuotesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymous' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuotesSetupFragment, unknown>;
export const CustomPageSetupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomPageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomPagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'guide' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomPageSetupFragment, unknown>;
export const ModuleInstanceDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstanceDetail' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ModuleInstance' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CollagesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuotesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomPageSetup' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'features' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'module' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Module' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photoFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibleComments' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resultsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAlbumsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'photosCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowImages' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuotesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymous' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomPageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomPagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'guide' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModuleInstanceDetailFragment, unknown>;
export const CustomPageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomPage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomPagesInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'page' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moduleInstance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ModuleInstanceDetail' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pageFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageQuality' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'features' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'module' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Module' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photoFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibleComments' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resultsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAlbumsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'photosCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowImages' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuotesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymous' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomPageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomPagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'guide' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstanceDetail' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ModuleInstance' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CollagesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuotesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomPageSetup' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomPageFragment, unknown>;
export const ModuleInstanceResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstanceResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ModuleInstance' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prewordInstance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrewordResult' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageResult' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingsResult' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CollagesResult' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportsResult' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuotesResult' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesInstances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CustomPage' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'features' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageUserSummary' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageUserSummaryNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'questionsAnswered' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imagesUploaded' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'module' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Module' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photoFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibleComments' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resultsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAlbumsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'photosCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowImages' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuotesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymous' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomPageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomPagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'guide' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstanceDetail' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ModuleInstance' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CollagesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuotesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomPageSetup' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrewordResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrewordInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'wordCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageUserSummary' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'usersDelivered' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'rankingsCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'photosCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reportsCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuotesResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'quotesCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomPage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomPagesInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'page' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moduleInstance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ModuleInstanceDetail' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pageFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageQuality' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModuleInstanceResultFragment, unknown>;
export const BaseProfilePagePhotoCategoryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseProfilePagePhotoCategory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePagePhotoCategoryNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseProfilePagePhotoCategoryFragment, unknown>;
export const ProfilePagePhotoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePagePhoto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePagePhotoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfilePagePhotoFragment, unknown>;
export const ProfilePagePhotoCategoryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePagePhotoCategory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePagePhotoCategoryNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BaseProfilePagePhotoCategory' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePagePhotos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ppqUserGroup' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ProfilePagePhoto' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseProfilePagePhotoCategory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePagePhotoCategoryNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePagePhoto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePagePhotoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfilePagePhotoCategoryFragment, unknown>;
export const ProfilePageQuestionOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageQuestionOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageQuestionOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfilePageQuestionOptionFragment, unknown>;
export const BaseProfilePageQuestionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseProfilePageQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageQuestionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageQuestionOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageQuestionOption' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageQuestionOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageQuestionOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseProfilePageQuestionFragment, unknown>;
export const ProfilePageAnswerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageAnswer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageAnswerNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageQuestionOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageQuestionOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageQuestionOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageQuestionOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfilePageAnswerFragment, unknown>;
export const ProfilePageQuestionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageQuestionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BaseProfilePageQuestion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageAnswers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ppqUserGroup' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ProfilePageAnswer',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageQuestionOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageQuestionOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseProfilePageQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageQuestionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageQuestionOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageQuestionOption' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageAnswer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageAnswerNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageQuestionOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageQuestionOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfilePageQuestionFragment, unknown>;
export const ProfilePageSuggestedQuestionOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageSuggestedQuestionOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageSuggestedQuestionOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfilePageSuggestedQuestionOptionFragment,
  unknown
>;
export const ProfilePageSuggestedQuestionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageSuggestedQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageSuggestedQuestionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfilePageSuggestedQuestionOption',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageSuggestedQuestionOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageSuggestedQuestionOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfilePageSuggestedQuestionFragment, unknown>;
export const ProfilePageCommentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageComment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageCommentNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comentatorUserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileUserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfilePageCommentFragment, unknown>;
export const CollagesPhotoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesPhoto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesPhotoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotoLikesCount' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotoLikes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollagesPhotoFragment, unknown>;
export const BaseCollagesAlbumFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseCollagesAlbum' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesAlbumNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseCollagesAlbumFragment, unknown>;
export const CollagesBasePhotoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesBasePhoto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesPhotoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotoLikesCount' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollagesBasePhotoFragment, unknown>;
export const CollagesAlbumFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesAlbum' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesAlbumNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BaseCollagesAlbum' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'photosCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cpAfter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cpFirst' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'CollagesBasePhoto',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseCollagesAlbum' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesAlbumNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesBasePhoto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesPhotoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotoLikesCount' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollagesAlbumFragment, unknown>;
export const CollagesBaseAlbumFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesBaseAlbum' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesAlbumNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BaseCollagesAlbum' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'photosCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseCollagesAlbum' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesAlbumNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollagesBaseAlbumFragment, unknown>;
export const ReportsInstanceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportsInstanceFragment, unknown>;
export const BaseQuoteFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseQuote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesInstanceLikesCount' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseQuoteFragment, unknown>;
export const QuoteFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Quote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BaseQuote' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesInstanceLikes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseQuote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesInstanceLikesCount' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuoteFragment, unknown>;
export const CustomListFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomList' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomListNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomListFragment, unknown>;
export const CustomListOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomListOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomListOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomListOptionFragment, unknown>;
export const RankingQuestionResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestionResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionResultNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee1UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee2UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voteeListOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomListOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomListOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomListOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RankingQuestionResultFragment, unknown>;
export const RankingQuestionResultsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestionResults' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionResultsNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'alreadyVoted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'possibleVoters' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingQuestionResult' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomListOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomListOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestionResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionResultNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee1UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee2UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voteeListOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomListOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RankingQuestionResultsFragment, unknown>;
export const RankingsAnswerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsAnswer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsAnswerNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee1UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee2UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voteeListOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomListOption' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomListOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomListOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RankingsAnswerFragment, unknown>;
export const RankingQuestionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answerType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedVotes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onlyOppositeGender' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'results' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingQuestionResults' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsAnswers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'voterUserGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'RankingsAnswer' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomListOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomListOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestionResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionResultNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee1UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee2UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voteeListOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomListOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestionResults' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionResultsNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'alreadyVoted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'possibleVoters' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingQuestionResult' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsAnswer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsAnswerNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee1UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee2UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voteeListOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomListOption' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RankingQuestionFragment, unknown>;
export const RankingBaseQuestionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingBaseQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answerType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedVotes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onlyOppositeGender' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsAnswers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'voterUserGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RankingBaseQuestionFragment, unknown>;
export const DataExportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataExport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DataExportNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isodatetime' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'exportFile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exportCollages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exportRankings' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportProfilePages' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'exportQuotes' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataExportFragment, unknown>;
export const AdminSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchString' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminSearchResults' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'custom' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchString' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminSearchUser' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminSearchGroup' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institutes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminSearchInstitute' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminSearchInstitute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchInstituteType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminSearchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchGroupType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'graduationYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminSearchInstitute' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminSearchUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchUserType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminSearchGroup' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminSearchQuery, AdminSearchQueryVariables>;
export const AdminGetGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminGetGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminGroup' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminGroupMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupMemberNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'icon' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'color' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminGroupInstitute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'graduationTypes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isImported' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminGroupMember' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminGroupInstitute' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminGetGroupQuery, AdminGetGroupQueryVariables>;
export const AdminToggleGroupActiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminToggleGroupActive' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ToggleIsActiveGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'toggleIsActiveGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminGroup' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminGroupMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupMemberNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'icon' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'color' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminGroupInstitute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'graduationTypes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isImported' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminGroupMember' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminGroupInstitute' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminToggleGroupActiveMutation,
  AdminToggleGroupActiveMutationVariables
>;
export const AdminMoveGroupToInstituteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminMoveGroupToInstitute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MoveGroupToInstituteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moveGroupToInstitute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminGroup' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminGroupMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupMemberNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'icon' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'color' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminGroupInstitute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'graduationTypes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isImported' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminGroupMember' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminGroupInstitute' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminMoveGroupToInstituteMutation,
  AdminMoveGroupToInstituteMutationVariables
>;
export const AdminGetUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminGetUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminUser' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUserGroups' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUserTeams' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optIn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isImported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSuperuser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminUserGroups' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminUserTeams' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminGetUserQuery, AdminGetUserQueryVariables>;
export const AdminGetGradooStaffDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminGetGradooStaff' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchNameEmail' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isStaff' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchNameEmail' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchNameEmail' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'AdminUser' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUserGroups' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUserTeams' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optIn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isImported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSuperuser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminUserGroups' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminUserTeams' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminGetGradooStaffQuery,
  AdminGetGradooStaffQueryVariables
>;
export const AdminGetUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminGetUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isAgent' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isSuperuser' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isStaff' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchNameEmail' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'firstName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lastName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isAgent' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'isAgent' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isSuperuser' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'isSuperuser' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isStaff' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'isStaff' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchNameEmail' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchNameEmail' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'AdminUser' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUserGroups' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUserTeams' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optIn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isImported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSuperuser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminUserGroups' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminUserTeams' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminGetUsersQuery, AdminGetUsersQueryVariables>;
export const AdminUpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminUpdateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminUpdateUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminUpdateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminUser' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUserGroups' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUserTeams' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optIn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isImported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSuperuser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminUserGroups' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminUserTeams' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminUpdateUserMutation,
  AdminUpdateUserMutationVariables
>;
export const AdminUpgradeUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminUpgradeUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminUpgradeUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminUpgradeUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminUser' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUserGroups' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUserTeams' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optIn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isImported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSuperuser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminUserGroups' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminUserTeams' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminUpgradeUserMutation,
  AdminUpgradeUserMutationVariables
>;
export const AdminDowngradeUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminDowngradeUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminDowngradeUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminDowngradeUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminUser' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUserGroups' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUserTeams' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optIn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isImported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSuperuser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminUserGroups' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminUserTeams' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminDowngradeUserMutation,
  AdminDowngradeUserMutationVariables
>;
export const AdminClearUserFlagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminClearUserFlags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminClearUserFlagsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminClearUserFlags' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminUser' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUserGroups' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUserTeams' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optIn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isImported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSuperuser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminUserGroups' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminUserTeams' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminClearUserFlagsMutation,
  AdminClearUserFlagsMutationVariables
>;
export const AdminForgetPasswordLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminForgetPasswordLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminForgetPasswordLinkInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminForgetPasswordLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'forgetPasswordLink' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminForgetPasswordLinkMutation,
  AdminForgetPasswordLinkMutationVariables
>;
export const AdminAddUserToTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminAddUserToTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminAddUserToTeamInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminAddUserToTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminUser' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUserGroups' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUserTeams' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optIn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isImported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSuperuser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminUserGroups' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminUserTeams' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminAddUserToTeamMutation,
  AdminAddUserToTeamMutationVariables
>;
export const AdminDeleteUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminDeleteUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminDeleteUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminDeleteUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminUser' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUserGroups' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUserTeams' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optIn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isImported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSuperuser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminUserGroups' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminUserTeams' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminDeleteUserMutation,
  AdminDeleteUserMutationVariables
>;
export const AdminGetInstituteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminGetInstitute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminInstitute' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminInstituteGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstituteActivityType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteActivityType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminInstitute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminInstituteGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instituteActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InstituteActivityType' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminGetInstituteQuery,
  AdminGetInstituteQueryVariables
>;
export const AdminUpdateInstituteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminUpdateInstitute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminUpdateInstituteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminUpdateInstitute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institute' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminInstitute' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminInstituteGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstituteActivityType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteActivityType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminInstitute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminInstituteGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instituteActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InstituteActivityType' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminUpdateInstituteMutation,
  AdminUpdateInstituteMutationVariables
>;
export const InstitutesTargetingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InstitutesTargeting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'targetingFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TargetingFilterInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institutesTargeting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'targetingFilters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'targetingFilters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeGroupsCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inactiveGroupsCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institutes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'InstituteTargeting' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstituteTargeting' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteTargetingNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InstitutesTargetingQuery,
  InstitutesTargetingQueryVariables
>;
export const MeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Me' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AuthUser' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'User' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailValidated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communicationLanguage' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isSuperuser' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryOfResidence' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalTokens' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'intercom' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeQuery, MeQueryVariables>;
export const AuthUserGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AuthUserGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AuthUserGroup' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'User' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailValidated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communicationLanguage' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isSuperuser' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryOfResidence' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalTokens' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'intercom' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthUserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'UserGroup' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AuthUser' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isYearbookTeamMember' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isCoreTeamMember' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthUserGroupQuery, AuthUserGroupQueryVariables>;
export const CreateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AuthUser' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'User' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailValidated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communicationLanguage' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isSuperuser' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryOfResidence' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalTokens' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'intercom' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>;
export const CreateTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ObtainJSONWebTokenInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'refreshToken' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateTokenMutation, CreateTokenMutationVariables>;
export const UpdateMeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateMeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMe' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AuthUser' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'refreshToken' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'User' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailValidated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communicationLanguage' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isSuperuser' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryOfResidence' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalTokens' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'intercom' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateMeMutation, UpdateMeMutationVariables>;
export const ForgetPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ForgetPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ForgetPasswordInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forgetPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ForgetPasswordMutation,
  ForgetPasswordMutationVariables
>;
export const ResetPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResetPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResetPasswordInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const DeleteUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AuthUser' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'User' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailValidated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communicationLanguage' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isSuperuser' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryOfResidence' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalTokens' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'intercom' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUserMutation, DeleteUserMutationVariables>;
export const UserExistsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserExists' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneCountryCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phone' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userExists' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phoneCountryCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'phoneCountryCode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phone' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'phone' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'emailExists' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneExists' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserExistsQuery, UserExistsQueryVariables>;
export const EventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Events' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isPublic' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'events' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fromDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isPublic' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'isPublic' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Event' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EventAttendee' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EventAttendeeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Event' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EventNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyLogo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bannerImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSponsored' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventAuthor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'authorType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAllDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isJoinable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EventAttendee' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EventsQuery, EventsQueryVariables>;
export const EventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Event' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'event' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Event' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EventAttendee' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EventAttendeeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Event' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EventNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyLogo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bannerImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSponsored' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventAuthor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'authorType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAllDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isJoinable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EventAttendee' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EventQuery, EventQueryVariables>;
export const CreateEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateEventInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'event' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Event' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EventAttendee' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EventAttendeeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Event' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EventNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyLogo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bannerImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSponsored' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventAuthor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'authorType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAllDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isJoinable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EventAttendee' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateEventMutation, CreateEventMutationVariables>;
export const JoinEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'JoinEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'JoinEventInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'joinEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'event' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Event' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EventAttendee' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EventAttendeeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Event' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EventNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyLogo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bannerImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSponsored' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventAuthor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'authorType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAllDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isJoinable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EventAttendee' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JoinEventMutation, JoinEventMutationVariables>;
export const LeaveEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LeaveEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LeaveEventInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leaveEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeaveEventMutation, LeaveEventMutationVariables>;
export const EditEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditEventInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'event' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Event' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EventAttendee' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EventAttendeeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Event' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EventNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyLogo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bannerImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSponsored' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventAuthor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'authorType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAllDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isJoinable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EventAttendee' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditEventMutation, EditEventMutationVariables>;
export const DeleteEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteEventInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteEventMutation, DeleteEventMutationVariables>;
export const SetEventBannerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetEventBanner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SetEventBannerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setEventBanner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'event' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Event' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EventAttendee' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EventAttendeeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Event' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EventNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyLogo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bannerImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSponsored' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventAuthor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'authorType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAllDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isJoinable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EventAttendee' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetEventBannerMutation,
  SetEventBannerMutationVariables
>;
export const SaveExternalEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveExternalEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SaveExternalEventInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveExternalEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'externalEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ExternalEventAttendee' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExternalEventAttendee' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExternalEventAttendeeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'platform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveExternalEventMutation,
  SaveExternalEventMutationVariables
>;
export const RemoveExternalEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveExternalEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RemoveExternalEventInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeExternalEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'externalEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ExternalEventAttendee' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExternalEventAttendee' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExternalEventAttendeeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'platform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveExternalEventMutation,
  RemoveExternalEventMutationVariables
>;
export const ToggleExternalEventSaveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ToggleExternalEventSave' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ToggleExternalEventSaveInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'toggleExternalEventSave' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'externalEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ExternalEventAttendee' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExternalEventAttendee' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExternalEventAttendeeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'platform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ToggleExternalEventSaveMutation,
  ToggleExternalEventSaveMutationVariables
>;
export const CampaignsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Campaigns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'before' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchFilter' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'before' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'last' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchFilter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchFilter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasPreviousPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Campaign' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'utm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CampaignsQuery, CampaignsQueryVariables>;
export const CampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Campaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CampaignDetails' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Asset' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'trackingUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageLarge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageSmall' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'targetingConfig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetingFilters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'region' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'radius' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gender' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'date' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'operator' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userIds' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graduationType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instituteType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graduationYear' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'operator' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupIds' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'teams' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'operator' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalViews' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalClicks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'conversionRate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignDetails' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'utm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalViews' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalClicks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Asset' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'conversionRate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CampaignQuery, CampaignQueryVariables>;
export const CreateBannerAssetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateBannerAsset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateBannerAssetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBannerAsset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'asset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Asset' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Asset' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'trackingUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageLarge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageSmall' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'targetingConfig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetingFilters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'region' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'radius' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gender' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'date' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'operator' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userIds' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graduationType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instituteType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graduationYear' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'operator' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupIds' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'teams' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'operator' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalViews' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalClicks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'conversionRate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateBannerAssetMutation,
  CreateBannerAssetMutationVariables
>;
export const CreateCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCampaignInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaign' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Campaign' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'utm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCampaignMutation,
  CreateCampaignMutationVariables
>;
export const AssetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Asset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Asset' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Asset' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'trackingUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageLarge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageSmall' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'targetingConfig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetingFilters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'region' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'radius' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gender' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'date' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'operator' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userIds' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graduationType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instituteType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graduationYear' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'operator' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupIds' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'teams' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'operator' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalViews' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalClicks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'conversionRate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AssetQuery, AssetQueryVariables>;
export const EditBannerAssetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditBannerAsset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditBannerAdAssetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editBannerAdAsset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'asset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Asset' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Asset' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'trackingUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageLarge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageSmall' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'targetingConfig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetingFilters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'region' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'radius' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gender' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'date' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'operator' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userIds' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graduationType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instituteType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graduationYear' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'operator' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupIds' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'teams' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'operator' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalViews' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalClicks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'conversionRate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditBannerAssetMutation,
  EditBannerAssetMutationVariables
>;
export const DuplicateBannerAssetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DuplicateBannerAsset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DuplicateAssetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duplicateAsset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'newAsset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Asset' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Asset' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'trackingUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageLarge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageSmall' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'targetingConfig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetingFilters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'region' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'radius' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gender' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'date' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'operator' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userIds' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graduationType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instituteType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graduationYear' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'operator' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupIds' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'teams' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'operator' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalViews' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalClicks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'conversionRate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DuplicateBannerAssetMutation,
  DuplicateBannerAssetMutationVariables
>;
export const DeleteBannerAssetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteBannerAsset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteAssetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAsset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'assetId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteBannerAssetMutation,
  DeleteBannerAssetMutationVariables
>;
export const BestMatchAssetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BestMatchAsset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'channelType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ChannelType' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bestMatchAssets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'channelType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'channelType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatchedAsset' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatchedAsset' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatchedAssetNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'trackingUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageLarge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageSmall' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'targetingConfig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetingFilters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'region' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'radius' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gender' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'date' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'operator' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userIds' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graduationType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instituteType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graduationYear' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'operator' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupIds' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'teams' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'operator' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BestMatchAssetQuery, BestMatchAssetQueryVariables>;
export const CreateAssetEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAssetEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateAssetEventInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAssetEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAssetEventMutation,
  CreateAssetEventMutationVariables
>;
export const RegionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Regions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'country' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'regions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'country' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CityLocation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CityLocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CityLocationNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'region' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RegionsQuery, RegionsQueryVariables>;
export const EditCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditCampaignInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaign' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Campaign' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'utm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditCampaignMutation,
  EditCampaignMutationVariables
>;
export const DeleteCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteCampaignInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCampaignMutation,
  DeleteCampaignMutationVariables
>;
export const StatisticsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Statistics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DashboardFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statistics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Statistics' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Statistics' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StatisticsNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'institutesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activeGroupsCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inactiveGroupsCount' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StatisticsQuery, StatisticsQueryVariables>;
export const SignupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Signups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DashboardFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CountryData' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CountryData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CountryData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'counts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignupsQuery, SignupsQueryVariables>;
export const KPIsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'KPIs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DashboardFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpis' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageStudentsPerGroup' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupTimeToActive' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileQuestionsPerYear' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'average' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instances' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groups' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quotesPerGroupPerYear' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'average' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instances' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groups' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'rankingQuestionsPerGroupPerYear',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'average' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instances' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groups' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'albumsPerGroupPerYear' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'average' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instances' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groups' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'photosPerGroupPerYear' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'average' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instances' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groups' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<KPIsQuery, KPIsQueryVariables>;
export const ColorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Colors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Color' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ColorsQuery, ColorsQueryVariables>;
export const IconsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Icons' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icons' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Icon' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IconsQuery, IconsQueryVariables>;
export const DefaultAvatarsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DefaultAvatars' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatars' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'DefaultAvatar' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DefaultAvatarsQuery, DefaultAvatarsQueryVariables>;
export const GroupIconsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GroupIcons' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groupIcons' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'GroupIcon' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupIcon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupIconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupIconsQuery, GroupIconsQueryVariables>;
export const GraduationTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GraduationTypes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'graduationTypes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'GraduationType' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GraduationType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GraduationTypeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GraduationTypesQuery,
  GraduationTypesQueryVariables
>;
export const GraduationTypesCountryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GraduationTypesCountry' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'country' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'graduationTypesCountry' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'country' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GraduationTypesCountryQuery,
  GraduationTypesCountryQueryVariables
>;
export const GroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Groups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'institute' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'marketProfile' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'year' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'year' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'institute' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'institute' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'marketProfile' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'marketProfile' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Group' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupIcon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupIconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GraduationType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GraduationTypeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupIcon' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GraduationType' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupsQuery, GroupsQueryVariables>;
export const GroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Group' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupDetail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'teams' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupIcon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupIconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GraduationType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GraduationTypeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupIcon' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GraduationType' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstituteType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteTypeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'graduationTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Institute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InstituteType' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupDetail' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Group' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Institute' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupQuery, GroupQueryVariables>;
export const GroupSampleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GroupSample' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupSample' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupIcon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupIconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GraduationType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GraduationTypeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupIcon' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GraduationType' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupSample' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Group' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sampleMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupSampleQuery, GroupSampleQueryVariables>;
export const GroupStatisticsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GroupStatistics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupStatistics' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'YearbookStatistics' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YearbookStatisticsNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePagesFilledIn' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'usersWithQuote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastDeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentModules' } },
          { kind: 'Field', name: { kind: 'Name', value: 'estimatedPages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imagesUploaded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wordCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupStatistics' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'yearbookStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'YearbookStatistics' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GroupStatisticsQuery,
  GroupStatisticsQueryVariables
>;
export const AddUserToGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddUserToGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddUserToGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addUserToGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userGroup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroup' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddUserToGroupMutation,
  AddUserToGroupMutationVariables
>;
export const AcceptUserInGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AcceptUserInGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AcceptUserInGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptUserInGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userGroup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroup' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptUserInGroupMutation,
  AcceptUserInGroupMutationVariables
>;
export const RemoveUserFromGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveUserFromGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RemoveUserFromGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeUserFromGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userGroup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroup' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveUserFromGroupMutation,
  RemoveUserFromGroupMutationVariables
>;
export const UserGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'group' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GroupsUserGroupStatusChoices' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'group' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'group' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'UserGroup' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserGroupsQuery, UserGroupsQueryVariables>;
export const GroupUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GroupUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'user' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GroupsUserGroupStatusChoices' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'user' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'GroupUser' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupIcon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupIconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GraduationType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GraduationTypeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupIcon' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GraduationType' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'UserGroup' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Group' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupUsersQuery, GroupUsersQueryVariables>;
export const UserGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserGroupQuery, UserGroupQueryVariables>;
export const CreateGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Group' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupIcon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupIconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GraduationType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GraduationTypeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupIcon' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GraduationType' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateGroupMutation, CreateGroupMutationVariables>;
export const UpdateGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Group' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupIcon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupIconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GraduationType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GraduationTypeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupIcon' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GraduationType' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateGroupMutation, UpdateGroupMutationVariables>;
export const SetGroupAvatarDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetGroupAvatar' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SetGroupAvatarInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setGroupAvatar' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Group' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupIcon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupIconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GraduationType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GraduationTypeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupIcon' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GraduationType' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetGroupAvatarMutation,
  SetGroupAvatarMutationVariables
>;
export const SetUserGroupAvatarDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetUserGroupAvatar' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SetUserGroupAvatarInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setUserGroupAvatar' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userGroup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroup' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetUserGroupAvatarMutation,
  SetUserGroupAvatarMutationVariables
>;
export const UpdateUserGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateUserGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userGroup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroup' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserGroupMutation,
  UpdateUserGroupMutationVariables
>;
export const ImportToErpDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ImportToErp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ImportToErpInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'importToErp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImportToErpMutation, ImportToErpMutationVariables>;
export const LeaderboardUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LeaderboardUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'before' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'user' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leaderboardUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'before' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'last' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'user' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'LeaderboardUser' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeaderboardUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LeaderboardUserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'points' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LeaderboardUsersQuery,
  LeaderboardUsersQueryVariables
>;
export const ImportFromAbihomeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ImportFromAbihome' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'graduationType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'graduationYear' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'approved' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'iconId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'avatar' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'importAbihome' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'graduationType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'graduationType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'graduationYear' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'graduationYear' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'approved' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'approved' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'iconId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'iconId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'avatar' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'avatar' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ImportFromAbihomeMutation,
  ImportFromAbihomeMutationVariables
>;
export const InstituteTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InstituteTypes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'country' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'InstitutesInstituteCountryChoices' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instituteTypes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'country' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'InstituteType' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstituteType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteTypeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'graduationTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstituteTypesQuery, InstituteTypesQueryVariables>;
export const InstitutesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Institutes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'country' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'InstitutesInstituteCountryChoices' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'internalId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institutes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'city' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'city' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'country' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'internalId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'internalId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Institute' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstituteType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteTypeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'graduationTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Institute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InstituteType' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstitutesQuery, InstitutesQueryVariables>;
export const InstituteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Institute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Institute' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstituteType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteTypeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'graduationTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Institute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InstituteType' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstituteQuery, InstituteQueryVariables>;
export const RequestMissingInstituteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestMissingInstitute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestMissingInstituteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestMissingInstitute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responseCode' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestMissingInstituteMutation,
  RequestMissingInstituteMutationVariables
>;
export const CitiesZipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CitiesZip' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'country' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'citiesZip' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'country' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CitiesZipQuery, CitiesZipQueryVariables>;
export const CreateInstituteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateInstitute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateInstituteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createInstitute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responseCode' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institute' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Institute' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstituteType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteTypeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'graduationTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Institute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InstituteType' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateInstituteMutation,
  CreateInstituteMutationVariables
>;
export const InstituteRequestsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InstituteRequests' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'before' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Status' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'street' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'country' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'InstitutesInstituteRequestCountryChoices',
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'website' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instituteRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'before' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'last' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'street' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'street' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'country' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zip' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zip' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'website' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'website' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'InstituteRequest' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstituteRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteRequestNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InstituteRequestsQuery,
  InstituteRequestsQueryVariables
>;
export const InstituteRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InstituteRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instituteRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InstituteRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstituteRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteRequestNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InstituteRequestQuery,
  InstituteRequestQueryVariables
>;
export const FindDuplicateInstitutesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindDuplicateInstitutes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'street' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'website' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customSearch' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findDuplicateInstitutes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'street' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'street' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'website' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'website' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customSearch' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customSearch' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Institute' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstituteType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteTypeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'graduationTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Institute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InstituteType' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindDuplicateInstitutesQuery,
  FindDuplicateInstitutesQueryVariables
>;
export const AcceptInstituteRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AcceptInstituteRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminAcceptInstituteRequestInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminAcceptInstituteRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instituteRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'InstituteRequest' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstituteRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteRequestNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptInstituteRequestMutation,
  AcceptInstituteRequestMutationVariables
>;
export const RejectInstituteRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RejectInstituteRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminRejectInstituteRequestInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminRejectInstituteRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instituteRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'InstituteRequest' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstituteRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstituteRequestNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RejectInstituteRequestMutation,
  RejectInstituteRequestMutationVariables
>;
export const DeleteInstituteRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteInstituteRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminDeleteInstituteRequestInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminDeleteInstituteRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteInstituteRequestMutation,
  DeleteInstituteRequestMutationVariables
>;
export const MarketProfilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MarketProfiles' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketProfiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'MarketProfile' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MarketProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketProfileNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarketProfilesQuery, MarketProfilesQueryVariables>;
export const PostsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Posts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'owner' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Owner' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'team' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdBy_Group' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'owner' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'owner' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createdBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createdBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'team' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'team' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'text' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'text' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createdBy_Group' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createdBy_Group' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Post' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Comment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommentNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Comment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollVote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollVoteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollVote' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'votesNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePoll' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answersAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'randomizeOptions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowRevote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousVotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privateResults' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Post' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasePost' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poll' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BasePoll' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PostsQuery, PostsQueryVariables>;
export const PostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Post' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'post' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Post' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Comment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommentNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Comment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollVote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollVoteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollVote' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'votesNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePoll' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answersAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'randomizeOptions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowRevote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousVotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privateResults' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Post' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasePost' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poll' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BasePoll' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PostQuery, PostQueryVariables>;
export const CreatePostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePostInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'post' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Post' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Comment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommentNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Comment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollVote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollVoteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollVote' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'votesNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePoll' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answersAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'randomizeOptions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowRevote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousVotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privateResults' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Post' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasePost' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poll' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BasePoll' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePostMutation, CreatePostMutationVariables>;
export const DeletePostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeletePostInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'post' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Post' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Comment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommentNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Comment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollVote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollVoteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollVote' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'votesNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePoll' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answersAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'randomizeOptions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowRevote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousVotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privateResults' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Post' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasePost' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poll' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BasePoll' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeletePostMutation, DeletePostMutationVariables>;
export const ReportPostContentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReportPostContent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReportPostContentInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportPostContent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReportPostContentMutation,
  ReportPostContentMutationVariables
>;
export const BlockUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BlockUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BlockUserMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'blockUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BlockUserMutation, BlockUserMutationVariables>;
export const ReportMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReportMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReportMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportMutation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReportMutationMutation,
  ReportMutationMutationVariables
>;
export const AddImagesToPostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddImagesToPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddImagesToPostInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addImagesToPost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'post' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Post' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Comment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommentNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Comment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollVote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollVoteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollVote' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'votesNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePoll' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answersAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'randomizeOptions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowRevote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousVotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privateResults' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Post' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasePost' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poll' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BasePoll' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddImagesToPostMutation,
  AddImagesToPostMutationVariables
>;
export const PollsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Polls' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'post_Team' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'post_CreatedBy_Group' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'polls' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'post_Team' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'post_Team' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'post_CreatedBy_Group' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'post_CreatedBy_Group' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Poll' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollVote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollVoteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollVote' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'votesNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePoll' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answersAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'randomizeOptions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowRevote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousVotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privateResults' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Comment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommentNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Comment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Poll' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasePoll' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'post' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BasePost' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'likes' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'userGroup' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'authUserGroupId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userGroup' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PollsQuery, PollsQueryVariables>;
export const CreatePollDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePoll' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePollInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPoll' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'poll' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Poll' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollVote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollVoteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollVote' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'votesNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePoll' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answersAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'randomizeOptions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowRevote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousVotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privateResults' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Comment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommentNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Comment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Poll' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasePoll' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'post' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BasePost' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'likes' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'userGroup' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'authUserGroupId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userGroup' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePollMutation, CreatePollMutationVariables>;
export const DeletePollDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePoll' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeletePollInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePoll' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'poll' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Poll' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollVote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollVoteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollVote' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'votesNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePoll' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answersAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'randomizeOptions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowRevote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousVotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privateResults' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Comment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommentNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Comment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Poll' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasePoll' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'post' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BasePost' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'likes' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'userGroup' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'authUserGroupId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userGroup' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeletePollMutation, DeletePollMutationVariables>;
export const CreatePollOptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePollOption' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePollOptionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPollOption' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pollOption' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PollOption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollVote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollVoteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollVote' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'votesNumber' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePollOptionMutation,
  CreatePollOptionMutationVariables
>;
export const CreatePollVoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePollVote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePollVoteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPollVote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pollVote' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PollVote' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollVote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollVoteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePollVoteMutation,
  CreatePollVoteMutationVariables
>;
export const UpdatePollDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePoll' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePollInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePoll' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'poll' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasePoll' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollVote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollVoteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollVote' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'votesNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePoll' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answersAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'randomizeOptions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowRevote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousVotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privateResults' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePollMutation, UpdatePollMutationVariables>;
export const DeletePollVoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePollVote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeletePollVoteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePollVote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pollVote' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PollVote' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollVote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollVoteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePollVoteMutation,
  DeletePollVoteMutationVariables
>;
export const LikePostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LikePost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LikePostInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likePost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'post' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Post' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Comment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommentNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Comment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollVote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollVoteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollVote' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'votesNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePoll' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answersAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'randomizeOptions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowRevote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousVotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privateResults' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Post' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasePost' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poll' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BasePoll' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LikePostMutation, LikePostMutationVariables>;
export const RemoveLikeFromPostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveLikeFromPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RemoveLikeFromPostInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeLikeFromPost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'post' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Post' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Comment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommentNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Comment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollVote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollVoteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollVote' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'votesNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePoll' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answersAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'randomizeOptions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowRevote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousVotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privateResults' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Post' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasePost' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poll' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BasePoll' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveLikeFromPostMutation,
  RemoveLikeFromPostMutationVariables
>;
export const CommentPostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CommentPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CommentPostInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commentPost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'post' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Post' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Comment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommentNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Comment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollVote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollVoteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollVote' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'votesNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePoll' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answersAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'randomizeOptions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowRevote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousVotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privateResults' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Post' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasePost' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poll' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BasePoll' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CommentPostMutation, CommentPostMutationVariables>;
export const LikeCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LikeComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LikeCommentInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likeComment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'post' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Post' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Comment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommentNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Comment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollVote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollVoteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollVote' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'votesNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePoll' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answersAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'randomizeOptions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowRevote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousVotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privateResults' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Post' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasePost' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poll' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BasePoll' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LikeCommentMutation, LikeCommentMutationVariables>;
export const RemoveLikeFromCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveLikeFromComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RemoveLikeFromCommentInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeLikeFromComment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'post' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Post' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Comment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommentNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Comment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollVote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollVoteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollVote' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'votesNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePoll' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answersAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'randomizeOptions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowRevote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousVotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privateResults' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Post' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasePost' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poll' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BasePoll' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveLikeFromCommentMutation,
  RemoveLikeFromCommentMutationVariables
>;
export const DeleteCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteCommentInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteComment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'post' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Post' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Comment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommentNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Comment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollVote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollVoteNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PollOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollVote' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'votesNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasePoll' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PollNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answersAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'randomizeOptions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowRevote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousVotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privateResults' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PollOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Post' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasePost' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poll' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BasePoll' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>;
export const GenerateShopifyUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GenerateShopifyUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GenerateShopifyRedirectUrlInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateShopifyUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'redirectUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenerateShopifyUrlMutation,
  GenerateShopifyUrlMutationVariables
>;
export const TeamsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Teams' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'group' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userGroupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'group' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'group' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Team' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TeamsQuery, TeamsQueryVariables>;
export const TeamSamplesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TeamSamples' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'group' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'group' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'group' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'TeamSample' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserTeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdmin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultAvatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamSample' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sampleMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TeamUser' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TeamSamplesQuery, TeamSamplesQueryVariables>;
export const TeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Team' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TeamQuery, TeamQueryVariables>;
export const TeamDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TeamDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TeamDetail' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserTeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdmin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultAvatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamDetail' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TeamUser' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TeamDetailQuery, TeamDetailQueryVariables>;
export const AddUserToTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddUserToTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddUserToTeamInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addUserToTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userTeam' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TeamUser' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'teams' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Team' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'group' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Group' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupIcon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupIconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GraduationType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GraduationTypeNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserTeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdmin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultAvatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupIcon' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GraduationType' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddUserToTeamMutation,
  AddUserToTeamMutationVariables
>;
export const RemoveUserFromTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveUserFromTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RemoveUserFromTeamInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeUserFromTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userTeam' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TeamUser' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserTeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdmin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultAvatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveUserFromTeamMutation,
  RemoveUserFromTeamMutationVariables
>;
export const CreateTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateTeamInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TeamDetail' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserTeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdmin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultAvatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamDetail' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TeamUser' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateTeamMutation, CreateTeamMutationVariables>;
export const UpdateTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateTeamInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TeamDetail' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserTeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdmin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultAvatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamDetail' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TeamUser' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateTeamMutation, UpdateTeamMutationVariables>;
export const DestroyTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DestroyTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DestroyTeamInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destroyTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TeamDetail' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserTeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdmin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultAvatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamDetail' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TeamUser' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DestroyTeamMutation, DestroyTeamMutationVariables>;
export const ActivateTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ActivateTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ActivateTeamInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activateTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TeamDetail' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserTeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdmin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultAvatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamDetail' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TeamUser' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ActivateTeamMutation,
  ActivateTeamMutationVariables
>;
export const AcceptUserInTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AcceptUserInTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AcceptUserInTeamInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptUserInTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userTeam' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TeamUser' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserTeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdmin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultAvatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptUserInTeamMutation,
  AcceptUserInTeamMutationVariables
>;
export const UserTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TeamUser' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserTeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdmin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultAvatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserTeamQuery, UserTeamQueryVariables>;
export const ModulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Modules' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modules' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Module' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'features' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModulesQuery, ModulesQueryVariables>;
export const ModuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Module' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'module' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Module' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'features' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModuleQuery, ModuleQueryVariables>;
export const ModuleInstancesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ModuleInstances' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'group' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moduleInstances' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'group' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'group' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ModuleInstance' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'features' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'module' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Module' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ModuleInstancesQuery,
  ModuleInstancesQueryVariables
>;
export const ModuleInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ModuleInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moduleInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ModuleInstanceDetail' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'features' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'module' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Module' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photoFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibleComments' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resultsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAlbumsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'photosCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowImages' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuotesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymous' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomPageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomPagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'guide' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstanceDetail' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ModuleInstance' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CollagesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuotesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomPageSetup' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModuleInstanceQuery, ModuleInstanceQueryVariables>;
export const ModuleInstanceResultsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ModuleInstanceResults' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'group' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moduleInstances' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'group' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'group' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ModuleInstanceResult',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'features' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'module' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Module' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrewordResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrewordInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'wordCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageUserSummary' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageUserSummaryNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'questionsAnswered' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imagesUploaded' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageUserSummary' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'usersDelivered' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'rankingsCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'photosCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reportsCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuotesResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'quotesCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photoFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibleComments' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resultsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAlbumsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'photosCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowImages' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuotesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymous' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomPageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomPagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'guide' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstanceDetail' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ModuleInstance' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CollagesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuotesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomPageSetup' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomPage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomPagesInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'page' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moduleInstance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ModuleInstanceDetail' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pageFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageQuality' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstanceResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ModuleInstance' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prewordInstance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrewordResult' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageResult' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingsResult' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CollagesResult' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportsResult' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuotesResult' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesInstances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CustomPage' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ModuleInstanceResultsQuery,
  ModuleInstanceResultsQueryVariables
>;
export const ModuleInstanceResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ModuleInstanceResult' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moduleInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ModuleInstanceResult' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'features' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'module' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Module' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrewordResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrewordInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'wordCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageUserSummary' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageUserSummaryNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'questionsAnswered' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imagesUploaded' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageUserSummary' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'usersDelivered' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'rankingsCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'photosCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reportsCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuotesResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'quotesCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photoFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibleComments' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resultsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAlbumsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'photosCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowImages' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuotesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymous' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomPageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomPagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'guide' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstanceDetail' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ModuleInstance' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CollagesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuotesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomPageSetup' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomPage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomPagesInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'page' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moduleInstance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ModuleInstanceDetail' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pageFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageQuality' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstanceResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ModuleInstance' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prewordInstance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrewordResult' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageResult' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingsResult' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CollagesResult' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportsResult' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuotesResult' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesInstances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CustomPage' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ModuleInstanceResultQuery,
  ModuleInstanceResultQueryVariables
>;
export const ProfilePagePhotoCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProfilePagePhotoCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'moduleInstance' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ppqUserGroup' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePagePhotoCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moduleInstance' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'moduleInstance' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ProfilePagePhotoCategory',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseProfilePagePhotoCategory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePagePhotoCategoryNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePagePhoto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePagePhotoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePagePhotoCategory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePagePhotoCategoryNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BaseProfilePagePhotoCategory' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePagePhotos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ppqUserGroup' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ProfilePagePhoto' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfilePagePhotoCategoriesQuery,
  ProfilePagePhotoCategoriesQueryVariables
>;
export const ProfilePageQuestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProfilePageQuestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'moduleInstance' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ppqUserGroup' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageQuestions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moduleInstance' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'moduleInstance' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ProfilePageQuestion',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageQuestionOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageQuestionOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseProfilePageQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageQuestionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageQuestionOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageQuestionOption' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageAnswer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageAnswerNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageQuestionOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageQuestionOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageQuestionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BaseProfilePageQuestion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageAnswers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ppqUserGroup' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ProfilePageAnswer',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfilePageQuestionsQuery,
  ProfilePageQuestionsQueryVariables
>;
export const ProfilePageSuggestedQuestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProfilePageSuggestedQuestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageSuggestedQuestions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ProfilePageSuggestedQuestion',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageSuggestedQuestionOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageSuggestedQuestionOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageSuggestedQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageSuggestedQuestionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfilePageSuggestedQuestionOption',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfilePageSuggestedQuestionsQuery,
  ProfilePageSuggestedQuestionsQueryVariables
>;
export const ProfilePageCommentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProfilePageComments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'profileUserGroup' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'comentatorUserGroup' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'moduleInstance' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageComments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileUserGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'profileUserGroup' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comentatorUserGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'comentatorUserGroup' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moduleInstance' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'moduleInstance' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ProfilePageComment',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageComment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageCommentNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comentatorUserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileUserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfilePageCommentsQuery,
  ProfilePageCommentsQueryVariables
>;
export const CollagesAlbumsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CollagesAlbums' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'moduleInstance' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cpAfter' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cpFirst' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesAlbums' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moduleInstance' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'moduleInstance' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CollagesAlbum' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseCollagesAlbum' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesAlbumNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesBasePhoto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesPhotoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotoLikesCount' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesAlbum' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesAlbumNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BaseCollagesAlbum' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'photosCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cpAfter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cpFirst' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'CollagesBasePhoto',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollagesAlbumsQuery, CollagesAlbumsQueryVariables>;
export const CollagesAlbumDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CollagesAlbum' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesAlbum' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CollagesBaseAlbum' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseCollagesAlbum' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesAlbumNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesBaseAlbum' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesAlbumNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BaseCollagesAlbum' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'photosCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollagesAlbumQuery, CollagesAlbumQueryVariables>;
export const CollagesPhotosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CollagesPhotos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collagesAlbum' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'collagesAlbum' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'collagesAlbum' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CollagesPhoto' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesPhoto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesPhotoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotoLikesCount' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotoLikes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollagesPhotosQuery, CollagesPhotosQueryVariables>;
export const ReportsInstancesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReportsInstances' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'moduleInstance' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsInstances' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moduleInstance' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'moduleInstance' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ReportsInstance' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReportsInstancesQuery,
  ReportsInstancesQueryVariables
>;
export const ReportsInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReportsInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportsInstance' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReportsInstanceQuery,
  ReportsInstanceQueryVariables
>;
export const QuotesInstancesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'QuotesInstances' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'moduleInstance' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userGroup' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesInstances' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moduleInstance' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'moduleInstance' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userGroup' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Quote' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseQuote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesInstanceLikesCount' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Quote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BaseQuote' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesInstanceLikes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  QuotesInstancesQuery,
  QuotesInstancesQueryVariables
>;
export const CustomPagesInstancesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomPagesInstances' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'moduleInstance' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userGroup' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesInstances' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moduleInstance' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'moduleInstance' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userGroup' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CustomPage' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'features' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'module' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Module' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photoFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibleComments' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resultsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAlbumsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'photosCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowImages' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuotesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymous' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomPageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomPagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'guide' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstanceDetail' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ModuleInstance' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CollagesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuotesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomPageSetup' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomPage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomPagesInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'page' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moduleInstance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ModuleInstanceDetail' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pageFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageQuality' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CustomPagesInstancesQuery,
  CustomPagesInstancesQueryVariables
>;
export const CustomPagesInstancesResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomPagesInstancesResult' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'moduleInstance' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesInstancesResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moduleInstance' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'moduleInstance' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CustomPage' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'features' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'module' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Module' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photoFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibleComments' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resultsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAlbumsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'photosCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowImages' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuotesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymous' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomPageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomPagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'guide' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstanceDetail' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ModuleInstance' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CollagesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuotesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomPageSetup' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomPage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomPagesInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'page' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moduleInstance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ModuleInstanceDetail' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pageFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageQuality' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CustomPagesInstancesResultQuery,
  CustomPagesInstancesResultQueryVariables
>;
export const CustomListsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomLists' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'moduleInstance' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customLists' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moduleInstance' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'moduleInstance' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CustomList' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomList' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomListNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomListsQuery, CustomListsQueryVariables>;
export const CustomListOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomListOptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customList' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customListOptions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customList' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customList' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CustomListOption' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomListOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomListOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CustomListOptionsQuery,
  CustomListOptionsQueryVariables
>;
export const RankingsQuestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RankingsQuestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'moduleInstance' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsQuestions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moduleInstance' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'moduleInstance' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'RankingQuestion' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomListOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomListOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestionResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionResultNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee1UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee2UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voteeListOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomListOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestionResults' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionResultsNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'alreadyVoted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'possibleVoters' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingQuestionResult' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsAnswer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsAnswerNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee1UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee2UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voteeListOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomListOption' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answerType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedVotes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onlyOppositeGender' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'results' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingQuestionResults' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsAnswers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'voterUserGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'RankingsAnswer' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RankingsQuestionsQuery,
  RankingsQuestionsQueryVariables
>;
export const RankingsQuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RankingsQuestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsQuestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingBaseQuestion' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingBaseQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answerType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedVotes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onlyOppositeGender' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsAnswers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'voterUserGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RankingsQuestionQuery,
  RankingsQuestionQueryVariables
>;
export const DataExportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataExports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'group' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dataExports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'group' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'group' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'DataExport' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataExport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DataExportNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isodatetime' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'exportFile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exportCollages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exportRankings' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportProfilePages' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'exportQuotes' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataExportsQuery, DataExportsQueryVariables>;
export const CustomPagesInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomPagesInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomPage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'features' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'module' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Module' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photoFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibleComments' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resultsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAlbumsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'photosCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowImages' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuotesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymous' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomPageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomPagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'guide' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstanceDetail' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ModuleInstance' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CollagesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuotesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomPageSetup' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomPage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomPagesInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'page' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moduleInstance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ModuleInstanceDetail' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pageFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageQuality' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CustomPagesInstanceQuery,
  CustomPagesInstanceQueryVariables
>;
export const CreateModuleInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateModuleInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateModuleInstanceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createModuleInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moduleInstance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ModuleInstance' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'features' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'module' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Module' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateModuleInstanceMutation,
  CreateModuleInstanceMutationVariables
>;
export const DestroyModuleInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DestroyModuleInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DestroyModuleInstanceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destroyModuleInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moduleInstance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ModuleInstance' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'features' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'module' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Module' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DestroyModuleInstanceMutation,
  DestroyModuleInstanceMutationVariables
>;
export const UpdatePrewordInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePrewordInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePrewordInstanceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePrewordInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'prewordInstance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PrewordInstance' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrewordInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrewordInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePrewordInstanceMutation,
  UpdatePrewordInstanceMutationVariables
>;
export const CreateProfilePagePhotoCategoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProfilePagePhotoCategory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreateProfilePagePhotoCategoryInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProfilePagePhotoCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePagePhotoCategory' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'BaseProfilePagePhotoCategory',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseProfilePagePhotoCategory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePagePhotoCategoryNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProfilePagePhotoCategoryMutation,
  CreateProfilePagePhotoCategoryMutationVariables
>;
export const UpdateProfilePagePhotoCategoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProfilePagePhotoCategory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateProfilePagePhotoCategoryInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfilePagePhotoCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePagePhotoCategory' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'BaseProfilePagePhotoCategory',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseProfilePagePhotoCategory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePagePhotoCategoryNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProfilePagePhotoCategoryMutation,
  UpdateProfilePagePhotoCategoryMutationVariables
>;
export const DestroyProfilePagePhotoCategoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DestroyProfilePagePhotoCategory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'DestroyProfilePagePhotoCategoryInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destroyProfilePagePhotoCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePagePhotoCategory' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'BaseProfilePagePhotoCategory',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseProfilePagePhotoCategory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePagePhotoCategoryNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DestroyProfilePagePhotoCategoryMutation,
  DestroyProfilePagePhotoCategoryMutationVariables
>;
export const CreateProfilePagePhotoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProfilePagePhoto' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateProfilePagePhotoInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProfilePagePhoto' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePagePhoto' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProfilePagePhoto' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePagePhoto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePagePhotoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProfilePagePhotoMutation,
  CreateProfilePagePhotoMutationVariables
>;
export const UpdateProfilePagePhotoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProfilePagePhoto' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateProfilePagePhotoInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfilePagePhoto' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePagePhoto' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProfilePagePhoto' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePagePhoto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePagePhotoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProfilePagePhotoMutation,
  UpdateProfilePagePhotoMutationVariables
>;
export const DestroyProfilePagePhotoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DestroyProfilePagePhoto' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DestroyProfilePagePhotoInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destroyProfilePagePhoto' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePagePhoto' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProfilePagePhoto' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePagePhoto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePagePhotoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DestroyProfilePagePhotoMutation,
  DestroyProfilePagePhotoMutationVariables
>;
export const CreateProfilePageQuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProfilePageQuestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateProfilePageQuestionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProfilePageQuestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePageQuestion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'BaseProfilePageQuestion',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageQuestionOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageQuestionOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseProfilePageQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageQuestionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageQuestionOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageQuestionOption' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProfilePageQuestionMutation,
  CreateProfilePageQuestionMutationVariables
>;
export const UpdateProfilePageQuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProfilePageQuestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateProfilePageQuestionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfilePageQuestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePageQuestion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'BaseProfilePageQuestion',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageQuestionOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageQuestionOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseProfilePageQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageQuestionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageQuestionOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageQuestionOption' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProfilePageQuestionMutation,
  UpdateProfilePageQuestionMutationVariables
>;
export const DestroyProfilePageQuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DestroyProfilePageQuestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DestroyProfilePageQuestionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destroyProfilePageQuestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePageQuestion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'BaseProfilePageQuestion',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageQuestionOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageQuestionOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseProfilePageQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageQuestionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageQuestionOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageQuestionOption' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DestroyProfilePageQuestionMutation,
  DestroyProfilePageQuestionMutationVariables
>;
export const CreateProfilePageAnswerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProfilePageAnswer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateProfilePageAnswerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProfilePageAnswer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePageAnswer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProfilePageAnswer' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageQuestionOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageQuestionOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageAnswer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageAnswerNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageQuestionOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageQuestionOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProfilePageAnswerMutation,
  CreateProfilePageAnswerMutationVariables
>;
export const UpdateProfilePageAnswerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProfilePageAnswer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateProfilePageAnswerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfilePageAnswer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePageAnswer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProfilePageAnswer' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageQuestionOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageQuestionOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageAnswer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageAnswerNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageQuestionOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageQuestionOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProfilePageAnswerMutation,
  UpdateProfilePageAnswerMutationVariables
>;
export const DestroyProfilePageAnswerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DestroyProfilePageAnswer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DestroyProfilePageAnswerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destroyProfilePageAnswer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clientMutationId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DestroyProfilePageAnswerMutation,
  DestroyProfilePageAnswerMutationVariables
>;
export const UpdateProfilePageSetupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProfilePageSetup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateProfilePageSetupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfilePageSetup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePageSetup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProfilePageSetup' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photoFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibleComments' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProfilePageSetupMutation,
  UpdateProfilePageSetupMutationVariables
>;
export const UpdateModuleInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateModuleInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateModuleInstanceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateModuleInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moduleInstance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ModuleInstanceDetail' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'features' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'module' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Module' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photoFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibleComments' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resultsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAlbumsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'photosCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowImages' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuotesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymous' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomPageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomPagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'guide' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstanceDetail' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ModuleInstance' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CollagesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuotesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomPageSetup' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateModuleInstanceMutation,
  UpdateModuleInstanceMutationVariables
>;
export const CreateProfilePageCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProfilePageComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateProfilePageCommentInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProfilePageComment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePageComment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProfilePageComment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageComment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageCommentNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comentatorUserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileUserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProfilePageCommentMutation,
  CreateProfilePageCommentMutationVariables
>;
export const UpdateProfilePageCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProfilePageComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateProfilePageCommentInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfilePageComment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePageComment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProfilePageComment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageComment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageCommentNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comentatorUserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileUserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProfilePageCommentMutation,
  UpdateProfilePageCommentMutationVariables
>;
export const DestroyProfilePageCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DestroyProfilePageComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DestroyProfilePageCommentInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destroyProfilePageComment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePageComment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProfilePageComment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageComment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageCommentNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comentatorUserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileUserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DestroyProfilePageCommentMutation,
  DestroyProfilePageCommentMutationVariables
>;
export const MoveProfilePageQuestionToPositionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MoveProfilePageQuestionToPosition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'MoveProfilePageQuestionToPositionInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moveProfilePageQuestionToPosition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePageQuestion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'BaseProfilePageQuestion',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageQuestionOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageQuestionOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseProfilePageQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageQuestionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageQuestionOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageQuestionOption' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MoveProfilePageQuestionToPositionMutation,
  MoveProfilePageQuestionToPositionMutationVariables
>;
export const AddPhotoToCollagesAlbumDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddPhotoToCollagesAlbum' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddPhotoToCollagesAlbumInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPhotoToCollagesAlbum' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'photo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CollagesPhoto' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesPhoto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesPhotoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotoLikesCount' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotoLikes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddPhotoToCollagesAlbumMutation,
  AddPhotoToCollagesAlbumMutationVariables
>;
export const RemovePhotoFromCollagesAlbumDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemovePhotoFromCollagesAlbum' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'RemovePhotoFromCollagesAlbumInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removePhotoFromCollagesAlbum' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemovePhotoFromCollagesAlbumMutation,
  RemovePhotoFromCollagesAlbumMutationVariables
>;
export const CreateCollagesAlbumDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCollagesAlbum' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCollagesAlbumInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCollagesAlbum' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collagesAlbum' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BaseCollagesAlbum' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseCollagesAlbum' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesAlbumNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCollagesAlbumMutation,
  CreateCollagesAlbumMutationVariables
>;
export const UpdateCollagesAlbumDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCollagesAlbum' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCollagesAlbumInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCollagesAlbum' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collagesAlbum' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BaseCollagesAlbum' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseCollagesAlbum' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesAlbumNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCollagesAlbumMutation,
  UpdateCollagesAlbumMutationVariables
>;
export const DestroyCollagesAlbumDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DestroyCollagesAlbum' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DestroyCollagesAlbumInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destroyCollagesAlbum' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collagesAlbum' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BaseCollagesAlbum' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseCollagesAlbum' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesAlbumNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DestroyCollagesAlbumMutation,
  DestroyCollagesAlbumMutationVariables
>;
export const LikeCollagesPhotoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LikeCollagesPhoto' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LikeCollagesPhotoInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likeCollagesPhoto' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collagesPhoto' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CollagesPhoto' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesPhoto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesPhotoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotoLikesCount' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotoLikes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LikeCollagesPhotoMutation,
  LikeCollagesPhotoMutationVariables
>;
export const RemoveLikeFromCollagesPhotoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveLikeFromCollagesPhoto' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RemoveLikeFromCollagesPhotoInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeLikeFromCollagesPhoto' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collagesPhoto' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CollagesPhoto' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesPhoto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesPhotoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotoLikesCount' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotoLikes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveLikeFromCollagesPhotoMutation,
  RemoveLikeFromCollagesPhotoMutationVariables
>;
export const UpdateCollagesSetupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCollagesSetup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCollagesSetupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCollagesSetup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collagesSetup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CollagesSetup' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAlbumsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'photosCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCollagesSetupMutation,
  UpdateCollagesSetupMutationVariables
>;
export const UpdateReportsSetupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateReportsSetup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateReportsSetupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateReportsSetup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportsSetup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReportsSetup' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowImages' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateReportsSetupMutation,
  UpdateReportsSetupMutationVariables
>;
export const CreateReportsInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateReportsInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateReportsInstanceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createReportsInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportsInstance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReportsInstance' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateReportsInstanceMutation,
  CreateReportsInstanceMutationVariables
>;
export const UpdateReportsInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateReportsInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateReportsInstanceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateReportsInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportsInstance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReportsInstance' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateReportsInstanceMutation,
  UpdateReportsInstanceMutationVariables
>;
export const DestroyReportsInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DestroyReportsInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DestroyReportsInstanceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destroyReportsInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportsInstanceId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DestroyReportsInstanceMutation,
  DestroyReportsInstanceMutationVariables
>;
export const CreateQuotesInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateQuotesInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateQuotesInstanceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createQuotesInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quotesInstance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BaseQuote' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseQuote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesInstanceLikesCount' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateQuotesInstanceMutation,
  CreateQuotesInstanceMutationVariables
>;
export const DestroyQuotesInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DestroyQuotesInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DestroyQuotesInstanceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destroyQuotesInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quotesInstance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BaseQuote' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseQuote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesInstanceLikesCount' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DestroyQuotesInstanceMutation,
  DestroyQuotesInstanceMutationVariables
>;
export const UpdateQuotesSetupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateQuotesSetup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateQuotesSetupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateQuotesSetup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quotesSetup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'QuotesSetup' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuotesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymous' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateQuotesSetupMutation,
  UpdateQuotesSetupMutationVariables
>;
export const LikeQuotesInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LikeQuotesInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LikeQuotesInstanceInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likeQuotesInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quotesInstance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Quote' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseQuote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesInstanceLikesCount' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Quote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BaseQuote' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesInstanceLikes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LikeQuotesInstanceMutation,
  LikeQuotesInstanceMutationVariables
>;
export const RemoveLikeFromQuotesInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveLikeFromQuotesInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'RemoveLikeFromQuotesInstanceInput',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeLikeFromQuotesInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quotesInstance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Quote' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseQuote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesInstanceLikesCount' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Quote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BaseQuote' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesInstanceLikes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveLikeFromQuotesInstanceMutation,
  RemoveLikeFromQuotesInstanceMutationVariables
>;
export const UpdateRankingsSetupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRankingsSetup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateRankingsSetupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRankingsSetup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rankingsSetup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RankingsSetup' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resultsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateRankingsSetupMutation,
  UpdateRankingsSetupMutationVariables
>;
export const UpdateCustomPagesSetupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCustomPagesSetup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCustomPagesSetupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCustomPagesSetup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customPagesSetup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CustomPageSetup' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomPageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomPagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'guide' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCustomPagesSetupMutation,
  UpdateCustomPagesSetupMutationVariables
>;
export const CreateCustomPagesInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCustomPagesInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCustomPagesInstanceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCustomPagesInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customPagesInstance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CustomPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'features' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'module' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Module' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photoFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibleComments' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resultsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAlbumsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'photosCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowImages' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuotesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymous' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomPageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomPagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'guide' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstanceDetail' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ModuleInstance' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CollagesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuotesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomPageSetup' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomPage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomPagesInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'page' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moduleInstance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ModuleInstanceDetail' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pageFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageQuality' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCustomPagesInstanceMutation,
  CreateCustomPagesInstanceMutationVariables
>;
export const UpdateCustomPagesInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCustomPagesInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCustomPagesInstanceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCustomPagesInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customPagesInstance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CustomPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'features' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'module' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Module' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photoFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymousComments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibleComments' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ColorNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Icon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IconNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TeamNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalSetting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Icon' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resultsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAlbumsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPhotosTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'photosCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowImages' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuotesSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anonymous' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxChars' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomPageSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomPagesSetupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewPagesTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Team' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'guide' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstanceDetail' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ModuleInstance' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfilePageSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CollagesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportsSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuotesSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomPageSetup' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomPage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomPagesInstanceNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'page' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moduleInstance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ModuleInstanceDetail' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pageFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageQuality' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCustomPagesInstanceMutation,
  UpdateCustomPagesInstanceMutationVariables
>;
export const DestroyCustomPagesInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DestroyCustomPagesInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DestroyCustomPagesInstanceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destroyCustomPagesInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customPagesInstanceId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DestroyCustomPagesInstanceMutation,
  DestroyCustomPagesInstanceMutationVariables
>;
export const CreateRankingsQuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateRankingsQuestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateRankingsQuestionInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createRankingsQuestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rankingsQuestion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RankingQuestion' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomListOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomListOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestionResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionResultNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee1UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee2UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voteeListOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomListOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestionResults' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionResultsNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'alreadyVoted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'possibleVoters' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingQuestionResult' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsAnswer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsAnswerNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee1UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee2UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voteeListOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomListOption' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answerType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedVotes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onlyOppositeGender' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'results' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingQuestionResults' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsAnswers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'voterUserGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'RankingsAnswer' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateRankingsQuestionMutation,
  CreateRankingsQuestionMutationVariables
>;
export const UpdateRankingsQuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRankingsQuestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateRankingsQuestionInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRankingsQuestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rankingsQuestion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RankingQuestion' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomListOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomListOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestionResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionResultNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee1UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee2UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voteeListOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomListOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestionResults' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionResultsNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'alreadyVoted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'possibleVoters' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingQuestionResult' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsAnswer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsAnswerNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee1UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee2UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voteeListOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomListOption' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answerType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedVotes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onlyOppositeGender' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'results' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingQuestionResults' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsAnswers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'voterUserGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'RankingsAnswer' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateRankingsQuestionMutation,
  UpdateRankingsQuestionMutationVariables
>;
export const DestroyRankingsQuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DestroyRankingsQuestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DestroyRankingsQuestionInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destroyRankingsQuestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rankingsQuestion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RankingQuestion' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomListOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomListOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestionResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionResultNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee1UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee2UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voteeListOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomListOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestionResults' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionResultsNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'alreadyVoted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'possibleVoters' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingQuestionResult' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsAnswer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsAnswerNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee1UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee2UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voteeListOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomListOption' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answerType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedVotes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onlyOppositeGender' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'results' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingQuestionResults' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsAnswers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'voterUserGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'RankingsAnswer' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DestroyRankingsQuestionMutation,
  DestroyRankingsQuestionMutationVariables
>;
export const CreateRankingsAnswerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateRankingsAnswer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateRankingsAnswerInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createRankingsAnswer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rankingsAnswer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rankingsQuestion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'RankingQuestion' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomListOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomListOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestionResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionResultNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee1UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee2UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voteeListOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomListOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestionResults' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionResultsNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'alreadyVoted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'possibleVoters' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingQuestionResult' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsAnswer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsAnswerNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee1UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee2UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voteeListOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomListOption' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answerType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedVotes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onlyOppositeGender' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'results' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingQuestionResults' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsAnswers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'voterUserGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'RankingsAnswer' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateRankingsAnswerMutation,
  CreateRankingsAnswerMutationVariables
>;
export const UpdateRankingsAnswerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRankingsAnswer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateRankingsAnswerInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authUserGroupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRankingsAnswer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rankingsAnswer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rankingsQuestion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'RankingQuestion' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DefaultAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DefaultAvatarNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DefaultAvatar' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomListOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomListOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestionResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionResultNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee1UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee2UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voteeListOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomListOption' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestionResults' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionResultsNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'alreadyVoted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'possibleVoters' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingQuestionResult' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsAnswer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsAnswerNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee1UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee2UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voteeListOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomListOption' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answerType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedVotes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onlyOppositeGender' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'results' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankingQuestionResults' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsAnswers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'voterUserGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authUserGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'RankingsAnswer' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateRankingsAnswerMutation,
  UpdateRankingsAnswerMutationVariables
>;
export const CreateCustomListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCustomList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCustomListInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCustomList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CustomList' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomList' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomListNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCustomListMutation,
  CreateCustomListMutationVariables
>;
export const UpdateCustomListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCustomList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCustomListInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCustomList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CustomList' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomList' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomListNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCustomListMutation,
  UpdateCustomListMutationVariables
>;
export const DeleteCustomListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCustomList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteCustomListInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCustomList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CustomList' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomList' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomListNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCustomListMutation,
  DeleteCustomListMutationVariables
>;
export const CreateCustomListOptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCustomListOption' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCustomListOptionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCustomListOption' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customListOption' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CustomListOption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomListOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomListOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCustomListOptionMutation,
  CreateCustomListOptionMutationVariables
>;
export const UpdateCustomListOptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCustomListOption' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCustomListOptionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCustomListOption' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customListOption' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CustomListOption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomListOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomListOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCustomListOptionMutation,
  UpdateCustomListOptionMutationVariables
>;
export const DeleteCustomListOptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCustomListOption' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteCustomListOptionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCustomListOption' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customListOption' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CustomListOption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomListOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomListOptionNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCustomListOptionMutation,
  DeleteCustomListOptionMutationVariables
>;
export const CreateDataExportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateDataExport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateDataExportInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDataExport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDataExportMutation,
  CreateDataExportMutationVariables
>;
