import React from 'react';
import { useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { useFocusEffect } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { DEFAULT_LIST_OFFSET } from '~/data/constants';
import { Event } from '~/data/models/calendar';
import { EVENTS } from '~/data/operations/calendar';
import { EventsQueryVariables } from '~/data/types/graphql';
import { PlanningStackParamList } from '~/navigation/types';
import { calendarDay } from '~/utils/dates';
import CalendarLayout from './layout';

type CalendarNavProp = StackNavigationProp<PlanningStackParamList, 'Calendar'>;

export default function Calendar(props?: { now?: Date }): JSX.Element {
  const now = props?.now;

  const navigation = useNavigation<CalendarNavProp>();

  const eventsVar: EventsQueryVariables = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
    fromDate: calendarDay(now),
  };

  const {
    data,
    loading,
    refetch,
    fetchMore: fetchMoreEvents,
  } = useQuery(EVENTS, {
    variables: eventsVar,
    notifyOnNetworkStatusChange: true,
  });

  useFocusEffect(
    React.useCallback(() => {
      refetch();
    }, [refetch]),
  );

  const getUpcomingEvents = (events: Event[]): Event[] => {
    const currentDate = new Date();
    return events.filter((event) => {
      const eventDate = new Date(event.startDateTime);
      const dayDifference =
        (eventDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24);

      return (
        eventDate >= currentDate && dayDifference > 0 && dayDifference <= 14
      );
    });
  };

  const events: Event[] =
    (data?.events?.edges.map((edge) => edge?.node) as Event[]) || [];

  const upcomingEvents = getUpcomingEvents(events);

  const hasNextPage = data?.events?.pageInfo.hasNextPage;
  const endCursor = data?.events?.pageInfo.endCursor;

  const onLoadMore = () => {
    hasNextPage &&
      fetchMoreEvents({
        variables: {
          ...eventsVar,
          after: endCursor,
        },
      });
  };

  return (
    <CalendarLayout
      calendarEvents={events}
      onBack={() => navigation.goBack()}
      onAddEvent={() => navigation.navigate('CreateEvent')}
      onEventOpen={(id) => navigation.navigate('EventDetail', { eventId: id })}
      upcomingEvents={upcomingEvents}
      loading={loading}
      hasNextPage={hasNextPage}
      onLoadMore={onLoadMore}
    />
  );
}
