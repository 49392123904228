import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { DEFAULT_LIST_OFFSET } from '~/data/constants';
import useAuthTeams from '~/data/hooks/useAuthTeams';
import { ADD_IMAGES_TO_POST, CREATE_POST } from '~/data/operations/post';
import {
  writePostsQuery,
  readPostsQuery,
} from '~/data/operations/post/helpers';
import { TEAM } from '~/data/operations/team';
import { Owner } from '~/data/types/graphql';
import { RootStackParamList } from '~/navigation/types';
import { getImageFile } from '~/utils/helpers';
import { t } from '~/utils/i18n';
import CreatePostLayout, { CreatePostInputProps } from './layout';

type CreatePostNavProp = StackNavigationProp<RootStackParamList, 'CreatePost'>;

type CreatePostRouteProp = RouteProp<RootStackParamList, 'CreatePost'>;

export default function CreatePost(): JSX.Element {
  const navigation = useNavigation<CreatePostNavProp>();
  const {
    params: { teamId, imgUris },
  } = useRoute<CreatePostRouteProp>();
  const lockedTeamId = teamId;

  const { authGroupId, authUserGroupId, authUserGroup } = useAuth();

  const { teams } = useAuthTeams({ skip: !!lockedTeamId });

  const { data: teamData } = useQuery(TEAM, {
    skip: !teamId,
    variables: {
      id: teamId as string,
    },
  });
  const lockedTeam = teamData?.team;

  const [createPost, { loading: createPostLoading }] = useMutation(
    CREATE_POST,
    {
      update(cache, { data }) {
        const newPost = data?.createPost?.post;

        const postsVar = {
          after: null,
          first: DEFAULT_LIST_OFFSET,
          createdBy_Group: !teamId ? authGroupId : undefined,
          team: teamId,
          authUserGroupId,
        };

        const currentPosts = readPostsQuery({
          cache,
          variables: postsVar,
        });

        if (currentPosts?.posts?.edges && newPost) {
          writePostsQuery({
            cache,
            variables: postsVar,
            data: {
              ...currentPosts,
              posts: {
                ...currentPosts.posts,
                edges: [
                  {
                    __typename: 'PostNodeEdge',
                    node: newPost,
                  },
                  ...currentPosts.posts.edges,
                ],
              },
            },
          });
        }
      },
    },
  );

  const [addImagesToPost, { loading: addImagesToPostLoading }] =
    useMutation(ADD_IMAGES_TO_POST);

  const onCreatePost = async ({
    authorTeamId,
    text,
    imageUris,
  }: CreatePostInputProps) => {
    try {
      const { data } = await createPost({
        variables: {
          input: {
            owner: authorTeamId ? Owner.TEAM : Owner.USER,
            team: authorTeamId,
            group: authGroupId,
            text,
          },
          authUserGroupId,
        },
      });
      const postId = data?.createPost?.post?.id;
      if (!postId) {
        const messages = data?.createPost?.errors?.map(
          (error) => error?.messages[0],
        );
        const errorMessage = messages?.[0] as string;
        Snackbar.show(errorMessage);
        return;
      }

      let i;
      for (i = 0; i < imageUris.length; i++) {
        const imageUri = imageUris[i];
        const imageFile = await getImageFile(imageUri);
        await addImagesToPost({
          variables: {
            input: {
              id: postId,
              images: imageFile,
            },
            authUserGroupId,
          },
        });
      }
      navigation.goBack();
      Snackbar.show(t('createPost.createPostSuccess'));
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return (
    <CreatePostLayout
      loggedUserGroup={authUserGroup}
      teams={lockedTeam ? [lockedTeam] : teams}
      lockedTeamId={lockedTeamId}
      loading={createPostLoading || addImagesToPostLoading}
      initialImgUris={imgUris}
      onBack={() => navigation.goBack()}
      onCreatePoll={() => navigation.navigate('CreatePoll')}
      onCreatePost={(postInput) => onCreatePost(postInput)}
    />
  );
}
