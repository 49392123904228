import React, { useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  NavigationContainer,
  NavigationContainerRef,
  LinkingOptions,
  getStateFromPath,
  PathConfig,
} from '@react-navigation/native';
import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack';
import * as Sentry from '@sentry/react-native';
import * as Linking from 'expo-linking';
import { Platform } from 'react-native';
import NetworkError from '~/components/NetworkError';
import { ROOT_ENTRANCE, useAuth } from '~/context/auth';
import { IS_LANDSCAPE, NO_PAG_LIST_OFFSET } from '~/data/constants';
import { GroupUser } from '~/data/models/group';
import { GROUP_USERS } from '~/data/operations/group';
import { GroupsUserGroupStatusChoices } from '~/data/types/graphql';
import AdminStack from '~/navigation/stacks/Admin';
import Browser from '~/screens/Common/Browser';
import Invite from '~/screens/Common/Invite';
import InviteSuccess from '~/screens/Common/InviteSuccess';
import SignUpSuccess from '~/screens/Common/SignUpSuccess';
import Splash from '~/screens/Common/Splash';
import BrokenLink from '~/screens/Main/BrokenLink';
import DeleteAccount from '~/screens/Main/DeleteAccount';
import NotFound from '~/screens/Main/NotFound';
import Onboarding from '~/screens/Main/Onboarding';
import ServerError from '~/screens/Main/ServerError';
import AuthStack from './stacks/Auth';
import GroupStack from './stacks/Group';
import ImporterStack from './stacks/Importer';
import LobbyStack from './stacks/Lobby';
import MainTab from './stacks/Main';
import {
  MOBILE_ROOT_PLANNING_SCREENS,
  MOBILE_ROOT_YEARBOOK_SCREENS,
} from './stacks/Main';
import {
  FeedStackParamList,
  PlanningStackParamList,
  ProfileStackParamList,
  RootEntranceKey,
  RootStackParamList,
  YearbookStackParamList,
} from './types';
import { getEntryGroupId } from './utils';

//https://docs.sentry.io/platforms/react-native/tracing/instrumentation/react-navigation/
export const navigationIntegration = Sentry.reactNavigationIntegration();

const RootStack = createStackNavigator<RootStackParamList>();

type RootEntranceMap = { [key in RootEntranceKey]: JSX.Element };

export default function RootNavigation(): JSX.Element {
  const navigation = useRef<NavigationContainerRef<RootStackParamList>>(null);

  const { rootEntranceKey, authUserId, authGroupId, rehydrated, onAuthGroup } =
    useAuth();

  const [loadGroupUsers] = useLazyQuery(GROUP_USERS, {
    variables: {
      first: NO_PAG_LIST_OFFSET,
      user: authUserId,
      status: GroupsUserGroupStatusChoices.ACCEPTED,
    },
  });

  const checkAuthEntry = async (url: string): Promise<string | undefined> => {
    //mobile only, web is handled on getStateFromPath
    //default return same url for any public entry

    //check for auth group entry
    if (url.includes('group')) {
      const entryGroupId = getEntryGroupId(url);

      if (!entryGroupId) {
        return;
      }

      const { data: groupUsersData } = await loadGroupUsers();

      const groupUsers =
        (groupUsersData?.userGroups?.edges.map(
          (userGroup) => userGroup?.node,
        ) as GroupUser[]) || [];

      const entryGroupUser = groupUsers?.find(
        (groupUser) => groupUser.group?.id === entryGroupId,
      );

      if (!entryGroupUser) {
        return;
      }

      if (entryGroupId !== authGroupId) {
        onAuthGroup(entryGroupId, entryGroupUser.id);
      }

      return url;
    }

    return url;
  };

  const linking: LinkingOptions<RootStackParamList> = {
    prefixes: ['gradoo://', 'https://gradoo.app'],
    async getInitialURL() {
      //mobile entry
      const url = await Linking.getInitialURL();

      if (!url) {
        return;
      }

      const checkedUrl = await checkAuthEntry(url);

      return checkedUrl;
    },
    subscribe(listener) {
      // Listen to incoming links from deep linking
      const linkingSubscription = Linking.addEventListener(
        'url',
        async ({ url }) => {
          const checkedUrl = await checkAuthEntry(url);

          !!checkedUrl && listener(checkedUrl);
        },
      );

      return () => {
        // Clean up the event listeners
        linkingSubscription?.remove();
      };
    },
    getStateFromPath(path, options) {
      //getStateFromPath is user on web entry, should use the same logic from getInitialURL
      //we need to load groupUsers query to check if the user is part of the group, but this funcion is not async
      //an alternative to enable in the future is show splash screen while groupUsers is not loaded

      //ensure old app entry for cc and layoutcreator params
      //the params will be passed and then redirected on AuthStack/Login if not logged
      //or Lobby if logged
      if (path.includes('splash') && Platform.OS === 'web') {
        path = path.replace('splash', authUserId ? 'lobby' : 'launch');
      }

      return getStateFromPath(path, options);
    },
    config: {
      initialRouteName: rootEntranceKey,
      screens: {
        Invite: 'invite',
        InviteSuccess: 'invite/success',
        SignUpSuccess: 'sign-up/success',
        BrokenLink: 'broken-link',
        ServerError: 'server-error',
        AuthStack: {
          initialRouteName: 'Launch',
          screens: {
            Launch: 'launch',
            Login: 'login',
            ForgotPassword: 'forgot',
            ResetPassword: 'reset',
            ResetPasswordSuccess: 'reset/success',
            SignUpName: 'sign-up/name',
            SignUpContact: 'sign-up/contact',
            SignUpPassword: 'sign-up/password',
            SignUpTerm: 'sign-up/terms',
          },
        },
        LobbyStack: {
          initialRouteName: 'Lobby',
          screens: {
            Lobby: 'lobby',
          },
        },
        MainTab: {
          path: 'group/:gid',
          stringify: {
            gid: () => '',
          },
          screens: {
            FeedStack: {
              initialRouteName: 'Feed',
              screens: {
                Feed: 'feed',
                Comments: 'comments',
                CreatePost: 'post-create',
              },
            } as PathConfig<FeedStackParamList>, //fix not mapped nested initialRouteName type
            PlanningStack: {
              initialRouteName: 'Planning',
              screens: {
                Planning: 'planning',
                TeamDetail: 'team/:teamId',
              },
            } as PathConfig<PlanningStackParamList>,
            YearbookStack: {
              initialRouteName: 'Yearbook',
              screens: {
                Yearbook: 'yearbook',
              },
            } as PathConfig<YearbookStackParamList>,
            ProfileStack: {
              initialRouteName: 'Profile',
              screens: {
                Profile: 'profile',
              },
            } as PathConfig<ProfileStackParamList>,
          },
        },
        NotFound: '*',
        ImporterStack: {
          initialRouteName: 'AbihomeLogin',
          screens: {
            AbihomeLogin: 'import',
            SetupGroup: 'import/setup-group',
            Overview: 'import/overview',
            ImportStatus: 'import/status',
          },
        },
        AdminStack: {
          path: 'admin',
          screens: {
            SearchStack: {
              screens: {
                Search: 'search',
                EditUser: 'edit/user/:id',
                EditGroup: 'edit/group/:id',
              },
            },
            InstitutesStack: {
              path: 'institutes',
              screens: {
                Institutes: '/',
                InstituteRequest: 'request/:id',
                InstituteAdd: 'add',
              },
            },
            AdsStack: {
              path: 'ads',
              screens: {
                Ads: '/',
                EditAd: 'edit/ad/:id',
              },
            },
            ReportingStack: {
              path: 'reporting',
              screens: {
                Reporting: '/',
              },
            },
            SupportStack: {
              path: 'support',
              screens: {
                Support: '/',
              },
            },
          },
        },
      },
    },
  };

  const modalPresentationIOSOption = {
    cardOverlayEnabled: true,
    ...TransitionPresets.ModalPresentationIOS,
  };

  if (!rehydrated) {
    //it's needed on web to resolve the async entry path
    return <Splash />;
  }

  const rootEntranceMap: RootEntranceMap = {
    [ROOT_ENTRANCE.AUTH]: (
      <RootStack.Screen name={'AuthStack'} component={AuthStack} />
    ),
    [ROOT_ENTRANCE.LOBBY]: (
      <RootStack.Screen name={'LobbyStack'} component={LobbyStack} />
    ),

    [ROOT_ENTRANCE.MAIN]: (
      <RootStack.Screen
        name={'MainTab'}
        component={MainTab}
        initialParams={{ gid: authGroupId }}
      />
    ),
    [ROOT_ENTRANCE.ADMIN]: (
      <RootStack.Screen name={'AdminStack'} component={AdminStack} />
    ),
  };

  const RootEntrance = rootEntranceMap[rootEntranceKey];
  return (
    <NavigationContainer
      ref={navigation}
      linking={linking}
      onReady={() => {
        navigationIntegration?.registerNavigationContainer(navigation);
      }}
      fallback={<Splash />}
    >
      <NetworkError />
      <RootStack.Navigator
        screenOptions={{
          headerShown: false,
          animationTypeForReplace: authUserId ? 'push' : 'pop',
        }}
        initialRouteName={rootEntranceKey}
      >
        {RootEntrance}
        {!IS_LANDSCAPE &&
          MOBILE_ROOT_PLANNING_SCREENS.map((screen) => (
            <RootStack.Screen
              key={screen.name}
              {...screen}
              options={
                screen.isModalPresentationIOS ? modalPresentationIOSOption : {}
              }
            />
          ))}
        {!IS_LANDSCAPE &&
          MOBILE_ROOT_YEARBOOK_SCREENS.map((screen) => (
            <RootStack.Screen
              key={screen.name}
              {...screen}
              options={
                screen.isModalPresentationIOS ? modalPresentationIOSOption : {}
              }
            />
          ))}
        <RootStack.Screen name={'GroupStack'} component={GroupStack} />
        <RootStack.Screen
          name={'Browser'}
          component={Browser}
          options={modalPresentationIOSOption}
        />
        <RootStack.Screen name={'Onboarding'} component={Onboarding} />
        <RootStack.Screen name={'Invite'} component={Invite} />
        <RootStack.Screen name={'InviteSuccess'} component={InviteSuccess} />
        <RootStack.Screen
          name={'SignUpSuccess'}
          component={SignUpSuccess}
          options={{ animationTypeForReplace: 'pop' }}
        />
        <RootStack.Screen name={'DeleteAccount'} component={DeleteAccount} />
        <RootStack.Screen name={'ImporterStack'} component={ImporterStack} />
        <RootStack.Screen name={'NotFound'} component={NotFound} />
        <RootStack.Screen name={'BrokenLink'} component={BrokenLink} />
        <RootStack.Screen name={'ServerError'} component={ServerError} />
      </RootStack.Navigator>
    </NavigationContainer>
  );
}
