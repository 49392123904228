import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import { Alert } from 'react-native';
import { t } from '~/utils/i18n';
import {
  FileDetails,
  FileOptions,
  GetFileWithDetailsFromLibrary,
  GetImageFromCamera,
  GetImageFromLibrary,
} from './types';
import { getFileDetail } from './utils';

export {
  FileOptions,
  FileDetails,
  GetFileWithDetailsFromLibrary,
  GetImageFromCamera,
  GetImageFromLibrary,
  getFileDetail,
};

async function launchImageLibrary(): Promise<string[]> {
  const result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    quality: 0.8,
  });

  if (!result.canceled) {
    return [result.assets[0].uri];
  }
  return [];
}

export const getImageFromLibrary: GetImageFromLibrary = async (
  options?: FileOptions, //eslint-disable-line
) => {
  return launchImageLibrary();
};

export const getImageFromCamera: GetImageFromCamera = async (
  options?: FileOptions, //eslint-disable-line
) => {
  return launchImageLibrary();
};

export const getFileWithDetailsFromLibrary: GetFileWithDetailsFromLibrary =
  async () => {
    try {
      const result = await DocumentPicker.getDocumentAsync({
        copyToCacheDirectory: true,
      });

      const files = result.assets ?? [];

      const detailedFiles = await Promise.all(
        files.map(async (file) => {
          return await getFileDetail({
            ...file,
            uri: file.uri,
            type: file.mimeType,
          });
        }),
      );
      return detailedFiles;
    } catch (error) {
      if (error instanceof Error && error.message.includes('cancelled')) {
        Alert.alert(t('g.operationCancelled'));
      } else {
        Alert.alert(t('g.unexpectedErrorOccurred'));
      }
      return [];
    }
  };
