import * as Browser from '@sentry/react';

//known issues that does not result in a crash and does not
//  ends in a major problem. marked as a level 'error' on sentry
const knownIssues: string[] = [];

export function trackError(e: unknown) {
  if (e instanceof Error && !knownIssues.includes(e.message)) {
    Browser.captureException(e);
  }
}

export const customSentryTagUser = (userId?: string | null) => {
  if (!userId) {
    return;
  }
  Browser.setUser({ userId });
  Browser.setTag('user_id_base64', userId);
};
