import { useState } from 'react';
import { TouchableOpacity } from 'react-native';

export interface TouchableOpacityWithMeasure
  extends React.ComponentProps<typeof TouchableOpacity> {
  measure: (
    callback: (
      x: number,
      y: number,
      width: number,
      height: number,
      pageX: number,
      pageY: number,
    ) => void,
  ) => void;
}

export const useMeasurableTouchableOpacity = () => {
  const [buttonLayout, setButtonLayout] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const measureButton = (
    buttonRef: React.RefObject<TouchableOpacityWithMeasure>,
  ) => {
    if (buttonRef.current) {
      buttonRef.current.measure(
        (
          x: number,
          y: number,
          width: number,
          height: number,
          pageX: number,
          pageY: number,
        ) => {
          setButtonLayout({ x: pageX, y: pageY, width, height });
        },
      );
    }
  };

  return {
    measureButton,
    buttonLayout,
  };
};
